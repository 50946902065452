import { getAxiosInstance } from '../../api';
import { USER_ENDPOINTS } from '../../constants/private-api';
import type { TUserSearchRequestParameters, TUserSearchResponse } from '@purple/shared-types';

export const getUsersSearch = async (parameters: TUserSearchRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TUserSearchResponse>(USER_ENDPOINTS.SEARCH, {
    params: parameters,
  });
  return response.data;
};
