import React from 'react';
import { STUDENT_STATUS } from '@purple/shared-types';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import { Badge, cn } from '@purple/ui';

type StudentStatusBadgeProperties = {
  isActive: boolean;
};

const StudentStatusBadge = ({ isActive }: StudentStatusBadgeProperties) => {
  const label = React.useMemo(
    () => (isActive ? capitalizeFirstLetter(STUDENT_STATUS.ACTIVE) : capitalizeFirstLetter(STUDENT_STATUS.INACTIVE)),
    [isActive],
  );

  return (
    <Badge
      size="small"
      label={label}
      className={cn('first-letter:capitalize', {
        'text-success-main': isActive,
        'text-grey-950': !isActive,
        'bg-success-bg': isActive,
        'text-grey-100': !isActive,
      })}
    />
  );
};

export { StudentStatusBadge };
