import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PasswordInput,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useChangePassword } from '~/queries';
import { changePasswordSchema } from './schema';
import type * as z from 'zod';

const ChangePasswordModal = () => {
  const { isOpen, toggleModal, closeModal } = useModal(ModalType.PROFILE_CHANGE_PASSWORD_MODAL);

  const { mutate, isPending } = useChangePassword();

  const form = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    mode: 'onChange',
    defaultValues: {
      new_password1: '',
      new_password2: '',
      old_password: '',
    },
  });

  const submitHandler = (data: z.infer<typeof changePasswordSchema>) => {
    const body = {
      old_password: data.old_password,
      new_password: data.new_password1,
    };
    mutate(
      { requestPayload: body },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Change Password</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitHandler)}
        >
          <FormField
            control={form.control}
            name="old_password"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Current Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter password"
                    {...field}
                    type="password"
                    isError={!!form.formState.errors.old_password}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="new_password1"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>New Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter new password"
                    {...field}
                    type="password"
                    isError={!!form.formState.errors.new_password1}
                    withRules
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="new_password2"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Confirm New Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter new password"
                    {...field}
                    type="password"
                    isError={!!form.formState.errors.new_password2}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={form.handleSubmit(submitHandler)}
            disabled={!form.formState.isValid || isPending}
            isLoading={isPending}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ChangePasswordModal };
