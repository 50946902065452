import { getAxiosInstance } from '../../../api';
import { ACTIONS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type {
  TSchoolActionListItem,
  TSchoolActionsPaginatedResponse,
  TSchoolActionsRequestParameters,
} from '@purple/shared-types';

export const getSchoolActionsList = async ({
  schoolId,
  queryParameters,
}: {
  schoolId: string;
  queryParameters: TSchoolActionsRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(ACTIONS.GET_SCHOOL_ACTIONS, schoolId);

  const response = await axiosInstance.get<TSchoolActionsPaginatedResponse<TSchoolActionListItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
