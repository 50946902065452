import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export type IStudentGradesFilterResponse = {
  grade_level: string[];
  subject: string[];
  course_name: string[];
  grade: string[];
  cycle: string[];
};

export const getStudentGradesFilterOptions = async (studentId: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IStudentGradesFilterResponse>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_GRADES_FILTERS, studentId),
  );
  return response.data;
};
