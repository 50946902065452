import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolInfo } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useSchoolById = (schoolId: string) => {
  const { isError, data, ...props } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_BY_ID, schoolId],
    queryFn: () => getSchoolInfo(schoolId),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch the school info', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError, data };
};
