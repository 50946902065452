import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TGroupActionDetailsResponse } from '@purple/shared-types';

export const getActionGroupDetails = async (groupId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TGroupActionDetailsResponse>(
    replaceUrlParameters(ACTIONS.ACTION_GROUPS_BY_ID, groupId),
  );
  return response.data;
};
