import { PurpleIcon } from '@purple/icons';
import { formatDate } from '@purple/shared-utils';
import { cn, Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentMeetingAttendanceListItem } from '@purple/shared-types';

export const meetingColumns: ColumnDef<TStudentMeetingAttendanceListItem>[] = [
  {
    accessorKey: 'meeting_record_name',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        MEETING RECORD NAME
      </Text>
    ),
    // TODO: Add navigation to meeting details
    cell: ({ row }) => (
      <Text
        variant="size-14"
        type="body-500"
        className="line-clamp-1 cursor-pointer text-brand-blue-700 hover:underline hover:underline-offset-2"
      >
        {row.original.meeting_record_name.name}
      </Text>
    ),
  },
  {
    accessorKey: 'attendance_date',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        ATTENDANCE DATE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {formatDate(row.original.attendance_date)}
      </Text>
    ),
  },
  {
    accessorKey: 'presence',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        PRESENCE
      </Text>
    ),
    cell: ({ row }) => (
      <div className="flex items-center justify-center">
        <PurpleIcon
          height={24}
          width={24}
          name={row.original.presence ? 'check' : 'X'}
          className={cn({ 'text-success-main': row.original.presence }, { 'text-error-main': !row.original.presence })}
        />
      </div>
    ),
  },
  {
    accessorKey: 'service_provider',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        SERVICE PROVIDER
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="line-clamp-1 whitespace-nowrap text-grey-950">
        {row.original.service_provider}
      </Text>
    ),
  },
  {
    accessorKey: 'group_service',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        GROUP SERVICE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="line-clamp-1 whitespace-nowrap text-grey-950">
        {row.original.group_service}
      </Text>
    ),
  },
];
