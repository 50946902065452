import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionsExportFile } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActionExportFileParameters } from '@purple/shared-types';

export const useExportActionsFile = ({
  studentId,
  queryParams,
  enabled,
  fileName = 'actions-table',
  onSuccess,
}: {
  studentId: string;
  queryParams: TActionExportFileParameters;
  /**
   * The name of the file to download
   * @default actions-table-${studentId}.xlsx where studentId is the id of the student
   * @example 'actions-table-123.xlsx'
   */
  fileName?: string;
  enabled: boolean;
  onSuccess?: () => void;
}) => {
  const { isError, data, isSuccess, ...rest } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.EXPORT_ACTIONS_FILE, { studentId, ...queryParams }],
    queryFn: () => getActionsExportFile({ studentId, queryParams }),
    enabled: !!studentId && enabled,
  });

  useEffect(() => {
    if (isSuccess && enabled && data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = `${fileName}-${studentId}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);

      onSuccess?.();
    }
  }, [isSuccess, enabled, data, studentId, fileName, onSuccess]);

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to export the table');
      onSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { isError, ...rest };
};
