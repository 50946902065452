import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError, updateProfilePassword } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { TUserChangePasswordMutationError, TUserChangePasswordRequestData } from '@purple/shared-types';

export const useChangePassword = () => {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    AxiosError<TUserChangePasswordMutationError>,
    { requestPayload: TUserChangePasswordRequestData }
  >({
    mutationKey: [USER_QUERY_KEYS.CURRENT_PROFILE_UPDATE_PASSWORD],
    mutationFn: updateProfilePassword,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE] });
      showSuccessToast('System message', 'The password was updated successfully');
    },
    onError: (errorResponse) => {
      if (errorResponse?.response?.data.new_password || errorResponse?.response?.data.old_password) {
        const newPasswordErrors = errorResponse.response.data.new_password || [];
        const oldPasswordErrors = errorResponse.response.data.old_password || [];
        const allErrors = [...newPasswordErrors, ...oldPasswordErrors];
        const errorMessage = allErrors.join(', ');

        showErrorToast('System Message', errorMessage);
        return;
      }
      showErrorToast('System Message', 'Failed to update the password');
    },
  });
};
