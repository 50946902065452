import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader } from '@purple/ui';
import { useUpdateProfile, useUpdateProfileEmail } from '~/queries';
import { DetailsEdit } from './DetailsEdit';
import { DetailsView } from './DetailsView';
import { updateAccountDetailsSchema } from './schema';
import type * as z from 'zod';
import type { TUserAccountDetails } from '@purple/shared-types';

type TAccountDetailsTabProperties = {
  data: TUserAccountDetails;
};

const AccountDetailsTab = ({ data }: TAccountDetailsTabProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const {
    mutate: updateAccountDetails,
    isPending: isAccountDetailsPending,
    isSuccess: isAccountDetailsSuccess,
  } = useUpdateProfile();
  const {
    mutate: updateProfileEmail,
    isPending: isUpdateEmailPending,
    isSuccess: isUpdateEmailSuccess,
  } = useUpdateProfileEmail();

  useEffect(() => {
    if (isAccountDetailsSuccess || isUpdateEmailSuccess) {
      setMode('view');
    }
  }, [isAccountDetailsSuccess, isUpdateEmailSuccess]);

  const form = useForm<z.infer<typeof updateAccountDetailsSchema>>({
    resolver: zodResolver(updateAccountDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      title: data.title,
      email: data.email,
      phone_number: data.phone_number,
    },
  });

  useEffect(() => {
    form.reset({
      title: data.title,
      email: data.email,
      phone_number: data.phone_number,
    });
  }, [data, form, mode]);

  const handleSave = ({ title, email, phone_number }: z.infer<typeof updateAccountDetailsSchema>) => {
    const isEmailChanged = email !== data.email && email;
    const isTitleChanged = title !== data.title;
    const isPhoneChanged = phone_number !== data.phone_number;

    if (isEmailChanged) {
      updateProfileEmail({
        requestPayload: {
          new_email: email,
        },
      });
    }

    if (isTitleChanged || isPhoneChanged) {
      const body = {
        title,
        phone_number,
      };
      updateAccountDetails({ requestPayload: body });
    }

    if (!isEmailChanged && !isTitleChanged && !isPhoneChanged) {
      setMode('view');
    }
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isAccountDetailsPending || isUpdateEmailPending}
      />
      {mode === 'view' ? <DetailsView data={data} /> : <DetailsEdit data={data} form={form} />}
    </div>
  );
};

export { AccountDetailsTab };
