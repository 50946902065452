import { z } from 'zod';
import { TakeActionDocumentType } from '@purple/shared-types';
import { unionOfLiterals } from '@purple/shared-utils';

export const takeActionResourceSchema = z.object({
  document: unionOfLiterals(Object.values(TakeActionDocumentType)),
  title: z.string(),
  type: z
    .string({
      invalid_type_error: 'Type name must be a string.',
    })
    .trim()
    .min(1, {
      message: 'Type is required.',
    }),
  services_providers: z.array(z.string()),
  services: z.array(z.string()).min(1, {
    message: 'At least one service is required.',
  }),
  date: z.date({
    required_error: 'Date & time is required.',
  }),
  quantity: z
    .number({
      invalid_type_error: 'Quantity must be a number.',
    })
    .int()
    .gt(0, {
      message: 'Quantity must be greater than 0.',
    }),
  tags: z.array(z.string()),
  sharedNotes: z.string().trim().min(1, {
    message: 'Shared notes are required.',
  }),
  myNotes: z.string().trim(),
});
