import { PurpleIcon } from '@purple/icons';
import { COLOR_LETTER, type ColorRanges, type TStackedChartData } from '@purple/shared-types';
import { getColorByRange } from '@purple/shared-utils';
import { ColorTrendsLineChart, Text } from '@purple/ui';

type TLineChartCellProperties = React.PropsWithChildren<{
  plot: TStackedChartData;
  colorsRange: ColorRanges;
}>;

const LineChartCell = ({ plot, colorsRange }: TLineChartCellProperties) => {
  if (plot.length === 0) {
    return (
      <div className="flex justify-center">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex h-[28px] w-[28px] items-center justify-center rounded bg-grey-100">
            <PurpleIcon name="chart-pie" height={16} width={16} className="text-grey-600" />
          </div>
          <Text variant="size-12" type="body-500" className="text-grey-700">
            No Data
          </Text>
        </div>
      </div>
    );
  }

  const converted = plot.map((item) => {
    const total = item.attendance + item.behavior;
    const color = getColorByRange(total, colorsRange);
    const name = COLOR_LETTER[color] || '';
    return {
      name,
      total,
      color,
    };
  });
  return <ColorTrendsLineChart data={converted} />;
};

export { LineChartCell };
