import { formatDateToLocalTime, formateDateShortMonth, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSafHistory } from '@purple/shared-types';

export const safHistoryColumns: ColumnDef<TSafHistory>[] = [
  {
    accessorKey: 'modified-by',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Modified By
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] capitalize text-grey-950">
            <span className="block w-full truncate">{original.user.full_name || '–'}</span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.user.full_name && (
            <TooltipContent align="start" withArrow={false}>
              {original.user.full_name}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
  {
    accessorKey: 'field',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Field
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] text-grey-950">
            <span className="block w-full truncate">{snakeToCamelWithSpaces(original.field) || '–'}</span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.field && (
            <TooltipContent align="start" withArrow={false}>
              {snakeToCamelWithSpaces(original.field)}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
  {
    accessorKey: 'original-value',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Original Value
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] text-grey-950">
            <span className="block w-full truncate">{original.original_value || '–'}</span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.original_value && (
            <TooltipContent align="start" withArrow={false}>
              {original.original_value}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
  {
    accessorKey: 'new-value',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        New Value
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] text-grey-950">
            <span className="block w-full truncate">{original.new_value || '–'}</span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.new_value && (
            <TooltipContent align="start" withArrow={false}>
              {original.new_value}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
  {
    accessorKey: 'date-created',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Date Created
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] text-grey-950">
            <span className="block w-full truncate">
              {original.datetime ? formateDateShortMonth(original.datetime) : '–'}
            </span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.datetime && (
            <TooltipContent align="start" withArrow={false}>
              {formatDateToLocalTime(original.datetime)}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
  {
    accessorKey: 'date-updated',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Date Updated
      </Text>
    ),
    cell: ({ row: { original } }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Text variant="size-14" type="body-500" className="min-w-[100px] max-w-[180px] text-grey-950">
            <span className="block w-full truncate">
              {original.datetime ? formateDateShortMonth(original.datetime) : '–'}
            </span>
          </Text>
        </TooltipTrigger>
        <TooltipPortal>
          {original.datetime && (
            <TooltipContent align="start" withArrow={false}>
              {formatDateToLocalTime(original.datetime)}
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    ),
    meta: { className: 'w-[140px]' },
  },
];
