import { type FC, Fragment } from 'react';
import { convertBooleanToText, formatDate } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { IStudentHomeInformation } from '@purple/shared-types';

type StudentHomeInformationViewProperties = {
  data: IStudentHomeInformation;
};

const StudentHomeInformationView: FC<StudentHomeInformationViewProperties> = ({ data }) => {
  const {
    home_language,
    mailing_address,
    is_immigrant,
    unaccompanied_youth_code,
    guardian_info,
    phone,
    family_size,
    with_whom_does_the_child_live,
    relationship_to_the_child,
    other_family_configuration,
  } = data;

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Home Language">
        <InfoItemValue>{home_language}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Mailing Address">
        <InfoItemValue>{mailing_address}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Immigrant">
        <InfoItemValue>{convertBooleanToText(is_immigrant)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Unaccompanied Youth">
        <InfoItemValue>{unaccompanied_youth_code ? formatDate(unaccompanied_youth_code) : '-'}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Primary Guardian First Name">
        <InfoItemValue>{guardian_info?.first_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Primary Guardian Last Name">
        <InfoItemValue>{guardian_info?.last_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Guardian Email">
        <InfoItemValue>{guardian_info?.email}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Phone Number">
        {phone.map((phoneNumber) => {
          return (
            <Fragment key={phoneNumber}>
              <InfoItemValue>{phoneNumber}</InfoItemValue>
              <InfoItemValue>{phoneNumber}</InfoItemValue>
            </Fragment>
          );
        })}
      </InfoItem>
      <InfoItem label="Family Size">
        <InfoItemValue>{family_size}</InfoItemValue>
      </InfoItem>
      <InfoItem label="With Whom does the Child Live?">
        <InfoItemValue>{with_whom_does_the_child_live}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Relationship to the Child">
        <InfoItemValue>{relationship_to_the_child}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Other Family Configuration">
        <InfoItemValue>{other_family_configuration}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { StudentHomeInformationView };
