import { useMemo } from 'react';
import { getGMTOffset } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue, MapComponent } from '~/components';
import type { TLocationDetails, TSchoolAddress } from '@purple/shared-types';

type TDetailsViewProperties = {
  data: TLocationDetails;
};

const DetailsView = ({ data }: TDetailsViewProperties) => {
  const { language, timezone, address } = data;

  const isAddressExist = useMemo(() => Boolean(address), [address]);

  const formatAddress = useMemo(() => {
    if (!address) return '-';
    return `${address.street}, ${address.city}, ${address.state} ${address.postal_code}`;
  }, [address]);

  const timezoneLabel = useMemo(() => {
    if (!timezone) return '-';
    const gmtOffset = getGMTOffset(timezone);
    return `(${gmtOffset}) ${timezone}`;
  }, [timezone]);

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Language">
        <InfoItemValue>{language}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Timezone" contentClassName="max-w-fit">
        <InfoItemValue>{timezoneLabel}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Address">
        <InfoItemValue>{formatAddress}</InfoItemValue>
      </InfoItem>
      {isAddressExist && <MapComponent location={address as TSchoolAddress} />}
    </div>
  );
};

export { DetailsView };
