import { cutGradeName } from './grade';

/**
 * Converts a string by replacing all spaces with underscores and making all characters lowercase.
 *
 * @param {string} string - The input string to be converted.
 * @returns {string} - The converted string with underscores instead of spaces and all characters in lowercase.
 */
export const convertToValue = (string: string): string => {
  return string.toLowerCase().replace(/\s+/g, '_');
};

/**
 * Converts a string by replacing all underscores with spaces and capitalizing the first letter of each word.
 *
 * @param {string} string - The input string to be converted.
 * @returns {string} - The converted string with spaces instead of underscores and each word capitalized.
 */
export const convertToLabel = (string: string): string => {
  return string.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * Converts a data object into a TFiltersConfig format.
 *
 * @param {Record<string, string[]>} data - The input data object where the key is the category name and the value is an array of filters.
 * @returns The converted filters configuration.
 */
export type TConvertFiltersObject = {
  label: string;
  value: string;
};
export type TConvertToFilterConfigOptions = {
  snakeCaseValue?: boolean;
};
export const convertToFilterConfig = (
  data: Record<string, string[] | Partial<Record<'id' | 'value' | 'label' | 'name', string>>[]>,
  options?: TConvertToFilterConfigOptions,
) => {
  const { snakeCaseValue = true } = { ...options };

  const categories = Object.entries(data).map(([categoryLabel, filters]) => ({
    label: convertToLabel(categoryLabel),
    value: snakeCaseValue ? convertToValue(categoryLabel) : categoryLabel,
    filters: filters.map((filter) => {
      if (typeof filter === 'string') {
        return {
          label: cutGradeName(convertToLabel(filter)),
          value: snakeCaseValue ? convertToValue(filter) : filter,
        };
      }
      const label = filter.label ?? filter.name ?? 'N/A';
      const value = filter.value ?? filter.id ?? 'N/A';
      return {
        label: cutGradeName(convertToLabel(label)),
        value: snakeCaseValue ? convertToValue(value) : value,
      };
    }),
  }));

  return { categories };
};
