import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useGetStudentGradesList } from '~/queries/students';
import { FilterActionsRow } from './FilterActionsRow';
import { studentGradesColumns } from './studentGradesColumns';

const Grades = () => {
  const { studentId } = useParams();

  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME);
  const course_name = searchParameters.get('course_name');
  const grade = searchParameters.get('grade');
  const cycle = searchParameters.get('cycle');
  const grade_level = searchParameters.get('grade_level');
  const subject = searchParameters.get('subject');

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { isLoading: isDataLoading, data } = useGetStudentGradesList({
    studentId: studentId as string,
    requestParameters: {
      limit,
      offset,
      ordering: sort,
      search: debounceSearch,
      course_name,
      grade,
      cycle,
      grade_level,
      subject,
    },
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isDataLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <div>
      <div className="flex pb-4 pl-4 pr-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Grades
        </Heading>
      </div>
      <FilterActionsRow />
      <div>
        <DataTable
          columns={studentGradesColumns}
          data={data?.results || []}
          isLoading={isDataLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No grades found"
          emptyStateMessage="There are no grades to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </div>
  );
};

export { Grades };
