import { TakeActionType } from '@purple/shared-types';
import type { ValueOf } from '@purple/shared-types';

export const ActionLayoutComponentType = {
  DETAILS: 'DETAILS',
  TAGS: 'TAGS',
  GOALS: 'GOALS',
  DATE_AND_TIME: 'DATE_AND_TIME',
  SCREENERS_AND_RESULTS: 'SCREENERS_AND_RESULTS',
  CRISIS_DETAILS: 'CRISIS_DETAILS',
  CRISIS_FIELDS: 'CRISIS_FIELDS',
} as const;
export type TActionLayoutComponentType = ValueOf<typeof ActionLayoutComponentType>;

export const IndividualActionDetailsLayout = {
  [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.GOALS, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.CHECK_IN]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.COUNSELING_SESSION]: {
    LEFT: [ActionLayoutComponentType.DETAILS, ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.TAGS],
    RIGHT: [ActionLayoutComponentType.SCREENERS_AND_RESULTS],
  },
  [TakeActionType.CRISIS_RESPONSE_SESSION]: {
    LEFT: [
      ActionLayoutComponentType.DETAILS,
      ActionLayoutComponentType.DATE_AND_TIME,
      ActionLayoutComponentType.CRISIS_FIELDS,
    ],
    RIGHT: [ActionLayoutComponentType.CRISIS_DETAILS, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.DISTRICT_INITIATIVE]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.GUIDANCE_COUNSELING_LESSON]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.GOALS, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.OBSERVATION]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.RESOURCE]: {
    LEFT: [ActionLayoutComponentType.DETAILS],
    RIGHT: [ActionLayoutComponentType.DATE_AND_TIME, ActionLayoutComponentType.TAGS],
  },
  [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]: null,
  [TakeActionType.REMINDER]: null,
  [TakeActionType.SERVICE_LINK]: null,
} as const;
