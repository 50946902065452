import { PurpleIcon } from '@purple/icons';
import { ToggleButton } from '../ToggleButton';
import type { Editor } from '@tiptap/core';

type TAlimentElement = {
  label: string;
  icon: React.ReactNode;
  action: (editor: Editor) => void;
  isActive: (editor: Editor) => boolean;
};

export const ThirdSection = ({ editor }: { editor: Editor }) => {
  const alignmentElements: TAlimentElement[] = [
    {
      label: 'Left',
      icon: <PurpleIcon name="menu-alt-1" className="size-5 text-grey-600" />,
      action: (_editor) => _editor.chain().focus().setTextAlign('left').run(),
      isActive: (_editor) => _editor.isActive({ textAlign: 'left' }),
    },
    {
      label: 'Center',
      icon: <PurpleIcon name="menu-alt-2" className="size-5 text-grey-600" />,
      action: (_editor) => _editor.chain().focus().setTextAlign('center').run(),
      isActive: (_editor) => _editor.isActive({ textAlign: 'center' }),
    },
    {
      label: 'Right',
      icon: <PurpleIcon name="menu-alt-3" className="size-5 text-grey-600" />,
      action: (_editor) => _editor.chain().focus().setTextAlign('right').run(),
      isActive: (_editor) => _editor.isActive({ textAlign: 'right' }),
    },
  ];
  return (
    <div className="flex items-center gap-1 p-0.5">
      {alignmentElements.map((element) => (
        <ToggleButton
          key={element.label}
          isActive={element.isActive(editor)}
          onClick={() => element.action(editor)}
          tooltip={element.label}
          ariaLabel={element.label}
        >
          {element.icon}
        </ToggleButton>
      ))}
    </div>
  );
};
