import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate } from '@purple/hooks';
import { ACTIVITY_TYPE_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME, TIME_PERIOD_NAME } from '@purple/shared-types';
import { TableFooterSection } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSkeleton } from '~/components';
import { ModalType } from '~/constants/modals';
import { useAppSelector, useModal } from '~/hooks';
import { useActivities, useBulkDeleteActivity, useDeleteActivity } from '~/queries';
import { userSelector } from '~/store/features/user';
import { DefaultViewHeader } from './components/DefaultViewHeader';
import { DefaultViewStatisticSection } from './components/DefaultViewStatisticSection';
import { useAllActivityColumns } from './useAllActivityColumns';
import type { TActivity } from '@purple/shared-types';

export const DefaultContentView = () => {
  const { id: currentUserId } = useAppSelector(userSelector);

  const { openModal: openDeleteActivityModal, closeModal: closeDeleteActivityModal } = useModal(
    ModalType.DELETE_ACTIVITY,
  );
  const { closeModal: closeBulkDeleteActivityModal } = useModal(ModalType.BULK_DELETE_ACTIVITY);

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);
  const timePeriod = searchParameters.get(TIME_PERIOD_NAME);
  const activityType = searchParameters.get(ACTIVITY_TYPE_QUERY_NAME);
  const owner = searchParameters.get('created_by');
  const schoolId = searchParameters.get('school');

  const { page, limit, offset, onPageChange, onLimitChange } = usePaginate();

  const [deleteActivityId, setDeleteActivityId] = useState<string | null>(null);
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);

  const { data, isFetching, isLoading } = useActivities({
    limit,
    offset,
    search,
    ordering: sort,
    community_activity_type: activityType,
    time_period: timePeriod,
    school: schoolId,
    created_by: owner,
  });
  const { mutate: deleteActivity, isPending: isDeletePending } = useDeleteActivity();
  const { mutate: bulkDeleteActivity, isPending: isBulkDeletePending } = useBulkDeleteActivity();

  const activities = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => activities.length > 0 && pageCount > 0 && !isFetching,
    [activities, isFetching, pageCount],
  );
  const isAllSelected = useMemo(
    () =>
      selectedActivityIds.length > 0 ? activities.some((activity) => selectedActivityIds.includes(activity.id)) : false,
    [activities, selectedActivityIds],
  );

  const selectActivityHandler = useCallback(
    (activity: TActivity) => {
      const index = selectedActivityIds.indexOf(activity.id);
      setSelectedActivityIds((previous) =>
        index === -1 ? [...previous, activity.id] : previous.filter((id) => id !== activity.id),
      );
    },
    [selectedActivityIds],
  );

  const selectAllActivitiesHandler = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedActivityIds(
          // Select only activities created by the current user
          activities.filter((activity) => activity.created_by?.id === currentUserId).map((activity) => activity.id),
        );
      } else {
        setSelectedActivityIds([]);
      }
    },
    [activities, currentUserId],
  );

  const deleteActivityClickHandler = useCallback(
    (activityId: string) => {
      setDeleteActivityId(activityId);
      openDeleteActivityModal();
    },
    [openDeleteActivityModal],
  );

  const deleteActivityHandler = useCallback(() => {
    if (!deleteActivityId) return;

    deleteActivity(deleteActivityId, {
      onSuccess: () => {
        setSelectedActivityIds((previous) => previous.filter((id) => id !== deleteActivityId));
        setDeleteActivityId(null);
        closeDeleteActivityModal();
      },
    });
  }, [deleteActivity, deleteActivityId, closeDeleteActivityModal]);

  const bulkDeleteActivityHandler = useCallback(() => {
    if (selectedActivityIds.length === 0) return;
    bulkDeleteActivity(
      { activities: selectedActivityIds },
      {
        onSuccess: () => {
          const isDeletedWasSelected
            = deleteActivityId === null ? false : selectedActivityIds.includes(deleteActivityId);
          if (isDeletedWasSelected) {
            setDeleteActivityId(null);
          }
          setSelectedActivityIds([]);
          closeBulkDeleteActivityModal();
        },
      },
    );
  }, [bulkDeleteActivity, selectedActivityIds, closeBulkDeleteActivityModal, deleteActivityId]);

  const activityColumns = useAllActivityColumns({
    isAllSelected,
    selectedActivityIds,
    onActivitySelect: selectActivityHandler,
    onSelectAll: selectAllActivitiesHandler,
    onDelete: deleteActivityClickHandler,
  });

  return (
    <>
      <div className="flex flex-col gap-6">
        <DefaultViewStatisticSection data={data} isLoading={isFetching || isLoading} />
        <section className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
          <DefaultViewHeader isActivitiesSelected={selectedActivityIds.length > 0} />
          <div>
            <DataTable
              columns={activityColumns}
              data={activities}
              isLoading={isFetching || isLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No activities found"
              emptyStateMessage="It looks like we haven’t found any community activities. If something went wrong, please reach out to support."
              classNames={{
                wrapper: 'mb-0',
                row: 'last:border-none',
                cell: 'py-3 h-14',
                emptyCell: 'py-8',
              }}
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {isDataAvailable && (
              <TableFooterSection
                currentPage={page}
                rowsPerPage={limit}
                pageCount={pageCount}
                totalRows={data?.count || 0}
                onPageChange={onPageChange}
                onRowsPerPageChange={onLimitChange}
              />
            )}
          </div>
        </section>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTIVITY}
        modalTitle="Delete Activity"
        modalDescription="Clicking the delete button will permanently remove this activity from the system."
        modalTextContent="Are you sure you want to delete this activity?"
        onPrimaryButtonClick={deleteActivityHandler}
        isLoading={isDeletePending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_ACTIVITY}
        modalTitle="Delete selected activities"
        modalDescription="Clicking the delete button will permanently remove selected activities from the system."
        modalTextContent="Are you sure you want to delete selected activities?"
        onPrimaryButtonClick={bulkDeleteActivityHandler}
        isLoading={isBulkDeletePending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
    </>
  );
};
