import { useMutation } from '@tanstack/react-query';
import { updateRecurringReminder } from '@purple/shared-services';
import { REMINDERS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useUpdateRecurrentReminder = () => {
  return useMutation({
    mutationKey: [REMINDERS_QUERY_KEYS.UPDATE_RECURRING_REMINDER],
    mutationFn: updateRecurringReminder,
    onSuccess: () => {
      showSuccessToast('System message', 'The recurring reminder has been updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update recurring reminder');
    },
  });
};
