import { useMutation } from '@tanstack/react-query';
import { addToPriorityListBulk } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useAddToPriorityListBulk = () => {
  return useMutation({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.ADD_TO_PRIORITY_LIST_BULK],
    mutationFn: addToPriorityListBulk,
    onSuccess: () => {
      showSuccessToast('System message', 'The students were added to the priority list successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not add students to the priority list. Check the provided information and try again',
      );
    },
  });
};
