import { useMutation } from '@tanstack/react-query';
import { patchSafDetails } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';

/**
 *
 * Do not forget to add onError and onSuccess handlers
 * This hook could be used in deferent places so it is better to handle errors and success in the place where it is used
 * with specific messages
 */
export const useUpdateSafDetails = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.UPDATE_SAF_DETAILS],
    mutationFn: patchSafDetails,
  });
};
