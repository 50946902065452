import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCurrentProfileEmail } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TUserChangeEmail, TUserChangeEmailRequestData, TUserProfileMutationError } from '@purple/shared-types';

export const useUpdateProfileEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<
    TUserChangeEmail,
    AxiosError<TUserProfileMutationError>,
    { requestPayload: TUserChangeEmailRequestData }
  >({
    mutationKey: [USER_QUERY_KEYS.CURRENT_PROFILE_UPDATE],
    mutationFn: updateCurrentProfileEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE] });
      showSuccessToast('System message', 'The profile email was updated successfully');
    },
    onError: (errorResponse) => {
      if (errorResponse?.response?.data.avatar) {
        showErrorToast('System Message', errorResponse.response.data.avatar.join(', '));
        return;
      }
      showErrorToast('System Message', 'Failed to update the profile email');
    },
  });
};
