import { Heading } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { HomeTabs } from '~/modules/Home';
import { userSelector } from '~/store/features/user';

export const HomePage: React.FC = () => {
  const { full_name } = useAppSelector(userSelector);
  return (
    <div className="flex w-full flex-col gap-5">
      <Heading variant="size-28" type="heading-600" className="text-grey-title">
        Hi,
        {' '}
        {full_name}
        !
      </Heading>
      <HomeTabs />
    </div>
  );
};
