import { useCallback } from 'react';
import { Checkbox, cn, Label } from '@purple/ui';
import type React from 'react';
import type { useForm } from 'react-hook-form';
import type { TSafAreaCategoriesValues, TSafCloseCheckboxOption } from '@purple/shared-types';
import type { TCloseSafFormValues } from './CloseSafModal';

type SubcategoryCheckboxProperties = {
  form: ReturnType<typeof useForm<TCloseSafFormValues>>;
  categoryKey: TSafAreaCategoriesValues;
  item: TSafCloseCheckboxOption;
  subcategories: TSafCloseCheckboxOption[];
  serviceAreaSubcategories: string[];
};

export const SubcategoryCheckbox: React.FC<SubcategoryCheckboxProperties> = ({
  form,
  categoryKey,
  item,
  subcategories,
  serviceAreaSubcategories,
}) => {
  const isChecked = serviceAreaSubcategories.includes(item.name);

  const handleSubcategoryChange = useCallback(() => {
    const updatedSubcategories = isChecked
      ? serviceAreaSubcategories.filter((name) => name !== item.name)
      : [...serviceAreaSubcategories, item.name];

    form.setValue('service_area_subcategories', updatedSubcategories);

    const subcategoryNames = subcategories.map((sub) => sub.name);
    const hasCheckedSubcategories = subcategoryNames.some((name) => updatedSubcategories.includes(name));

    form.setValue(categoryKey, hasCheckedSubcategories);
  }, [form, categoryKey, isChecked, item.name, serviceAreaSubcategories, subcategories]);

  return (
    <div className="flex items-center gap-2 space-y-0">
      <Checkbox id={item.name} checked={isChecked} onCheckedChange={handleSubcategoryChange} />
      <Label
        className={cn('text-sm font-medium', form.formState.errors.service_area_subcategories && 'text-error-main')}
        htmlFor={item.name}
      >
        {item.display_name}
      </Label>
    </div>
  );
};
