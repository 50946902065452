import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import { convertActivityToFormData } from './utils';
import type { TActivityBodyRequest } from '@purple/shared-types';

export const postCreateActivity = async (parameters: TActivityBodyRequest) => {
  const axiosInstance = getAxiosInstance();

  const formData = convertActivityToFormData(parameters);

  const response = await axiosInstance.post<void>(ACTIVITY_ENDPOINTS.ROOT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
