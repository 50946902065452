import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TPriorityList, TPriorityListShareBody } from '@purple/shared-types';

export const removeSharePriorityListBulk = async ({ id, users }: TPriorityListShareBody) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.REMOVE_SHARE_PRIORITY_LIST_BULK, id);
  const response = await apiInstance.post<TPriorityList>(URL, { users });
  return response.data;
};
