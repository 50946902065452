import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMonth, getYear } from 'date-fns';
import { getSchoolDataCalendar } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolDataCalendarRequestParameters } from '@purple/shared-types';

export const useSchoolDataCalendar = (parameters?: TSchoolDataCalendarRequestParameters & { enabled?: boolean }) => {
  const { month = getMonth(new Date()), year = getYear(new Date()), enabled, ...otherParameters } = parameters ?? {};

  const result = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_DATA_CALENDAR, month, year, otherParameters],
    queryFn: () => getSchoolDataCalendar({ month, year, ...otherParameters }),
    enabled,
  });

  useEffect(() => {
    if (result.isError) {
      showErrorToast('System Message', 'Failed to fetch school data calendar');
    }
  }, [result.isError]);

  return result;
};
