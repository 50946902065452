import { Link } from 'react-router-dom';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { PriorityTableCell } from './PriorityTableCell';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSchoolSafItem } from '@purple/shared-types';

export const studentSafsColumns: ColumnDef<TSchoolSafItem>[] = [
  {
    accessorKey: 'saf_number',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        saf number
      </Text>
    ),
    cell: ({ row: { original } }) =>
      original.saf_number
        ? (
            <Link
              to={AppRoutes.App.Safs.SAFDetails.makePath(original.id)}
              className="cursor-pointer font-primary text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
            >
              {original.saf_number}
            </Link>
          )
        : (
            '—'
          ),
  },
  {
    accessorKey: 'subject',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        subject
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="truncate text-grey-950">
        {row.original.subject}
      </Text>
    ),
  },
  {
    accessorKey: 'needs',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        needs
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="line-clamp-1 text-grey-950">
        {row.original.needs?.length > 0 ? row.original.needs.map(({ name }) => name).join(', ') : '—'}
      </Text>
    ),
  },
  {
    accessorKey: 'date_time_opened',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap uppercase text-grey-600">
        date/time opened
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {formatDateToLocalTime(row.original.date_time_opened)}
      </Text>
    ),
  },
  {
    accessorKey: 'priority',
    size: 80,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-center uppercase text-grey-600">
        priority
      </Text>
    ),
    cell: ({ row }) => (
      <div className="flex items-center justify-center">
        <PriorityTableCell rowData={row.original} />
      </div>
    ),
  },
];
