import { Skeleton } from '@purple/ui';

type TListGridSkeletonProperties = {
  count: number;
};

const ListGridSkeleton = ({ count }: TListGridSkeletonProperties) => {
  return (
    <div className="grid grid-flow-row grid-cols-3 gap-6">
      {Array.from({ length: count }).map((_, index) => {
        const key = Date.now() + index;
        return <Skeleton key={key} className="h-[350px] w-[432px]" />;
      })}
    </div>
  );
};

export { ListGridSkeleton };
