import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardResponse } from '@purple/shared-types';

export const getDashboard = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardResponse>(DASHBOARD_ENDPOINTS.GET_DASHBOARD);
  return response.data;
};
