import { getAxiosInstance } from '../../api';
import { NOTE_ENDPOINTS } from '../../constants/private-api';
import type { TNoteListResponse, TNoteQueryParameters } from '@purple/shared-types';

export const getNoteList = async (parameters: TNoteQueryParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TNoteListResponse>(NOTE_ENDPOINTS.ROOT, {
    params: parameters,
  });

  return response.data;
};
