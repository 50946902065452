import { useNavigate, useParams } from 'react-router-dom';
import { DonutChartCard, Heading } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { showErrorToast } from '~/shared/lib';
import type { IStudentDashboardData } from '~/queries/student-dashboard/types';

type CircleOfSupportProperties = {
  data: IStudentDashboardData | null;
};

const CircleOfSupport = ({ data }: CircleOfSupportProperties) => {
  const { supportByType, supportByProgram, supportByRole, supportByNeed } = data || {};
  const { studentId } = useParams();
  const navigate = useNavigate();

  const navigateSupportByRoleHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByRole.makePath(studentId));
  };

  const navigateSupportByTypeHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByType.makePath(studentId));
  };

  const navigateSupportByProgramHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByProgram.makePath(studentId));
  };

  const navigateSupportByNeedsHandler = () => {
    if (!studentId) {
      return showErrorToast('System message', 'Student ID is missing');
    }
    navigate(AppRoutes.App.Students.StudentDetails.SupportByNeeds.makePath(studentId));
  };

  return (
    <>
      <Heading type="heading-600" variant="size-18" className="text-grey-title">
        Circle of Support
      </Heading>
      {supportByType && supportByNeed && supportByProgram && supportByRole && (
        <div className="mt-2 grid grid-cols-2 gap-4">
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={navigateSupportByTypeHandler}
            data={supportByType}
            title="Support by Type"
            actionTitle="View Report"
          />
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={navigateSupportByProgramHandler}
            data={supportByProgram}
            title="Support by Program"
            actionTitle="View Report"
          />
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={navigateSupportByRoleHandler}
            data={supportByRole}
            title="Support by Role"
            actionTitle="View Report"
          />
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={navigateSupportByNeedsHandler}
            data={supportByNeed}
            title="Support by Need"
            actionTitle="View Report"
          />
        </div>
      )}
    </>
  );
};

export default CircleOfSupport;
