import { Text } from '@purple/ui';
import { CellUserAccessPhone } from './CellUserAccessPhone';
import { CellUsersAccessStatus } from './CellUsersAccessStatus';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSchoolUserAccessListItem } from '@purple/shared-types';

export const usersWithAccessColumns: ColumnDef<TSchoolUserAccessListItem>[] = [
  {
    accessorKey: 'full_name',
    size: 160,
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Contact Name
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="max-w-[200px] truncate capitalize text-grey-950">
        {row.original.full_name}
      </Text>
    ),
  },
  {
    accessorKey: 'is_active',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Status
      </Text>
    ),
    cell: ({ row }) => <CellUsersAccessStatus isActive={row.original.is_active} />,
  },
  {
    accessorKey: 'email',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Email
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="max-w-[280px] truncate text-grey-950">
        {row.original.email}
      </Text>
    ),
  },
  {
    accessorKey: 'contacts__phone',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Phone Number
      </Text>
    ),
    cell: ({ row }) => <CellUserAccessPhone phone={row.original.phone} />,
  },
  {
    accessorKey: 'title',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Title
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="max-w-[160px] truncate text-grey-950">
        {row.original.title ?? '–'}
      </Text>
    ),
  },
];
