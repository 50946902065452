import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { cutGradeName, getInitialsFromName } from '@purple/shared-utils';
import {
  AutoCompleteInput,
  AutocompleteItem,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Form,
  FormField,
  FormItem,
  FormLabel,
} from '@purple/ui';
import { useGetAllStudents } from '~/queries';
import { useSafLinkStudent } from '~/queries/saf/useSafLinkStudent';
import { showErrorToast } from '~/shared/lib';
import { linkStudentFormSchema } from './link-student-fom-schema';
import type * as z from 'zod';

const DEFAULT_USER_LIMIT = 8;
const DEFAULT_USER_OFFSET = 0;

type TFormValues = z.infer<typeof linkStudentFormSchema>;

export const LinkStudentForm = () => {
  const { safId } = useParams();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const { data, isLoading } = useGetAllStudents({
    search: debouncedSearchValue,
    limit: DEFAULT_USER_LIMIT,
    offset: DEFAULT_USER_OFFSET,
  });

  const { mutate, isPending } = useSafLinkStudent();

  const setDebouncedSearch = useDebouncedCallback((searchValue: string) => {
    setDebouncedSearchValue(searchValue);
  }, 400);

  const autocompleteOptions = useMemo(() => {
    return (
      data?.results.map((student) => ({
        ...student,
        label: `${student.first_name} ${student.last_name}`,
        value: student.id,
      })) ?? []
    );
  }, [data]);

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(linkStudentFormSchema),
    defaultValues: {
      student: {},
    },
  });

  const linkStudentHandler = (formData: TFormValues) => {
    if (!safId) {
      return showErrorToast('System message', 'Unable to link student, check if SAF is valid');
    }
    mutate({ safId, student: formData.student.value });
  };

  return (
    <Form providerProps={form} className="flex gap-4" onSubmit={form.handleSubmit(linkStudentHandler)}>
      <FormField
        name="student"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Link Student</FormLabel>
            <AutoCompleteInput
              options={autocompleteOptions}
              onSearchChange={setDebouncedSearch}
              emptyMessage="No results found"
              placeholder="Search student by name or ID"
              isLoading={isLoading}
              {...field}
              onValueChange={field.onChange}
            >
              {autocompleteOptions.map((student) => {
                const { first_name, last_name, photo, value, grade, school } = student;
                const studentFullName = `${first_name} ${last_name}`;
                return (
                  <AutocompleteItem key={value} option={student} customContent>
                    <div className="flex items-center gap-2">
                      <Avatar size={32}>
                        <AvatarImage src={photo ?? undefined} alt={studentFullName} />
                        <AvatarFallback className="bg-grey-200">{getInitialsFromName(studentFullName)}</AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col">
                        <strong className="line-clamp-1 font-primary text-xs font-medium text-grey-950">
                          {studentFullName}
                        </strong>
                        <div className="flex items-center gap-2">
                          <span className="font-primary text-xs text-grey-600">
                            School:
                            {school.name}
                          </span>
                          <div className="flex items-center gap-1">
                            <div className="h-1 w-1 rounded-full bg-grey-600" />
                            <span className="line-clamp-1 font-primary text-xs text-grey-600">
                              Grade:
                              {' '}
                              {grade.length > 0 ? cutGradeName(grade) : '—'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AutocompleteItem>
                );
              })}
            </AutoCompleteInput>
          </FormItem>
        )}
      />
      <Button type="submit" className="self-end" disabled={isPending} isLoading={isPending}>
        Save
      </Button>
    </Form>
  );
};
