import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { useModal } from '~/hooks';
import type { TNote } from '@purple/shared-types';

type TNotesTableOPtionsProperties = {
  rowData: TNote;
  onSelectOption: (noteId: string) => void;
  onModeChange: (mode: 'create' | 'edit' | 'preview') => void;
};

export const NotesTableOptions = ({ rowData, onSelectOption, onModeChange }: TNotesTableOPtionsProperties) => {
  const { openModal } = useModal('student-note');
  const { openModal: openDeleteStudentNoteModal } = useModal('delete-student-note');

  const handleEditNote = () => {
    onSelectOption(rowData.id);
    onModeChange('edit');
    openModal();
  };

  const handleDeleteNote = () => {
    onSelectOption(rowData.id);
    openDeleteStudentNoteModal();
  };

  return (
    <DropdownRoot>
      <DropdownTrigger asChild>
        <Button
          variant="link"
          className="p-0"
          iconLeft={<PurpleIcon name="dots-vertical" className="text-grey-600" />}
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[150px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={handleEditNote}
          className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={<PurpleIcon name="pencil" />}
        >
          Edit
        </Button>
        <Button
          variant="link"
          className="w-full justify-start text-error-main hover:bg-grey-100 hover:text-error-main active:bg-grey-200"
          onClick={handleDeleteNote}
          iconLeft={<PurpleIcon name="trash" />}
        >
          Delete
        </Button>
      </DropdownContent>
    </DropdownRoot>
  );
};
