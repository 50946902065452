import { ChartCardContainer, ChartCardContent, ChartCardFooter, ChartCardHeader, StackedBarChart } from '@purple/ui';
import { CustomizedGroupTick, LegendComponent } from '~/components';
import type { ColorRanges, TStackedChartData } from '@purple/shared-types';

const BAR_SIZE = 28;

type TTotalFlagsComponentProperties = {
  colorRange: ColorRanges;
  data: TStackedChartData;
};

const TotalFlagsComponent = ({ colorRange, data }: TTotalFlagsComponentProperties) => {
  return (
    <ChartCardContainer>
      <ChartCardHeader onClick={() => null} title="Total Flags" />
      <ChartCardContent className="h-[236px] p-0">
        <StackedBarChart
          barSize={BAR_SIZE}
          legend={() => <LegendComponent colorRange={colorRange} />}
          customXAxis={<CustomizedGroupTick />}
          xAxisLabel="Month"
          data={data}
        />
      </ChartCardContent>
      <ChartCardFooter onClick={() => null} actionTitle="View Report" />
    </ChartCardContainer>
  );
};

export { TotalFlagsComponent };
