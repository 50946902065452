import { Link } from 'react-router-dom';
import { cn, Text } from '@purple/ui';
import type React from 'react';

type InfoItemValueProperties = {
  to?: string;
  target?: React.ComponentProps<typeof Link>['target'];
  className?: string;
} & React.PropsWithChildren;

const InfoItemValue = ({ to, target, className, children }: InfoItemValueProperties) => {
  if (!to) {
    return (
      <Text variant="size-16" type="body-500" className={cn('text-right text-grey-950', className)}>
        {children || '-'}
      </Text>
    );
  }

  return (
    <Link to={to} target={target}>
      <Text variant="size-16" type="body-500" className={cn('text-right text-brand-blue-700', className)}>
        {children === null || children === '' ? '-' : children}
      </Text>
    </Link>
  );
};

export { InfoItemValue };
