import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type {
  IPaginatedResponse,
  IStudentAccountHistoryItem,
  IStudentAccountHistoryListRequest,
} from '@purple/shared-types';

export const getStudentAccountHistoryList = async ({
  studentId,
  pagination: { limit, offset },
  search,
}: IStudentAccountHistoryListRequest) => {
  const axiosInstance = getAxiosInstance();
  const path = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_ACCOUNT_HISTORY, studentId);

  const url = `${path}?limit=${limit}&offset=${offset}&search=${search}`;

  const response = await axiosInstance.get<IPaginatedResponse<IStudentAccountHistoryItem[]>>(url);

  return response.data;
};
