import { ACTIVITIES_PERMISSIONS, usePermissions } from '@purple/permissions';
import { Button, Heading, Text } from '@purple/ui';
import { CreateActivityModal } from '~/components';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { CrisisTeamContentView, DefaultContentView } from '~/modules/Community';

export const CommunityPage: React.FC = () => {
  const { hasPermissions } = usePermissions();
  const { openModal } = useModal(ModalType.CREATE_ACTIVITY);

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col gap-1">
            <Heading variant="size-28" type="heading-600">
              Community
            </Heading>
            <Text variant="size-14" type="body-400">
              Create and Manage Your Community Activities
            </Text>
          </div>
          <Button onClick={openModal}>Create Activity</Button>
        </div>
        {hasPermissions([ACTIVITIES_PERMISSIONS.CAN_ACCESS_CRISIS_TEAM_VIEW])
          ? (
              <CrisisTeamContentView />
            )
          : (
              <DefaultContentView />
            )}
      </div>
      <CreateActivityModal />
    </>
  );
};
