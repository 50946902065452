import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsersEvents } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IUserEventsRequestParameters } from '@purple/shared-types';

export const useUsersEvents = (queryParameters: IUserEventsRequestParameters) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [USER_QUERY_KEYS.EVENTS, queryParameters],
    queryFn: () => getUsersEvents({ queryParams: queryParameters }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'An error occurred while fetching user events');
    }
  }, [isError]);

  return { data, isLoading };
};
