import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import {
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { useGetStudentIncidentsFilterOptions } from '~/queries';
import { SORT_OPTIONS } from './constants';

const FilterActionsRow = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const [selectedFilterOption, setSelectedFilterOption] = useState<string>(searchParameters.get('incident_type') || '');

  const { search, onClearSearch, onSearchChange } = useSearch();
  const { sort, onSortChange, onSortClear } = useSort();

  const { data: filtersData, isLoading: isFiltersDataLoading } = useGetStudentIncidentsFilterOptions();

  const handleAddNewIncident = () => {
    // TODO: not finished, there is no modal for it
  };

  const filterOptions = useMemo(() => {
    return filtersData?.map(({ name }) => ({ label: name, value: name })) || [];
  }, [filtersData]);

  const handleFilterChange = (value: string) => {
    setSelectedFilterOption(value);
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('incident_type', value);
      newSearchParameters.set('offset', '0');
      return newSearchParameters;
    });
  };

  const handleClearFilter = () => {
    setSelectedFilterOption('');
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('incident_type');
      newSearchParameters.set('offset', '0');
      return newSearchParameters;
    });
  };

  return (
    <div className="flex w-full flex-row items-center justify-between gap-4 px-4">
      <div className="flex gap-4">
        <RadixSelect onValueChange={handleFilterChange} value={selectedFilterOption}>
          <RadixSelectTrigger
            hasClearButton={Boolean(selectedFilterOption)}
            onClearCallback={handleClearFilter}
            triggerContainerClassName="w-[180px]"
            pw-id="filter-combobox"
            disabled={isFiltersDataLoading}
            isLoading={isFiltersDataLoading}
          >
            <RadixSelectValue placeholder="All Types" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {filterOptions.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <RadixSelect onValueChange={onSortChange} value={sort}>
          <RadixSelectTrigger
            hasClearButton={Boolean(sort)}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[180px]"
            pw-id="sort-combobox"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {SORT_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>

        <div className="w-[300px]">
          <SearchInput
            placeholder="Search by incidents name"
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
        </div>
      </div>
      <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_CREATE_STUDENT_INCIDENTS]}>
        <Button onClick={handleAddNewIncident} iconLeft={<PurpleIcon name="plus" />}>
          New Incident
        </Button>
      </Guard>
    </div>
  );
};

export { FilterActionsRow };
