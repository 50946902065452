import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAttendanceItem } from '@purple/shared-types';

export const attendanceColumns: ColumnDef<TAttendanceItem>[] = [
  {
    accessorKey: 'month',
    header: () => (
      <Text variant="size-12" type="body-600" className="max-w-[450px] uppercase text-grey-600">
        Month
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.month}
      </Text>
    ),
  },
  {
    accessorKey: 'year',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Year
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.year}
      </Text>
    ),
  },
  {
    accessorKey: 'percents',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Attendance Percentage
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.percents ? `${row.original.percents} %` : '–'}
      </Text>
    ),
  },
];
