import { useRef } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Separator,
  ToggleGroup,
  ToggleGroupItem,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
// constants
import { ALLOWED_EMAIL_FILE_EXTENSIONS } from '@purple/shared-utils';
import { AlignmentControls, ListControls, TypographyControls } from './constants';
import type { Editor } from '@tiptap/core';
// types
import type React from 'react';
import type { TTypographyControls } from './constants';

type TEditorToolbarProperties = {
  editor?: Editor | null;
  onFilesChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const EditorToolbar: React.FC<TEditorToolbarProperties> = (props) => {
  const { editor = null, onFilesChange } = props;

  const inputReference = useRef<HTMLInputElement>(null);

  const typographyValues: string[] = [
    ...(editor?.isActive(TypographyControls.BOLD) ? [TypographyControls.BOLD] : []),
    ...(editor?.isActive(TypographyControls.ITALIC) ? [TypographyControls.ITALIC] : []),
    ...(editor?.isActive(TypographyControls.STRIKE) ? [TypographyControls.STRIKE] : []),
    ...(editor?.isActive(TypographyControls.UNDERLINE) ? [TypographyControls.UNDERLINE] : []),
  ];
  const listValues: string = editor?.isActive(ListControls.BULLET_LIST)
    ? ListControls.BULLET_LIST
    : editor?.isActive(ListControls.ORDERED_LIST)
      ? ListControls.ORDERED_LIST
      : '';
  const alignmentValues: string = editor?.isActive({ textAlign: AlignmentControls.LEFT })
    ? AlignmentControls.LEFT
    : editor?.isActive({ textAlign: AlignmentControls.CENTER })
      ? AlignmentControls.CENTER
      : editor?.isActive({ textAlign: AlignmentControls.RIGHT })
        ? AlignmentControls.RIGHT
        : '';

  const typographyValueChangeHandler = (values: TTypographyControls[]) => {
    for (const control of Object.values(TypographyControls)) {
      if (values.includes(control)) {
        editor?.chain().focus().setMark(control).run();
      } else {
        editor?.chain().focus().unsetMark(control).run();
      }
    }
  };

  const listValueChangeHandler = (value: string) => {
    if (value === ListControls.BULLET_LIST) {
      editor?.chain().focus().toggleBulletList().run();
    }
    if (value === ListControls.ORDERED_LIST) {
      editor?.chain().focus().toggleOrderedList().run();
    }
    if (!value) {
      if (editor?.isActive(ListControls.BULLET_LIST)) {
        editor?.chain().focus().toggleBulletList().run();
      }
      if (editor?.isActive(ListControls.ORDERED_LIST)) {
        editor?.chain().focus().toggleOrderedList().run();
      }
    }
  };

  const alignmentValueChangeHandler = (value: string) => {
    if (value === AlignmentControls.LEFT) {
      editor?.chain().focus().setTextAlign(AlignmentControls.LEFT).run();
    }
    if (value === AlignmentControls.CENTER) {
      editor?.chain().focus().setTextAlign(AlignmentControls.CENTER).run();
    }
    if (value === AlignmentControls.RIGHT) {
      editor?.chain().focus().setTextAlign(AlignmentControls.RIGHT).run();
    }
    if (!value) {
      if (editor?.isActive({ textAlign: AlignmentControls.LEFT })) {
        editor?.chain().focus().setTextAlign(AlignmentControls.LEFT).run();
      }
      if (editor?.isActive({ textAlign: AlignmentControls.CENTER })) {
        editor?.chain().focus().setTextAlign(AlignmentControls.CENTER).run();
      }
      if (editor?.isActive({ textAlign: AlignmentControls.RIGHT })) {
        editor?.chain().focus().setTextAlign(AlignmentControls.RIGHT).run();
      }
    }
  };

  const uploadButtonClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  return (
    <div className="flex w-fit items-center justify-start gap-2 rounded-md border border-grey-300 px-2 py-1">
      <ToggleGroup type="multiple" size="sm" value={typographyValues} onValueChange={typographyValueChangeHandler}>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={TypographyControls.BOLD}>
                <PurpleIcon name="Bold" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Bold</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={TypographyControls.ITALIC}>
                <PurpleIcon name="Italic" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Italic</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={TypographyControls.STRIKE}>
                <PurpleIcon name="Crossed" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Strikethrough</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={TypographyControls.UNDERLINE}>
                <PurpleIcon name="Underline" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Underline</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </ToggleGroup>
      <Separator orientation="vertical" className="h-4" />
      <ToggleGroup type="single" size="sm" value={listValues} onValueChange={listValueChangeHandler}>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={ListControls.BULLET_LIST}>
                <PurpleIcon name="Bullet" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Bullet List</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={ListControls.ORDERED_LIST}>
                <PurpleIcon name="Number" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Ordered List</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </ToggleGroup>
      <Separator orientation="vertical" className="h-4" />
      <ToggleGroup type="single" size="sm" value={alignmentValues} onValueChange={alignmentValueChangeHandler}>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={AlignmentControls.LEFT}>
                <PurpleIcon name="menu-alt-1" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Align Left</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={AlignmentControls.CENTER}>
                <PurpleIcon name="menu-alt-2" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Align Center</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="leading-[0]">
              <ToggleGroupItem value={AlignmentControls.RIGHT}>
                <PurpleIcon name="menu-alt-3" className="size-5" />
              </ToggleGroupItem>
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Align Right</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </ToggleGroup>
      <Separator orientation="vertical" className="h-4" />
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            type="button"
            variant="tertiary"
            size="icon_32"
            className="inline-flex h-6 w-6 items-center justify-center rounded-md bg-transparent p-0 text-sm font-medium text-grey-600 transition-colors hover:bg-grey-100 hover:text-grey-600 focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50"
            onClick={uploadButtonClickHandler}
          >
            <PurpleIcon name="upload" className="size-5" />
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Attach File</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <input
        type="file"
        accept={ALLOWED_EMAIL_FILE_EXTENSIONS}
        ref={inputReference}
        multiple
        className="hidden"
        onChange={onFilesChange}
      />
    </div>
  );
};
