import { APP_PERMISSIONS } from '@purple/permissions';
import { Actions } from './Actions';
import { Activities } from './Activities';
import { AllServiceLinks } from './AllServiceLinks';
import { SchoolFiles } from './Files';
import { Overview } from './Overview';
import { Programs } from './Programs';
import { SaFs } from './SaFs';
import { UsersWithAccess } from './UsersWithAccess';
import type { TAppTab } from '@purple/ui';

export const schoolTabs: TAppTab[] = [
  {
    label: 'Overview',
    value: 'overview',
    content: <Overview />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_SCHOOL_DATA_OVERVIEW],
  },
  {
    label: 'Users with Access',
    value: 'users_with_access',
    content: <UsersWithAccess />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_SCHOOL_USERS_WITH_ACCESS],
  },
  {
    label: 'All Service Links',
    value: 'all_service_links',
    content: <AllServiceLinks />,
    permissions: [],
  },
  {
    label: 'Actions',
    value: 'actions',
    content: <Actions />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_SCHOOL_DOCUMENTED_ACTIONS],
  },
  {
    label: 'SAFs',
    value: 'safs',
    content: <SaFs />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_SCHOOL_SAFS],
  },
  {
    label: 'Activities',
    value: 'activities',
    content: <Activities />,
    permissions: [],
  },
  {
    label: 'Programs',
    value: 'programs',
    content: <Programs />,
    permissions: [],
  },
  {
    label: 'Files',
    value: 'files',
    content: <SchoolFiles />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_ATTACHED_FILES, APP_PERMISSIONS.CAN_ACCESS_ADD_FILES_AND_ATTACHMENTS],
  },
];
