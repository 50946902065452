import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafDetailsDto } from '@purple/shared-types';

export const getSafDetails = async (safId: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafDetailsDto>(replaceUrlParameters(SAF_ENDPOINTS.GET_SAF_DETAILS, safId));
  return response.data;
};
