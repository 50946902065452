import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import * as z from 'zod';
import { formatDateWithTime, SAF_QUERY_KEYS } from '@purple/shared-utils';
import {
  cn,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Text,
  Textarea,
} from '@purple/ui';
import { SAF_PLACE_ON_HOLD_REASON_OPTIONS } from '~/components/Modals/SafModals/SafPlaceOnHoldModal/constants';
import { useUpdateSafDetails } from '~/queries/saf/useUpdateSafDetails';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import TabEditSection from '../TabEditSection/TabEditSection';
import type { TSafDetailsDto } from '@purple/shared-types';

const safOnHoldDetailsFormSchema = z.object({
  reason: z.string().min(1, { message: 'Please provide a reason' }),
  detail: z.string().min(1, { message: 'Please provide a detail' }),
});

type TFormValues = z.infer<typeof safOnHoldDetailsFormSchema>;

type TSafOnHoldDetailsProperties = {
  safId?: string | null;
};

export const SafOnHoldDetails = ({ safId }: TSafOnHoldDetailsProperties) => {
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);
  const { on_hold_details } = safDetailsData || {};

  const { mutate, isPending } = useUpdateSafDetails();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(safOnHoldDetailsFormSchema),
    defaultValues: {
      reason: on_hold_details?.on_hold_reason || '',
      detail: on_hold_details?.on_hold_reason_text || '',
    },
  });

  const reasonValue = form.watch('reason');

  const activeOnHoldReason = useMemo(() => {
    return SAF_PLACE_ON_HOLD_REASON_OPTIONS.find((reason) => reason.value === reasonValue);
  }, [reasonValue]);

  const updateOnHoldDetails = (values: TFormValues) => {
    if (!safId) {
      return showErrorToast('System Message', 'Unable to update On Hold Details. Check if the SAF is valid.');
    }

    mutate(
      {
        safId,
        requestBody: {
          on_hold_reason: values.reason,
          on_hold_reason_text: values.detail,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast('System Message', 'On Hold Details updated successfully');
          queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
          setIsEditing(false);
          form.reset({
            reason: values.reason,
            detail: values.detail,
          });
        },
        onError: () => showErrorToast('System Message', 'Unable to update On Hold Details'),
      },
    );
  };

  return (
    <DescriptionList>
      <TabEditSection
        isEditMode={isEditing}
        onEditChange={setIsEditing}
        isUpdating={isPending}
        onUpdate={form.handleSubmit(updateOnHoldDetails)}
      />
      <DescriptionItem>
        <DescriptionTerm>On Hold Date</DescriptionTerm>
        <DescriptionDetails>
          {on_hold_details && on_hold_details.status_changed_at
            ? formatDateWithTime(on_hold_details.status_changed_at)
            : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="reason"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Reason</FormLabel>
                    <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <RadixSelectTrigger
                          className={cn({
                            'border-error-main': !!form.formState.errors.reason,
                          })}
                        >
                          <RadixSelectValue placeholder="Select reason" />
                        </RadixSelectTrigger>
                      </FormControl>
                      <RadixSelectContent>
                        {SAF_PLACE_ON_HOLD_REASON_OPTIONS.map(({ label, value }) => (
                          <RadixSelectItem key={value} value={value}>
                            {label}
                          </RadixSelectItem>
                        ))}
                      </RadixSelectContent>
                    </RadixSelect>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="detail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Details</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!form.formState.errors.detail}
                        placeholder="Enter details here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <>
              <DescriptionItem>
                <DescriptionTerm>Reason</DescriptionTerm>
                <DescriptionDetails>{activeOnHoldReason?.label ?? '—'}</DescriptionDetails>
              </DescriptionItem>
              <div className="flex flex-col gap-1">
                <Text variant="size-16" type="body-600">
                  Details
                </Text>
                <Text variant="size-16" type="body-400" className="text-grey-600">
                  {on_hold_details?.on_hold_reason_text || '—'}
                </Text>
              </div>
            </>
          )}
    </DescriptionList>
  );
};
