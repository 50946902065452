import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Guard } from '@purple/permissions';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Separator } from '@purple/ui';
import { SafSideBarHeader } from './SafSideBarHeader';
import { useSafDetailsSideTabs } from './Tabs/useSafDetailsSideTabs';
import type { TSafDetailsDto } from '@purple/shared-types';

export const SafDetailsSideBar: React.FC = () => {
  const { safId } = useParams();
  const queryClient = useQueryClient();

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { accordionTabs, defaultOpenTab } = useSafDetailsSideTabs({ safId, safDetailsInfo: safDetailsData });

  return (
    <aside className="flex w-full shrink-0 flex-col rounded-lg border border-grey-200 bg-white px-4 pt-6 shadow-custom-heavy">
      <SafSideBarHeader />
      <Separator className="mt-6 bg-grey-200" />
      <Accordion type="multiple" defaultValue={[defaultOpenTab]}>
        {accordionTabs.map((tab) => (
          <Guard key={tab.value} requiredPermissions={tab.permissions}>
            <AccordionItem value={tab.value} className="[&:last-child]:border-none">
              <AccordionTrigger>{tab.label}</AccordionTrigger>
              <AccordionContent>{tab.content}</AccordionContent>
            </AccordionItem>
          </Guard>
        ))}
      </Accordion>
    </aside>
  );
};
