import { getAxiosInstance } from '../../api';
import { AUTH_ENDPOINTS } from '../../constants/public-api';

type LogoutRequestBody = {
  refresh: string;
};

export const logout = async (body: LogoutRequestBody) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.post<void>(AUTH_ENDPOINTS.LOGOUT, body);
  return response.data;
};
