import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentsDataAll } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IStudentDataListRequest } from '@purple/shared-types';

export const useGetAllStudents = (request: IStudentDataListRequest & { enabled?: boolean }) => {
  const { enabled, ...parameters } = request;

  const { isLoading, isError, isSuccess, data, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_DATA_ALL, parameters],
    queryFn: () => getStudentsDataAll(parameters),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch students', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, isSuccess, data, ...rest };
};
