export const convertBooleanToText = (value?: boolean | null): string => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value ? 'Yes' : 'No';
};

export const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

/**
 * Returns the initials from a given name.
 * @param name - The full name.
 * @param trim - The number of initials to return.
 * @returns The initials of the name.
 */
export const getInitialsFromName = (name: string, trim = 2): string => {
  return name
    .split(' ')
    .slice(0, trim)
    .map((string) => string.charAt(0).toUpperCase())
    .join('');
};

export const snakeToCamelWithSpaces = (string_: string): string => {
  const words = string_.split('_');

  const camelCasedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return camelCasedWords.join(' ').replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const snakeCaseToCapitalized = (string_?: string | null, emptyValue = ''): string => {
  if (!string_) {
    return emptyValue;
  }
  const words = string_.split('_');

  const camelCasedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return camelCasedWords.join(' ');
};

export const transformToSnakeCase = (string_: string | null): string => {
  if (!string_) {
    return '';
  }
  return (
    string_

      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .join('_')
      .toLowerCase()
  );
};

/**
 * Extracts the last segment of a given URL's pathname.
 *
 * @param url - The URL string from which to extract the last segment.
 * @returns The last segment of the URL's pathname. If the URL ends with a trailing slash,
 *          the second to last segment is returned. If no segments are found, an empty string is returned.
 */
export const getLastUrlSegment = (url: string): string => {
  const segments = new URL(url).pathname.split('/');
  // Handle potential trailing slash
  const last = segments.pop() || segments.pop();
  return last || '';
};

/**
 * Converts specified substrings within a given string to uppercase.
 *
 * @param string_ - The original string in which substrings will be converted to uppercase.
 * @param subStrings - An array of substrings that need to be converted to uppercase within the original string.
 * @returns The modified string with specified substrings converted to uppercase.
 */
export const makeSubstringsUpperCase = (string_: string, subStrings: string[]): string =>
  subStrings.reduce((accumulator, subString) => accumulator.replace(subString, subString.toUpperCase()), string_);
