import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { TSchoolSafFilters } from '@purple/shared-types';

export const getStudentsDataFilters = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TSchoolSafFilters>(STUDENTS_ENDPOINTS.GET_STUDENTS_DATA_FILTERS_LIST);
  return response.data;
};
