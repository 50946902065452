import { PurpleIcon } from '@purple/icons';
import { ToggleButton } from '../ToggleButton';
import type { Editor } from '@tiptap/core';

type TListElement = {
  label: string;
  icon: React.ReactNode;
  action: (editor: Editor) => void;
  isActive: (editor: Editor) => boolean;
};

export const SecondSection = ({ editor }: { editor: Editor }) => {
  const listElements: TListElement[] = [
    {
      label: 'Bullet list',
      icon: <PurpleIcon name="Bullet" className="size-5" />,
      action: (_editor) => _editor.chain().focus().toggleBulletList().run(),
      isActive: (_editor) => _editor.isActive('bulletList'),
    },
    {
      label: 'Ordered list',
      icon: <PurpleIcon name="Number" className="size-5" />,
      action: (_editor) => _editor.chain().focus().toggleOrderedList().run(),
      isActive: (_editor) => _editor.isActive('orderedList'),
    },
  ];

  return (
    <div className="flex items-center gap-1 p-0.5">
      {listElements.map((element) => (
        <ToggleButton
          key={element.label}
          isActive={element.isActive(editor)}
          onClick={() => element.action(editor)}
          tooltip={element.label}
          ariaLabel={element.label}
        >
          {element.icon}
        </ToggleButton>
      ))}
    </div>
  );
};
