import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafRemoveFromHoldRequest } from '@purple/shared-types';

export const safRemoveFromHold = async ({ safId, body }: { safId: string; body: TSafRemoveFromHoldRequest }) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(SAF_ENDPOINTS.REMOVE_FROM_HOLD, safId);
  const response = await apiInstance.patch<void>(URL, body);
  return response.data;
};
