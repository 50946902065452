import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Badge, Button, Heading } from '@purple/ui';
import { ChangePasswordModal, ChangePhotoModal } from '~/components';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import type { TUserProfileBasic } from '@purple/shared-types';

type TProfileSidebarHeaderProperties = {
  data: TUserProfileBasic;
};

const ProfileSidebarHeader = ({ data }: TProfileSidebarHeaderProperties) => {
  const { isOpen: isChangePasswordModalOpen, openModal: openChangePasswordModal } = useModal(
    ModalType.PROFILE_CHANGE_PASSWORD_MODAL,
  );
  const { isOpen: isChangePhotoModalOpen, openModal: openChangePhotoModal } = useModal(
    ModalType.PROFILE_CHANGE_PHOTO_MODAL,
  );

  const { full_name, avatar, title } = data;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-3">
        <Avatar size={60}>
          <AvatarImage src={avatar ?? undefined} alt={full_name} />
          <AvatarFallback className="bg-grey-200">{getInitialsFromName(full_name ?? '- -')}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col gap-1">
          <Heading variant="size-18" type="heading-600" tag="h3">
            {full_name}
          </Heading>
          {Boolean(title) && (
            <div className="flex gap-2">
              <Badge label={title} size="small" className="bg-brand-blue-100 text-brand-blue-700" />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <Button variant="primary" onClick={openChangePasswordModal}>
          Change Password
        </Button>
        <Button variant="secondary" onClick={openChangePhotoModal}>
          Change Photo
        </Button>
      </div>
      {isChangePasswordModalOpen && <ChangePasswordModal />}
      {isChangePhotoModalOpen && <ChangePhotoModal />}
    </div>
  );
};

export { ProfileSidebarHeader };
