import {
  Card,
  CardContent,
  CardData,
  CardDataCompareValue,
  CardDataDescription,
  CardDataIndicator,
  CardDataValue,
  CardTitle,
} from '@purple/ui';
import { SchoolStatsSkeleton } from './SchoolStatsSkeleton';
import type React from 'react';
import type { TSchoolAttendanceTrends } from '@purple/shared-types';
import type { TCardDataFormatterContext } from '@purple/ui';

type TSchoolStatsProperties = {
  /**
   * School attendance trends data for the selected year.
   */
  data?: TSchoolAttendanceTrends;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const SchoolStats: React.FC<TSchoolStatsProperties> = (props) => {
  const { data, isLoading = false } = props;

  const formatDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    if (isIncrease) {
      return 'Increase compared to last year';
    }
    if (isDecrease) {
      return 'Decrease compared to last year';
    }
    return 'Stable compared to last year';
  };

  if (isLoading || !data) {
    return <SchoolStatsSkeleton />;
  }

  return (
    <ul className="grid grid-cols-3 gap-6">
      <Card tag="li">
        <CardTitle>No. of Students</CardTitle>
        <CardContent>
          <CardData value={data.total_students} compareValue={data.previous_total_students}>
            <CardDataValue enableAnimation formatterOptions={{ maximumFractionDigits: 0 }} />
            <CardDataDescription className="w-full" formatter={formatDescription} />
          </CardData>
        </CardContent>
      </Card>
      <Card tag="li">
        <CardTitle>YTD Attendance Rate</CardTitle>
        <CardContent>
          <CardData value={data.yearly_attendance_avg / 100} compareValue={data.previous_yearly_attendance_avg / 100}>
            <CardDataValue
              applyTrendingColor
              enableAnimation
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataCompareValue
              applyTrendingColor
              enableAnimation
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataIndicator />
            <CardDataDescription className="w-full" formatter={formatDescription} />
          </CardData>
        </CardContent>
      </Card>
      <Card tag="li">
        <CardTitle>YTD Absenteeism Rate</CardTitle>
        <CardContent>
          <CardData
            value={data.yearly_absenteeism_avg / 100}
            compareValue={data.previous_yearly_absenteeism_avg / 100}
            reversed
          >
            <CardDataValue
              applyTrendingColor
              enableAnimation
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataCompareValue
              applyTrendingColor
              enableAnimation
              formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
            />
            <CardDataIndicator />
            <CardDataDescription className="w-full" formatter={formatDescription} />
          </CardData>
        </CardContent>
      </Card>
    </ul>
  );
};
