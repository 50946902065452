import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNote } from '@purple/shared-services';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useDeleteNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [NOTE_QUERY_KEYS.DELETE_NOTE],
    mutationFn: deleteNote,
    onSuccess: () => {
      showSuccessToast('System Message', 'Note deleted successfully');
      queryClient.invalidateQueries({ queryKey: [NOTE_QUERY_KEYS.GET_NOTES_LIST] });
    },
    onError: () => {
      showErrorToast('System Error', 'Unable to delete note');
    },
  });
};
