import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
  Textarea,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useAppSelector, useModal } from '~/hooks';
import { useSafTransfer } from '~/queries';
import { userSelector } from '~/store/features/user';
import { SAF_TRANSFER_BOOLEAN_OPTIONS } from './constants';
import { OwnerListComboBox } from './OwnerListComboBox';
import { safTransferSchema } from './schema';
import type * as z from 'zod';

type TSafTransferModalProperties = {
  safId: string;
  onCloseCallBack?: () => void;
};

const SafTransferModal = ({ safId, onCloseCallBack }: TSafTransferModalProperties) => {
  const { schools } = useAppSelector(userSelector);
  const { isOpen, toggleModal } = useModal(ModalType.SAF_TRANSFER);

  const { mutate: safTransfer, isPending } = useSafTransfer();

  const form = useForm<z.infer<typeof safTransferSchema>>({
    resolver: zodResolver(safTransferSchema),
    mode: 'onChange',
    defaultValues: {
      is_has_school_access: 'yes',
      school: '',
      current_owner: '',
      transfer_reason: '',
    },
  });

  const formIsHasSchoolAccess = form.watch('is_has_school_access');
  const formSchool = form.watch('school');

  const shouldShowSchoolField = useMemo(() => formIsHasSchoolAccess === 'yes', [formIsHasSchoolAccess]);

  const schoolsOptions = useMemo(() => {
    return schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));
  }, [schools]);

  const toggleModalCloseHandler = () => {
    onCloseCallBack?.();
    toggleModal(!isOpen);
    form.reset();
  };

  const transferHandler = ({
    is_has_school_access,
    current_owner,
    transfer_reason,
  }: z.infer<typeof safTransferSchema>) => {
    const body = {
      is_has_school_access: is_has_school_access === 'yes',
      ...(is_has_school_access === 'yes' && { current_owner }),
      transfer_reason,
    };

    safTransfer(
      { safId, body },
      {
        onSuccess: () => {
          toggleModalCloseHandler();
        },
      },
    );
  };

  if (!safId) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(transferHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>Transfer SAF</DialogTitle>
            <DialogDescription className="sr-only">Saf Transfer Modal</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full flex-col gap-4 p-6">
            <FormField
              control={form.control}
              name="is_has_school_access"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Does the student attend a school you work with/have access to?</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(value) => {
                        field.onChange(value);
                        // Reset school and current owner fields when user changes the value of is_has_school_access
                        form.setValue('school', '');
                        form.setValue('current_owner', '');
                      }}
                      defaultValue={field.value}
                      className="flex gap-6"
                    >
                      {SAF_TRANSFER_BOOLEAN_OPTIONS.map(({ label, value }) => (
                        <FormItem key={value} className="flex items-center gap-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={value} />
                          </FormControl>
                          <FormLabel>{label}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {shouldShowSchoolField && (
              <div className="flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="school"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>School</FormLabel>
                      <RadixSelect
                        onValueChange={(value) => {
                          field.onChange(value);
                          // Reset current owner field when user changes the value of school
                          form.setValue('current_owner', '');
                        }}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <RadixSelectTrigger
                            className={cn({
                              'border-error-main': !!form.formState.errors.school,
                            })}
                          >
                            <RadixSelectValue placeholder="Select a school" />
                          </RadixSelectTrigger>
                        </FormControl>
                        <RadixSelectContent>
                          {schoolsOptions.map(({ label, value }) => (
                            <RadixSelectItem key={value} value={value}>
                              {label}
                            </RadixSelectItem>
                          ))}
                        </RadixSelectContent>
                      </RadixSelect>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            {shouldShowSchoolField && (
              <FormField
                control={form.control}
                name="current_owner"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>New Owner</FormLabel>
                    <FormControl>
                      <OwnerListComboBox
                        value={field.value || ''}
                        onChange={(value) => form.setValue('current_owner', value, { shouldValidate: true })}
                        isError={!!fieldState.error}
                        schoolId={formSchool || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="transfer_reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Transfer Reason</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      isError={!!form.formState.errors.transfer_reason}
                      placeholder="Enter reason text here"
                      responsiveHeight
                      className="min-h-[80px] resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button type="button" variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { SafTransferModal };
