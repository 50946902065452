import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { AccessDenied, AppTabs } from '@purple/ui';
import { useSafDetails } from '~/queries/saf/useSafDetails';
import { Emails, SafHistoryTimeline, SafManager } from './Tabs';
import type { TAppTab } from '@purple/ui';

export const SafDetailsTabs: React.FC = () => {
  const { safId } = useParams();

  const { data: safData } = useSafDetails(safId as string);

  const safsDetailsTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'SAF Manager',
        value: 'saf-manager',
        content: <SafManager />,
        permissions: [],
      },
      ...(safData?.saf_submission_details?.is_submitted_anonymously
        ? []
        : [
            {
              label: 'Emails',
              value: 'emails',
              content: <Emails />,
              permissions: [],
            },
          ]),
      {
        label: 'History/Timeline',
        value: 'history-timeline',
        content: <SafHistoryTimeline />,
        permissions: [],
      },
    ],
    [safData?.saf_submission_details?.is_submitted_anonymously],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: safsDetailsTabs,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }

  return (
    <AppTabs
      className="col-span-2 flex flex-col items-start 2xl:-mt-[30px]"
      listClassName="pl-2"
      tabs={safsDetailsTabs}
      value={activeTab}
      onValueChange={tabChangeHandler}
    />
  );
};
