import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { TSchoolPoliticalRegions } from '@purple/shared-types';

type TPoliticalRegionsViewProperties = {
  data: TSchoolPoliticalRegions;
};

const PoliticalRegionsView = ({ data }: TPoliticalRegionsViewProperties) => {
  const {
    trustee_district_region,
    texas_house_district_region,
    state_board_of_education_district,
    city_council_district,
    texas_state_senate_district,
  } = data;

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Trustee District Region">
        <InfoItemValue>{trustee_district_region}</InfoItemValue>
      </InfoItem>
      <InfoItem label="State House District Region">
        <InfoItemValue>{texas_house_district_region}</InfoItemValue>
      </InfoItem>
      <InfoItem label="State Board of Education District">
        <InfoItemValue>{state_board_of_education_district}</InfoItemValue>
      </InfoItem>
      <InfoItem label="City Council District">
        <InfoItemValue>{city_council_district}</InfoItemValue>
      </InfoItem>
      <InfoItem label="State Senate District">
        <InfoItemValue>{texas_state_senate_district}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { PoliticalRegionsView };
