import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName, PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { queryClient } from '~/constants/query-client';
import { useModal } from '~/hooks';
import { useAddToPriorityListBulk, useGetAllStudents } from '~/queries';
import { addStudentsToPriorityListSchema } from './schema';

import type * as z from 'zod';

const SEARCH_DEBOUNCE_DELAY = 500;
const STUDENTS_PER_PAGE = 10;

type TAddStudentToPriorityListModalProperties = {
  listId: string;
};

export const AddStudentToPriorityListModal = ({ listId }: TAddStudentToPriorityListModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('add-to-priority-list');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { mutate: addStudentsToList, isPending } = useAddToPriorityListBulk();

  const { data: students, isLoading: isLoadingStudents } = useGetAllStudents({
    limit: STUDENTS_PER_PAGE,
    offset: 0,
    enabled: isOpen,
    search: debouncedSearchValue,
  });

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, SEARCH_DEBOUNCE_DELAY);

  const multiSelectOptions = useMemo(
    () =>
      students?.results.map((student) => ({
        ...student,
        label: student.first_name ?? student.last_name,
        value: student.id,
      })) ?? [],
    [students],
  );

  const form = useForm<z.infer<typeof addStudentsToPriorityListSchema>>({
    resolver: zodResolver(addStudentsToPriorityListSchema),
    mode: 'onChange',
    defaultValues: {
      students: [],
    },
  });

  const addStudentsHandler = (data: z.infer<typeof addStudentsToPriorityListSchema>) => {
    addStudentsToList(
      {
        id: listId,
        students: data.students,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_STUDENTS],
          });
          queryClient.invalidateQueries({
            queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_BY_ID],
          });
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(addStudentsHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>Add Students</DialogTitle>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full p-6">
            <FormField
              control={form.control}
              name="students"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel required>Add Students</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!fieldState.error}
                      selectedOptions={multiSelectOptions.filter((tag) => field.value.includes(tag.value))}
                      showSearch
                      loading={isLoadingStudents}
                      shouldFilter={false}
                      searchPlaceholder="Search by student name"
                      placeholder="Select students"
                      modalPopover
                      onOptionChange={field.onChange}
                      onSearchChange={debouncedSearch}
                    >
                      {multiSelectOptions.map((option) => {
                        const { first_name, last_name, photo, value, student_id, grade } = option;
                        const fullName = `${first_name} ${last_name}`;
                        return (
                          <MultiSelectItem
                            key={value}
                            value={value}
                            option={option}
                            isSelected={field.value.includes(value)}
                            customContent
                          >
                            <>
                              {field.value.includes(value) && (
                                <PurpleIcon
                                  name="check"
                                  className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 transform text-brand-blue-700"
                                />
                              )}
                              <div className="flex items-center gap-2">
                                <Avatar size={32}>
                                  <AvatarImage src={photo ?? undefined} alt={fullName} />
                                  <AvatarFallback className="bg-grey-200">
                                    {getInitialsFromName(fullName ?? '- -')}
                                  </AvatarFallback>
                                </Avatar>
                                <div className="flex flex-col gap-1">
                                  <strong className="line-clamp-1 text-xs font-medium text-grey-950">{fullName}</strong>
                                  <div className="flex flex-row items-center gap-1">
                                    <span className="line-clamp-1 text-xs text-grey-600">
                                      ID:
                                      {student_id}
                                    </span>
                                    <div className="h-1 w-1 rounded-full bg-grey-600" />
                                    <span className="line-clamp-1 text-xs text-grey-600">
                                      Grade:
                                      {grade}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          </MultiSelectItem>
                        );
                      })}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
