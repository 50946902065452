import { PurpleIcon } from '@purple/icons';
import { Heading, Text } from '@purple/ui';
import type { FC } from 'react';

const ICON_SIZE = 56;
const TITLE_WITH_FILTERS = 'No results found';
const MESSAGE_WITH_FILTERS = 'Try adjusting your search or filter to find what you are looking for.';

type TEmptyDataPlaceholderProperties = React.PropsWithChildren<{
  title: string;
  message: string;
  isFiltersApplied?: boolean;
}>;

const EmptyDataPlaceholder: FC<TEmptyDataPlaceholderProperties> = ({ title, message, isFiltersApplied = false }) => {
  const titleText = isFiltersApplied ? TITLE_WITH_FILTERS : title;
  const messageText = isFiltersApplied ? MESSAGE_WITH_FILTERS : message;
  const iconName = isFiltersApplied ? 'search' : 'folder-open';

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-[20px]">
        <div className="flex rounded-xl bg-grey-100 p-3">
          <PurpleIcon name={iconName} height={ICON_SIZE} width={ICON_SIZE} className="text-grey-600" />
        </div>
        <div className="flex flex-col justify-center gap-1">
          <Heading tag="h4" variant="size-18" type="heading-500" className="text-center text-grey-700">
            {titleText}
          </Heading>
          <Text variant="size-14" type="body-400" className="text-center text-grey-700">
            {messageText}
          </Text>
        </div>
      </div>
    </div>
  );
};

export { EmptyDataPlaceholder };
