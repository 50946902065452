import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActivityHistory } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActivityHistoryRequestParameters } from '@purple/shared-types';

export const useActivityHistory = ({ activityId, ...parameters }: TActivityHistoryRequestParameters) => {
  const { isError, ...props } = useQuery({
    queryKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_HISTORY, activityId, parameters],
    queryFn: () => getActivityHistory({ activityId, ...parameters }),
    enabled: !!activityId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch activity history');
    }
  }, [isError]);

  return { ...props, isError };
};
