import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError, updateProfileAvatar } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { TUserChangePhotoRequestData, TUserProfileMutationError } from '@purple/shared-types';

export const useChangePhoto = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<TUserProfileMutationError>, { requestPayload: TUserChangePhotoRequestData }>({
    mutationKey: [USER_QUERY_KEYS.CURRENT_PROFILE_UPDATE_AVATAR],
    mutationFn: updateProfileAvatar,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE] });
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT] });
      showSuccessToast('System message', 'The photo was updated successfully');
    },
    onError: (errorResponse) => {
      if (errorResponse?.response?.data.avatar) {
        const newErrors = errorResponse.response.data.avatar || [];
        const errorMessage = newErrors.join(', ');

        showErrorToast('System Message', errorMessage);
        return;
      }
      showErrorToast('System Message', 'Failed to update the photo');
    },
  });
};
