import { useMutation } from '@tanstack/react-query';
import { addStudentsToList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useAddStudentsToList = () => {
  return useMutation({
    mutationKey: [STUDENTS_QUERY_KEYS.ADD_STUDENTS_TO_LIST],
    mutationFn: addStudentsToList,

    // NOTE: we do not use onSuccess because success response has 2 fields:
    // missing_student_ids - it has array of student ids that were not found
    // students - it has array of student ids that were added to the list

    onError: () => {
      showErrorToast(
        'System message',
        'Could not add students to the list. Check the provided information and try again',
      );
    },
  });
};
