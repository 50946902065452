import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionExportFileParameters } from '@purple/shared-types';

export const getActionsExportFile = async ({
  studentId,
  queryParams,
}: {
  studentId: string;
  queryParams: TActionExportFileParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(ACTIONS.EXPORT_ACTIONS, studentId);
  const response = await axiosInstance.get<Blob>(URL_PATH, {
    params: queryParams,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
