import React from 'react';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks/redux';
import type { IStudentDataEntity } from '@purple/shared-types';

export const useStudentsDataChartModal = () => {
  const { openModal: openStudentChartModal } = useModal(ModalType.STUDENTS_DATA_CHART);
  const [selectedStudentWithChart, setSelectedStudentWithChart] = React.useState<IStudentDataEntity | null>(null);

  return {
    openStudentChartModal,
    selectedStudentWithChart,
    setSelectedStudentWithChart,
  };
};
