import { formatDateToDDMMYY, getColorByRange, getTransformObjectArrayToLabelValuePairs } from '@purple/shared-utils';
import type { StudentTrendsAndFlagsResponse } from '@purple/shared-services';
import type { ColorRanges, FlagCycleProgress, TStackedChartData } from '@purple/shared-types';
import type { ConvertedStudentTrendsAndFlags } from './types';

export const convertPlot = (plot: FlagCycleProgress[], colorRanges: ColorRanges): TStackedChartData => {
  return plot.map((entry) => {
    const { attendance_cc, behavior_cc, cycle_start_date, pull_date } = entry;

    const total = attendance_cc + behavior_cc;

    const barColor = getColorByRange(total, colorRanges);
    return {
      name: `${formatDateToDDMMYY(cycle_start_date)} - ${formatDateToDDMMYY(pull_date)}`,
      attendance: attendance_cc,
      behavior: behavior_cc,
      color: barColor,
    };
  });
};

export const convertStudentTrendsAndFlags = (data: StudentTrendsAndFlagsResponse): ConvertedStudentTrendsAndFlags => {
  const { trends, flags_plot, attendance_analysis, behavior_analysis } = data;

  const { student_trending, avg_attendance_per_cycle } = trends;
  const { filtered_cycle_progress, colors } = flags_plot;

  return {
    studentTrending: {
      currentRate: student_trending.current,
      prevRate: student_trending.prev,
    },
    avgAttendancePerCycle: {
      currentRate: avg_attendance_per_cycle.current,
      prevRate: avg_attendance_per_cycle.prev,
    },
    flagsPlot: {
      plot: convertPlot(filtered_cycle_progress, colors),
      colorRanges: colors,
    },
    attendanceAnalysis: {
      currentYear: getTransformObjectArrayToLabelValuePairs(attendance_analysis.current_year),
      previousYear: getTransformObjectArrayToLabelValuePairs(attendance_analysis.previous_year),
    },
    behaviorAnalysis: {
      currentYear: getTransformObjectArrayToLabelValuePairs(behavior_analysis.current_year),
      previousYear: getTransformObjectArrayToLabelValuePairs(behavior_analysis.previous_year),
    },
  };
};
