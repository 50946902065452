import type { ValueOf } from '../utils';

export const VIEW_QUERY = 'view' as const;

export const PAGE_VIEW_QUERIES = {
  DETAIL_VIEW: 'detail_view',
  LIST_VIEW: 'list_view',
} as const;
export type TPageViewQuery = ValueOf<typeof PAGE_VIEW_QUERIES>;

export const SEARCH_QUERY_NAME = 'search' as const;
export const SORT_QUERY_NAME = 'ordering' as const;
export const LIMIT_QUERY_NAME = 'limit' as const;
export const OFFSET_QUERY_NAME = 'offset' as const;
export const TIME_PERIOD_NAME = 'time_period' as const;
export const ACTIVITY_TYPE_QUERY_NAME = 'community_activity_type' as const;
export const CRISIS_TYPE_QUERY_NAME = 'crisis_type' as const;
export const CRISIS_GRADE_LEVEL_QUERY_NAME = 'crisis_grade_level' as const;
