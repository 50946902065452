import { createSlice } from '@reduxjs/toolkit';
import { SafSubmitterType } from '@purple/shared-types';
import { StudentAssistanceScreen } from '~/constants/saf';
import { AppReduxReducer } from '~/store/white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
// types
import type { z } from 'zod';
import type { TSafSubmitterType } from '@purple/shared-types';
import type { TSafReturnType, TStudentAssistanceScreen } from '~/constants/saf';
import type { safFormSchema } from '~/modules/StudentAssistance';

export type TStudentAssistanceState = {
  /**
   * Current screen of the student assistance form that is being displayed.
   */
  currentScreen: TStudentAssistanceScreen;
  /**
   * Type of the submitter.
   */
  submitterType: TSafSubmitterType;
  /**
   * List of grades that are available for selected school.
   */
  schoolGrades: string[];
  /**
   * Type which indicates the redirection after the form is submitted.
   * If null, no redirection is needed.
   */
  returnType: TSafReturnType | null;
  /**
   * Last submitted form data that is used for redirection.
   */
  lastSubmittedForm: z.infer<typeof safFormSchema> | null;
};

const initialState: TStudentAssistanceState = {
  currentScreen: StudentAssistanceScreen.SUBMITTER,
  submitterType: SafSubmitterType.STUDENT,
  schoolGrades: [],
  returnType: null,
  lastSubmittedForm: null,
};

export const studentAssistanceSlices = createSlice({
  name: AppReduxReducer.STUDENT_ASSISTANCE,
  initialState,
  reducers: {
    setSafCurrentScreen: (state, action: PayloadAction<TStudentAssistanceScreen>) => {
      state.currentScreen = action.payload;
    },
    setSafSubmitterType: (state, action: PayloadAction<TSafSubmitterType>) => {
      state.submitterType = action.payload;
    },
    setSchoolGrades: (state, action: PayloadAction<string[]>) => {
      state.schoolGrades = action.payload;
    },
    setSafReturnType: (state, action: PayloadAction<TSafReturnType | null>) => {
      state.returnType = action.payload;
    },
    setLastSubmittedForm: (state, action: PayloadAction<z.infer<typeof safFormSchema> | null>) => {
      state.lastSubmittedForm = action.payload;
    },
  },
});

export const studentAssistanceReducer = studentAssistanceSlices.reducer;
