import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector } from '~/hooks/redux/useRedux';
import { routeHistorySelector } from '~/store/features/router-history';

type TUseGoBack = (options?: { fallbackPath?: string | null }) => {
  onGoBack: (event?: React.MouseEvent<HTMLElement>) => void;
};

/**
 * Custom hook for navigating back in the application.
 *
 * @param options - The options for the hook.
 * @param options.fallbackPath - The fallback path to navigate to if there is no previous page.
 *
 * @returns An object with the `onGoBack` function to handle the go back action.
 */
export const useGoBack: TUseGoBack = (options) => {
  const { fallbackPath = null } = options || {};

  const navigate = useNavigate();
  const history = useAppSelector(routeHistorySelector);

  const goBackHandler = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      event?.preventDefault();
      const previousHistoryRoute = history.at(-2);
      if (previousHistoryRoute) {
        const fullRoute = `${previousHistoryRoute.path}${previousHistoryRoute.search}`;
        navigate(fullRoute);
        return;
      }

      if (fallbackPath) {
        navigate(fallbackPath);
      } else {
        navigate(AppRoutes.App.Home.Root.path);
      }
    },
    [navigate, fallbackPath, history],
  );

  return { onGoBack: goBackHandler };
};
