import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getYear } from 'date-fns';
import { getSchoolDataStats } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolDataStatsRequestParameters } from '@purple/shared-types';

export const useSchoolDataStats = (parameters?: TSchoolDataStatsRequestParameters & { enabled?: boolean }) => {
  const { year = getYear(new Date()), enabled, ...otherParameters } = parameters ?? {};

  const result = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_DATA_STATS, year, otherParameters],
    queryFn: () => getSchoolDataStats({ year, ...otherParameters }),
    enabled,
  });

  useEffect(() => {
    if (result.isError) {
      showErrorToast('System Message', 'Failed to fetch school data stats');
    }
  }, [result.isError]);

  return result;
};
