import parsePhoneNumber, { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as z from 'zod';
import type { CountryCode } from 'libphonenumber-js';

/**
 * Zod schema that validates a phone number using `libphonenumber-js`.
 * Attempts to parse the provided value with a default country of `US`.
 *
 * If the phone number is possible, the schema transforms the phone number into
 * an international format (e.g. `+1 123-456-7890`).
 */
export const zPhoneNumber = z.string().transform((value, context) => {
  const phoneNumber = parsePhoneNumber(value, {
    defaultCountry: 'US',
  });

  if (!phoneNumber?.isPossible()) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number',
    });
    return z.NEVER;
  }

  return phoneNumber.formatInternational();
});

export const getNationalFormattedPhoneNumber = ({
  phoneNumber,
  countryCody = 'US',
}: {
  phoneNumber?: string | null;
  countryCody?: CountryCode;
}) => {
  if (!phoneNumber) {
    return '—';
  }

  const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCody);

  if (!parsedNumber?.isPossible()) {
    console.error('Invalid phone number:', phoneNumber);
    return '—';
  }
  const { nationalNumber, countryCallingCode } = parsedNumber;
  const formattedNationalNumber = `(${nationalNumber.slice(0, 3)}) ${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;

  // Return the formatted number with the country code
  return `+${countryCallingCode} ${formattedNationalNumber}`;
};
