import { z } from 'zod';

export const addRecurrenceSchema = z.object({
  title: z.string(),
  members: z.array(z.string()).min(1, {
    message: 'At least one member is required.',
  }),
  date: z.coerce
    .date({
      message: 'Date is invalid.',
      required_error: 'Date is required.',
    })
    .refine((data) => data > new Date(), { message: 'Date must be in the future' }),
  duration: z
    .number({
      message: 'Duration must be an integer.',
    })
    .int()
    .gt(0, {
      message: 'Duration must be greater than 0.',
    }),
  sharedNotes: z.string().trim(),
  myNotes: z.string().trim(),
});
