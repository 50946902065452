import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { SAF_STATUS } from '@purple/shared-types';
import { ACTIONS_QUERY_KEYS, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { Button, Heading, NoDataAvailable, Separator, Text } from '@purple/ui';
import {
  SafNoNeedModal,
  SafPlaceOnHoldModal,
  SafRemoveFromHoldModal,
  SafUnidentifiableModal,
  TakeActionModal,
} from '~/components';
import { CloseSafModal } from '~/components/Modals/SafModals/CloseSafModal';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
import { useSafDetails } from '~/queries/saf/useSafDetails';
import { showErrorToast } from '~/shared/lib';
import { LinkedSafActionsSection } from './components/LinkedSafActionsSection';
import { SafManagerAccordion } from './components/SafManagerAccordion';
import { SafManagerActionSection } from './components/SafManagerActionSection';

export const SafManager = () => {
  const { safId } = useParams();
  const queryClient = useQueryClient();

  const { setSelectedStudentId, toggleActionModal, setSelectedSafId } = useTakeAction();

  const { openModal: openUnidentifiableModal } = useModal(ModalType.SAF_UNIDENTIFIABLE);
  const { openModal: openNoNeedModal } = useModal(ModalType.SAF_NO_NEED);
  const { openModal: openPlaceOnHoldModal } = useModal(ModalType.SAF_PLACE_ON_HOLD);
  const { openModal: openRemoveFromHoldModal } = useModal(ModalType.SAF_REMOVE_FROM_HOLD);

  const { data } = useSafDetails(safId as string);
  const { linked_student_details, priority, documented_actions, status } = data || {};

  const hasStudentUnidentifiable = useMemo(
    () => !linked_student_details?.id && status === SAF_STATUS.CLOSED,
    [linked_student_details?.id, status],
  );
  const hasNoStudentOrPriority = useMemo(
    () => !linked_student_details?.id || !priority,
    [linked_student_details?.id, priority],
  );
  const hasCloseSafStatus = useMemo(() => status === SAF_STATUS.CLOSED, [status]);
  const hasLinkedActions = useMemo(
    () => typeof documented_actions === 'number' && documented_actions > 0,
    [documented_actions],
  );

  const successModalCloseCallBackHandler = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
  }, [queryClient, safId]);

  const successTakeActionModalCloseCallBackHandler = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
    queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_SAF_LINKED_ACTIONS, { safId }] });
  }, [queryClient, safId]);

  const openSetReminderModal = useCallback(() => {
    if (linked_student_details && linked_student_details?.id && safId) {
      setSelectedStudentId(linked_student_details.id);
      setSelectedSafId(safId);
      toggleActionModal('reminder');
      return;
    }
    showErrorToast('System Message', 'Unable to set a reminder. Check if the student is linked and saf is valid');
  }, [linked_student_details, setSelectedStudentId, toggleActionModal, safId, setSelectedSafId]);

  const openSafOnHoldModal = useCallback(() => {
    if (status === SAF_STATUS.ON_HOLD) {
      openRemoveFromHoldModal();
      return;
    }
    openPlaceOnHoldModal();
  }, [openPlaceOnHoldModal, openRemoveFromHoldModal, status]);

  if (hasStudentUnidentifiable) {
    return (
      <NoDataAvailable
        iconName="lightning-bolt"
        title="No Actions Available"
        description="This student in the SAF is marked as unidentifiable. No further action can be taken."
        className="h-[350px]"
        iconSize={40}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-6 py-6">
        <div className="flex items-center gap-4 px-4">
          {Boolean(!linked_student_details?.id) && !hasCloseSafStatus && (
            <Button variant="secondary" onClick={openUnidentifiableModal}>
              Student Unidentifiable
            </Button>
          )}
          {!hasCloseSafStatus && (
            <Button variant="secondary" disabled={Boolean(!linked_student_details?.id)} onClick={openNoNeedModal}>
              Mark as No Need
            </Button>
          )}
          {!hasCloseSafStatus && (
            <Button variant="secondary" disabled={hasNoStudentOrPriority} onClick={openSafOnHoldModal}>
              {status === SAF_STATUS.ON_HOLD ? 'Remove from Hold' : 'Place on Hold'}
            </Button>
          )}

          <Button
            variant="secondary"
            disabled={hasNoStudentOrPriority && !hasCloseSafStatus}
            onClick={openSetReminderModal}
          >
            Set Reminder
          </Button>
        </div>
        <div className="flex flex-col px-4">
          <Heading variant="size-18" type="heading-600">
            SAF Processing Steps
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-600">
            Start processing your SAF from the steps below
          </Text>
        </div>
        <div className="flex flex-col">
          {hasNoStudentOrPriority && !hasLinkedActions && !hasCloseSafStatus && <SafManagerAccordion safId={safId} />}
          <SafManagerActionSection safId={safId} />
        </div>
        {!hasLinkedActions && (
          <>
            <Separator />
            <NoDataAvailable
              iconName="lightning-bolt"
              title="No Action Here"
              description="You haven't documented any actions yet."
              className="h-[350px]"
              iconSize={30}
            />
          </>
        )}
        {hasLinkedActions && <LinkedSafActionsSection />}
      </div>
      <TakeActionModal onSuccessfulAction={successTakeActionModalCloseCallBackHandler} />
      <SafUnidentifiableModal safId={safId as string} onCloseCallBack={successModalCloseCallBackHandler} />
      <SafNoNeedModal safId={safId as string} onCloseCallBack={successModalCloseCallBackHandler} />
      <SafPlaceOnHoldModal safId={safId as string} onCloseCallBack={successModalCloseCallBackHandler} />
      <SafRemoveFromHoldModal safId={safId as string} onCloseCallBack={successModalCloseCallBackHandler} />
      <CloseSafModal safId={safId as string} onSuccess={successModalCloseCallBackHandler} />
    </>
  );
};
