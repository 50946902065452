import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentActionsList } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentActionsRequestParameters } from '@purple/shared-types';

export const useStudentActionsList = ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentActionsRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { studentId, queryParameters }],
    queryFn: () => getStudentActionsList({ studentId, queryParameters }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch students actions');
    }
  }, [isError]);

  return { data, isLoading };
};
