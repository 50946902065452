import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentMeetingAttendance } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentMeetingAttendanceRequestParameters } from '@purple/shared-types';

export const useStudentMeetingAttendanceList = ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentMeetingAttendanceRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_MEETING_ATTENDANCE_LIST, { studentId, queryParameters }],
    queryFn: () => getStudentMeetingAttendance({ studentId, queryParameters }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch students meetings');
    }
  }, [isError]);

  return { data, isLoading };
};
