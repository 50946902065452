import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafCorrespondence } from '@purple/shared-types';

export const getSafEmailSubject = async ({ safId, correspondenceId }: { safId: string; correspondenceId: string }) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafCorrespondence>(
    replaceUrlParameters(SAF_ENDPOINTS.CORRESPONDENTS_BY_ID, safId, correspondenceId),
  );
  return response.data;
};
