export const FormErrorMessages = {
  EMAIL: {
    REQUIRED: 'Email is required',
    INVALID_TYPE: 'Email must be a string',
    INVALID_EMAIL: 'Incorrect email format',
  },
  PASSWORD: {
    REQUIRED: 'Password is required.',
    INVALID_TYPE: 'Password must be a string',
    ONE_NUMBER: 'Password must contain at least one number.',
    ONE_LETTER: 'Password must contain at least one letter.',
    MIN_LENGTH_8: 'Password must be at least 8 characters.',
  },
  CONFIRM_PASSWORD: {
    REQUIRED: 'Confirm password is required.',
    INVALID_TYPE: 'Confirm password must be a string',
    PASSWORD_MISMATCH: 'Please make sure your passwords match',
  },
};
