import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ACTIVITIES_PERMISSIONS, APP_PERMISSIONS, Guard, usePermissions } from '@purple/permissions';
import { SAF_STATUS } from '@purple/shared-types';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@purple/ui';
import { TIME_PERIODS } from '~/constants/options';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { NavigationListItem } from '../NavigationListItem';

export const SiteNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const { id } = useAppSelector(userSelector);

  const { hasPermissions } = usePermissions();

  const communityNavigationRout = useMemo(() => {
    if (hasPermissions([ACTIVITIES_PERMISSIONS.CAN_ACCESS_CRISIS_TEAM_VIEW])) {
      return AppRoutes.App.Community.Root.makePath(TIME_PERIODS.this_academic_year);
    }
    return AppRoutes.App.Community.Root.makePath(TIME_PERIODS.this_academic_year, id);
  }, [hasPermissions, id]);

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link
              to={AppRoutes.App.Home.Root.path}
              className={navigationMenuTriggerStyle({ active: pathname === AppRoutes.App.Home.Root.path })}
            >
              Home
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Identify & Act</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-max max-w-[240px] grid-cols-1 p-1">
              <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_SAF_LIST_MANAGER]}>
                <NavigationListItem
                  to={AppRoutes.App.Safs.Root.makePath(id, TIME_PERIODS.this_academic_year, SAF_STATUS.PRIORITIZED)}
                  active={pathname === AppRoutes.App.Safs.Root.path}
                  iconName="clipboard-list"
                >
                  SAF List Manager
                </NavigationListItem>
              </Guard>
              <NavigationListItem
                to={AppRoutes.App.Visualizer.Root.path}
                active={pathname === AppRoutes.App.Visualizer.Root.path}
                iconName="academic-cap"
              >
                Students Visualizer
              </NavigationListItem>
              <NavigationListItem
                to={AppRoutes.App.ServiceLinks.Root.path}
                active={pathname === AppRoutes.App.ServiceLinks.Root.path}
                iconName="link"
              >
                Service Link Manager
              </NavigationListItem>
              <Guard
                requiredPermissions={[
                  APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
                  APP_PERMISSIONS.CAN_ACCESS_MY_GROUPS,
                ]}
              >
                <NavigationListItem
                  to={AppRoutes.App.Actions.Group.path}
                  active={pathname === AppRoutes.App.Actions.Group.path}
                  iconName="user-group"
                >
                  My Group Actions
                </NavigationListItem>
              </Guard>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link
              to={AppRoutes.App.Monitor.Root.path}
              className={navigationMenuTriggerStyle({
                active: pathname === AppRoutes.App.Monitor.Root.path,
              })}
            >
              Monitor
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link
              to={communityNavigationRout}
              className={navigationMenuTriggerStyle({
                active: pathname === AppRoutes.App.Community.Root.path,
              })}
            >
              Community
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link
              to={AppRoutes.App.ServiceProviders.Root.path}
              className={navigationMenuTriggerStyle({ active: pathname === AppRoutes.App.ServiceProviders.Root.path })}
            >
              Service Providers
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Link
              to={AppRoutes.App.Help.Root.path}
              className={navigationMenuTriggerStyle({
                active: pathname === AppRoutes.App.Help.Root.path,
              })}
            >
              Help Center
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
