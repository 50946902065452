import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateActivityDetails } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActivityDetailsUpdateBody, TActivityDetailsUpdateResponse } from '@purple/shared-types';

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation<TActivityDetailsUpdateResponse, AxiosError, TActivityDetailsUpdateBody>({
    mutationKey: [ACTIVITY_QUERY_KEYS.UPDATE_ACTIVITY_DETAILS],
    mutationFn: updateActivityDetails,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_DETAILS, data.id] });
      showSuccessToast('System message', 'The activity was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not update the activity. Check the provided information and try again');
    },
  });
};
