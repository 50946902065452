import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafNeeds } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useSafNeeds = () => {
  const { isError, ...rest } = useQuery({
    queryKey: [SAF_QUERY_KEYS.SAF_NEEDS_LIST],
    queryFn: getSafNeeds,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch SAF needs options');
    }
  }, [isError]);

  return { isError, ...rest };
};
