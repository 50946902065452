import { useMutation } from '@tanstack/react-query';
import { safClose } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

/**
 *
 * Do not forget to add onSuccess handlers
 * This hook could be used in deferent places so it is better to handle errors and success in the place where it is used
 * with specific messages
 */
export const useCloseSaf = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.CLOSE_SAF],
    mutationFn: safClose,
    onError: () => {
      showErrorToast('System message', 'Unable to close SAF');
    },
  });
};
