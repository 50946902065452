import { useParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { AccessDenied, AppTabs } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { attendanceImpactAnalysisTabs } from '~/modules/Students/StudentProfile/AttendanceImpactAnalysis';

export const AttendanceImpactAnalysis: React.FC = () => {
  const { studentId } = useParams();
  const { activeTab, tabChangeHandler } = useTabs({
    tabs: attendanceImpactAnalysisTabs,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col gap-6">
      <LinkButton
        to={AppRoutes.App.Students.StudentDetails.Root.makePath(studentId as string, 'dashboard')}
        iconName="chevron-left"
        className="font-semibold"
      >
        Back to Student Profile
      </LinkButton>
      <AppTabs tabs={attendanceImpactAnalysisTabs} value={activeTab} onValueChange={tabChangeHandler} />
    </div>
  );
};
