import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafDetails } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useSafDetails = (safId: string) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId],
    queryFn: () => getSafDetails(safId),
    enabled: !!safId,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch saf details');
    }
  }, [isError]);
  return { isError, ...rest };
};
