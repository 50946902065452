import { z } from 'zod';

export const crisisCallTrackerActivitySchema = z.object({
  school: z
    .string({ invalid_type_error: 'School must be a string.' })
    .trim()
    .min(1, { message: 'School is required.' }),
  name: z
    .string({ invalid_type_error: 'Activity Name must be a string.' })
    .trim()
    .min(1, { message: 'Activity Name is required.' }),
  date_and_time: z.coerce
    .date({ required_error: 'Date is required.', message: 'Date is invalid.' })
    .refine((date) => date > new Date(), { message: 'Date must be in the future' }),
  duration: z
    .number({ message: 'Duration must be an integer.' })
    .int()
    .gt(0, { message: 'Duration must be greater than 0.' }),
  campus_contact_name: z
    .string({ invalid_type_error: 'Campus Contact Name must be a string.' })
    .trim()
    .min(1, { message: 'Campus Contact Name is required.' }),
  campus_contact_title: z
    .string({ invalid_type_error: 'Campus Contact must be a string.' })
    .trim()
    .min(1, { message: 'Campus Contact is required.' }),
  contact_phone_number: z
    .string({ invalid_type_error: 'Contact Phone Number must be a string.' })
    .trim()
    .min(1, { message: 'Contact Phone Number is required.' }),
  contact_email: z
    .string({ invalid_type_error: 'Contact Email must be a string.' })
    .trim()
    .min(1, { message: 'Contact Email is required.' }),
  crisis_type: z.array(z.string()).min(1, { message: 'Crisis Type is required.' }),
  crisis_grade_level: z
    .string({ invalid_type_error: 'Crisis Grade Level must be a string.' })
    .trim()
    .min(1, { message: 'Crisis Grade Level is required.' }),
  description_of_crisis: z.string().trim().min(1, { message: 'Description of Crisis is required.' }),
  tags: z.array(z.string()),
  assigned_to: z.string(),
});
