import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TStudentFilterOptions } from '@purple/shared-types';

export const getStudentSafFilterOptions = async ({ studentId }: { studentId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_SAFS_FILTERS_OPTIONS, studentId);
  const response = await axiosInstance.get<TStudentFilterOptions>(URL_PATH);
  return response.data;
};
