import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable, DataTableSkeleton } from '~/components';
import { useAttendanceList } from '~/queries/students/attendance';
import { attendanceColumns } from './attendanceColumns';
import { AttendanceHeaderControls } from './AttendanceHeaderControls';

export const Attendance = () => {
  const { studentId } = useParams();
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());

  const { data, isLoading } = useAttendanceList({ studentId: studentId as string, year: selectedYear });
  return (
    <div className="flex flex-col gap-4">
      <AttendanceHeaderControls selectedYear={selectedYear} onYearChange={setSelectedYear} />
      <DataTable
        columns={attendanceColumns}
        data={data || []}
        isLoading={isLoading}
        emptyStateTitle="No Attendance found"
        emptyStateMessage="There are no Attendance to display. If it an error, please contact support."
        skeleton={<DataTableSkeleton rows={12} />}
      />
    </div>
  );
};
