import { Actions, Attendance } from './Tabs';
import type { TAppTab } from '@purple/ui';

export const attendanceImpactAnalysisTabs: TAppTab[] = [
  {
    label: 'Actions',
    value: 'actions',
    content: <Actions />,
    permissions: [],
  },
  {
    label: 'Attendance',
    value: 'attendance',
    content: <Attendance />,
    permissions: [],
  },
];
