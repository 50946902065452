import { Link } from 'react-router-dom';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { FilesTableOptions } from './FilesTableOptions';
import type { ColumnDef } from '@tanstack/react-table';
import type { TFileListItem } from '@purple/shared-types';

type TFilesOptions = {
  onSelectFileId: (fileId: string) => void;
  onSelectFile: (file: File | null) => void;
};

type TFilesColumns = (options: TFilesOptions) => ColumnDef<TFileListItem>[];

export const useFilesColumns: TFilesColumns = (options) => {
  const { onSelectFileId, onSelectFile } = options || {};

  const filesColumns: ColumnDef<TFileListItem>[] = [
    {
      accessorKey: 'title',
      size: 180,
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Title
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Link
          to={original.file}
          target="_blank"
          rel="noreferrer"
          className="block max-w-[180px] cursor-pointer truncate font-primary font-medium text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
        >
          {original.title}
        </Link>
      ),
    },
    {
      accessorKey: 'owner',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Owner
        </Text>
      ),
      cell: ({
        row: {
          original: { created_by },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="max-w-[200px] truncate text-grey-950">
          {created_by?.full_name || '–'}
        </Text>
      ),
    },
    {
      accessorKey: 'last_modified',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Last Modified
        </Text>
      ),
      cell: ({
        row: {
          original: { updated_at },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {formatDateToLocalTime(updated_at)}
        </Text>
      ),
    },
    {
      accessorKey: 'size',
      size: 140,
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Size
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {original.size.toFixed(2)}
          {' '}
          MB
        </Text>
      ),
    },
    {
      id: 'actions',
      size: 20,
      cell: ({ row: { original } }) => (
        <div className="flex justify-center">
          <FilesTableOptions rowData={original} onSelectOption={onSelectFileId} onReplaceFileCallback={onSelectFile} />
        </div>
      ),
    },
  ];

  return filesColumns;
};
