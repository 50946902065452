import { useTabs } from '@purple/hooks';
import { Guard } from '@purple/permissions';
import { AccessDenied, Tabs, TabsContent, TabsList, TabsTrigger } from '@purple/ui';
import { homeTabsComponents } from './homeTabsComponent';

export const HomeTabs: React.FC = () => {
  const { activeTab, tabChangeHandler } = useTabs({
    tabs: homeTabsComponents,
  });

  if (!activeTab) {
    return (
      <div className="w-full gap-4 rounded-lg border border-grey-200 bg-white py-12 shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }

  return (
    <div>
      <Tabs defaultValue={activeTab} value={activeTab} onValueChange={tabChangeHandler} className="flex flex-col gap-5">
        <TabsList className="flex w-full justify-start gap-4 border-b border-grey-300">
          {homeTabsComponents.map((tab) => (
            <Guard key={tab.value} requiredPermissions={tab.permissions}>
              <TabsTrigger value={tab.value} className="flex items-center gap-2.5 pb-[8px]">
                {tab.icon}
                {tab.label}
              </TabsTrigger>
            </Guard>
          ))}
        </TabsList>
        {homeTabsComponents.map((tab) => (
          <TabsContent key={tab.value} value={tab.value} className="w-full">
            <Guard requiredPermissions={tab.permissions}>{tab.content}</Guard>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};
