import { useMemo } from 'react';
import { Badge, cn, Text } from '@purple/ui';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

type TSelectAllStudentsRowProperties = React.PropsWithChildren<{
  allStudentsCount: number;
  selectedStudents: TSelectedStudent[];
  allStudentsOnPage: TSelectedStudent[];
  onSelectAll: () => void;
  isSelectingAll: boolean;
}>;

const SelectAllStudentsRow = ({
  allStudentsCount,
  selectedStudents,
  allStudentsOnPage,
  onSelectAll,
  isSelectingAll,
}: TSelectAllStudentsRowProperties) => {
  const isAllStudentsOnPageSelected = useMemo(
    () =>
      allStudentsOnPage.length > 0
      && allStudentsOnPage.every((student) => selectedStudents.some((selected) => selected.id === student.id) || false),
    [allStudentsOnPage, selectedStudents],
  );

  const isAllStudentsSelected = useMemo(
    () => allStudentsCount === selectedStudents.length,
    [allStudentsCount, selectedStudents],
  );

  if (allStudentsOnPage.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {isAllStudentsOnPageSelected && (
        <Text variant="size-14" type="body-500" className="text-brand-blue-700">
          All
          {' '}
          {allStudentsOnPage.length}
          {' '}
          Students on this page are selected
        </Text>
      )}
      {!isAllStudentsSelected && (
        <Badge
          label={`Select All Found (${allStudentsCount})`}
          onClick={onSelectAll}
          className={cn('bg-brand-blue-100 text-brand-blue-700', {
            'cursor-default text-transparent opacity-50': isSelectingAll,
          })}
          isLoading={isSelectingAll}
        />
      )}
    </div>
  );
};

export { SelectAllStudentsRow };
