import { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import {
  Card,
  CardContent,
  CardTitle,
  EMPTY_CHART_DATA,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { ChartLegend } from '~/components/ChartLegend';
import { AttendanceChartSkeleton } from './AttendanceChartSkeleton';
import type React from 'react';
import type { TDashboardStudentsAbsenteeismPercentages } from '@purple/shared-types';
import type { TChartConfig } from '@purple/ui';

const chartConfig = {
  value: {
    label: 'Students',
  },
  extreme_chronic: {
    label: 'Extreme Chronic (>50%)',
    color: '#AE5983',
  },
  severe_chronic: {
    label: 'Severe Chronic (20-49%)',
    color: '#DD8080',
  },
  moderate_chronic: {
    label: 'Moderate Chronic (10-19%)',
    color: '#E1C122',
  },
  satisfactory: {
    label: 'Satisfactory (0-5%)',
    color: '#2AA5AD',
  },
  at_risk: {
    label: 'At Risk (5-9%)',
    color: '#1BC1A1',
  },
} satisfies TChartConfig;

type TAttendanceChartProperties = {
  /**
   * School severe satisfactory attendance data for the selected year.
   */
  data?: TDashboardStudentsAbsenteeismPercentages;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const AttendanceChart: React.FC<TAttendanceChartProperties> = (props) => {
  const { data, isLoading = false } = props;

  const chartData = useMemo(
    () =>
      data
        ? Object.entries(data).map(([key, value]) => ({
          name: chartConfig[key as keyof TDashboardStudentsAbsenteeismPercentages].label,
          value,
          fill: chartConfig[key as keyof TDashboardStudentsAbsenteeismPercentages].color,
          color: chartConfig[key as keyof TDashboardStudentsAbsenteeismPercentages].color,
        }))
        : [],
    [data],
  );
  const isNoData = useMemo(
    () => chartData.reduce((accumulator, item) => accumulator + item.value, 0) === 0,
    [chartData],
  );

  if (isLoading) {
    return <AttendanceChartSkeleton />;
  }

  return (
    <Card className="gap-8 px-4 py-6 pb-4 shadow-custom-medium">
      <CardTitle className="text-base font-semibold">Student Absenteeism Percentages</CardTitle>
      <CardContent className="flex-col items-center gap-8">
        <ReChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[177px] w-full">
          <PieChart>
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Pie outerRadius={177 / 2} data={isNoData ? EMPTY_CHART_DATA : chartData} dataKey="value" nameKey="name" />
          </PieChart>
        </ReChartContainer>
        <ChartLegend chartData={chartData} className="gap-3" />
      </CardContent>
    </Card>
  );
};
