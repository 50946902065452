import { nativeEnum, z } from 'zod';
import { ReminderDaysOfWeek, ReminderWeeklyInterval } from '@purple/shared-types';

export const communityActivitySchema = z
  .object({
    school: z
      .string({ invalid_type_error: 'School must be a string.' })
      .trim()
      .min(1, { message: 'School is required.' }),
    tags: z.array(z.string()),
    name: z
      .string({ invalid_type_error: 'Activity Name must be a string.' })
      .trim()
      .min(1, { message: 'Activity Name is required.' }),
    type: z.string({ invalid_type_error: 'Type must be a string.' }).trim().min(1, { message: 'Type is required.' }),
    start_date_and_time: z.coerce
      .date({ required_error: 'Date is required.', message: 'Date is invalid.' })
      .refine((date) => date > new Date(), { message: 'Date must be in the future' }),
    duration: z
      .number({ message: 'Duration must be an integer.' })
      .int()
      .gt(0, { message: 'Duration must be greater than 0.' }),
    stakeholders_served: z.array(z.string()).min(1, { message: 'At least one stakeholder must be served.' }),
    service_category: z.array(z.string()),
    number_of_people_served: z.number({ message: 'Number of People Served must be an integer.' }).int(),
    quantity_of_resources_provided: z.union([z.number(), z.null()]),
    description: z.string().trim(),
    enable_recurring: z.boolean(),
    end_date_and_time: z.coerce.date({ message: 'Date is invalid.' }).optional(),
    weekly_interval: nativeEnum(ReminderWeeklyInterval).optional(),
    days_of_week: z.array(nativeEnum(ReminderDaysOfWeek)).optional(),
  })
  .refine((data) => !data.enable_recurring || data.end_date_and_time, {
    message: 'End date and time is required',
    path: ['end_date_and_time'],
  })
  .refine((data) => !data.enable_recurring || (data.end_date_and_time && data.end_date_and_time > new Date()), {
    message: 'End date and time must be in the future',
    path: ['end_date_and_time'],
  })
  .refine(
    (data) => !data.enable_recurring || (data.end_date_and_time && data.start_date_and_time < data.end_date_and_time),
    {
      message: 'End date must be bigger than start date',
      path: ['end_date_and_time'],
    },
  )
  .refine((data) => !data.enable_recurring || data.weekly_interval, {
    message: 'Weekly interval is required',
    path: ['weekly_interval'],
  })
  .refine((data) => !data.enable_recurring || (data.days_of_week && data.days_of_week.length > 0), {
    message: 'Days of the week are required',
    path: ['days_of_week'],
  });
