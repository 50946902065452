import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate } from '@purple/hooks';
import { type IStudentDataEntity, SEARCH_QUERY_NAME, type TPriorityListDetailResponse } from '@purple/shared-types';
import { Heading, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton, DeleteModal, StudentsDataChartModal } from '~/components';
import { useModal } from '~/hooks';
import { useSelectedStudents } from '~/hooks/useSelectedStudents';
import { useStudentsDataChartModal } from '~/hooks/useStudentsDataChartModal';
import { SelectedStudentsRow } from '~/modules/Visualizer/StudentsData/components';
import { useGetPriorityListStudents, useRemoveStudentsFromPriorityList } from '~/queries';
import { ActionsRow } from './ActionsRow';
import { useStudentsDataColumns } from './usePriorityListStudentsColumns';

type TStudentsTableProperties = {
  listData: TPriorityListDetailResponse | undefined;
  canEdit: boolean;
};

const StudentsTable = ({ listData, canEdit = false }: TStudentsTableProperties) => {
  const [selectedStudentsIdsToRemove, setSelectedStudentsIdsToRemove] = useState<string[]>([]);

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal('remove-modal');

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const { limit, offset, page, onPageChange, onLimitChange } = usePaginate();

  const { isLoading: isDataLoading, data } = useGetPriorityListStudents({
    id: listData?.id || '',
    pagination: {
      limit: +limit,
      offset: +offset,
    },
    search,
  });

  const {
    mutate: removeStudents,
    isPending: isDeletingStudents,
    isSuccess: isSuccessDeletedStudents,
  } = useRemoveStudentsFromPriorityList();

  const { openStudentChartModal, selectedStudentWithChart, setSelectedStudentWithChart } = useStudentsDataChartModal();

  const { selectedStudents, onChangeStudent, onRemoveBulkStudents, onAddBulkStudents, onClearAllStudents }
    = useSelectedStudents();

  useEffect(() => {
    closeDeleteModal();
    onClearAllStudents();
    setSelectedStudentsIdsToRemove([]);
  }, [closeDeleteModal, isSuccessDeletedStudents]); // eslint-disable-line react-hooks/exhaustive-deps

  const isSelectedAllOnPage = useMemo(() => {
    if (!data?.results.length) return false;
    return data?.results?.every((student) => selectedStudents.some((selected) => selected.id === student.id) || false);
  }, [data, selectedStudents]);

  const removeSingleStudentHandler = (studentToRemove: IStudentDataEntity) => {
    setSelectedStudentsIdsToRemove([studentToRemove.id]);
    openDeleteModal();
  };

  const removeSelectedStudentsHandler = () => {
    const ids = selectedStudents.map((student) => student.id);
    setSelectedStudentsIdsToRemove(ids);
    openDeleteModal();
  };

  const columns = useStudentsDataColumns({
    canEdit,
    onCheck: onChangeStudent,
    onCheckAll: () => {
      const convertedStudents
        = data?.results?.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.last_name}`,
        })) || [];

      if (isSelectedAllOnPage) {
        onRemoveBulkStudents(convertedStudents);
      } else {
        onAddBulkStudents(convertedStudents);
      }
    },
    selectedStudents,
    isSelectedAllOnPage,
    onShowChart: (selectedStudentForChart) => {
      setSelectedStudentWithChart(selectedStudentForChart);
      openStudentChartModal();
    },
    onRemove: removeSingleStudentHandler,
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isDataLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / Number(limit)) : 1;
  }, [data?.count, limit]);

  const studentsCountLabel = useMemo(() => {
    return data?.count ? `(${data?.count})` : '';
  }, [data]);

  const confirmDeleteHandler = () => {
    if (!selectedStudentsIdsToRemove?.length || !listData) return;

    removeStudents({
      listId: listData.id,
      students: selectedStudentsIdsToRemove,
    });
  };

  const renderRemoveStudentsModal = () => {
    if (!isDeleteModalOpen || selectedStudentsIdsToRemove.length === 0) return null;

    const studentWord = selectedStudentsIdsToRemove.length > 1 ? 'students' : 'student';
    const title = `Are you sure you want to remove ${studentWord} from ${listData?.name}?`;
    const description = `Once you remove a ${studentWord}, you can manually add them again later.`;

    return (
      <DeleteModal
        title={title}
        description={description}
        onConfirm={confirmDeleteHandler}
        isLoading={isDeletingStudents}
      />
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 px-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="flex items-end gap-1">
            <Heading variant="size-22" type="heading-600" className="text-grey-title">
              Students
            </Heading>
            <Text variant="size-12" type="body-600" className="pb-1 text-grey-600">
              {studentsCountLabel}
            </Text>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <ActionsRow
            listId={listData?.id as string}
            selectedStudents={selectedStudents}
            canEdit={canEdit}
            onRemoveStudents={removeSelectedStudentsHandler}
          />
        </div>
        <SelectedStudentsRow
          selectedStudents={selectedStudents}
          onClearAllStudents={onClearAllStudents}
          onChangeStudent={onChangeStudent}
        />
      </div>
      <div>
        <DataTable
          columns={columns}
          data={data?.results || []}
          isLoading={isDataLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No students found"
          emptyStateMessage="There are no students to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} className="h-[58px]" />}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <StudentsDataChartModal student={selectedStudentWithChart} />
      {renderRemoveStudentsModal()}
    </div>
  );
};

export { StudentsTable };
