import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector, useModal } from '~/hooks';
import { userSelector } from '~/store/features/user';

export const MySchoolsModal = () => {
  const { isOpen, toggleModal } = useModal('my-schools-modal');
  const { schools } = useAppSelector(userSelector);

  const navigate = useNavigate();

  const navigateToSchool = (schoolId: string) => {
    navigate(AppRoutes.App.School.Root.makePath(schoolId, 'overview'));
    toggleModal(!isOpen);
  };
  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-7 tracking-normal">My Schools</DialogTitle>
            <DialogDescription className="sr-only">Select a school to view its details.</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="flex p-6 pb-10">
          <Command className="outline-none">
            <CommandList className="max-h-max rounded-lg border border-grey-200 p-4 pb-2">
              <CommandGroup heading="Choose the school to continue:" className="font-primary">
                {schools.map((school) => (
                  <CommandItem key={school.id} onSelect={() => navigateToSchool(school.id)} className="cursor-pointer">
                    {school.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </div>
      </DialogContent>
    </Dialog>
  );
};
