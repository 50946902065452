import { PurpleIcon } from '@purple/icons';
import { cn, Text } from '@purple/ui';
import type React from 'react';
import type { PurpleIconType } from '@purple/icons';

type TSafCardProperties = React.HTMLAttributes<HTMLButtonElement> & {
  /**
   * The name of the icon to display in the card.
   */
  iconName: PurpleIconType;
  /**
   * The label to display in the card.
   */
  label: string;
  /**
   * Callback function to execute when the card is clicked.
   */
  onCardClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SafCard: React.FC<TSafCardProperties> = (props) => {
  const { iconName, label, className, onCardClick, ...buttonProperties } = props;

  return (
    <button
      type="button"
      {...buttonProperties}
      className={cn(
        'opacity-1 group flex min-h-[200px] w-full flex-col items-center justify-center gap-4 rounded-2xl border border-grey-200 bg-white p-4 py-8 opacity-0 shadow-black/10 outline-none transition-shadow duration-200 ease-in animate-out fade-in fill-mode-forwards repeat-1 hover:shadow-custom-heavy focus:shadow-custom-heavy focus-visible:shadow-custom-heavy',
        className,
      )}
      onClick={onCardClick}
    >
      <span className="flex h-16 w-16 items-center justify-center rounded-xl bg-brand-blue-100">
        <PurpleIcon name={iconName} className="h-8 w-8 text-brand-blue-700" />
      </span>
      <Text
        tag="strong"
        className="whitespace-pre-wrap text-lg font-semibold text-grey-title transition-colors duration-200 group-hover:text-brand-blue-700 group-focus:text-brand-blue-700 group-focus-visible:text-brand-blue-700"
      >
        {label}
      </Text>
    </button>
  );
};
