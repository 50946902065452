import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { currentUserProfileFiles } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TUserFilesListRequestParameters } from '@purple/shared-types';

export const useProfileFiles = (request: TUserFilesListRequestParameters) => {
  const { isError, isLoading, isSuccess, data, ...props } = useQuery({
    queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE_FILES, { request }],
    queryFn: () => currentUserProfileFiles(request),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student grades', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { ...props, isError, isLoading, isSuccess, data };
};
