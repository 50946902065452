import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
// hooks
import { usePaginate } from '@purple/hooks';
import { useModal } from '~/hooks';
import { useNotesList } from '~/queries';
// helpers
import { ModalType } from '~/constants/modals';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { DeleteActionNoteDialog } from '../../../components/DeleteActionNoteDialog';
import { ActionGroupNotePreviewDialog } from './ActionGroupNotePreviewDialog';
import { GroupActionNotesHeader } from './GroupActionNotesHeader';
import { useGroupActionNotesColumns } from './useGroupActionNotesColumns';
// types
import type React from 'react';
import type { TGroupActionDetails, TNote } from '@purple/shared-types';

type TGroupActionNotesProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionNotes: React.FC<TGroupActionNotesProperties> = (props) => {
  const { groupAction } = props;

  const [searchParameters] = useSearchParams();

  const [selectedNote, setSelectedNote] = useState<TNote | null>(null);

  const { openModal: openDeleteNoteModal } = useModal(ModalType.DELETE_ACTION_NOTE);
  const { openModal: openNotePreviewModal } = useModal(ModalType.GROUP_ACTION_NOTE);

  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { data, isLoading } = useNotesList({
    limit,
    offset,
    search,
    ordering: sort,
    action_group: groupAction.id,
  });
  const notes = useMemo(() => data?.results || [], [data?.results]);

  const isDataNotExist = useMemo(() => notes.length === 0 && !isLoading, [notes, isLoading]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  const rowClickHandler = (note: TNote) => {
    setSelectedNote(note);
    openNotePreviewModal();
  };

  const deleteNoteClickHandler = (note: TNote) => {
    setSelectedNote(note);
    openDeleteNoteModal();
  };

  const notesColumns = useGroupActionNotesColumns({
    actionType: groupAction.record_action_type,
    onDelete: deleteNoteClickHandler,
  });

  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4 px-4 pt-6">
        <Heading tag="h2" className="text-lg font-semibold text-grey-title">
          Notes
        </Heading>
        <GroupActionNotesHeader groupAction={groupAction} />
      </div>
      <div className="flex w-full flex-col">
        <DataTable
          columns={notesColumns}
          data={notes}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="Notes Not Found"
          emptyStateMessage="There are no notes to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          classNames={{ root: 'table-fixed', cell: 'py-3 h-14', emptyCell: 'py-8' }}
          onRowClick={rowClickHandler}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <ActionGroupNotePreviewDialog note={selectedNote} groupAction={groupAction} />
      <DeleteActionNoteDialog noteId={selectedNote?.id} />
    </section>
  );
};
