import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentDetails } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

import type { UseQueryResult } from '@tanstack/react-query';
import type { IStudentDetailsResponse } from '@purple/shared-services';

export const useGetStudentDetails = (studentId: string | undefined): UseQueryResult<IStudentDetailsResponse, Error> => {
  const useQueryResponse = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_DETAILS, studentId],
    queryFn: () => getStudentDetails(studentId as string),
    enabled: !!studentId,
  });

  useEffect(() => {
    if (useQueryResponse.isError) {
      showErrorToast('Failed to fetch student data', 'Something went wrong, try again later.');
    }
  }, [useQueryResponse.isError]);

  return useQueryResponse;
};
