import { Address, Details, PoliticalRegions, SchoolStats } from './tabs';
import type { TSchoolInfo } from '@purple/shared-types';

export const useSchoolSidebarAccordionItems = (data: TSchoolInfo | undefined) =>
  data
    ? [
        {
          label: 'Details',
          content: <Details data={data} />,
          permissions: [],
        },
        {
          label: 'School Stats',
          content: <SchoolStats data={data.stats} />,
          permissions: [],
        },
        {
          label: 'Political Regions',
          content: <PoliticalRegions data={data} />,
          permissions: [],
        },
        {
          label: 'Address',
          content: <Address data={data} />,
          permissions: [],
        },
      ]
    : [];
