import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReminderType, ReminderWeeklyInterval, type TReminderDetails } from '@purple/shared-types';
import {
  Checkbox,
  cn,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Textarea,
} from '@purple/ui';
import { DAYS_OF_WEEK_OPTIONS, ReadableReminderWeeklyInterval } from '~/constants/reminder';
import { takeActionReminderSchema } from '../../TakeActionModal/ReminderModal/schema';
import type * as z from 'zod';

type TReminderEditFormProperties = {
  reminderDetails?: TReminderDetails;
  formId: string;
  onSubmitForm: (data: z.infer<typeof takeActionReminderSchema>) => void;
};

export const ReminderEditForm = ({ reminderDetails, formId, onSubmitForm }: TReminderEditFormProperties) => {
  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(takeActionReminderSchema),
    defaultValues: {
      type: reminderDetails?.type ?? ReminderType.ONE_TIME,
      subject: reminderDetails?.subject ?? '',
      notifyBeforeStart: reminderDetails?.notify_before_minutes ?? 0,
      duration: reminderDetails?.duration ?? 0,
      isAllDayEvent: Boolean(reminderDetails?.is_all_day_event),
      date: reminderDetails ? new Date(reminderDetails.date_and_time) : new Date(),
      endDate:
        reminderDetails && reminderDetails.end_date_and_time ? new Date(reminderDetails.end_date_and_time) : undefined,
      daysOfWeek: reminderDetails?.days_of_week ?? [],
      weeklyInterval: reminderDetails?.weekly_interval ?? ReminderWeeklyInterval.EVERY,
      description: reminderDetails?.description ?? '',
    },
  });

  const reminderType = form.watch('type');
  const startDate = form.watch('date');
  const isAllDayEvent = form.watch('isAllDayEvent');

  return (
    <Form
      providerProps={form}
      className="grid w-full grid-cols-2 gap-4 p-6"
      id={formId}
      onSubmit={form.handleSubmit(onSubmitForm)}
    >
      <FormField
        control={form.control}
        name="subject"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel required>Subject</FormLabel>
            <FormControl>
              <Input {...field} isError={!!fieldState.error} placeholder="Enter subject here" type="text" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="date"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>
              {reminderType === ReminderType.ONE_TIME ? 'Date & Time' : 'Start Date & Time'}
            </FormLabel>
            <FormControl>
              <DatePicker
                mode="single"
                placeholder="Select date & time"
                formatterString="PPP p"
                isError={!!fieldState.error}
                triggerDisabled={field.disabled}
                defaultMonth={field.value}
                selected={field.value}
                disabled={{ before: new Date() }}
                captionLayout="dropdown"
                onDayClick={field.onChange}
                onTimeChange={field.onChange}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {reminderType === ReminderType.RECURRING && (
        <FormField
          control={form.control}
          name="endDate"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>End Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  disabled={{
                    before: startDate,
                  }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <FormField
        control={form.control}
        name="notifyBeforeStart"
        render={({ field, fieldState }) => (
          <FormItem className={cn(reminderType === ReminderType.RECURRING && 'col-span-2')}>
            <FormLabel required>Notify Before Start (via email)</FormLabel>
            <FormControl>
              <NumberInput
                {...field}
                type="number"
                isError={!!fieldState.error}
                placeholder="Enter time in minutes here"
                min={0}
                changeOnWheel
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {reminderType === ReminderType.ONE_TIME && (
        <FormField
          control={form.control}
          name="isAllDayEvent"
          render={({ field }) => (
            <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>All Day</FormLabel>
            </FormItem>
          )}
        />
      )}

      {!isAllDayEvent && (
        <FormField
          control={form.control}
          name="duration"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {reminderType === ReminderType.RECURRING && (
        <>
          <FormField
            control={form.control}
            name="daysOfWeek"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Days of week</FormLabel>
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!fieldState.error}
                    modalPopover
                    showSearch
                    selectedOptions={DAYS_OF_WEEK_OPTIONS.filter((option) => field.value?.includes(option.value))}
                    placeholder="Select days"
                    onOptionChange={field.onChange}
                  >
                    {DAYS_OF_WEEK_OPTIONS.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.includes(option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="weeklyInterval"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Weekly Interval</FormLabel>
                <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <RadixSelectTrigger
                      className={cn({
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select interval" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {Object.values(ReminderWeeklyInterval).map((value) => (
                      <RadixSelectItem key={value} value={value}>
                        {ReadableReminderWeeklyInterval[value]}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}

      <FormField
        control={form.control}
        name="description"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Textarea
                {...field}
                isError={!!fieldState.error}
                placeholder="Enter description here"
                responsiveHeight
                className="min-h-[80px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
};
