import { getAxiosInstance } from '../../api';
import { SCHOOLS_ENDPOINTS } from '../../constants/private-api';
import type { TAllSchoolsResponse, TSchoolListRequestParameters } from '@purple/shared-types';

export const getAllSchools = async (parameters: TSchoolListRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TAllSchoolsResponse>(SCHOOLS_ENDPOINTS.SCHOOLS, {
    params: parameters,
  });
  return response.data;
};
