import { StatisticCardSkeleton } from '../StatisticCard';

export const ActionsHeaderSectionSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="grid grid-flow-col gap-x-4">
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StatisticCardSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};
