import { motion } from 'framer-motion';
import { Separator, Skeleton } from '@purple/ui';

const FormSkeletonItem = () => {
  return (
    <div className="rounded-lg border border-grey-200 p-4">
      <div className="flex flex-col gap-2.5">
        <Skeleton className="h-6 w-full" />
        <Separator className="bg-grey-200" />
        <div className="flex flex-col gap-2 pl-7">
          {Array.from({ length: 5 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} className="h-6 w-full" />
          ))}
        </div>
      </div>
    </div>
  );
};

export const CloseSafFormSkeleton = () => {
  return (
    <motion.div
      key="skeleton"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="grid grid-cols-2 gap-4 p-6"
    >
      {Array.from({ length: 4 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FormSkeletonItem key={index} />
      ))}
    </motion.div>
  );
};
