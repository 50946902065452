import React from 'react';
import { STUDENT_CALENDAR_ITEM_TYPE, STUDENT_CALENDAR_PERIOD } from '@purple/shared-types';
import type { TStudentCalendarItemType, TStudentCalendarPeriod } from '@purple/shared-types';

const useStudentCalendar = () => {
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth() + 1);

  const [selectedEventType, setSelectedEventType] = React.useState<TStudentCalendarItemType>(
    STUDENT_CALENDAR_ITEM_TYPE.ATTENDANCE,
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState<TStudentCalendarPeriod>(STUDENT_CALENDAR_PERIOD.MONTH);

  const onEventTypeChange = (eventType: TStudentCalendarItemType) => {
    setSelectedEventType(eventType);
  };

  const onPeriodChange = (period: TStudentCalendarPeriod) => {
    setSelectedPeriod(period);
  };

  const onSelectedDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  React.useEffect(() => {
    if (selectedDate) {
      setSelectedYear(selectedDate.getFullYear());
      setSelectedMonth(selectedDate.getMonth() + 1);
    }
  }, [selectedDate]);

  return {
    selectedDate,
    selectedYear,
    selectedMonth,
    onSelectedDateChange,
    selectedEventType,
    onEventTypeChange,
    selectedPeriod,
    onPeriodChange,
  };
};

export default useStudentCalendar;
