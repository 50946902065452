import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { IStudentAccountHistoryItem } from '@purple/shared-types';

export const studentAccountHistoryColumns: ColumnDef<IStudentAccountHistoryItem>[] = [
  {
    accessorKey: 'datetime',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap text-grey-600">
        DATE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {row.original.datetime}
      </Text>
    ),
  },
  {
    accessorKey: 'user',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap text-grey-600">
        USER
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {row.original.user ? row.original.user.full_name : 'System'}
      </Text>
    ),
  },
  {
    accessorKey: 'field',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap text-grey-600">
        FIELD
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {snakeToCamelWithSpaces(row.original.field)}
      </Text>
    ),
  },
  {
    accessorKey: 'original_value',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap text-grey-600">
        ORIGINAL VALUE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {row.original.original_value}
      </Text>
    ),
  },
  {
    accessorKey: 'new_value',
    header: () => (
      <Text variant="size-12" type="body-600" className="whitespace-nowrap text-grey-600">
        NEW VALUE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {row.original.new_value}
      </Text>
    ),
  },
];
