import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { AccessDenied } from '@purple/ui';
import { useSchoolDataCalendar, useSchoolDataStats } from '~/queries';
import { AttendanceCalendar } from './AttendanceCalendar';
import { AttendanceChart } from './AttendanceChart';
import { AttendanceOverYear } from './AttendanceOverYear';
import { SchoolFlagType } from './constants';
import { SchoolDataHeader } from './SchoolDataHeader';
import { SchoolStats } from './SchoolStats';
import { StudentsMetrics } from './StudentsMetrics';
import type { TSchoolFlagType } from './constants';

export const SchoolData: React.FC = () => {
  const [selectedFlag, setSelectedFlag] = useState<TSchoolFlagType>(SchoolFlagType.ATTENDANCE);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState<string>(new Date().getMonth().toString());

  const { hasPermissions } = usePermissions();
  const [schoolQueryParameters] = useSearchParams();

  const hasUserPermission = useMemo(
    () => hasPermissions(APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_SCHOOLS),
    [hasPermissions],
  );

  const { data: schoolStats, isFetching } = useSchoolDataStats({
    year: selectedYear,
    enabled: hasUserPermission,
    school: schoolQueryParameters.get('school'),
    grade: schoolQueryParameters.get('grade'),
    gender: schoolQueryParameters.get('gender'),
    current_color: schoolQueryParameters.get('current_color'),
    ethnicity: schoolQueryParameters.get('ethnicity'),
    student_status: schoolQueryParameters.get('student_status'),
    action: schoolQueryParameters.get('action'),
    subpopulation: schoolQueryParameters.get('subpopulation'),
  });
  const { data: schoolCalendar, isFetching: isFetchingCalendar } = useSchoolDataCalendar({
    month: (Number.parseInt(selectedMonth, 10) + 1).toString(),
    year: selectedYear,
    enabled: hasUserPermission,
    school: schoolQueryParameters.get('school'),
    grade: schoolQueryParameters.get('grade'),
    gender: schoolQueryParameters.get('gender'),
    current_color: schoolQueryParameters.get('current_color'),
    ethnicity: schoolQueryParameters.get('ethnicity'),
    student_status: schoolQueryParameters.get('student_status'),
    action: schoolQueryParameters.get('action'),
    subpopulation: schoolQueryParameters.get('subpopulation'),
  });

  const flagChangeHandler = useCallback((flag: TSchoolFlagType) => {
    setSelectedFlag(flag);
  }, []);

  const dateChangeHandler = useCallback((date: Date) => {
    setSelectedMonth(date.getMonth().toString());
    setSelectedYear(date.getFullYear().toString());
  }, []);

  const yearChangeHandler = useCallback((year: string) => {
    setSelectedYear(year);
  }, []);

  if (!hasUserPermission) {
    return (
      <div className="flex w-full flex-col px-4 pb-8 pt-6">
        <AccessDenied />
      </div>
    );
  }

  return (
    <section className="grid w-full gap-6 px-4 pb-8 pt-6">
      <SchoolDataHeader
        selectedFlag={selectedFlag}
        selectedYear={selectedYear}
        onYearChange={yearChangeHandler}
        onFlagChange={flagChangeHandler}
      />
      <SchoolStats data={schoolStats?.attendance_trends} isLoading={isFetching} />
      <AttendanceOverYear data={schoolStats?.schools_metrics_by_month} isLoading={isFetching} />
      <div className="grid grid-cols-3 gap-6">
        <AttendanceCalendar
          data={schoolCalendar}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          isLoading={isFetchingCalendar}
          onDateChange={dateChangeHandler}
        />
        <AttendanceChart data={schoolStats?.severe_satisfactory_attendance_chart} isLoading={isFetching} />
      </div>
      <StudentsMetrics data={schoolStats?.schools_metrics_by_month} isLoading={isFetching} />
    </section>
  );
};
