import { forwardRef, useImperativeHandle, useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import { showErrorToast } from '~/shared/lib';
import type { ReactNode } from 'react';

const DEFAULT_FILE_NAME = 'downloaded-content';
const DEFAULT_SCALE = 1;

type DownloadableWrapperProperties = {
  children: ReactNode;
  fileName?: string;
};

type DownloadOptions = {
  scale?: number;
};

export type DownloadableWrapperReference = {
  downloadImage: (options?: DownloadOptions) => void;
};

const DownloadableWrapper = forwardRef<DownloadableWrapperReference, DownloadableWrapperProperties>(
  ({ fileName = DEFAULT_FILE_NAME, children }, reference) => {
    const wrapperReference = useRef<HTMLDivElement>(null);

    useImperativeHandle(reference, () => ({
      downloadImage: (options) => {
        const { scale = DEFAULT_SCALE } = options || {};

        if (wrapperReference.current) {
          htmlToImage
            .toPng(wrapperReference.current, {
              width: wrapperReference.current.clientWidth * scale,
              height: wrapperReference.current.clientHeight * scale,
              style: {
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: `${wrapperReference.current.clientWidth}px`,
                height: `${wrapperReference.current.clientHeight}px`,
              },
            })
            .then((dataUrl) => {
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = `${fileName}.png`;
              link.click();
            })
            .catch(() => {
              showErrorToast('System Message', 'Failed to download the content');
            });
        }
      },
    }));

    return <div ref={wrapperReference}>{children}</div>;
  },
);

DownloadableWrapper.displayName = 'DownloadableWrapper';

export { DownloadableWrapper };
