import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafReplyEmailPayload, TSafReplyEmailResponse } from '@purple/shared-types';

export const replySafEmail = async (payload: TSafReplyEmailPayload) => {
  const { safId, correspondenceId, message_html, message_json, attachments } = payload;

  const formData = new FormData();
  formData.append('message_html', message_html);
  formData.append('message_json', message_json);

  for (const file of attachments) {
    formData.append('attachments', file);
  }

  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.post<TSafReplyEmailResponse>(
    replaceUrlParameters(SAF_ENDPOINTS.REPLY_CORRESPONDENTS_BY_ID, safId, correspondenceId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};
