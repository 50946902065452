import { FormControl, FormField, FormItem, FormLabel, FormMessage, Textarea } from '@purple/ui';
import type { UseFormReturn } from 'react-hook-form';

type TDefaultStudentsIdFormProperties = React.PropsWithChildren<{
  form: UseFormReturn<
    {
      studentIds: string[];
      manualStudentIds: string[];
      mode: 'default' | 'manual';
    },
    any,
    undefined
  >;
}>;

const DefaultStudentsIdForm = ({ form }: TDefaultStudentsIdFormProperties) => {
  return (
    <FormField
      control={form.control}
      name="studentIds"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Student IDs</FormLabel>
          <FormControl>
            <Textarea
              {...field}
              isError={!!form.formState.errors.studentIds}
              placeholder="Enter IDs here"
              className="min-h-[100px] resize-none"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export { DefaultStudentsIdForm };
