import { Skeleton } from '@purple/ui';

export const BarChartSkeleton = () => {
  return (
    <div className="col-span-2 flex flex-col gap-6 rounded-lg px-4 py-6 shadow-custom-medium">
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-[24px] w-1/2" />
        <Skeleton className="h-[32px] w-[32px]" />
      </div>
      <Skeleton className="h-[250px] w-full shrink-0 rounded-lg" />
    </div>
  );
};
