import { Skeleton } from '@purple/ui';

export const AttendanceChartSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-8 rounded-lg border border-grey-200 bg-white px-4 py-6 pb-4 shadow-custom-medium">
      <Skeleton className="h-[24px] w-[280px]" />
      <div className="flex w-full justify-center">
        <Skeleton className="h-[185px] w-[185px] rounded-full" />
      </div>
      <div className="flex w-full flex-col gap-3">
        <Skeleton className="h-[20px] w-full" />
        <Skeleton className="h-[20px] w-full" />
        <Skeleton className="h-[20px] w-full" />
        <Skeleton className="h-[20px] w-full" />
        <Skeleton className="h-[20px] w-full" />
      </div>
    </div>
  );
};
