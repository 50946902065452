import { useMemo } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PurpleIcon } from '@purple/icons';
import { cn, InfoItem, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { TUserAccountDetails } from '@purple/shared-types';

type TDetailsViewProperties = {
  data: TUserAccountDetails;
};

const DetailsView = ({ data }: TDetailsViewProperties) => {
  const { title, email, phone_number, company_name, department, change_email_request } = data;

  const formatPhoneNumber = (phoneNumber: string | null) => {
    // Parse the phone number string
    if (!phoneNumber) return '–';
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

    if (parsedNumber) {
      // Format the number as (XXX) XXX-XXXX
      return parsedNumber.formatNational();
    }

    return phoneNumber;
  };

  const isChangeEmailRequestExist = useMemo(() => Boolean(change_email_request), [change_email_request]);

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Title">
        <InfoItemValue>{title}</InfoItemValue>
      </InfoItem>
      <div className="flex flex-row items-start justify-between">
        <div className="flex items-center gap-2">
          <Text variant="size-16" type="body-400" className="text-grey-600">
            Email
          </Text>
          {isChangeEmailRequestExist && (
            <Tooltip delayDuration={300}>
              <TooltipTrigger>
                <PurpleIcon name="exclamation" className="h-4 min-h-4 w-4 min-w-4 text-warning-dark" />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="center">Please verify your updated email.</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )}
        </div>
        <InfoItemValue
          className={cn({
            'text-warning-dark': isChangeEmailRequestExist,
          })}
        >
          {email}
        </InfoItemValue>
      </div>
      <InfoItem label="Phone Number">
        <InfoItemValue>{formatPhoneNumber(phone_number)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Company Name">
        <InfoItemValue>{company_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Department">
        <InfoItemValue>{department}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { DetailsView };
