import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useSort } from '@purple/hooks';
import {
  AppFilters,
  AppSelectedFiltersList,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  TableFooterSection,
} from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useSafLinkedActions } from '~/queries';
import { SAF_LINKED_ACTIONS_FILTERS, SAF_LINKED_ACTIONS_SORT_OPTIONS } from './constants';
import { linkedSafActionsColumns } from './linkedSafActionsColumns';

export const LinkedSafActionsSection = () => {
  const { safId } = useParams();
  const [searchParameters] = useSearchParams();

  const { limit, offset, onLimitChange, onPageChange, page } = usePaginate();
  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();
  const { onSortChange, onSortClear, sort } = useSort();

  const { data, isLoading } = useSafLinkedActions({
    safId: safId as string,
    requestQueryParameters: {
      limit,
      offset,
      search: debounceSearch,
      ordering: sort,
      document_as: searchParameters.get('document_as'),
    },
  });

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);
  const isDataEmpty = useMemo(() => !data?.results.length && !isLoading, [data?.results, isLoading]);
  const totalLinkedActionsAmount = useMemo(() => data?.count || 0, [data?.count]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 px-4">
        <Heading variant="size-18" type="heading-600">
          Actions
          {' '}
          <span className="text-sm text-grey-600">
            (
            {totalLinkedActionsAmount}
            )
          </span>
        </Heading>
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-4">
            <AppFilters config={SAF_LINKED_ACTIONS_FILTERS} />
            <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
              <RadixSelectTrigger
                hasClearButton={hasSortValue}
                onClearCallback={onSortClear}
                triggerContainerClassName="w-[200px]"
              >
                <RadixSelectValue placeholder="Sort" />
              </RadixSelectTrigger>
              <RadixSelectContent className="w-max">
                {SAF_LINKED_ACTIONS_SORT_OPTIONS.map((option) => (
                  <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                    {option.label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          </div>
          <SearchInput
            value={search}
            onClear={onClearSearch}
            onChange={onSearchChange}
            placeholder="Search by action name"
            className="max-w-[350px]"
          />
        </div>
        <AppSelectedFiltersList config={SAF_LINKED_ACTIONS_FILTERS} />
      </div>

      <div className="flex w-full flex-col">
        <DataTable
          columns={linkedSafActionsColumns}
          data={data?.results || []}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          classNames={{ cell: 'py-3 only:py-0' }}
          emptyStateTitle="Purple Users Participants not found"
          emptyStateMessage="There are no participants to display. If an error occurs, please contact support."
          skeleton={<DataTableSkeleton rows={limit} rowClassName="py-4" />}
        />
        {!isDataEmpty && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </div>
  );
};
