import { useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, TableFooterSection } from '@purple/ui';
// hooks
import { usePaginate } from '@purple/hooks';
import { useModal } from '~/hooks';
import { useNotesList } from '~/queries';
// helpers
import { ModalType } from '~/constants/modals';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { DeleteActionNoteDialog } from '../../components';
import { useActionNotesColumns } from '../useActionNotesColumns';
import { ActionAddNoteDialog } from './ActionAddNoteDialog';
import { NotePreviewDialog } from './NotePreviewDialog';
// types
import type React from 'react';
import type { TActionDetails, TNote } from '@purple/shared-types';

type TNotesSectionProperties = {
  action: TActionDetails;
};

export const NotesSection: React.FC<TNotesSectionProperties> = (props) => {
  const { action } = props;

  const [selectedNote, setSelectedNote] = useState<TNote | null>(null);

  const { openModal: openNotePreviewModal } = useModal(ModalType.ACTION_NOTE);
  const { openModal: openAddNoteModal } = useModal(ModalType.ACTION_ADD_NOTE);
  const { openModal: openDeleteNoteModal } = useModal(ModalType.DELETE_ACTION_NOTE);

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { data, isFetching } = useNotesList({
    action: action.id,
    limit,
    offset,
  });

  const notes = useMemo(() => data?.results || [], [data]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => notes.length > 0 && !isFetching,
    [notes, isFetching],
  );

  const noteRowClickHandler = (note: TNote) => {
    setSelectedNote(note);
    openNotePreviewModal();
  };

  const deleteNoteClickHandler = (note: TNote) => {
    setSelectedNote(note);
    openDeleteNoteModal();
  };

  const notesColumns = useActionNotesColumns({
    actionType: action.record_action_type,
    onDelete: deleteNoteClickHandler,
  });

  return (
    <section className="flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white shadow-custom-light">
      <div className="flex w-full items-center justify-between gap-4 px-4 pt-6">
        <Heading tag="h2" className="text-lg font-semibold text-grey-title">
          Notes
        </Heading>
        <Button type="button" iconLeft={<PurpleIcon name="plus" />} onClick={openAddNoteModal}>
          Add Note
        </Button>
      </div>
      <div className="flex w-full flex-col">
        <DataTable
          columns={notesColumns}
          data={notes}
          isLoading={isFetching}
          emptyStateTitle="Notes Not Found"
          emptyStateMessage="There are no notes to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          classNames={{
            root: 'table-fixed',
            cell: 'py-3 h-14',
            wrapper: 'mb-0',
            emptyCell: 'py-8',
          }}
          onRowClick={noteRowClickHandler}
        />
        {isDataAvailable && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <NotePreviewDialog note={selectedNote} action={action} />
      <ActionAddNoteDialog action={action} />
      <DeleteActionNoteDialog noteId={selectedNote?.id} />
    </section>
  );
};
