import { useMemo } from 'react';
import { cn, Text } from '@purple/ui';

type TCellUsersAccessStatusProperties = {
  isActive: boolean;
};

export const CellUsersAccessStatus = ({ isActive }: TCellUsersAccessStatusProperties) => {
  const statusText = useMemo(() => (isActive ? 'Active' : 'Inactive'), [isActive]);
  return (
    <div
      className={cn('w-max rounded-full bg-grey-100 px-2.5 py-1', {
        'bg-success-bg': isActive,
      })}
    >
      <Text variant="size-14" type="body-500" className={cn('text-grey-900', { 'text-success-main': isActive })}>
        {statusText}
      </Text>
    </div>
  );
};
