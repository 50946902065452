import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionGroupHistory } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TGroupActionHistoryRequestParameters } from '@purple/shared-types';

export const useActionGroupHistory = ({ groupActionId, ...parameters }: TGroupActionHistoryRequestParameters) => {
  const { isError, ...props } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_GROUP_ACTION_HISTORY, groupActionId, parameters],
    queryFn: () => getActionGroupHistory({ groupActionId, ...parameters }),
    enabled: !!groupActionId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch action group history');
    }
  }, [isError]);

  return { ...props, isError };
};
