import { MATCH_NEWLINE } from '../regex';

/**
 * Converts a string into a JSON representation compatible with a rich editor.
 * @param string The input string to convert.
 * @returns The JSON string representing the converted input string.
 */
export const convertStringToEditorJson = (string: string): string => {
  const initialStructure = { type: 'doc', content: [] };

  const dividedString = string.split(MATCH_NEWLINE).filter((item) => item !== '');
  const resultString = dividedString.map((item) =>
    item === '\n'
      ? {
          type: 'paragraph',
          attrs: {
            textAlign: 'left',
          },
        }
      : {
          type: 'paragraph',
          attrs: {
            textAlign: 'left',
          },
          content: [
            {
              type: 'text',
              text: item,
            },
          ],
        },
  );

  return JSON.stringify({ ...initialStructure, content: resultString });
};
