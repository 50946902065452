import { cn, Text } from '@purple/ui';

type TCellApprovedStatusProperties = {
  isApproved: boolean;
};

export const CellApprovedStatus = ({ isApproved }: TCellApprovedStatusProperties) => {
  const statusText = isApproved ? 'Approved' : 'Not Approved';
  return (
    <div className={cn('w-max rounded-full bg-error-bg px-2.5 py-1', isApproved && 'bg-success-bg')}>
      <Text variant="size-14" type="body-500" className={cn('text-error-main', isApproved && 'text-success-main')}>
        {statusText}
      </Text>
    </div>
  );
};
