import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';

type TStudentRowOptionsProperties = {
  canRemove: boolean;
  isChartDisabled: boolean;
  onShowChart: () => void;
  onRemove: () => void;
};

const StudentRowOptions = ({ canRemove, onShowChart, onRemove, isChartDisabled }: TStudentRowOptionsProperties) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button iconLeft={<PurpleIcon name="dots-vertical" />} variant="tertiary_icon_only" size="icon_32" />
      </DropdownTrigger>
      <DropdownContent className="max-w-[210px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={onShowChart}
          className="w-full justify-start text-grey-950 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="chart-bar" />}
          disabled={isChartDisabled}
        >
          Color Trends Details
        </Button>
        {canRemove && (
          <Button
            variant="link"
            className="w-full justify-start text-error-main hover:text-error-main active:text-error-main"
            onClick={onRemove}
            iconLeft={<PurpleIcon name="trash" />}
          >
            Remove Student
          </Button>
        )}
      </DropdownContent>
    </DropdownRoot>
  );
};

export { StudentRowOptions };
