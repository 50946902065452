import { useMemo } from 'react';
import { ACTIVITIES_PERMISSIONS, usePermissions } from '@purple/permissions';
import { ECO_SYSTEM_EVENT_TYPES } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import type { TFiltersConfig } from '@purple/ui';

export const useEcoSystemFilters = () => {
  const { hasPermissions } = usePermissions();
  const filterOptions = useMemo(() =>
    ({
      categories: [
        {
          label: 'Action Type',
          value: 'type',
          filters: [
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.ACADEMIC_CAREER_PLANNING_SESSION),
              value: ECO_SYSTEM_EVENT_TYPES.ACADEMIC_CAREER_PLANNING_SESSION,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.CHECK_IN),
              value: ECO_SYSTEM_EVENT_TYPES.CHECK_IN,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.COUNSELING_SESSION),
              value: ECO_SYSTEM_EVENT_TYPES.COUNSELING_SESSION,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.DISTRICT_INITIATIVE),
              value: ECO_SYSTEM_EVENT_TYPES.DISTRICT_INITIATIVE,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.GUIDANCE_COUNSELING_LESSON),
              value: ECO_SYSTEM_EVENT_TYPES.GUIDANCE_COUNSELING_LESSON,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.OBSERVATION),
              value: ECO_SYSTEM_EVENT_TYPES.OBSERVATION,
            },
            {
              label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.STAKEHOLDER_COLLABORATIVE_MEETING),
              value: ECO_SYSTEM_EVENT_TYPES.STAKEHOLDER_COLLABORATIVE_MEETING,
            },
            ...(hasPermissions([ACTIVITIES_PERMISSIONS.CAN_ACCESS_CRISIS_TEAM_VIEW])
              ? [{
                  label: snakeCaseToCapitalized(ECO_SYSTEM_EVENT_TYPES.CRISIS_RESPONSE_SESSION),
                  value: ECO_SYSTEM_EVENT_TYPES.CRISIS_RESPONSE_SESSION,
                }]
              : []),
            {
              label: 'Group Action',
              value: ECO_SYSTEM_EVENT_TYPES.GROUP_ACTION,
            },
            {
              label: 'Community Activity',
              value: ECO_SYSTEM_EVENT_TYPES.COMMUNITY_ACTIVITY,
            },
            {
              label: 'One Time Session Meeting',
              value: ECO_SYSTEM_EVENT_TYPES.ONE_TIME_SESSION_MEETING,
            },
            {
              label: 'Campus Recurring Session',
              value: ECO_SYSTEM_EVENT_TYPES.RECURRING_SESSION_MEETING,
            },
          ],
        },
        {
          label: 'Meeting Statuses',
          value: 'meeting_status',
          filters: [
            {
              label: 'In Queue to Complete',
              value: 'in_queue_to_complete',
            },
            {
              label: 'Attendance Overdue',
              value: 'attendance_overdue',
            },
            {
              label: 'Upcoming (Scheduled)',
              value: 'upcoming',
            },
            {
              label: 'Completed',
              value: 'completed',
            },
            {
              label: 'Cancelled',
              value: 'cancelled',
            },
          ],
        },
      ],
    } satisfies TFiltersConfig), [hasPermissions]);

  return { filterOptions };
};
