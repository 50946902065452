import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Text } from '@purple/ui';

type TCellUserAccessPhoneProperties = {
  phone: string | null;
};

export const CellUserAccessPhone = ({ phone }: TCellUserAccessPhoneProperties) => {
  const formatPhoneNumber = (phoneNumber: string | null) => {
    // Parse the phone number string
    if (!phoneNumber) return '–';
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');

    if (parsedNumber) {
      // Format the number as (XXX) XXX-XXXX
      return parsedNumber.formatNational();
    }

    return phoneNumber;
  };
  return (
    <Text variant="size-14" type="body-500" className="text-grey-950">
      {formatPhoneNumber(phone)}
    </Text>
  );
};
