import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { format, parseISO } from 'date-fns';
import { format as formatLocale } from 'date-fns/format';
import { getDay as getDayLocale } from 'date-fns/getDay';
import { enUS } from 'date-fns/locale/en-US';
import { parse as parseLocale } from 'date-fns/parse';
import { startOfWeek as startOfWeekLocale } from 'date-fns/startOfWeek';
import { useLazyTimeout } from '@purple/hooks';
import { cn } from '@purple/ui';
import { getDataViewByCalendarView } from '../utils';
import { CalendarEvent } from './CalendarEvent';
import { CustomDateCellWrapper } from './MonthCalendarCell';
import type { View } from 'react-big-calendar';
import type { IUserEvent, TEcoSystemActionsTypes } from '@purple/shared-types';
import type { TEcoSystemDataViewValues } from '../constants';

export type ICalendarEvent = {
  eventId: string;
  relatedOjectId: string;
  title: string;
  start: Date;
  end: Date;
  eventType: TEcoSystemActionsTypes;
};

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format: formatLocale,
  parse: parseLocale,
  startOfWeek: startOfWeekLocale,
  getDay: getDayLocale,
  locales,
});

type TEcosystemCalendarViewProperties = {
  currentDate: Date;
  calenDarView: View;
  data?: IUserEvent[];
  onChangeSelectedEventId: (id: string | null) => void;
  onDataViewChange: (value: TEcoSystemDataViewValues) => void;
  onChangeCalendarView: (value: View) => void;
  onChangeDate: (date: Date) => void;
};

export const EcosystemCalendarView = ({
  data,
  currentDate,
  calenDarView,
  onChangeSelectedEventId,
  onDataViewChange,
  onChangeCalendarView,
  onChangeDate,
}: TEcosystemCalendarViewProperties) => {
  const { timeout } = useLazyTimeout(100);
  const calendarReference = useRef<Calendar<ICalendarEvent>>(null);

  const calendarEvents = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((event) => ({
      eventId: event.id,
      relatedOjectId: event.related_object_id,
      title: event.title,
      start: parseISO(event.start_time),
      end: parseISO(event.end_time ?? new Date().toISOString()),
      eventType: event.type as TEcoSystemActionsTypes,
    }));
  }, [data]);

  const calendarViewChangeHandler = useCallback(
    (newView: View) => {
      onDataViewChange(getDataViewByCalendarView(newView));
      onChangeCalendarView(newView);
    },
    [onDataViewChange, onChangeCalendarView],
  );

  useEffect(() => {
    if (!calendarReference.current) return;
    timeout(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');
      if (currentTimeIndicator) {
        currentTimeIndicator.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarReference.current, calenDarView]);

  return (
    <Calendar
      ref={calendarReference}
      date={currentDate}
      defaultView={calenDarView}
      view={calenDarView}
      toolbar={false}
      events={calendarEvents}
      // from 6am
      min={new Date(0, 0, 0, 6)}
      startAccessor="start"
      endAccessor="end"
      onNavigate={onChangeDate}
      onView={calendarViewChangeHandler}
      components={{
        event: (props) => <CalendarEvent {...props} onChangeSelectedEventId={onChangeSelectedEventId} />,
        dateCellWrapper: CustomDateCellWrapper,
      }}
      className={cn(
        'h-[525px]',
        '[&_.rbc-event-label]:hidden [&_.rbc-event]:border-none',
        '[&_.rbc-event-content]:h-full',
        '[&_.rbc-today]:bg-brand-blue-50',
        '[&_.rbc-allday-cell]:hidden',
        '[&_.rbc-time-header-cell]:p-0',
        '[&_.rbc-time-header-gutter]:border-b [&_.rbc-time-header-gutter]:border-[#ddd]',
        calenDarView === Views.DAY && '[&_.rbc-time-header]:hidden',
        '[&_.rbc-label &_.rbc-time-header-gutter]:w-[45px]',
        '[&_.rbc-time-content]:border-t-0',
        '[&_.rbc-label]:font-primary [&_.rbc-label]:text-xs',
        '[&_.rbc-current-time-indicator]:h-0.5 [&_.rbc-current-time-indicator]:rounded-full [&_.rbc-current-time-indicator]:bg-error-main [&_.rbc-current-time-indicator]:opacity-60',
        '[&_.rbc-button-link]:w-full [&_.rbc-button-link]:text-left [&_.rbc-button-link]:font-primary [&_.rbc-button-link]:text-base [&_.rbc-button-link]:font-medium',
        '[&_.rbc-header.rbc-today]:bg-brand-blue-500',
        '[&_.rbc-header.rbc-today_.rbc-button-link]:text-white',
        '[&_.rbc-time-slot]:h-10 [&_.rbc-time-slot]:flex-none',
        '[&_.rbc-button-link.rbc-show-more]:text-sm',
        '[&_.rbc-event:active]:outline-none [&_.rbc-event:focus]:outline-none',
      )}
      localizer={localizer}
      formats={{
        timeGutterFormat: 'h a',
        dayFormat: (date, culture, locale) => locale?.format(date, 'EEE d', culture) ?? format(date, 'EEE d'),
        weekdayFormat: (date, culture, locale) => locale?.format(date, 'EEE', culture) ?? format(date, 'EEE'),
      }}
    />
  );
};
