import { useLocation, useNavigate } from 'react-router-dom';
import { match } from 'path-to-regexp';
import { CLIENT_ERROR_STATUS_CODES, createApiInstance, getAxiosInstance } from '@purple/shared-services';
import { AppRoutes, WHITE_LIST_ROUTES } from '~/constants/routes/routes';
import { useAppDispatch } from '~/hooks';
import { resetApp } from '~/store';

type IApiProviderProperties = {
  children: React.ReactNode;
};
export const ApiProvider = ({ children }: IApiProviderProperties) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  createApiInstance({
    baseURL: import.meta.env.VITE_API_URL,
    interceptorsErrorCallback: (statusCode: number) => {
      // Reset the hole app state
      dispatch(resetApp());
      // Full clear the local storage
      localStorage.clear();

      const isPublicRoute = WHITE_LIST_ROUTES.some((route) => {
        const matcher = match(route);
        const matchResult = matcher(location.pathname);
        return !!matchResult;
      });

      if (statusCode === CLIENT_ERROR_STATUS_CODES.UNAUTHORIZED && !isPublicRoute) {
        // Redirect to the login page
        navigate(AppRoutes.Auth.SignIn.Root.path);
      }
    },
  });

  const instance = getAxiosInstance();

  if (!instance) {
    throw new Error('Axios instance is not created');
  }

  return children;
};
