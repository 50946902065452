import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const deleteAction = async (actionId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.delete<void>(replaceUrlParameters(ACTIONS.ACTION_DETAILS, actionId));

  return response.data;
};
