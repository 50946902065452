import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
// hooks
import { usePaginate } from '@purple/hooks';
import { useModal } from '~/hooks';
import { useActionLinkedStudents } from '~/queries';
// helpers
import { ModalType } from '~/constants/modals';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { DeleteGroupActionStudentDialog } from './DeleteGroupActionStudentDialog';
import { GroupActionLinkedStudentsHeader } from './GroupActionLinkedStudentsHeader';
import { useGroupActionStudentsColumns } from './useGroupActionStudentsColumns';
// types
import type React from 'react';
import type { TActionLinkedStudent, TGroupActionDetails } from '@purple/shared-types';

type TGroupActionLinkedStudentsProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionLinkedStudents: React.FC<TGroupActionLinkedStudentsProperties> = (props) => {
  const { groupAction } = props;

  const [searchParameters] = useSearchParams();

  const [selectedStudent, setSelectedStudent] = useState<TActionLinkedStudent | null>(null);

  const { openModal: openDeleteStudentModal } = useModal(ModalType.DELETE_GROUP_ACTION_LINKED_STUDENT);

  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { data, isLoading } = useActionLinkedStudents({
    limit,
    offset,
    search,
    ordering: sort,
    groupActionId: groupAction.id,
    grade: searchParameters.get('grade'),
    gender: searchParameters.get('gender'),
    current_color: searchParameters.get('current_color'),
    actions: searchParameters.get('actions'),
    student_status: searchParameters.get('student_status'),
    ethnicity: searchParameters.get('ethnicity'),
    priority_list: searchParameters.get('priority_list'),
    subpop: searchParameters.get('subpop'),
    school: searchParameters.get('school'),
  });
  const students = useMemo(() => data?.results || [], [data?.results]);

  const isDataNotExist = useMemo(() => students.length === 0 && !isLoading, [students, isLoading]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  const deleteStudentClickHandler = (student: TActionLinkedStudent) => {
    setSelectedStudent(student);
    openDeleteStudentModal();
  };

  const studentColumns = useGroupActionStudentsColumns({
    groupAction,
    onDelete: deleteStudentClickHandler,
  });

  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4 px-4 pt-6">
        <Heading tag="h2" className="text-lg font-semibold text-grey-title">
          Linked Students
          {' '}
          {data?.count !== undefined && (
            <small className="text-xs font-semibold text-grey-600">
              (
              {data.count}
              )
            </small>
          )}
        </Heading>
        <GroupActionLinkedStudentsHeader />
      </div>
      <div className="flex w-full flex-col">
        <DataTable
          columns={studentColumns}
          data={students}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="Linked students Not Found"
          emptyStateMessage="There are no students to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          classNames={{ root: 'table-fixed', cell: 'py-3 h-14' }}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <DeleteGroupActionStudentDialog groupAction={groupAction} student={selectedStudent} />
    </section>
  );
};
