import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IPaginatedResponse, IStudentGrade, IStudentGradesListRequest } from '@purple/shared-types';

export const getStudentGradesList = async ({ studentId, requestParameters }: IStudentGradesListRequest) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<IPaginatedResponse<IStudentGrade[]>>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_GRADES_LIST, studentId),
    {
      params: requestParameters,
    },
  );
  return response.data;
};
