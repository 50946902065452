import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafListManager } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSafListManagerRequestParameters } from '@purple/shared-types';

export const useSafListManager = (queryParameters: TSafListManagerRequestParameters) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER, { queryParams: queryParameters }],
    queryFn: () => getSafListManager(queryParameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch saf list manager');
    }
  }, [isError]);

  return { data, isLoading };
};
