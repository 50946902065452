import { useEffect, useMemo, useState } from 'react';
import { InfoItem } from '@purple/ui';
import { AddressComboBox, MapComponent } from '~/components';
import type { UseFormReturn } from 'react-hook-form';
import type { TLocation, TSchoolAddress, TSchoolInfo } from '@purple/shared-types';

type TAddressEditProperties = {
  data: TSchoolInfo;
  form: UseFormReturn<Partial<TSchoolAddress>>;
};

const AddressEdit = ({ data, form }: TAddressEditProperties) => {
  const [location, setLocation] = useState<TLocation | null>(data.address?.address || null);

  useEffect(() => {
    if (data.address?.address) {
      setLocation(data.address.address);
    }
  }, [data]);

  useEffect(() => {
    form.setValue('latitude', location?.latitude);
    form.setValue('longitude', location?.longitude);
    form.setValue('street', location?.street);
    form.setValue('city', location?.city);
    form.setValue('state', location?.state);
    form.setValue('postal_code', location?.postal_code);
  }, [form, location]);

  const isAddressExist = useMemo(() => Boolean(location), [location]);

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Address" className="flex-col gap-2" contentClassName="w-full max-w-full">
        <AddressComboBox location={location} onChange={(newAddress) => setLocation(newAddress)} />
      </InfoItem>
      {isAddressExist && <MapComponent location={location as TLocation} />}
    </div>
  );
};

export { AddressEdit };
