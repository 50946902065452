import { APP_PERMISSIONS } from '@purple/permissions';
import { AccountHistory } from './AccountHistory';
import { DashboardContainer } from './Dashboard/DashboardContainer';
import { FilesTab } from './Files';
import { Grades } from './Grades';
import { Incidents } from './Incidents';
import { NotesTab } from './Notes';
import { TrendAndFlags } from './TrendAndFlags/TrendAndFlags';
import type { TAppTab } from '@purple/ui';

export const studentDetailsTabs: TAppTab[] = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    content: <DashboardContainer />,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_ATTENDANCE_TRENDS_AND_CIRCLE_OF_SUPPORT_VISUALS,
      APP_PERMISSIONS.CAN_ACCESS_SAFS_AND_ACTIONS_STATISTICS,
    ],
  },
  {
    label: 'Trends & Flags',
    value: 'trends_flags',
    content: <TrendAndFlags />,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_COLOR_TRENDS_STATISTICS,
      APP_PERMISSIONS.CAN_ACCESS_SAFS_AND_ACTIONS_STATISTICS,
    ],
  },
  {
    label: 'Grades',
    value: 'grades',
    content: <Grades />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_GRADES],
  },
  {
    label: 'Notes',
    value: 'notes',
    content: <NotesTab />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_NOTES],
  },
  {
    label: 'Files',
    value: 'files',
    content: <FilesTab />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_ATTACHED_FILES],
  },
  {
    label: 'Account History',
    value: 'account_history',
    content: <AccountHistory />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_HISTORY],
  },
  {
    label: 'Incidents',
    value: 'incidents',
    content: <Incidents />,
    permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_INCIDENTS],
  },
];
