import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { currentUserProfile } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertProfileDTOToProfile } from './converter';

export const useProfile = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE],
    queryFn: currentUserProfile,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch user profile information');
    }
  }, [isError]);

  return { data: data ? convertProfileDTOToProfile(data) : data, isLoading };
};
