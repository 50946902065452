import { useQueryClient } from '@tanstack/react-query';
import { formatDate, formatDateWithTime, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import type { TSafDetailsDto } from '@purple/shared-types';

type TSafStatsProperties = {
  safId?: string | null;
};

export const SafStats = ({ safId }: TSafStatsProperties) => {
  const queryClient = useQueryClient();

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { saf_stats } = safDetailsData || {};

  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Created Date & Time</DescriptionTerm>
        <DescriptionDetails>
          {saf_stats && saf_stats.created_at ? formatDateWithTime(saf_stats.created_at) : '—'}
        </DescriptionDetails>
      </DescriptionItem>

      <DescriptionItem>
        <DescriptionTerm>Date & Time Status Changed</DescriptionTerm>
        <DescriptionDetails>
          {saf_stats && saf_stats.status_changed_at ? formatDate(saf_stats.status_changed_at) : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Days in Current Status</DescriptionTerm>
        <DescriptionDetails>{saf_stats?.days_in_current_status ?? '—'}</DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
