import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMask } from '@react-input/mask';
import { PurpleIcon } from '@purple/icons';
import { ActivityType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ReadableActivityType } from '~/constants/create-activity';
import { PHONE_MASK } from '~/constants/phone';
import { useAppSelector, useCreateActivity } from '~/hooks/redux';
import { useActivitiesTypes, useActivityChoices, useCreateNotRecurringActivity } from '~/queries';
import { userSelector } from '~/store/features/user';
import { ActivityContainerModal } from '../ActivityContainerModal';
import { AssignedToComboBox } from './AssignedToComboBox';
import { crisisCallTrackerActivitySchema } from './schema';
import type { z } from 'zod';

type TCrisisCallTrackerModalProperties = {
  onSuccessfulActivity?: () => void;
};

const CrisisCallTrackerModal = ({ onSuccessfulActivity }: TCrisisCallTrackerModalProperties) => {
  const formId = useId();
  const { toggleActivityModal, isCrisisCallTrackerModalOpen } = useCreateActivity();

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const { schools } = useAppSelector(userSelector);

  const { mutate: createActivity, isPending: isCreatingActivity } = useCreateNotRecurringActivity();

  const schoolsOptions = useMemo(() => {
    return schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));
  }, [schools]);

  const { data: activitiesTypes } = useActivitiesTypes();

  const community_activity_type_id = useMemo(() => {
    return activitiesTypes?.find((item) => item.name === ActivityType.CRISIS_CALL_TRACKER)?.id;
  }, [activitiesTypes]);

  const { tagsOptions, campusContactTitlesOptions, crisisTypesOptions, crisisGradesOptions } = useActivityChoices({
    enabled: isCrisisCallTrackerModalOpen,
    community_activity_type: ActivityType.CRISIS_CALL_TRACKER,
  });

  const defaultValues = useMemo(() => {
    const [defaultSchool] = schools;

    return {
      school: schools.length === 1 ? defaultSchool?.id : '',
      name: '',
      date_and_time: new Date(),
      duration: 0,
      campus_contact_name: '',
      campus_contact_title: '',
      contact_phone_number: '',
      contact_email: '',
      crisis_type: [],
      crisis_grade_level: '',
      description_of_crisis: '',
      assigned_to: '',
      tags: [],
    };
  }, [schools]);

  const form = useForm<z.infer<typeof crisisCallTrackerActivitySchema>>({
    resolver: zodResolver(crisisCallTrackerActivitySchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const resetFormHandler = () => {
    toggleActivityModal(null);
    onSuccessfulActivity?.();
    form.reset(defaultValues);
  };

  const submitActivityHandler = (data: z.infer<typeof crisisCallTrackerActivitySchema>) => {
    if (!community_activity_type_id) return;

    const selectedTags = tagsOptions
      .filter((item) => data.tags.includes(item.value))
      .map((item) => ({ name: item.label, color: item.color }));

    const body = {
      community_activity_type: community_activity_type_id,
      name: data.name,
      school: data.school,
      campus_contact_title: data.campus_contact_title,
      contact_phone_number: data.contact_phone_number,
      contact_email: data.contact_email,
      crisis_type: data.crisis_type,
      crisis_grade_level: data.crisis_grade_level,
      description_of_crisis: data.description_of_crisis,
      date_and_time: data.date_and_time.toISOString(),
      ...(data.assigned_to && { assigned_to: data.assigned_to }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
    };

    createActivity(body, {
      onSuccess: () => {
        resetFormHandler();
      },
    });
  };

  return (
    <ActivityContainerModal
      title={ReadableActivityType[ActivityType.CRISIS_CALL_TRACKER]}
      submitButtonLabel="Create"
      formId={formId}
      activityType={ActivityType.CRISIS_CALL_TRACKER}
      isLoading={isCreatingActivity}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitActivityHandler)}
      >
        <FormField
          control={form.control}
          name="school"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>School</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select school"
                    selectedLabel={schoolsOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search school..." emptyContent="School not found.">
                  {schoolsOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Activity Name</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date_and_time"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{ before: new Date() }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="campus_contact_name"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-row items-center gap-1">
                <FormLabel required>Campus Contact Name</FormLabel>
                <Tooltip>
                  <TooltipTrigger>
                    <PurpleIcon name="information-circle" className="h-4 w-4 text-grey-600" />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent align="center" withArrow={false}>
                      Enter the name of the staff member making the call
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </div>

              <FormControl>
                <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="campus_contact_title"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Campus Contact Title</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select school"
                    selectedLabel={campusContactTitlesOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search campus..." emptyContent="Campus not found.">
                  {campusContactTitlesOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="contact_phone_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Contact Phone Number</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, inputReference])}
                  isError={!!form.formState.errors.contact_phone_number}
                  placeholder={PHONE_MASK}
                  type="tel"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="contact_email"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Contact Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.contact_email}
                  placeholder="Enter email here"
                  type="email"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="crisis_type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Crisis Type</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={crisisTypesOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {crisisTypesOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="crisis_grade_level"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Crisis Grade Level</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select level"
                    selectedLabel={crisisGradesOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search level..." emptyContent="Level not found.">
                  {crisisGradesOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description_of_crisis"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Description of Crisis</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.description_of_crisis}
                  placeholder="Enter description text here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="assigned_to"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Assigned to</FormLabel>
              <FormControl>
                <AssignedToComboBox
                  value={field.value || ''}
                  onChange={(value) => form.setValue('assigned_to', value, { shouldValidate: true })}
                  isError={!!fieldState.error}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {tagsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActivityContainerModal>
  );
};

export { CrisisCallTrackerModal };
