import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  InfoItem,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { CITY_COUNCIL_DISTRICT_OPTIONS, TRUSTEE_DISTRICT_REGION_OPTIONS } from '../Details/constants';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateSchoolPoliticalRegionSchema } from '../../schema';

type TPoliticalRegionsEditProperties = {
  form: UseFormReturn<z.infer<typeof updateSchoolPoliticalRegionSchema>>;
};

const PoliticalRegionsEdit = ({ form }: TPoliticalRegionsEditProperties) => {
  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <InfoItem label="Trustee District Region" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="trustee_district_region"
          render={({ field }) => (
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <RadixSelectTrigger size="sm">
                  <RadixSelectValue placeholder="Select Region" />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent>
                {TRUSTEE_DISTRICT_REGION_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          )}
        />
      </InfoItem>
      <InfoItem label="State House District Region" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="texas_house_district_region"
          render={({ field }: { field: any }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.texas_house_district_region}
                  placeholder="Enter District Region"
                  type="number"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="State Board of Education District" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="state_board_of_education_district"
          render={({ field }: { field: any }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.state_board_of_education_district}
                  placeholder="Enter State Board"
                  type="number"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="City Council District" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="city_council_district"
          render={({ field }: { field: any }) => (
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value || undefined}>
              <FormControl>
                <RadixSelectTrigger size="sm">
                  <RadixSelectValue placeholder="Select City Council" />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent>
                {CITY_COUNCIL_DISTRICT_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          )}
        />
      </InfoItem>
      <InfoItem label="State Senate District" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="texas_state_senate_district"
          render={({ field }: { field: any }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.texas_state_senate_district}
                  placeholder="Enter State Senate"
                  type="number"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
    </Form>
  );
};

export { PoliticalRegionsEdit };
