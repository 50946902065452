import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TSchoolOverviewLinkedStudentsRequest, TSchoolOverviewLinkedStudentsResponse } from '@purple/shared-types';

export const getSchoolOverviewLinkedStudents = async ({
  schoolId,
  chart_type,
}: TSchoolOverviewLinkedStudentsRequest) => {
  const axiosInstance = getAxiosInstance();
  const PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOL_OVERVIEW_LINKED_STUDENTS, schoolId);
  const URL_PATH = `${PATH}?chart_type=${chart_type}`;

  const response = await axiosInstance.get<TSchoolOverviewLinkedStudentsResponse>(URL_PATH);

  return response.data;
};
