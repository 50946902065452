import type { ValueOf } from './utils';

export const STUDENT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;
export type TStudentStatus = ValueOf<typeof STUDENT_STATUS>;

export const STUDENT_FAMILY_SIZE = {
  ONE_TO_THREE: '1-3',
  FOUR_TO_FIVE: '4-5',
  SIX_OR_MORE: '6 or more',
} as const;
export type TStudentFamilySize = ValueOf<typeof STUDENT_FAMILY_SIZE>;

export const STUDENT_GUARDIAN = {
  TWO_PARENTS: 'Two parents',
  MOTHER_ONLY: 'Mother only',
  FATHER_ONLY: 'Father only',
  JOINT_CUSTODY: 'One parent at a time (joint custody)',
  OTHER: 'Other (e.g. foster parent, grandparent, other relative, group home)',
} as const;
export type TStudentGuardian = ValueOf<typeof STUDENT_GUARDIAN>;

export const STUDENT_CALENDAR_EVENT_TYPE = {
  ATTENDANCE: 'attendance',
  BEHAVIOR: 'behavior',
  ACTIONS: 'actions',
} as const;

export type TStudentCalendarEventType = ValueOf<typeof STUDENT_CALENDAR_EVENT_TYPE>;

export const STUDENT_CALENDAR_ITEM_TYPE = {
  ATTENDANCE: 'attendance',
  BEHAVIOR: 'behavior',
  ACTIONS: 'actions',
  ALL: 'all',
} as const;

export type TStudentCalendarItemType = ValueOf<typeof STUDENT_CALENDAR_ITEM_TYPE>;

export const STUDENT_CALENDAR_PERIOD = {
  MONTH: 'month',
  YEAR: 'year',
} as const;

export type TStudentCalendarPeriod = ValueOf<typeof STUDENT_CALENDAR_PERIOD>;

export const STUDENT_ATTENDANCE_TYPE = {
  ABSENT: 'Absent',
  HOLIDAY: 'Holiday',
  PARTIAL_PRESENT: 'Partially Present',
  PRESENT: 'Present',
} as const;

export type TStudentCalendarAttendanceType = ValueOf<typeof STUDENT_ATTENDANCE_TYPE>;

export const STUDENT_BEHAVIOR_TYPE = {
  ISS: 'ISS',
  OSS: 'OSS',
  DAEP: 'DAEP',
  Holiday: 'Holiday',
} as const;

export type TStudentCalendarBehaviorType = ValueOf<typeof STUDENT_BEHAVIOR_TYPE>;

export const STUDENT_GRADES_FILTERS = {
  GRADE_LEVEL: 'grade_level',
  SUBJECT: 'subject',
  COURSE_NAME: 'course_name',
  GRADE: 'grade',
  CYCLE: 'cycle',
} as const;

export type TStudentGradesFilter = ValueOf<typeof STUDENT_GRADES_FILTERS>;

export const STUDENT_DATA_FILTERS = {
  GRADE_LEVEL: 'grade',
  GENDER: 'gender',
  CURRENT_COLOR: 'current_color',
  ACTIONS: 'actions',
  STUDENT_STATUS: 'student_status',
  ETHNICITY: 'ethnicity',
  SUBPOPULDATIONS: 'subpop',
};
