import { PurpleIcon } from '@purple/icons';
import { ToggleButton } from '../ToggleButton';
import type { Editor } from '@tiptap/core';

type TTextStyleElement = {
  label: string;
  icon: React.ReactNode;
  action: (editor: Editor) => void;
  isActive: (editor: Editor) => boolean;
};

export const FirstSection = ({ editor }: { editor: Editor }) => {
  const textStyleElements: TTextStyleElement[] = [
    {
      label: 'Bold',
      icon: <PurpleIcon name="Bold" className="size-5 text-grey-600" />,
      action: (_editor) => _editor.chain().focus().toggleBold().run(),
      isActive: (_editor) => _editor.isActive('bold'),
    },
    {
      label: 'Italic',
      icon: <PurpleIcon name="Italic" className="size-5 text-grey-600" />,
      action: (_editor) => _editor.chain().focus().toggleItalic().run(),
      isActive: (_editor) => _editor.isActive('italic'),
    },
    {
      label: 'Strikethrough',
      icon: <PurpleIcon name="Crossed" className="size-5 text-grey-600" />,
      action: (_editor) => editor.chain().focus().toggleStrike().run(),
      isActive: (_editor) => editor.isActive('strike'),
    },
    {
      label: 'Underline',
      icon: <PurpleIcon name="Underline" className="size-5 text-grey-600" />,
      action: (_editor) => editor.chain().focus().toggleUnderline().run(),
      isActive: (_editor) => editor.isActive('underline'),
    },
  ];

  return (
    <div className="flex items-center gap-1 p-0.5">
      {textStyleElements.map((element) => (
        <ToggleButton
          key={element.label}
          isActive={element.isActive(editor)}
          onClick={() => element.action(editor)}
          tooltip={element.label}
          ariaLabel={element.label}
        >
          {element.icon}
        </ToggleButton>
      ))}
    </div>
  );
};
