import { CYCLE_FLAGS_COLOR } from '@purple/shared-types';
import type { ColorRanges } from '@purple/shared-types';

export const lightenColor = (hex: string, percent: number): string => {
  hex = hex.replace(/^#/, '');

  let r = Number.parseInt(hex.slice(0, 2), 16);
  let g = Number.parseInt(hex.slice(2, 4), 16);
  let b = Number.parseInt(hex.slice(4, 6), 16);

  r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100));
  g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100));
  b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100));

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const getColorByRange = (value: number, colorRange: ColorRanges): string => {
  const roundedValue = Math.round(value);
  if (roundedValue >= colorRange.green[0] && roundedValue <= colorRange.green[1]) {
    return CYCLE_FLAGS_COLOR.GREEN;
  }

  if (roundedValue >= colorRange.yellow[0] && roundedValue <= colorRange.yellow[1]) {
    return CYCLE_FLAGS_COLOR.YELLOW;
  }

  if (roundedValue >= colorRange.red[0]) {
    return CYCLE_FLAGS_COLOR.RED;
  }

  return CYCLE_FLAGS_COLOR.GREEN;
};

/**
 * Pads a string value with zeros to a specified length.
 *
 * @param value - The string value to pad with zeros.
 * @param length - The desired length of the resulting string (default: 2).
 * @returns The padded string value.
 */
export const padZero = (value: string, length: number = 2) => {
  const zeros = Array.from({ length }).join('0');
  return (zeros + value).slice(-length);
};

/**
 * Inverts the color represented by the given hexadecimal value.
 *
 * @param hex - The hexadecimal color value to invert.
 * @param blackAndWhite - Whether to return black or white if the color is light (default: false).
 * @returns The inverted color as a hexadecimal string.
 * @throws Error if the provided hex value is invalid.
 */
export const invertColor = (hex: string, blackAndWhite = false) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0]! + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r: string | number = Number.parseInt(hex.slice(0, 2), 16);
  let g: string | number = Number.parseInt(hex.slice(2, 4), 16);
  let b: string | number = Number.parseInt(hex.slice(4, 6), 16);
  if (blackAndWhite) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
};

/**
 * Calculates the luminance of a color given its red, green, and blue components.
 *
 * @param red - The red component of the color (0-255).
 * @param green - The green component of the color (0-255).
 * @param blue - The blue component of the color (0-255).
 * @param gamma - The gamma correction value (default is 2.4).
 * @returns The calculated luminance of the color.
 */
export const luminance = (red: number, green: number, blue: number, gamma = 2.4): number => {
  // sRGB luminance coefficients
  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;

  const [calibratedRed, calibratedGreen, calibratedBlue] = [red, green, blue].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : ((v + 0.055) / 1.055) ** gamma;
  }) as [number, number, number];
  return calibratedRed * RED + calibratedGreen * GREEN + calibratedBlue * BLUE;
};

/**
 * Calculates the contrast ratio between two hexadecimal color values.
 *
 * @param hexA - The first hexadecimal color value (e.g., "#FFFFFF" or "FFFFFF").
 * @param hexB - The second hexadecimal color value (e.g., "#000000" or "000000").
 * @returns The contrast ratio between the two colors.
 *
 * @remarks
 * The function first normalizes the input hex values by removing the leading '#' if present
 * and converting 3-digit hex values to 6-digit hex values. It then converts the hex values
 * to RGB and calculates the luminance of each color. Finally, it computes the contrast ratio
 * using the luminance values.
 */
export const getColorContrast = (hexA: string, hexB: string): number => {
  if (hexA.indexOf('#') === 0) {
    hexA = hexA.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hexA.length === 3) {
    hexA = hexA[0]! + hexA[0] + hexA[1] + hexA[1] + hexA[2] + hexA[2];
  }
  if (hexB.indexOf('#') === 0) {
    hexB = hexB.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hexB.length === 3) {
    hexB = hexB[0]! + hexB[0] + hexB[1] + hexB[1] + hexB[2] + hexB[2];
  }
  const rgbA = [hexA.slice(0, 2), hexA.slice(2, 4), hexA.slice(4, 6)].map((x) => Number.parseInt(x, 16)) as [number, number, number];
  const rgbB = [hexB.slice(0, 2), hexB.slice(2, 4), hexB.slice(4, 6)].map((x) => Number.parseInt(x, 16)) as [number, number, number];

  const lum1 = luminance(...rgbA);
  const lum2 = luminance(...rgbB);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};
