import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoadScript } from '@react-google-maps/api';
import { EditableBlockHeader, Skeleton } from '@purple/ui';
import { useUpdateProfile } from '~/queries';
import { DetailsEdit } from './DetailsEdit';
import { DetailsView } from './DetailsView';
import { updateLocationDetailsSchema } from './schema';
import type { TLocationDetails, TUpdateUserLocationDetails } from '@purple/shared-types';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API;
const libraries: 'places'[] = ['places'];

type TLocationDetailsTabProperties = {
  data: TLocationDetails;
};

const LocationDetailsTab = ({ data }: TLocationDetailsTabProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  const { mutate, isPending, isSuccess } = useUpdateProfile();

  useEffect(() => {
    if (isSuccess) {
      setMode('view');
    }
  }, [isSuccess]);

  const form = useForm<TUpdateUserLocationDetails>({
    resolver: zodResolver(updateLocationDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      language: data.language,
      timezone: data.timezone,
      address: data.address,
    },
  });

  useEffect(() => {
    form.reset({
      language: data.language,
      timezone: data.timezone,
      address: data.address,
    });
  }, [data, form, mode]);

  const handleSave = (formData: TUpdateUserLocationDetails) => {
    mutate({ requestPayload: formData });
  };

  if (!isLoaded) {
    return <Skeleton className="w-full h-[136px]" />;
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
      />
      {mode === 'view' ? <DetailsView data={data} /> : <DetailsEdit data={data} form={form} />}
    </div>
  );
};

export { LocationDetailsTab };
