import { getAxiosInstance } from '../../api';
import { AUTH_ENDPOINTS } from '../../constants/public-api';

type TResetPasswordRequestObject = {
  email: string;
};

type TResetPasswordConfirmRequestObject = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};

export const resetPassword = async (data: TResetPasswordRequestObject): Promise<void> => {
  const axiosInstance = getAxiosInstance();
  await axiosInstance.post(AUTH_ENDPOINTS.PASSWORD_RESET, data);
};

export const resetPasswordConfirm = async (data: TResetPasswordConfirmRequestObject): Promise<void> => {
  const payload = {
    new_password1: data.new_password1,
    new_password2: data.new_password2,
  };
  const axiosInstance = getAxiosInstance();
  await axiosInstance.post(`${AUTH_ENDPOINTS.PASSWORD_RESET_CONFIRM}${data.uid}/${data.token}/`, payload);
};
