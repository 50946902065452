import { createSelector } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { RootState } from '~/store';

const getState = (state: RootState) => state[AppReduxReducer.CREATE_ACTIVITY];

export const createActivityModalSelector = createSelector(getState, (state) => state.activityTypeModalOpen);
export const createActivityCommunityActivityTypeSelector = createSelector(
  getState,
  (state) => state.selectedCommunityActivityType,
);
