import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TAttendanceList } from '@purple/shared-types';

export const getAttendanceList = async ({ studentId, year }: { studentId: string; year?: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_ATTENDANCE_LIST, studentId);
  const response = await axiosInstance.get<TAttendanceList>(URL_PATH, { params: { year } });
  return response.data;
};
