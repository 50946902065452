import { Text } from '@purple/ui';
import { useAppDispatch, useModal } from '~/hooks';
import { setStudentIncidentId } from '~/store/features/studentIncident';

type TIncidentNameComponentProperties = React.PropsWithChildren<{
  incidentId: string;
  name: string;
}>;

const IncidentNameComponent = ({ incidentId, name }: TIncidentNameComponentProperties) => {
  const { openModal } = useModal('detail-student-incident');
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(setStudentIncidentId(incidentId));
    openModal();
  };

  return (
    <button type="button" onClick={handleOpenModal} className="max-w-[184px]">
      <Text variant="size-14" type="body-500" className="cursor-pointer truncate text-left text-brand-blue-700">
        {name}
      </Text>
    </button>
  );
};

export { IncidentNameComponent };
