import { useMemo } from 'react';
import { useQueryParameter, useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { EMAIL_SORT_OPTIONS, EMAIL_STATUS_FILTER_OPTIONS, SafEmailQueryName } from './constants';
import type React from 'react';

type TEmailListHeaderProperties = {
  onNewEmail?: () => void;
};

export const EmailListHeader: React.FC<TEmailListHeaderProperties> = (props) => {
  const { onNewEmail } = props;

  const {
    query: status,
    onClearQuery,
    onQueryChange,
  } = useQueryParameter({
    queryName: SafEmailQueryName.STATUS,
    resetOffset: true,
  });

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const hasStatusValue = useMemo(() => Boolean(status), [status]);
  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <div className="flex items-center gap-4">
        <RadixSelect value={status ?? ''} onValueChange={onQueryChange}>
          <RadixSelectTrigger
            hasClearButton={hasStatusValue}
            onClearCallback={onClearQuery}
            triggerContainerClassName="w-[170px]"
          >
            <RadixSelectValue placeholder="All Statuses" />
          </RadixSelectTrigger>
          <RadixSelectContent className="min-w-fit">
            {EMAIL_STATUS_FILTER_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <RadixSelect value={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[170px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="min-w-fit">
            {EMAIL_SORT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer text-nowrap">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by subject"
          className="max-w-[300px]"
        />
      </div>
      <Button type="button" variant="primary" iconLeft={<PurpleIcon name="mail" />} onClick={onNewEmail}>
        New Email
      </Button>
    </div>
  );
};
