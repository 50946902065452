const X_VALUE_OFFSET = 25;
const Y_VALUE_OFFSET = 8;
const Y_VALUE_OFFSET2 = 22;

const CustomizedGroupTick = (props: any) => {
  const { x, y, payload } = props;

  const splitDateRange = (dateRange: string) => {
    const dates = dateRange.split(' - ');
    return [dates[0], dates[1]];
  };

  const [startDateFormatted, endDateFormatted] = splitDateRange(payload.value as string);

  return (
    <g>
      <g>
        <text x={x - X_VALUE_OFFSET} y={(y + Y_VALUE_OFFSET) as number} fill="#4E617B" fontSize={12}>
          {startDateFormatted}
        </text>
        <text x={x - X_VALUE_OFFSET} y={(y + Y_VALUE_OFFSET2) as number} fill="#4E617B" fontSize={12}>
          {endDateFormatted}
        </text>
      </g>
    </g>
  );
};

export { CustomizedGroupTick };
