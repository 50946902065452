import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentAccountHistoryList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertAccountHistoryResponseToDataTable } from '../converter';
import type { IStudentAccountHistoryListRequest } from '@purple/shared-types';

export const useGetStudentAccountHistoryList = (request: IStudentAccountHistoryListRequest) => {
  const { studentId, pagination, search } = request;
  const { limit, offset } = pagination;

  const { isError, isLoading, isSuccess, data, ...props } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_ACCOUNT_HISTORY, studentId, limit, offset, search],
    queryFn: () => getStudentAccountHistoryList(request),
    enabled: !!studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student account history', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return {
    ...props,
    isError,
    isLoading,
    isSuccess,
    data: data ? convertAccountHistoryResponseToDataTable(data) : data,
  };
};
