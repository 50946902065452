import { useMemo, useRef, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_STUDENT_FILE_EXTENSIONS, MAX_STUDENT_FILE_SIZE } from '@purple/shared-utils';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { useAppSelector, useModal } from '~/hooks';
import { showErrorToast } from '~/shared/lib';
import { userSelector } from '~/store/features/user';
import type { TFileListItem } from '@purple/shared-types';

type TFilesTableOptionsProperties = {
  rowData: TFileListItem;
  onSelectOption: (fileId: string) => void;
  onReplaceFileCallback: (file: File | null) => void;
};

export const FilesTableOptions = ({ rowData, onSelectOption, onReplaceFileCallback }: TFilesTableOptionsProperties) => {
  const { openModal } = useModal('delete-student-file');
  const { openModal: openEditModal } = useModal('edit-student-file');
  const { openModal: openUploadModal } = useModal('upload-file-preview');

  const { id } = useAppSelector(userSelector);

  const inputReference = useRef<HTMLInputElement>(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isFileOwner = useMemo(() => rowData.created_by?.id === id, [id, rowData.created_by?.id]);

  const fileUploadHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || !files[0]) return;

    if (files[0].size > MAX_STUDENT_FILE_SIZE) {
      showErrorToast('System message', `File size exceeds the limit of ${MAX_STUDENT_FILE_SIZE / 1024 / 1024}MB`);
      inputReference.current!.value = '';
      return;
    }

    onSelectOption(rowData.id);
    onReplaceFileCallback(files[0]);
    openUploadModal();
    inputReference.current!.value = '';
  };

  const downloadFileHandler = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(rowData.file);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', rowData.title);
      document.body.append(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsDropdownOpen(false);
    } catch {
      showErrorToast('System message', 'There was an error downloading the file');
    } finally {
      setIsDownloading(false);
    }
  };

  const editNoteHandler = () => {
    onSelectOption(rowData.id);
    openEditModal();
  };

  const deleteNoteHandler = () => {
    onSelectOption(rowData.id);
    openModal();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          variant="link"
          className="p-0"
          iconLeft={<PurpleIcon name="dots-vertical" className="text-grey-600" />}
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[150px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={downloadFileHandler}
          type="button"
          className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={
            isDownloading ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />
          }
        >
          Download
        </Button>
        {isFileOwner && (
          <>
            <Button
              variant="link"
              onClick={editNoteHandler}
              className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
              iconLeft={<PurpleIcon name="pencil" />}
            >
              Edit
            </Button>
            <Button
              variant="link"
              onClick={fileUploadHandler}
              className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
              iconLeft={<PurpleIcon name="upload" />}
            >
              Replace File
            </Button>
            <Button
              variant="link"
              className="w-full justify-start text-error-main hover:bg-grey-100 hover:text-error-main active:bg-grey-200"
              onClick={deleteNoteHandler}
              iconLeft={<PurpleIcon name="trash" />}
            >
              Delete
            </Button>
          </>
        )}
      </DropdownContent>
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_STUDENT_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
      />
    </DropdownRoot>
  );
};
