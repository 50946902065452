import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { usePermissions } from '@purple/permissions';
import {
  SAF_AREA_SUBCATEGORIES_LABELS,
  TakeActionDocumentType,
  TakeActionLinkType,
  TakeActionType,
} from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import {
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
// hooks
import { useDebouncedCallback } from '@purple/hooks';
import { useCurrentUser } from '~/hooks/redux';
import {
  useActionChoices,
  useActionPurpleUserParticipants,
  useAllSchools,
  useGetAllStudents,
  useUpdateAction,
} from '~/queries';
// helpers
import { AppRoutes } from '~/constants/routes/routes';
import { ActionTypeToPermission, ReadableTakeActionDocumentType } from '~/constants/take-action';
import { constructTakeActionTitle } from '~/shared/utils';
// components
import { LinkButton } from '~/components/LinkButton';
import { ActionSectionHeader } from './ActionSectionHeader';
// types
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

const STUDENTS_PER_PAGE = 50;
const DEFAULT_MEMBERS_LIMIT = 100;

const detailsSchema = z
  .object({
    title: z.string(),
    document: z.nativeEnum(TakeActionDocumentType).optional(),
    studentId: z.string().optional(),
    schoolId: z.string().optional(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .optional(),
    subType: z
      .string({
        invalid_type_error: 'Subtype must be a string.',
      })
      .trim()
      .optional(),
    numberOfResources: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Quantity must be a positive number or zero.',
      }),
    focusAreas: z.array(z.string()).optional(),
    services: z.array(z.string()).optional(),
    quantity: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Quantity must be a positive number or zero.',
      }),
  })
  .superRefine((data, context) => {
    if (isFieldExist(data.type) && data.type.length === 0) {
      context.addIssue({
        path: ['type'],
        code: z.ZodIssueCode.custom,
        message: 'Type is required.',
      });
    }
    if (isFieldExist(data.subType) && data.subType.length === 0) {
      context.addIssue({
        path: ['subType'],
        code: z.ZodIssueCode.custom,
        message: 'SubType is required.',
      });
    }
    if (isFieldExist(data.focusAreas) && data.focusAreas.length === 0) {
      context.addIssue({
        path: ['focusAreas'],
        code: z.ZodIssueCode.custom,
        message: 'At least one focus area is required.',
      });
    }
    if (isFieldExist(data.services) && data.services.length === 0) {
      context.addIssue({
        path: ['services'],
        code: z.ZodIssueCode.custom,
        message: 'At least one service is required.',
      });
    }
    if (isFieldExist(data.document) && !data.document) {
      context.addIssue({
        path: ['document'],
        code: z.ZodIssueCode.custom,
        message: 'Document type is required.',
      });
    }
    if (isFieldExist(data.studentId) && !data.studentId) {
      context.addIssue({
        path: ['studentId'],
        code: z.ZodIssueCode.custom,
        message: 'Student is required.',
      });
    }
    if (isFieldExist(data.schoolId) && !data.schoolId) {
      context.addIssue({
        path: ['schoolId'],
        code: z.ZodIssueCode.custom,
        message: 'School is required.',
      });
    }
  });

type TActionDetailsSectionProperties = {
  action: TActionDetails;
  hideTitle?: boolean;
  itemClassName?: string;
};

export const ActionDetailsSection: React.FC<TActionDetailsSectionProperties> = (props) => {
  const {
    action: { id, title, action_group, details, record_action_type, created_at },
    hideTitle = false,
    itemClassName,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const [debouncedSchoolSearchValue, setDebouncedSchoolSearchValue] = useState<string>('');

  const { subTypeOptions, typeOptions, focusAreaOptions, serviceOptions } = useActionChoices({
    record_action_type,
    students: details.student?.id,
    enabled: true,
  });
  const { data: participantsData } = useActionPurpleUserParticipants(
    id,
    {
      limit: DEFAULT_MEMBERS_LIMIT,
    },
    { enabled: record_action_type === TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING },
  );
  const { data: studentData, isFetching } = useGetAllStudents({
    enabled: isEditing,
    search: debouncedSearchValue,
    limit: STUDENTS_PER_PAGE,
    offset: 0,
  });
  const { data: schoolData, isFetching: isSchoolsFetching } = useAllSchools({
    search: debouncedSchoolSearchValue,
  });
  const { mutate: updateAction, isPending } = useUpdateAction();
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () =>
      hasPermissions(ActionTypeToPermission[record_action_type])
      && (user.id === details.created_by?.id
        || participantsData?.results.some((participant) => participant.id === user.id)),
    [user, details.created_by?.id, hasPermissions, record_action_type, participantsData?.results],
  );

  const [readableSubType] = useMemo(
    () => subTypeOptions.filter((option) => option.value === details.subtype).map((option) => option.label),
    [details.subtype, subTypeOptions],
  );
  const [readableType] = useMemo(
    () => typeOptions.filter((option) => option.value === details.type).map((option) => option.label),
    [details.type, typeOptions],
  );

  const focusAreas = useMemo(() => {
    if (!isFieldExist(details.focus_areas)) return;
    return details.focus_areas.length > 0 ? details.focus_areas.map((area) => area.name).join(', ') : '—';
  }, [details]);
  const serviceCategories = useMemo(() => {
    if (!isFieldExist(details.service_categories)) return;
    return details.service_categories.length > 0
      ? details.service_categories.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')
      : '—';
  }, [details]);

  const studentsOptions = useMemo(
    () =>
      studentData?.results.map((student) => ({
        label: `${student.first_name} ${student.last_name}`,
        value: student.id,
      })) ?? [],
    [studentData?.results],
  );
  const schoolsOptions = useMemo(
    () =>
      schoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [schoolData?.results],
  );

  const defaultValues: z.infer<typeof detailsSchema> = useMemo(
    () => ({
      title,
      document: details.document_as,
      studentId: details.student?.id,
      schoolId: details.school?.id,
      type: details.type,
      subType: details.subtype,
      numberOfResources: details.number_of_resources_selected ?? 0,
      focusAreas: details.focus_areas?.map((area) => area.id.toString()),
      services: details.service_categories?.map((category) => category.id),
      quantity: details.quantity ?? 0,
    }),
    [details, title],
  );

  const form = useForm<z.infer<typeof detailsSchema>>({
    resolver: zodResolver(detailsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const documentType = form.watch('document');

  useEffect(() => {
    if (!documentType) return;
    form.setValue(
      'title',
      constructTakeActionTitle({
        documentType,
        link: action_group === null ? TakeActionLinkType.INDIVIDUAL : TakeActionLinkType.GROUP,
        actionType: record_action_type,
        userName: user.full_name ?? 'Unknown User',
        date: created_at,
      }),
    );
  }, [action_group, documentType, record_action_type, user.full_name, form, created_at]);

  const debouncedStudentSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);
  const debouncedSchoolSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSchoolSearchValue(searchQuery);
  }, 500);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof detailsSchema>) => {
    updateAction(
      {
        id,
        ...(isFieldExist(details.type) && { type: formData.type }),
        ...(isFieldExist(details.subtype) && { subtype: formData.subType }),
        ...(isFieldExist(details.number_of_resources_selected) && {
          number_of_resources_selected: formData.numberOfResources,
        }),
        ...(isFieldExist(focusAreas) && formData.focusAreas && { focus_areas: formData.focusAreas.map(Number) }),
        ...(isFieldExist(serviceCategories) && { service_categories: formData.services }),
        ...(isFieldExist(details.quantity) && { quantity: formData.quantity }),
        ...(isFieldExist(details.document_as) && {
          document_as: formData.document,
          title: formData.title,
        }),
        ...(isFieldExist(details.student) && { student: formData.studentId }),
        ...(isFieldExist(details.school) && { school: formData.schoolId }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader
        title={hideTitle ? null : 'Details'}
        editing={isEditing}
        loading={isPending}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              {isFieldExist(details.student) && (
                <FormField
                  control={form.control}
                  name="studentId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Student</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select student"
                              selectedLabel={
                                studentsOptions.find((option) => option.value === field.value)?.label
                                ?? details.student?.full_name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isFetching}
                            searchPlaceholder="Search student..."
                            emptyContent="Student not found."
                            onSearchChange={debouncedStudentSearch}
                          >
                            {studentsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.school) && (
                <FormField
                  control={form.control}
                  name="schoolId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">School</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select school"
                              selectedLabel={
                                schoolsOptions.find((option) => option.value === field.value)?.label ?? details.school?.name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isSchoolsFetching}
                            searchPlaceholder="Search school..."
                            emptyContent="School not found."
                            onSearchChange={debouncedSchoolSearch}
                          >
                            {schoolsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.service_provider) && (
                <DescriptionItem>
                  <DescriptionTerm>Service Provider</DescriptionTerm>
                  <DescriptionDetails>
                    {/* TODO: change link path to actual service provider page */}
                    <LinkButton to={AppRoutes.App.Home.Root.path}>{details.service_provider.name}</LinkButton>
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.document_as) && (
                <FormField
                  control={form.control}
                  name="document"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Document as</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <RadixSelect onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <RadixSelectTrigger
                              className={cn('max-h-9', {
                                'border-error-main': !!fieldState.error,
                              })}
                            >
                              <RadixSelectValue placeholder="Select document type" />
                            </RadixSelectTrigger>
                          </FormControl>
                          <RadixSelectContent>
                            {Object.values(TakeActionDocumentType).map((value) => (
                              <RadixSelectItem key={value} value={value}>
                                {ReadableTakeActionDocumentType[value]}
                              </RadixSelectItem>
                            ))}
                          </RadixSelectContent>
                        </RadixSelect>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.type) && (
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Type</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select type"
                              selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                            {typeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.subtype) && (
                <FormField
                  control={form.control}
                  name="subType"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Subtype</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select subtype"
                              selectedLabel={subTypeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search subtype..." emptyContent="Subtype not found.">
                            {subTypeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.number_of_resources_selected) && (
                <FormField
                  control={form.control}
                  name="numberOfResources"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of Resources
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.numberOfResources}
                            placeholder="Enter number of resources"
                            className="h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(focusAreas) && (
                <FormField
                  control={form.control}
                  name="focusAreas"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Focus Areas</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.focusAreas}
                            modalPopover
                            showSearch
                            selectedOptions={focusAreaOptions.filter((service) => field.value?.includes(service.value))}
                            placeholder="Select areas"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            onOptionChange={field.onChange}
                          >
                            {focusAreaOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(serviceCategories)
              && (record_action_type === TakeActionType.COUNSELING_SESSION
                ? (
                    <DescriptionItem>
                      <DescriptionTerm>Service Categories</DescriptionTerm>
                      <DescriptionDetails className="whitespace-pre-wrap text-right">{serviceCategories}</DescriptionDetails>
                    </DescriptionItem>
                  )
                : (
                    <FormField
                      control={form.control}
                      name="services"
                      render={({ field }) => (
                        <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                          <FormLabel className="font-primary text-base font-normal text-grey-600">
                            Service Categories
                          </FormLabel>
                          <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                            <FormControl>
                              <MultiSelect
                                {...field}
                                isError={!!form.formState.errors.services}
                                selectedOptions={serviceOptions.filter((service) => field.value?.includes(service.value))}
                                placeholder="Select categories"
                                classNames={{ trigger: 'min-h-9 py-1' }}
                                modalPopover
                                showSearch
                                onOptionChange={field.onChange}
                              >
                                {serviceOptions.map((option) => (
                                  <MultiSelectItem
                                    key={option.value}
                                    value={option.value}
                                    option={option}
                                    isSelected={field.value?.includes(option.value)}
                                  />
                                ))}
                              </MultiSelect>
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  ))}
              {isFieldExist(details.quantity) && (
                <FormField
                  control={form.control}
                  name="quantity"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Quantity</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1', itemClassName)}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.quantity}
                            placeholder="Enter quantity"
                            className="h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.created_by) && (
                <DescriptionItem>
                  <DescriptionTerm tag="p">Created By</DescriptionTerm>
                  <DescriptionDetails tag="p">{details.created_by.full_name ?? 'N/A'}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.saf) && (
                <DescriptionItem>
                  {/* TODO: Add form field to update SAF id when the SAFs will be available */}
                  <DescriptionTerm tag="p">Linked SAF</DescriptionTerm>
                  <DescriptionDetails tag="p">{details.saf?.saf_number ?? '-'}</DescriptionDetails>
                </DescriptionItem>
              )}
            </Form>
          )
        : (
            <DescriptionList>
              {isFieldExist(details.student) && (
                <DescriptionItem>
                  <DescriptionTerm>Student</DescriptionTerm>
                  <DescriptionDetails>
                    <LinkButton to={AppRoutes.App.Students.StudentDetails.Root.makePath(details.student.id, 'dashboard')}>
                      {details.student.full_name}
                    </LinkButton>
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.school) && (
                <DescriptionItem>
                  <DescriptionTerm>School</DescriptionTerm>
                  <DescriptionDetails>
                    <LinkButton to={AppRoutes.App.School.Root.makePath(details.school.id, 'overview')}>
                      {details.school.name}
                    </LinkButton>
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.service_provider) && (
                <DescriptionItem>
                  <DescriptionTerm>Service Provider</DescriptionTerm>
                  <DescriptionDetails>
                    {/* TODO: change link path to actual service provider page */}
                    <LinkButton to={AppRoutes.App.Home.Root.path}>{details.service_provider.name}</LinkButton>
                  </DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.document_as) && (
                <DescriptionItem>
                  <DescriptionTerm>Document as</DescriptionTerm>
                  <DescriptionDetails>{ReadableTakeActionDocumentType[details.document_as]}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.type) && (
                <DescriptionItem>
                  <DescriptionTerm>Type</DescriptionTerm>
                  <DescriptionDetails>{readableType}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.subtype) && (
                <DescriptionItem>
                  <DescriptionTerm>Subtype</DescriptionTerm>
                  <DescriptionDetails>{readableSubType ?? '-'}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.number_of_resources_selected) && (
                <DescriptionItem>
                  <DescriptionTerm>Number of Resources</DescriptionTerm>
                  <DescriptionDetails>{details.number_of_resources_selected}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(focusAreas) && (
                <DescriptionItem>
                  <DescriptionTerm>Focus Areas</DescriptionTerm>
                  <DescriptionDetails className="max-w-[300px] text-right">{focusAreas}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(serviceCategories) && (
                <DescriptionItem>
                  <DescriptionTerm>Service Categories</DescriptionTerm>
                  <DescriptionDetails className="max-w-[300px] text-right">{serviceCategories}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.quantity) && (
                <DescriptionItem>
                  <DescriptionTerm>Quantity</DescriptionTerm>
                  <DescriptionDetails>{details.quantity}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.created_by) && (
                <DescriptionItem>
                  <DescriptionTerm>Created By</DescriptionTerm>
                  <DescriptionDetails>{details.created_by.full_name ?? 'N/A'}</DescriptionDetails>
                </DescriptionItem>
              )}
              {isFieldExist(details.saf) && (
                <DescriptionItem>
                  <DescriptionTerm>Linked SAF</DescriptionTerm>
                  <DescriptionDetails>
                    {details.saf
                      ? (
                          <Link
                            to={AppRoutes.App.Safs.SAFDetails.makePath(details.saf.id)}
                            className="cursor-pointer truncate font-primary text-brand-blue-700 hover:underline hover:underline-offset-2"
                          >
                            {details.saf?.saf_number}
                          </Link>
                        )
                      : (
                          '—'
                        )}
                  </DescriptionDetails>
                </DescriptionItem>
              )}
            </DescriptionList>
          )}
    </div>
  );
};
