import { useTranslation } from 'react-i18next';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Heading, Text } from '@purple/ui';
import { SafReturnType, StudentAssistanceScreen } from '~/constants/saf';
import { useStudentAssistance } from '~/hooks/redux';
import { useArabicLanguage } from '../hooks';

export const SuccessCard: React.FC = () => {
  const { t } = useTranslation('saf');

  const { setSafCurrentScreen, setSafReturnType } = useStudentAssistance();
  const { isArabic } = useArabicLanguage();

  const currentStudentSelectHandler = () => {
    setSafReturnType(SafReturnType.CURRENT_STUDENT);
    setSafCurrentScreen(StudentAssistanceScreen.FORM);
  };

  const newStudentSelectHandler = () => {
    setSafReturnType(SafReturnType.NEW_STUDENT);
    setSafCurrentScreen(StudentAssistanceScreen.FORM);
  };

  return (
    <section className="mt-[152px] flex w-full max-w-[840px] flex-col items-center gap-12 rounded-2xl border border-grey-200 bg-white p-12">
      <div className="flex w-full flex-col items-center gap-6">
        <div className="flex h-16 w-16 items-center justify-center rounded-xl bg-success-bg">
          <PurpleIcon name="check-circle" className="h-8 w-8 text-success-main" />
        </div>
        <div className="flex w-full flex-col items-center gap-2">
          <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-title">
            {t('success.title')}
          </Heading>
          <Text
            type="body-400"
            variant="size-16"
            className="max-w-[742px] whitespace-pre-wrap text-center text-grey-600"
          >
            {t('success.description')}
          </Text>
        </div>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            iconRight={<PurpleIcon name="chevron-down" className="h-5 w-5 text-white" />}
            dir={isArabic ? 'rtl' : 'ltr'}
          >
            {t('success.trigger')}
          </Button>
        </DropdownTrigger>
        <DropdownContent useTriggerWidth>
          <DropdownItem
            iconName="user"
            onSelect={currentStudentSelectHandler}
            dir={isArabic ? 'rtl' : 'ltr'}
            className={cn({ '[&>svg]:ml-2 [&>svg]:mr-0': isArabic })}
          >
            {t('success.option-1')}
          </DropdownItem>
          <DropdownItem
            iconName="user-add"
            onSelect={newStudentSelectHandler}
            dir={isArabic ? 'rtl' : 'ltr'}
            className={cn({ '[&>svg]:ml-2 [&>svg]:mr-0': isArabic })}
          >
            {t('success.option-2')}
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
    </section>
  );
};
