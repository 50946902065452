import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { TSchoolStats } from '@purple/shared-types';

type TSchoolStatsProperties = React.PropsWithChildren<{
  data: TSchoolStats;
}>;

const SchoolStats = ({ data }: TSchoolStatsProperties) => {
  const { average_teacher_experience, teacher_to_student_ratio, campus_attendance_rate } = data;
  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="AVG Teacher Experience">
        <InfoItemValue>{average_teacher_experience}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Teacher to Student Ratio">
        <InfoItemValue>{teacher_to_student_ratio}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Campus Attendance Rate">
        <InfoItemValue>{campus_attendance_rate}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { SchoolStats };
