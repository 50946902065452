import { useMutation, useQueryClient } from '@tanstack/react-query';
import { batchAddStudents } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionBatchStudentsBody } from '@purple/shared-types';

export const useAddLinkedStudents = (groupActionId: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, TActionBatchStudentsBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.BATCH_ADD_STUDENTS],
    mutationFn: (body) => batchAddStudents(groupActionId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [STUDENTS_QUERY_KEYS.GET_ACTION_LINKED_STUDENTS, { groupActionId }] });
      showSuccessToast('System message', 'Members have been successfully added');
    },
    onError: () => {
      showErrorToast('System message', 'Could not add new members. Please try again later or contact support');
    },
  });
};
