import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Heading,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';

type TDeleteModalProperties = {
  title: string;
  description: string;
  onConfirm: () => void;
  isLoading?: boolean;
};

export const DeleteModal = ({ onConfirm, title, description, isLoading = false }: TDeleteModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('remove-modal');

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[400px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle asChild>
            <Heading tag="h4" variant="size-18" type="heading-600" className="leading-[28px] text-grey-title">
              {title}
            </Heading>
          </DialogTitle>
          <DialogDescription className="sr-only">Confirm that you want to remove</DialogDescription>
        </DialogHeader>
        <div className="flex w-full px-6">
          <Text variant="size-14" type="body-400" className="text-grey-950">
            {description}
          </Text>
        </div>
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="destructive_primary" onClick={onConfirm} disabled={isLoading} isLoading={isLoading}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
