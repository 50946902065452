import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateActivityTags } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActivityTagUpdateBody, TActivityTagUpdateResponse } from '@purple/shared-types';

export const useUpdateActivityTags = (activityId: string) => {
  const queryClient = useQueryClient();

  return useMutation<TActivityTagUpdateResponse, AxiosError, TActivityTagUpdateBody>({
    mutationKey: [ACTIVITY_QUERY_KEYS.UPDATE_ACTIVITY_TAGS],
    mutationFn: (body) => updateActivityTags(activityId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_DETAILS, activityId] });
      showSuccessToast('System message', 'The activity tags were updated successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not update the activity tags. Check the provided information and try again',
      );
    },
  });
};
