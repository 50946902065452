import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { IPaginatedResponse, IStudentBaseInfo, TStudentDataListRequestParameters } from '@purple/shared-types';

export const getStudentsDataAll = async (request: TStudentDataListRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const URL_PATH = STUDENTS_ENDPOINTS.GET_STUDENTS_DATA_ALL;

  const response = await axiosInstance.get<IPaginatedResponse<IStudentBaseInfo[]>>(URL_PATH, {
    params: request,
  });
  return response.data;
};
