import type { TUserProfile, TUserProfileDTO } from '@purple/shared-types';

export const convertProfileDTOToProfile = (profileDTO: TUserProfileDTO): TUserProfile => {
  return {
    id: profileDTO.id,
    basic_details: {
      username: profileDTO.username,
      full_name: profileDTO.full_name,
      avatar: profileDTO.avatar,
      title: profileDTO.title,
    },
    account_details: {
      title: profileDTO.title,
      email: profileDTO.email,
      phone_number: profileDTO.phone_number,
      company_name: profileDTO.company_name,
      department: profileDTO.department,
      change_email_request: profileDTO.change_email_request,
    },
    school_access: {
      schools: profileDTO.schools,
    },
    location_details: {
      language: profileDTO.language,
      timezone: profileDTO.timezone,
      address: profileDTO.address,
    },
  };
};
