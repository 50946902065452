import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import {
  AppFilters,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { SORT_OPTIONS } from './constants';
import type { FC } from 'react';
import type { TFiltersConfig } from '@purple/ui';

const DEFAULT_SORTING = 'flags_cy';

type FilterActionsRowProperties = {
  filterConfig: TFiltersConfig;
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

const FilterActionsRow: FC<FilterActionsRowProperties> = ({
  filterConfig,
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}) => {
  const { search, onSearchChange, onClearSearch } = useSearch();
  const { sort, onSortChange, onSortClear } = useSort({ defaultValue: DEFAULT_SORTING });

  const defaultSortOption = useMemo(() => SORT_OPTIONS.find((option) => option.value === sort) || null, [sort]);

  const sortChangeHandler = (newValue: string) => {
    if (newValue) {
      onSortChange(newValue);
    } else {
      onSortClear();
    }
  };

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex items-center gap-4">
        <AppFilters config={filterConfig} />
        <RadixSelect
          onValueChange={sortChangeHandler}
          defaultValue={defaultSortOption?.value || ''}
          value={defaultSortOption?.value || ''}
        >
          <RadixSelectTrigger
            hasClearButton={Boolean(defaultSortOption?.value)}
            onClearCallback={() => sortChangeHandler('')}
            triggerContainerClassName="w-[180px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {SORT_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <RadixSelect onValueChange={onPeriodChange} defaultValue={PERIOD_SELECT_OPTIONS[0]?.value} value={timePeriod}>
          <RadixSelectTrigger
            hasClearButton={Boolean(timePeriod)}
            onClearCallback={onPeriodClear}
            triggerContainerClassName="w-[180px]"
          >
            <RadixSelectValue placeholder="Time Period" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <div className="w-[240px]">
          <SearchInput placeholder="Search" value={search} onChange={onSearchChange} onClear={onClearSearch} />
        </div>
      </div>
    </div>
  );
};

export { FilterActionsRow };
