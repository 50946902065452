import { type FC, Fragment } from 'react';
import React from 'react';
import { type IStudentHomeInformation, STUDENT_FAMILY_SIZE, STUDENT_GUARDIAN } from '@purple/shared-types';
import { convertBooleanToText, formatDate } from '@purple/shared-utils';
import {
  cn,
  Form,
  FormControl,
  FormField,
  FormItem,
  InfoItem,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { UseFormReturn } from 'react-hook-form';
import type { StudentHomeInformationForm } from './types';

const FAMILY_SIZE_OPTIONS = Object.values(STUDENT_FAMILY_SIZE).map((label) => ({
  label,
  value: label,
}));

const GUARDIAN_OPTIONS = Object.values(STUDENT_GUARDIAN).map((label) => ({
  label,
  value: label,
}));

type StudentHomeInformationEditProperties = {
  data: IStudentHomeInformation;
  form: UseFormReturn<StudentHomeInformationForm>;
};

const StudentHomeInformationEdit: FC<StudentHomeInformationEditProperties> = ({ data, form }) => {
  const {
    home_language,
    mailing_address,
    is_immigrant,
    unaccompanied_youth_code,
    guardian_info,
    phone,
    family_size,
    with_whom_does_the_child_live,
  } = data;

  const defaultFamilySizeOption = React.useMemo(
    () => FAMILY_SIZE_OPTIONS.find((option) => option.value === family_size),
    [family_size],
  );

  const defaultWithWhomDoesTheChildLiveOption = React.useMemo(
    () => GUARDIAN_OPTIONS.find((option) => option.value === with_whom_does_the_child_live),
    [with_whom_does_the_child_live],
  );

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <InfoItem label="Home Language">
        <InfoItemValue>{home_language}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Mailing Address">
        <InfoItemValue>{mailing_address}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Immigrant">
        <InfoItemValue>{convertBooleanToText(is_immigrant)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Unaccompanied Youth">
        <InfoItemValue>{unaccompanied_youth_code ? formatDate(unaccompanied_youth_code) : '-'}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Primary Guardian First Name">
        <InfoItemValue>{guardian_info?.first_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Primary Guardian Last Name">
        <InfoItemValue>{guardian_info?.last_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Guardian Email">
        <InfoItemValue>{guardian_info?.email}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Phone Number">
        {phone.map((phoneNumber) => {
          return (
            <Fragment key={phoneNumber}>
              <InfoItemValue>{phoneNumber}</InfoItemValue>
              <InfoItemValue>{phoneNumber}</InfoItemValue>
            </Fragment>
          );
        })}
      </InfoItem>
      <InfoItem label="Family Size">
        <FormField
          control={form.control}
          name="family_size"
          render={({ field, fieldState }) => (
            <FormItem>
              <RadixSelect
                onValueChange={field.onChange}
                defaultValue={defaultFamilySizeOption?.value}
                value={field.value ?? undefined}
              >
                <FormControl>
                  <RadixSelectTrigger
                    className={cn('h-8 min-w-48 gap-1 px-3 py-1', {
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select family size" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {FAMILY_SIZE_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="With Whom does the Child Live?">
        <FormField
          control={form.control}
          name="with_whom_does_the_child_live"
          render={({ field, fieldState }) => (
            <FormItem>
              <RadixSelect
                onValueChange={field.onChange}
                defaultValue={defaultWithWhomDoesTheChildLiveOption?.value}
                value={field.value ?? undefined}
              >
                <FormControl>
                  <RadixSelectTrigger
                    className={cn('h-8 min-w-48 gap-1 px-3 py-1', {
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select guardian" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {GUARDIAN_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Relationship to the Child">
        <FormField
          control={form.control}
          name="relationship_to_the_child"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.relationship_to_the_child}
                  placeholder=""
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Other Family Configuration">
        <FormField
          control={form.control}
          name="other_family_configuration"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.other_family_configuration}
                  placeholder=""
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </InfoItem>
    </Form>
  );
};

export { StudentHomeInformationEdit };
