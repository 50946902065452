import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IPaginatedResponse, IStudentIncident, IStudentIncidentsListRequest } from '@purple/shared-types';

export const getStudentIncidentsList = async ({ studentId, requestParameters }: IStudentIncidentsListRequest) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<IPaginatedResponse<IStudentIncident[]>>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_INCIDENTS_LIST, studentId),
    { params: requestParameters },
  );

  return response.data;
};
