import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { usePermissions } from '@purple/permissions';
import { TakeActionType } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@purple/ui';
import { ActionTypeToPermission } from '~/constants/take-action';
import { useCurrentUser } from '~/hooks/redux';
import { useActionPurpleUserParticipants, useUpdateAction } from '~/queries';
import { ActionSectionHeader } from './ActionSectionHeader';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

const DEFAULT_MEMBERS_LIMIT = 100;

const goalsSchema = z.object({
  goal: z.string().trim(),
});

type TActionGoalsSectionProperties = {
  action: TActionDetails;
  dataKey?: 'goal_or_progress_towards_goal' | 'student_goals';
};

export const ActionGoalsSection: React.FC<TActionGoalsSectionProperties> = (props) => {
  const { action, dataKey = 'goal_or_progress_towards_goal' } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: participantsData } = useActionPurpleUserParticipants(
    action.id,
    {
      limit: DEFAULT_MEMBERS_LIMIT,
    },
    { enabled: action.record_action_type === TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING },
  );
  const { mutate: updateAction, isPending } = useUpdateAction();
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () =>
      hasPermissions(ActionTypeToPermission[action.record_action_type])
      && (user.id === action.details.created_by?.id
        || participantsData?.results.some((participant) => participant.id === user.id)),
    [user, action.details.created_by?.id, hasPermissions, action.record_action_type, participantsData?.results],
  );

  const defaultValues = useMemo(
    () => ({
      goal: action[dataKey] || '',
    }),
    [action, dataKey],
  );

  const form = useForm<z.infer<typeof goalsSchema>>({
    resolver: zodResolver(goalsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveGoalsClickHandler = (formData: z.infer<typeof goalsSchema>) => {
    updateAction(
      {
        id: action.id,
        [dataKey]: formData.goal,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          form.reset(formData);
        },
      },
    );
  };

  if (!isFieldExist(action[dataKey])) return null;

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader
        title={dataKey === 'goal_or_progress_towards_goal' ? 'Goals / Progress Towards Goals' : 'Student Goals'}
        editing={isEditing}
        loading={isPending}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveGoalsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="goal"
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter goals or progress towards goals"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <p className="text-balance pb-1 font-primary text-base font-normal text-grey-600">
              {action[dataKey] || 'No goals set'}
            </p>
          )}
    </div>
  );
};
