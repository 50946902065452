import { useMutation } from '@tanstack/react-query';
import { uploadRichTextImage } from '@purple/shared-services';
import { RICH_TEXT_IMAGE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useUploadRichTextImage = () => {
  return useMutation({
    mutationKey: [RICH_TEXT_IMAGE_QUERY_KEYS.UPLOAD_RICH_TEXT_IMAGE],
    mutationFn: uploadRichTextImage,
    onError: () => {
      showErrorToast('System message', 'Unable to upload selected image');
    },
  });
};
