import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getNoteById } from '@purple/shared-services';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useNoteById = ({ noteId, enabled }: { noteId: string; enabled?: boolean }) => {
  const { isError, ...rest } = useQuery({
    queryKey: [NOTE_QUERY_KEYS.GET_NOTE_BY_ID, { noteId }],
    queryFn: () => getNoteById({ noteId }),
    enabled: !!noteId && enabled,
    staleTime: 0,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Unable to fetch note');
    }
  }, [isError]);

  return { isError, ...rest };
};
