import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActivityTagUpdateBody, TActivityTagUpdateResponse } from '@purple/shared-types';

export const updateActivityTags = async (activityId: string, body: TActivityTagUpdateBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.patch<TActivityTagUpdateResponse>(
    replaceUrlParameters(ACTIVITY_ENDPOINTS.TAGS, activityId),
    body,
  );
  return response.data;
};
