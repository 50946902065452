import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useCreateNote } from '~/queries';
import { showErrorToast } from '~/shared/lib';

const NOTES_SORT_SELECT_OPTIONS: { labe: string; value: string }[] = [
  {
    labe: 'Title(ASC)',
    value: 'title',
  },
  {
    labe: 'Title(DESC)',
    value: '-title',
  },
  {
    labe: 'Created By(ASC)',
    value: 'created_by__full_name',
  },
  {
    labe: 'Created By(DESC)',
    value: '-created_by__full_name',
  },
  {
    labe: 'Last Modified Date(ASC)',
    value: 'updated_at',
  },
  {
    labe: 'Last Modified Date(DESC)',
    value: '-updated_at',
  },
  {
    labe: 'Last Modified By(ASC)',
    value: 'last_modified_by__full_name',
  },
  {
    labe: 'Last Modified By(DESC)',
    value: '-last_modified_by__full_name',
  },
];

type TNotesHeaderSectionProperties = {
  setNoteId: (noteId: string) => void;
};

export const NotesHeaderSection = ({ setNoteId }: TNotesHeaderSectionProperties) => {
  const { studentId } = useParams();

  const { openModal } = useModal('student-note');

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const { isPending, mutate } = useCreateNote();

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  const openAddStudentNoteModalHandler = () => {
    if (!studentId) {
      showErrorToast('Student ID is missing', 'System Error');
      return;
    }
    mutate(
      { content_type: 'student', object_id: studentId },
      {
        onSuccess: (responseData) => {
          setNoteId(responseData.id);
          openModal();
        },
      },
    );
  };

  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-4">
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[200px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="w-max">
            {NOTES_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.labe}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by title"
          className="w-max-[300px]"
        />
      </div>
      <Button
        onClick={openAddStudentNoteModalHandler}
        iconLeft={<PurpleIcon name="plus" />}
        isLoading={isPending}
        disabled={isPending}
      >
        Add Note
      </Button>
    </div>
  );
};
