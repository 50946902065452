import { createSelector } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { RootState } from '~/store';

const getState = (state: RootState) => state[AppReduxReducer.STUDENT_ASSISTANCE];

export const studentAssistanceScreenSelector = createSelector(getState, (state) => state.currentScreen);
export const studentAssistanceSubmitterSelector = createSelector(getState, (state) => state.submitterType);
export const studentAssistanceGradesSelector = createSelector(getState, (state) => state.schoolGrades);
export const studentAssistanceReturnSelector = createSelector(getState, (state) => state.returnType);
export const studentAssistanceFormSelector = createSelector(getState, (state) => state.lastSubmittedForm);
