import { Text } from '@purple/ui';

const StudentNewlyCreatedStatus = () => {
  return (
    <div className="flex w-full justify-center rounded-t-lg bg-brand-blue-700 py-2" pw-id="new-student-badge">
      <Text variant="size-14" type="body-500" className="text-white">
        Newly Transferred
      </Text>
    </div>
  );
};

export { StudentNewlyCreatedStatus };
