import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { StudentListByIDsResponseBody } from '@purple/shared-types';

export const addStudentsToList = async (studentIds: string[]) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.post<StudentListByIDsResponseBody>(STUDENTS_ENDPOINTS.ADD_STUDENTS_TO_LIST, {
    students: studentIds,
  });
  return response.data;
};
