import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const removeStudentsFromPriorityList = async ({ listId, students }: { listId: string; students: string[] }) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.REMOVE_STUDENTS_FROM_PRIORITY_LIST, listId);

  const response = await apiInstance.post<void>(URL, { students });
  return response.data;
};
