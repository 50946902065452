import { z } from 'zod';
import type { RawCreateParams } from 'zod';

/**
 * Allowing to map readonly array to Zod union.
 * @see https://github.com/colinhacks/zod/issues/831
 * @see https://github.com/colinhacks/zod/discussions/2790
 */
export function unionOfLiterals<T extends string | number>(
  constants: readonly T[],
  literalParameters?: RawCreateParams,
  unionParameters?: RawCreateParams,
) {
  const literals = constants.map((x) => z.literal(x, literalParameters)) as unknown as readonly [
    z.ZodLiteral<T>,
    z.ZodLiteral<T>,
    ...z.ZodLiteral<T>[],
  ];
  return z.union(literals, unionParameters);
}
