/**
 * Helper function that checks if a given value is not undefined. It uses a generic type to ensure type safety.
 *
 * @param value - The value to check.
 * @returns A boolean indicating whether the field exists or not.
 */
export const isFieldExist = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};

/**
 * Checks if a given value is neither `null` nor `undefined`.
 *
 * @template T - The type of the value to check.
 * @param value - The value to check.
 * @returns A boolean indicating whether the value is defined (not `null` or `undefined`).
 */
export const isFieldDefined = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

/**
 * Checks if the given object is empty.
 *
 * @template T - The type of the object.
 * @param {any} object - The object to check.
 * @returns {object is T} - Returns true if the object is empty, otherwise false.
 */
export const isObjectEmpty = (object: unknown): object is object => {
  return typeof object === 'object' && object !== null && Object.keys(object).length === 0;
};
