import { useMutation } from '@tanstack/react-query';
import { removeSharePriorityListBulk } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useRemoveSharePriorityListBulk = () => {
  return useMutation({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.REMOVE_SHARE_PRIORITY_LIST_BULK],
    mutationFn: removeSharePriorityListBulk,
    onError: () => {
      showErrorToast(
        'System message',
        'Could not remove users from the share list. Check the provided information and try again',
      );
    },
  });
};
