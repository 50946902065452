// hooks
import { useStudentAssistance } from '~/hooks/redux';
// helpers
import { StudentAssistanceScreen } from '~/constants/saf';
// components
import { FormContainer, SafBanner, SafDisclaimer, SubmitterChooser, SuccessCard } from '~/modules/StudentAssistance';
// types
import type { TSafSubmitterType } from '@purple/shared-types';

export const StudentAssistanceFormPage: React.FC = () => {
  const { isFormScreen, isSuccessScreen, isSubmitterScreen, setSafCurrentScreen, setSafSubmitterType }
    = useStudentAssistance();

  const cardClickHandler = (type: TSafSubmitterType) => {
    setSafSubmitterType(type);
    setSafCurrentScreen(StudentAssistanceScreen.FORM);
  };

  return (
    <div className="flex w-full flex-col items-center gap-6">
      {!isSuccessScreen && <SafDisclaimer />}
      {isSuccessScreen && <SafBanner />}
      {isSuccessScreen && <SuccessCard />}
      {isFormScreen && <FormContainer />}
      {isSubmitterScreen && <SubmitterChooser onSubmitterClick={cardClickHandler} />}
    </div>
  );
};
