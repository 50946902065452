import type { ValueOf } from '@purple/shared-types';

export const ECO_SYSTEM_DATA_VIEW_VALUES = {
  LIST: 'list',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
} as const;

export type TEcoSystemDataViewValues = ValueOf<typeof ECO_SYSTEM_DATA_VIEW_VALUES>;

export const ECO_SYSTEM_DATA_SELECT_VIEW = [
  {
    label: 'List View',
    value: 'list',
  },
  {
    label: 'Day View',
    value: 'day',
  },
  {
    label: 'Week View',
    value: 'week',
  },
  {
    label: 'Month View',
    value: 'month',
  },
] as const;

export const ECO_SYSTEM_TYPES_SELECT_OPTIONS = [
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Task',
    value: 'task',
  },
  {
    label: 'Reminder',
    value: 'reminder',
  },
] as const;

export const ECO_SYSTEM_STATUS_SELECT_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
] as const;
