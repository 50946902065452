import { useMemo } from 'react';
import { format } from 'date-fns';
import { ECO_SYSTEM_EVENT_TYPES } from '@purple/shared-types';
import { cn, Text } from '@purple/ui';
import { useModal } from '~/hooks';
import { getEventColorByType } from '../utils';
import type { EventProps } from 'react-big-calendar';
import type { ICalendarEvent } from './EcosystemCalendarView';

type TCalendarEvent = {
  onChangeSelectedEventId: (id: string | null) => void;
};

export const CalendarEvent = ({ event, onChangeSelectedEventId }: EventProps<ICalendarEvent> & TCalendarEvent) => {
  const { title, start, end, eventType, relatedOjectId } = event;

  const { openModal: openActionModal } = useModal('action-event-modal');
  const { openModal: openRemindersModal } = useModal('reminder-event-modal');
  const { openModal: openGroupActionModal } = useModal('group-action-modal');
  const { openModal: openCommunityActivityModal } = useModal('community-activity-modal');

  const eventDuration = useMemo(() => `${format(start, 'h:mm')} - ${format(end, 'h:mm a')}`, [start, end]);
  const hasEvent = useMemo(() => Object.values(ECO_SYSTEM_EVENT_TYPES).includes(eventType), [eventType]);

  const eventClickHandler = () => {
    onChangeSelectedEventId(relatedOjectId);
    switch (eventType) {
      case ECO_SYSTEM_EVENT_TYPES.ACTION: {
        openActionModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.GROUP_ACTION: {
        openGroupActionModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.REMINDER: {
        openRemindersModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.COMMUNITY_ACTIVITY: {
        openCommunityActivityModal();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div
      className={cn(
        'flex h-full items-baseline rounded-lg border border-green-50 px-2 pt-0.5 shadow-md transition-colors',
        getEventColorByType(eventType),
      )}
      onClick={eventClickHandler}
      onKeyDown={eventClickHandler}
      role="button"
      aria-label="Click to view event"
      tabIndex={0}
    >
      <Text variant="size-12" type="body-600" className={cn('break-words text-white', { 'text-grey-950': !hasEvent })}>
        {eventDuration}
        {' '}
        {title}
      </Text>
    </div>
  );
};
