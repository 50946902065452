import { useMemo, useState } from 'react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { cn, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@purple/ui';
import { EmptyDataPlaceholder } from '../EmptyDataPlaceholder';
import type { ColumnDef, SortingState } from '@tanstack/react-table';

const defaultClassNames = {
  cell: '',
  head: '',
};

type DataTableProperties<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  emptyStateTitle?: string;
  emptyStateMessage?: string;
  isFiltersApplied?: boolean;
  isLoading?: boolean;
  skeleton?: React.ReactNode;
  classNames?: {
    cell?: string;
    head?: string;
    wrapper?: string;
    root?: string;
    row?: string;
    emptyCell?: string;
  };
  onRowClick?: (row: TData) => void;
};

export const DataTable = <TData, TValue>({
  columns,
  data,
  emptyStateMessage = 'There is no data to display right now.',
  emptyStateTitle = 'No data yet',
  isFiltersApplied = false,
  isLoading = false,
  skeleton = null,
  classNames = defaultClassNames,
  onRowClick,
}: DataTableProperties<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const body = useMemo(() => {
    if (isLoading) {
      return (
        <TableRow className={cn('w-full border-none', classNames?.row)}>
          <TableCell colSpan={columns.length} className={cn('w-full p-0 text-center', classNames?.cell)}>
            {skeleton}
          </TableCell>
        </TableRow>
      );
    }
    if (table.getRowModel().rows?.length) {
      const hasOnClick = !!onRowClick;
      return table.getRowModel().rows.map((row) => (
        <TableRow
          key={row.id}
          data-state={row.getIsSelected() && 'selected'}
          onClick={hasOnClick ? () => onRowClick(row.original) : undefined}
          className={cn(hasOnClick && 'cursor-pointer hover:bg-grey-100/70', classNames?.row)}
          role={hasOnClick ? 'button' : undefined}
          aria-label={hasOnClick ? 'Table row clickable' : undefined}
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              className={cn(
                cell.column.getSize() && `w-[${cell.column.getSize()}px]`,
                classNames?.cell,
                cell.column.columnDef.meta?.className,
              )}
              pw-id="table-cell"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ));
    }
    return (
      <TableRow className={cn('border-none', classNames?.row)}>
        <TableCell
          colSpan={columns.length}
          className={cn('h-[432px] w-full text-center', classNames?.cell, classNames?.emptyCell)}
        >
          <EmptyDataPlaceholder
            title={emptyStateTitle}
            message={emptyStateMessage}
            isFiltersApplied={isFiltersApplied}
          />
        </TableCell>
      </TableRow>
    );
  }, [
    isLoading,
    classNames,
    table,
    columns.length,
    emptyStateTitle,
    emptyStateMessage,
    isFiltersApplied,
    skeleton,
    onRowClick,
  ]);

  return (
    <div className={cn('mb-2 w-full rounded-md', classNames?.wrapper)}>
      <Table className={classNames?.root}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn(
                      header.getSize() && `w-[${header.getSize()}px]`,
                      classNames?.head,
                      header.column.columnDef.meta?.className,
                    )}
                    pw-id="table-column"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>{body}</TableBody>
      </Table>
    </div>
  );
};
