import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActivityFilters } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActivitiesFiltersParameters } from '@purple/shared-types';

export const useActivitiesFilters = (parameters: TActivitiesFiltersParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [ACTIVITY_QUERY_KEYS.GET_ACTIVITY_FILTER_OPTIONS, parameters],
    queryFn: () => getActivityFilters(parameters),
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Unable to fetch activity filters');
    }
  }, [isError]);
  return { isError, ...rest };
};
