import { cn, Text } from '@purple/ui';
import { TipBox } from '../../../../TipBox';
import type { IStudentByIdFormEntity } from '../types';

type TStudentIdColumnViewProperties = React.PropsWithChildren<{
  student: IStudentByIdFormEntity;
  onEdit: () => void;
}>;

const StudentIdColumnView = ({ student, onEdit }: TStudentIdColumnViewProperties) => {
  const { id, isCorrect } = student;

  return (
    <button
      type="button"
      onClick={isCorrect ? () => null : onEdit}
      className={cn('flex min-w-[160px] flex-row flex-nowrap justify-between rounded px-3 py-2', {
        'bg-white:': isCorrect,
        'bg-error-bg': !isCorrect,
      })}
    >
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {id}
      </Text>
      {!isCorrect && (
        <TipBox text="Non-existent Student ID! Click on a row to edit." tooltipClassName="w-[200px] text-center" />
      )}
    </button>
  );
};

export { StudentIdColumnView };
