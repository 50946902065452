import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionBatchPurpleUserParticipantsBody } from '@purple/shared-types';

export const batchRemovePurpleParticipants = async (actionId: string, body: TActionBatchPurpleUserParticipantsBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.post<void>(
    replaceUrlParameters(ACTIONS.REMOVE_PURPLE_USER_PARTICIPANTS, actionId),
    body,
  );

  return response.data;
};
