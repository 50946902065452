import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { Button, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { ReadableTakeActionType } from '~/constants/take-action';
import { useCurrentUser } from '~/hooks/redux';
import type { ColumnDef } from '@tanstack/react-table';
import type { TNote, TTakeActionType } from '@purple/shared-types';

type TNotesOptions = {
  actionType: TTakeActionType;
  onDelete?: (note: TNote) => void;
};

type TNotesColumns = (options: TNotesOptions) => ColumnDef<TNote>[];

export const useActionNotesColumns: TNotesColumns = (options) => {
  const { user } = useCurrentUser();
  const { onDelete, actionType } = options || {};

  const getTitle = useCallback((original: TNote) => {
    return `[${original.created_by?.id === user.id && original.is_private ? 'My Notes' : 'Shared Notes'}] ${ReadableTakeActionType[actionType]} ${format(new Date(original.updated_at), 'MM/dd/yyyy')}`;
  }, [user, actionType]);

  const columns: ColumnDef<TNote>[] = useMemo(() => [
    {
      accessorKey: 'title',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Title
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500">
              <span className="block w-full truncate">
                {original.title || getTitle(original)}
              </span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {original.title || getTitle(original)}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[45%]' },
    },
    {
      accessorKey: 'created_by',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Created By
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
              <span className="block w-full truncate">{original.created_by?.full_name || '–'}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.created_by?.full_name && (
              <TooltipContent align="start" withArrow={false}>
                {original.created_by?.full_name}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[15%]' },
    },
    {
      accessorKey: 'last_modified',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Last Modified
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="text-grey-950">
              <span className="block w-full truncate">{formatDateToLocalTime(original.updated_at)}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.updated_at && (
              <TooltipContent align="start" withArrow={false}>
                {formatDateToLocalTime(original.updated_at)}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[18%]' },
    },
    {
      accessorKey: 'last_modified_by',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Last Modified By
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
              <span className="block w-full truncate">{original.last_modified_by?.full_name || '–'}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.last_modified_by?.full_name && (
              <TooltipContent align="start" withArrow={false}>
                {original.last_modified_by?.full_name}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[15%]' },
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) =>
        original.created_by?.id === user.id
          ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="destructive_secondary"
                    size="icon_32"
                    iconLeft={<PurpleIcon name="trash" />}
                    aria-label="Delete note"
                    className="ml-auto border-none bg-transparent"
                    onClick={(event) => {
                      event.stopPropagation();
                      onDelete?.(original);
                    }}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Remove note</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : null,
      meta: { className: 'w-[8%]' },
    },
  ], [user, onDelete, getTitle]);

  return columns;
};
