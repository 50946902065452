import { useMemo } from 'react';
import { cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type React from 'react';

type TChartLegendData = {
  name: string;
  value: number;
  color: string;
};

type TChartLegendProperties = React.HTMLAttributes<HTMLUListElement> & {
  /**
   * The chart data to display in the legend.
   */
  chartData: TChartLegendData[];
  /**
   * Indicates if the sorting is disabled.
   * @default false
   */
  disableSorting?: boolean;
};

export const ChartLegend: React.FC<TChartLegendProperties> = (props) => {
  const { chartData, disableSorting = false, className, ...other } = props;

  const sortedChartData = useMemo(
    () => (disableSorting ? chartData : [...chartData].sort((a, b) => b.value - a.value)),
    [chartData, disableSorting],
  );

  return (
    <ul className={cn('flex w-full flex-col gap-2', className)} {...other}>
      {sortedChartData.map((item) => (
        <li key={item.name} className="flex items-center justify-between">
          <p className="flex items-center gap-2">
            <span className="inline-block h-2 w-2 shrink-0 rounded-full" style={{ backgroundColor: item.color }} />
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 text-sm font-medium text-grey-600">{item.name}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{item.name}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </p>
          <strong className="text-sm font-semibold text-grey-950">{item.value.toLocaleString('en-US')}</strong>
        </li>
      ))}
    </ul>
  );
};
