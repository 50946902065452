import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TSchoolOverviewResponse } from '@purple/shared-types';

export const getSchoolOverview = async ({ schoolId }: { schoolId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOL_OVERVIEW, schoolId);

  const response = await axiosInstance.get<TSchoolOverviewResponse>(URL_PATH);

  return response.data;
};
