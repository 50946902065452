import { useMemo } from 'react';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CYCLE_FLAGS_COLOR, type IStudentDataEntity } from '@purple/shared-types';
import { getColorByRange } from '@purple/shared-utils';
import { Badge, Checkbox, Text } from '@purple/ui';
import { FlagBadge, LineChartCell, StudentCell } from '~/modules/Visualizer/StudentsData/components';
import { StudentRowOptions } from './StudentRowDotsOptions';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

type TUseStudentsDataColumns = (options: {
  canEdit: boolean;
  onCheck: (student: TSelectedStudent) => void;
  onCheckAll: () => void;
  selectedStudents: TSelectedStudent[];
  isSelectedAllOnPage: boolean;
  onShowChart: (student: IStudentDataEntity) => void;
  onRemove: (student: IStudentDataEntity) => void;
}) => ColumnDef<IStudentDataEntity, string>[];

export const useStudentsDataColumns: TUseStudentsDataColumns = ({
  canEdit,
  onCheck,
  onCheckAll,
  selectedStudents,
  isSelectedAllOnPage,
  onShowChart,
  onRemove,
}) => {
  const columnHelper = createColumnHelper<IStudentDataEntity>();

  const columns: ColumnDef<IStudentDataEntity, string>[] = useMemo(() => {
    const data = [
      columnHelper.accessor(() => 'checkbox', {
        id: 'checkbox',
        header: () => {
          return <Checkbox variant="checkedAll" onClick={onCheckAll} checked={isSelectedAllOnPage} />;
        },
        cell: ({ row: { original } }) => {
          const { id, first_name, last_name } = original;
          const newStudent = {
            id,
            name: `${first_name} ${last_name}`,
          };
          const isChecked = selectedStudents.map((student) => student.id).includes(id);
          return <Checkbox onClick={() => onCheck(newStudent)} checked={isChecked} />;
        },
      }),
      columnHelper.accessor(() => 'student', {
        id: 'student',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-grey-600">
            STUDENT
          </Text>
        ),
        cell: ({ row }) => {
          const { id, first_name, middle_name, last_name, photo, is_turned_red, subpop } = row.original;
          return (
            <StudentCell
              id={id}
              firstName={first_name}
              middleName={middle_name}
              lastName={last_name}
              avatar={photo}
              isTurnedRed={is_turned_red}
              subpop={subpop}
            />
          );
        },
      }),
      columnHelper.accessor(() => 'grade', {
        id: 'grade',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            GRADE
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-center text-grey-950">
            {row.original.grade || '-'}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'absences', {
        id: 'absences',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            ABSENCES
          </Text>
        ),
        cell: ({ row }) => {
          if (row.original.is_absences_red) {
            return (
              <div className="flex justify-center">
                <Badge
                  label={row.original.total_absences.toString()}
                  type="number"
                  className="border border-error-main bg-white text-error-main"
                />
              </div>
            );
          }
          return (
            <Text variant="size-14" type="body-500" className="text-center text-grey-950">
              {row.original.total_absences}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'safs', {
        id: 'safs',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            SAFs
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-center text-grey-950">
            {row.original.total_safs}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'actions', {
        id: 'actions',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            ACTIONS
          </Text>
        ),
        cell: ({ row }) => (
          <Text variant="size-14" type="body-500" className="text-center text-grey-950">
            {row.original.total_actions}
          </Text>
        ),
      }),
      columnHelper.accessor(() => 'previous_sy', {
        id: 'previous_sy',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            PREVIOUS SY
          </Text>
        ),
        cell: ({ row }) => {
          const { colorsRange, previous_year_avg_flags_per_cycle } = row.original;

          const isValueNull = previous_year_avg_flags_per_cycle === null;

          const value = isValueNull ? '-' : Number(previous_year_avg_flags_per_cycle);

          const color
            = isValueNull || typeof value === 'string' ? CYCLE_FLAGS_COLOR.GREEN : getColorByRange(value, colorsRange);
          return (
            <div className="flex justify-center">
              <FlagBadge color={color} value={value} />
            </div>
          );
        },
      }),
      columnHelper.accessor(() => 'current_sy', {
        id: 'current_sy',
        header: () => (
          <Text variant="size-12" type="body-600" className="text-center text-grey-600">
            CURRENT SY
          </Text>
        ),
        cell: ({ row }) => {
          const { colorsRange, current_year_avg_flags_per_cycle } = row.original;

          const isValueNull = current_year_avg_flags_per_cycle === null;
          const value = isValueNull ? '-' : Number(current_year_avg_flags_per_cycle);

          const color
            = isValueNull || typeof value === 'string' ? CYCLE_FLAGS_COLOR.GREEN : getColorByRange(value, colorsRange);
          return (
            <div className="flex justify-center">
              <FlagBadge color={color} value={value} />
            </div>
          );
        },
      }),
      columnHelper.accessor(() => 'color_trends', {
        id: 'color_trends',
        header: () => (
          <div className="flex w-full justify-center">
            <Text variant="size-12" type="body-600" className="w-[253px] text-center text-grey-600">
              COLOR TRENDS
            </Text>
          </div>
        ),
        cell: ({ row }) => {
          const { plot, colorsRange } = row.original;

          return (
            <div className="flex w-full items-center justify-center">
              <LineChartCell plot={plot} colorsRange={colorsRange} />
            </div>
          );
        },
      }),
      columnHelper.accessor(() => 'show_chart', {
        id: 'show_chart',
        header: () => <Text variant="size-12" type="body-600" className="text-center text-grey-600" />,
        cell: ({ row }) => {
          const isDisabled = row.original.plot.length === 0;
          return (
            <div className="flex items-center justify-center">
              <StudentRowOptions
                canRemove={canEdit}
                isChartDisabled={isDisabled}
                onShowChart={() => onShowChart(row.original)}
                onRemove={() => onRemove(row.original)}
              />
            </div>
          );
        },
      }),
    ];
    if (canEdit) {
      return data;
    }
    return data.slice(1);
  }, [columnHelper, canEdit, onCheckAll, isSelectedAllOnPage, selectedStudents, onCheck, onShowChart, onRemove]);

  return columns;
};
