import { useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Guard } from '@purple/permissions';
import { AccessDenied, NoDataAvailable } from '@purple/ui';
import { ActionTypeToPermission } from '~/constants/take-action';
import { useActionGroupDetails } from '~/queries';
import { GroupActionContainer, GroupActionDetailsSkeleton } from './components';
import type React from 'react';

export const GroupActionDetails: React.FC = () => {
  const { groupId } = useParams();

  const { data: groupAction, isPending } = useActionGroupDetails(groupId as string);

  if (!groupAction && !isPending) {
    return (
      <NoDataAvailable
        iconName="presentation-chart-bar"
        title="Group action data not available"
        description="For some reason, the group action data is not available. Please try again later."
        className="h-full w-full rounded-lg border border-grey-200 bg-white p-6 py-24"
      />
    );
  }

  return (
    <AnimatePresence mode="wait">
      {isPending
        ? (
            <motion.div
              key="skeleton"
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.3 }}
            >
              <GroupActionDetailsSkeleton />
            </motion.div>
          )
        : (
            <motion.div
              key="details"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -30 }}
              transition={{ duration: 0.3 }}
            >
              <Guard
                requiredPermissions={ActionTypeToPermission[groupAction.record_action_type]}
                fallback={(
                  <AccessDenied
                    accessDeniedMessage="You do not have permission to view group action details."
                    className="h-full w-full rounded-lg border border-grey-200 bg-white p-6 py-24"
                  />
                )}
              >
                <GroupActionContainer groupAction={groupAction} />
              </Guard>
            </motion.div>
          )}
    </AnimatePresence>
  );
};
