import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate } from '@purple/hooks';
import { AppSelectedFiltersList, Heading, Text } from '@purple/ui';
import {
  ActionEventModal,
  CommunityActivityModal,
  DefaultEcoSystemDataView,
  GroupActionModal,
  ReminderEventModal,
} from '~/components/Modals/EcoSystemHub';
import { ModalType } from '~/constants/modals';
import { useAppSelector, useModal } from '~/hooks';
import { useUsersEvents } from '~/queries/users/useUsersEvents';
import { userSelector } from '~/store/features/user';
import { ECO_SYSTEM_DATA_VIEW_VALUES } from './constants';
import { EcosystemListView } from './EcoSystemListView';
import { EcosystemCalendarView } from './EcosyustemCalendarView/EcosystemCalendarView';
import { FiltersSection } from './FiltersSection';
import { HeaderControls } from './HeaderControls';
import { useEcoSystemFilters } from './useEcoSystemFilters';
import { getCalendarViewByDataView, getEndDateByListView, getStartDateByListView } from './utils';
import type { View } from 'react-big-calendar';
import type { TEcoSystemDataViewValues } from './constants';

const LIST_VIEW_LIMIT = 10;
const CALENDAR_VIEW_LIMIT = 500;

export const EcoSystemHubTab: React.FC = () => {
  const { ecosystem_default_view } = useAppSelector(userSelector);

  const [searchParameters] = useSearchParams();
  const { filterOptions } = useEcoSystemFilters();

  const { isOpen: isActionModalOpen } = useModal(ModalType.ACTION_EVENT_MODAL);
  const { isOpen: isReminderModalOpen } = useModal(ModalType.REMINDER_EVENT_MODAL);
  const { isOpen: isGroupActionModalOpen } = useModal(ModalType.GROUP_ACTION_MODAL);

  const [ecoSystemDataView, setEcoSystemDataView] = useState<TEcoSystemDataViewValues>(ecosystem_default_view);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [calendarView, setCalendarView] = useState<View>(getCalendarViewByDataView(ecoSystemDataView));

  const requestLimit = useMemo(
    () => (ecoSystemDataView === ECO_SYSTEM_DATA_VIEW_VALUES.LIST ? LIST_VIEW_LIMIT : CALENDAR_VIEW_LIMIT),
    [ecoSystemDataView],
  );

  const paginate = usePaginate({ defaultLimit: requestLimit });

  const { data, isLoading } = useUsersEvents({
    offset: Number(searchParameters.get('offset')),
    limit: Number(searchParameters.get('limit')),
    type: searchParameters.get('type') ?? undefined,
    is_owner: searchParameters.get('is_owner') ?? 'true',
    is_reminders: searchParameters.get('is_reminders') ?? undefined,
    start_time__gte: getStartDateByListView(ecoSystemDataView, currentDate),
    end_time__lte: getEndDateByListView(ecoSystemDataView, currentDate),
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-6">
        <div className="flex flex-grow flex-col">
          <Heading variant="size-22" type="heading-600" className="text-nowrap">
            Ecosystem Hub
          </Heading>
          <Text variant="size-14" className="text-grey-600">
            {data?.owned_by_user ?? 0}
            {' '}
            Owned By You
          </Text>
        </div>
        <FiltersSection />
      </div>
      <AppSelectedFiltersList config={filterOptions} maxDisplayedFilters={8} />
      <div className="flex flex-col gap-4 rounded-lg border border-grey-200 bg-white pt-4 shadow-sm">
        <HeaderControls
          dataView={ecoSystemDataView}
          currentDate={currentDate}
          calendarView={calendarView}
          onChangeDataView={setEcoSystemDataView}
          onChangeCalendarView={setCalendarView}
          onChangeCurrentDate={setCurrentDate}
          onLimitChange={paginate.onLimitChange}
        />
        {ecoSystemDataView === ECO_SYSTEM_DATA_VIEW_VALUES.LIST
          ? (
              <EcosystemListView
                data={data}
                isEventsLoading={isLoading}
                paginating={paginate}
                onChangeSelectedEventId={setSelectedEventId}
              />
            )
          : (
              <EcosystemCalendarView
                currentDate={currentDate}
                data={data?.results}
                calenDarView={calendarView}
                onDataViewChange={setEcoSystemDataView}
                onChangeCalendarView={setCalendarView}
                onChangeDate={setCurrentDate}
                onChangeSelectedEventId={setSelectedEventId}
              />
            )}
      </div>
      <DefaultEcoSystemDataView />
      {isActionModalOpen && <ActionEventModal eventId={selectedEventId} onChangeSelectedEventId={setSelectedEventId} />}
      {isReminderModalOpen && (
        <ReminderEventModal eventId={selectedEventId} onChangeSelectedEventId={setSelectedEventId} />
      )}
      {isGroupActionModalOpen && (
        <GroupActionModal eventId={selectedEventId} onChangeSelectedEventId={setSelectedEventId} />
      )}
      <CommunityActivityModal eventId={selectedEventId} onChangeSelectedEventId={setSelectedEventId} />
    </div>
  );
};
