import { Fragment, useMemo } from 'react';
import { isFieldExist } from '@purple/shared-utils';
import { Separator } from '@purple/ui';
// components
import { GroupActionSectionHeader } from './GroupActionSectionHeader';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupCrisisFieldsSectionProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupCrisisFieldsSection: React.FC<TGroupCrisisFieldsSectionProperties> = (props) => {
  const { groupAction } = props;

  const fieldsData = useMemo(
    () =>
      [
        {
          title: 'Parent / Caregiver Conversation Summary',
          content: groupAction.parent_caregiver_conversation_summary ?? '-',
          available: isFieldExist(groupAction.parent_caregiver_conversation_summary),
        },
        {
          title: 'Risk Level Result Explanation',
          content: groupAction.risk_level_result_explanation ?? '-',
          available: isFieldExist(groupAction.risk_level_result_explanation),
        },
        {
          title: 'Follow Up Plan',
          content: groupAction.follow_up_plan ?? '-',
          available: isFieldExist(groupAction.follow_up_plan),
        },
      ].filter(({ available }) => available),
    [groupAction],
  );

  const isEmpty = useMemo(() => fieldsData.length === 0, [fieldsData]);

  if (isEmpty) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-2">
      {fieldsData.map(({ title, content }, index) => (
        <Fragment key={title}>
          <GroupActionSectionHeader title={title} disableEditing />
          <p className="text-balance pb-2 font-primary text-base font-normal text-grey-600">{content}</p>
          {index < fieldsData.length - 1 && <Separator />}
        </Fragment>
      ))}
    </div>
  );
};
