import { SAF_STATUS, type TSafListManagerDto } from '@purple/shared-types';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Badge, cn, Text } from '@purple/ui';

type TStatusColumnProperties = {
  data: TSafListManagerDto;
};

const StatusColumn = ({ data }: TStatusColumnProperties) => {
  const { status } = data;

  if (status) {
    return (
      <Badge
        label={snakeToCamelWithSpaces(status)}
        className={cn({
          'bg-brand-blue-100 text-brand-blue-700': status === SAF_STATUS.NEW,
          'bg-success-bg text-success-main': status === SAF_STATUS.PRIORITIZED,
          'bg-warning-bg text-warning-main': status === SAF_STATUS.ON_HOLD,
          'bg-grey-100 text-grey-600': status === SAF_STATUS.CLOSED,
        })}
      />
    );
  }
  return (
    <Text variant="size-14" type="body-500" className="text-grey-960">
      -
    </Text>
  );
};

export { StatusColumn };
