import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import type React from 'react';
import type { PurpleIconType } from '@purple/icons';

type TActivitySectionHeaderProperties = {
  /**
   * The title of the section.
   */
  title?: string | null;
  /**
   * Whether the section is in editing mode.
   * @default false
   */
  editing?: boolean;
  /**
   * Whether the section is in loading state.
   * @default false
   */
  loading?: boolean;
  /**
   * Whether the recurring activity is in loading state.
   * @default false
   */
  recurringLoading?: boolean;
  /**
   * Whether the recurring activity is allowed.
   */
  allowRecurring?: boolean;
  /**
   * The label for the edit button.
   * @default 'Edit'
   */
  editButtonLabel?: string;
  /**
   * Name of the icon for the edit button.
   * @default 'pencil'
   */
  editButtonIcon?: PurpleIconType;
  /**
   * Indicates whether the editing is disabled.
   * @default false
   */
  disableEditing?: boolean;
  /**
   * Callback when the edit button is clicked.
   */
  onEdit?: () => void;
  /**
   * Callback when the cancel button is clicked.
   */
  onCancel?: () => void;
  /**
   * Callback when the save button is clicked.
   */
  onSave?: () => void;
  /**
   * Callback when the recurring activity is saved.
   */
  onRecurringSave?: () => void;
};

export const ActivitySectionHeader: React.FC<TActivitySectionHeaderProperties> = (props) => {
  const {
    title = null,
    editing = false,
    loading = false,
    recurringLoading = false,
    disableEditing = false,
    allowRecurring = false,
    editButtonLabel = 'Edit',
    editButtonIcon = 'pencil',
    onEdit,
    onCancel,
    onSave,
    onRecurringSave,
  } = props;

  const editingControls = useMemo(
    () =>
      editing
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="button" variant="primary" size="small" isLoading={loading} onClick={onSave}>
                Save
              </Button>
              {allowRecurring && (
                <Button type="button" variant="primary" size="small" isLoading={recurringLoading} onClick={onRecurringSave}>
                  Save for all
                </Button>
              )}
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name={editButtonIcon} className="h-4 w-4 shrink-0" />}
              onClick={onEdit}
            >
              {editButtonLabel}
            </Button>
          ),
    [
      editing,
      loading,
      allowRecurring,
      onCancel,
      onSave,
      onRecurringSave,
      onEdit,
      recurringLoading,
      editButtonIcon,
      editButtonLabel,
    ],
  );

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      {title && (
        <Heading tag="h3" className="text-base font-semibold text-grey-950">
          {title}
        </Heading>
      )}
      {!disableEditing && editingControls}
    </div>
  );
};
