import { useMutation } from '@tanstack/react-query';
import { updateNote } from '@purple/shared-services';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';

export const useUpdateNote = () => {
  return useMutation({
    mutationKey: [NOTE_QUERY_KEYS.UPDATE_NOTE],
    mutationFn: updateNote,
  });
};
