import * as z from 'zod';
import { SPLIT_TEXT_BY_COMMA_OR_NEW_LINE } from '@purple/shared-utils';

export const ADD_STUDENTS_BY_ID_MODE = {
  DEFAULT: 'default',
  MANUAL: 'manual',
} as const;

export const addStudentsByIdSchema = z.object({
  studentIds: z
    .string({
      message: 'Invalid student ID(s) provided.',
    })
    .transform((input) =>
      input
        .split(SPLIT_TEXT_BY_COMMA_OR_NEW_LINE)
        .map((id) => id.trim())
        .filter((id) => id !== ''),
    )
    .refine((ids) => ids.length > 0, {
      message: 'At least one valid student ID is required.',
    }),
  manualStudentIds: z.array(z.string()),
  mode: z
    .enum([ADD_STUDENTS_BY_ID_MODE.DEFAULT, ADD_STUDENTS_BY_ID_MODE.MANUAL])
    .default(ADD_STUDENTS_BY_ID_MODE.DEFAULT),
});

export const changeStudentIdSchema = z.object({
  studentId: z.string().min(1, 'Student ID is required.'),
});
