import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { usePaginate } from '@purple/hooks';
import {
  CRISIS_GRADE_LEVEL_QUERY_NAME,
  CRISIS_TYPE_QUERY_NAME,
  SEARCH_QUERY_NAME,
  SORT_QUERY_NAME,
  TIME_PERIOD_NAME,
} from '@purple/shared-types';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { TableFooterSection } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSkeleton } from '~/components';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useBulkDeleteActivity, useCrisisActivities } from '~/queries';
import { CrisisContentStatisticSection } from './components/CrisisContentStatisticSection';
import { CrisisContentViewHeader } from './components/CrisisContentViewHeader';
import { useCrisisCallTrackerColumns } from './useCrisisCallTrackerColumns';
import type { TCrisisActivity } from '@purple/shared-types';

export const CrisisTeamContentView = () => {
  const queryCLient = useQueryClient();

  const { closeModal: closeBulkDeleteActivityModal } = useModal(ModalType.BULK_DELETE_CRISIS);

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);
  const timePeriod = searchParameters.get(TIME_PERIOD_NAME);
  const crisisType = searchParameters.get(CRISIS_TYPE_QUERY_NAME);
  const gradeLevel = searchParameters.get(CRISIS_GRADE_LEVEL_QUERY_NAME);
  const schoolId = searchParameters.get('school');

  const { page, limit, offset, onPageChange, onLimitChange } = usePaginate();

  const { data, isLoading, isFetching } = useCrisisActivities({
    limit,
    offset,
    search,
    school: schoolId,
    ordering: sort,
    crisis_type: crisisType,
    crisis_grade_level: gradeLevel,
    time_period: timePeriod,
  });

  const [deleteActivityId, setDeleteActivityId] = useState<string | null>(null);
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);

  const { mutate: bulkDeleteActivity, isPending: isBulkDeletePending } = useBulkDeleteActivity();

  const crisisItems = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => crisisItems.length > 0 && !isFetching,
    [crisisItems, isFetching],
  );

  const isAllSelected = useMemo(
    () =>
      selectedActivityIds.length > 0 ? crisisItems.every((crisis) => selectedActivityIds.includes(crisis.id)) : false,
    [crisisItems, selectedActivityIds],
  );

  const selectActivityHandler = useCallback(
    (crisis: TCrisisActivity) => {
      const index = selectedActivityIds.indexOf(crisis.id);
      setSelectedActivityIds((previous) =>
        index === -1 ? [...previous, crisis.id] : previous.filter((id) => id !== crisis.id),
      );
    },
    [selectedActivityIds],
  );

  const selectAllActivitiesHandler = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedActivityIds(crisisItems.map((crisis) => crisis.id));
      } else {
        setSelectedActivityIds([]);
      }
    },
    [crisisItems],
  );

  const bulkDeleteCrisisHandler = useCallback(() => {
    if (selectedActivityIds.length === 0) return;
    bulkDeleteActivity(
      { activities: selectedActivityIds },
      {
        onSuccess: () => {
          queryCLient.invalidateQueries({ queryKey: [ACTIVITY_QUERY_KEYS.GET_CRISIS_LIST] });
          const isDeletedWasSelected
            = deleteActivityId === null ? false : selectedActivityIds.includes(deleteActivityId);
          if (isDeletedWasSelected) {
            setDeleteActivityId(null);
          }
          setSelectedActivityIds([]);
          closeBulkDeleteActivityModal();
        },
      },
    );
  }, [bulkDeleteActivity, selectedActivityIds, closeBulkDeleteActivityModal, deleteActivityId, queryCLient]);

  const activityColumns = useCrisisCallTrackerColumns({
    isAllSelected,
    selectedActivityIds,
    onActivitySelect: selectActivityHandler,
    onSelectAll: selectAllActivitiesHandler,
  });

  return (
    <>
      <div className="flex flex-col gap-6">
        <CrisisContentStatisticSection data={data} isLoading={isLoading || isFetching} />
        <section className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
          <CrisisContentViewHeader isActivitiesSelected={selectedActivityIds.length > 0} />
          <div>
            <DataTable
              columns={activityColumns}
              data={crisisItems}
              isLoading={isFetching}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No crisis found"
              emptyStateMessage="It looks like we haven’t found any crisis activities. If something went wrong, please reach out to support."
              classNames={{
                wrapper: 'mb-0',
                cell: 'py-3 h-14',
                emptyCell: 'py-8',
              }}
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {isDataAvailable && (
              <TableFooterSection
                currentPage={page}
                rowsPerPage={limit}
                pageCount={pageCount}
                totalRows={data?.count || 0}
                onPageChange={onPageChange}
                onRowsPerPageChange={onLimitChange}
              />
            )}
          </div>
        </section>
      </div>
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_CRISIS}
        modalTitle="Delete selected activities"
        modalDescription="Clicking the delete button will permanently remove selected crisis from the system."
        modalTextContent="Are you sure you want to delete selected crisis?"
        onPrimaryButtonClick={bulkDeleteCrisisHandler}
        isLoading={isBulkDeletePending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
    </>
  );
};
