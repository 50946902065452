import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFiles } from '@purple/shared-services';
import { FILE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TFileListQueryParameters } from '@purple/shared-types';

export const useFiles = (parameters?: TFileListQueryParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST, parameters],
    queryFn: () => getFiles(parameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'An error occurred while fetching list of files');
    }
  }, [isError]);

  return { isError, ...rest };
};
