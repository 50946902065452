import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TDistrictInfo } from '@purple/shared-types';

type TDistrictState = {
  district: TDistrictInfo | null;
  isLoading: boolean;
};

const initialState: TDistrictState = {
  district: null,
  isLoading: false,
};

export const districtSlice = createSlice({
  name: AppReduxReducer.DISTRICT,
  initialState,
  reducers: {
    setDistrict: (state, action: PayloadAction<TDistrictInfo>) => {
      state.district = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setDistrict, setIsLoading } = districtSlice.actions;
export const districtReducer = districtSlice.reducer;
