import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useSafUnidentifiable } from '~/queries';

type TSafUnidentifiableModalProperties = {
  safId: string;
  onCloseCallBack?: () => void;
};

const SafUnidentifiableModal = ({ safId, onCloseCallBack }: TSafUnidentifiableModalProperties) => {
  const { isOpen, toggleModal } = useModal(ModalType.SAF_UNIDENTIFIABLE);

  const { mutate: makeUnidentifiable, isPending } = useSafUnidentifiable();

  const toggleModalCloseHandler = () => {
    onCloseCallBack?.();
    toggleModal(!isOpen);
  };

  const makeUnidentifiableHandler = () => {
    makeUnidentifiable(
      { safId },
      {
        onSuccess: () => {
          toggleModalCloseHandler();
        },
      },
    );
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={toggleModal}>
      <AlertDialogContent className="w-[564px]">
        <AlertDialogHeader className="flex-row items-center justify-between p-6">
          <div className="flex flex-col gap-2">
            <AlertDialogTitle>Is this student truly unidentifiable?</AlertDialogTitle>
            <AlertDialogDescription>
              Check the Student Information System to see if the student attends another school in the district before
              marking the SAF as unidentifiable.
            </AlertDialogDescription>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={toggleModalCloseHandler}>Cancel</AlertDialogCancel>
          <AlertDialogAction variant="primary" isLoading={isPending} onClick={makeUnidentifiableHandler}>
            Submit
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { SafUnidentifiableModal };
