import * as z from 'zod';

export const linkStudentFormSchema = z.object({
  student: z
    .object({
      label: z.string({ required_error: 'Please select a student' }),
      value: z.string({ required_error: 'Please select a student' }),
    })
    .refine((student) => student.label && student.value, {
      message: 'Please select a student',
    }),
});
