import { Text } from '@purple/ui';
import { useModal } from '~/hooks';
import type { TNote } from '@purple/shared-types';

type TNotesTableTitleCellProperties = {
  rowData: TNote;
  onSelectOption: (noteId: string) => void;
  onModeChange: (mode: 'create' | 'edit' | 'preview') => void;
};

export const NotesTableTitleCell = ({ rowData, onSelectOption, onModeChange }: TNotesTableTitleCellProperties) => {
  const { openModal } = useModal('student-note');

  const openStudentNoteModalHandler = () => {
    onSelectOption(rowData.id);
    onModeChange('preview');
    openModal();
  };

  return (
    <Text
      variant="size-14"
      type="body-500"
      className="max-w-[220px] cursor-pointer truncate text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
      onClick={openStudentNoteModalHandler}
    >
      {rowData.title}
    </Text>
  );
};
