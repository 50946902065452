import { getAxiosInstance } from '../../api';
import { DASHBOARD_ENDPOINTS } from '../../constants/private-api';
import type { TDashboardNewStudentsResponse } from '@purple/shared-types';

export const getDashboardNewStudents = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TDashboardNewStudentsResponse>(
    DASHBOARD_ENDPOINTS.GET_DASHBOARD_NEW_STUDENTS,
  );
  return response.data;
};
