import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolSafsList } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolSafsRequestParameters } from '@purple/shared-types';

export const useSafList = ({
  schoolId,
  queryParams,
}: {
  schoolId: string;
  queryParams: TSchoolSafsRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_SAFS_LIST, { schoolId, queryParams }],
    queryFn: () => getSchoolSafsList({ schoolId, queryParameters: queryParams }),
    enabled: !!schoolId,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch schools SAFs');
    }
  }, [isError]);
  return { data, isLoading };
};
