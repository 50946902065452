import { useMemo, useState } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Label,
  Message,
  RadioGroup,
  RadioGroupItem,
  Separator,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useDeleteOneTimeReminder, useDeleteRecurringReminder } from '~/queries';

type TReminderEventModalProperties = {
  /**
   * This id will be needed to delete only this reminder not the whole group
   */
  reminderId: string | null;
  /**
   * This id will be needed to delete the whole group of reminders
   */
  reminderGroupId?: string | null;
  /**
   * Callback function to be called on successful delete
   */
  onSuccessfulDelete?: () => void;
};

export const DeleteRecurringReminder = ({
  reminderId,
  reminderGroupId,
  onSuccessfulDelete,
}: TReminderEventModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('delete-recurring-reminder-event-modal');
  const [deleteEventType, setDeleteEventType] = useState<'single' | 'all'>('single');

  const { mutate: deleteOneTimeReminder, isPending: isOneTimeDeleting } = useDeleteOneTimeReminder();
  const { mutate: deleteRecurringReminder, isPending: isRecurringDeleting } = useDeleteRecurringReminder();

  const warningText
    = deleteEventType === 'single'
      ? 'Choosing this action will affect only current reminder'
      : 'Choosing this action will affect all reminders in the group';

  const isDeleting = useMemo(() => {
    return isOneTimeDeleting || isRecurringDeleting;
  }, [isOneTimeDeleting, isRecurringDeleting]);

  const deleteRecurringReminderHandler = () => {
    if (reminderId && deleteEventType === 'single') {
      return deleteOneTimeReminder(reminderId, {
        onSuccess: () => {
          onSuccessfulDelete?.();
          closeModal();
        },
      });
    }
    if (reminderGroupId && deleteEventType === 'all') {
      return deleteRecurringReminder(reminderGroupId, {
        onSuccess: () => {
          onSuccessfulDelete?.();
          closeModal();
        },
      });
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={toggleModal}>
      <AlertDialogContent className="w-[564px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Reminder</AlertDialogTitle>
          <AlertDialogDescription className="sr-only">Delete Reminder modal</AlertDialogDescription>
        </AlertDialogHeader>
        <Separator />
        <div className="flex flex-col gap-5 p-6">
          <div className="flex flex-col gap-2">
            <Text variant="size-14" type="body-600">
              Choose the reminder you want to delete:
            </Text>
            <RadioGroup
              onValueChange={(value: 'single' | 'all') => setDeleteEventType(value)}
              defaultValue={deleteEventType}
              className="flex flex-col gap-3"
            >
              <div className="flex items-center gap-x-2 space-y-0">
                <RadioGroupItem value="single" id="single" className="h-5 w-5" />
                <Label htmlFor="single">This Reminder</Label>
              </div>
              <div className="flex items-center gap-x-2 space-y-0">
                <RadioGroupItem value="all" id="all" className="h-5 w-5" />
                <Label htmlFor="all">All Reminders in the group</Label>
              </div>
            </RadioGroup>
          </div>
          <Message variant="error">
            <Text variant="size-14" type="body-500">
              {warningText}
            </Text>
          </Message>
        </div>
        <Separator />
        <AlertDialogFooter>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            onClick={deleteRecurringReminderHandler}
            disabled={isDeleting}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
