import React from 'react';
import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';
import { useUploadRichTextImage } from '~/queries';
import type { Editor } from '@tiptap/core';

export const FourthSection = ({ editor, objectId }: { editor: Editor; objectId: string }) => {
  const inputReference = React.useRef<HTMLInputElement>(null);

  const { mutate, isPending } = useUploadRichTextImage();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [file] = files || [];
    if (!file) return;

    mutate(
      {
        object_id: objectId,
        image: file,
        content_type: 'note',
      },
      {
        onSuccess: (data) => {
          editor.chain().setImage({ src: data.image }).focus().run();
        },
      },
    );
    inputReference.current!.value = '';
  };

  return (
    <>
      <button
        type="button"
        className={cn(
          'flex h-8 w-8 items-center justify-center rounded-md text-grey-600 hover:bg-grey-100',
          editor.isActive('image') && 'bg-grey-200',
        )}
        title="Upload Image"
        aria-label="upload image"
        disabled={isPending}
        onClick={(event) => handleClick(event)}
      >
        {isPending
          ? (
              <PurpleIcon name="loader" className="size-5 animate-spin" />
            )
          : (
              <PurpleIcon name="upload" className="size-5" />
            )}
      </button>
      <input type="file" accept="image/*" ref={inputReference} multiple className="hidden" onChange={handleFile} />
    </>
  );
};
