import { Skeleton } from '@purple/ui';

const DashboardSkeleton = () => {
  return (
    <div className="px-4 pb-4 pt-6">
      <div className="flex flex-col gap-4">
        <div>
          <Skeleton className="h-[28px] w-full" />
        </div>
        <div className="flex flex-1 flex-row gap-3">
          <Skeleton className="h-[132px] w-[50%]" />
          <Skeleton className="flex h-[132px] w-[50%] flex-1" />
        </div>
        <div className="flex gap-4">
          <Skeleton className="h-[136px] w-[33%]" />
          <Skeleton className="h-[136px] w-[33%]" />
          <Skeleton className="h-[136px] w-[33%]" />
        </div>
        <div className="flex flex-row items-center gap-4">
          <Skeleton className="h-[368px] w-full" />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Skeleton className="h-[368px]" />
          <Skeleton className="h-[368px]" />
          <Skeleton className="h-[368px]" />
          <Skeleton className="h-[368px]" />
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
