import { useMask } from '@react-input/mask';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, InfoItem, Input } from '@purple/ui';
import { InfoItemValue } from '~/components';
import { PHONE_MASK } from '~/constants/phone';
import type { UseFormReturn } from 'react-hook-form';
import type { TUpdateUserAccountDetails, TUserAccountDetails } from '@purple/shared-types';

type TDetailsEditProperties = {
  data: TUserAccountDetails;
  form: UseFormReturn<TUpdateUserAccountDetails>;
};

const DetailsEdit = ({ data, form }: TDetailsEditProperties) => {
  const { company_name, department } = data;

  const phoneInputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <InfoItem label="Title" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.title}
                  placeholder="Enter Title"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Email" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.email}
                  placeholder="Enter Email"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Phone Number">
        <FormField
          control={form.control}
          name="phone_number"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, phoneInputReference])}
                  isError={!!form.formState.errors.phone_number}
                  placeholder={PHONE_MASK}
                  type="tel"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      {/* Company Name can not be editable */}
      <InfoItem label="Company Name">
        <InfoItemValue>{company_name}</InfoItemValue>
      </InfoItem>
      {/* CDepartment can not be editable */}
      <InfoItem label="Department">
        <InfoItemValue>{department}</InfoItemValue>
      </InfoItem>
    </Form>
  );
};

export { DetailsEdit };
