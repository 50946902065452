export const SCHOOL_LEVEL_CHOICES = {
  EARLY_EDUCATION: 'Early Education',
  ELEMENTARY_SCHOOL: 'Elementary School',
  MIDDLE_SCHOOL: 'Middle School',
  HIGH_SCHOOL: 'High School',
  MULTILEVEL_SCHOOL: 'Multilevel School',
} as const;
export type TSchoolLevel = keyof typeof SCHOOL_LEVEL_CHOICES;

export const GRADE_CHOICES = {
  EE: 'EE',
  PK: 'PK',
  KG: 'KG',
  GRADE_1: 'Grade 1',
  GRADE_2: 'Grade 2',
  GRADE_3: 'Grade 3',
  GRADE_4: 'Grade 4',
  GRADE_5: 'Grade 5',
  GRADE_6: 'Grade 6',
  GRADE_7: 'Grade 7',
  GRADE_8: 'Grade 8',
  GRADE_9: 'Grade 9',
  GRADE_10: 'Grade 10',
  GRADE_11: 'Grade 11',
  GRADE_12: 'Grade 12',
  ADULT: 'Adult',
} as const;
export type TGrade = keyof typeof GRADE_CHOICES;

export const SCHOOL_SUBTYPE_CHOICES = {
  TRADITIONAL: 'Traditional',
  MAGNET: 'Magnet',
  CHARTER: 'Charter',
} as const;
export type TSchoolSubtype = keyof typeof SCHOOL_SUBTYPE_CHOICES;

export const SCHOOL_OFFICE_CHOICES = {
  ACHIEVE_180: 'Achieve 180',
  SPECIAL_EDUCATION: 'Special Education',
  ESO1: 'ESO1',
  ESO2: 'ESO2',
  ESO3: 'ESO3',
  HSO: 'HSO',
  MSO: 'MSO',
} as const;
export type TSchoolOffice = keyof typeof SCHOOL_OFFICE_CHOICES;

export const SCHOOL_DIVISION_CHOICES = {
  NORTH: 'North',
  SOUTH: 'South',
  CENTRAL: 'Central',
  WEST: 'West',
} as const;
export type TSchoolDivision = keyof typeof SCHOOL_DIVISION_CHOICES;

export const SUPER_NEIGHBORHOOD_CHOICES = {
  ACRES_HOME: 'Acres Home',
  AFTON_OAKS_RIVER_OAKS: 'Afton Oaks / River Oaks',
  ALIEF: 'Alief',
  ASTRODOME_AREA: 'Astrodome area',
  BELLAIRE: 'Bellaire',
  BRAEBURN: 'Braeburn',
  BRAESWOOD: 'Braeswood',
  BRAYS_OAKS: 'Brays Oaks',
  BRIAR_FOREST: 'Briar Forest',
  CENTRAL_NORTHWEST: 'Central Northwest',
  CENTRAL_SOUTHWEST: 'Central Southwest',
  CLINTON_PARK_TRI_COMMUNITY: 'Clinton Park / Tri-Community',
  DENVER_HARBOR_PORT_HOUSTON: 'Denver Harbor / Port Houston',
  DOWNTOWN: 'Downtown',
  EAST_HOUSTON: 'East Houston',
  EAST_LITTLE_YORK_HOMESTEAD: 'East Little York / Homestead',
  EASTEX_JENSEN: 'Eastex - Jensen',
  EL_DORADO_OATES_PRAIRIE: 'El Dorado / Oates Prairie',
  ELDRIDGE_WEST_OAKS: 'Eldridge / West Oaks',
  FAIRBANKS_NORTHWEST_CROSSING: 'Fairbanks / Northwest Crossing',
  FONDREN_GARDENS: 'Fondren Gardens',
  FORT_BEND_HOUSTON: 'Fort Bend / Houston',
  FOURTH_WARD: 'Fourth Ward',
  GOLFCREST_BELLFORT_REVEILLE: 'Golfcrest / Bellfort / Reveille',
  GREATER_EASTWOOD: 'Greater Eastwood',
  GREATER_FIFTH_WARD: 'Greater Fifth Ward',
  GREATER_GREENSPOINT: 'Greater Greenspoint',
  GREATER_HEIGHTS: 'Greater Heights',
  GREATER_HOBBY_AREA: 'Greater Hobby Area',
  GREATER_INWOOD: 'Greater Inwood',
  GREATER_OST_SOUTH_UNION: 'Greater OST / South Union',
  GREATER_THIRD_WARD: 'Greater Third Ward',
  GREATER_UPTOWN: 'Greater Uptown',
  GREENWAY_UPPER_KIRBY: 'Greenway / Upper Kirby',
  GUFLGATE_RIVERVIEW_PINE_VALLEY: 'Guflgate Riverview / Pine Valley',
  GULFTON: 'Gulfton',
  HARRISBURG_MANCHESTER: 'Harrisburg / Manchester',
  HIDDEN_VALLEY: 'Hidden Valley',
  HUNTERWOOD: 'Hunterwood',
  INDEPENDENCE_HEIGHTS: 'Independence Heights',
  KASHMERE_GARDENS: 'Kashmere Gardens',
  LANGWOOD: 'Langwood',
  LAWNDALE_WAYSIDE: 'Lawndale / Wayside',
  LAZYBROOK_TIMBERGROVE: 'Lazybrook / Timbergrove',
  MACGREGOR: 'MacGregor',
  MAGNOLIA_PARK: 'Magnolia Park',
  MEADOWBROOK_ALLENDALE: 'Meadowbrook / Allendale',
  MEDICAL_CENTER: 'Medical Center',
  MEMORIAL: 'Memorial',
  MEYERLAND: 'Meyerland',
  MID_WEST: 'Mid-West',
  MIDTOWN: 'Midtown',
  MINNETEX: 'Minnetex',
  MUSEUM_PARK: 'Museum Park',
  NEAR_NORTHSIDE: 'Near Northside',
  NEAR_SOUTHWEST: 'Near Southwest',
  NEARTOWN_MONTROSE: 'Neartown / Montrose',
  NORTHSHORE: 'Northshore',
  NORTHSIDE_NORTHLINE: 'Northside / Northline',
  PARK_PLACE: 'Park Place',
  PECAN_PARK: 'Pecan Park',
  PLEASANTVILLE_AREA: 'Pleasantville Area',
  SECOND_WARD: 'Second Ward',
  SETTEGAST: 'Settegast',
  SHARPSTOWN: 'Sharpstown',
  SOUTH_ACRES_CRESTMONT_PARK: 'South Acres / Crestmont Park',
  SOUTH_BELT_ELLINGTON: 'South Belt / Ellington',
  SOUTH_MAIN: 'South Main',
  SOUTH_PARK: 'South Park',
  SPRING_BRANCH_EAST: 'Spring Branch East',
  SUNNYSIDE: 'Sunnyside',
  TRINITY_HOUSTON_GARDENS: 'Trinity / Houston Gardens',
  UNIVERSITY_PLACE: 'University Place',
  WASHINGTON_AVENUE_COALITION_MEMORIAL_PARK: 'Washington Avenue Coalition / Memorial Park',
  WEST_U: 'West U',
  WESTBURY: 'Westbury',
  WESTCHASE: 'Westchase',
  WESTWOOD: 'Westwood',
  WILLOW_MEADOWS_WILLOWBEND: 'Willow Meadows / Willowbend',
} as const;
export type TSuperNeighborhood = keyof typeof SUPER_NEIGHBORHOOD_CHOICES;

export const TRUSTEE_DISTRICT_REGION_CHOICES = {
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
  VII: 'VII',
  VIII: 'VIII',
  IX: 'IX',
} as const;
export type TTrusteeDistrictRegion = keyof typeof TRUSTEE_DISTRICT_REGION_CHOICES;

export const CITY_COUNCIL_DISTRICT_CHOICES = {
  DISTRICT_A: 'District A',
  DISTRICT_B: 'District B',
  DISTRICT_C: 'District C',
  DISTRICT_D: 'District D',
  DISTRICT_E: 'District E',
  DISTRICT_F: 'District F',
  DISTRICT_G: 'District G',
  DISTRICT_H: 'District H',
  DISTRICT_I: 'District I',
  DISTRICT_J: 'District J',
  DISTRICT_K: 'District K',
} as const;
export type TCityCouncilDistrict = keyof typeof CITY_COUNCIL_DISTRICT_CHOICES;
