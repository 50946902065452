import { Skeleton } from '@purple/ui';

const DetailViewHeaderSkeleton = () => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col gap-4">
        <Skeleton className="h-[24px] w-[516px]" />
        <Skeleton className="h-[24px] w-[516px]" />
        <Skeleton className="h-[24px] w-[516px]" />
      </div>

      <Skeleton className="h-[40px] w-[167px]" />
    </div>
  );
};

export { DetailViewHeaderSkeleton };
