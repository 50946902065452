import { getTransformObjectArrayToLabelValuePairs, snakeToCamelWithSpaces } from '@purple/shared-utils';
import type {
  TSchoolOverviewData,
  TSchoolOverviewLinkedStudentsData,
  TSchoolOverviewLinkedStudentsResponse,
  TSchoolOverviewResponse,
} from '@purple/shared-types';

const COLOR_TREND_MAP: Record<string, string> = {
  green: '#1BC1A1',
  yellow: '#E1C122',
  red: '#AE5983',
};

const applyColorTrend = (data: Array<{ label: string; value: number; color?: string }>) => {
  return data.map((item) => ({
    ...item,
    color: COLOR_TREND_MAP[item.label.toLowerCase()],
  }));
};

const transformLabelToUppercase = (data: Array<{ label: string; value: number }>) => {
  return data.map((item) => ({
    label: snakeToCamelWithSpaces(item.label),
    value: item.value,
  }));
};

export const convertSchoolOverview = (data: TSchoolOverviewResponse): TSchoolOverviewData => {
  return {
    ...data,
    safs: transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.safs)),
    actions: transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.actions)),
    programs: transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.programs)),
    color_trend: applyColorTrend(transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.color_trend))),
    subpopulations: transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.subpopulations)),
  };
};

export const convertSchoolOverviewLinkedStudents = (
  data: TSchoolOverviewLinkedStudentsResponse,
): TSchoolOverviewLinkedStudentsData => {
  return {
    linked_students: transformLabelToUppercase(getTransformObjectArrayToLabelValuePairs(data.data)),
  };
};
