import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { IPaginatedResponse, TStudentSafItem, TStudentSafsRequestParameters } from '@purple/shared-types';

export const getStudentSafsList = async ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentSafsRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_SAFS_LIST, studentId);
  const response = await axiosInstance.get<IPaginatedResponse<TStudentSafItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
