import { PAGE_VIEW_QUERIES } from '@purple/shared-types';
import { useDetailsView } from '~/hooks/useDetailsView';
import MyPriorityListDetailView from './MyPriorityListDetailView';
import MyPriorityListsView from './MyPriorityListsView';

const ENTITY_QUERY_NAME = 'list_id' as const;

const MyPriorityListsContainer = () => {
  const { view } = useDetailsView({
    entityQueryKey: ENTITY_QUERY_NAME,
    defaultView: PAGE_VIEW_QUERIES.LIST_VIEW,
  });

  if (view === 'detail_view') {
    return <MyPriorityListDetailView />;
  }

  return <MyPriorityListsView />;
};

export { MyPriorityListsContainer };
