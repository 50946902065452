import { APP_PERMISSIONS } from '@purple/permissions';
import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType, TakeActionType } from '@purple/shared-types';

export const ReadableTakeActionType = {
  [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: 'Academic/Career Planning Session',
  [TakeActionType.CHECK_IN]: 'Check-In',
  [TakeActionType.COUNSELING_SESSION]: 'Counseling Session',
  [TakeActionType.DISTRICT_INITIATIVE]: 'District Initiative',
  [TakeActionType.GUIDANCE_COUNSELING_LESSON]: 'Guidance/Counseling Lesson',
  [TakeActionType.OBSERVATION]: 'Observation',
  [TakeActionType.CRISIS_RESPONSE_SESSION]: 'Crisis Response Session',
  [TakeActionType.REMINDER]: 'Reminder',
  [TakeActionType.RESOURCE]: 'Resource',
  [TakeActionType.SERVICE_LINK]: 'Refer/Connect Student to a Program',
  [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]: 'Stakeholder Collaborative Meeting',
} as const;

export const ReadableTakeActionDocumentType = {
  [TakeActionDocumentType.GENERAL]: 'General',
  [TakeActionDocumentType.TARGETED]: 'Targeted',
  [TakeActionDocumentType.ATTEMPTED]: 'Attempted',
} as const;

export const ReadableTakeActionLinkType = {
  [TakeActionLinkType.INDIVIDUAL]: 'Individual',
  [TakeActionLinkType.GROUP]: 'Group',
} as const;

export const ReadableTakeActionGroupType = {
  [TakeActionGroupType.NEW]: 'New group',
  [TakeActionGroupType.EXISTING]: 'Link to existing group',
} as const;

export const TAKE_ACTION_SERVICE_LIST = [
  {
    iconName: 'link',
    type: TakeActionType.SERVICE_LINK,
    label: 'Refer/Connect Student to a Program',
    permissions: [APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS, APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD],
  },
] as const;

export const TAKE_ACTION_LIST = [
  {
    iconName: 'academic-cap',
    type: TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION,
    label: ReadableTakeActionType[TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_ACADEMIC_CAREER_PLANNING_SESSION_ACTION,
    ],
  },
  {
    iconName: 'chat-alt-2',
    type: TakeActionType.CHECK_IN,
    label: ReadableTakeActionType[TakeActionType.CHECK_IN],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_CHECK_IN_ACTION,
    ],
  },
  {
    iconName: 'users',
    type: TakeActionType.COUNSELING_SESSION,
    label: ReadableTakeActionType[TakeActionType.COUNSELING_SESSION],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_COUNSELING_SESSION_ACTION,
    ],
  },
  {
    iconName: 'exclamation',
    type: TakeActionType.CRISIS_RESPONSE_SESSION,
    label: ReadableTakeActionType[TakeActionType.CRISIS_RESPONSE_SESSION],
    allowMultipleStudents: false,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_CRISIS_RESPONSE_SESSION_LESSON_ACTION,
    ],
  },
  {
    iconName: 'library',
    type: TakeActionType.DISTRICT_INITIATIVE,
    label: ReadableTakeActionType[TakeActionType.DISTRICT_INITIATIVE],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_DISTRICT_INITIATIVE_ACTION,
    ],
  },
  {
    iconName: 'book-open',
    type: TakeActionType.GUIDANCE_COUNSELING_LESSON,
    label: ReadableTakeActionType[TakeActionType.GUIDANCE_COUNSELING_LESSON],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_GUIDANCE_COUNSELING_LESSON_ACTION,
    ],
  },
  {
    iconName: 'eye',
    type: TakeActionType.OBSERVATION,
    label: ReadableTakeActionType[TakeActionType.OBSERVATION],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_OBSERVATION_ACTION,
    ],
  },
  {
    iconName: 'bell',
    type: TakeActionType.REMINDER,
    label: ReadableTakeActionType[TakeActionType.REMINDER],
    allowMultipleStudents: false,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_REMINDERS,
    ],
  },
  {
    iconName: 'collection',
    type: TakeActionType.RESOURCE,
    label: ReadableTakeActionType[TakeActionType.RESOURCE],
    allowMultipleStudents: true,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_RESOURCE_ACTION,
    ],
  },
  {
    iconName: 'user-group',
    type: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
    label: ReadableTakeActionType[TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING],
    allowMultipleStudents: false,
    permissions: [
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_STAKEHOLDER_COLLABORATIVE_MEETING_ACTION,
    ],
  },
] as const;

export const ActionTypeToPermission = {
  [TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION]: APP_PERMISSIONS.CAN_ACCESS_ACADEMIC_CAREER_PLANNING_SESSION_ACTION,
  [TakeActionType.CHECK_IN]: APP_PERMISSIONS.CAN_ACCESS_CHECK_IN_ACTION,
  [TakeActionType.COUNSELING_SESSION]: APP_PERMISSIONS.CAN_ACCESS_COUNSELING_SESSION_ACTION,
  [TakeActionType.CRISIS_RESPONSE_SESSION]: APP_PERMISSIONS.CAN_ACCESS_CRISIS_RESPONSE_SESSION_LESSON_ACTION,
  [TakeActionType.DISTRICT_INITIATIVE]: APP_PERMISSIONS.CAN_ACCESS_DISTRICT_INITIATIVE_ACTION,
  [TakeActionType.GUIDANCE_COUNSELING_LESSON]: APP_PERMISSIONS.CAN_ACCESS_GUIDANCE_COUNSELING_LESSON_ACTION,
  [TakeActionType.OBSERVATION]: APP_PERMISSIONS.CAN_ACCESS_OBSERVATION_ACTION,
  [TakeActionType.REMINDER]: APP_PERMISSIONS.CAN_ACCESS_REMINDERS,
  [TakeActionType.RESOURCE]: APP_PERMISSIONS.CAN_ACCESS_RESOURCE_ACTION,
  [TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING]:
    APP_PERMISSIONS.CAN_ACCESS_STAKEHOLDER_COLLABORATIVE_MEETING_ACTION,
  [TakeActionType.SERVICE_LINK]: null,
} as const;
