import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppLoaderScreen } from '@purple/ui';
import { useSendChangeEmailMutation } from '~/queries';

const ConfirmEmail = () => {
  const { userId, token } = useParams();

  const userUID = userId || '';
  const resetToken = token || '';

  const { mutate } = useSendChangeEmailMutation();

  useEffect(() => {
    if (userUID && resetToken) {
      mutate({ uid: userUID, token: resetToken });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <AppLoaderScreen />;
};

export { ConfirmEmail };
