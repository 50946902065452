import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  DatePicker,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  Separator,
  Textarea,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useSafPlaceOnHold } from '~/queries';
import { SAF_PLACE_ON_HOLD_REASON_OPTIONS } from './constants';
import { safPlaceOnHoldSchema } from './schema';
import type * as z from 'zod';

type TSafPlaceOnHoldModalProperties = {
  safId: string;
  onCloseCallBack?: () => void;
};

const SafPlaceOnHoldModal = ({ safId, onCloseCallBack }: TSafPlaceOnHoldModalProperties) => {
  const { isOpen, toggleModal } = useModal(ModalType.SAF_PLACE_ON_HOLD);

  const { mutate: placeOnHold, isPending } = useSafPlaceOnHold();

  const form = useForm<z.infer<typeof safPlaceOnHoldSchema>>({
    resolver: zodResolver(safPlaceOnHoldSchema),
    mode: 'onChange',
    defaultValues: {
      on_hold_reason: '',
      on_hold_reason_text: '',
      on_hold_until: null,
    },
  });

  const toggleModalCloseHandler = () => {
    onCloseCallBack?.();
    toggleModal(!isOpen);
    form.reset();
  };

  const placeOnHoldHandler = ({
    on_hold_reason,
    on_hold_reason_text,
    on_hold_until,
  }: z.infer<typeof safPlaceOnHoldSchema>) => {
    const body = {
      on_hold_reason,
      ...(on_hold_reason_text ? { on_hold_reason_text } : {}),
      ...(on_hold_until ? { on_hold_until: on_hold_until.toISOString() } : {}),
    };

    placeOnHold(
      { safId, body },
      {
        onSuccess: () => {
          toggleModalCloseHandler();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(placeOnHoldHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>Place On Hold</DialogTitle>
            <DialogDescription className="sr-only">Saf Place on Hold Modal</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full flex-col gap-4 p-6">
            <FormField
              control={form.control}
              name="on_hold_reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Reason</FormLabel>
                  <FormControl>
                    <RadioGroup onValueChange={field.onChange} defaultValue={field.value}>
                      {SAF_PLACE_ON_HOLD_REASON_OPTIONS.map(({ label, value }) => (
                        <FormItem key={value} className="flex items-center gap-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={value} />
                          </FormControl>
                          <FormLabel>{label}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="on_hold_reason_text"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Details</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      isError={!!form.formState.errors.on_hold_reason_text}
                      placeholder="Enter details here"
                      responsiveHeight
                      className="min-h-[80px] resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="on_hold_until"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Reminder</FormLabel>
                  <FormControl>
                    <DatePicker
                      mode="single"
                      placeholder="Select date & time"
                      formatterString="PPP p"
                      isError={!!fieldState.error}
                      triggerDisabled={field.disabled}
                      selected={field.value ?? undefined}
                      month={field.value ?? undefined}
                      defaultMonth={field.value ?? undefined}
                      captionLayout="dropdown"
                      onDayClick={field.onChange}
                      onTimeChange={field.onChange}
                      disabled={{
                        before: new Date(),
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button type="button" variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { SafPlaceOnHoldModal };
