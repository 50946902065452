export type ColorRanges = {
  green: [number, number];
  yellow: [number, number];
  red: [number];
};

export const CYCLE_FLAGS_COLOR = {
  GREEN: '#23A477',
  YELLOW: '#E1C122',
  RED: '#F54949',
};

export const COLOR_LETTER = {
  [CYCLE_FLAGS_COLOR.GREEN]: 'G',
  [CYCLE_FLAGS_COLOR.YELLOW]: 'Y',
  [CYCLE_FLAGS_COLOR.RED]: 'R',
};
