import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { formatDateToLocalTime, formateDateShortMonth } from '@purple/shared-utils';
import { Button, Checkbox, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { ReadableActivityType } from '~/constants/create-activity';
import { AppRoutes } from '~/constants/routes/routes';
import { useCurrentUser } from '~/hooks';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActivity } from '@purple/shared-types';

type TActivityOptions = {
  isAllSelected?: boolean;
  selectedActivityIds?: string[];
  onActivitySelect?: (activity: TActivity) => void;
  onSelectAll?: (checked: boolean) => void;
  onDelete?: (activityId: string) => void;
};

type TActivityColumns = (options: TActivityOptions) => ColumnDef<TActivity>[];

export const useActivityColumns: TActivityColumns = (options) => {
  const { isAllSelected, selectedActivityIds = [], onActivitySelect, onSelectAll, onDelete } = options;

  const { user } = useCurrentUser();

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'select',
          meta: { className: 'max-w-[60px] min-w-[60px] w-[60px] leading-[0]' },
          header: ({ table }) => table.getRowModel().rows.some((row) => row.original.created_by?.id === user.id)
            ? <Checkbox variant="checkedAll" onCheckedChange={onSelectAll} checked={isAllSelected} />
            : <span className="size-5 inline-block shrink-0" />,
          cell: ({ row: { original } }) => original.created_by?.id === user.id
            ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="leading-[0]">
                      <Checkbox
                        onCheckedChange={() => onActivitySelect?.(original)}
                        checked={selectedActivityIds.includes(original.id)}
                      />
                    </span>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Toggle selection</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              )
            : null,
        },
        {
          accessorKey: 'name',
          meta: { className: 'min-w-[200px] max-w-[200px] w-[200px]' },
          header: () => (
            <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
              Activity Name
            </Text>
          ),
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AppRoutes.App.Community.Details.makePath(original.id)}
                  className="inline-block max-w-[160px] cursor-pointer truncate align-middle font-primary font-medium text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
                >
                  {original.name}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>{original.name}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
        },
        {
          accessorKey: 'type',
          meta: { className: 'min-w-[240px] max-w-[240px] w-[240px]' },
          header: () => (
            <Text variant="size-12" type="body-600" className="min-w-[180px] max-w-[180px] uppercase text-grey-600">
              Record Type
            </Text>
          ),
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text variant="size-14" type="body-500" className="max-w-[200px] truncate text-grey-950">
                  {ReadableActivityType[original.community_activity_type.name]}
                </Text>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {ReadableActivityType[original.community_activity_type.name]}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
        },
        {
          accessorKey: 'created-by',
          meta: { className: 'min-w-[160px] max-w-[160px] w-[160px]' },
          header: () => (
            <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
              Created By
            </Text>
          ),
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text variant="size-14" type="body-500" className="truncate text-grey-950">
                  {original.created_by?.full_name ?? '-'}
                </Text>
              </TooltipTrigger>
              {original.created_by && (
                <TooltipPortal>
                  <TooltipContent withArrow={false}>{original.created_by.full_name ?? '-'}</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
        },
        {
          accessorKey: 'date',
          meta: { className: 'min-w-[160px] w-full' },
          header: () => (
            <Text variant="size-12" type="body-600" className="min-w-[120px] uppercase text-grey-600">
              Date & Time
            </Text>
          ),
          cell: ({ row: { original } }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text variant="size-14" type="body-500" className="text-grey-950">
                  {original.date_and_time ? formateDateShortMonth(original.date_and_time) : '-'}
                </Text>
              </TooltipTrigger>
              {original.date_and_time && (
                <TooltipPortal>
                  <TooltipContent withArrow={false}>{formatDateToLocalTime(original.date_and_time)}</TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
        },
        {
          accessorKey: 'actions',
          meta: { className: 'max-w-[72px] min-w-[72px] w-[72px]' },
          header: () => null,
          cell: ({ row: { original } }) => original.created_by?.id === user.id
            ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="destructive_secondary"
                      aria-label="Remove activity"
                      iconLeft={<PurpleIcon name="trash" />}
                      size="icon_32"
                      className="border-none"
                      onClick={() => onDelete?.(original.id)}
                    />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Remove activity</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              )
            : null,
        },
      ] satisfies ColumnDef<TActivity>[],
    [isAllSelected, selectedActivityIds, user.id, onActivitySelect, onSelectAll, onDelete],
  );

  return columns;
};
