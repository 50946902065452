import { Heading, Text } from '@purple/ui';
import { VisualizerTabs } from '~/modules/Visualizer';

export const StudentsVisualizerPage: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-8">
      <div className="flex flex-col gap-1">
        <Heading variant="size-28" type="heading-600" tag="h1" className="text-grey-title">
          Students Visualizer
        </Heading>
        <Text variant="size-14" type="body-400" className="text-grey-950">
          Student data is collected through your district`s student information system (SIS).
        </Text>
      </div>
      <div className="flex w-full flex-col">
        <VisualizerTabs />
      </div>
    </div>
  );
};
