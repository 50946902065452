import { useMutation } from '@tanstack/react-query';
import { updateSchoolInformation } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useUpdateSchool = () => {
  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.UPDATE_SCHOOL],
    mutationFn: updateSchoolInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_BY_ID] });
      showSuccessToast('System message', 'School information updated successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not update school information. Check the provided information and try again',
      );
    },
  });
};
