import { useTranslation } from 'react-i18next';
import { Button, useStepper } from '@purple/ui';
import type React from 'react';

type TFormActionsProperties = {
  /**
   * The Id of the form that the actions are associated with.
   */
  formId: string;
  /**
   * Whether the primary button should be disabled
   * @default false
   */
  primaryButtonDisabled?: boolean;
  /**
   * Whether the form is submitting
   * @default false
   */
  isSubmitting?: boolean;
  /**
   * Callback for when the primary button is clicked.
   */
  onPreviousClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Callback for when the next button is clicked.
   */
  onNextClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const FormActions: React.FC<TFormActionsProperties> = (props) => {
  const { formId, primaryButtonDisabled = false, isSubmitting = false, onPreviousClick, onNextClick } = props;

  const { isLastStep } = useStepper();
  const { t } = useTranslation('saf');

  return (
    <div className="grid w-full max-w-[792px] grid-cols-2 gap-4">
      <Button onClick={onPreviousClick} variant="secondary">
        {t('controls.prev')}
      </Button>
      {isLastStep
        ? (
            <Button type="submit" disabled={primaryButtonDisabled} form={formId} isLoading={isSubmitting}>
              {t('controls.submit')}
            </Button>
          )
        : (
            <Button type="button" disabled={primaryButtonDisabled} onClick={onNextClick}>
              {t('controls.next')}
            </Button>
          )}
    </div>
  );
};
