import { CYCLE_FLAGS_COLOR } from '@purple/shared-types';
import { Badge, cn } from '@purple/ui';

type TFlagBadgeProperties = React.PropsWithChildren<{
  color: string;
  value: string | number;
}>;

const FlagBadge = ({ color, value }: TFlagBadgeProperties) => {
  return (
    <Badge
      label={value.toString() || '-'}
      type="number"
      className={cn({
        'bg-success-bg text-success-main': color === CYCLE_FLAGS_COLOR.GREEN,
        'bg-warning-bg text-warning-main': color === CYCLE_FLAGS_COLOR.YELLOW,
        'bg-error-bg text-error-main': color === CYCLE_FLAGS_COLOR.RED,
      })}
    />
  );
};

export { FlagBadge };
