import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFile } from '@purple/shared-services';
import { FILE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [FILE_QUERY_KEYS.DELETE_FILE],
    mutationFn: deleteFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
      showSuccessToast('System Message', 'File deleted successfully');
    },
    onError: () => {
      showErrorToast('System Message', 'Failed to delete file');
    },
  });
};
