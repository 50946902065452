import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import {
  Badge,
  Button,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { OverdueAttendanceSkeleton } from './OverdueAttendanceSkeleton';
import type React from 'react';
import type { TDashboardOverdueAttendance } from '@purple/shared-types';

const DEFAULT_OVERDUE_ATTENDANCE: TDashboardOverdueAttendance[] = [];

type TOverdueAttendanceProperties = {
  /**
   * Overdue attendance data over selected period.
   */
  data?: TDashboardOverdueAttendance[];
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const OverdueAttendance: React.FC<TOverdueAttendanceProperties> = (props) => {
  const { data = DEFAULT_OVERDUE_ATTENDANCE, isLoading = false } = props;

  if (isLoading) {
    return <OverdueAttendanceSkeleton />;
  }

  return (
    <div className="col-span-2 flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white pt-6 shadow-custom-medium">
      <Heading tag="h3" variant="size-18" type="heading-600" className="px-4 text-base text-grey-title">
        Overdue Attendance
      </Heading>
      <Table>
        <TableHeader>
          <TableRow className="border-grey-300">
            <TableHead className="w-4/12 shrink px-5 py-4 text-left text-xs font-semibold uppercase text-grey-600">
              Actions
            </TableHead>
            <TableHead className="w-1/5 px-3 py-4 text-left text-xs font-semibold uppercase text-grey-600">
              Meeting record name
            </TableHead>
            <TableHead className="w-1/12 px-3 py-4 text-center text-xs font-semibold uppercase text-grey-600">
              Status
            </TableHead>
            <TableHead className="w-2/12 px-3 py-4 text-center text-xs font-semibold uppercase text-grey-600">
              Service Provider
            </TableHead>
            <TableHead className="w-2/12 px-3 py-4 text-center text-xs font-semibold uppercase text-grey-600">
              Group service
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} className="border-grey-200 last-of-type:border-0">
              <TableCell className="flex w-4/12 shrink items-center justify-start gap-2 px-3 py-3 text-sm text-grey-800">
                <DropdownRoot>
                  <DropdownTrigger asChild>
                    <Button
                      type="button"
                      aria-label="Options"
                      variant="tertiary_icon_only"
                      size="icon_32"
                      className="text-grey-600"
                      iconLeft={<PurpleIcon name="dots-vertical" className="h-5 w-5 shrink-0" />}
                    />
                  </DropdownTrigger>
                  <DropdownContent className="min-w-max" align="start" side="right">
                    <DropdownItem iconName="chat-alt">Send Message</DropdownItem>
                    <DropdownItem iconName="user-group">Manage Members</DropdownItem>
                    <DropdownItem iconName="Calendar-Clock">Reschedule Meeting</DropdownItem>
                    <DropdownItem iconName="X">Cancel Meeting</DropdownItem>
                  </DropdownContent>
                </DropdownRoot>
                <Button
                  type="button"
                  aria-label="Message"
                  variant="tertiary_icon_only"
                  size="icon_32"
                  className="text-grey-600"
                  iconLeft={<PurpleIcon name="chat-alt-2" className="h-5 w-5 shrink-0" />}
                />
                <Button
                  type="button"
                  aria-label="Edit"
                  variant="tertiary_icon_only"
                  size="icon_32"
                  className="text-grey-600"
                  iconLeft={<PurpleIcon name="pencil-alt" className="h-5 w-5 shrink-0" />}
                />
                <Button type="button" variant="table" size="small">
                  Take Attendance
                </Button>
              </TableCell>
              <TableCell className="w-1/5 px-3 py-3">
                {/* TODO: Replace link path to valid meeting record page */}
                <Link
                  to={AppRoutes.App.Home.Root.path}
                  className="inline-block cursor-pointer text-left text-sm font-medium text-brand-blue-700 transition-colors duration-200 hover:text-brand-blue-800 focus:text-brand-blue-800 focus-visible:text-brand-blue-800 focus-visible:outline-none active:text-brand-blue-500 active:transition-none"
                >
                  <span className="line-clamp-1">{row.name}</span>
                </Link>
              </TableCell>
              <TableCell className="w-1/12 px-3 py-3 text-center">
                {/* TODO: Add color map to status value when will be available */}
                <Badge label={row.status} className="bg-error-bg capitalize text-error-main" />
              </TableCell>
              <TableCell className="w-2/12 px-3 py-3 text-sm font-medium text-grey-950">
                {/* TODO: Replace link path to valid service provider page */}
                <Link
                  to={AppRoutes.App.Home.Root.path}
                  className="inline-block cursor-pointer text-left text-sm font-medium text-brand-blue-700 transition-colors duration-200 hover:text-brand-blue-800 focus:text-brand-blue-800 focus-visible:text-brand-blue-800 focus-visible:outline-none active:text-brand-blue-500 active:transition-none"
                >
                  <span className="line-clamp-1">{row.service_provider}</span>
                </Link>
              </TableCell>
              <TableCell className="w-2/12 px-3 py-3 text-sm font-medium text-grey-950">
                {/* TODO: Replace link path to valid group service page */}
                <Link
                  to={AppRoutes.App.Home.Root.path}
                  className="inline-block cursor-pointer text-left text-sm font-medium text-brand-blue-700 transition-colors duration-200 hover:text-brand-blue-800 focus:text-brand-blue-800 focus-visible:text-brand-blue-800 focus-visible:outline-none active:text-brand-blue-500 active:transition-none"
                >
                  <span className="line-clamp-1">{row.service_link_group ?? '-'}</span>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
