import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { AccessDenied, AppTabs } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { districtSelector } from '~/store/features/district';
import { studentDetailsTabs } from './student-details-tabs';

export const StudentDetailsTabs: React.FC = () => {
  const { flags_enabled = {} } = useAppSelector(districtSelector) ?? {};

  // Filter tabs based on flags_enabled
  // Because grades tab depends on flags_enabled
  // These flags can be enabled/disabled by the districts
  const tabsList = useMemo(() => {
    return studentDetailsTabs.filter((tab) => {
      if (Object.keys(flags_enabled).includes(tab.value)) {
        return flags_enabled[tab.value as keyof typeof flags_enabled] || false;
      }
      return true;
    });
  }, [flags_enabled]);

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: tabsList,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }

  return <AppTabs className="col-span-2" tabs={tabsList} value={activeTab} onValueChange={tabChangeHandler} />;
};
