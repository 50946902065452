import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DonutChartCard,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Skeleton,
} from '@purple/ui';
import { useSchoolOverview, useSchoolOverviewLinkedStudents } from '~/queries';
import { OverviewStatisticCard, SchoolDonutChartWrapper } from './components';
import { LINKED_STUDENTS_CHART_TYPE_OPTIONS } from './constants';

export const Overview: React.FC = () => {
  const { schoolId } = useParams();

  const [selectedLinkedType, setSelectedLinkedType] = useState<string>(LINKED_STUDENTS_CHART_TYPE_OPTIONS[0].value);

  const {
    data: schoolOverviewData,
    isLoading: isSchoolDataLoading,
    isError: isSchoolDataError,
  } = useSchoolOverview(schoolId as string);

  const {
    data: linkedStudentsData,
    isLoading: isSchoolDataLinkedStudentsLoading,
    isError: isSchoolDataLinkedStudentsError,
  } = useSchoolOverviewLinkedStudents({
    schoolId: schoolId as string,
    chart_type: selectedLinkedType,
  });

  const { safs, actions, programs, color_trend, subpopulations, chronically_absent, ada } = schoolOverviewData || {};
  const { linked_students } = linkedStudentsData || {};

  return (
    <div className="flex flex-col gap-2 p-4 py-6">
      <div className="flex">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          School Data Overview
        </Heading>
      </div>
      <div className="grid grid-flow-row grid-cols-2 gap-4">
        {isSchoolDataLoading
          ? (
              <Skeleton className="h-[136px] w-full" />
            )
          : (
              <OverviewStatisticCard
                title="Chronically Absent"
                value={chronically_absent as number}
                onViewReport={() => null}
                betterIs="decrease"
              />
            )}
        {isSchoolDataLoading
          ? (
              <Skeleton className="h-[136px] w-full" />
            )
          : (
              <OverviewStatisticCard title="ADA" value={ada as number} onViewReport={() => null} betterIs="increase" />
            )}
      </div>
      <div className="grid grid-flow-row grid-cols-2 gap-4">
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="w-full h-[334px]"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={safs || []}
            title="SAFs"
            actionTitle="View Report"
          />
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="w-full h-[334px]"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={actions || []}
            title="Actions"
            actionTitle="View Report"
          />
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="w-full h-[334px]"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={programs || []}
            title="Programs"
            actionTitle="View Report"
          />
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="w-full h-[334px]"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={color_trend || []}
            title="Color Trend"
            actionTitle="View Report"
          />
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLinkedStudentsLoading || isSchoolDataLinkedStudentsError}
          className="w-full h-[408px]"
          loaderVariant="spinner"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={linked_students || []}
            title="Linked Students"
            actionTitle="View Report"
            content={(
              <div className="max-w-[270px] px-4">
                <RadixSelect onValueChange={setSelectedLinkedType} defaultValue={selectedLinkedType}>
                  <RadixSelectTrigger>
                    <RadixSelectValue placeholder="Select type" />
                  </RadixSelectTrigger>
                  <RadixSelectContent>
                    {LINKED_STUDENTS_CHART_TYPE_OPTIONS.map(({ label, value }) => (
                      <RadixSelectItem key={value} value={value}>
                        {label}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
              </div>
            )}
          />
        </SchoolDonutChartWrapper>
        <SchoolDonutChartWrapper
          isLoading={isSchoolDataLoading || isSchoolDataError}
          className="w-full h-[334px]"
        >
          <DonutChartCard
            onClickDownload={() => null}
            onClickViewReport={() => null}
            data={subpopulations || []}
            title="Subpopulations"
            actionTitle="View Report"
          />
        </SchoolDonutChartWrapper>
      </div>
    </div>
  );
};
