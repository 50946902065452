import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { IStudentDataResponse, TStudentDataListRequestParameters } from '@purple/shared-types';

export const getStudentsDataList = async (request: TStudentDataListRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const URL_PATH = STUDENTS_ENDPOINTS.GET_STUDENTS_DATA_LIST;

  const response = await axiosInstance.get<IStudentDataResponse>(URL_PATH, {
    params: request,
  });
  return response.data;
};
