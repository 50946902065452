import { Skeleton } from '@purple/ui';

export const NewProvidersSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-8 rounded-lg border border-grey-200 bg-white px-4 py-6 pb-4 shadow-custom-medium">
      <div className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
        <Skeleton className="h-[24px] w-[120px]" />
        <Skeleton className="h-[20px] w-[84px]" />
      </div>
      <div className="flex w-full items-center justify-center gap-16">
        <Skeleton className="h-[224px] w-[224px] shrink-0 rounded-full" />
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-[48px] w-[42px]" />
            <Skeleton className="h-[24px] w-[101px]" />
          </div>
          <div className="flex w-full flex-col gap-3">
            <Skeleton className="h-[20px] w-full" />
            <Skeleton className="h-[20px] w-full" />
            <Skeleton className="h-[20px] w-full" />
            <Skeleton className="h-[20px] w-full" />
            <Skeleton className="h-[20px] w-full" />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <Skeleton className="h-[36px] w-[86px]" />
      </div>
    </div>
  );
};
