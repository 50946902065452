import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Button, Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useModal } from '~/hooks';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActionLinkedStudent } from '@purple/shared-types';

type TGroupActionsMembersOptions = {
  onSelectedMember: (studentId: string) => void;
  closeMainModal?: () => void;
};

type TActionsGroupMembersColumns = (options: TGroupActionsMembersOptions) => ColumnDef<TActionLinkedStudent>[];

export const useGroupActionsMembersColumns: TActionsGroupMembersColumns = (options) => {
  const { openModal } = useModal('delete-group-action-members-modal');
  const { onSelectedMember, closeMainModal } = options || {};

  const deleteMemberHandler = (memberId: string) => {
    onSelectedMember(memberId);
    openModal();
  };

  const actionsMembersColumns: ColumnDef<TActionLinkedStudent>[] = [
    {
      accessorKey: 'full_name',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Name
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <div className="flex items-center gap-2.5">
          <Avatar size={24}>
            <AvatarImage src={original.photo ?? undefined} />
            <AvatarFallback className="bg-grey-200">{getInitialsFromName(original.full_name ?? '—')}</AvatarFallback>
          </Avatar>
          <Link
            to={AppRoutes.App.Students.StudentDetails.Root.makePath(original.id, 'dashboard')}
            onClick={closeMainModal}
            className="w-max font-primary text-sm font-semibold text-brand-blue-800 hover:underline hover:underline-offset-2"
          >
            {original.full_name}
          </Link>
        </div>
      ),
    },
    {
      accessorKey: 'grade',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Grade
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
          {original.grade || '—'}
        </Text>
      ),
    },
    {
      accessorKey: 'date',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Date
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
          {original.action.created_at ? format(original.action.created_at, 'MMM dd, yyyy') : '—'}
        </Text>
      ),
    },
    {
      accessorKey: 'school',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          School
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
          {original.school.name || '—'}
        </Text>
      ),
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) => (
        <div className="flex items-center justify-center">
          <Button
            type="button"
            variant="tertiary_icon_only"
            iconLeft={<PurpleIcon name="trash" className="text-error-main" />}
            className="border-none hover:bg-grey-100 active:bg-grey-200"
            onClick={() => deleteMemberHandler(original.id)}
          />
        </div>
      ),
    },
  ];

  return actionsMembersColumns;
};
