import { useEffect, useMemo, useState } from 'react';
import { Message, Switch, Text } from '@purple/ui';
import { DataTable } from '../../../DataTable';
import { useManualFormColumns } from './useManualFormColumns';
import type { IEditionSaveStudentByIdFormEntity, IStudentByIdFormEntity } from './types';

type TManualStudentsIdFormProperties = React.PropsWithChildren<{
  manualStudents: IStudentByIdFormEntity[];
  onChange: (value: IEditionSaveStudentByIdFormEntity) => void;
  onRemove: (id: string) => void;
}>;

const ManualStudentsIdForm = ({ manualStudents, onChange, onRemove }: TManualStudentsIdFormProperties) => {
  const columns = useManualFormColumns({
    onChange,
    onRemove,
  });

  const [showMessage, setShowMessage] = useState<boolean>(true);
  const [showOnlyProblems, setShowOnlyProblems] = useState<boolean>(false);

  const totalStudents = useMemo(() => {
    return manualStudents.length;
  }, [manualStudents]);

  const incorrectStudents = useMemo(() => {
    return manualStudents.filter((student) => !student.isCorrect);
  }, [manualStudents]);

  const isIncorrectExists = useMemo(() => {
    return incorrectStudents.length > 0;
  }, [incorrectStudents]);

  useEffect(() => {
    if (!isIncorrectExists) {
      setShowOnlyProblems(false);
    }
  }, [isIncorrectExists]);

  const messageText = useMemo(() => {
    const rowsText = incorrectStudents.length === 1 ? 'row' : 'rows';
    return `${incorrectStudents.length} ${rowsText} contain errors. Please correct them and try again!`;
  }, [incorrectStudents]);

  const tableData = useMemo(() => {
    if (showOnlyProblems) {
      return incorrectStudents;
    }
    return [...manualStudents];
  }, [manualStudents, incorrectStudents, showOnlyProblems]);

  return (
    <div className="flex flex-col gap-6">
      {showMessage && isIncorrectExists && (
        <Message variant="error" onClose={() => setShowMessage(false)}>
          <Text variant="size-14" type="body-500">
            {messageText}
          </Text>
        </Message>
      )}
      <div className="flex flex-row items-center justify-between">
        <Text variant="size-14" type="body-400" className="text-grey-950">
          {totalStudents}
          {' '}
          students
        </Text>
        {isIncorrectExists && (
          <div className="flex flex-row items-center gap-4">
            <Switch defaultChecked={showOnlyProblems} onClick={() => setShowOnlyProblems((state) => !state)} />
            <Text variant="size-14" type="body-400" className="text-grey-950">
              Show only rows with problems
            </Text>
          </div>
        )}
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        emptyStateTitle="No students found"
        emptyStateMessage="There are no students to display. If it is an error, please contact support."
        classNames={{
          cell: 'p-2',
          head: 'p-2',
        }}
      />
    </div>
  );
};

export { ManualStudentsIdForm };
