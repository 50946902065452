export const SAF_PLACE_ON_HOLD_REASON_OPTIONS = [
  {
    label: 'Resource/Services not currently available',
    value: 'service_not_available',
  },
  {
    label: 'Gathering Information/Identifying the Need',
    value: 'gathering_information',
  },
  {
    label: 'Waiting on Parent/Guardian Communication',
    value: 'waiting_communication',
  },
  {
    label: 'Waiting on Paperwork/Insurance',
    value: 'waiting_paperwork_or_insurance',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
