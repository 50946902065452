import { getAxiosInstance } from '../../api';
import { SCHOOLS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSchoolInfo } from '@purple/shared-types';

export const getSchoolInfo = async (schoolId: string) => {
  const axiosInstance = getAxiosInstance();

  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOL_INFO, schoolId);

  const response = await axiosInstance.get<TSchoolInfo>(URL_PATH);
  return response.data;
};
