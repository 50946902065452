import { Separator, Text } from '@purple/ui';
import { SubcategoryCheckbox } from './SubcategoryCheckbox';
import type React from 'react';
import type { useForm } from 'react-hook-form';
import type { TSafAreaCategoriesValues, TSafCloseCheckboxOption } from '@purple/shared-types';
import type { TCloseSafFormValues } from './CloseSafModal';

type CategoryCheckboxProperties = {
  form: ReturnType<typeof useForm<TCloseSafFormValues>>;
  categoryKey: TSafAreaCategoriesValues;
  categoryLabel: string;
  subcategories: TSafCloseCheckboxOption[];
  serviceAreaSubcategories: string[];
};

export const CategoryCheckbox: React.FC<CategoryCheckboxProperties> = ({
  form,
  categoryKey,
  categoryLabel,
  subcategories,
  serviceAreaSubcategories,
}) => {
  return (
    <>
      <Text className="text-base font-semibold">{categoryLabel}</Text>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-2 pl-4">
        {subcategories.map((item) => (
          <SubcategoryCheckbox
            key={item.name}
            form={form}
            categoryKey={categoryKey}
            item={item}
            subcategories={subcategories}
            serviceAreaSubcategories={serviceAreaSubcategories}
          />
        ))}
      </div>
    </>
  );
};
