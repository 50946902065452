import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useAddToPriorityListBulk } from '~/queries';
import { PriorityListComboBox } from './PriorityListComboBox';
import { addToExistingPriorityListSchema } from './schema';
import type * as z from 'zod';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

type TAddToExistingPriorityListModalProperties = {
  students: TSelectedStudent[];
};

export const AddToExistingPriorityListModal = ({ students }: TAddToExistingPriorityListModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('add-to-existing-priority-list');

  const { mutate: addStudentsToList, isPending } = useAddToPriorityListBulk();

  const form = useForm<z.infer<typeof addToExistingPriorityListSchema>>({
    resolver: zodResolver(addToExistingPriorityListSchema),
    mode: 'onChange',
    defaultValues: {
      id: '',
    },
  });

  const addToListHandler = (data: z.infer<typeof addToExistingPriorityListSchema>) => {
    addStudentsToList(
      {
        id: data.id,
        students: students.map((student) => student.id),
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Add to Existing Priority List</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(addToListHandler)}
        >
          <FormField
            control={form.control}
            name="id"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Priority List</FormLabel>
                <FormControl>
                  <PriorityListComboBox
                    value={field.value}
                    onChange={(value) => form.setValue('id', value, { shouldValidate: true })}
                    isError={!!fieldState.error}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(addToListHandler)} disabled={isPending} isLoading={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
