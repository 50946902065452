import * as z from 'zod';

export const safTransferSchema = z
  .object({
    is_has_school_access: z
      .string()
      .trim()
      .min(1, 'Field is required')
      .refine((value) => value === 'yes' || value === 'no', { message: 'Value must be either "yes" or "no"' }),
    school: z.string().trim().optional(),
    current_owner: z.string().trim().optional(),
    transfer_reason: z.string().trim().min(1, 'Transfer Reason is required'),
  })
  .refine(
    (data) => {
      if (data.is_has_school_access === 'yes') {
        return data.school;
      }
      return true;
    },
    {
      message: 'School is required',
      path: ['school'],
    },
  )
  .refine(
    (data) => {
      if (data.is_has_school_access === 'yes') {
        return data.current_owner;
      }
      return true;
    },
    {
      message: 'New Owner is required',
      path: ['current_owner'],
    },
  );
