import { PurpleIcon } from '@purple/icons';
import { Button, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { useCurrentUser } from '~/hooks/redux';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActionLinkedStudent, TGroupActionDetails } from '@purple/shared-types';

type TGroupActionLinkedStudentsOptions = {
  groupAction: TGroupActionDetails;
  onDelete?: (student: TActionLinkedStudent) => void;
};

type TGroupActionLinkedStudentsColumns = (
  options: TGroupActionLinkedStudentsOptions,
) => ColumnDef<TActionLinkedStudent>[];

export const useGroupActionStudentsColumns: TGroupActionLinkedStudentsColumns = (options) => {
  const { user } = useCurrentUser();
  const { onDelete, groupAction } = options || {};

  return [
    {
      accessorKey: 'name',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Student Name
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <LinkButton to={AppRoutes.App.Actions.Details.makePath(original.action?.id)} className="line-clamp-1">
              {original.full_name}
            </LinkButton>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {original.full_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[40%]' },
    },
    {
      accessorKey: 'grade',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Grade
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
              <span className="block w-full truncate">{original.grade || '–'}</span>
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            {original.grade && (
              <TooltipContent align="start" withArrow={false}>
                {original.grade}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[20%]' },
    },
    {
      accessorKey: 'school',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          School
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <LinkButton
              to={AppRoutes.App.School.Root.makePath(original.school.id, 'overview')}
              className="line-clamp-1"
            >
              {original.school.name}
            </LinkButton>
          </TooltipTrigger>
          <TooltipPortal>
            {original.school && (
              <TooltipContent align="start" withArrow={false}>
                {original.school.name}
              </TooltipContent>
            )}
          </TooltipPortal>
        </Tooltip>
      ),
      meta: { className: 'w-[30%]' },
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) =>
        groupAction.details?.created_by?.id === user.id
          ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="destructive_secondary"
                    size="icon_32"
                    iconLeft={<PurpleIcon name="trash" />}
                    aria-label={`Remove linked student ${original.full_name}`}
                    className="ml-auto border-none bg-transparent"
                    onClick={(event) => {
                      event.stopPropagation();
                      onDelete?.(original);
                    }}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>
                    Remove
                    {original.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : null,
      meta: { className: 'w-[10%]' },
    },
  ];
};
