import { type FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { formatDateToLocalTime, USER_QUERY_KEYS } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector, useModal } from '~/hooks';
import { useActivityChoices, useActivityDetails, useDeleteActivity } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { userSelector } from '~/store/features/user';
import { CallToActionModal } from '../../CallToActionModal';
import { CommunityModalSkeleton } from './CommunityModalSkeleton';
import type { TActivityType } from '@purple/shared-types';

type TCommunityActivityModalProperties = {
  eventId: string | null;
  onChangeSelectedEventId?: (id: string | null) => void;
};

export const CommunityActivityModal: FC<TCommunityActivityModalProperties> = ({ eventId, onChangeSelectedEventId }) => {
  const queryClient = useQueryClient();

  const { isOpen, toggleModal } = useModal(ModalType.COMMUNITY_ACTIVITY_MODAL);
  const { openModal: openDeleteActivityModal, closeModal: closeDeleteActivityModal } = useModal(
    ModalType.DELETE_COMMUNITY_ACTIVITY_MODAL,
  );

  const { id } = useAppSelector(userSelector);

  const { data, isLoading } = useActivityDetails(eventId as string, isOpen);
  const { typeOptions } = useActivityChoices({
    community_activity_type: data?.details?.community_activity_type.name as TActivityType,
    enabled: !!data?.details?.community_activity_type.name && isOpen,
  });
  const { mutate, isPending } = useDeleteActivity();

  const { name, details, description } = data || {};

  const isActivityOwner = useMemo(() => details?.created_by?.id === id, [details?.created_by?.id, id]);
  const [readableType] = useMemo(
    () => typeOptions.filter((option) => option.value === details?.type).map((option) => option.label),
    [details?.type, typeOptions],
  );

  const toggleModalCloseHandler = () => {
    onChangeSelectedEventId?.(null);
    toggleModal(!isOpen);
  };

  const deleteActivityHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while deleting action');
    }
    mutate(eventId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
        closeDeleteActivityModal();
        toggleModalCloseHandler();
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModalCloseHandler}>
        <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
          <DialogHeader className="flex flex-row items-center justify-between">
            <div>
              <DialogTitle>Activity</DialogTitle>
              <DialogDescription className="sr-only">Activity event modal</DialogDescription>
            </div>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            {isLoading && !data && <CommunityModalSkeleton />}
            {data && !isLoading && (
              <div className="flex flex-col gap-6 p-6">
                <div className="flex items-center justify-between gap-2">
                  <Text variant="size-16" type="body-600">
                    {name}
                  </Text>
                  {isActivityOwner && (
                    <Button variant="secondary" className="px-4 py-2.5" onClick={openDeleteActivityModal}>
                      Delete Activity
                    </Button>
                  )}
                </div>
                <div className="flex flex-col gap-3">
                  <Text variant="size-16" type="body-600">
                    Description
                  </Text>
                  <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
                    {/* Activity Type */}
                    <Text variant="size-14" type="body-500">
                      Activity Type:
                    </Text>
                    <Text variant="size-14">{readableType}</Text>
                    {/* Created By */}
                    <Text variant="size-14" type="body-500">
                      Created by:
                    </Text>
                    <Text variant="size-14" type="body-500">
                      {details?.created_by?.full_name ?? '—'}
                    </Text>
                    {/* Description */}
                    <Text variant="size-14" type="body-500">
                      Description Text:
                    </Text>
                    <Text variant="size-14">{description ?? '—'}</Text>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <Text variant="size-16" type="body-600">
                    Date & Time
                  </Text>
                  <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
                    {/* Start Date */}
                    <Text variant="size-14" type="body-500">
                      Date & Time:
                    </Text>
                    <Text variant="size-14">{formatDateToLocalTime(details?.date_and_time) ?? '—'}</Text>
                    {/* Duration */}
                    <Text variant="size-14" type="body-500">
                      Duration:
                    </Text>
                    <Text variant="size-14">{details?.duration ?? '—'}</Text>
                  </div>
                </div>
              </div>
            )}
          </ScrollArea>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={toggleModalCloseHandler} asChild>
              <Link to={AppRoutes.App.Community.Details.makePath(eventId || '')}>Open Activity</Link>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <CallToActionModal
        modalName={ModalType.DELETE_COMMUNITY_ACTIVITY_MODAL}
        modalTitle="Delete Community Activity"
        modalDescription="Delete community activity modal"
        modalTextContent="Are you sure you want to delete this community activity?"
        primaryButtonText="Yes, delete"
        secondaryButtonText="Cancel"
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
        onPrimaryButtonClick={deleteActivityHandler}
      />
    </>
  );
};
