export const LocalStorageKey = {
  Auth: {
    ResetPassword: {
      Email: 'purple:email',
      CountDown: 'purple:reset-password:countdown',
    },
    EmailVerification: {
      Email: 'purple:auth:verify:email',
    },
    Token: {
      AccessToken: 'purple:access-token',
      RefreshToken: 'purple:refresh-token',
    },
  },
  Admin: {
    Sidebar: 'purple:admin:sidebar:open',
    ResetPassword: {
      Email: 'purple:admin:reset-password:email',
      CountDown: 'purple:admin:reset-password:countdown',
    },
    Token: {
      AccessToken: 'purple:admin:access-token',
      RefreshToken: 'purple:admin:refresh-token',
    },
  },
} as const;
