import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { useGetPriorityLists } from '~/queries';
import type React from 'react';

const LIMIT = 5;

type TPriorityListComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
};

export const PriorityListComboBox: React.FC<TPriorityListComboBoxProperties> = (props) => {
  const { value, isError = false, onChange } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: priorityLists, isFetching } = useGetPriorityLists({
    limit: LIMIT,
    offset: 0,
    search: debouncedSearchValue,
  });

  const lists = useMemo(() => priorityLists?.results ?? [], [priorityLists?.results]);
  const selectedList = useMemo(() => lists.find((list) => list.id === value) ?? null, [value, lists]);

  const selectValueHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={isError} placeholder="Select list" selectedLabel={selectedList?.name} />
      <ComboBoxContent
        loading={isFetching}
        shouldFilter={false}
        searchPlaceholder="Search list..."
        emptyContent="List not found."
        onSearchChange={debouncedSearch}
      >
        {lists.map(({ id, name }) => (
          <ComboBoxItem key={id} value={id} selected={value === id} onSelect={selectValueHandler}>
            {name}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
