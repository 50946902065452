import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafCorrespondenceListResponse, TSafEmailListRequestParameters } from '@purple/shared-types';

export const getSafEmailList = async (safId: string, parameters?: TSafEmailListRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafCorrespondenceListResponse>(
    replaceUrlParameters(SAF_ENDPOINTS.CORRESPONDENTS, safId),
    {
      params: parameters,
    },
  );
  return response.data;
};
