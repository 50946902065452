import { PurpleIcon } from '@purple/icons';
import { cn, Skeleton } from '@purple/ui';

type TSchoolDonutChartWrapperProperties = React.PropsWithChildren<{
  isLoading: boolean;
  className?: string;
  loaderVariant?: 'skeleton' | 'spinner';
}>;

const SchoolDonutChartWrapper = ({
  isLoading,
  children,
  className = '',
  loaderVariant = 'skeleton',
}: TSchoolDonutChartWrapperProperties) => {
  if (isLoading) {
    if (loaderVariant === 'spinner') {
      return (
        <div className={cn('flex w-full justify-center rounded-lg border border-grey-200 bg-white items-center', className)}>
          <PurpleIcon name="loader" className="h-6 w-6 animate-spin" />
        </div>
      );
    } else {
      return <Skeleton className={className} />;
    }
  }

  return children;
};

export { SchoolDonutChartWrapper };
