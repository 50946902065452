import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NoDataAvailable,
  Separator,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useModal } from '~/hooks';
import { useDeleteGroupAction } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { CallToActionModal } from '../../CallToActionModal';
import { Details } from './Tabs';
import { ManageMembers } from './Tabs/ManageMembers';

type TActionEventModalProperties = {
  eventId: string | null;
  onChangeSelectedEventId?: (id: string | null) => void;
};

export const GroupActionModal = ({ eventId, onChangeSelectedEventId }: TActionEventModalProperties) => {
  const navigate = useNavigate();
  const { isOpen, toggleModal } = useModal('group-action-modal');
  const { isOpen: isDeleteGroupActionOpen, closeModal } = useModal('delete-group-action-event-modal');

  const { mutate, isPending } = useDeleteGroupAction();

  const navigateToActionDetailsHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while navigating to action details');
    }
    navigate(AppRoutes.App.Actions.GroupDetails.makePath(eventId));
    toggleModal(!isOpen);
  };

  const toggleModalCloseHandler = () => {
    onChangeSelectedEventId?.(null);
    toggleModal(!isOpen);
  };

  const deleteActionHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while deleting action');
    }
    mutate(eventId, {
      onSuccess: () => {
        toggleModalCloseHandler();
        closeModal();
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModalCloseHandler}>
        <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle>Group Action</DialogTitle>
            <DialogDescription className="sr-only">Group Action event modal</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          {!eventId && <Separator />}
          {!eventId && <NoDataAvailable iconName="folder" className="h-[368px] text-grey-600" />}
          {eventId && (
            <Tabs defaultValue="details">
              <TabsList className="pl-6 font-primary">
                <TabsTrigger value="details">Details</TabsTrigger>
                <TabsTrigger value="manage-members">Manage Members</TabsTrigger>
              </TabsList>
              <Separator />
              <TabsContent value="details">
                <Details eventId={eventId} />
              </TabsContent>
              <TabsContent value="manage-members">
                <ManageMembers groupActionId={eventId} closeMainModal={toggleModalCloseHandler} />
              </TabsContent>
            </Tabs>
          )}
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            {eventId && (
              <Button variant="primary" onClick={navigateToActionDetailsHandler}>
                Open Action
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {isDeleteGroupActionOpen && (
        <CallToActionModal
          modalName="delete-group-action-event-modal"
          modalTitle="Delete Group Action"
          modalDescription="Delete group action modal"
          modalTextContent=" Deleting this group action will also delete all the associated events. Are you sure you want to delete that action?"
          primaryButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          primaryButtonVariant="destructive_primary"
          isLoading={isPending}
          onPrimaryButtonClick={deleteActionHandler}
        />
      )}
    </>
  );
};
