import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type {
  IPaginatedResponse,
  TSchoolUserAccessListItem,
  TSchoolUsersAccessRequestParameters,
} from '@purple/shared-types';

export const getSchoolUsersAccessList = async ({
  schoolId,
  queryParameters,
}: {
  schoolId: string;
  queryParameters: TSchoolUsersAccessRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOLS_USERS_WITH_ACCESS_LIST, schoolId);
  const response = await axiosInstance.get<IPaginatedResponse<TSchoolUserAccessListItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
