import { TakeActionDocumentType, TakeActionType } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';

export const ATTENDANCE_ACTIONS_SORT = [
  {
    label: 'Name(ASC)',
    value: 'title',
  },
  {
    label: 'Name(DESC)',
    value: '-title',
  },
  {
    label: 'Action type(ASC)',
    value: 'type',
  },
  {
    label: 'Action type(DESC)',
    value: '-type',
  },
  {
    label: 'Date(ASC)',
    value: 'date_and_time',
  },
  {
    label: 'Date(DESC)',
    value: '-date_and_time',
  },
  {
    label: 'Document As(ASC)',
    value: 'document_as',
  },
  {
    label: 'Document As(DESC)',
    value: '-document_as',
  },
  {
    label: 'Linked SAFs(ASC)',
    value: 'linked_safs',
  },
  {
    label: 'Linked SAFs(DESC)',
    value: '-linked_safs',
  },
] as const;

export const ATTENDANCE_ACTIONS_FILTERS = {
  categories: [
    {
      label: 'Action Type',
      value: 'record_action_type',
      filters: Object.values(TakeActionType).map((type) => ({
        label: snakeCaseToCapitalized(type),
        value: type,
      })),
    },
    {
      label: 'Document As',
      value: 'document_as',
      filters: [
        {
          label: 'General',
          value: TakeActionDocumentType.GENERAL,
        },
        {
          label: 'Targeted',
          value: TakeActionDocumentType.TARGETED,
        },
        {
          label: 'Attempted',
          value: TakeActionDocumentType.ATTEMPTED,
        },
      ],
    },
  ],
};
