import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { FilterOptions } from '@purple/shared-types';

export const getStudentMeetingFilterOptions = async ({ studentId }: { studentId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_SERVICE_PROVIDERS_FILTERS, studentId);
  const response = await axiosInstance.get<FilterOptions>(URL_PATH);
  return response.data;
};
