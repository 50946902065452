import { AccountDetailsTab, LocationDetailsTab, SchoolAccessTab } from './tabs';
import type { TUserProfile } from '@purple/shared-types';

export const useProfileHeaderSideTabs = (data: TUserProfile | undefined) =>
  data
    ? [
        {
          label: 'Account Details',
          value: 'account-details',
          content: <AccountDetailsTab data={data.account_details} />,
          permissions: [],
        },
        {
          label: 'School Access',
          value: 'school-access',
          content: <SchoolAccessTab data={data.school_access} />,
          permissions: [],
        },
        {
          label: 'Location Details',
          value: 'location-details',
          content: <LocationDetailsTab data={data.location_details} />,
          permissions: [],
        },
      ]
    : [];
