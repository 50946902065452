import { useMemo } from 'react';
import { Guard } from '@purple/permissions';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Separator } from '@purple/ui';
import { ActivityDetailsSection } from './ActivityDetailsSection';
import { ActivitySidebarHeader } from './ActivitySidebarHeader';
import { ActivityTagsSection } from './ActivityTagsSection';
import type React from 'react';
import type { TActivityInfo } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

type TActivityDetailsSideBarProperties = {
  activity: TActivityInfo;
};

export const ActivityDetailsSideBar: React.FC<TActivityDetailsSideBarProperties> = (props) => {
  const { activity } = props;

  const accordionTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Activity Details',
        value: 'details',
        content: <ActivityDetailsSection activity={activity} />,
        permissions: [],
      },
      {
        label: 'Tags',
        value: 'tags',
        content: <ActivityTagsSection activity={activity} />,
        permissions: [],
      },
    ],
    [activity],
  );
  const defaultOpenTab = useMemo(() => accordionTabs.map((tab) => tab.value).slice(0, 1), [accordionTabs]);

  return (
    <aside className="flex w-full shrink-0 flex-col rounded-lg border border-grey-200 bg-white px-4 pt-6 shadow-custom-heavy">
      <ActivitySidebarHeader activity={activity} />
      <Separator className="mt-6 bg-grey-200" />
      <Accordion type="multiple" defaultValue={defaultOpenTab}>
        {accordionTabs.map((tab) => (
          <Guard key={tab.value} requiredPermissions={tab.permissions}>
            <AccordionItem value={tab.value} className="[&:last-child]:border-none">
              <AccordionTrigger>{tab.label}</AccordionTrigger>
              <AccordionContent>{tab.content}</AccordionContent>
            </AccordionItem>
          </Guard>
        ))}
      </Accordion>
    </aside>
  );
};
