import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { AppFilters, Button, Checkbox, Label } from '@purple/ui';
import { useModal } from '~/hooks';
import { useEcoSystemFilters } from '../useEcoSystemFilters';
import type { FC } from 'react';

export const FiltersSection: FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const { filterOptions } = useEcoSystemFilters();

  const { openModal } = useModal('default-eco-system-data-view');

  const defaultOwnedByMeValue
    = searchParameters.get('is_owner') === 'true' || !searchParameters.get('is_owner') || false;
  const defaultShowRemindersValue = searchParameters.get('is_reminders') === 'true' || false;

  const [isOwnedByMe, setIsOwnedByMe] = useState(defaultOwnedByMeValue);
  const [isShowReminders, setIsShowReminders] = useState(defaultShowRemindersValue);

  const ownedByMeChangeHandler = useCallback(
    (value: boolean) => {
      setIsOwnedByMe(value);
      setSearchParameters((previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        newSearchParameters.set('is_owner', value.toString());
        return newSearchParameters;
      });
    },
    [setSearchParameters],
  );

  const showRemindersChangeHandler = useCallback(
    (value: boolean) => {
      setIsShowReminders(value);
      setSearchParameters((previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        if (!value) {
          newSearchParameters.delete('is_reminders');
          return newSearchParameters;
        }
        newSearchParameters.set('is_reminders', value.toString());
        return newSearchParameters;
      });
    },
    [setSearchParameters],
  );

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex gap-3">
        <div className="flex items-center gap-2 space-y-0">
          <Checkbox
            id="owned-by-me"
            checked={isOwnedByMe}
            onCheckedChange={ownedByMeChangeHandler}
            className="h-4 w-4"
          />
          <Label htmlFor="owned-by-me">Show Only Owned by Me</Label>
        </div>
        <div className="flex items-center gap-2 space-y-0">
          <Checkbox
            id="reminders"
            checked={isShowReminders}
            onCheckedChange={showRemindersChangeHandler}
            className="h-4 w-4"
          />
          <Label htmlFor="reminders">Show Only Reminders</Label>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <AppFilters config={filterOptions} align="end" />
        <Button variant="secondary" size="icon_40" onClick={openModal}>
          <PurpleIcon name="cog" className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};
