import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Disclaimer, Text } from '@purple/ui';

export const SafDisclaimer: React.FC = () => {
  const [hideDisclaimer, setHideDisclaimer] = useState<boolean>(false);

  const { t } = useTranslation('saf');

  const closeDisclaimerClickHandler = () => {
    setHideDisclaimer(true);
  };

  if (hideDisclaimer) {
    return null;
  }

  return (
    <Disclaimer
      heading={t('disclaimer.title')}
      actionButtonLabel={t('disclaimer.accept')}
      onClose={closeDisclaimerClickHandler}
      onAction={closeDisclaimerClickHandler}
    >
      <div className="flex w-full flex-col gap-2">
        <Text type="body-400" variant="size-14">
          <Trans
            i18nKey="disclaimer.description"
            t={t}
            components={{
              telLink: (
                <a
                  href="tel:1-800-273-8255"
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-brand-blue-700 underline outline-none transition-colors hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800"
                >
                  1-800-273-8255
                </a>
              ),
              helpLink: (
                <a
                  href="https://suicidepreventionlifeline.org"
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-brand-blue-700 underline outline-none transition-colors hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800"
                >
                  www.suicidepreventionlifeline.org
                </a>
              ),
            }}
          />
        </Text>
        <Text type="body-400" variant="size-14">
          {t('disclaimer.special-students')}
        </Text>
      </div>
    </Disclaimer>
  );
};
