import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppSelectedFiltersList, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useStudentActionsList } from '~/queries';
import { actionsColumns } from './actionsColumns';
import { ActionsHeaderControls } from './ActionsHeaderControls';
import { ATTENDANCE_ACTIONS_FILTERS } from './constants';

export const Actions = () => {
  const [searchParameters] = useSearchParams();
  const { studentId } = useParams();

  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);

  const { timePeriod, onPeriodChange, onPeriodClear } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onLimitChange, onPageChange, page } = usePaginate();

  const { data, isLoading } = useStudentActionsList({
    studentId: studentId as string,
    queryParameters: {
      limit,
      offset,
      ordering: sort,
      search,
      document_as: searchParameters.get('document_as') || undefined,
      record_action_type: searchParameters.get('record_action_type') || undefined,
      time_period: timePeriod,
    },
  });

  const isDataNotExist = useMemo(
    () => !data?.results || (data.results.length === 0 && !isLoading),
    [data?.results, isLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full flex-col gap-4">
        <ActionsHeaderControls timePeriod={timePeriod} onPeriodChange={onPeriodChange} onPeriodClear={onPeriodClear} />
        <AppSelectedFiltersList config={ATTENDANCE_ACTIONS_FILTERS} maxDisplayedFilters={8} className="px-4" />
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <DataTable
            columns={actionsColumns}
            data={data?.results || []}
            isLoading={isLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No Actions found"
            emptyStateMessage="There are no Actions to display. If it an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};
