import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TStudentSupportByProgramQueryParameters } from '@purple/shared-types';

export const getSupportByProgramExportFile = async ({
  queryParams,
  studentId,
}: {
  studentId: string;
  queryParams: TStudentSupportByProgramQueryParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<Blob>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.EXPORT_SUPPORT_BY_PROGRAM, studentId),
    {
      params: queryParams,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    },
  );
  return response.data;
};
