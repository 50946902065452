import * as z from 'zod';

export const updateSchoolDetailsSchema = z.object({
  school_level: z.array(z.string()).optional(),
  grades: z.array(z.string()).optional(),
  subtype: z.string().optional(),
  school_office: z.string().optional(),
  school_division: z.string().optional(),
  subgroup: z.string().optional(),
  phone: z.string().optional(),
  website: z
    .string()
    .max(255, 'Website URL is too long')
    .refine((value) => value === '' || z.string().url().safeParse(value).success, {
      message: 'Website must be a valid URL or an empty string',
    })
    .optional(),
  super_neighborhood: z.string().optional(),
});

export const updateSchoolPoliticalRegionSchema = z.object({
  trustee_district_region: z.string().optional(),
  texas_house_district_region: z
    .string()
    .optional()
    .refine(
      (value) => {
        const numberValue = Number(value);
        return (!Number.isNaN(numberValue) && numberValue > 0) || value === '';
      },
      {
        message: 'Must be a positive number',
      },
    )
    .nullable(),
  state_board_of_education_district: z
    .string()
    .optional()
    .refine(
      (value) => {
        const numberValue = Number(value);
        return (!Number.isNaN(numberValue) && numberValue > 0) || value === '';
      },
      {
        message: 'Must be a positive number',
      },
    )
    .nullable(),
  city_council_district: z.string().nullable().optional(),
  texas_state_senate_district: z
    .string()
    .optional()
    .refine(
      (value) => {
        const numberValue = Number(value);
        return (!Number.isNaN(numberValue) && numberValue > 0) || value === '';
      },
      {
        message: 'Must be a positive number',
      },
    )
    .nullable(),
});
