import { getAxiosInstance } from '../../api';
import { NOTE_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TNote } from '@purple/shared-types';

export const getNoteById = async ({ noteId }: { noteId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(NOTE_ENDPOINTS.BY_ID, noteId);
  const response = await axiosInstance.get<TNote>(URL_PATH);

  return response.data;
};
