import { useNavigate, useParams } from 'react-router-dom';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { AppRoutes } from '~/constants/routes/routes';
import { StudentProfileSidebar } from '~/modules/Students';
import { StudentDetailsTabs } from '~/modules/Students/StudentProfile/StudentMainContent';

const StudentProfilePage = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();

  if (!studentId) {
    navigate(AppRoutes.System.NotFound.Root.path);
  }

  return (
    <>
      <Guard requiredPermissions={APP_PERMISSIONS.CAN_ACCESS_STUDENT_DETAILS}>
        <StudentProfileSidebar studentId={studentId as string} />
      </Guard>
      <div className="w-[908px] max-w-[908px]">
        <StudentDetailsTabs />
      </div>
    </>
  );
};

export { StudentProfilePage };
