import { useCallback, useMemo } from 'react';
import { ActivityType } from '@purple/shared-types';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import {
  createActivityCommunityActivityTypeSelector,
  createActivityModalSelector,
  createActivitySlice,
  type TCreateActivityState,
} from '~/store/features/create-activity';

type TUseCreateActivityReturn = TCreateActivityState & {
  /**
   * Whether the Counseling Related type modal is open or not.
   */
  isCounselingRelatedActivityDutyModalOpen: boolean;
  /**
   * Whether the Non Counseling Related type modal is open or not.
   */
  isNonCounselingRelatedActivityDutyModalOpen: boolean;
  /**
   * Whether the Community type modal is open or not.
   */
  isCommunityActivityModalOpen: boolean;
  /**
   * Whether the HisdSouper type modal is open or not.
   */
  isHisdSouperFoodPantryEventModalOpen: boolean;
  /**
   * Whether the ClassroomGuidance type modal is open or not.
   */
  isClassroomGuidanceLessonModalOpen: boolean;
  /**
   * Whether the CampusProposal type modal is open or not.
   */
  isCampusProposalMeetingModalOpen: boolean;
  /**
   * Whether the CrisisCallTracker type modal is open or not.
   */
  isCrisisCallTrackerModalOpen: boolean;
  /**
   * Whether the CrisisCallTracker type modal is open or not.
   */
  isCrisisResponseBehavioralModalOpen: boolean;
  /**
   * Toggle the action type modal.
   * @param activityType - type of the action to open the modal for
   */
  toggleActivityModal: (activityType: TCreateActivityState['activityTypeModalOpen']) => void;
  /**
   * Set the selected community activity type.
   * @param activityType - type of the activity to set
   */
  setSelectedCommunityActivityType: (activityType: TCreateActivityState['selectedCommunityActivityType']) => void;
};

export const useCreateActivity = (): TUseCreateActivityReturn => {
  const dispatch = useAppDispatch();
  const selectedCommunityActivityType = useAppSelector(createActivityCommunityActivityTypeSelector);
  const activityTypeModalOpen = useAppSelector(createActivityModalSelector);

  const { toggleActivityModal, setSelectedCommunityActivityType } = createActivitySlice.actions;

  const isCounselingRelatedActivityDutyModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.COUNSELING_RELATED,
    [activityTypeModalOpen],
  );

  const isNonCounselingRelatedActivityDutyModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.NON_COUNSELING_RELATED,
    [activityTypeModalOpen],
  );

  const isCommunityActivityModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.COMMUNITY,
    [activityTypeModalOpen],
  );

  const isHisdSouperFoodPantryEventModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT,
    [activityTypeModalOpen],
  );

  const isClassroomGuidanceLessonModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.CLASSROOM_GUIDANCE_LESSON,
    [activityTypeModalOpen],
  );

  const isCampusProposalMeetingModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.CAMPUS_PROPOSAL_MEETING,
    [activityTypeModalOpen],
  );

  const isCrisisCallTrackerModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.CRISIS_CALL_TRACKER,
    [activityTypeModalOpen],
  );

  const isCrisisResponseBehavioralModalOpen = useMemo(
    () => activityTypeModalOpen === ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT,
    [activityTypeModalOpen],
  );

  return {
    activityTypeModalOpen,
    isCounselingRelatedActivityDutyModalOpen,
    isNonCounselingRelatedActivityDutyModalOpen,
    isCommunityActivityModalOpen,
    isHisdSouperFoodPantryEventModalOpen,
    isClassroomGuidanceLessonModalOpen,
    isCampusProposalMeetingModalOpen,
    isCrisisCallTrackerModalOpen,
    isCrisisResponseBehavioralModalOpen,
    selectedCommunityActivityType,
    toggleActivityModal: useCallback(
      (actionType: TCreateActivityState['activityTypeModalOpen']) => dispatch(toggleActivityModal(actionType)),
      [dispatch, toggleActivityModal],
    ),
    setSelectedCommunityActivityType: useCallback(
      (communityActivityType: TCreateActivityState['selectedCommunityActivityType']) =>
        dispatch(setSelectedCommunityActivityType(communityActivityType)),
      [dispatch, setSelectedCommunityActivityType],
    ),
  };
};
