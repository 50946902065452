import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { AddToExistingPriorityListModal, CreatePriorityListModal } from '~/components';
import { TakeActionModal } from '~/components/Modals/TakeActionModal';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

type TStudentsActionsRowProperties = React.PropsWithChildren<{
  selectedStudents: TSelectedStudent[];
}>;

const StudentsActionsRow = ({ selectedStudents }: TStudentsActionsRowProperties) => {
  const { isOpen: isCreatePriorityListOpen, openModal: openCreatePriorityListModal } = useModal('create-priority-list');
  const { isOpen: isAddToExistingPriorityModalOpen, openModal: openAddToExistingPriorityModal } = useModal(
    'add-to-existing-priority-list',
  );

  const isStudentsSelected = useMemo(() => selectedStudents?.length > 0, [selectedStudents]);

  const { openModal: openTakeActionModal } = useModal(ModalType.TAKE_ACTION);
  const { setSelectedStudentId } = useTakeAction();

  const handleTakeAction = () => {
    if (isStudentsSelected) {
      if (selectedStudents.length === 1 && selectedStudents?.[0]) {
        setSelectedStudentId(selectedStudents[0].id);
      } else {
        setSelectedStudentId(selectedStudents.map((student) => student.id));
      }
      openTakeActionModal();
    }
  };

  const createPriorityListHandler = () => {
    openCreatePriorityListModal();
  };

  const addToExistingPriorityListHandler = () => {
    openAddToExistingPriorityModal();
  };

  return (
    <div className="flex flex-row gap-4">
      <Guard requiredPermissions={APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD}>
        <Button onClick={handleTakeAction} disabled={!isStudentsSelected}>
          Take Action
        </Button>
      </Guard>
      <Guard requiredPermissions={APP_PERMISSIONS.CAN_ACCESS_MY_PRIORITY_LISTS}>
        <div className="min-w-[133px]">
          <DropdownRoot>
            <DropdownTrigger disabled={!isStudentsSelected} asChild>
              <Button disabled={!isStudentsSelected} variant="secondary" iconRight={<PurpleIcon name="chevron-down" />}>
                Priority List
              </Button>
            </DropdownTrigger>
            <DropdownContent align="end" sideOffset={4} className="min-w-[232px]">
              <DropdownItem iconName="plus" onClick={createPriorityListHandler}>
                Create New Priority List
              </DropdownItem>
              <DropdownItem iconName="clipboard-list" onClick={addToExistingPriorityListHandler}>
                Add to Existing Priority List
              </DropdownItem>
            </DropdownContent>
          </DropdownRoot>
        </div>
      </Guard>
      <Button
        onClick={() => {}}
        variant="secondary"
        size="icon_40"
        iconLeft={<PurpleIcon name="printer" />}
      />
      <TakeActionModal />
      {isCreatePriorityListOpen && <CreatePriorityListModal students={selectedStudents} />}
      {isAddToExistingPriorityModalOpen && <AddToExistingPriorityListModal students={selectedStudents} />}
    </div>
  );
};

export { StudentsActionsRow };
