import { useMemo } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parseISO } from 'date-fns';
import { format as formatLocale } from 'date-fns/format';
import { getDay as getDayLocale } from 'date-fns/getDay';
import { enUS } from 'date-fns/locale/en-US';
import { parse as parseLocale } from 'date-fns/parse';
import { startOfWeek as startOfWeekLocale } from 'date-fns/startOfWeek';
import { cn, NumberBadge, Skeleton } from '@purple/ui';
import { getNumberStatusBasedOnPercentage } from '../helpers';
import { AttendanceCalendarToolbar } from './AttendanceCalendarToolbar';
import type React from 'react';
import type { DateCellWrapperProps } from 'react-big-calendar';
import type { TSchoolDataCalendar } from '@purple/shared-types';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format: formatLocale,
  parse: parseLocale,
  startOfWeek: startOfWeekLocale,
  getDay: getDayLocale,
  locales,
});

const MonthDateHeader: React.FC<DateCellWrapperProps & { isLoading: boolean }> = (props) => {
  const { isLoading = false, ...rest } = props;

  return isLoading
    ? (
        <div className="flex h-full w-full flex-[1_0_0%] border-grey-200 [&:not(:first-of-type)]:border-l">
          <Skeleton className="mx-3 mb-3 mt-auto h-6 w-16 rounded-md" />
        </div>
      )
    : (
        rest.children
      );
};

type TAttendanceCalendarProperties = {
  /**
   * Currently selected year in the parent component.
   */
  selectedYear: string;
  /**
   * Currently selected month in the parent component.
   */
  selectedMonth: string;
  /**
   * School attendance calendar data for the selected month and year.
   */
  data?: TSchoolDataCalendar;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Callback function to update selected date in the parent component.
   * @param date - Selected date.
   */
  onDateChange?: (date: Date) => void;
};

export const AttendanceCalendar: React.FC<TAttendanceCalendarProperties> = (props) => {
  const { selectedYear, selectedMonth, data, isLoading = false, onDateChange } = props;

  const selectedDate = useMemo(
    () => new Date(Number.parseInt(selectedYear, 10), Number.parseInt(selectedMonth, 10)),
    [selectedYear, selectedMonth],
  );

  const events = useMemo(
    () =>
      Object.entries(data ?? {}).map(([key, value]) => ({
        title: key,
        start: parseISO(key),
        end: parseISO(key),
        value: value / 100,
      })),
    [data],
  );

  const monthChangeHandler = (date: Date) => {
    onDateChange?.(date);
  };

  return (
    <Calendar
      date={selectedDate}
      onNavigate={monthChangeHandler}
      defaultView="month"
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      components={{
        toolbar: (toolbarProperties) => (
          <AttendanceCalendarToolbar {...toolbarProperties} selectedDate={selectedDate} />
        ),
        dateCellWrapper: (dateCellProperties) => <MonthDateHeader {...dateCellProperties} isLoading={isLoading} />,
        event: (eventProperties) => (
          <NumberBadge variant={getNumberStatusBasedOnPercentage(eventProperties.event.value)} size="small">
            {eventProperties.event.value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
          </NumberBadge>
        ),
      }}
      className={cn(
        'col-span-2 rounded-lg bg-white',
        '[&_.rbc-button-link]:pointer-events-none',
        '[&_.rbc-row:first-child]:pb-1 [&_.rbc-row:first-child]:pt-1.5',
        '[&_.rbc-event]:pointer-events-none [&_.rbc-event]:m-0',
      )}
      formats={{
        weekdayFormat: (date, culture, locale) => locale?.format(date, 'EEE', culture) ?? format(date, 'EEE'),
      }}
    />
  );
};
