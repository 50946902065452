import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolActionsList } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolActionsRequestParameters } from '@purple/shared-types';

export const useSchoolActionsList = ({
  schoolId,
  queryParameters,
}: {
  schoolId: string;
  queryParameters: TSchoolActionsRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST, { schoolId, queryParameters }],
    queryFn: () => getSchoolActionsList({ schoolId, queryParameters }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch schools actions');
    }
  }, [isError]);

  return { data, isLoading };
};
