import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IStudentCalendarRequest, TCalendar } from '@purple/shared-types';

export type IStudentCalendarResponse = {
  calendar: TCalendar;
};

export const getStudentCalendarEvents = async (request: IStudentCalendarRequest) => {
  const { studentId, month, year, itemType, period } = request;

  const axiosInstance = getAxiosInstance();

  const path = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_CALENDAR, studentId);
  const url = `${path}?month=${month}&year=${year}&item_type=${itemType}&period=${period}`;

  const response = await axiosInstance.get<IStudentCalendarResponse>(url);
  return response.data;
};
