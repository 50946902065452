import { EmailStatus } from '@purple/shared-types';

export const EMAIL_STATUS_FILTER_OPTIONS = Object.entries(EmailStatus).map(([key, value]) => ({
  label: key,
  value,
}));

export const EMAIL_SORT_OPTIONS = [
  {
    label: 'Subject (ASC)',
    value: 'subject',
  },
  {
    label: 'Subject (DESC)',
    value: '-subject',
  },
  {
    label: 'Date (ASC)',
    value: 'created_at',
  },
  {
    label: 'Date (DESC)',
    value: '-created_at',
  },
  {
    label: 'Status (ASC)',
    value: 'status',
  },
  {
    label: 'Status (DESC)',
    value: '-status',
  },
] as const;

export const SafEmailQueryName = {
  STATUS: 'status',
  VIEW: 'view',
  CORRESPONDENCE: 'correspondence',
} as const;

export const SafEmailViewType = {
  LIST: 'list',
  DETAILS: 'details',
} as const;

export const StatusToBadgeVariantMap = {
  [EmailStatus.New]: 'info',
  [EmailStatus.Sent]: 'success-light',
  [EmailStatus.Acknowledged]: 'success',
  [EmailStatus.Pending]: 'warning-light',
  [EmailStatus.Failed]: 'danger',
} as const;
