import { useMemo } from 'react';
import { SAF_PROCESS_STEPS_VALUES } from '@purple/shared-types';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, cn } from '@purple/ui';
import { useSafDetails } from '~/queries/saf/useSafDetails';
import { AssignPriorityForm } from '../AssignPriorityForm';
import { LinkStudentForm } from '../LinkStudentForm';
import { SafManagerStepItem } from '../SafManagerStepItem';

type TSafManagerAccordionProperties = {
  safId?: string;
};

export const SafManagerAccordion = ({ safId }: TSafManagerAccordionProperties) => {
  const { data } = useSafDetails(safId as string);

  const { saf_processing_step, linked_student_details, priority } = data || {};

  const isSafProcessingStepLinkStudent = useMemo(
    () => saf_processing_step === SAF_PROCESS_STEPS_VALUES.LINK_STUDENT,
    [saf_processing_step],
  );
  const isSafProcessingStepPriority = useMemo(
    () => saf_processing_step === SAF_PROCESS_STEPS_VALUES.ASSIGN_PRIORITY,
    [saf_processing_step],
  );

  const defaultAccordionValue = useMemo(() => {
    if (isSafProcessingStepLinkStudent) {
      return 'link-student';
    }
    if (isSafProcessingStepPriority) {
      return 'assign-priority';
    }
    return '';
  }, [isSafProcessingStepLinkStudent, isSafProcessingStepPriority]);

  return (
    <Accordion type="single" collapsible value={defaultAccordionValue}>
      <AccordionItem value="link-student" className="border-b-0">
        <AccordionTrigger asChild disabled={!isSafProcessingStepLinkStudent}>
          <SafManagerStepItem
            className={cn('px-4 py-3', isSafProcessingStepLinkStudent && 'bg-warning-bg')}
            stepTitle="Link Student"
            stepDescription="Link the SAF to a student in the system"
            activeStateIcon="link"
            isBlocked={!isSafProcessingStepLinkStudent}
            isCompleted={Boolean(linked_student_details?.id)}
            isCurrent={isSafProcessingStepLinkStudent}
          />
        </AccordionTrigger>
        <AccordionContent className="px-[72px] py-4">
          <LinkStudentForm />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="assign-priority" className="border-b-0">
        <AccordionTrigger
          className={cn('px-4 py-3', isSafProcessingStepPriority && 'bg-warning-bg')}
          disabled={!isSafProcessingStepPriority}
          asChild
        >
          <SafManagerStepItem
            stepTitle="Assign Priority"
            stepDescription="Assign priority to notify the submitter that the SAF is being worked on"
            activeStateIcon="Exclamation-mark"
            isBlocked={!isSafProcessingStepPriority && !priority}
            isCompleted={Boolean(priority)}
            isCurrent={isSafProcessingStepPriority}
          />
        </AccordionTrigger>
        <AccordionContent className="px-[72px] py-4">
          <AssignPriorityForm />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
