import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentsDataList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertStudentDataResponseToDataTable } from '../converter';
import type { TStudentDataListRequestParameters } from '@purple/shared-types';

export const useGetStudentsDataList = (request: TStudentDataListRequestParameters) => {
  const { isError, error, data, isLoading, isSuccess } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_DATA_LIST, { request }],
    queryFn: () => getStudentsDataList(request),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch students data', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, error, isSuccess, data: data ? convertStudentDataResponseToDataTable(data) : data };
};
