import { Link } from 'react-router-dom';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { SubpopInfo } from './SubpopInfo';
import type { ISubPopulation } from '@purple/shared-types';

type TStudentCellProperties = React.PropsWithChildren<{
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  avatar: string | null;
  isTurnedRed: boolean | null;
  subpop: ISubPopulation[];
}>;

const StudentCell = ({ id, firstName, lastName, avatar, isTurnedRed, subpop }: TStudentCellProperties) => {
  const fullName = `${firstName} ${lastName}`;

  const isSubpopExist = subpop?.length > 0;

  return (
    <div className="flex w-fit items-center gap-2">
      <Avatar size={24}>
        <AvatarImage src={avatar ?? undefined} alt={firstName} />
        <AvatarFallback>{getInitialsFromName(lastName || firstName)}</AvatarFallback>
      </Avatar>
      <div className="flex flex-row items-center gap-1">
        <Link to={AppRoutes.App.Students.StudentDetails.Root.makePath(id, 'dashboard')}>
          <Text variant="size-14" type="body-500" className="text-brand-blue-700">
            {fullName}
          </Text>
        </Link>
        {isSubpopExist && <SubpopInfo subpop={subpop} />}
        {isTurnedRed && (
          <Text variant="size-14" type="body-500" className="text-error-main">
            !
          </Text>
        )}
      </div>
    </div>
  );
};

export { StudentCell };
