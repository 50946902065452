import { Fragment, useCallback, useMemo } from 'react';
import { TakeActionType } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import { Separator } from '@purple/ui';
import {
  ActionDetailsSection,
  ActionGoalsSection,
  ActionHeader,
  ActionTagsSection,
  CrisisDetailsSection,
  CrisisFieldsSection,
  DateAndTimeSection,
  NotesSection,
  ScreenersAndResultsSection,
} from './components';
import { ActionLayoutComponentType, IndividualActionDetailsLayout } from './config';
import { IndividualActionDetailsAlternate } from './IndividualActionDetailsAlternate';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';
import type { TActionLayoutComponentType } from './config';

type TIndividualActionDetailsProperties = {
  action: TActionDetails;
};

export const IndividualActionDetails: React.FC<TIndividualActionDetailsProperties> = (props) => {
  const { action } = props;

  const getSectionNode = useCallback(
    (type: TActionLayoutComponentType) => {
      switch (type) {
        case ActionLayoutComponentType.DETAILS: {
          return {
            type,
            element: <ActionDetailsSection action={action} />,
            isPresented: isFieldExist(action.details),
          };
        }
        case ActionLayoutComponentType.TAGS: {
          return {
            type,
            element: <ActionTagsSection action={action} />,
            isPresented: isFieldExist(action.tags),
          };
        }
        case ActionLayoutComponentType.GOALS: {
          return {
            type,
            element: <ActionGoalsSection action={action} />,
            isPresented: isFieldExist(action.goal_or_progress_towards_goal) || isFieldExist(action.student_goals),
          };
        }
        case ActionLayoutComponentType.DATE_AND_TIME: {
          return {
            type,
            element: <DateAndTimeSection action={action} />,
            isPresented: isFieldExist(action.date_and_time_details),
          };
        }
        case ActionLayoutComponentType.SCREENERS_AND_RESULTS: {
          return {
            type,
            element: <ScreenersAndResultsSection action={action} />,
            isPresented: isFieldExist(action.screeners_and_results),
          };
        }
        case ActionLayoutComponentType.CRISIS_FIELDS: {
          return {
            type,
            element: <CrisisFieldsSection action={action} />,
            isPresented:
              isFieldExist(action.parent_caregiver_conversation_summary)
              || isFieldExist(action.risk_level_result_explanation)
              || isFieldExist(action.follow_up_plan),
          };
        }
        case ActionLayoutComponentType.CRISIS_DETAILS: {
          return {
            type,
            element: <CrisisDetailsSection action={action} />,
            isPresented: isFieldExist(action.crisis_details),
          };
        }
        default: {
          return null;
        }
      }
    },
    [action],
  );

  const layout = useMemo(
    () => IndividualActionDetailsLayout[action.record_action_type] ?? null,
    [action.record_action_type],
  );
  const rawSections = useMemo(
    () => ({
      left:
        layout?.LEFT.map((type) => getSectionNode(type)).flatMap((item) => (item && item.isPresented ? [item] : []))
        ?? [],
      right:
        layout?.RIGHT.map((type) => getSectionNode(type)).flatMap((item) => (item && item.isPresented ? [item] : []))
        ?? [],
    }),
    [layout, getSectionNode],
  );
  const sections = useMemo(() => {
    // Move some section from left to right and vice versa if one of them is empty
    if (rawSections.left.length === 0 && rawSections.right.length > 0) {
      return {
        left: rawSections.right.slice(0, Math.floor(rawSections.right.length / 2)),
        right: rawSections.right.slice(Math.floor(rawSections.right.length / 2)),
      };
    }

    if (rawSections.right.length === 0 && rawSections.left.length > 0) {
      return {
        left: rawSections.left.slice(0, Math.floor(rawSections.left.length / 2)),
        right: rawSections.left.slice(Math.floor(rawSections.left.length / 2)),
      };
    }

    return rawSections;
  }, [rawSections]);

  if (action.record_action_type === TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING) {
    return <IndividualActionDetailsAlternate action={action} />;
  }

  if (layout === null) return null;

  return (
    <div className="flex w-full flex-col gap-6">
      <section className="flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white p-6 shadow-custom-light">
        <ActionHeader actionData={action} />
        <div className="flex w-full gap-10">
          {sections.left.length > 0 && (
            <div className="flex w-full flex-col gap-3">
              {sections.left.map((item, index, self) => (
                <Fragment key={item.type}>
                  {item.element}
                  {index < self.length - 1 && <Separator className="first:hidden last:hidden" />}
                </Fragment>
              ))}
            </div>
          )}
          <Separator orientation="vertical" className="first:hidden last:hidden" />
          {sections.right.length > 0 && (
            <div className="flex w-full flex-col gap-3">
              {sections.right.map((item, index, self) => (
                <Fragment key={item.type}>
                  {item.element}
                  {index < self.length - 1 && <Separator className="first:hidden last:hidden" />}
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </section>
      <NotesSection action={action} />
    </div>
  );
};
