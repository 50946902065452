import { DaysOfWeek, type TDaysOfWeek } from '../days';
import type { Prettify, ValueOf } from '../utils';
import type { IStudentIncidentCreator } from './students';

export const ReminderType = {
  ONE_TIME: 'one_time',
  RECURRING: 'recurring',
} as const;
export type TReminderType = ValueOf<typeof ReminderType>;

export const ReminderWeeklyInterval = {
  EVERY: 'every',
  ALTERNATE: 'alternate',
  FIRST: 'first',
  SECOND: 'second',
  THIRD: 'third',
  FOURTH: 'fourth',
  FIFTH: 'fifth',
} as const;
export type TReminderWeeklyInterval = ValueOf<typeof ReminderWeeklyInterval>;

export const ReminderDaysOfWeek = {
  ...DaysOfWeek,
} as const;
export type TReminderDaysOfWeek = TDaysOfWeek;

export type TReminder = {
  student: string;
  subject: string;
  description?: string | null;
  date_and_time: string;
  notify_before_minutes: number;
  is_all_day_event: boolean;
  duration?: number | null;
};

export type TReminderRecurring = Prettify<
  Omit<TReminder, 'date_and_time'> & {
    start_date_and_time: string;
    end_date_and_time: string;
    days_of_week: TReminderDaysOfWeek[];
    weekly_interval: TReminderWeeklyInterval;
  }
>;

export type TReminderCreateBody = TReminder;
export type TReminderCreateResponse = TReminder;

export type TReminderUpdateBody = Partial<TReminder>;
export type TReminderUpdateResponse = TReminder & { id: string };

export type TReminderCreateRecurringBody = TReminderRecurring;
export type TReminderCreateRecurringResponse = TReminderRecurring;

export type TReminderUpdateRecurringBody = Partial<TReminderRecurring>;
export type TReminderUpdateRecurringResponse = TReminderRecurring;

export type TReminderDetails = Prettify<
  TReminder &
  TReminderRecurring & {
    created_by: IStudentIncidentCreator | null;
    browser_url: string;
    type: TReminderType;
    recurring_group_id: string | null;
  }
>;
