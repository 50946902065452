import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import * as z from 'zod';
import { SAF_NO_NEED_REASON_VALUES, type TSafDetailsDto, type TSafNoNeedReasonValues } from '@purple/shared-types';
import { convertToValue, formatDate, SAF_QUERY_KEYS } from '@purple/shared-utils';
import {
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Text,
  Textarea,
} from '@purple/ui';
import { SAF_NO_NEED_REASON_OPTIONS } from '~/components/Modals/SafModals/SafNoNeedModal/constants';
import { useUpdateSafDetails } from '~/queries/saf/useUpdateSafDetails';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import TabEditSection from '../TabEditSection/TabEditSection';

const safNoNeedDetailsFormSchema = z.object({
  reason: z.array(z.nativeEnum(SAF_NO_NEED_REASON_VALUES)).min(1, { message: 'Please provide a reason' }),
  detail: z.string().min(1, { message: 'Please provide a detail' }),
});

type TFormValues = z.infer<typeof safNoNeedDetailsFormSchema>;

type TSafNoNeedDetailsProperties = {
  safId?: string | null;
};

export const SafNoNeedDetails = ({ safId }: TSafNoNeedDetailsProperties) => {
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);
  const { no_need_details } = safDetailsData || {};

  const { mutate, isPending } = useUpdateSafDetails();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(safNoNeedDetailsFormSchema),
    defaultValues: {
      reason: no_need_details?.no_need_reason.map((reason) => convertToValue(reason) as TSafNoNeedReasonValues) || [],
      detail: no_need_details?.no_need_reason_text || '',
    },
  });

  const updateNoNeedDetails = ({ detail, reason }: TFormValues) => {
    if (!safId) {
      return showErrorToast('System Message', 'Unable to update No Need Details. Check if the SAF is valid.');
    }
    mutate(
      {
        safId,
        requestBody: {
          no_need_reason: reason,
          no_need_reason_text: detail,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast('System Message', 'No Need Details updated successfully');
          queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
          setIsEditing(false);
          form.reset({
            reason,
            detail,
          });
        },
        onError: () => showErrorToast('System Message', 'Unable to update No Need Details'),
      },
    );
  };

  return (
    <DescriptionList>
      <TabEditSection
        isEditMode={isEditing}
        onEditChange={setIsEditing}
        isUpdating={isPending}
        onUpdate={form.handleSubmit(updateNoNeedDetails)}
      />
      <DescriptionItem>
        <DescriptionTerm>No Need Date</DescriptionTerm>
        <DescriptionDetails>
          {no_need_details && no_need_details.status_changed_at ? formatDate(no_need_details.status_changed_at) : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="reason"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>Reason</FormLabel>
                    <FormControl>
                      <MultiSelect
                        {...field}
                        isError={!!fieldState.error}
                        selectedOptions={SAF_NO_NEED_REASON_OPTIONS.filter((item) => field.value.includes(item.value))}
                        modalPopover
                        placeholder="Select reasons"
                        shouldFilter
                        onOptionChange={field.onChange}
                      >
                        {SAF_NO_NEED_REASON_OPTIONS.map((item) => (
                          <MultiSelectItem
                            key={item.value}
                            value={item.value}
                            option={item}
                            isSelected={field.value.includes(item.value)}
                          >
                            {item.label}
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="detail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Details</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!form.formState.errors.detail}
                        placeholder="Enter details here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <>
              <DescriptionItem>
                <DescriptionTerm>Reason</DescriptionTerm>
                <DescriptionDetails>{no_need_details?.no_need_reason.join(', ') ?? '—'}</DescriptionDetails>
              </DescriptionItem>
              <div className="flex flex-col gap-1">
                <Text variant="size-16" type="body-600">
                  Details
                </Text>
                <Text variant="size-16" type="body-400" className="text-grey-600">
                  {no_need_details?.no_need_reason_text || '—'}
                </Text>
              </div>
            </>
          )}
    </DescriptionList>
  );
};
