import { getAxiosInstance } from '../../api';
import { REMINDERS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TReminderDetails } from '@purple/shared-types';

export const getReminderDetails = async (reminderId: string) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(REMINDERS.GET_REMINDERS_DETAILS, reminderId);
  const response = await axiosInstance.get<TReminderDetails>(URL_PATH);
  return response.data;
};
