import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Separator,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useDeleteNote } from '~/queries';
import { showErrorToast } from '~/shared/lib';

type TDeleteStudentNoteDialogProperties = {
  noteId: string | null;
  setSelectedNoteId: (noteId: string | null) => void;
};

export const DeleteStudentNoteDialog = ({ noteId, setSelectedNoteId }: TDeleteStudentNoteDialogProperties) => {
  const { isOpen, toggleModal } = useModal('delete-student-note');

  const { mutate, isPending } = useDeleteNote();

  const handleCloseModal = () => {
    setSelectedNoteId(null);
    toggleModal(!isOpen);
  };

  const handleDeleteNote = () => {
    if (!noteId) {
      showErrorToast('Unable to delete student note', 'System Error');
      return;
    }

    mutate(
      { noteId },
      {
        onSuccess: () => {
          handleCloseModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseModal}>
      <DialogContent className="max-w-[550px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle className='className="leading-7 tracking-normal'>Delete Note</DialogTitle>
          <DialogDescription className="sr-only">Delete dialog modal</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="p-6">
          <Text variant="size-14" type="body-500" className="text-grey-600">
            Are you sure you want to delete this note? This action cannot be undone. Proceed?
          </Text>
        </div>
        <Separator />
        <DialogFooter>
          <Button variant="link" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="destructive_primary" onClick={handleDeleteNote} disabled={isPending} isLoading={isPending}>
            Yes, Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
