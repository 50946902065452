import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TPriorityListUpdateBody, TPriorityListUpdateResponse } from '@purple/shared-types';

export const updatePriorityList = async (request: TPriorityListUpdateBody) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.UPDATE_PRIORITY_LIST_BY_ID, request.id);
  const response = await apiInstance.patch<TPriorityListUpdateResponse>(URL, {
    name: request.name,
    description: request.description,
  });
  return response.data;
};
