import * as React from 'react';
import Image from '@tiptap/extension-image';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { cn, Separator } from '@purple/ui';
import { FirstSection, FourthSection, SecondSection, ThirdSection } from './components/section';
import { getOutput } from './utils';

import type { Editor as TiptapEditor } from '@tiptap/core';
import './styles/index.css';

export type MinimalTiptapProperties = {
  value?: string | null;
  outputValue?: 'html' | 'json' | 'text';
  placeholder?: string;
  disabled?: boolean;
  contentClass?: string;
  onValueChange: (value: string) => void;
  /**
   * The id of entity that image will be bound to. For example, studentId or noteId. This is only required for image uploading
   */
  objectId?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

const useMinimalTiptapEditor = (props: MinimalTiptapProperties) => {
  const { value, outputValue = 'html', placeholder, disabled, onValueChange } = props;

  return useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Image,
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: () => placeholder || '',
      }),
    ],
    editorProps: {
      attributes: {
        class: 'focus:outline-none h-full',
      },
    },
    onUpdate: ({ editor }) => {
      onValueChange(getOutput(editor, outputValue));
    },
    content: value,
    editable: !disabled,
    onCreate: ({ editor }) => {
      if (value) {
        editor.commands.setContent(JSON.parse(value) as string);
      }
    },
  });
};

const Toolbar = ({ editor, objectId }: { editor: TiptapEditor; objectId?: string }) => (
  <div className="flex w-max items-center rounded-md border border-grey-300">
    <FirstSection editor={editor} />
    <Separator orientation="vertical" className="mx-1 h-[20px]" />
    <SecondSection editor={editor} />
    <Separator orientation="vertical" className="mx-1 h-[20px]" />
    <ThirdSection editor={editor} />
    {objectId && (
      <>
        <Separator orientation="vertical" className="mx-1 h-[20px]" />
        <FourthSection editor={editor} objectId={objectId} />
      </>
    )}
  </div>
);

export const MinimalTiptapEditor = React.forwardRef<HTMLDivElement, MinimalTiptapProperties>(
  (
    { value, outputValue, disabled, contentClass, onValueChange, placeholder, className, objectId, ...props },
    reference,
  ) => {
    const editor = useMinimalTiptapEditor({ value, outputValue, placeholder, disabled, onValueChange });

    React.useEffect(() => {
      if (value) {
        editor?.commands.setContent(JSON.parse(value) as string);
      }
    }, [value, editor]);

    return (
      <div className="flex flex-col gap-2">
        {editor && !disabled && <Toolbar editor={editor} objectId={objectId} />}

        <div
          className={cn(
            'flex h-auto min-h-72 w-full flex-col rounded-md border border-grey-300 shadow-sm focus-within:border-brand-blue-700',
            className,
          )}
          {...props}
          ref={reference}
        >
          <EditorContent
            editor={editor}
            className={cn(
              'minimal-tiptap-editor h-full px-3 py-2.5 font-primary',
              contentClass,
              disabled && 'cursor-text border-grey-300 text-grey-300',
            )}
          />
        </div>
      </div>
    );
  },
);

MinimalTiptapEditor.displayName = 'MinimalTiptapEditor';

export default MinimalTiptapEditor;
