export const CRISIS_MODE_OF_SUPPORT = {
  MAIL: 'email',
  IN_PERSON: 'in_person',
  PHONE: 'phone',
  VIRTUAL: 'virtual',
} as const;

export const CRISIS_MODE_OF_SUPPORT_OPTIONS = [
  {
    label: 'Email',
    value: CRISIS_MODE_OF_SUPPORT.MAIL,
  },
  {
    label: 'In Person',
    value: CRISIS_MODE_OF_SUPPORT.IN_PERSON,
  },
  {
    label: 'Phone',
    value: CRISIS_MODE_OF_SUPPORT.PHONE,
  },
  {
    label: 'Virtual',
    value: CRISIS_MODE_OF_SUPPORT.VIRTUAL,
  },
] as const;
