import { useCallback, useMemo, useState } from 'react';
import { usePaginate, useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import {
  Button,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  TableFooterSection,
} from '@purple/ui';
import { CallToActionModal, DataTable, DataTableSkeleton } from '~/components';
import { ModalType } from '~/constants/modals';
import { ActionTypeToPermission } from '~/constants/take-action';
import { useModal } from '~/hooks';
import { useCurrentUser } from '~/hooks/redux';
import { useActionPurpleUserParticipants, useRemovePurpleUserParticipants } from '~/queries';
import { PURPLE_USER_PARTICIPANT_SORT } from '../constants';
import { usePurpleUserParticipantsColumns } from '../usePurpleUserParticipantsColumns';
import { AddParticipantsDialog } from './AddParticipantsDialog';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TActionUserParticipantsProperties = {
  action: TActionDetails;
};

export const ActionUserParticipants: React.FC<TActionUserParticipantsProperties> = (props) => {
  const { action } = props;

  const [selectedParticipantIds, setSelectedParticipantIds] = useState<string[]>([]);
  const { openModal, closeModal } = useModal(ModalType.DELETE_ACTION_PARTICIPANTS);
  const { openModal: openAddParticipantsModal } = useModal(ModalType.ADD_ACTION_PARTICIPANTS);
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onClearSearch, onSearchChange } = useSearch();
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () =>
      user.id === action.details.created_by?.id && hasPermissions(ActionTypeToPermission[action.record_action_type]),
    [user, action, hasPermissions],
  );
  const columns = usePurpleUserParticipantsColumns({
    allowedToDelete: allowedToEdit,
    onDelete: (userId) => {
      setSelectedParticipantIds([userId]);
      openModal();
    },
  });

  const { isLoading: isDataLoading, data } = useActionPurpleUserParticipants(action.id, {
    search,
    limit,
    offset,
    ordering: sort,
  });
  const { mutate: removeUsers, isPending } = useRemovePurpleUserParticipants(action.id);

  const users = useMemo(() => data?.results || [], [data?.results]);
  const isDataNotExist = useMemo(() => users.length === 0 && !isDataLoading, [users, isDataLoading]);
  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  const removeParticipantsHandler = useCallback(() => {
    removeUsers(
      { purple_user_participants: selectedParticipantIds },
      {
        onSuccess: () => {
          setSelectedParticipantIds([]);
          closeModal();
        },
      },
    );
  }, [removeUsers, selectedParticipantIds, closeModal]);

  return (
    <section className="flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white shadow-custom-light">
      <Heading tag="h2" className="px-4 pt-6 text-lg font-semibold text-grey-title">
        Purple Users Participants
      </Heading>
      <div className="flex w-full flex-col gap-5">
        <div className="flex w-full items-center justify-between gap-2 px-4">
          <div className="flex items-center gap-4">
            <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
              <RadixSelectTrigger
                hasClearButton={hasSortValue}
                onClearCallback={onSortClear}
                triggerContainerClassName="w-[180px]"
              >
                <RadixSelectValue placeholder="Sort" />
              </RadixSelectTrigger>
              <RadixSelectContent className="w-max">
                {PURPLE_USER_PARTICIPANT_SORT.map((option) => (
                  <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                    {option.label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
            <SearchInput
              value={search}
              onChange={onSearchChange}
              onClear={onClearSearch}
              placeholder="Search by title"
              className="max-w-[300px]"
            />
          </div>
          {allowedToEdit && (
            <Button
              type="button"
              variant="primary"
              iconLeft={<PurpleIcon name="plus" />}
              onClick={openAddParticipantsModal}
            >
              Add Purple Users
            </Button>
          )}
        </div>
        <div className="flex w-full flex-col">
          <DataTable
            columns={columns}
            data={users}
            isLoading={isDataLoading}
            isFiltersApplied={Boolean(search)}
            classNames={{ cell: 'py-3 only:py-0' }}
            emptyStateTitle="Purple Users Participants not found"
            emptyStateMessage="There are no participants to display. If an error occurs, please contact support."
            skeleton={<DataTableSkeleton rows={limit} rowClassName="py-4" />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTION_PARTICIPANTS}
        modalTitle="Delete Purple User Participant"
        modalDescription="Selected user will be removed from the session of the action"
        modalTextContent="Are you sure you want delete selected user from the session?"
        onPrimaryButtonClick={removeParticipantsHandler}
        isLoading={isPending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
      <AddParticipantsDialog actionId={action.id} existingParticipants={users} />
    </section>
  );
};
