import { useId, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { ReminderType, type TReminderUpdateBody, type TReminderUpdateRecurringBody } from '@purple/shared-types';
import { REMINDERS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NoDataAvailable,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useDeleteOneTimeReminder, useReminderDetails } from '~/queries/reminders';
import { useUpdateRecurrentReminder } from '~/queries/reminders/useUpdateRecurrentReminder';
import { useUpdateReminder } from '~/queries/reminders/useUpdateReminder';
import { showErrorToast } from '~/shared/lib';
import { CallToActionModal } from '../../CallToActionModal';
import { ConfirmEditRecurringReminder } from '../ConfirmEditRecurringReminder';
import { DeleteRecurringReminder } from '../DeleteRecurringReminder';
import { ReminderContent } from './ReminderContent';
import { ReminderEditForm } from './ReminderEditForm';
import { ReminderEventModalSkeleton } from './ReminderEventModalSkeleton';
import type * as z from 'zod';
import type { takeActionReminderSchema } from '../../TakeActionModal/ReminderModal/schema';

type TReminderEventModalProperties = {
  eventId: string | null;
  onChangeSelectedEventId?: (id: string | null) => void;
};

export const ReminderEventModal = ({ eventId, onChangeSelectedEventId }: TReminderEventModalProperties) => {
  const queryClient = useQueryClient();
  const formId = useId();

  const formDataReference = useRef<z.infer<typeof takeActionReminderSchema>>();

  const [isEditMode, setIsEditMode] = useState(false);

  const { isOpen, toggleModal } = useModal('reminder-event-modal');
  const { isOpen: isDeleteReminderOpen, closeModal: closeDeleteReminderModal }
    = useModal('delete-reminder-event-modal');
  const { isOpen: isDeleteRecurringOpen } = useModal('delete-recurring-reminder-event-modal');
  const {
    isOpen: isConfirmEditOpen,
    openModal: openConfirmEditModal,
    closeModal: closeConfirmEditModal,
  } = useModal('confirm-edit-recurring-reminder-modal');

  const { data, isLoading, isError } = useReminderDetails(eventId as string);
  const { mutate: deleteOneTimeReminder, isPending: isOneTimeDeleting } = useDeleteOneTimeReminder();

  const { mutate: updateOnetimeReminder, isPending: isOneTimeUpdating } = useUpdateReminder();
  const { mutate: updateRecurrentReminder, isPending: isRecurringUpdating } = useUpdateRecurrentReminder();

  const modalTitleText = useMemo(() => {
    return isEditMode ? 'Edit Reminder' : 'Reminder';
  }, [isEditMode]);

  const toggleModalCloseHandler = () => {
    onChangeSelectedEventId?.(null);
    toggleModal(!isOpen);
  };

  const updateSingleReminderHandler = (formData: z.infer<typeof takeActionReminderSchema>) => {
    if (!eventId) {
      return showErrorToast('System Message', 'Cannot update this reminder');
    }
    const requestBody: TReminderUpdateBody = {
      subject: formData.subject,
      description: formData.description,
      date_and_time: formData.date.toISOString(),
      notify_before_minutes: formData.notifyBeforeStart,
      is_all_day_event: formData.isAllDayEvent,
      duration: formData.duration,
    };
    updateOnetimeReminder(
      { body: requestBody, reminderId: eventId },
      {
        onSuccess: (responseData) => {
          queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
          queryClient.invalidateQueries({
            queryKey: [REMINDERS_QUERY_KEYS.GET_REMINDER_DETAILS, responseData.id],
          });
          closeConfirmEditModal();
          toggleModalCloseHandler();
          formDataReference.current = undefined;
        },
      },
    );
  };

  const updateReminderGroupHandler = (formData: z.infer<typeof takeActionReminderSchema>) => {
    if (data && data.recurring_group_id) {
      const requestBody: TReminderUpdateRecurringBody = {
        subject: formData.subject,
        student: data.student,
        description: formData.description,
        start_date_and_time: formData.date.toISOString(),
        end_date_and_time: formData.endDate?.toISOString(),
        days_of_week: formData.daysOfWeek,
        weekly_interval: formData.weeklyInterval,
        notify_before_minutes: formData.notifyBeforeStart,
        is_all_day_event: formData.isAllDayEvent,
        duration: formData.duration,
      };
      return updateRecurrentReminder(
        { body: requestBody, reminderId: data?.recurring_group_id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
            closeConfirmEditModal();
            toggleModalCloseHandler();
            formDataReference.current = undefined;
          },
        },
      );
    }
    showErrorToast('System Message', 'Cannot update this reminder');
  };

  const confirmEditHandler = (selectedReminderType: 'single' | 'all') => {
    if (!formDataReference.current) {
      return showErrorToast('System Message', 'An error occurred while updating reminder');
    }

    if (selectedReminderType === 'single') {
      return updateSingleReminderHandler(formDataReference.current);
    }
    updateReminderGroupHandler(formDataReference.current);
  };

  const updateReminderHandler = (formData: z.infer<typeof takeActionReminderSchema>) => {
    if (formData.type === ReminderType.ONE_TIME) {
      return updateSingleReminderHandler(formData);
    }
    formDataReference.current = formData;
    openConfirmEditModal();
  };

  const deleteReminderHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while deleting reminder');
    }
    deleteOneTimeReminder(eventId, {
      onSuccess: () => {
        toggleModalCloseHandler();
        closeDeleteReminderModal();
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModalCloseHandler}>
        <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle>{modalTitleText}</DialogTitle>
            <DialogDescription className="sr-only">Reminder event modal</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          {(!eventId || isError) && <NoDataAvailable iconName="folder" className="h-[368px] text-grey-600" />}
          {eventId && (
            <ScrollArea
              type="auto"
              className="flex max-h-[640px] w-full flex-col p-0"
              scrollBarClassName="p-2 w-[22px]"
            >
              {isLoading && !data && <ReminderEventModalSkeleton />}
              {data && !isLoading && !isEditMode && (
                <ReminderContent reminderDetails={data} onEditChange={setIsEditMode} />
              )}
              {data && !isLoading && isEditMode && (
                <ReminderEditForm reminderDetails={data} formId={formId} onSubmitForm={updateReminderHandler} />
              )}
            </ScrollArea>
          )}
          {isEditMode && (
            <>
              <Separator />
              <DialogFooter>
                <Button variant="tertiary" onClick={() => setIsEditMode(false)}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isOneTimeUpdating || isRecurringUpdating}
                  isLoading={isOneTimeUpdating || isRecurringUpdating}
                  form={formId}
                >
                  Update
                </Button>
              </DialogFooter>
            </>
          )}

        </DialogContent>
      </Dialog>
      {isDeleteReminderOpen && (
        <CallToActionModal
          modalName="delete-reminder-event-modal"
          modalTitle="Delete Reminder"
          modalDescription="Delete reminder modal"
          modalTextContent="Are you sure you want delete that activity?"
          primaryButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          primaryButtonVariant="destructive_primary"
          isLoading={isOneTimeDeleting}
          onPrimaryButtonClick={deleteReminderHandler}
        />
      )}
      {isDeleteRecurringOpen && (
        <DeleteRecurringReminder
          reminderId={eventId}
          reminderGroupId={data?.recurring_group_id}
          onSuccessfulDelete={toggleModalCloseHandler}
        />
      )}
      {isConfirmEditOpen && (
        <ConfirmEditRecurringReminder
          isUpdating={isOneTimeUpdating || isRecurringUpdating}
          onConfirmEdit={confirmEditHandler}
        />
      )}
    </>
  );
};
