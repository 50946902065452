import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardNewStudents } from '@purple/shared-services';
import { DASHBOARD_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useDashboardNewStudents = (parameters?: { enabled?: boolean }) => {
  const { enabled } = parameters ?? {};
  const { isError, ...props } = useQuery({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_NEW_STUDENTS],
    queryFn: getDashboardNewStudents,
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch dashboard new students data', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
