import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { formatDate, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import type { TSafDetailsDto } from '@purple/shared-types';

type TSafClosedDetailsProperties = {
  safId?: string | null;
};

export const SafClosedDetails = ({ safId }: TSafClosedDetailsProperties) => {
  const queryClient = useQueryClient();
  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);
  const { closed_saf_details } = safDetailsData || {};

  const serviceAreaCategories = useMemo(() => {
    return closed_saf_details?.service_area_subcategories ?? [];
  }, [closed_saf_details]);

  const categoriesWithSubCategories = useMemo(() => {
    return Object.entries(serviceAreaCategories).map(([category, subCategories]) => {
      return {
        category,
        subCategories,
      };
    });
  }, [serviceAreaCategories]);

  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Close Date</DescriptionTerm>
        <DescriptionDetails>
          {closed_saf_details && closed_saf_details.closed_at ? formatDate(closed_saf_details.closed_at) : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Days Until Closed</DescriptionTerm>
        <DescriptionDetails>{closed_saf_details?.time_until_closed_days ?? '—'}</DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Resolution</DescriptionTerm>
        <DescriptionDetails>{closed_saf_details?.resolution.join(', ') ?? '—'}</DescriptionDetails>
      </DescriptionItem>
      {categoriesWithSubCategories.map(({ category, subCategories }) => (
        <DescriptionItem key={category}>
          <DescriptionTerm>{category}</DescriptionTerm>
          <DescriptionDetails>{subCategories.map((subCategory) => subCategory.name).join(', ')}</DescriptionDetails>
        </DescriptionItem>
      ))}
    </DescriptionList>
  );
};
