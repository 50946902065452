import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch, useSort } from '@purple/hooks';
import { OFFSET_QUERY_NAME } from '@purple/shared-types';
import {
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { ACTION_TYPES_OPTIONS, GROUP_ACTIONS_SORT_OPTIONS } from './constants';

const ACTION_TYPE_QUERY_NAME = 'record_action_type';

export const MyGroupActionsHeader: React.FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const actionType = searchParameters.get(ACTION_TYPE_QUERY_NAME) ?? '';

  const { search, onClearSearch, onSearchChange } = useSearch();
  const { sort, onSortChange, onSortClear } = useSort();

  const actionTypeChangeHandler = useCallback(
    (newSort: string) => {
      setSearchParameters((previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        newSearchParameters.set(ACTION_TYPE_QUERY_NAME, newSort);

        // Reset the offset to 0 when the search value changes
        newSearchParameters.set(OFFSET_QUERY_NAME, '0');
        return newSearchParameters;
      });
    },
    [setSearchParameters],
  );

  const actionTypeClearHandler = useCallback(() => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete(ACTION_TYPE_QUERY_NAME);

      // Reset the offset to 0 when the search value changes
      newSearchParameters.set(OFFSET_QUERY_NAME, '0');
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-2 px-4 pt-6">
      <div className="flex items-center gap-4">
        <RadixSelect onValueChange={actionTypeChangeHandler} value={actionType}>
          <RadixSelectTrigger
            hasClearButton={Boolean(actionType)}
            onClearCallback={actionTypeClearHandler}
            triggerContainerClassName="w-[260px]"
          >
            <RadixSelectValue placeholder="All Action Types" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {ACTION_TYPES_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <RadixSelect onValueChange={onSortChange} value={sort}>
          <RadixSelectTrigger
            hasClearButton={Boolean(sort)}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[200px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {GROUP_ACTIONS_SORT_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        onClear={onClearSearch}
        placeholder="Search by action name"
        className="max-w-[300px]"
      />
    </div>
  );
};
