import { useQuery } from '@tanstack/react-query';
import { getSchoolOverview } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { convertSchoolOverview } from './converter';

export const useSchoolOverview = (schoolId: string) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_OVERVIEW, { schoolId }],
    queryFn: () => getSchoolOverview({ schoolId }),
    enabled: !!schoolId,
  });

  return {
    data: data ? convertSchoolOverview(data) : data,
    isError,
    isLoading,
  };
};
