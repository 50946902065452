import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafActions } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSafLinkedActionsRequestParameters } from '@purple/shared-types';

export const useSafLinkedActions = ({
  safId,
  requestQueryParameters,
}: {
  safId: string;
  requestQueryParameters: TSafLinkedActionsRequestParameters;
}) => {
  const { isError, ...rest } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_SAF_LINKED_ACTIONS, { safId, requestQueryParameters }],
    queryFn: () => getSafActions({ safId, requestParameters: requestQueryParameters }),
    enabled: !!safId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Unable to get saf linked actions');
    }
  }, [isError]);

  return { isError, ...rest };
};
