import { useMemo } from 'react';
import { format } from 'date-fns';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
// hooks
import { useActivityChoices } from '~/queries';
// components
import { LinkButton } from '~/components/LinkButton';
import { ReadableActivityType } from '~/constants/create-activity';
import { ReadableReminderDaysOfWeek } from '~/constants/reminder';
// helpers
import { AppRoutes } from '~/constants/routes/routes';
// types
import type React from 'react';
import type { TActivityInfo, TCrisisActivityType } from '@purple/shared-types';

type TActivityDetailsInfoProperties = {
  activity: TActivityInfo;
};

export const ActivityDetailsInfo: React.FC<TActivityDetailsInfoProperties> = (props) => {
  const {
    activity: { details },
  } = props;

  const {
    typeOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisGradesOptions,
    crisisTypesOptions,
  } = useActivityChoices({
    community_activity_type: details.community_activity_type.name,
    enabled: true,
  });
  const [readableType] = useMemo(
    () => typeOptions.filter((option) => option.value === details.type).map((option) => option.label),
    [details.type, typeOptions],
  );

  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Record Type</DescriptionTerm>
        <DescriptionDetails>{ReadableActivityType[details.community_activity_type.name]}</DescriptionDetails>
      </DescriptionItem>
      {isFieldExist(details.crisis_type) && (
        <DescriptionItem>
          <DescriptionTerm>Crisis Types</DescriptionTerm>
          <DescriptionDetails>
            {crisisTypesOptions
              .filter((item) => details.crisis_type?.includes(item.value as TCrisisActivityType))
              .map((item) => item.label)
              .join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.type) && (
        <DescriptionItem>
          <DescriptionTerm>Type</DescriptionTerm>
          <DescriptionDetails>{readableType}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.topic) && (
        <DescriptionItem>
          <DescriptionTerm>Topic</DescriptionTerm>
          <DescriptionDetails>{details.topic ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.school) && (
        <DescriptionItem>
          <DescriptionTerm>School</DescriptionTerm>
          <DescriptionDetails>
            <LinkButton to={AppRoutes.App.School.Root.makePath(details.school.id, 'overview')} className="text-base">
              {details.school.name}
            </LinkButton>
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.campus_contact_title) && (
        <DescriptionItem>
          <DescriptionTerm>Campus Contact Title</DescriptionTerm>
          <DescriptionDetails>
            {campusContactTitlesOptions.find((option) => option.value === details.campus_contact_title)?.label
            ?? details.campus_contact_title}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.campus_contact_name) && (
        <DescriptionItem>
          <DescriptionTerm>Campus Contact Name</DescriptionTerm>
          <DescriptionDetails>{details.campus_contact_name}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.contact_email) && (
        <DescriptionItem>
          <DescriptionTerm>Contact Email</DescriptionTerm>
          <DescriptionDetails>{details.contact_email}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.contact_phone_number) && (
        <DescriptionItem>
          <DescriptionTerm>Contact Phone Number</DescriptionTerm>
          <DescriptionDetails>{details.contact_phone_number}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.crisis_grade_level) && (
        <DescriptionItem>
          <DescriptionTerm>Crisis Grade Level</DescriptionTerm>
          <DescriptionDetails>
            {crisisGradesOptions
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
              .find((option) => option.value === details.crisis_grade_level)
              ?.label ?? details.crisis_grade_level}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.assigned_to) && (
        <DescriptionItem>
          <DescriptionTerm>Assigned to</DescriptionTerm>
          <DescriptionDetails>{details.assigned_to?.full_name ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {details.recurring_group === null && isFieldExist(details.date_and_time) && (
        <DescriptionItem>
          <DescriptionTerm>{details.recurring_group ? 'Start Date & Time' : 'Date & Time'}</DescriptionTerm>
          <DescriptionDetails>{format(new Date(details.date_and_time), 'MMMM d, yyyy, h:mm a')}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.recurring_group?.end_date_and_time) && (
        <DescriptionItem>
          <DescriptionTerm>Start Date & Time</DescriptionTerm>
          <DescriptionDetails>
            {format(new Date(details.recurring_group.start_date_and_time), 'MMMM d, yyyy, h:mm a')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.recurring_group?.end_date_and_time) && (
        <DescriptionItem>
          <DescriptionTerm>End Date & Time</DescriptionTerm>
          <DescriptionDetails>
            {format(new Date(details.recurring_group.end_date_and_time), 'MMMM d, yyyy, h:mm a')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.duration) && (
        <DescriptionItem>
          <DescriptionTerm>Duration</DescriptionTerm>
          <DescriptionDetails>{details.duration ?? 0}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.stakeholders_served) && (
        <DescriptionItem>
          <DescriptionTerm>Stakeholders Served</DescriptionTerm>
          <DescriptionDetails>
            {stakeholdersServedOptions
              .filter((item) => details.stakeholders_served?.includes(item.value))
              .map((item) => item.label)
              .join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.service_categories) && (
        <DescriptionItem>
          <DescriptionTerm>Service Categories</DescriptionTerm>
          <DescriptionDetails>
            {details.service_categories.map((service) => SAF_AREA_SUBCATEGORIES_LABELS[service.name]).join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.number_of_people_served) && (
        <DescriptionItem>
          <DescriptionTerm>Number of People Served</DescriptionTerm>
          <DescriptionDetails>{details.number_of_people_served}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.quantity_of_resources_provided) && (
        <DescriptionItem>
          <DescriptionTerm>Quantity of Resources Provided</DescriptionTerm>
          <DescriptionDetails>{details.quantity_of_resources_provided ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.bags_provided_count) && (
        <DescriptionItem>
          <DescriptionTerm>Quantity of Bags Provided</DescriptionTerm>
          <DescriptionDetails>{details.bags_provided_count}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.supported_school) && (
        <DescriptionItem>
          <DescriptionTerm>Supported School</DescriptionTerm>
          <DescriptionDetails>
            {details.supported_school === null
              ? (
                  '-'
                )
              : (
                  <LinkButton
                    to={AppRoutes.App.School.Root.makePath(details.supported_school.id, 'overview')}
                    className="text-base"
                  >
                    {details.supported_school.name}
                  </LinkButton>
                )}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.grade_level) && (
        <DescriptionItem>
          <DescriptionTerm>Grade Level</DescriptionTerm>
          <DescriptionDetails>
            {gradeLevelsOptions
              .filter((item) => details.grade_level?.includes(item.value))
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
              .map((grade) => grade.label)
              .join(', ') || '-'}
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.participants_count) && (
        <DescriptionItem>
          <DescriptionTerm>Number of Participants</DescriptionTerm>
          <DescriptionDetails>{details.participants_count ?? '-'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.session_participant) && (
        <DescriptionItem>
          <DescriptionTerm>Session Participant</DescriptionTerm>
          <DescriptionDetails>{details.session_participant.full_name}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.recurring_group?.weekly_interval) && (
        <DescriptionItem>
          <DescriptionTerm>Weekly Occurrence</DescriptionTerm>
          <DescriptionDetails className="capitalize">{details.recurring_group.weekly_interval}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.recurring_group?.days_of_week) && (
        <DescriptionItem>
          <DescriptionTerm>Days of Week</DescriptionTerm>
          <DescriptionDetails>
            {details.recurring_group.days_of_week.map((day) => ReadableReminderDaysOfWeek[day]).join(', ')}
          </DescriptionDetails>
        </DescriptionItem>
      )}
    </DescriptionList>
  );
};
