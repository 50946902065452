import { Separator, Skeleton } from '@purple/ui';

export const GroupActionDetailsSkeleton: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-1 gap-y-12 xl:grid-cols-3 xl:gap-6">
      <div className="flex w-full shrink-0 flex-col gap-6 rounded-lg border border-grey-200 bg-white p-6 shadow-custom-heavy">
        <div className="flex w-full justify-between gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Skeleton className="h-7 w-64" />
              <Skeleton className="h-7 w-72" />
              <div className="flex flex-wrap items-center gap-2">
                <Skeleton className="h-6 w-20 rounded-full" />
                <Skeleton className="h-6 w-20 rounded-full" />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Skeleton className="h-10 w-36" />
              <Skeleton className="h-10 w-32" />
            </div>
          </div>
          <Skeleton className="h-8 w-8" />
        </div>

        <Separator className="bg-grey-200" />

        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[54px]" />
              <Skeleton className="h-6 w-6" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="ml-auto h-6 w-[54px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[100px]" />
              <Skeleton className="h-6 w-[84px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[60px]" />
              <Skeleton className="h-6 w-[110px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[110px]" />
              <Skeleton className="h-6 w-[60px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[96px]" />
              <Skeleton className="h-6 w-[120px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[134px]" />
              <Skeleton className="h-6 w-[140px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[78px]" />
              <Skeleton className="h-6 w-[100px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[80px]" />
              <Skeleton className="h-6 w-[54px]" />
            </div>
          </div>

          <Separator className="bg-grey-200" />

          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[54px]" />
              <Skeleton className="h-6 w-6" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="ml-auto h-6 w-[54px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[110px]" />
              <Skeleton className="h-6 w-[140px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[96px]" />
              <Skeleton className="h-6 w-[140px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[134px]" />
              <Skeleton className="h-6 w-[110px]" />
            </div>
          </div>

          <Separator className="bg-grey-200" />

          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[54px]" />
              <Skeleton className="h-6 w-6" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="ml-auto h-6 w-[54px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[140px]" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-2 flex h-fit w-full flex-col items-start rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <div className="mx-2 -mt-[32px] flex w-full items-center gap-10">
          <Skeleton className="h-5 w-10" />
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-5 w-24" />
        </div>

        <div className="mt-10 flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-4">
            <Skeleton className="mx-4 h-7 w-16" />
            <div className="flex w-full items-center justify-between gap-4 px-4">
              <div className="flex items-center gap-4">
                <Skeleton className="h-10 w-[150px]" />
                <Skeleton className="h-10 w-[300px]" />
              </div>
              <Skeleton className="h-10 w-[120px]" />
            </div>
          </div>

          <div className="flex w-full flex-col gap-4 py-4">
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <Skeleton className="mx-5 h-5 w-auto" />
            <Separator className="bg-gray-200" />
            <div className="flex w-full items-center justify-between gap-4 px-4">
              <div className="flex items-center gap-2">
                <Skeleton className="h-5 w-24" />
                <Skeleton className="h-8 w-16" />
                <Skeleton className="h-5 w-16" />
              </div>
              <div className="flex items-center gap-4">
                <Skeleton className="h-8 w-8" />
                <div className="flex items-center gap-1">
                  <Skeleton className="h-8 w-8" />
                  <Skeleton className="h-8 w-8" />
                  <Skeleton className="h-8 w-8" />
                </div>
                <Skeleton className="h-8 w-8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
