import { Label, Pie, PieChart } from 'recharts';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  ChartLegend,
  cn,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { PieChartCardSkeleton } from './PieChartCardSkeleton';
import type React from 'react';
import type { IDonutChartData, TChartConfig } from '@purple/ui';

type TPieChartCardProperties = {
  cardTitle: string;
  totalValue?: number;
  onDownload: () => void;
  chartConfig: TChartConfig;
  chartData: IDonutChartData[];
  chartLegendClassName?: string;
  isLoading?: boolean;
  className?: string;
};

export const PieChartCard: React.FC<TPieChartCardProperties> = ({
  chartConfig,
  chartData,
  cardTitle,
  totalValue = 0,
  onDownload,
  chartLegendClassName,
  isLoading = false,
  className,
}) => {
  if (isLoading) {
    return <PieChartCardSkeleton />;
  }

  return (
    <Card className={cn('gap-6 px-4 py-6 shadow-custom-medium', className)}>
      <div className="flex items-center justify-between gap-2">
        <CardTitle className="text-base font-semibold">{cardTitle}</CardTitle>
        <Button variant="tertiary_icon_only" size="icon_32" onClick={onDownload}>
          <PurpleIcon name="download" />
        </Button>
      </div>
      <CardContent className="flex items-center gap-8">
        <ReChartContainer config={chartConfig} className="aspect-square h-auto w-full shrink-0 basis-[170px]">
          <PieChart>
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Pie outerRadius={170 / 2} innerRadius={125 / 2} data={chartData} dataKey="value" nameKey="name">
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                        <tspan x={viewBox.cx} y={viewBox.cy} className="text-3xl font-bold">
                          {totalValue}
                        </tspan>
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Pie>
          </PieChart>
        </ReChartContainer>
        {chartData && <ChartLegend data={chartData} className={cn('gap-4', chartLegendClassName)} />}
      </CardContent>
    </Card>
  );
};
