import { useMemo } from 'react';
import { ECO_SYSTEM_EVENT_TYPES, type IUserEvent, type IUserEventsPaginatedResponse } from '@purple/shared-types';
import { TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useModal } from '~/hooks';
import { listViewColumns } from './listViewColumns';
import type { TUsePaginateReturn } from '@purple/hooks';

type TEcosystemListViewProperties = {
  data?: IUserEventsPaginatedResponse<IUserEvent[]>;
  isEventsLoading?: boolean;
  paginating: TUsePaginateReturn;
  onChangeSelectedEventId?: (id: string | null) => void;
};

export const EcosystemListView = ({
  data,
  isEventsLoading,
  paginating,
  onChangeSelectedEventId,
}: TEcosystemListViewProperties) => {
  const { openModal: openActionModal } = useModal('action-event-modal');
  const { openModal: openRemindersModal } = useModal('reminder-event-modal');
  const { openModal: openGroupActionModal } = useModal('group-action-modal');
  const { openModal: openCommunityActivityModal } = useModal('community-activity-modal');

  const isDataNotExist = (!data || data.results.length === 0) && !isEventsLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / Number(paginating.limit)) : 1;
  }, [data?.count, paginating.limit]);

  const rowClickHandler = (rowData: IUserEvent) => {
    onChangeSelectedEventId?.(rowData.related_object_id);
    switch (rowData.type) {
      case ECO_SYSTEM_EVENT_TYPES.ACTION: {
        openActionModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.GROUP_ACTION: {
        openGroupActionModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.REMINDER: {
        openRemindersModal();
        break;
      }
      case ECO_SYSTEM_EVENT_TYPES.COMMUNITY_ACTIVITY: {
        openCommunityActivityModal();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div>
      <DataTable
        columns={listViewColumns}
        data={data?.results || []}
        isLoading={isEventsLoading}
        emptyStateTitle="No Events found, for current period of time"
        emptyStateMessage="There are no Events to display, for current period of time. If it an error, please contact support."
        skeleton={<DataTableSkeleton rows={paginating.limit} />}
        onRowClick={rowClickHandler}
      />
      {!isDataNotExist && (
        <TableFooterSection
          currentPage={paginating.page}
          pageCount={pageCount}
          onPageChange={paginating.onPageChange}
          rowsPerPage={paginating.limit}
          onRowsPerPageChange={paginating.onLimitChange}
          totalRows={data?.count || 0}
        />
      )}
    </div>
  );
};
