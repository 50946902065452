import { useCallback } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  OfflineController,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux/useTakeAction';
import type React from 'react';
import type { TTakeActionType } from '@purple/shared-types';

type TActionContainerModalProperties = React.PropsWithChildren<{
  /**
   * The title of the modal.
   */
  title: string;
  /**
   * Yhe Id of the form that will be submitted.
   */
  formId: string;
  /**
   * The text of the submit button.
   */
  submitButtonLabel?: string;
  /**
   * The type of the action that will be taken.
   */
  actionType: TTakeActionType;
  /**
   * The loading state of the form.
   * @default false
   */
  isLoading?: boolean;
  /**
   * The function that will be called when the modal is closed.
   */
  onClose?: () => void;
}>;

export const ActionContainerModal: React.FC<TActionContainerModalProperties> = (props) => {
  const { title, formId, actionType, submitButtonLabel = 'Create', isLoading = false, onClose, children } = props;

  const { actionTypeModalOpen, toggleActionModal } = useTakeAction();
  const { openModal } = useModal(ModalType.TAKE_ACTION);

  const modalOpenChangeHandler = useCallback(
    (open: boolean) => {
      toggleActionModal(open ? actionType : null);
      if (!open) {
        onClose?.();
      }
    },
    [toggleActionModal, actionType, onClose],
  );

  const backToActionsClickHandler = useCallback(() => {
    toggleActionModal(null);
    openModal();
    onClose?.();
  }, [toggleActionModal, openModal, onClose]);

  const cancelClickHandler = useCallback(() => {
    toggleActionModal(null);
    onClose?.();
  }, [toggleActionModal, onClose]);

  return (
    <Dialog open={actionTypeModalOpen === actionType} onOpenChange={modalOpenChangeHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle className="leading-7 tracking-normal">{title}</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <DialogDescription asChild>
          <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            <div className="w-full p-6 pr-[30px]">{children}</div>
          </ScrollArea>
        </DialogDescription>
        <Separator />
        <DialogFooter className="flex w-full shrink-0 flex-row items-center justify-between">
          <Button type="button" variant="secondary" onClick={backToActionsClickHandler}>
            Back to Actions
          </Button>
          <div className="flex items-center justify-center gap-4">
            <Button type="button" variant="tertiary" onClick={cancelClickHandler}>
              Cancel
            </Button>
            <OfflineController>
              <Button type="submit" form={formId} isLoading={isLoading}>
                {submitButtonLabel}
              </Button>
            </OfflineController>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
