import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getPriorityListById } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useGetPriorityListById = (id: string | null) => {
  const { isError, error, data, isLoading, isSuccess } = useQuery({
    queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_BY_ID, id],
    queryFn: () => getPriorityListById(id as string),
    enabled: !!id,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch priority list', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, error, isSuccess, data };
};
