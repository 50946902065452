import React from 'react';
import { STUDENT_CALENDAR_PERIOD } from '@purple/shared-types';
import { useGetStudentCalendarEvents } from '~/queries/students';
import { MonthStudentCalendar, StudentCalendarSwitcher, YearStudentCalendar } from './components';
import useStudentCalendar from './hooks/useStudentCalendar';

type StudentDetailsCalendarProperties = {
  studentId: string;
};

const StudentDetailsCalendar: React.FC<StudentDetailsCalendarProperties> = ({ studentId }) => {
  const {
    selectedDate,
    selectedYear,
    selectedMonth,
    onSelectedDateChange,
    selectedEventType,
    onEventTypeChange,
    selectedPeriod,
    onPeriodChange,
  } = useStudentCalendar();

  const { data } = useGetStudentCalendarEvents({
    studentId,
    month: selectedMonth,
    year: selectedYear,
    itemType: selectedEventType,
    period: selectedPeriod,
  });

  const calendarSwitcher = React.useMemo(
    () => (
      <StudentCalendarSwitcher
        eventType={selectedEventType}
        onEventTypeChange={onEventTypeChange}
        period={selectedPeriod}
        onPeriodChange={onPeriodChange}
      />
    ),
    [selectedEventType, selectedPeriod, onEventTypeChange, onPeriodChange],
  );

  const handleNavigate = (date: Date) => {
    onSelectedDateChange(date);
  };

  return (
    <div className="w-full">
      {selectedPeriod === STUDENT_CALENDAR_PERIOD.MONTH && (
        <MonthStudentCalendar
          calendarSwitcher={calendarSwitcher}
          currentDate={selectedDate}
          onNavigate={handleNavigate}
          events={data || []}
        />
      )}
      {selectedPeriod === STUDENT_CALENDAR_PERIOD.YEAR && (
        <YearStudentCalendar
          calendarSwitcher={calendarSwitcher}
          events={data || []}
          // TODO: uncomment when will continue working on this component
          // currentDate={selectedDate}
          // onNavigate={handleNavigate}
          eventType={selectedEventType}
        />
      )}
    </div>
  );
};

export { StudentDetailsCalendar };
