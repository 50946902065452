import { useMemo, useRef, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_SCHOOL_FILE_EXTENSIONS, MAX_SCHOOL_FILE_SIZE } from '@purple/shared-utils';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
import { useCurrentUser } from '~/hooks/redux';
// helpers
import { showErrorToast } from '~/shared/lib';
// types
import type React from 'react';
import type { TFileListItem } from '@purple/shared-types';

type TFileActionProperties = {
  note: TFileListItem;
  onSelect?: (fileId: string) => void;
  onReplace?: (file: File | null) => void;
};

export const FileAction: React.FC<TFileActionProperties> = (props) => {
  const { note, onSelect, onReplace } = props;

  const inputReference = useRef<HTMLInputElement>(null);

  const { user } = useCurrentUser();

  const { openModal: openDeleteModal } = useModal(ModalType.DELETE_SCHOOL_FILE);
  const { openModal: openEditModal } = useModal(ModalType.EDIT_SCHOOL_FILE);
  const { openModal: openUploadModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isFileOwner = useMemo(() => note.created_by?.id === user.id, [user.id, note.created_by?.id]);

  const fileUploadHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [file] = files ?? [];
    if (!file) return;

    if (file.size > MAX_SCHOOL_FILE_SIZE) {
      showErrorToast('System message', `File size exceeds the limit of ${MAX_SCHOOL_FILE_SIZE / 1024 / 1024}MB`);
      inputReference.current!.value = '';
      return;
    }

    onSelect?.(note.id);
    onReplace?.(file);
    openUploadModal();
    inputReference.current!.value = '';
  };

  const downloadFileHandler = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(note.file);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', note.title);
      document.body.append(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsDropdownOpen(false);
    } catch {
      showErrorToast('System message', 'There was an error downloading the file');
    } finally {
      setIsDownloading(false);
    }
  };

  const editNoteHandler = () => {
    onSelect?.(note.id);
    openEditModal();
  };

  const deleteNoteHandler = () => {
    onSelect?.(note.id);
    openDeleteModal();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          variant="tertiary"
          size="icon_32"
          className="max-w-8 active:bg-grey-100"
          iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[150px] gap-1" align="end">
        <Button
          variant="link"
          onClick={downloadFileHandler}
          type="button"
          className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={
            isDownloading ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />
          }
        >
          Download
        </Button>
        {isFileOwner && (
          <>
            <Button
              variant="link"
              onClick={editNoteHandler}
              className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
              iconLeft={<PurpleIcon name="pencil" />}
            >
              Edit
            </Button>
            <Button
              variant="link"
              onClick={fileUploadHandler}
              className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
              iconLeft={<PurpleIcon name="upload" />}
            >
              Replace File
            </Button>
            <Button
              variant="link"
              className="w-full justify-start text-error-main hover:bg-error-bg hover:text-error-main active:bg-grey-200"
              onClick={deleteNoteHandler}
              iconLeft={<PurpleIcon name="trash" />}
            >
              Delete
            </Button>
          </>
        )}
      </DropdownContent>
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_SCHOOL_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
      />
    </DropdownRoot>
  );
};
