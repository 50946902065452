import * as z from 'zod';
import { FormErrorMessages } from '@purple/shared-utils';

export const updateAccountDetailsSchema = z.object({
  title: z.string().optional(),
  email: z
    .string({
      invalid_type_error: FormErrorMessages.EMAIL.INVALID_TYPE,
    })
    .trim()
    .optional()
    .refine((value) => value === '' || z.string().email().safeParse(value).success, {
      message: FormErrorMessages.EMAIL.INVALID_EMAIL,
    }),
  phone_number: z.string().optional(),
});
