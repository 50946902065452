import React from 'react';

import { useGetDistrictsCheck } from '~/queries';
import { useGetUserPermissions } from '~/queries/permissions';
import { useUser } from './useUser';

export const useAppInit = () => {
  const { isLoading: isDistrictsCheckLoading } = useGetDistrictsCheck();
  const { isLoading: isUserInfoLoading, isSuccess: isUserInfoSuccess } = useUser();
  const { isLoading: isPermissionsLoading, data: userPermissions } = useGetUserPermissions({
    enabled: isUserInfoSuccess,
  });

  const isLoading = React.useMemo(
    () => isUserInfoLoading || isDistrictsCheckLoading || isPermissionsLoading,
    [isUserInfoLoading, isDistrictsCheckLoading, isPermissionsLoading],
  );

  return { isLoading, userPermissions };
};
