import { combineReducers } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../white-list';
import { createActivityReducer } from './create-activity';
import { districtReducer } from './district';
import { modalsReducer } from './modals';
import { routeHistoryReducer } from './router-history';
import { studentAssistanceReducer } from './student-assistance';
import { studentIncidentReducer } from './studentIncident';
import { takeActionReducer } from './take-action';
// reducers
import { userReducer } from './user';
import { userPermissionsReducer } from './userPermissions';

export const appReducer = combineReducers({
  [AppReduxReducer.USER]: userReducer,
  [AppReduxReducer.MODAL]: modalsReducer,
  [AppReduxReducer.DISTRICT]: districtReducer,
  [AppReduxReducer.USER_PERMISSIONS]: userPermissionsReducer,
  [AppReduxReducer.STUDENT_ASSISTANCE]: studentAssistanceReducer,
  [AppReduxReducer.STUDENT_INCIDENT]: studentIncidentReducer,
  [AppReduxReducer.TAKE_ACTION]: takeActionReducer,
  [AppReduxReducer.CREATE_ACTIVITY]: createActivityReducer,
  [AppReduxReducer.ROUTE_HISTORY]: routeHistoryReducer,
});
