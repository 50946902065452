import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, Text } from '@purple/ui';
import { SCHOOL_YEARS } from '~/modules/Visualizer/SchoolData/constants';
import { useExportAttendanceFile } from '~/queries/students/attendance';

type TAttendanceHeaderControlsProperties = {
  selectedYear: string;
  onYearChange: (year: string) => void;
};

export const AttendanceHeaderControls = ({ selectedYear, onYearChange }: TAttendanceHeaderControlsProperties) => {
  const { studentId } = useParams();
  const [fetchExportAttendanceFile, setFetchExportAttendanceFile] = useState(false);
  const { isLoading } = useExportAttendanceFile({
    enabled: fetchExportAttendanceFile,
    selectedYear,
    studentId: studentId as string,
    onSuccess: () => setFetchExportAttendanceFile(false),
  });
  const yearSelectChangeHandler = useCallback(
    (year: string) => {
      onYearChange?.(year);
    },
    [onYearChange],
  );

  return (
    <div className="flex flex-col gap-4 px-4 pt-6">
      <Text variant="size-16" type="body-600">
        Attendance
      </Text>
      <div className="flex items-center justify-between gap-2">
        <ComboBox modal>
          <ComboBoxTrigger
            placeholder="Select year"
            selectedLabel={`Year: ${selectedYear}`}
            className="max-w-[180px]"
          />
          <ComboBoxContent align="end" searchPlaceholder="Search" emptyContent="Year not found." className="w-auto">
            {SCHOOL_YEARS.map(({ label, value }) => (
              <ComboBoxItem
                key={value}
                value={value}
                selected={value === selectedYear}
                onSelect={yearSelectChangeHandler}
              >
                {label}
              </ComboBoxItem>
            ))}
          </ComboBoxContent>
        </ComboBox>
        <Button
          iconLeft={<PurpleIcon name="download" />}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => setFetchExportAttendanceFile(true)}
        >
          Export
        </Button>
      </div>
    </div>
  );
};
