import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentIncidentDetail } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertIncidentDetailResponseToDataTable } from '../converter';

export const useGetStudentIncidentDetail = (incidentId: string) => {
  const { isError, error, data, isLoading, isSuccess } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_INCIDENT_DETAIL, incidentId],
    queryFn: () => getStudentIncidentDetail(incidentId),
    enabled: !!incidentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student incident detail', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, error, isSuccess, data: data ? convertIncidentDetailResponseToDataTable(data) : data };
};
