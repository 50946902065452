import { useCallback } from 'react';
import { addDays, addMonths, addWeeks, endOfWeek, format, startOfWeek, subDays, subMonths, subWeeks } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { Button, ButtonGroupItem, RadioGroup, Text } from '@purple/ui';
import type { View } from 'react-big-calendar';
import type { TEcoSystemDataViewValues } from '../constants';

type THeaderControlsProperties = {
  dataView: TEcoSystemDataViewValues;
  currentDate: Date;
  calendarView: View;
  onChangeDataView: (dataView: TEcoSystemDataViewValues) => void;
  onChangeCalendarView: (calendarView: View) => void;
  onChangeCurrentDate: (date: Date) => void;
  onLimitChange: (limit: number) => void;
};

export const HeaderControls = ({
  dataView,
  calendarView,
  currentDate,
  onChangeDataView,
  onChangeCurrentDate,
  onChangeCalendarView,
  onLimitChange,
}: THeaderControlsProperties) => {
  const changeDataViewHandler = useCallback(
    (value: TEcoSystemDataViewValues) => {
      onLimitChange(value === 'list' ? 10 : 500);
      onChangeDataView(value);
      switch (value) {
        case 'list': {
          onChangeCalendarView('day');
          break;
        }
        case 'day': {
          onChangeCalendarView('day');
          break;
        }
        case 'week': {
          onChangeCalendarView('week');
          break;
        }
        case 'month': {
          onChangeCalendarView('month');
          break;
        }
        default: {
          onChangeCalendarView('day');
          break;
        }
      }
    },
    [onChangeDataView, onChangeCalendarView, onLimitChange],
  );

  const renderFormattedDateBasedOnCalendarView = useCallback((_view: View, date: Date) => {
    switch (_view) {
      case 'week': {
        return `${format(startOfWeek(date), 'MMMM dd')} - ${format(endOfWeek(date), 'MMMM dd, yyyy')}`;
      }
      case 'month': {
        return format(date, 'MMMM yyyy');
      }
      default: {
        return format(date, 'MMMM dd, yyyy');
      }
    }
  }, []);

  const todayClickHandler = useCallback(() => {
    onChangeCurrentDate(new Date());
  }, [onChangeCurrentDate]);

  const increaseDateHandler = useCallback(() => {
    switch (calendarView) {
      case 'week': {
        onChangeCurrentDate(addWeeks(currentDate, 1));
        break;
      }
      case 'month': {
        onChangeCurrentDate(addMonths(currentDate, 1));
        break;
      }
      default: {
        onChangeCurrentDate(addDays(currentDate, 1));
        break;
      }
    }
  }, [calendarView, currentDate, onChangeCurrentDate]);

  const decreaseDateHandler = useCallback(() => {
    switch (calendarView) {
      case 'week': {
        onChangeCurrentDate(subWeeks(currentDate, 1));
        break;
      }
      case 'month': {
        onChangeCurrentDate(subMonths(currentDate, 1));
        break;
      }
      default: {
        onChangeCurrentDate(subDays(currentDate, 1));
        break;
      }
    }
  }, [calendarView, currentDate, onChangeCurrentDate]);

  return (
    <div className="flex items-center justify-between px-4">
      <div className="flex items-center gap-4">
        <Button variant="secondary" onClick={todayClickHandler}>
          Today
        </Button>
        <div className="flex items-center gap-2">
          <Button variant="tertiary_icon_only" size="icon_32" onClick={decreaseDateHandler}>
            <PurpleIcon name="chevron-left" />
          </Button>
          <Text variant="size-14" type="body-600" className="text-brand-blue-700">
            {renderFormattedDateBasedOnCalendarView(calendarView, currentDate)}
          </Text>
          <Button variant="tertiary_icon_only" size="icon_32" onClick={increaseDateHandler}>
            <PurpleIcon name="chevron-right" />
          </Button>
        </div>
      </div>
      <RadioGroup
        defaultValue="list"
        value={dataView}
        onValueChange={(value) => changeDataViewHandler(value as TEcoSystemDataViewValues)}
        className="flex items-center gap-0"
      >
        <ButtonGroupItem value="list" checked={dataView === 'list'}>
          List
        </ButtonGroupItem>
        <ButtonGroupItem value="day" checked={dataView === 'day'}>
          Day
        </ButtonGroupItem>
        <ButtonGroupItem value="week" checked={dataView === 'week'}>
          Week
        </ButtonGroupItem>
        <ButtonGroupItem value="month" checked={dataView === 'month'}>
          Month
        </ButtonGroupItem>
      </RadioGroup>
    </div>
  );
};
