import { ScrollArea } from '@purple/ui';
import { GroupActionManageMembers } from '~/components/GroupActionManageMembers';

type TManageMembersProperties = {
  groupActionId: string | null;
  closeMainModal: () => void;
};

export const ManageMembers = ({ groupActionId, closeMainModal }: TManageMembersProperties) => {
  return (
    <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
      <div className="p-6">
        <GroupActionManageMembers groupActionId={groupActionId} onModalClose={closeMainModal} />
      </div>
    </ScrollArea>
  );
};
