import { useEffect, useRef, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_ACTIVITY_FILE_EXTENSIONS, getFileInfo, MAX_ACTIVITY_FILES_SIZE } from '@purple/shared-utils';
import { Button, Text } from '@purple/ui';
import { showErrorToast } from '~/shared/lib';
import { UploadedFileCard } from './UploadedFileCard';
import type React from 'react';

const MAX_FILE_SIZE_MB = MAX_ACTIVITY_FILES_SIZE / 1024 / 1024;

type TUploadFilesFieldProperties = {
  onFileUpload: (files: File[] | null) => void;
  onError?: (error: string) => void;
};

const UploadFilesField = ({ onFileUpload, onError }: TUploadFilesFieldProperties) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onFileUpload(uploadedFiles);
  }, [uploadedFiles, onFileUpload]);

  const fileUploadHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const checkFileRequirements = (file: File) => {
    const { extension, size } = getFileInfo(file);

    const allowedFileExtensions = ALLOWED_ACTIVITY_FILE_EXTENSIONS.split(',').map((currentExtension) =>
      currentExtension.trim(),
    );

    const fileSizeCheck = size <= MAX_ACTIVITY_FILES_SIZE;
    const fileExtensionCheck = allowedFileExtensions.includes(`.${extension}`);

    if (!fileSizeCheck || !fileExtensionCheck) {
      return false;
    }

    return true;
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files?.length) return;

    const fileArray = [...files];

    if (fileArray.some((file) => !checkFileRequirements(file))) {
      const errorMessage = `File size exceeds the limit of ${MAX_ACTIVITY_FILES_SIZE / 1024 / 1024}MB`;
      showErrorToast('System message', errorMessage);
      inputReference.current!.value = '';
      onError?.(errorMessage);
      return;
    }

    setUploadedFiles((oldState) => {
      if (!oldState) {
        return fileArray;
      }

      return [...oldState, ...fileArray];
    });
    inputReference.current!.value = '';
  };

  const removeUploadedFileHandler = (file: File) => {
    setUploadedFiles((files) => {
      const filteredFiles = files?.filter((currentFile) => currentFile !== file);

      return filteredFiles?.length ? filteredFiles : null;
    });
  };

  return (
    <div className="col-span-2 flex flex-col gap-2">
      {Boolean(uploadedFiles?.length) && (
        <div className="flex w-full flex-col gap-1">
          {uploadedFiles?.map((file) => (
            <UploadedFileCard key={file.name} file={file} onRemove={() => removeUploadedFileHandler(file)} />
          ))}
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div>
          <Button variant="secondary" type="button" onClick={fileUploadHandler} iconLeft={<PurpleIcon name="upload" />}>
            Upload File
          </Button>
        </div>
        <div className="flex items-center gap-1">
          <PurpleIcon name="information-circle" className="h-3 w-3 text-grey-600" />
          <Text variant="size-12" type="body-500" className="text-grey-600">
            The upload file should not be more than
            {' '}
            {MAX_FILE_SIZE_MB}
            MB for
            {' '}
            {ALLOWED_ACTIVITY_FILE_EXTENSIONS}
            {' '}
            files
          </Text>
        </div>
      </div>
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_ACTIVITY_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
        multiple
      />
    </div>
  );
};

export { UploadFilesField };
