import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
import { useDeleteActivity, useDeleteRecurringActivity } from '~/queries';
// helpers
import { AppRoutes } from '~/constants/routes/routes';
import { showErrorToast } from '~/shared/lib';
// types
import type React from 'react';
import type { TActivityInfo } from '@purple/shared-types';

type TDeleteActivityDialogProperties = {
  activity: TActivityInfo;
  isRecurring?: boolean;
};

export const DeleteActivityDialog: React.FC<TDeleteActivityDialogProperties> = (props) => {
  const { activity, isRecurring = false } = props;

  const navigate = useNavigate();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.DELETE_ACTIVITY);
  const { mutate: deleteActivity, isPending: isDeletePending } = useDeleteActivity();
  const { mutate: deleteRecurringActivity, isPending: isRecurringDeletePending } = useDeleteRecurringActivity();

  const isPending = useMemo(
    () => isDeletePending || isRecurringDeletePending,
    [isDeletePending, isRecurringDeletePending],
  );

  const deleteActivitySeriesHandler = () => {
    if (!activity.details.recurring_group) {
      showErrorToast('System message', 'Activity does not belong to a recurring group');
      return;
    }
    deleteRecurringActivity(activity.details.recurring_group.id, {
      onSuccess: () => {
        closeModal();
        navigate(AppRoutes.App.Community.Root.path);
      },
    });
  };

  const deleteActivityHandler = () => {
    deleteActivity(activity.id, {
      onSuccess: () => {
        closeModal();
        navigate(AppRoutes.App.Community.Root.path);
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-full max-w-[480px]">
        <DialogHeader className="flex-row items-center justify-between p-6 pb-0">
          <DialogTitle className='className="leading-7 tracking-normal'>
            {isRecurring
              ? 'Are you sure you want to delete this activity series?'
              : 'Are you sure you want to delete this activity?'}
          </DialogTitle>
          <DialogDescription className="sr-only">
            {isRecurring
              ? 'Confirm if you want to delete activity series'
              : `Confirm if you want to delete activity with title: ${activity.name}`}
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Text variant="size-14" type="body-400" className="px-6 pb-1 pt-3 text-grey-950">
          {isRecurring
            ? 'Deleting the recurring activity will delete all activities in the series and cannot be undone.'
            : 'Deleting the activity will delete all related files and cannot be undone.'}
        </Text>
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            disabled={isPending}
            isLoading={isPending}
            onClick={isRecurring ? deleteActivitySeriesHandler : deleteActivityHandler}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
