import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const safUnidentifiable = async ({ safId }: { safId: string }) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(SAF_ENDPOINTS.UNIDENTIFIABLE, safId);
  const response = await apiInstance.patch<void>(URL);
  return response.data;
};
