import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';

type THistoryRoute = {
  path: string;
  search: string;
};

type TRouteHistoryState = {
  /**
   * Stack of route history.
   */
  history: THistoryRoute[];
};

const initialState: TRouteHistoryState = {
  history: [],
};

export const routeHistorySlice = createSlice({
  name: AppReduxReducer.ROUTE_HISTORY,
  initialState,
  reducers: {
    addRoute: (state, action: PayloadAction<THistoryRoute>) => {
      const lastRoute = state.history.at(-1);
      if (lastRoute?.path !== action.payload.path) {
        state.history = [...state.history, action.payload];
        return;
      }
      if (lastRoute?.path === action.payload.path && lastRoute?.search !== action.payload.search) {
        state.history = [...state.history.slice(0, -1), action.payload];
      }
    },
  },
});

export const { addRoute } = routeHistorySlice.actions;
export const routeHistoryReducer = routeHistorySlice.reducer;
