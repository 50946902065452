import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActivityDetailsUpdateBody, TActivityDetailsUpdateResponse } from '@purple/shared-types';

export const updateRecurringActivityDetails = async ({ id, ...body }: TActivityDetailsUpdateBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.put<TActivityDetailsUpdateResponse>(
    replaceUrlParameters(ACTIVITY_ENDPOINTS.RECURRING_BY_ID, id),
    body,
  );
  return response.data;
};
