import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postCreateActivity } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useCreateNotRecurringActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ACTIVITY_QUERY_KEYS.CREATE_ACTIVITY],
    mutationFn: postCreateActivity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIVITY_QUERY_KEYS.GET_LIST] });
      showSuccessToast('System Message', 'Activity was successfully created');
    },
    onError: () => {
      showErrorToast('Failed to fetch action choices', 'Please try again later');
    },
  });
};
