// hooks
import { useQueryParameter } from '@purple/hooks';
// components
import { EmailDetails } from './EmailDetails';
import { EmailList } from './EmailList';
// helpers
import { SafEmailQueryName, SafEmailViewType } from './constants';
// types
import type React from 'react';
import type { ValueOf } from '@purple/shared-types';

export const Emails: React.FC = () => {
  const { query: viewQuery } = useQueryParameter<ValueOf<typeof SafEmailViewType>>({
    queryName: SafEmailQueryName.VIEW,
    defaultValue: SafEmailViewType.LIST,
  });
  const { query: correspondenceId } = useQueryParameter({
    queryName: SafEmailQueryName.CORRESPONDENCE,
  });

  if (viewQuery === SafEmailViewType.DETAILS && correspondenceId !== undefined) {
    return <EmailDetails />;
  }

  return <EmailList />;
};
