import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadFile } from '@purple/shared-services';
import { FILE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TFile, TFileCreatePayload } from '@purple/shared-types';

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  return useMutation<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileCreatePayload>({
    mutationKey: [FILE_QUERY_KEYS.UPDATE_FILE],
    mutationFn: uploadFile,
    onSuccess: () => {
      showSuccessToast('System Message', 'File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
    },
    onError: (error) => {
      if (error?.response?.data.file) {
        showErrorToast('System Message', error.response.data.file.join(', '));
        return;
      }
      showErrorToast('System Error', 'Failed to upload file');
    },
  });
};
