import { getAxiosInstance } from '../../../api';
import { ACTIONS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type {
  TStudentActionListItem,
  TStudentActionsPaginatedResponse,
  TStudentActionsRequestParameters,
} from '@purple/shared-types';

export const getStudentActionsList = async ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentActionsRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(ACTIONS.GET_STUDENT_ACTIONS, studentId);

  const response = await axiosInstance.get<TStudentActionsPaginatedResponse<TStudentActionListItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
