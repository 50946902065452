import { useCallback, useMemo } from 'react';
import { ReminderType, type TReminderDetails } from '@purple/shared-types';
import { formatDateToLocalTime, formatMinutesToDuration } from '@purple/shared-utils';
import { Button, Text } from '@purple/ui';
import { useAppSelector, useModal } from '~/hooks';
import { userSelector } from '~/store/features/user';

type TReminderContentProperties = {
  reminderDetails?: TReminderDetails;
  onEditChange: (isEditMode: boolean) => void;
};

export const ReminderContent = ({ reminderDetails, onEditChange }: TReminderContentProperties) => {
  const { id } = useAppSelector(userSelector);
  const {
    subject,
    created_by,
    days_of_week,
    description,
    date_and_time,
    notify_before_minutes,
    duration,
  } = reminderDetails || {};

  const { openModal } = useModal('delete-reminder-event-modal');
  const { openModal: openDeleteRecurring } = useModal('delete-recurring-reminder-event-modal');

  const isEventOwner = useMemo(() => {
    return reminderDetails?.created_by?.id === id;
  }, [reminderDetails?.created_by?.id, id]);

  const isFutureEvent = useMemo(() => {
    if (!date_and_time) return false;
    return new Date(date_and_time) > new Date();
  }, [date_and_time]);

  const openDeleteReminderModal = useCallback(() => {
    if (reminderDetails?.type === ReminderType.ONE_TIME) return openModal();
    openDeleteRecurring();
  }, [openDeleteRecurring, openModal, reminderDetails?.type]);

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center justify-between gap-2">
        <Text variant="size-16" type="body-600">
          {subject}
        </Text>
        {isEventOwner && (
          <div className="flex items-center gap-4">
            {isFutureEvent && (
              <Button variant="secondary" className="px-4 py-2.5" onClick={() => onEditChange(true)}>
                Edit Reminder
              </Button>
            )}
            <Button variant="secondary" className="px-4 py-2.5" onClick={openDeleteReminderModal}>
              Delete Reminder
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-3">
        <Text variant="size-16" type="body-600">
          Description
        </Text>
        <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
          <Text variant="size-14" type="body-500">
            Created by:
          </Text>
          <Text variant="size-14">{created_by?.full_name ?? '—'}</Text>
          <Text variant="size-14" type="body-500">
            Type:
          </Text>
          <Text variant="size-14">{days_of_week && days_of_week?.length > 0 ? 'Recurring' : 'One-Time'}</Text>
          <Text variant="size-14" type="body-500">
            Description Text:
          </Text>
          <Text variant="size-14">{description ?? '—'}</Text>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Text variant="size-16" type="body-600">
          Original Date & Time
        </Text>
        <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
          <Text variant="size-14" type="body-500">
            Start Date:
          </Text>
          <Text variant="size-14">{formatDateToLocalTime(date_and_time)}</Text>

          {days_of_week && (
            <>
              <Text variant="size-14" type="body-500">
                Days:
              </Text>
              <Text variant="size-14" className="capitalize">
                {days_of_week.length === 7 ? 'All' : days_of_week.join(', ')}
              </Text>
            </>
          )}
          {notify_before_minutes && (
            <>
              <Text variant="size-14" type="body-500">
                Notify Before:
              </Text>
              <Text variant="size-14">{`${notify_before_minutes} Minutes`}</Text>
            </>
          )}
          {duration && (
            <>
              <Text variant="size-14" type="body-500">
                Duration:
              </Text>
              <Text variant="size-14">{formatMinutesToDuration(duration)}</Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
