import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getPriorityLists } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IPriorityListRequest } from '@purple/shared-types';

export const useGetPriorityLists = (requestParameters: IPriorityListRequest) => {
  const { isError, ...rest } = useQuery({
    queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LISTS, requestParameters],
    queryFn: () => getPriorityLists(requestParameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch priority lists', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isError, ...rest };
};
