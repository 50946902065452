import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import type { TUserSchoolAccess } from '@purple/shared-types';

type TSchoolAccessTabProperties = {
  data: TUserSchoolAccess;
};

const SchoolAccessTab = ({ data }: TSchoolAccessTabProperties) => {
  const { schools } = data;

  const schoolsLength = schools?.length || 0;

  if (!schools || schoolsLength === 0) {
    return (
      <div className="flex flex-col gap-4">
        <InfoItem label="School">
          <InfoItemValue>-</InfoItemValue>
        </InfoItem>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {schools.map((school, index) => {
        const { id, name } = school;

        const schoolUrl = AppRoutes.App.School.Root.makePath(id, 'overview');
        const schoolIndex = index + 1;
        const schoolLabel = schoolsLength > 1 ? `School #${schoolIndex}` : 'School';

        return (
          <InfoItem key={id} label={schoolLabel}>
            <InfoItemValue to={schoolUrl} target="_blank">
              {name}
            </InfoItemValue>
          </InfoItem>
        );
      })}
    </div>
  );
};

export { SchoolAccessTab };
