import { toast } from '@purple/ui';
import type React from 'react';

export const showSuccessToast = (title: string, message: React.ReactNode) => {
  toast({
    variant: 'success',
    title,
    description: message,
    toastType: 'success',
  });
};

export const showErrorToast = (title: string, message: React.ReactNode) => {
  toast({
    variant: 'error',
    title,
    description: message,
    toastType: 'error',
  });
};
