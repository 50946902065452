import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionDetails } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useActionDetails = (actionId: string) => {
  const { isError, ...props } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_DETAILS, actionId],
    queryFn: () => getActionDetails(actionId),
    enabled: !!actionId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch action details', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
