import { useCallback, useMemo } from 'react';
import { StudentAssistanceScreen } from '~/constants/saf';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import {
  studentAssistanceFormSelector,
  studentAssistanceGradesSelector,
  studentAssistanceReturnSelector,
  studentAssistanceScreenSelector,
  studentAssistanceSlices,
  studentAssistanceSubmitterSelector,
} from '~/store/features/student-assistance';
import type { TStudentAssistanceState } from '~/store/features/student-assistance';

type TUseStudentAssistanceReturn = TStudentAssistanceState & {
  /**
   * Whether the current screen is the submitter screen.
   */
  isSubmitterScreen: boolean;
  /**
   * Whether the current screen is the form screen.
   */
  isFormScreen: boolean;
  /**
   * Whether the current screen is the success screen.
   */
  isSuccessScreen: boolean;
  /**
   * Set the current screen of the student assistance form.
   * @param screen - type of the screen to set
   */
  setSafCurrentScreen: (screen: TStudentAssistanceState['currentScreen']) => void;
  /**
   * Set the type of the submitter.
   * @param type - type of the submitter
   */
  setSafSubmitterType: (type: TStudentAssistanceState['submitterType']) => void;
  /**
   * Set the list of grades that are available for selected school.
   * @param grades - list of grades
   */
  setSchoolGrades: (grades: TStudentAssistanceState['schoolGrades']) => void;
  /**
   * Set the type which indicates the redirection after the form is submitted.
   * @param type - type of the redirection
   */
  setSafReturnType: (type: TStudentAssistanceState['returnType']) => void;
  /**
   * Set the last submitted form data that is used for redirection.
   * @param form - last submitted form data
   */
  setLastSubmittedForm: (form: TStudentAssistanceState['lastSubmittedForm']) => void;
};

export const useStudentAssistance = (): TUseStudentAssistanceReturn => {
  const dispatch = useAppDispatch();
  const currentScreen = useAppSelector(studentAssistanceScreenSelector);
  const submitterType = useAppSelector(studentAssistanceSubmitterSelector);
  const schoolGrades = useAppSelector(studentAssistanceGradesSelector);
  const returnType = useAppSelector(studentAssistanceReturnSelector);
  const lastSubmittedForm = useAppSelector(studentAssistanceFormSelector);
  const { setSafCurrentScreen, setSafSubmitterType, setSchoolGrades, setSafReturnType, setLastSubmittedForm }
    = studentAssistanceSlices.actions;

  const isSubmitterScreen = useMemo(() => currentScreen === StudentAssistanceScreen.SUBMITTER, [currentScreen]);
  const isFormScreen = useMemo(() => currentScreen === StudentAssistanceScreen.FORM, [currentScreen]);
  const isSuccessScreen = useMemo(() => currentScreen === StudentAssistanceScreen.SUCCESS, [currentScreen]);

  return {
    currentScreen,
    isSubmitterScreen,
    isFormScreen,
    isSuccessScreen,
    submitterType,
    schoolGrades,
    returnType,
    lastSubmittedForm,
    setSafCurrentScreen: useCallback(
      (screen) => dispatch(setSafCurrentScreen(screen)),
      [dispatch, setSafCurrentScreen],
    ),
    setSafSubmitterType: useCallback((type) => dispatch(setSafSubmitterType(type)), [dispatch, setSafSubmitterType]),
    setSchoolGrades: useCallback((grades) => dispatch(setSchoolGrades(grades)), [dispatch, setSchoolGrades]),
    setSafReturnType: useCallback((type) => dispatch(setSafReturnType(type)), [dispatch, setSafReturnType]),
    setLastSubmittedForm: useCallback((form) => dispatch(setLastSubmittedForm(form)), [dispatch, setLastSubmittedForm]),
  };
};
