import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionGroups } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActionGroupsRequestParameters } from '@purple/shared-types';

export const useActionGroups = (parameters: TActionGroupsRequestParameters) => {
  const { limit = 100, offset = 0, search = '', record_action_type } = parameters;

  const { isError, ...props } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_GROUPS_CHOICES, limit, offset, search, record_action_type],
    queryFn: () => getActionGroups({ limit, offset, search, record_action_type }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch list of groups', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
