import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSupportByProgramExportFile } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentSupportByProgramQueryParameters } from '@purple/shared-types';

export const useExportSupportByProgramFile = ({
  studentId,
  queryParams,
  enabled,
  onSuccess,
}: {
  studentId: string;
  queryParams: TStudentSupportByProgramQueryParameters;
  enabled: boolean;
  onSuccess?: () => void;
}) => {
  const { isError, data, isSuccess, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.EXPORT_SUPPORT_BY_PROGRAM, { studentId, queryParams }],
    queryFn: () => getSupportByProgramExportFile({ studentId, queryParams }),
    enabled: !!studentId && enabled,
  });

  useEffect(() => {
    if (isSuccess && enabled && data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `support_by_program_for_${studentId}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      // Clean up the URL to prevent memory leaks
      window.URL.revokeObjectURL(url);

      onSuccess?.();
    }
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to export the table');
    }
  }, [isError]);

  return { isError, ...rest };
};
