import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useArabicLanguage = () => {
  const { i18n } = useTranslation();

  const isArabic = useMemo(() => i18n.language === 'ar', [i18n.language]);

  return {
    isArabic,
  };
};
