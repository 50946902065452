import { useEffect } from 'react';
import { useAppDispatch } from '~/hooks';
import { routeHistorySlice } from '~/store/features/router-history';
import type React from 'react';

/**
 * RouteHistoryProvider component.
 *
 * This component is responsible for tracking the history of routes in the application.
 * It listens for changes in the browser's history and dispatches an action to add the current route to the route history.
 */
export const RouteHistoryProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { addRoute } = routeHistorySlice.actions;

  useEffect(() => {
    const trackHistory = () => {
      const currentRoute = window.location.pathname;
      dispatch(addRoute({ path: currentRoute, search: window.location.search }));
    };

    // Track initial route on load
    trackHistory();

    // Handle popstate events (e.g., back/forward navigation)
    window.addEventListener('popstate', trackHistory);

    // Capture pushState and replaceState calls
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = (...arguments_) => {
      originalPushState.apply(window.history, arguments_);
      trackHistory();
    };

    window.history.replaceState = (...arguments_) => {
      originalReplaceState.apply(window.history, arguments_);
      trackHistory();
    };

    return () => {
      window.removeEventListener('popstate', trackHistory);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [dispatch, addRoute]);

  return children;
};
