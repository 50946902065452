import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCrisisActivities } from '@purple/shared-services';
import { ACTIVITY_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TCrisisActivityListQueryParameters } from '@purple/shared-types';

export const useCrisisActivities = (parameters: TCrisisActivityListQueryParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [ACTIVITY_QUERY_KEYS.GET_CRISIS_LIST, parameters],
    queryFn: () => getCrisisActivities(parameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Unable to fetch crisis activities');
    }
  }, [isError]);

  return { isError, ...rest };
};
