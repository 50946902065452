import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionDetailsResponse } from '@purple/shared-types';

export const getActionDetails = async (actionId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActionDetailsResponse>(
    replaceUrlParameters(ACTIONS.ACTION_DETAILS, actionId),
  );
  return response.data;
};
