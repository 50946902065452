import { useParams } from 'react-router-dom';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { SupportByRoleContent } from '~/modules/Students/StudentProfile/SupportByRole';

export const SupportByRole = () => {
  const { studentId } = useParams();
  return (
    <div className="flex w-full flex-col gap-6">
      <LinkButton
        to={AppRoutes.App.Students.StudentDetails.Root.makePath(studentId as string, 'dashboard')}
        iconName="chevron-left"
        className="font-semibold"
      >
        Back to Student Profile
      </LinkButton>
      <SupportByRoleContent />
    </div>
  );
};
