import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import type {
  TSafListManagerDto,
  TSafListManagerRequestParameters,
  TSafListManagerResponse,
} from '@purple/shared-types';

export const getSafListManager = async (queryParameters: TSafListManagerRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafListManagerResponse<TSafListManagerDto[]>>(
    SAF_ENDPOINTS.GET_SAF_LIST_MANAGER,
    {
      params: queryParameters,
    },
  );
  return response.data;
};
