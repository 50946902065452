import { useMemo } from 'react';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Button, Text } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
import { ListItemOptions } from '../ListItemOptions';
import type { TPriorityListResponseItem } from '@purple/shared-types';

const MAX_STUDENTS_TO_SHOW = 5;

type TListCardProperties = {
  data: TPriorityListResponseItem;
  onRemove: () => void;
  onShare: () => void;
  onDetailClick: () => void;
};

const ListCard = ({ data, onRemove, onShare, onDetailClick }: TListCardProperties) => {
  const { name, reason, description, total_students, id, students, is_owner } = data;

  const { openModal: openTakeActionModal } = useModal(ModalType.TAKE_ACTION);
  const { setSelectedStudentId, setSelectedPriorityListId } = useTakeAction();

  const studentsToShow = useMemo(() => data.students.slice(0, MAX_STUDENTS_TO_SHOW), [data]);

  const isStudentsExists = useMemo(() => data.students.length > 0, [data]);

  const detailClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    onDetailClick();
  };

  const takeActionClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    if (students.length > 0) {
      if (students.length > 1) {
        setSelectedPriorityListId(id);
      } else {
        const studentId = students[0]?.id;
        if (studentId) {
          setSelectedStudentId(studentId);
        }
      }
      openTakeActionModal();
    }
  };

  return (
    <div
      className="flex w-full cursor-pointer flex-col rounded-lg border border-grey-200 p-4"
      onClick={detailClickHandler}
    >
      <div className="flex h-full flex-col justify-between gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between gap-1">
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-600" className="text-left text-grey-title">
                Priority List Name
              </Text>
              <Text variant="size-14" type="body-400" className="line-clamp-1 text-left text-grey-950">
                {name}
              </Text>
            </div>
            <ListItemOptions
              onRemove={onRemove}
              onShare={onShare}
              onDetailClick={detailClickHandler}
              isOwner={is_owner}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-600" className="text-left text-grey-title">
                Type
              </Text>
              <Text variant="size-14" type="body-400" className="text-left text-grey-950">
                {reason}
              </Text>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-600" className="text-left text-grey-title">
                Description
              </Text>
              <Text variant="size-14" type="body-400" className="line-clamp-2 text-left text-grey-950">
                {description || '-'}
              </Text>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-600" className="text-left text-grey-title">
                Students
              </Text>
              <Text variant="size-14" type="body-400" className="text-left text-grey-950">
                {total_students || '-'}
              </Text>
              <div className="flex items-center justify-between">
                <div className="flex">
                  {studentsToShow.map((student) => {
                    const { id: studentId, photo, first_name, last_name } = student;
                    const fullName = `${first_name} ${last_name}`;

                    return (
                      <Avatar key={studentId} size={32} className="-ml-2 border-2 border-white first:-ml-0">
                        <AvatarImage src={photo ?? undefined} alt={fullName ?? ''} />
                        <AvatarFallback className="bg-grey-200">
                          {getInitialsFromName(fullName ?? '- -')}
                        </AvatarFallback>
                      </Avatar>
                    );
                  })}
                </div>
                {isStudentsExists && (
                  <Button onClick={detailClickHandler} variant="link" size="link">
                    View All
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {isStudentsExists && (
          <Guard
            requiredPermissions={[
              APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
              APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
            ]}
          >
            <Button onClick={takeActionClickHandler}>Take Action</Button>
          </Guard>
        )}
      </div>
    </div>
  );
};

export { ListCard };
