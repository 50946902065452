import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafHistoryRequestParameters, TSafHistoryResponse } from '@purple/shared-types';

export const getSafHistory = async (safId: string, parameters: TSafHistoryRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafHistoryResponse>(replaceUrlParameters(SAF_ENDPOINTS.HISTORY, safId), {
    params: parameters,
  });
  return response.data;
};
