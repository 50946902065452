import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import { isAuthenticatedSelector, isLoadingSelector, userSelector, userSlice } from '~/store/features/user';
import type { TUserState } from '~/store/features/user';

type TUseCurrentUserReturn = TUserState & {
  /**
   * Set the user authentication status.
   * @param isAuthenticated - whether the user is authenticated or not
   */
  setUserAuthenticated: (isAuthenticated: boolean) => void;
  /**
   * Set the user loading status.
   * @param isLoading - whether the user is loading or not
   */
  setUserLoading: (isLoading: boolean) => void;
  /**
   * Set the user.
   * @param user - the user to set
   */
  setUser: (user: TUserState['user']) => void;
};

export const useCurrentUser = (): TUseCurrentUserReturn => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isLoading = useAppSelector(isLoadingSelector);
  const user = useAppSelector(userSelector);
  const { setAuthenticated, setIsLoading, setUser } = userSlice.actions;

  return {
    isAuthenticated,
    isLoading,
    user,
    setUserAuthenticated: useCallback(
      (authenticated: boolean) => dispatch(setAuthenticated(authenticated)),
      [dispatch, setAuthenticated],
    ),
    setUserLoading: useCallback((loading) => dispatch(setIsLoading(loading)), [dispatch, setIsLoading]),
    setUser: useCallback((updatedUser) => dispatch(setUser(updatedUser)), [dispatch, setUser]),
  };
};
