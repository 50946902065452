/* eslint-disable react/no-nested-components */
import { DayPicker } from 'react-day-picker';
import { CustomCaption } from './CustomCaption';
import { CustomDay } from './CustomDay';
import type { DayProps } from 'react-day-picker';
import type { IStudentMonthEvent, TStudentCalendarItemType } from '@purple/shared-types';

type CalendarDayDotsProperties = {
  eventType: TStudentCalendarItemType;
  events: IStudentMonthEvent[];
};

// TODO: not finished
const CalendarDayDots = ({ eventType, events }: CalendarDayDotsProperties) => {
  return (
    <DayPicker
      showOutsideDays
      components={{
        Day: (props: DayProps) => <CustomDay {...props} eventType={eventType} events={events} />,
        MonthCaption: CustomCaption,
        Nav: () => <>{null}</>,
      }}
      className="CalendarDayDots w-fit"
    />
  );
};

export { CalendarDayDots };
