import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TGroupActionRecurrenceBody } from '@purple/shared-types';

export const createActionGroupRecurrence = async ({ id, ...body }: TGroupActionRecurrenceBody) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.post<never>(
    replaceUrlParameters(ACTIONS.CREATE_ACTION_GROUP_RECURRENCE, id),
    body,
  );
  return response.data;
};
