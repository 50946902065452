import { formatDate } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { IStudentGrade } from '@purple/shared-types';

export const studentGradesColumns: ColumnDef<IStudentGrade>[] = [
  {
    accessorKey: 'grade_level',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        GRADE LEVEL
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.grade_level}
      </Text>
    ),
  },
  {
    accessorKey: 'subject',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        SUBJECT
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.subject}
      </Text>
    ),
  },
  {
    accessorKey: 'course_name',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        COURSE NAME
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.course_name}
      </Text>
    ),
  },
  {
    accessorKey: 'grade',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-center text-grey-600">
        GRADE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-center text-grey-950">
        {row.original.grade}
      </Text>
    ),
  },
  {
    accessorKey: 'cycle',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-center text-grey-600">
        CYCLE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-center text-grey-950">
        {row.original.cycle}
      </Text>
    ),
  },
  {
    accessorKey: 'last_sync_date',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        LAST SYNC DATE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {formatDate(row.original.last_sync_date)}
      </Text>
    ),
  },
];
