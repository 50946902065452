import { useMutation } from '@tanstack/react-query';
import { createPriorityList } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TPriorityList, TPriorityListBody, TPriorityListMutationError } from '@purple/shared-types';

export const useCreatePriorityList = () => {
  return useMutation<TPriorityList, AxiosError<TPriorityListMutationError>, TPriorityListBody>({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.CREATE_PRIORITY_LIST],
    mutationFn: createPriorityList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LISTS] });
      showSuccessToast('System message', 'The priority list was created successfully');
    },
    onError: (error) => {
      const name = error?.response?.data?.name || null;
      const errorMessage = name
        ? name.join(', ')
        : 'Could not create a priority list. Check the provided information and try again';

      showErrorToast('System message', errorMessage);
    },
  });
};
