export const LINKED_STUDENTS_CHART_TYPE_OPTIONS = [
  {
    label: 'Ethnicity',
    value: 'ethnicity',
  },
  {
    label: 'Grade Level',
    value: 'grade_level',
  },
  {
    label: 'Home Language Spoken',
    value: 'home_language_spoken',
  },
] as const;
