import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ACTIONS_EXPORT_VIEWS } from '@purple/shared-types';
import {
  AppFilters,
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  Text,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { useExportActionsFile } from '~/queries/actions/useExportActionsFile';
import { useSupportByRoleFilterOptions } from '../useSupportByRoleFilterOptions';

type TSupportByRoleHeaderSectionProperties = {
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

export const SupportByRoleHeaderSection = ({
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}: TSupportByRoleHeaderSectionProperties) => {
  const [searchParameters] = useSearchParams();
  const { studentId } = useParams();
  const [fetchExportActionsFile, setFetchExportActionsFile] = useState(false);

  const { filterOptions } = useSupportByRoleFilterOptions();

  const { search, onClearSearch, onSearchChange } = useSearch();
  const { isLoading } = useExportActionsFile({
    fileName: 'support_by_role',
    enabled: fetchExportActionsFile,
    studentId: studentId as string,
    queryParams: {
      view: ACTIONS_EXPORT_VIEWS.STUDENT_ACTIONS_SUPPORT_BY_ROLE,
      search,
      record_action_type: searchParameters.get('record_action_type') || undefined,
      time_period: timePeriod,
    },
    onSuccess: () => setFetchExportActionsFile(false),
  });
  return (
    <div className="flex flex-col gap-4 px-4 pt-6">
      <Text variant="size-16" type="body-600">
        Support by Role
      </Text>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <AppFilters config={filterOptions} />
          <RadixSelect onValueChange={onPeriodChange} defaultValue={timePeriod} value={timePeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(timePeriod)}
              onClearCallback={onPeriodClear}
              triggerContainerClassName="w-[200px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search"
            className="max-w-[300px]"
          />
        </div>
        <Button
          iconLeft={<PurpleIcon name="download" />}
          onClick={() => setFetchExportActionsFile(true)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Export
        </Button>
      </div>
    </div>
  );
};
