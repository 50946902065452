import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import {
  AvatarUploader,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useChangePhoto } from '~/queries';
import type { TUserChangePhotoRequestData } from '@purple/shared-types';

// 20MB
const MAX_PHOTO_SIZE_BYTES = 1024 * 1024 * 20;

const ChangePhotoModal = () => {
  const { isOpen, toggleModal, closeModal } = useModal(ModalType.PROFILE_CHANGE_PHOTO_MODAL);

  const { mutate, isPending } = useChangePhoto();

  const form = useForm<TUserChangePhotoRequestData>({
    mode: 'onChange',
    defaultValues: {
      avatar: undefined,
    },
  });

  const formAvatar = form.watch('avatar');

  const submitHandler = (data: TUserChangePhotoRequestData) => {
    mutate(
      {
        requestPayload: data,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  const photoChangeHandler = (newFiles: File[] | null) => {
    if (!newFiles || newFiles.length === 0) {
      form.setValue('avatar', null);
    } else {
      const newFile = newFiles[0];
      if (newFile) {
        form.setValue('avatar', newFile);
      }
    }
  };

  const canSubmit = useMemo(() => !isPending && Boolean(formAvatar), [isPending, formAvatar]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Change Photo</DialogTitle>
          <DialogDescription className="sr-only">Change profile photo</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitHandler)}
        >
          <FormField
            control={form.control}
            name="avatar"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AvatarUploader
                    value={field.value ? [field.value] : null}
                    onValueChange={photoChangeHandler}
                    maxSize={MAX_PHOTO_SIZE_BYTES}
                    height={216}
                    disabled={isPending}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(submitHandler)} disabled={!canSubmit} isLoading={isPending}>
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ChangePhotoModal };
