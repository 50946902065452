import { getAxiosInstance } from '../../api';
import { USER_ENDPOINTS } from '../../constants/private-api';
import type {
  IPaginatedResponse,
  IUser,
  IUserEvent,
  IUserEventsPaginatedResponse,
  IUserEventsRequestParameters,
  TUserChangeEmail,
  TUserChangeEmailRequestData,
  TUserChangePasswordRequestData,
  TUserChangePhotoRequestData,
  TUserFile,
  TUserFilesListRequestParameters,
  TUserProfileDTO,
  TUserProfileUpdateDTO,
} from '@purple/shared-types';

export type TUpdateUserPasswordRequestData = {
  old_password: string;
  new_password1: string;
  new_password2: string;
};

export const currentUser = async () => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IUser>(USER_ENDPOINTS.CURRENT);
  return response.data;
};

export const partialUserUpdate = async (data: Partial<IUser>) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<IUser>(USER_ENDPOINTS.CURRENT, data);
  return response.data;
};

export const getUsersEvents = async ({ queryParams }: { queryParams: IUserEventsRequestParameters }) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IUserEventsPaginatedResponse<IUserEvent[]>>(USER_ENDPOINTS.USER_EVENTS, {
    params: queryParams,
  });
  return response.data;
};

export const currentUserProfile = async () => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TUserProfileDTO>(USER_ENDPOINTS.CURRENT_PROFILE);
  return response.data;
};

// BE has this limitation for latitude and longitude
const MAX_LATITUDE_AND_LONGITUDE_DECIMAL_PLACES = 6;

export const updateCurrentProfile = async ({ requestPayload }: { requestPayload: TUserProfileUpdateDTO }) => {
  const axiosInstance = getAxiosInstance();

  if (requestPayload.address?.latitude || requestPayload.address?.longitude) {
    requestPayload.address = {
      ...requestPayload.address,
      latitude: +requestPayload.address.latitude.toFixed(MAX_LATITUDE_AND_LONGITUDE_DECIMAL_PLACES),
      longitude: +requestPayload.address.longitude.toFixed(MAX_LATITUDE_AND_LONGITUDE_DECIMAL_PLACES),
    };
  }

  const URL_PATH = USER_ENDPOINTS.UPDATE_CURRENT_PROFILE;

  const response = await axiosInstance.patch<TUserProfileDTO>(URL_PATH, requestPayload);
  return response.data;
};

export const updateCurrentProfileEmail = async ({
  requestPayload,
}: {
  requestPayload: TUserChangeEmailRequestData;
}) => {
  const axiosInstance = getAxiosInstance();

  const URL_PATH = USER_ENDPOINTS.UPDATE_CURRENT_PROFILE_EMAIL;

  const response = await axiosInstance.post<TUserChangeEmail>(URL_PATH, requestPayload);
  return response.data;
};

export const currentUserProfileFiles = async (request: TUserFilesListRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IPaginatedResponse<TUserFile[]>>(USER_ENDPOINTS.CURRENT_PROFILE_FILES, {
    params: request,
  });
  return response.data;
};

export const updateProfilePassword = async ({ requestPayload }: { requestPayload: TUserChangePasswordRequestData }) => {
  const axiosInstance = getAxiosInstance();

  const URL_PATH = USER_ENDPOINTS.UPDATE_CURRENT_PROFILE_PASSWORD;

  const response = await axiosInstance.post<void>(URL_PATH, requestPayload);
  return response.data;
};

export const updateProfileAvatar = async ({ requestPayload }: { requestPayload: TUserChangePhotoRequestData }) => {
  const { avatar } = requestPayload;

  const axiosInstance = getAxiosInstance();

  const formData = new FormData();
  if (avatar instanceof File) formData.append('avatar', avatar);

  const response = await axiosInstance.patch<void>(USER_ENDPOINTS.UPDATE_CURRENT_PROFILE_AVATAR, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
