import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSaflDetailsUpdateBody } from '@purple/shared-types';

export const patchSafDetails = async ({
  safId,
  requestBody,
}: {
  safId: string;
  requestBody: TSaflDetailsUpdateBody;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<TSaflDetailsUpdateBody>(
    replaceUrlParameters(SAF_ENDPOINTS.UPDATE_SAF_DETAILS, safId),
    requestBody,
  );
  return response.data;
};
