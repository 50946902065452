import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const safClose = async ({
  safId,
  service_area_subcategories,
}: {
  safId: string;
  service_area_subcategories: string[];
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<{ id: string; time_until_closed_days: number }>(
    replaceUrlParameters(SAF_ENDPOINTS.CLOSE_SAF, safId),
    {
      service_area_subcategories,
    },
  );
  return response.data;
};
