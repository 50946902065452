import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionPurpleUserParticipantsRequest, TActionPurpleUserParticipantsResponse } from '@purple/shared-types';

export const getActionPurpleUserParticipants = async (
  actionId: string,
  parameters?: TActionPurpleUserParticipantsRequest,
) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActionPurpleUserParticipantsResponse>(
    replaceUrlParameters(ACTIONS.ACTION_PURPLE_USER_PARTICIPANTS, actionId),
    { params: parameters },
  );
  return response.data;
};
