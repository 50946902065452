import { getAxiosInstance } from '../../api';
import { RICH_TEXT_IMAGES_ENDPOINTS } from '../../constants/private-api';
import type { TRichTextImagePayload } from '@purple/shared-types';

export const uploadRichTextImage = async (requestData: TRichTextImagePayload) => {
  const axiosInstance = getAxiosInstance();
  const formData = new FormData();
  for (const [key, value] of Object.entries(requestData)) {
    formData.append(key, value as string);
  }

  const response = await axiosInstance.post<{ id: number; image: string }>(RICH_TEXT_IMAGES_ENDPOINTS.ROOT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
