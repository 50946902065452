import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCloseSafOptions } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useCloseSafOptions = (enabled = true) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SAF_QUERY_KEYS.GET_CLOSE_SAF_CATEGORIES],
    queryFn: getCloseSafOptions,
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Unable to retrieve close saf options');
    }
  }, [isError]);

  return { isError, ...rest };
};
