import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentGradesList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IStudentGradesListRequest } from '@purple/shared-types';

export const useGetStudentGradesList = (requestParameters: IStudentGradesListRequest) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_GRADES_LIST, requestParameters],
    queryFn: () => getStudentGradesList(requestParameters),
    enabled: !!requestParameters.studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student grades', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isError, ...rest };
};
