import { SafSubmitterType } from '@purple/shared-types';
import type { ValueOf } from '@purple/shared-types';
import type { TStepItem } from '@purple/ui';

export const SAF_PHONE_MASK = '+1 (___) ___-____';

export const StudentAssistanceScreen = {
  SUBMITTER: 'SUBMITTER',
  FORM: 'FORM',
  SUCCESS: 'SUCCESS',
} as const;
export type TStudentAssistanceScreen = ValueOf<typeof StudentAssistanceScreen>;

export const SafReturnType = {
  CURRENT_STUDENT: 'CURRENT_STUDENT',
  NEW_STUDENT: 'NEW_STUDENT',
} as const;
export type TSafReturnType = ValueOf<typeof SafReturnType>;

export const SAF_CARDS = [
  {
    icon: 'academic-cap',
    i18nKey: 'student',
    type: SafSubmitterType.STUDENT,
  },
  {
    icon: 'identification',
    i18nKey: 'teacher',
    type: SafSubmitterType.TEACHER,
  },
  {
    icon: 'Family',
    i18nKey: 'parent',
    type: SafSubmitterType.PARENT,
  },
  {
    icon: 'users',
    i18nKey: 'provider',
    type: SafSubmitterType.PROVIDER,
  },
] as const;

export const SAF_LANGUAGES = [
  {
    label: 'English (US)',
    value: 'en',
  },
  {
    label: 'عربى',
    value: 'ar',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: '中国人',
    value: 'zh',
  },
] as const;

export const SafStepType = {
  INFO: 'info',
  STUDENT: 'student',
  OBSERVATION: 'observation',
} as const;

export const SAF_STEPS = [
  { id: SafStepType.INFO },
  { id: SafStepType.STUDENT },
  { id: SafStepType.OBSERVATION },
] satisfies TStepItem[];
