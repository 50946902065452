import { useState } from 'react';
import { cn, MultiSelect, MultiSelectItem } from '@purple/ui';
import { ChoiceTag } from '~/components';
import { useActionChoices, useUpdateActionTags } from '~/queries';
import { ActionSectionHeader } from './ActionSectionHeader';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TActionTagsSectionProperties = {
  action: TActionDetails;
  hideTitle?: boolean;
  triggerClassName?: string;
};

export const ActionTagsSection: React.FC<TActionTagsSectionProperties> = (props) => {
  const { action, hideTitle = false, triggerClassName } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(action.tags.map((tag) => tag.name));

  const { mutate: updateTags, isPending } = useUpdateActionTags(action.id);

  const { tagsOptions } = useActionChoices({
    record_action_type: action.record_action_type,
    students: action.details.student?.id,
    enabled: isEditing,
  });

  const applyTagClickHandler = () => {
    setIsEditing(true);
  };

  const closeClickHandler = () => {
    setIsEditing(false);
  };

  const saveTagsClickHandler = () => {
    const newTags = tagsOptions
      .filter((tag) => selectedTags.includes(tag.value) && !action.tags.some((item) => item.name === tag.value))
      .map((tag) => ({ color: tag.color, name: tag.value }));
    if (newTags.length === 0) {
      setIsEditing(false);
      return;
    }

    updateTags(
      {
        tags: newTags,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const tagsChangeHandler = (tags: string[]) => {
    setSelectedTags(tags);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <ActionSectionHeader
        title={hideTitle ? null : 'Tags'}
        editButtonLabel="Apply Tag"
        editButtonIcon="plus"
        editing={isEditing}
        loading={isPending}
        onCancel={closeClickHandler}
        onEdit={applyTagClickHandler}
        onSave={saveTagsClickHandler}
      />
      {isEditing
        ? (
            <MultiSelect
              selectedOptions={tagsOptions.filter((tag) => selectedTags.includes(tag.value))}
              blockedOptions={action.tags.map((tag) => tag.name)}
              placeholder="Select tags"
              modalPopover
              disabled={isPending}
              onOptionChange={tagsChangeHandler}
              classNames={{
                trigger: cn('max-w-[360px]', triggerClassName),
              }}
            >
              {tagsOptions.map((option) => (
                <MultiSelectItem
                  key={option.value}
                  value={option.value}
                  option={option}
                  disabled={action.tags.some((tag) => tag.name === option.value)}
                  isSelected={selectedTags.includes(option.value)}
                />
              ))}
            </MultiSelect>
          )
        : (
            <ul className="flex flex-row flex-wrap items-center gap-2">
              {action.tags.map(({ name, color }) => (
                <li key={name}>
                  <ChoiceTag color={color}>{name}</ChoiceTag>
                </li>
              ))}
            </ul>
          )}
    </div>
  );
};
