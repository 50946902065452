import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { passwordResetSchema } from '@purple/shared-utils';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  PasswordInput,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useResetPasswordConfirmMutation } from '~/queries';
import type * as z from 'zod';

const NewPassword = () => {
  const navigate = useNavigate();

  const { userId, token } = useParams();

  const userUID = userId || '';
  const resetToken = token || '';

  const { isPending, mutate } = useResetPasswordConfirmMutation();

  const form = useForm<z.infer<typeof passwordResetSchema>>({
    resolver: zodResolver(passwordResetSchema),
    mode: 'onChange',
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
  });

  const handleFormSubmit = (data: z.infer<typeof passwordResetSchema>) => {
    mutate({ ...data, uid: userUID, token: resetToken });
  };

  const handleBackToLogin = () => {
    navigate(AppRoutes.Auth.SignIn.Root.path);
  };

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <div className="absolute left-[16px] top-[24px]">
        <Button onClick={handleBackToLogin} variant="link" size="link" iconLeft={<PurpleIcon name="chevron-left" />}>
          Back to Login
        </Button>
      </div>
      <div className="flex flex-col items-center gap-8">
        <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-950">
          New Password
        </Heading>
      </div>
      <Form providerProps={form} className="flex w-full flex-col gap-4" onSubmit={form.handleSubmit(handleFormSubmit)}>
        <FormField
          control={form.control}
          name="new_password1"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>New Password</FormLabel>
              <FormControl>
                <PasswordInput
                  placeholder="Enter new password"
                  {...field}
                  type="password"
                  isError={!!form.formState.errors.new_password1}
                  withRules
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="new_password2"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Confirm New Password</FormLabel>
              <FormControl>
                <PasswordInput
                  placeholder="Enter new password"
                  {...field}
                  type="password"
                  isError={!!form.formState.errors.new_password2}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={!form.formState.isValid || isPending} isLoading={isPending}>
          Save & Continue
        </Button>
      </Form>
    </div>
  );
};

export default NewPassword;
