import { useId, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { AddRecurrenceForm } from '~/components/AddRecurrenceForm';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupActionAddRecurrenceDialogProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionAddRecurrenceDialog: React.FC<TGroupActionAddRecurrenceDialogProperties> = ({
  groupAction,
}) => {
  const formId = useId();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.GROUP_ACTION_ADD_RECURRENCE);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <DialogTitle>Add Recurrence</DialogTitle>
          <DialogDescription className="sr-only">
            This dialog allows you to add a recurrence to the group action.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="p-6">
            <AddRecurrenceForm
              groupAction={groupAction}
              formId={formId}
              onSuccess={closeModal}
              onLoadingChange={setIsLoading}
            />
          </div>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} variant="primary" isLoading={isLoading} disabled={isLoading}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
