import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateGroupActionTags } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionTagUpdateBody, TActionTagUpdateResponse } from '@purple/shared-types';

export const useUpdateGroupActionTags = (groupActionId: string) => {
  const queryClient = useQueryClient();

  return useMutation<TActionTagUpdateResponse, AxiosError, TActionTagUpdateBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_GROUP_ACTION_TAGS],
    mutationFn: (body) => updateGroupActionTags(groupActionId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_GROUP_DETAILS, groupActionId] });
      showSuccessToast('System message', 'Group action tags were updated successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not update group action tags. Check the provided information and try again',
      );
    },
  });
};
