import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch, useSort } from '@purple/hooks';
import { cutGradeName, snakeCaseToCapitalized } from '@purple/shared-utils';
import {
  AppFilters,
  AppSelectedFiltersList,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { useGetStudentGradesFilterOptions } from '~/queries';
import { SORT_OPTIONS } from './constants';
import type { TFiltersConfig } from '@purple/ui';

const FilterActionsRow = () => {
  const { studentId } = useParams();

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const { data: filtersData, isLoading: isFiltersDataLoading } = useGetStudentGradesFilterOptions(studentId as string);

  const config = useMemo(() => {
    const filterConfig: TFiltersConfig = {
      categories: [
        ...(filtersData && filtersData?.grade_level.length > 0
          ? [
              {
                label: 'Grade Level',
                value: 'grade_level',
                filters:
                  filtersData?.grade_level.map((level) => ({
                    label: cutGradeName(snakeCaseToCapitalized(level)),
                    value: level,
                  })) || [],
              },
            ]
          : []),
        ...(filtersData && filtersData?.grade.length > 0
          ? [
              {
                label: 'Grades',
                value: 'grades',
                filters:
                  filtersData?.grade.map((grade) => ({
                    label: cutGradeName(snakeCaseToCapitalized(grade)),
                    value: grade,
                  })) || [],
              },
            ]
          : []),
        ...(filtersData && filtersData?.subject.length > 0
          ? [
              {
                label: 'Subjects',
                value: 'subjects',
                filters:
                  filtersData?.subject.map((subject) => ({
                    label: snakeCaseToCapitalized(subject),
                    value: subject,
                  })) || [],
              },
            ]
          : []),
        ...(filtersData && filtersData?.course_name.length > 0
          ? [
              {
                label: 'Course Name',
                value: 'course_name',
                filters:
                  filtersData?.course_name.map((name) => ({
                    label: snakeCaseToCapitalized(name),
                    value: name,
                  })) || [],
              },
            ]
          : []),
        ...(filtersData && filtersData?.cycle.length > 0
          ? [
              {
                label: 'Cycle',
                value: 'Cycle',
                filters:
                  filtersData?.cycle.map((cycle) => ({
                    label: snakeCaseToCapitalized(cycle),
                    value: cycle,
                  })) || [],
              },
            ]
          : []),
      ],
    };
    return filterConfig;
  }, [filtersData]);

  return (
    <div className="flex flex-col gap-5 px-4">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex gap-4">
          <AppFilters config={config} loading={isFiltersDataLoading} />
          <RadixSelect onValueChange={onSortChange} value={sort}>
            <RadixSelectTrigger
              hasClearButton={Boolean(sort)}
              onClearCallback={onSortClear}
              triggerContainerClassName="w-[206px]"
              pw-id="sort-dropdown"
            >
              <RadixSelectValue placeholder="Sort" />
            </RadixSelectTrigger>
            <RadixSelectContent pw-id="sort-options">
              {SORT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </div>
        <div className="w-[300px]">
          <SearchInput
            placeholder="Search by course name"
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
        </div>
      </div>
      <AppSelectedFiltersList config={config} />
    </div>
  );
};

export { FilterActionsRow };
