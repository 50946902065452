import { PurpleIcon } from '@purple/icons';
import { Button } from '@purple/ui';

type TTabEditSectionProperties = {
  isEditMode: boolean;
  onEditChange: (isEditing: boolean) => void;
  isUpdating: boolean;
  onUpdate: () => void;
};

const TabEditSection = ({ isEditMode, onEditChange, isUpdating, onUpdate }: TTabEditSectionProperties) => {
  return (
    <>
      {isEditMode
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={() => onEditChange(false)}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                size="small"
                isLoading={isUpdating}
                disabled={isUpdating}
                onClick={onUpdate}
              >
                Save
              </Button>
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name="pencil" className="h-4 w-4 shrink-0" />}
              onClick={() => onEditChange(true)}
            >
              Edit
            </Button>
          )}
    </>
  );
};

export default TabEditSection;
