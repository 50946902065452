import { useMemo } from 'react';
import { SAF_STATUS } from '@purple/shared-types';
import { SafClosedDetails } from './SafClosedDetails';
import { SafDetails } from './SafDetails';
import { SafLinkedStudentDetails } from './SafLinkedStudentDetails';
import { SafNoNeedDetails } from './SafNoNeedDetails';
import { SafOnHoldDetails } from './SafOnHoldDetails';
import { SafStats } from './SafStats';
import { SafSubmissionDetails } from './SafSubmissionDetails';
import type { TSafDetailsDto } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

type TSafDetailsSideTabs = ({ safId, safDetailsInfo }: { safId?: string | null; safDetailsInfo?: TSafDetailsDto }) => {
  accordionTabs: TAppTab[];
  defaultOpenTab: string;
};

export const useSafDetailsSideTabs: TSafDetailsSideTabs = ({ safId, safDetailsInfo }) => {
  const defaultOpenTab = useMemo(() => {
    if (safDetailsInfo?.status === SAF_STATUS.NEW) {
      return 'saf-details';
    }
    return 'saf-submission-details';
  }, [safDetailsInfo?.status]);

  const accordionTabs = useMemo(() => {
    const baseTabs: TAppTab[] = [
      {
        label: 'SAF Submission Details',
        value: 'saf-submission-details',
        content: <SafSubmissionDetails safId={safId} />,
        permissions: [],
      },
      {
        label: 'SAF Details',
        value: 'saf-details',
        content: <SafDetails safId={safId} />,
        permissions: [],
      },
      {
        label: 'Linked Student Details',
        value: 'linked-student-details',
        content: <SafLinkedStudentDetails safId={safId} />,
        permissions: [],
      },
      {
        label: 'SAF Stats',
        value: 'saf-stats',
        content: <SafStats safId={safId} />,
        permissions: [],
      },
    ];

    const conditionalTabs: TAppTab[] = [
      ...(safDetailsInfo?.closed_saf_details
        ? [
            {
              label: 'Closed SAF Details',
              value: 'closed-saf-details',
              content: <SafClosedDetails safId={safId} />,
              permissions: [],
            },
          ]
        : []),
      ...(safDetailsInfo?.on_hold_details
        ? [
            {
              label: 'On-Hold Details',
              value: 'on-hold-details',
              content: <SafOnHoldDetails safId={safId} />,
              permissions: [],
            },
          ]
        : []),
      ...(safDetailsInfo?.no_need_details
        ? [
            {
              label: 'No Need Details',
              value: 'no-need-details',
              content: <SafNoNeedDetails safId={safId} />,
              permissions: [],
            },
          ]
        : []),
    ];

    return [...baseTabs, ...conditionalTabs];
  }, [safId, safDetailsInfo]);

  return { defaultOpenTab, accordionTabs };
};
