import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import type { TActivityListQueryParameters, TCrisisActivityListResponse } from '@purple/shared-types';

export const getCrisisActivities = async (parameters: TActivityListQueryParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TCrisisActivityListResponse>(ACTIVITY_ENDPOINTS.CRISIS_ACTIVITY_LIST, {
    params: parameters,
  });
  return response.data;
};
