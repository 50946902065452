import { useMutation } from '@tanstack/react-query';
import { updatePriorityList } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type {
  TPriorityListMutationError,
  TPriorityListUpdateBody,
  TPriorityListUpdateResponse,
} from '@purple/shared-types';

export const useEditPriorityList = () => {
  return useMutation<TPriorityListUpdateResponse, AxiosError<TPriorityListMutationError>, TPriorityListUpdateBody>({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.EDIT_PRIORITY_LIST],
    mutationFn: updatePriorityList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_BY_ID],
      });
      showSuccessToast('System message', 'The priority list was updated successfully');
    },
    onError: (error) => {
      const name = error?.response?.data?.name || null;
      const errorMessage = name
        ? name.join(', ')
        : 'Could not update a priority list. Check the provided information and try again';

      showErrorToast('System message', errorMessage);
    },
  });
};
