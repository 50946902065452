import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IStudentIncidentDetail } from '@purple/shared-types';

export const getStudentIncidentDetail = async (incidentId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<IStudentIncidentDetail>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_INCIDENT_DETAIL, incidentId),
  );

  return response.data;
};
