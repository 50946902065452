import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { SAF_PRIORITY } from '@purple/shared-types';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useSafRemoveFromHold } from '~/queries';
import { safRemoveFromHoldSchema } from './schema';
import type * as z from 'zod';

type TSafRemoveFromHoldModalProperties = {
  safId: string;
  onCloseCallBack?: () => void;
};

const SafRemoveFromHoldModal = ({ safId, onCloseCallBack }: TSafRemoveFromHoldModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal(ModalType.SAF_REMOVE_FROM_HOLD);

  const { mutate: removeFromHold, isPending } = useSafRemoveFromHold();

  const form = useForm<z.infer<typeof safRemoveFromHoldSchema>>({
    resolver: zodResolver(safRemoveFromHoldSchema),
    mode: 'onChange',
    defaultValues: {
      priority: '',
    },
  });

  const toggleModalCloseHandler = () => {
    onCloseCallBack?.();
    toggleModal(!isOpen);
  };

  const removeFromHoldHandler = (data: z.infer<typeof safRemoveFromHoldSchema>) => {
    removeFromHold(
      { safId, body: data },
      {
        onSuccess: () => {
          toggleModalCloseHandler();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(removeFromHoldHandler)}>
          <DialogHeader className="flex-row items-start justify-between">
            <div className="flex flex-col gap-2">
              <DialogTitle>Remove from Hold</DialogTitle>
              <DialogDescription className="text-grey-600">
                Moving it back from “On Hold” to “Prioritized” will keep the original date and time.
              </DialogDescription>
            </div>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full flex-col gap-4 p-6">
            <FormField
              control={form.control}
              name="priority"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Priority</FormLabel>
                  <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <RadixSelectTrigger
                        className={cn({
                          'border-error-main': !!form.formState.errors.priority,
                        })}
                      >
                        <RadixSelectValue placeholder="Select SAF priority" />
                      </RadixSelectTrigger>
                    </FormControl>
                    <RadixSelectContent>
                      {Object.values(SAF_PRIORITY).map((label) => (
                        <RadixSelectItem key={label} value={label}>
                          {capitalizeFirstLetter(label)}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { SafRemoveFromHoldModal };
