import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import { convertToFilterConfig } from '@purple/shared-utils';
import {
  AppFilters,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { useCurrentUser } from '~/hooks/redux';
import { useGetStudentsDataFilters } from '~/queries';
import { LINKED_STUDENTS_SORT_SELECT_OPTIONS } from '../../constants';
import type React from 'react';
import type { TSchoolSafFilters } from '@purple/shared-types';

export const GroupActionLinkedStudentsHeader: React.FC = () => {
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();
  const { user } = useCurrentUser();

  const { data: filtersData, isLoading: isFiltersDataLoading } = useGetStudentsDataFilters();

  const filters = useMemo(
    () =>
      filtersData
        ? Object.entries(filtersData).reduce<TSchoolSafFilters>(
          (accumulator, [key, value]) => (key === 'grade' ? { ...accumulator, [key]: value } : accumulator),
          {
            ...(user?.schools.length > 1 && {
              school: user.schools.map(({ id, name }) => ({ id, name })),
            }),
          },
        )
        : null,
    [user?.schools, filtersData],
  );

  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );
  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <div className="flex items-center gap-4">
        <AppFilters config={filterConfig} loading={isFiltersDataLoading} />
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[240px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="w-max">
            {LINKED_STUDENTS_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        onClear={onClearSearch}
        placeholder="Search by name"
        className="max-w-[300px]"
      />
    </div>
  );
};
