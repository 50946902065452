import { invertColor } from '@purple/shared-utils';
import { cn } from '@purple/ui';
import type React from 'react';

type TChoiceTagProperties = React.HTMLAttributes<HTMLSpanElement> & {
  /**
   * The color of the tag.
   * @example '#FF0000'
   */
  color?: string;
};

export const ChoiceTag: React.FC<TChoiceTagProperties> = (props) => {
  const { color, children, className, ...rest } = props;

  return (
    <span
      className={cn(
        'inline-block max-h-6 max-w-[200px] items-center truncate rounded-full bg-brand-blue-100 px-2 py-0.5 text-center text-sm font-medium text-brand-blue-700',
        className,
      )}
      style={{ ...(color && { backgroundColor: color, color: invertColor(color, true) }) }}
      {...rest}
    >
      {children}
    </span>
  );
};
