import { format } from 'date-fns';
import { Text } from '@purple/ui';
import { ReadableTakeActionType } from '~/constants/take-action';
import type { ColumnDef } from '@tanstack/react-table';
import type { TMyGroupAction } from '@purple/shared-types';

export const myGroupActionColumns: ColumnDef<TMyGroupAction>[] = [
  {
    accessorKey: 'title',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Action Name
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        <span className="block w-full truncate">
          (
          {row.original.name}
          )
          {' '}
          {row.original.title}
        </span>
      </Text>
    ),
    meta: {
      className: 'w-5/12',
    },
  },
  {
    accessorKey: 'type',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Action Type
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        <span className="block w-full truncate">{ReadableTakeActionType[row.original.record_action_type] ?? '–'}</span>
      </Text>
    ),
    meta: {
      className: 'w-3/12',
    },
  },
  {
    accessorKey: 'duration',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-center uppercase text-grey-600">
        Duration
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-center text-grey-950">
        <span className="block w-full truncate">{row.original.duration ?? '–'}</span>
      </Text>
    ),
    meta: {
      className: 'w-2/12',
    },
  },
  {
    accessorKey: 'date_and_time',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Date Created
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        <span className="block w-full truncate">{format(row.original.created_at, 'MMMM d, yyyy, h:mm a')}</span>
      </Text>
    ),
    meta: {
      className: 'w-2/12',
    },
  },
];
