import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes, format } from 'date-fns';
import { z } from 'zod';
import { usePermissions } from '@purple/permissions';
import {
  cn,
  DatePicker,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  NumberInput,
} from '@purple/ui';
import { ActionTypeToPermission } from '~/constants/take-action';
import { useCurrentUser } from '~/hooks/redux';
import { useUpdateGroupAction } from '~/queries';
import { GroupActionSectionHeader } from './GroupActionSectionHeader';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

const dateAndTimeSchema = z.object({
  date: z.date({
    message: 'Date is invalid.',
    required_error: 'Date is required.',
  }),
  duration: z
    .number({
      message: 'Duration must be an integer.',
    })
    .int()
    .min(0, {
      message: 'Duration must be a positive number or zero.',
    }),
});

type TGroupDateAndTimeSectionProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupDateAndTimeSection: React.FC<TGroupDateAndTimeSectionProperties> = (props) => {
  const { groupAction } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateGroupAction, isPending } = useUpdateGroupAction();
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () =>
      user.id === groupAction.details.created_by?.id
      && hasPermissions(ActionTypeToPermission[groupAction.record_action_type]),
    [user, groupAction, hasPermissions],
  );

  const defaultValues = useMemo(
    () => ({
      date: new Date(groupAction.date_and_time_details.date_and_time),
      duration: groupAction.date_and_time_details.duration,
    }),
    [groupAction.date_and_time_details],
  );

  const form = useForm<z.infer<typeof dateAndTimeSchema>>({
    resolver: zodResolver(dateAndTimeSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDateClickHandler = (formData: z.infer<typeof dateAndTimeSchema>) => {
    updateGroupAction(
      {
        id: groupAction.id,
        date_and_time: formData.date.toISOString(),
        duration: formData.duration,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          form.reset(formData);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      <GroupActionSectionHeader
        editing={isEditing}
        loading={isPending}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDateClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="date"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                    <FormLabel className="font-primary text-base font-normal text-grey-600">Start Date & Time</FormLabel>
                    <div
                      className={cn(
                        'flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:[&:has(button)]:max-w-full',
                      )}
                    >
                      <FormControl>
                        <DatePicker
                          mode="single"
                          placeholder="Select date & time"
                          formatterString="PPP p"
                          isError={!!fieldState.error}
                          triggerDisabled={field.disabled}
                          selected={field.value}
                          defaultMonth={field.value}
                          captionLayout="dropdown"
                          onDayClick={field.onChange}
                          onTimeChange={field.onChange}
                          triggerClassName="max-h-9"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <DescriptionItem>
                <DescriptionTerm tag="p">End Date & Time</DescriptionTerm>
                <DescriptionDetails tag="p">
                  {format(addMinutes(new Date(form.watch('date')), form.watch('duration')), 'MMMM d, yyyy, h:mm a')}
                </DescriptionDetails>
              </DescriptionItem>
              <FormField
                control={form.control}
                name="duration"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                    <FormLabel className="font-primary text-base font-normal text-grey-600">Duration</FormLabel>
                    <div
                      className={cn(
                        'flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:[&:has(button)]:max-w-full',
                      )}
                    >
                      <FormControl>
                        <NumberInput
                          {...field}
                          type="number"
                          isError={!!form.formState.errors.duration}
                          placeholder="Enter duration"
                          className="max-h-9"
                          min={0}
                          changeOnWheel
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionList>
              <DescriptionItem>
                <DescriptionTerm>Start Date & Time</DescriptionTerm>
                <DescriptionDetails>
                  {format(new Date(groupAction.date_and_time_details.date_and_time), 'MMMM d, yyyy, h:mm a')}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionTerm>End Date & Time</DescriptionTerm>
                <DescriptionDetails>
                  {format(
                    addMinutes(
                      new Date(groupAction.date_and_time_details.date_and_time),
                      groupAction.date_and_time_details.duration,
                    ),
                    'MMMM d, yyyy, h:mm a',
                  )}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionTerm>Duration</DescriptionTerm>
                <DescriptionDetails>{groupAction.date_and_time_details.duration ?? 0}</DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
