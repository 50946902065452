import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { AppRoutes } from '~/constants/routes/routes';
import { useGoBack, useModal } from '~/hooks';
import { useDeleteGroupAction } from '~/queries';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TDeleteGroupActionDialogProperties = {
  groupAction: TGroupActionDetails;
};

export const DeleteGroupActionDialog: React.FC<TDeleteGroupActionDialogProperties> = (props) => {
  const { groupAction } = props;

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.DELETE_GROUP_ACTION);
  const { mutate: deleteGroupAction, isPending } = useDeleteGroupAction();
  const { onGoBack } = useGoBack({
    fallbackPath: AppRoutes.App.Actions.Group.path,
  });

  const deleteActionHandler = () => {
    deleteGroupAction(groupAction.id, {
      onSuccess: () => {
        closeModal();
        onGoBack();
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-full max-w-[480px]">
        <DialogHeader className="flex-row items-center justify-between p-6 pb-0">
          <DialogTitle className='className="leading-7 tracking-normal'>
            Are you sure you want to delete this action?
          </DialogTitle>
          <DialogDescription className="sr-only">
            Confirm if you want to delete group action with title:
            {' '}
            {groupAction.details.name}
            {' '}
            {groupAction.details.title}
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Text variant="size-14" type="body-400" className="px-6 pb-1 pt-3 text-grey-950">
          Deleting the action will delete all related attendance records, meetings, and interventions.
        </Text>
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            disabled={isPending}
            isLoading={isPending}
            onClick={deleteActionHandler}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
