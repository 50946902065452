import { z } from 'zod';

/**
 * A helper function that refines a Zod type by adding custom validation logic.
 * Zod ignoring the `superRefine` method if not fields exist in the object.
 * @see https://github.com/colinhacks/zod/issues/479#issuecomment-2067278879
 *
 * @param zodType - The Zod type to refine.
 * @returns The refined Zod type.
 */
export function zodAlwaysRefine<T extends z.ZodTypeAny>(zodType: T) {
  return z.any().superRefine(async (value, context) => {
    const result = await zodType.safeParseAsync(value);

    if (result.success === false) {
      for (const issue of result.error.issues) {
        context.addIssue(issue);
      }
    }
  }) as unknown as T;
}
