import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TModalName } from '~/constants/modals';

type TModalState = {
  /**
   * Object that holds the state of all modals.
   */
  modalsStates: Record<string, boolean>;
};

const initialState: TModalState = {
  modalsStates: {},
};

export const modalSlice = createSlice({
  name: AppReduxReducer.MODAL,
  initialState,
  reducers: {
    toggleModalState: (state, action: PayloadAction<{ name: TModalName; state: boolean }>) => {
      state.modalsStates = {
        ...state.modalsStates,
        [action.payload.name]: action.payload.state,
      };
    },
  },
});

export const { toggleModalState } = modalSlice.actions;
export const modalsReducer = modalSlice.reducer;
