import { isSameDay } from 'date-fns';
import { cn } from '@purple/ui';
import type { DateCellWrapperProps } from 'react-big-calendar';

export const CustomDateCellWrapper = ({ children, value }: DateCellWrapperProps) => {
  const isToday = isSameDay(value, new Date());

  return (
    <div
      className={cn(
        'flex h-full w-full flex-[1_0_0%] border-grey-200 [&:not(:first-of-type)]:border-l',
        {
          'rounded-sm border border-brand-blue-500 bg-brand-blue-50': isToday,
        },
        'h-full',
      )}
    >
      {children}
    </div>
  );
};
