import { Skeleton } from '@purple/ui';

const SchoolInfoHeaderSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-3">
        <Skeleton className="h-[60px] min-w-[60px] rounded-full" />
        <Skeleton className="flex h-[60px] w-full" />
      </div>
      <div className="flex flex-col gap-4">
        <Skeleton className="flex h-[24px] w-full" />
        <div className="flex flex-col gap-1">
          <Skeleton className="flex h-[60px] w-full" />
        </div>
      </div>
    </div>
  );
};

export { SchoolInfoHeaderSkeleton };
