import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const deleteLinkedStudent = async ({
  groupActionId,
  studentId,
}: {
  groupActionId: string;
  studentId: string;
}) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.delete<void>(
    replaceUrlParameters(ACTIONS.REMOVE_LINKED_STUDENT, groupActionId, studentId),
  );

  return response.data;
};
