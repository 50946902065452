import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafHistory } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSafHistoryRequestParameters } from '@purple/shared-types';

export const useSafHistory = (safId: string, parameters: TSafHistoryRequestParameters) => {
  const { isError, ...props } = useQuery({
    queryKey: [SAF_QUERY_KEYS.HISTORY_TIMELINE, safId, parameters],
    queryFn: () => getSafHistory(safId, parameters),
    enabled: !!safId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch SAF history');
    }
  }, [isError]);

  return { ...props, isError };
};
