import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useKeyPress } from '@purple/hooks';
import type React from 'react';

type TAppTheme = 'dark' | 'light' | 'system';

type TThemeProviderProperties = {
  children: React.ReactNode;
  defaultTheme?: TAppTheme;
  storageKey?: string;
};

type TThemeProviderState = {
  theme: TAppTheme;
  setTheme: (theme: TAppTheme) => void;
};

const initialState: TThemeProviderState = {
  theme: 'light',
  setTheme: () => null,
};

const ThemeProviderContext = createContext<TThemeProviderState>(initialState);

export const ThemeProvider: React.FC<TThemeProviderProperties> = (props) => {
  const { children, defaultTheme = 'light', storageKey = 'purple-sense-theme', ...rest } = props;

  const [theme, setTheme] = useState<TAppTheme>(() => (localStorage.getItem(storageKey) as TAppTheme) || defaultTheme);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const updateTheme = useCallback(
    (newTheme: TAppTheme) => {
      localStorage.setItem(storageKey, newTheme);
      setTheme(newTheme);
    },
    [storageKey],
  );

  const switchTheme = useCallback(() => {
    updateTheme(theme === 'light' ? 'dark' : 'light');
  }, [theme, updateTheme]);

  useKeyPress('KeyF', switchTheme, { withCtrl: true, withShift: true });

  const value = useMemo(
    () => ({
      theme,
      setTheme: updateTheme,
    }),
    [theme, updateTheme],
  );

  return (
    <ThemeProviderContext.Provider {...rest} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};
