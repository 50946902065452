import type React from 'react';
import type { Day } from 'react-day-picker';
import type { IStudentMonthEvent, TStudentCalendarItemType } from '@purple/shared-types';

type IWithEventType = {
  eventType: TStudentCalendarItemType;
  events: IStudentMonthEvent[];
};
type CustomDayProperties = {} & React.ComponentProps<typeof Day> & IWithEventType;

const CustomDay = ({ hidden, day, events, eventType, ...props }: CustomDayProperties) => {
  // TODO: uncomment when will continue working on this component
  // const foundedEvent = events.find((currentEvent) => isSameDay(date, currentEvent.start));

  // eslint-disable-next-line no-console
  console.log('CustomDay', { hidden, day, events, eventType });

  // From here returns whatever you want, but the logic should be the same:
  if (hidden) {
    return <div role="gridcell" />;
  }

  return (
    <td className="rdp-cell" {...props}>
      <button type="button" className="h-4 w-4 rounded-full bg-grey-200 text-[0px]">
        {day.date.getDate()}
      </button>
    </td>
  );
};

export { CustomDay };
