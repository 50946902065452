import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolUsersAccessList } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolUsersAccessRequestParameters } from '@purple/shared-types';

export const useUsersWithAccessList = ({
  schoolId,
  queryParams,
}: {
  schoolId: string;
  queryParams: TSchoolUsersAccessRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_USERS_WITH_ACCESS_LIST, { schoolId, queryParams }],
    queryFn: () => getSchoolUsersAccessList({ schoolId, queryParameters: queryParams }),
    enabled: !!schoolId,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch schools Users with access');
    }
  }, [isError]);
  return { data, isLoading };
};
