import {
  STUDENT_ATTENDANCE_TYPE,
  STUDENT_BEHAVIOR_TYPE,
  STUDENT_CALENDAR_ITEM_TYPE,
  type TStudentCalendarItemType,
} from '@purple/shared-types';
import { cn, Text } from '@purple/ui';

type ILegendItemProperties = {
  className?: string;
  label: string;
};

// TODO: not finished, need to finish after BA will ask the question
const LegendItem = ({ label, className }: ILegendItemProperties) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <div className={cn('h-2 w-2 rounded-full bg-grey-200', className)} />
      <Text variant="size-12" type="body-500" className="text-grey-600">
        {label}
      </Text>
    </div>
  );
};

type IEventsLegendProperties = {
  eventType: TStudentCalendarItemType;
};

// TODO: not finished, need to finish after BA will ask the question
const EventsLegend = ({ eventType }: IEventsLegendProperties) => {
  if (eventType === STUDENT_CALENDAR_ITEM_TYPE.ATTENDANCE) {
    return (
      <div className="flex flex-row gap-4">
        <LegendItem label={STUDENT_ATTENDANCE_TYPE.PRESENT} className="bg-grey-200" />
        <LegendItem label={STUDENT_ATTENDANCE_TYPE.PARTIAL_PRESENT} className="bg-brand-yellow" />
        <LegendItem label={STUDENT_ATTENDANCE_TYPE.ABSENT} className="bg-other-rose-2" />
        <LegendItem label={STUDENT_ATTENDANCE_TYPE.HOLIDAY} className="border border-brand-blue-700 bg-white" />
      </div>
    );
  }

  if (eventType === STUDENT_CALENDAR_ITEM_TYPE.BEHAVIOR) {
    return (
      <div className="flex flex-row gap-4">
        <LegendItem label={STUDENT_BEHAVIOR_TYPE.DAEP} className="bg-grey-200" />
        <LegendItem label={STUDENT_BEHAVIOR_TYPE.OSS} className="bg-brand-orange" />
        <LegendItem label={STUDENT_BEHAVIOR_TYPE.ISS} className="bg-other-rose-2" />
        <LegendItem label={STUDENT_BEHAVIOR_TYPE.Holiday} className="border border-brand-blue-700 bg-white" />
      </div>
    );
  }

  return <div>Legend</div>;
};

export { EventsLegend };
