import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { appReducer } from './features';
import type { Action, StoreEnhancer } from '@reduxjs/toolkit';

const sentryReduxEnhancer = Sentry.createReduxEnhancer() as StoreEnhancer;

export const store = configureStore({
  reducer: appReducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

/**
 * Reset the app state to its initial state
 */
export const RESET_APP = 'RESET_APP';
export const resetApp = () => ({ type: RESET_APP });

export const resettableRootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return appReducer(state, action);
};

store.replaceReducer(resettableRootReducer);
