import { TakeActionDocumentType, type ValueOf } from '@purple/shared-types';

export const DASHBOARD_VIEW = {
  DASHBOARD: 'dashboard',
  TOTAL_ACTIONS: 'total_actions',
  TOTAL_SAFS: 'total_safs',
  MEETING_ATTENDANCE: 'meeting_attendance',
} as const;
export type TDashboardView = ValueOf<typeof DASHBOARD_VIEW>;

export const ACTION_FILTERS_OPTIONS = {
  document_as: ['attempted', 'targeted', 'general'],
  date: [],
} as const;

export const ACTIONS_FILTER_TABS = [
  {
    label: 'Document As',
    value: 'document_as',
  },
  {
    label: 'Date',
    value: 'date',
  },
] as const;

export const ACTIONS_SORT_SELECT_OPTIONS = [
  {
    label: 'Name(ASC)',
    value: 'title',
  },
  {
    label: 'Name(DESC)',
    value: '-title',
  },
  {
    label: 'Date(ASC)',
    value: 'date_and_time',
  },
  {
    label: 'Date(DESC)',
    value: '-date_and_time',
  },
  {
    label: 'Owner(ASC)',
    value: 'created_by__full_name',
  },
  {
    label: 'Owner(DESC)',
    value: '-created_by__full_name',
  },
  {
    label: 'Document As(ASC)',
    value: 'document_as',
  },
  {
    label: 'Document As(DESC)',
    value: '-document_as',
  },
] as const;

export const STUDENT_ACTIONS_FILTERS = {
  categories: [
    {
      label: 'Document As',
      value: 'document_as',
      filters: [
        {
          label: 'General',
          value: TakeActionDocumentType.GENERAL,
        },
        {
          label: 'Targeted',
          value: TakeActionDocumentType.TARGETED,
        },
        {
          label: 'Attempted',
          value: TakeActionDocumentType.ATTEMPTED,
        },
      ],
    },
  ],
};
