import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReminderDetails } from '@purple/shared-services';
import { REMINDERS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useReminderDetails = (reminderId: string) => {
  const { isError, ...props } = useQuery({
    queryKey: [REMINDERS_QUERY_KEYS.GET_REMINDER_DETAILS, reminderId],
    queryFn: () => getReminderDetails(reminderId),
    enabled: !!reminderId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'An error occurred while fetching reminder details');
    }
  }, [isError]);

  return { isError, ...props };
};
