import { getAxiosInstance } from '../../api';
import { DISTRICTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TDistrictInfo } from '@purple/shared-types';

export const districtsCheck = async (subdomain: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TDistrictInfo>(replaceUrlParameters(DISTRICTS_ENDPOINTS.CHECK, subdomain));

  return response.data;
};
