import { Skeleton } from '@purple/ui';

export const HeaderSectionSkeleton = () => {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex gap-4 px-4 pt-6">
        <Skeleton className="h-10 w-24" />
        <Skeleton className="h-10 w-[300px]" />
      </div>
      <Skeleton className="h-10 w-24" />
    </div>
  );
};
