import type { TSafListManagerFilters } from '@purple/shared-types';

export const convertSafListManagerFilters = (response: TSafListManagerFilters, userId: string) => {
  const otherOwners = response.current_owner
    // NOTE: we remove current user from the list
    .filter((owner) => owner.id !== userId)
    .map((owner) => ({
      label: owner.full_name,
      value: owner.id,
    }));

  const owners = otherOwners;

  if (userId) {
    const currentUser = {
      label: 'Owned by me',
      value: userId,
    };
    owners.unshift(currentUser);
  }

  return {
    // NOTE: we show school filter only if there are more than 1 schools
    ...(response.school.length > 1 && {
      school: response.school.map((school) => ({
        label: school.name,
        value: school.id,
      })),
    }),
    current_owner: owners,
  };
};
