import { useMutation } from '@tanstack/react-query';
import { partialUserUpdate } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';

export const useCurrentUserPartialUpdate = () => {
  return useMutation({
    mutationKey: [USER_QUERY_KEYS.PARTIAL_CURRENT_USER_UPDATE],
    mutationFn: partialUserUpdate,
  });
};
