import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { CellApprovedStatus } from './CellApprovedStatus';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentSupportByProgramItem } from '@purple/shared-types';

export const supportByProgramColumns: ColumnDef<TStudentSupportByProgramItem>[] = [
  {
    accessorKey: 'type',
    header: () => (
      <Text variant="size-12" type="body-600" className="max-w-[450px] uppercase text-grey-600">
        Type
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-gray-950">
        {row.original.program_type}
      </Text>
    ),
  },
  {
    accessorKey: 'status',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Status
      </Text>
    ),
    cell: ({ row }) => <CellApprovedStatus isApproved={row.original.approval_status} />,
  },
  {
    accessorKey: 'service_provider',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Service Provider
      </Text>
    ),
    cell: ({ row }) => (
      <Link
        // TODO: Add navigation to service provider details page
        to="#"
        className="block max-w-[450px] truncate font-primary text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
      >
        {row.original.service_provider.name}
      </Link>
    ),
  },
  {
    accessorKey: 'program',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Program
      </Text>
    ),
    cell: ({ row }) => (
      <Link
        // TODO: Add navigation to service program details page
        to="#"
        className="block max-w-[450px] truncate font-primary text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
      >
        {row.original.program.name}
      </Link>
    ),
  },
  {
    accessorKey: 'group_service',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Group Service
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-gray-950">
        {snakeCaseToCapitalized(row.original.group_service, '–')}
      </Text>
    ),
  },
  {
    accessorKey: 'start_date',
    header: () => (
      <Text variant="size-12" type="body-600" className="w-max uppercase text-grey-600">
        Start Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="w-max text-gray-950">
        {format(row.original.start_date, 'MMMM dd, yyyy')}
      </Text>
    ),
  },
  {
    accessorKey: 'end_date',
    header: () => (
      <Text variant="size-12" type="body-600" className="w-max uppercase text-grey-600">
        End Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="w-max text-gray-950">
        {format(row.original.end_date, 'MMMM dd, yyyy')}
      </Text>
    ),
  },
];
