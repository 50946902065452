import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
// hooks
import { usePaginate } from '@purple/hooks';
import { useFiles } from '~/queries';
// components
import { DataTable, DataTableSkeleton } from '~/components';
import { DeleteSchoolFileDialog } from './DeleteSchoolFileDialog';
import { EditSchoolFileDialog } from './EditSchoolFileDialog';
import { FilesHeaderSection } from './FilesHeaderSection';
import { UploadFilePreviewDialog } from './UploadFilePreviewDialog';
import { useFilesColumns } from './useFilesColumns';

export const SchoolFiles: React.FC = () => {
  const { schoolId } = useParams();

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);
  const { page, limit, offset, onPageChange, onLimitChange } = usePaginate();

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const filesColumns = useFilesColumns({ onSelectFileId: setSelectedFileId, onSelectFile: setUploadedFile });

  const { data, isFetching } = useFiles({
    limit,
    offset,
    search,
    ordering: sort,
    school: schoolId as string,
  });

  const files = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => files.length > 0 && !isFetching,
    [files, isFetching],
  );

  if (!schoolId) return null;

  return (
    <section className="flex w-full flex-col gap-6">
      <Heading tag="h2" className="px-4 pt-6 text-lg font-semibold text-grey-title">
        Files
      </Heading>
      <div className="flex w-full flex-col gap-5">
        <FilesHeaderSection onFileUpload={setUploadedFile} />
        <div>
          <DataTable
            columns={filesColumns}
            data={files}
            isLoading={isFetching}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No files found"
            emptyStateMessage="It looks like we haven’t found any files. If something went wrong, please reach out to support."
            classNames={{
              wrapper: 'mb-0',
              cell: 'py-3 h-14',
              emptyCell: 'py-8',
            }}
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {isDataAvailable && (
            <TableFooterSection
              currentPage={page}
              rowsPerPage={limit}
              pageCount={pageCount}
              totalRows={data?.count || 0}
              onPageChange={onPageChange}
              onRowsPerPageChange={onLimitChange}
            />
          )}
        </div>
      </div>
      <UploadFilePreviewDialog
        uploadedFile={uploadedFile}
        selectedFileId={selectedFileId}
        setUploadedFile={setUploadedFile}
        setSelectedFileId={setSelectedFileId}
      />
      <DeleteSchoolFileDialog selectedFileId={selectedFileId} setSelectedFileId={setSelectedFileId} />
      <EditSchoolFileDialog selectedFileId={selectedFileId} setSelectedFileId={setSelectedFileId} />
    </section>
  );
};
