import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { StudentTrendsAndFlagsResponse } from './types';

export const getStudentTrendsAndFlags = async (studentId: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<StudentTrendsAndFlagsResponse>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_TRENDS_AND_FLAGS, studentId),
  );

  return response.data;
};

export { type FlagsPlot, type StudentTrendsAndFlagsResponse, type TrendAnalysis } from './types';
