import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, DataNotFoundLayout, EntityDetailsLayout } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useAccordion } from '~/hooks';
import { useSchoolById } from '~/queries';
import { SchoolInfoHeader, SchoolInfoHeaderSkeleton } from './SchoolInfoHeader';
import { useSchoolSidebarAccordionItems } from './useSchoolSidebarAccordionItems';

export const SchoolInfoSidebar = () => {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  const { data, isLoading, isError, refetch } = useSchoolById(schoolId as string);

  const accordionItems = useSchoolSidebarAccordionItems(data);

  const { allowedTabs } = useAccordion({
    tabs: accordionItems,
  });

  const handleBackClick = () => {
    navigate(AppRoutes.App.Home.Root.path);
  };

  return (
    <EntityDetailsLayout
      back={<BackButton label="Back to Home Page" onClick={handleBackClick} />}
      header={isLoading || !data ? <SchoolInfoHeaderSkeleton /> : <SchoolInfoHeader data={data} />}
      content={allowedTabs}
      errorContent={isError ? <DataNotFoundLayout onRetry={() => refetch()} className="h-[608px]" /> : null}
    />
  );
};
