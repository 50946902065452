import { getAxiosInstance } from '../../api';
import { REMINDERS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TReminderUpdateBody, TReminderUpdateResponse } from '@purple/shared-types';

export const updateReminder = async ({ reminderId, body }: { reminderId: string; body: TReminderUpdateBody }) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<TReminderUpdateResponse>(
    replaceUrlParameters(REMINDERS.UPDATE_REMINDER, reminderId),
    body,
  );
  return response.data;
};
