import { useMemo, useState } from 'react';
import { useLocalPagination, useLocalSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, SearchInput, TableFooterSection } from '@purple/ui';
import { useModal } from '~/hooks';
import { useActionLinkedStudents, useDeleteLinkedStudent } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { DataTable } from '../DataTable';
import { DataTableSkeleton } from '../DataTableSkeleton';
import { CallToActionModal } from '../Modals';
import { AddGroupActionMembersModal } from '../Modals/AddGroupActionMembersModal';
import { useGroupActionsMembersColumns } from './useGroupActionsMembersColumns';

type TGroupActionManageMembersProperties = {
  groupActionId: string | null;
  onModalClose?: () => void;
};

export const GroupActionManageMembers = ({ groupActionId, onModalClose }: TGroupActionManageMembersProperties) => {
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const { debounceSearch, onClearSearch, onSearchChange, search } = useLocalSearch();
  const { limit, offset, onLimitChange, onPageChange, page } = useLocalPagination();

  const { isOpen, closeModal } = useModal('delete-group-action-members-modal');
  const { isOpen: isAddGroupMembersOpen, openModal: openAddGroupMembersModal } = useModal(
    'add-group-action-members-modal',
  );

  const groupActionMembersColumns = useGroupActionsMembersColumns({
    onSelectedMember: setSelectedMemberId,
    closeMainModal: onModalClose,
  });

  const { mutate, isPending } = useDeleteLinkedStudent();

  const { data, isLoading } = useActionLinkedStudents({
    groupActionId: groupActionId as string,
    limit,
    offset,
    search: debounceSearch,
  });

  const members = useMemo(() => data?.results || [], [data?.results]);
  const totalMemberAmount = useMemo(() => data?.count ?? 0, [data?.count]);
  const isDataNotExist = useMemo(() => members.length === 0 && !isLoading, [members, isLoading]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  const dismissDeleteModal = () => {
    setSelectedMemberId('');
    closeModal();
  };

  const deleteMemberHandler = () => {
    if (!selectedMemberId || !groupActionId) {
      return showErrorToast('System Message', 'An error occurred while deleting member');
    }
    mutate(
      { groupActionId, studentId: selectedMemberId },
      {
        onSuccess: () => {
          dismissDeleteModal();
        },
      },
    );
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <Heading variant="size-18" type="heading-600">
            Group Action Members
            {' '}
            <span className="text-xs text-grey-600">{`(${totalMemberAmount})`}</span>
          </Heading>
          <div className="flex items-center justify-between gap-2">
            <SearchInput
              value={search}
              onChange={onSearchChange}
              onClear={onClearSearch}
              placeholder="Search"
              className="max-w-[300px]"
            />
            <Button variant="secondary" iconLeft={<PurpleIcon name="user-add" />} onClick={openAddGroupMembersModal}>
              Add Members
            </Button>
          </div>
        </div>
        <div className="flex w-full flex-col">
          <DataTable
            columns={groupActionMembersColumns}
            data={members}
            isLoading={isLoading}
            isFiltersApplied={Boolean(search)}
            classNames={{ cell: 'py-3 only:py-0', wrapper: 'mb-0' }}
            emptyStateTitle="Purple Users Participants not found"
            emptyStateMessage="There are no participants to display. If an error occurs, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
      {isAddGroupMembersOpen && <AddGroupActionMembersModal groupActionId={groupActionId} existingMembers={members} />}
      {isOpen && (
        <CallToActionModal
          modalName="delete-group-action-members-modal"
          modalTitle="Remove Group Action Member"
          modalDescription="Remove group action member modal"
          modalTextContent="Are you sure you want to remove selected member from the group action?"
          primaryButtonText="Yes, remove"
          secondaryButtonText="Cancel"
          primaryButtonVariant="destructive_primary"
          isLoading={isPending}
          onSecondaryButtonClick={dismissDeleteModal}
          onPrimaryButtonClick={deleteMemberHandler}
        />
      )}
    </>
  );
};
