import { useSearchParams } from 'react-router-dom';
import {
  LIMIT_QUERY_NAME,
  OFFSET_QUERY_NAME,
  SEARCH_QUERY_NAME,
  SORT_QUERY_NAME,
  VIEW_QUERY,
} from '@purple/shared-types';

export type TUseSubPageViewProperties = {
  defaultView: string;
};

/**
 * @deprecated - Use `useQueryParameter` hook instead
 */
export const useSubPageView = ({ defaultView }: TUseSubPageViewProperties) => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const currentView = searchParameters.get(VIEW_QUERY) || defaultView;

  const changeView = (view: string) => {
    setSearchParameters(
      (previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        newSearchParameters.set(VIEW_QUERY, view);

        // Clear search, sort, limit and offset queries
        newSearchParameters.delete(SEARCH_QUERY_NAME);
        newSearchParameters.delete(SORT_QUERY_NAME);
        newSearchParameters.delete(LIMIT_QUERY_NAME);
        newSearchParameters.delete(OFFSET_QUERY_NAME);

        return newSearchParameters;
      },
      { replace: true },
    );
  };

  return {
    view: currentView,
    changeView,
  };
};
