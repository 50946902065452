import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { cutGradeName } from '@purple/shared-utils';
import {
  AccessDenied,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { useDashboardRedStudents } from '~/queries';
import { StudentsInRedSkeleton } from './StudentsInRedSkeleton';
import type { TChartConfig } from '@purple/ui';

const chartConfig = {
  students: {
    label: 'Students',
    color: '#DD8080',
  },
} satisfies TChartConfig;

export const StudentsInRed: React.FC = () => {
  const { hasPermissions } = usePermissions();

  const hasUserPermissions = hasPermissions(APP_PERMISSIONS.CAN_ACCESS_RED_STUDENTS_VISUAL);

  const { data: redStudentsData, isFetching } = useDashboardRedStudents({ enabled: hasUserPermissions });

  const chartData = useMemo(
    () =>
      Object.entries(redStudentsData ?? {})
        .filter(([key]) => key !== 'total')
        .map(([name, value]) => ({
          name: cutGradeName(name),
          students: value,
        })),
    [redStudentsData],
  );

  const total = useMemo(() => chartData.reduce((accumulator, item) => accumulator + item.students, 0), [chartData]);

  if (!hasUserPermissions) {
    return (
      <div className="flex min-h-[364px] w-full flex-col rounded-lg border border-grey-200 bg-white shadow-custom-medium">
        <AccessDenied accessDeniedMessage="Sorry, you don't have access to this widget" />
      </div>
    );
  }

  if (isFetching) {
    return <StudentsInRedSkeleton />;
  }

  return (
    <Card className="gap-4 px-4 py-6 shadow-custom-medium">
      <CardHeader className="flex-row items-center justify-between gap-2 space-y-0">
        <CardTitle className="text-base font-semibold">Students in Red: Actions Required</CardTitle>
        <CardDescription className="text-grey-600">Last 30 days</CardDescription>
      </CardHeader>
      <CardContent className="flex-col items-center gap-4">
        <p className="inline-flex w-full flex-col">
          <strong className="text-4xl font-extrabold text-grey-title">{total}</strong>
          <span className="text-sm font-medium text-grey-600">Total Students</span>
        </p>
        <ReChartContainer config={chartConfig} className="max-h-[144px] w-full shrink-0">
          <BarChart data={[...chartData]}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <YAxis
              dataKey="students"
              width={40}
              tickSize={40}
              tickCount={5}
              interval={0}
              tick={{ fontSize: 10, fontWeight: 500, textAnchor: 'left' }}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              interval={0}
              tick={{ fontSize: 10, fontWeight: 500 }}
              label={{
                value: 'Grade',
                position: 'left',
                className: 'fill-grey-600',
                fontSize: 10,
                dx: 0,
                dy: -4,
                lineHeight: 12,
                fontWeight: 400,
              }}
            />
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <Bar dataKey="students" fill="var(--color-students)" radius={2} />
          </BarChart>
        </ReChartContainer>
      </CardContent>
      <CardFooter className="mt-auto justify-center">
        <Button type="button" variant="primary">
          Take Action
        </Button>
      </CardFooter>
    </Card>
  );
};
