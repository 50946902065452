import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ACTIVITY_TYPE_QUERY_NAME, TIME_PERIOD_NAME } from '@purple/shared-types';
import { makeSubstringsUpperCase, snakeCaseToCapitalized } from '@purple/shared-utils';
import {
  AppFilters,
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
// helpers
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { useActivitiesTypes } from '~/queries/activities/useActivitiesTypes';
import { FILES_SORT_SELECT_OPTIONS } from './constants';
// types
import type React from 'react';

const ACTIVITY_NAME_ABBREVIATIONS = ['Hisd'];

type TActivitiesHeaderSectionProperties = {
  isActivitiesSelected?: boolean;
};

export const ActivitiesHeaderSection: React.FC<TActivitiesHeaderSectionProperties> = (props) => {
  const { isActivitiesSelected = false } = props;

  const { openModal: openBulkDeleteActivityModal } = useModal(ModalType.BULK_DELETE_ACTIVITY);

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const { data, isFetching } = useActivitiesTypes();

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);
  const activityTypes = useMemo(() => data || [], [data]);

  const filters = useMemo(
    () => ({
      categories: [
        {
          label: 'Activity Type',
          value: ACTIVITY_TYPE_QUERY_NAME,
          filters: activityTypes.map(({ id, name }) => ({
            label: makeSubstringsUpperCase(snakeCaseToCapitalized(name), ACTIVITY_NAME_ABBREVIATIONS),
            value: id,
          })),
        },
        {
          label: 'Time Period',
          value: TIME_PERIOD_NAME,
          filters: PERIOD_SELECT_OPTIONS.map(({ label, value }) => ({
            label,
            value,
          })),
        },
      ],
    }),
    [activityTypes],
  );

  const printActivitiesHandler = () => {
    console.log('Print activities'); // eslint-disable-line no-console
  };

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-4 px-4">
      <div className="flex gap-4">
        <AppFilters config={filters} loading={isFetching} />
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="min-w-[200px] max-w-[200px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="w-max">
            {FILES_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by name or owner"
          className="max-w-[260px]"
        />
      </div>
      <div className="flex gap-4">
        <Button
          type="button"
          variant="destructive_secondary"
          disabled={!isActivitiesSelected}
          onClick={openBulkDeleteActivityModal}
        >
          Delete Activities
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              aria-label="Print activities"
              size="icon_40"
              variant="secondary"
              onClick={printActivitiesHandler}
              iconLeft={<PurpleIcon name="printer" />}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Print activities</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    </div>
  );
};
