import { STUDENT_DATA_FILTERS } from '@purple/shared-types';

export const SORT_OPTIONS = [
  {
    label: 'Total Flags This Period (ASC)',
    value: 'flags_cy',
  },
  {
    label: 'Total Flags This Period (DESC)',
    value: '-flags_cy',
  },
  {
    label: 'Absences This Period (ASC)',
    value: 'total_absences',
  },
  {
    label: 'Absences This Period (DESC)',
    value: '-total_absences',
  },
  {
    label: 'Behavior Flags This Period (ASC)',
    value: 'behavior_cy',
  },
  {
    label: 'Behavior Flags This Period (DESC)',
    value: '-behavior_cy',
  },
  {
    label: 'Grade Flags This Period (ASC)',
    value: 'grades_cy',
  },
  {
    label: 'Grade Flags This Period (DESC)',
    value: '-grades_cy',
  },
  {
    label: 'Total SAF This Period (ASC)',
    value: 'total_safs',
  },
  {
    label: 'Total SAF This Period (DESC)',
    value: '-total_safs',
  },
  {
    label: 'Total Interventions This Period (ASC)',
    value: 'interventions_cy',
  },
  {
    label: 'Total Interventions This Period (DESC)',
    value: '-interventions_cy',
  },
  {
    label: 'Subpopulations This Period (ASC)',
    value: 'number_of_subpopulations',
  },
  {
    label: 'Subpopulations This Period (DESC)',
    value: '-number_of_subpopulations',
  },
  {
    label: 'First Name (ASC)',
    value: 'first_name',
  },
  {
    label: 'First Name (DESC)',
    value: '-first_name',
  },
  {
    label: 'Last Name (ASC)',
    value: 'last_name',
  },
  {
    label: 'Last Name (-ASC)',
    value: '-last_name',
  },
  {
    label: 'ADA (ASC)',
    value: 'average_daily_attendance_cy',
  },
  {
    label: 'ADA (DESC)',
    value: '-average_daily_attendance_cy',
  },
  {
    label: 'Nurse Visits (ASC)',
    value: 'nurse_visits_cy',
  },
  {
    label: 'Nurse Visits (DESC)',
    value: '-nurse_visits_cy',
  },
];

export const FILTER_TABS = [
  {
    label: 'Grade Level',
    value: STUDENT_DATA_FILTERS.GRADE_LEVEL,
  },
  {
    label: 'Gender',
    value: STUDENT_DATA_FILTERS.GENDER,
  },
  {
    label: 'Current Color',
    value: STUDENT_DATA_FILTERS.CURRENT_COLOR,
  },
  {
    label: 'Actions',
    value: STUDENT_DATA_FILTERS.ACTIONS,
  },
  {
    label: 'Student Status',
    value: STUDENT_DATA_FILTERS.STUDENT_STATUS,
  },
  {
    label: 'Ethnicity',
    value: STUDENT_DATA_FILTERS.ETHNICITY,
  },
  {
    label: 'Subpopulations',
    value: STUDENT_DATA_FILTERS.SUBPOPULDATIONS,
  },
];
