import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import { Button, Heading } from '@purple/ui';
import { ChoiceTag } from '~/components';
import { ActionTypeToPermission, ReadableTakeActionDocumentType } from '~/constants/take-action';
import { useModal } from '~/hooks';
import { useCurrentUser } from '~/hooks/redux';
import { DeleteActionDialog } from './DeleteActionDialog';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TActionHeaderProperties = {
  actionData: TActionDetails;
  hideTags?: boolean;
};

export const ActionHeader: React.FC<TActionHeaderProperties> = (props) => {
  const { actionData, hideTags = false } = props;

  const { openModal } = useModal('delete-action');
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToDelete = useMemo(
    () =>
      user.id === actionData.details.created_by?.id
      && hasPermissions(ActionTypeToPermission[actionData.record_action_type]),
    [user, actionData, hasPermissions],
  );

  return (
    <div className="flex w-full justify-between gap-4">
      <div className="flex flex-col gap-2">
        <Heading tag="h1" className="text-lg font-semibold text-grey-title">
          {actionData.title}
        </Heading>
        <ul className="flex flex-wrap items-center gap-2">
          <li>
            {hideTags && <span className="text-sm text-grey-600">Document as: </span>}
            <ChoiceTag>{ReadableTakeActionDocumentType[actionData.document_as]}</ChoiceTag>
          </li>
          {!hideTags
          && actionData.tags.map(({ color, name }) => (
            <li key={name}>
              <ChoiceTag color={color}>{name}</ChoiceTag>
            </li>
          ))}
        </ul>
      </div>
      {allowedToDelete && (
        <Button
          type="button"
          aria-label="Delete action"
          variant="destructive_secondary"
          size="icon_32"
          className="border-0"
          iconLeft={<PurpleIcon name="trash" className="h-5 w-5 shrink-0" />}
          onClick={openModal}
        />
      )}
      <DeleteActionDialog action={actionData} />
    </div>
  );
};
