import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafListManagerFilters } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertSafListManagerFilters } from './converter';

export const useSafListManagerFilters = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER_FILTERS],
    queryFn: () => getSafListManagerFilters(),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch saf list manager filters');
    }
  }, [isError]);

  return { data: data ? convertSafListManagerFilters(data, userId) : data, isLoading };
};
