import { useMutation, useQueryClient } from '@tanstack/react-query';
import { batchAddPurpleParticipants } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionBatchPurpleUserParticipantsBody } from '@purple/shared-types';

export const useAddPurpleUserParticipants = (actionId: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, TActionBatchPurpleUserParticipantsBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.ADD_PURPLE_USER_PARTICIPANTS],
    mutationFn: (body) => batchAddPurpleParticipants(actionId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_DETAILS, actionId] });
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.PURPLE_USER_PARTICIPANTS, actionId] });
      showSuccessToast('System message', 'Purple user participants have been successfully added');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not add purple user participants. Please try again later or contact support',
      );
    },
  });
};
