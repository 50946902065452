import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { useSafAssignPriority } from '~/queries/saf/useSafAssignPriority';
import { showErrorToast } from '~/shared/lib';
import { assignPriorityFormSchema } from './assign-priority-form-schema';
import { SAF_PRIORITY_OPTIONS } from './constants';
import type * as z from 'zod';

type TFormValues = z.infer<typeof assignPriorityFormSchema>;

export const AssignPriorityForm = () => {
  const { safId } = useParams();

  const { mutate, isPending } = useSafAssignPriority();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(assignPriorityFormSchema),
    defaultValues: {
      priority: undefined,
    },
  });

  const setSafetyPriorityHandler = (formData: TFormValues) => {
    if (!safId) {
      return showErrorToast('System message', 'Unable to assign the priority, check if SAF is valid');
    }
    mutate({ safId, priority: formData.priority });
  };

  return (
    <Form providerProps={form} className="flex gap-4" onSubmit={form.handleSubmit(setSafetyPriorityHandler)}>
      <FormField
        control={form.control}
        name="priority"
        render={({ field }) => (
          <FormItem>
            <div className="flex gap-2">
              <FormLabel required>Priority</FormLabel>
              <FormMessage />
            </div>
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
              <RadixSelectTrigger className="w-[320px]">
                <RadixSelectValue placeholder="Select SAF priority" />
              </RadixSelectTrigger>
              <RadixSelectContent>
                {SAF_PRIORITY_OPTIONS.map((priority) => (
                  <RadixSelectItem key={priority.value} value={priority.value}>
                    {priority.label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          </FormItem>
        )}
      />
      <Button type="submit" className="self-end" disabled={isPending} isLoading={isPending}>
        Save
      </Button>
    </Form>
  );
};
