import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentActionListItem } from '@purple/shared-types';

export const supportByTypeColumns: ColumnDef<TStudentActionListItem>[] = [
  {
    accessorKey: 'title',
    header: () => (
      <Text variant="size-12" type="body-600" className="max-w-[450px] uppercase text-grey-600">
        Name
      </Text>
    ),
    cell: ({ row }) => (
      <Link
        to={AppRoutes.App.Actions.Details.makePath(row.original.id)}
        className="block max-w-[450px] truncate font-primary text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
      >
        {row.original.title}
      </Link>
    ),
  },
  {
    accessorKey: 'type',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        ACTION TYPE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {snakeCaseToCapitalized(row.original.record_action_type, '–')}
      </Text>
    ),
  },
  {
    accessorKey: 'document_as',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Document As
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
        {row.original.document_as}
      </Text>
    ),
  },
  {
    accessorKey: 'date_and_time',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {format(row.original.date_and_time, 'MMMM d, yyyy')}
      </Text>
    ),
  },
  {
    accessorKey: 'saf',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Linked SAFs
      </Text>
    ),
    cell: ({ row: { original } }) =>
      original.saf
        ? (
            <Link
              to={AppRoutes.App.Safs.SAFDetails.makePath(original.saf.id)}
              className="cursor-pointer truncate font-primary text-brand-blue-700 hover:underline hover:underline-offset-2"
            >
              {original.saf.saf_number}
            </Link>
          )
        : (
            '—'
          ),
  },
];
