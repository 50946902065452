import { Skeleton } from '@purple/ui';

const ContentSkeleton = () => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-[264px] w-full" />
    </div>
  );
};

export { ContentSkeleton };
