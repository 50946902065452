import { useState } from 'react';
import { StudentIdColumnEdit } from './StudentIdColumnEdit';
import { StudentIdColumnView } from './StudentIdColumnView';
import type { IEditionSaveStudentByIdFormEntity, IStudentByIdFormEntity } from '../types';

type TStudentIdColumnProperties = React.PropsWithChildren<{
  student: IStudentByIdFormEntity;
  onEdit: (value: IEditionSaveStudentByIdFormEntity) => void;
}>;

const StudentIdColumn = ({ student, onEdit }: TStudentIdColumnProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const handleEditionSave = (value: IEditionSaveStudentByIdFormEntity) => {
    setMode('view');
    onEdit(value);
  };

  if (mode === 'view') {
    return <StudentIdColumnView student={student} onEdit={() => setMode('edit')} />;
  }
  return <StudentIdColumnEdit student={student} onSave={handleEditionSave} />;
};

export { StudentIdColumn };
