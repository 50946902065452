import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { EditableBlockHeader } from '@purple/ui';
import { useUpdateSchool } from '~/queries';
import { updateSchoolPoliticalRegionSchema } from '../../schema';
import { PoliticalRegionsEdit } from './PoliticalRegionsEdit';
import { PoliticalRegionsView } from './PoliticalRegionsView';
import type * as z from 'zod';
import type { TSchoolInfo } from '@purple/shared-types';

type TPoliticalRegionsProperties = {
  data: TSchoolInfo;
};

const PoliticalRegions: React.FC<TPoliticalRegionsProperties> = ({ data }: TPoliticalRegionsProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending, isSuccess } = useUpdateSchool();

  useEffect(() => {
    if (isSuccess) {
      setMode('view');
    }
  }, [isSuccess]);

  const defaultValues = useMemo(
    () => ({
      trustee_district_region: data.political_regions.trustee_district_region,
      texas_house_district_region: data.political_regions.texas_house_district_region?.toString(),
      state_board_of_education_district: data.political_regions.state_board_of_education_district?.toString(),
      city_council_district: data.political_regions.city_council_district,
      texas_state_senate_district: data.political_regions.texas_state_senate_district?.toString(),
    }),
    [data],
  );

  const form = useForm<z.infer<typeof updateSchoolPoliticalRegionSchema>>({
    resolver: zodResolver(updateSchoolPoliticalRegionSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [data, defaultValues, form, mode]);

  const handleSave = (formData: z.infer<typeof updateSchoolPoliticalRegionSchema>) => {
    const requestValue = {
      schoolId: data.id,
      request: {
        political_regions: {
          ...formData,
          texas_house_district_region: Number(formData.texas_house_district_region) || null,
          state_board_of_education_district: Number(formData.state_board_of_education_district) || null,
          texas_state_senate_district: Number(formData.texas_state_senate_district) || null,
        },
      },
    };
    mutate(requestValue);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <Guard requiredPermissions={[APP_PERMISSIONS.CAN_EDIT_SCHOOL_PROFILE]}>
        <EditableBlockHeader
          mode={mode}
          onEdit={() => setMode('edit')}
          onCancel={() => setMode('view')}
          onSave={form.handleSubmit(handleSave)}
          isSaving={isPending}
        />
      </Guard>
      {mode === 'view' ? <PoliticalRegionsView data={data.political_regions} /> : <PoliticalRegionsEdit form={form} />}
    </div>
  );
};

export { PoliticalRegions };
