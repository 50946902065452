export const AUTH_QUERY_KEYS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  RESET_PASSWORD: 'reset-password',
  CONFIRM_RESET_PASSWORD: 'confirm-reset-password',
  CREATE_ACCOUNT: 'create-account',
  CREATE_COMPANY_PROFILE: 'create-company-profile',
  SEND_CHANGE_EMAIL: 'send-change-email',
  DECLINE_CHANGE_EMAIL: 'decline-change-email',
  SSO_LOGIN: 'sso-login',
} as const;

export const USER_QUERY_KEYS = {
  CURRENT: 'current-user',
  CURRENT_PROFILE: 'current-user-profile',
  CURRENT_PROFILE_FILES: 'current-user-profile-files',
  CURRENT_PROFILE_UPDATE: 'current-user-profile-update',
  CURRENT_PROFILE_UPDATE_PASSWORD: 'current-user-profile-update-password',
  CURRENT_PROFILE_UPDATE_AVATAR: 'current-user-profile-update',
  PARTIAL_CURRENT_USER_UPDATE: 'partial-current-user-update',
  SEARCH: 'search-users',
  EVENTS: 'user-events',
} as const;

export const DISTRICTS_QUERY_KEYS = {
  CHECK: 'check-district',
} as const;

export const PERMISSIONS_QUERY_KEYS = {
  GET_USER_PERMISSIONS: 'get-user-permissions',
} as const;

export const STUDENTS_QUERY_KEYS = {
  GET_STUDENT_DETAILS: 'get-student-details',
  GET_STUDENTS_LIST: 'get-students-list',
  UPDATE_HOME_INFORMATION: 'update-student-home-information',
  GET_DASHBOARD: 'get-student-dashboard',
  GET_STUDENT_CALENDAR: 'get-student-calendar',
  GET_STUDENT_GRADES_FILTERS: 'get-student-grades-filters',
  GET_STUDENT_GRADES_LIST: 'get-student-grades',
  GET_STUDENT_TRENDS_AND_FLAGS: 'get-student-attendance',
  GET_STUDENT_INCIDENTS: 'get-student-incidents',
  GET_STUDENT_INCIDENTS_FILTERS: 'get-student-incidents-filters',
  GET_STUDENT_INCIDENT_DETAIL: 'get-student-incident-detail',
  GET_STUDENT_ACCOUNT_HISTORY: 'get-student-account-history',
  GET_STUDENTS_DATA_ALL_MUTATION: 'get-students-data-all-mutation',
  GET_STUDENTS_DATA_ALL: 'get-students-data-all',
  GET_STUDENTS_DATA_LIST: 'get-students-data-list',
  GET_STUDENTS_DATA_FILTERS: 'get-students-data-filters',
  // STUDENT SUPPORT
  GET_STUDENT_SUPPORT_BY_PROGRAM: 'get-student-support-by-program',
  EXPORT_SUPPORT_BY_PROGRAM: 'export-support-by-program',
  // ATTENDANCE
  GET_STUDENT_ATTENDANCE_LIST: 'get-student-attendance-list',
  EXPORT_ATTENDANCE_ANALYSIS: 'export-attendance-analysis',
  // STUDENTS LIST
  ADD_STUDENTS_TO_LIST: 'add-students-to-list',
  // STUDENT SAFS
  GET_STUDENT_SAF_FILTERS: 'get-student-saf-filters',
  GET_STUDENT_SAFS_LIST: 'get-student-safs-list',
  // STUDENTS MEETING ATTENDANCE
  GET_STUDENT_MEETING_ATTENDANCE_LIST: 'get-student-meeting-attendance-list',
  GET_STUDENT_MEETING_ATTENDANCE_FILTERS: 'get-student-meeting-attendance-filters',
  // ACTIONS
  GET_ACTION_LINKED_STUDENTS: 'get-action-linked-students',
} as const;

export const SCHOOLS_QUERY_KEYS = {
  GET_ALL_SCHOOLS: 'get-all-schools',
  GET_ALL_MY_SCHOOLS: 'get-all-my-schools',
  GET_SCHOOLS_LIST: 'get-schools-list',
  GET_SCHOOLS_BY_ID: 'get-school-by-id',
  UPDATE_SCHOOL: 'update-school',
  // SCHOOL SAFS
  GET_SCHOOLS_SAF_FILTERS: 'get-schools-saf-filters',
  GET_SCHOOL_SAFS_LIST: 'get-school-safs-list',
  // SCHOOL USERS WITH ACCESS
  GET_SCHOOLS_USERS_WITH_ACCESS_LIST: 'get-schools-users-with-access-list',
  GET_SCHOOLS_USERS_WITH_ACCESS_FILTERS: 'get-schools-users-with-access-filters',
  GET_SCHOOL_DATA_STATS: 'get-school-data-stats',
  GET_SCHOOL_DATA_CALENDAR: 'get-school-data-calendar',
  GET_SCHOOL_FILTER_CHOICES: 'get-school-filter-choices',
  // SCHOOL OVERVIEW
  GET_SCHOOL_OVERVIEW: 'get-school-overview',
  GET_SCHOOL_OVERVIEW_LINKED_STUDENTS: 'get-school-overview-linked-students',
} as const;

export const SAF_QUERY_KEYS = {
  CREATE_SAF: 'create-saf',
  SAF_NEEDS_LIST: 'saf-needs-list',
  GET_SAF_LIST_MANAGER: 'get-saf-list-manager',
  GET_SAF_DETAILS: 'get-saf-details',
  GET_SAF_LIST_MANAGER_FILTERS: 'get-saf-list-manager-filters',
  GET_CLOSE_SAF_CATEGORIES: 'get-close-saf-categories',
  CLOSE_SAF: 'close-saf',
  LINK_STUDENT: 'link-student',
  ASSIGN_PRIORITY: 'assign-priority',
  UPDATE_SAF_DETAILS: 'update-saf-details',
  PLACE_ON_HOLD: 'place-on-hold',
  REMOVE_FROM_HOLD: 'remove-from-hold',
  TRANSFER: 'transfer',
  NO_NEED: 'no-need',
  EMAIL_LIST: 'saf-email-list',
  EMAIL_SUBJECT: 'saf-email-subject',
  SEND_EMAIL: 'send-saf-email',
  REPLY_EMAIL: 'reply-saf-email',
  HISTORY_TIMELINE: 'saf-history-timeline',
  UNIDENTIFIABLE: 'unidentifiable',
} as const;

export const ACTIONS_QUERY_KEYS = {
  GET_ACTION_GROUPS_CHOICES: 'get-action-groups-choices',
  GET_ACTION_CHOICES: 'get-action-choices',
  GET_ACTION_DETAILS: 'get-action-details',
  GET_SAF_LINKED_ACTIONS: 'get-saf-linked-actions',
  UPDATE_GROUP_ACTION_TAGS: 'update-group-action-tags',
  MY_GROUP_ACTIONS: 'get-my-group-actions',
  GET_ACTION_GROUP_DETAILS: 'get-action-group-details',
  UPDATE_ACTION: 'update-action',
  UPDATE_GROUP_ACTION_DETAILS: 'update-group-action-details',
  DELETE_ACTION: 'delete-action',
  DELETE_GROUP_ACTION: 'delete-group-action',
  DELETE_LINKED_STUDENT: 'delete-linked-student',
  CREATE_ACTION: 'create-action',
  CREATE_ACTION_GROUP_RECURRENCE: 'create-action-group-recurrence',
  UPDATE_ACTION_TAGS: 'update-action-tags',
  GET_ACTIONS_SCHOOL_LIST: 'get-actions-school-list',
  EXPORT_ACTIONS_FILE: 'export-actions-file',
  PURPLE_USER_PARTICIPANTS: 'get-purple-user-participants',
  REMOVE_PURPLE_USER_PARTICIPANTS: 'remove-purple-user-participants',
  ADD_PURPLE_USER_PARTICIPANTS: 'add-purple-user-participants',
  BATCH_ADD_STUDENTS: 'batch-add-students',
  GET_GROUP_ACTION_HISTORY: 'get-action-group-history',
} as const;

export const REMINDERS_QUERY_KEYS = {
  CREATE: 'create-reminder',
  CREATE_RECURRING: 'create-recurring-reminder',
  UPDATE_REMINDER: 'update-reminder',
  GET_REMINDER_DETAILS: 'get-reminder-details',
  DELETE_ONE_TIME_REMINDER: 'delete-one-time-reminder',
  UPDATE_RECURRING_REMINDER: 'update-recurring-reminder',
  DELETE_RECURRING_REMINDER: 'delete-recurring-reminder',
} as const;

export const PRIORITY_LISTS_QUERY_KEYS = {
  CREATE_PRIORITY_LIST: 'create-priority-list',
  EDIT_PRIORITY_LIST: 'edit-priority-list',
  GET_PRIORITY_LISTS: 'get-priority-lists',
  GET_PRIORITY_LIST_BY_ID: 'get-priority-list-by-id',
  ADD_TO_PRIORITY_LIST_BULK: 'add-to-priority-list-bulk',
  DELETE_PRIORITY_LIST: 'delete-priority-list',
  ADD_SHARE_PRIORITY_LIST_BULK: 'add-share-priority-list-bulk',
  REMOVE_SHARE_PRIORITY_LIST_BULK: 'remove-share-priority-list-bulk',
  GET_PRIORITY_LIST_STUDENTS: 'get-priority-list-students',
  REMOVE_STUDENT_FROM_PRIORITY_LIST: 'remove-student-from-priority-list',
};

export const DASHBOARD_QUERY_KEYS = {
  GET_DASHBOARD: 'get-dashboard',
  GET_DASHBOARD_NEW_STUDENTS: 'get-dashboard-new-students',
  GET_DASHBOARD_RED_STUDENTS: 'get-dashboard-red-students',
} as const;

export const NOTE_QUERY_KEYS = {
  GET_NOTES_LIST: 'get-notes-list',
  GET_NOTE_BY_ID: 'get-note-by-id',
  CREATE_NOTE: 'create-note',
  UPDATE_NOTE: 'update-note',
  DELETE_NOTE: 'delete-note',
} as const;

export const RICH_TEXT_IMAGE_QUERY_KEYS = {
  UPLOAD_RICH_TEXT_IMAGE: 'upload-rich-text-image',
} as const;

export const FILE_QUERY_KEYS = {
  GET_FILES_LIST: 'get-files-list',
  GET_FILE_BY_ID: 'get-file-by-id',
  UPLOAD_FILE: 'upload-file',
  UPDATE_FILE: 'update-file',
  DELETE_FILE: 'delete-file',
} as const;

export const ACTIVITY_QUERY_KEYS = {
  GET_LIST: 'get-activities-list',
  GET_CRISIS_LIST: 'get-crisis-activities-list',
  GET_ACTIVITY_TYPES_LIST: 'get-activity-types-list',
  GET_ACTIVITY_FILTER_OPTIONS: 'get-activity-filter-options',
  DELETE_ACTIVITY: 'delete-activity',
  DELETE_RECURRING_ACTIVITY: 'delete-recurring-activity',
  BULK_DELETE_ACTIVITY: 'bulk-delete-activity',
  GET_ACTIVITY_DETAILS: 'get-activity-details',
  GET_ACTIVITY_HISTORY: 'get-activity-history',
  UPDATE_ACTIVITY_TAGS: 'update-activity-tags',
  GET_ACTIVITY_CHOICES: 'get-activity-choices',
  UPDATE_ACTIVITY_DETAILS: 'update-activity-details',
  UPDATE_RECURRING_ACTIVITY_DETAILS: 'update-recurring-activity-details',
  CREATE_ACTIVITY: 'create-activity',
  CREATE_RECURRING_ACTIVITY: 'create-recurring-activity',
} as const;
