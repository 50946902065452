import { TakeActionType } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';

export const SUPPORT_BY_NEED_ACTIONS_FILTERS = {
  categories: [
    {
      label: 'Action Type',
      value: 'record_action_type',
      filters: Object.values(TakeActionType).map((type) => ({
        label: snakeCaseToCapitalized(type),
        value: type,
      })),
    },
  ],
};
