import { useCallback, useMemo } from 'react';
import { SAF_PROCESS_STEPS_VALUES, SAF_STATUS } from '@purple/shared-types';
import { cn } from '@purple/ui';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
import { useSafDetails } from '~/queries/saf/useSafDetails';
import { showErrorToast } from '~/shared/lib';
import { SafManagerStepItem } from '../SafManagerStepItem';

type TSafManagerActionSectionProperties = {
  safId?: string;
};

export const SafManagerActionSection = ({ safId }: TSafManagerActionSectionProperties) => {
  const { openModal } = useModal('take-action');
  const { openModal: openCloseSafModal } = useModal('close-saf');
  const { setSelectedStudentId, setSelectedSafId } = useTakeAction();

  const { data } = useSafDetails(safId as string);
  const { saf_processing_step, status, documented_actions, linked_student_details } = data || {};

  const isDocumentActionsStep = useMemo(
    () => saf_processing_step === SAF_PROCESS_STEPS_VALUES.DOCUMENT_ACTIONS,
    [saf_processing_step],
  );
  const isCloseSafStep = useMemo(
    () => saf_processing_step === SAF_PROCESS_STEPS_VALUES.CLOSE_SAF,
    [saf_processing_step],
  );
  const hasCloseSafStatus = useMemo(() => Boolean(status === SAF_STATUS.CLOSED), [status]);
  const hasLinkedActions = useMemo(() => Boolean(documented_actions), [documented_actions]);
  const actionButtonVariant = useMemo(() => {
    if (isDocumentActionsStep && !hasLinkedActions) {
      return 'primary';
    }
    return 'secondary';
  }, [isDocumentActionsStep, hasLinkedActions]);
  const actionCompletedStateColor = useMemo(() => {
    if (isDocumentActionsStep && !hasLinkedActions) {
      return 'bg-success-main';
    }
    return 'bg-other-green';
  }, [isDocumentActionsStep, hasLinkedActions]);

  const openTakeActionModal = useCallback(() => {
    if (linked_student_details && linked_student_details?.id && safId) {
      setSelectedStudentId(linked_student_details.id);
      setSelectedSafId(safId);
      openModal();
      return;
    }
    showErrorToast('System Message', 'Unable take action. Check if the student is linked and saf is valid');
  }, [linked_student_details, openModal, setSelectedStudentId, safId, setSelectedSafId]);

  return (
    <div className="flex flex-col">
      <div className={cn('px-4 py-3', isDocumentActionsStep && !hasLinkedActions && 'bg-warning-bg')}>
        <SafManagerStepItem
          stepTitle="Document Actions"
          stepDescription="Mark the actions you took to address the root cause of this SAF"
          activeStateIcon="lightning-bolt"
          isBlocked={!isDocumentActionsStep && !isCloseSafStep}
          isCompleted={hasLinkedActions}
          completedStateColor={actionCompletedStateColor}
          completedStateIcon={hasLinkedActions ? documented_actions : 'check'}
          isCurrent={isDocumentActionsStep || isCloseSafStep}
          buttonText="Take Action"
          buttonVariant={actionButtonVariant}
          onActionClick={openTakeActionModal}
          isTrigger={false}
        />
      </div>
      {!hasCloseSafStatus && (
        <div className={cn('px-4 py-3')}>
          <SafManagerStepItem
            stepTitle="Close SAF"
            stepDescription="Close the SAF once a plan of action is in place or the need has been addressed."
            activeStateIcon="X"
            activeStateColor="bg-brand-blue-700"
            isBlocked={!isDocumentActionsStep || !hasLinkedActions}
            isCompleted={false}
            isCurrent={isDocumentActionsStep && hasLinkedActions}
            buttonText="Close SAF"
            onActionClick={openCloseSafModal}
            isTrigger={false}
          />
        </div>
      )}
    </div>
  );
};
