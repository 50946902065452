import { z } from 'zod';

export const takeActionStakeholderMeetingSchema = z.object({
  title: z.string(),
  type: z
    .string({
      invalid_type_error: 'Type name must be a string.',
    })
    .trim()
    .min(1, {
      message: 'Type is required.',
    }),
  subType: z
    .string({
      invalid_type_error: 'Subtype must be a string.',
    })
    .trim(),
  sessionParticipants: z.array(z.string()).min(1, {
    message: 'At least one participant is required.',
  }),
  purpleParticipants: z.array(z.string()).min(1, {
    message: 'At least one participant is required.',
  }),
  date: z.date({
    message: 'Date is invalid.',
    required_error: 'Date is required.',
  }),
  duration: z
    .number({
      invalid_type_error: 'Duration must be a number.',
      message: 'Duration must be an integer.',
    })
    .int()
    .gt(0, {
      message: 'Duration must be greater than 0.',
    }),
  services: z.array(z.string()).min(1, {
    message: 'At least one service is required.',
  }),
  tags: z.array(z.string()),
  studentGoals: z
    .string({
      invalid_type_error: 'Student goals must be a string.',
    })
    .trim()
    .min(1, {
      message: 'Student goals are required.',
    }),
  sharedNotes: z
    .string({
      invalid_type_error: 'Shared notes must be a string.',
    })
    .trim(),
  myNotes: z.string().trim(),
});
