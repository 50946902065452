import { useMutation } from '@tanstack/react-query';
import { createActionGroupRecurrence } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useCreateRecurrence = () => {
  return useMutation({
    mutationKey: [ACTIONS_QUERY_KEYS.CREATE_ACTION_GROUP_RECURRENCE],
    mutationFn: createActionGroupRecurrence,
    onSuccess: () => {
      showSuccessToast('System message', 'The recurrence was created successfully');
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST] });
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create a recurrence. Check the provided information and try again');
    },
  });
};
