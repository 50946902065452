import React from 'react';
/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const GroupActionModalSkeleton = () => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center justify-between gap-4">
        <Skeleton className="h-10 w-9/12" />
        <div className="flex items-center gap-2">
          <Skeleton className="h-10 w-32" />
          <Skeleton className="h-10 w-32" />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Skeleton className="h-7 w-32" />
        <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
          <Skeleton className="h-5 w-36" />
          <Skeleton className="h-5 w-full" />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Skeleton className="h-7 w-32" />
        <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
          {Array.from({ length: 3 }).map((_, index) => (
            <React.Fragment key={index}>
              <Skeleton className="h-5 w-36" />
              <Skeleton className="h-5 w-full" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
