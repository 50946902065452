import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import {
  AppFilters,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { ACTIONS_SORT_SELECT_OPTIONS, SCHOOL_ACTION_FILTERS_OPTIONS } from '../constants';
import { StatisticCard } from '../StatisticCard';
import { ActionsHeaderSectionSkeleton } from './ActionsHeaderSectionSkeleton';
import type { TSchoolTotalActions } from '@purple/shared-types';

type TActionsHeaderSectionProperties = {
  actionsAmount?: TSchoolTotalActions;
  isLoading?: boolean;
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

export const ActionsHeaderSection = ({
  actionsAmount,
  isLoading,
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}: TActionsHeaderSectionProperties) => {
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onClearSearch, onSearchChange } = useSearch();

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full flex-col gap-4">
      {isLoading
        ? (
            <ActionsHeaderSectionSkeleton />
          )
        : (
            <div className="grid grid-flow-col gap-x-4">
              <StatisticCard title="General Actions" value={actionsAmount?.general} />
              <StatisticCard title="Targeted Actions" value={actionsAmount?.targeted} />
              <StatisticCard title="Attempted Actions" value={actionsAmount?.attempted} />
            </div>
          )}

      <div className="flex w-full justify-between gap-2">
        <div className="flex gap-4">
          <AppFilters config={SCHOOL_ACTION_FILTERS_OPTIONS} />
          <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
            <RadixSelectTrigger
              hasClearButton={hasSortValue}
              onClearCallback={onSortClear}
              triggerContainerClassName="w-[180px]"
            >
              <RadixSelectValue placeholder="Sort" />
            </RadixSelectTrigger>
            <RadixSelectContent className="w-max">
              {ACTIONS_SORT_SELECT_OPTIONS.map((option) => (
                <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                  {option.label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <RadixSelect onValueChange={onPeriodChange} defaultValue={PERIOD_SELECT_OPTIONS[0]?.value} value={timePeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(timePeriod)}
              onClearCallback={onPeriodClear}
              triggerContainerClassName="w-[180px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </div>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by name or owner"
          className="max-w-[300px]"
        />
      </div>
    </div>
  );
};
