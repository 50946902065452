import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TTakeActionType } from '@purple/shared-types';

export type TTakeActionState = {
  /**
   * Whether the modal is open or not for action type selection.
   * If null, the modal is closed.
   */
  actionTypeModalOpen: TTakeActionType | null;
  /**
   * The Id or list of Ids of the student(s) that the action will be taken for.
   */
  selectedStudentId?: string | string[] | null;
  /**
   * The Id of the selected SAF.
   */
  selectedSafId?: string | null;
  /**
   * The Id of the selected priority list.
   */
  selectedPriorityListId?: string | null;
};

const initialState: TTakeActionState = {
  actionTypeModalOpen: null,
  selectedStudentId: null,
  selectedSafId: null,
  selectedPriorityListId: null,
};

export const takeActionSlice = createSlice({
  name: AppReduxReducer.TAKE_ACTION,
  initialState,
  reducers: {
    toggleActionModal: (state, action: PayloadAction<TTakeActionState['actionTypeModalOpen']>) => {
      state.actionTypeModalOpen = action.payload;
    },
    setSelectedSafId: (state, action: PayloadAction<TTakeActionState['selectedSafId']>) => {
      state.selectedSafId = action.payload;
    },
    setSelectedStudentId: (state, action: PayloadAction<TTakeActionState['selectedStudentId']>) => {
      state.selectedStudentId = action.payload;
    },
    setSelectedPriorityListId: (state, action: PayloadAction<TTakeActionState['selectedPriorityListId']>) => {
      state.selectedPriorityListId = action.payload;
    },
  },
});

export const { toggleActionModal } = takeActionSlice.actions;
export const takeActionReducer = takeActionSlice.reducer;
