import { useMemo } from 'react';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { Button, SearchInput } from '@purple/ui';
import { AddStudentToPriorityListModal } from '~/components';
import { useModal } from '~/hooks';
import type { FC } from 'react';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

type ActionsRowProperties = {
  listId: string;
  selectedStudents: TSelectedStudent[];
  canEdit: boolean;
  onRemoveStudents: () => void;
};

const ActionsRow: FC<ActionsRowProperties> = ({ listId, selectedStudents, canEdit, onRemoveStudents }) => {
  const { search, onSearchChange, onClearSearch } = useSearch();

  const { isOpen: isAddModalOpen, openModal: openAddModal } = useModal('add-to-priority-list');

  const areSelectedStudentsExist = useMemo(() => selectedStudents.length > 0, [selectedStudents]);

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex w-[240px] items-center">
        <SearchInput placeholder="Search" value={search} onChange={onSearchChange} onClear={onClearSearch} />
      </div>
      {canEdit && (
        <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS]}>
          <div className="flex items-center gap-4">
            {areSelectedStudentsExist && (
              <Button variant="destructive_secondary" onClick={onRemoveStudents}>
                Remove Students
              </Button>
            )}
            <Button variant="secondary" iconLeft={<PurpleIcon name="plus" />} onClick={openAddModal}>
              Add Student
            </Button>
          </div>
        </Guard>
      )}
      {isAddModalOpen && <AddStudentToPriorityListModal listId={listId} />}
    </div>
  );
};

export { ActionsRow };
