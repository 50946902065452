import * as z from 'zod';
import { FormErrorMessages } from '../../constants/error-messages';
import { CONTAIN_DIGIT_REGEX, CONTAIN_LETTER_REGEX } from '../../regex';

export const passwordResetSchema = z
  .object({
    new_password1: z
      .string({
        invalid_type_error: FormErrorMessages.PASSWORD.INVALID_TYPE,
      })
      .min(1, {
        message: FormErrorMessages.PASSWORD.REQUIRED,
      })
      .min(8, {
        message: FormErrorMessages.PASSWORD.MIN_LENGTH_8,
      })
      .regex(CONTAIN_DIGIT_REGEX, {
        message: FormErrorMessages.PASSWORD.ONE_NUMBER,
      })
      .regex(CONTAIN_LETTER_REGEX, {
        message: FormErrorMessages.PASSWORD.ONE_LETTER,
      }),
    new_password2: z
      .string({
        invalid_type_error: FormErrorMessages.CONFIRM_PASSWORD.INVALID_TYPE,
      })
      .min(1, {
        message: FormErrorMessages.CONFIRM_PASSWORD.REQUIRED,
      }),
  })
  .refine((data) => data.new_password1 === data.new_password2, {
    message: FormErrorMessages.CONFIRM_PASSWORD.PASSWORD_MISMATCH,
    path: ['new_password2'],
  });
