import { convertBooleanToText, snakeCaseToCapitalized } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';

type TStudentCustomFieldsProperties = {
  data: Record<string, string | boolean | number>;
};

const StudentCustomFields = ({ data }: TStudentCustomFieldsProperties) => {
  const convertValue = (value: string | boolean | number) => {
    if (typeof value === 'boolean') {
      return convertBooleanToText(value);
    }
    return value;
  };

  return (
    <div className="flex flex-col gap-4">
      {Object.entries(data).map(([key, value]) => {
        const fieldLabel = snakeCaseToCapitalized(key);
        const fieldValue = convertValue(value);

        return (
          <InfoItem key={key} label={fieldLabel}>
            <InfoItemValue>{fieldValue}</InfoItemValue>
          </InfoItem>
        );
      })}
    </div>
  );
};

export { StudentCustomFields };
