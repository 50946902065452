import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppSelectedFiltersList, Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useSafList } from '~/queries/schools/saf';
import { useSafsFilterOptions } from '~/queries/schools/saf/useSafsFilterOptions';
import { schoolSafsColumns } from './safsColumnts';
import { SaFsHeaderSection, SafsHeaderSectionSkeleton } from './SaFsHeaderSection';

export const SaFs: React.FC = () => {
  const { schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data: filterOptions, isLoading: isFiltersLoading } = useSafsFilterOptions(schoolId as string);

  const { isLoading: isDataLoading, data } = useSafList({
    schoolId: schoolId as string,
    queryParams: {
      search,
      limit,
      offset,
      ordering: sort,
      needs: searchParameters.get('needs'),
      priority: searchParameters.get('priority'),
      time_period: timePeriod,
    },
  });

  const isDataNotExist = useMemo(
    () => (!data?.results || data.results.length === 0) && (!isDataLoading || !isFiltersLoading),
    [data?.results, isDataLoading, isFiltersLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!schoolId) {
    return null;
  }

  return (
    <>
      <div className="flex pb-4 pl-4 pr-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Total SAFs
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row px-4">
          {isFiltersLoading || !filterOptions
            ? (
                <SafsHeaderSectionSkeleton />
              )
            : (
                <div className="flex w-full flex-col gap-4">
                  <SaFsHeaderSection
                    filtersConfig={convertToFilterConfig(filterOptions)}
                    timePeriod={timePeriod}
                    onPeriodChange={onPeriodChange}
                    onPeriodClear={onPeriodClear}
                  />
                  <AppSelectedFiltersList config={convertToFilterConfig(filterOptions)} maxDisplayedFilters={8} />
                </div>
              )}
        </div>

        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={schoolSafsColumns}
              data={data?.results || []}
              isLoading={isDataLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No SAFs found"
              emptyStateMessage="There are no SAFs to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {!isDataNotExist && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={data?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
