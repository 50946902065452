import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { useActionGroups } from '~/queries';
import type React from 'react';
import type { TTakeActionType } from '@purple/shared-types';

type TGroupComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * The type of action that is being taken to filter the groups.
   */
  actionType: TTakeActionType;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
};

export const GroupComboBox: React.FC<TGroupComboBoxProperties> = (props) => {
  const { value, actionType, isError = false, onChange } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: groupsData, isFetching } = useActionGroups({
    search: debouncedSearchValue,
    record_action_type: actionType,
  });

  const groups = useMemo(() => groupsData?.results ?? [], [groupsData?.results]);
  const selectedGroup = useMemo(() => groups.find((group) => group.id === value) ?? null, [value, groups]);

  const selectValueHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={isError} placeholder="Select group" selectedLabel={selectedGroup?.name} />
      <ComboBoxContent
        loading={isFetching}
        shouldFilter={false}
        searchPlaceholder="Search group..."
        emptyContent="Group not found."
        onSearchChange={debouncedSearch}
      >
        {groups.map(({ id, name }) => (
          <ComboBoxItem key={id} value={id} selected={value === id} onSelect={selectValueHandler}>
            {name}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
