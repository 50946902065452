import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const safLinkStudent = async ({ safId, student }: { safId: string; student: string }) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<{ id: string; student: string }>(
    replaceUrlParameters(SAF_ENDPOINTS.LINK_STUDENT, safId),
    { student },
  );
  return response.data;
};
