import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  AppFilters,
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  Text,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { useExportSupportByProgramFile } from '~/queries/students/support-by-program';
import type { TFiltersConfig } from '@purple/ui';

type TSupportByProgramHeaderSectionProperties = {
  filtersConfig: TFiltersConfig;
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

export const SupportByProgramHeaderSection = ({
  filtersConfig,
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}: TSupportByProgramHeaderSectionProperties) => {
  const [searchParameters] = useSearchParams();
  const { studentId } = useParams();
  const [fetchExportActionsFile, setFetchExportActionsFile] = useState(false);

  const { onClearSearch, onSearchChange, search } = useSearch();

  const { isLoading } = useExportSupportByProgramFile({
    studentId: studentId as string,
    queryParams: {
      type: searchParameters.get('type'),
      search,
      service_providers: searchParameters.get('service_providers'),
      time_period: timePeriod,
    },
    enabled: fetchExportActionsFile,
    onSuccess: () => setFetchExportActionsFile(false),
  });

  return (
    <div className="flex flex-col gap-4 px-4 pt-6">
      <Text variant="size-16" type="body-600">
        Support by Program
      </Text>
      <div className="flex items-center justify-between">
        <div className="flex gap-4">
          <AppFilters config={filtersConfig} />
          <RadixSelect onValueChange={onPeriodChange} defaultValue={timePeriod} value={timePeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(timePeriod)}
              onClearCallback={onPeriodClear}
              triggerContainerClassName="w-[200px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search"
            className="max-w-[300px]"
          />
        </div>
        <Button
          iconLeft={<PurpleIcon name="download" />}
          onClick={() => setFetchExportActionsFile(true)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Export
        </Button>
      </div>
    </div>
  );
};
