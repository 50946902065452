import { type FC, useMemo, useState } from 'react';
import { Button, InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import type { IStudentSchoolHistoryItem } from '@purple/shared-types';

const SHOW_SCHOOL_LIMIT = 1;

type StudentSchoolHistoryProperties = {
  data: IStudentSchoolHistoryItem[];
};

const StudentSchoolHistory: FC<StudentSchoolHistoryProperties> = ({ data }) => {
  const [viewMore, setViewMore] = useState(false);

  const visibleSchools = useMemo(() => {
    return viewMore ? data : data.slice(0, SHOW_SCHOOL_LIMIT);
  }, [data, viewMore]);

  if (data.length === 0) {
    return (
      <div className="flex flex-col gap-4">
        <InfoItem label="Previous School">
          <InfoItemValue>-</InfoItemValue>
        </InfoItem>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {visibleSchools.map((school) => {
        const schoolUrl = AppRoutes.App.School.Root.makePath(school?.id, 'overview');
        return (
          <InfoItem key={school.id} label="Previous School">
            <InfoItemValue to={schoolUrl}>{school.name}</InfoItemValue>
          </InfoItem>
        );
      })}
      {data.length > SHOW_SCHOOL_LIMIT && (
        <div className="flex justify-start">
          <Button size="link" variant="link" onClick={() => setViewMore((toggle) => !toggle)}>
            {viewMore ? 'View less' : 'View more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export { StudentSchoolHistory };
