import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginate } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import { useMyGroupActions } from '~/queries';
import { myGroupActionColumns } from './myGroupActionColumns';
import { MyGroupActionsHeader } from './MyGroupActionsHeader';
import type { TMyGroupAction, TTakeActionType } from '@purple/shared-types';

export const MyGroupActions: React.FC = () => {
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const search = searchParameters.get(SEARCH_QUERY_NAME);

  const { limit, offset, onLimitChange, onPageChange, page } = usePaginate();

  const { data, isLoading } = useMyGroupActions({
    limit,
    offset,
    search,
    ordering: searchParameters.get(SORT_QUERY_NAME) as string,
    record_action_type: searchParameters.get('record_action_type') as TTakeActionType,
  });

  const isDataNotExist = useMemo(
    () => !data?.results || (data.results.length === 0 && !isLoading),
    [data?.results, isLoading],
  );

  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);

  const rowClickHandler = useCallback(
    (rowData: TMyGroupAction) => {
      navigate(AppRoutes.App.Actions.GroupDetails.makePath(rowData.id));
    },
    [navigate],
  );

  return (
    <section className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
      <MyGroupActionsHeader />
      <div className="flex w-full flex-col">
        <DataTable
          columns={myGroupActionColumns}
          data={data?.results || []}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          classNames={{ wrapper: 'm-0', root: 'table-fixed w-full' }}
          emptyStateTitle="My Group Actions not found"
          emptyStateMessage="There are no group actions to display. If it an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
          onRowClick={rowClickHandler}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </section>
  );
};
