import { useQueryClient } from '@tanstack/react-query';
import { formatDate, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { OcDayTooltip } from './OcDayTooltip';
import type { TSafDetailsDto } from '@purple/shared-types';

type TSafDetailsProperties = {
  safId?: string | null;
};

export const SafDetails = ({ safId }: TSafDetailsProperties) => {
  const queryClient = useQueryClient();

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { saf_details } = safDetailsData || {};

  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Perceived Need</DescriptionTerm>
        <DescriptionDetails>{saf_details?.saf_need ?? '—'}</DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Submitted Date</DescriptionTerm>
        <DescriptionDetails>
          {saf_details && saf_details.created_at ? formatDate(saf_details.created_at) : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Prioritized Date</DescriptionTerm>
        <DescriptionDetails>
          {saf_details && saf_details.priority_assigned_at
            ? (
                formatDate(saf_details.priority_assigned_at)
              )
            : (
                <span className="text-error-main">Required</span>
              )}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm className="flex items-center gap-1">
          OC Day
          <OcDayTooltip />
        </DescriptionTerm>
        <DescriptionDetails>{saf_details?.time_until_closed_days ?? '—'}</DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>SAF Owner</DescriptionTerm>
        <DescriptionDetails className="capitalize">{saf_details?.saf_owner ?? '—'}</DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
};
