import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TActionPurpleUserParticipant } from '@purple/shared-types';

type TUsePurpleUserParticipantsColumns = (options?: {
  allowedToDelete?: boolean;
  onDelete?: (userId: string) => void;
}) => ColumnDef<TActionPurpleUserParticipant>[];

export const usePurpleUserParticipantsColumns: TUsePurpleUserParticipantsColumns = (options) => {
  const { allowedToDelete = false, onDelete } = options || {};

  return [
    {
      accessorKey: 'full_name',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Participant Name
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-14" type="body-500" className="max-w-[200px] truncate capitalize text-grey-950">
          {row.original.full_name}
        </Text>
      ),
    },
    {
      accessorKey: 'title',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Participant Title
        </Text>
      ),
      cell: ({ row }) => (
        <Text variant="size-14" type="body-500" className="max-w-[200px] truncate capitalize text-grey-950">
          {row.original.title ?? '-'}
        </Text>
      ),
    },
    {
      accessorKey: 'delete',
      size: 32,
      header: () => null,
      cell: ({ row }) =>
        allowedToDelete && (
          <Button
            type="button"
            variant="destructive_secondary"
            aria-label="Remove participant"
            iconLeft={<PurpleIcon name="trash" />}
            size="icon_32"
            className="border-none"
            onClick={() => onDelete?.(row.original.id)}
          />
        ),
    },
  ];
};
