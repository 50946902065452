import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IPriorityListStudentsRequest, IStudentDataResponse } from '@purple/shared-types';

export const getPriorityListStudents = async ({
  id,
  pagination: { limit, offset },
  search,
}: IPriorityListStudentsRequest) => {
  const axiosInstance = getAxiosInstance();

  const path = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.GET_PRIORITY_LIST_STUDENTS, id);
  const url = `${path}?limit=${limit}&offset=${offset}&search=${search}`;

  const response = await axiosInstance.get<IStudentDataResponse>(url);
  return response.data;
};
