import { Skeleton } from '@purple/ui';

const ActionsHeaderSectionSkeleton = () => {
  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex gap-4">
        <Skeleton className="h-10 w-[164px]" />
        <Skeleton className="h-10 w-[180px]" />
        <Skeleton className="h-10 w-[180px]" />
      </div>
      <Skeleton className="h-10 w-[340px]" />
    </div>
  );
};

export { ActionsHeaderSectionSkeleton };
