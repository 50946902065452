import { useMemo } from 'react';
import { cn, Heading, NumberBadge, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@purple/ui';
import { getNumberStatusBasedOnPercentage } from '../helpers';
import { StudentsMetricsSkeleton } from './StudentsMetricsSkeleton';
import type React from 'react';
import type { TSchoolMonthMetrics } from '@purple/shared-types';

type TSchoolMetricsTableData = Record<
  'atRisk' | 'homeless' | 'refugee' | 'immigrant',
  { label: string; data: { value: number | string; month: string }[] }
>;

type TStudentsMetricsProperties = {
  /**
   * School metrics data over the selected year.
   */
  data?: Record<string, TSchoolMonthMetrics>;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const StudentsMetrics: React.FC<TStudentsMetricsProperties> = (props) => {
  const { data, isLoading = false } = props;

  const tableHeader = useMemo(() => ['Criteria', ...Object.keys(data ?? {}).map((month) => month)], [data]);
  const tableData = useMemo(
    () =>
      Object.entries(data ?? {}).reduce<TSchoolMetricsTableData>(
        (accumulator, [month, value]) => ({
          ...accumulator,
          atRisk: {
            ...accumulator.atRisk,
            data: [
              ...accumulator.atRisk.data,
              { value: value.at_risk_percentage === null ? '-' : value.at_risk_percentage / 100, month },
            ],
          },
          homeless: {
            ...accumulator.homeless,
            data: [
              ...accumulator.homeless.data,
              {
                value: value.homeless_percentage === null ? '-' : value.homeless_percentage / 100,
                month,
              },
            ],
          },
          refugee: {
            ...accumulator.refugee,
            data: [
              ...accumulator.refugee.data,
              {
                value: value.refugee_percentage === null ? '-' : value.refugee_percentage / 100,
                month,
              },
            ],
          },
          immigrant: {
            ...accumulator.immigrant,
            data: [
              ...accumulator.immigrant.data,
              {
                value: value.immigrant_percentage === null ? '-' : value.immigrant_percentage / 100,
                month,
              },
            ],
          },
        }),
        {
          atRisk: { label: 'At Risk', data: [] },
          homeless: { label: 'Homeless', data: [] },
          refugee: { label: 'Refugee', data: [] },
          immigrant: { label: 'Immigrant', data: [] },
        },
      ),
    [data],
  );

  if (isLoading) {
    return <StudentsMetricsSkeleton />;
  }

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200">
      <Heading tag="h2" variant="size-18" type="heading-600" className="mt-6 px-4 text-base text-grey-title">
        Students Metrics
      </Heading>
      <Table wrapperClassName="overflow-x-auto">
        <TableHeader>
          <TableRow className="border-grey-200">
            {tableHeader.map((header, index) => (
              <TableHead
                key={header}
                className={cn('px-5 py-4 text-center text-xs font-semibold uppercase text-grey-600', {
                  'min-w-40 px-5 py-4 text-left capitalize': index === 0,
                })}
              >
                {header}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow className="border-grey-200 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.atRisk.label}
            </TableCell>
            {tableData.atRisk.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={getNumberStatusBasedOnPercentage(value, true)}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
          <TableRow className="border-grey-200 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.homeless.label}
            </TableCell>
            {tableData.homeless.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={getNumberStatusBasedOnPercentage(value, true)}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
          <TableRow className="border-grey-200 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.refugee.label}
            </TableCell>
            {tableData.refugee.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={typeof value === 'string' ? 'default' : 'success-light'}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
          <TableRow className="border-0 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.immigrant.label}
            </TableCell>
            {tableData.immigrant.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={typeof value === 'string' ? 'default' : 'success-light'}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
