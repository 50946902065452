import { useTranslation } from 'react-i18next';
import { useMask } from '@react-input/mask';
import { motion } from 'framer-motion';
import { SafSubmitterType } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import { Checkbox, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@purple/ui';
import { SAF_PHONE_MASK } from '~/constants/saf';
// types
import { useArabicLanguage } from '../hooks';
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { safFormSchema } from './schema';

type TInfoFormProperties = {
  /**
   * The form object that contains the form state and methods.
   */
  form: UseFormReturn<z.infer<typeof safFormSchema>>;
  /**
   * The transition delta that determines the direction of the form transition.
   */
  transitionDelta: number;
};

export const InfoForm: React.FC<TInfoFormProperties> = (props) => {
  const { form, transitionDelta } = props;

  const inputReference = useMask({
    mask: SAF_PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });
  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const isForMySelf = form.watch('myself');
  const isAnonymous = form.watch('anonymous');
  const submitter = form.watch('submitter');

  return (
    <motion.div
      initial={{ x: transitionDelta >= 0 ? '50%' : '-50%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="grid w-full grid-cols-2 gap-x-4 gap-y-6"
    >
      <div className="col-span-2 grid w-full grid-cols-1 gap-x-4 gap-y-3">
        {submitter === SafSubmitterType.STUDENT && (
          <FormField
            control={form.control}
            name="myself"
            render={({ field }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <Checkbox
                    {...field}
                    value={field.value.toString()}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    disabled={isAnonymous}
                  />
                </FormControl>
                <FormLabel>{t('form.info.for-myself')}</FormLabel>
              </FormItem>
            )}
          />
        )}

        {!isForMySelf && (
          <FormField
            control={form.control}
            name="anonymous"
            render={({ field }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <Checkbox
                    {...field}
                    value={field.value.toString()}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>{t('form.info.anonymous')}</FormLabel>
              </FormItem>
            )}
          />
        )}
      </div>

      {!isAnonymous && (
        <>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.info.first-name.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.firstName}
                    placeholder={t('form.info.first-name.placeholder')}
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.info.last-name.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.lastName}
                    placeholder={t('form.info.last-name.placeholder')}
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={submitter === SafSubmitterType.PROVIDER || submitter === SafSubmitterType.TEACHER}>
                  {t('form.info.email.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.email}
                    placeholder={
                      submitter === SafSubmitterType.STUDENT || submitter === SafSubmitterType.PARENT
                        ? t('form.info.email.placeholder-2')
                        : t('form.info.email.placeholder')
                    }
                    type="email"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel required={submitter === SafSubmitterType.PROVIDER || submitter === SafSubmitterType.TEACHER}>
                  {t('form.info.phone.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    ref={mergeReferences([field.ref, inputReference])}
                    isError={!!form.formState.errors.phone}
                    placeholder={SAF_PHONE_MASK}
                    type="tel"
                    dir={isArabic ? 'rtl' : 'ltr'}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {(submitter === SafSubmitterType.TEACHER || submitter === SafSubmitterType.PROVIDER) && (
            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel required>{t('form.info.organization.label')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      isError={!!form.formState.errors.organization}
                      placeholder={t('form.info.organization.placeholder')}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </>
      )}
    </motion.div>
  );
};
