import { STUDENT_MEETING_ATTENDANCE_FILTERS } from '@purple/shared-types';

export const MEETING_FILTERS_TABS = [
  {
    label: 'Service Provider name',
    value: STUDENT_MEETING_ATTENDANCE_FILTERS.SERVICE_PROVIDERS,
  },
] as const;

export const SORT_SELECT_OPTIONS = [
  {
    label: 'Name (ASC)',
    value: 'meeting_record_name',
  },
  {
    label: 'Name (DESC)',
    value: '-meeting_record_name',
  },
  {
    label: 'Date (ASC)',
    value: 'attendance_date',
  },
  {
    label: 'Date (DESC)',
    value: '-attendance_date',
  },
  {
    label: 'Group Service (ASC)',
    value: 'service_provider',
  },
  {
    label: 'Group Service (DESC)',
    value: '-service_provider',
  },
] as const;
