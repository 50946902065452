import { APP_PERMISSIONS } from '@purple/permissions';
import { ActivityType } from '@purple/shared-types';

const COMMON_ACTIVITY_PERMISSIONS = [APP_PERMISSIONS.CAN_ACCESS_CREATE_COMMUNITY_ACTIVITY, APP_PERMISSIONS.CAN_ACCESS_EDIT_DELETE_ACTIVITY, APP_PERMISSIONS.CAN_ACCESS_PRINT_ACTIVITY, APP_PERMISSIONS.CAN_ACCESS_ACTIVITY_VISUALS, APP_PERMISSIONS.CAN_ACCESS_UPLOAD_FILE_TO_ACTIVITY];

export const ReadableActivityType = {
  [ActivityType.CRISIS_CALL_TRACKER]: 'Crisis Call Tracker',
  [ActivityType.CAMPUS_PROPOSAL_MEETING]: 'Campus Proposal Meeting',
  [ActivityType.CLASSROOM_GUIDANCE_LESSON]: 'Classroom Guidance Lesson',
  [ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT]: 'HISD Souper Food Pantry Event',
  [ActivityType.COMMUNITY]: 'Community Activity',
  [ActivityType.NON_COUNSELING_RELATED]: 'Non-Counseling related Activity/Duty',
  [ActivityType.COUNSELING_RELATED]: 'Counseling Related Activity/Duty',
  [ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT]: 'Crisis Response & Behavioral Support Activity',
} as const;

export const CREATE_ACTIVITY_LIST = [
  {
    type: ActivityType.COUNSELING_RELATED,
    label: ReadableActivityType[ActivityType.COUNSELING_RELATED],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.NON_COUNSELING_RELATED,
    label: ReadableActivityType[ActivityType.NON_COUNSELING_RELATED],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.COMMUNITY,
    label: ReadableActivityType[ActivityType.COMMUNITY],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT,
    label: ReadableActivityType[ActivityType.HISD_SOUPER_FOOD_PARTY_EVENT],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.CLASSROOM_GUIDANCE_LESSON,
    label: ReadableActivityType[ActivityType.CLASSROOM_GUIDANCE_LESSON],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.CAMPUS_PROPOSAL_MEETING,
    label: ReadableActivityType[ActivityType.CAMPUS_PROPOSAL_MEETING],
    permissions: COMMON_ACTIVITY_PERMISSIONS,
  },
  {
    type: ActivityType.CRISIS_CALL_TRACKER,
    label: ReadableActivityType[ActivityType.CRISIS_CALL_TRACKER],
    permissions: [APP_PERMISSIONS.CAN_ACCESS_CRISIS_TEAM_VIEW, ...COMMON_ACTIVITY_PERMISSIONS],
  },
  {
    type: ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT,
    label: ReadableActivityType[ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT],
    permissions: [APP_PERMISSIONS.CAN_ACCESS_CRISIS_TEAM_VIEW, ...COMMON_ACTIVITY_PERMISSIONS],
  },
] as const;

export const ALLOWED_TOPIC_TYPES = new Set(['training']);
