import { useTranslation } from 'react-i18next';
import { SafSubmitterType, type TSafSubmitterType } from '@purple/shared-types';
import { Text } from '@purple/ui';
import type React from 'react';

type TDetailsDescriptionProperties = {
  /**
   * The type of submitter that is submitting the form.
   */
  submitterType: TSafSubmitterType;
  /**
   * Whether the submitter is a student itself.
   */
  isSelfStudent: boolean;
};

export const DetailsDescription: React.FC<TDetailsDescriptionProperties> = (props) => {
  const { submitterType, isSelfStudent } = props;

  const { t } = useTranslation('saf');

  if (submitterType === SafSubmitterType.STUDENT && isSelfStudent) {
    return (
      <>
        <Text variant="size-16" type="body-500" className="text-grey-950">
          {t('form.observation.details.student-list.title')}
        </Text>
        <ul className="flex list-inside list-disc flex-col [&>li]:text-sm [&>li]:font-medium [&>li]:text-grey-600">
          <li>{t('form.observation.details.student-list.item-1')}</li>
          <li>{t('form.observation.details.student-list.item-2')}</li>
          <li>{t('form.observation.details.student-list.item-3')}</li>
        </ul>
      </>
    );
  }

  if ((submitterType === SafSubmitterType.STUDENT && !isSelfStudent) || submitterType === SafSubmitterType.PARENT) {
    return (
      <>
        <Text variant="size-16" type="body-500" className="text-grey-950">
          {t('form.observation.details.parent-list.title')}
        </Text>
        <ul className="flex list-inside list-disc flex-col [&>li]:text-sm [&>li]:font-medium [&>li]:text-grey-600">
          <li>{t('form.observation.details.parent-list.item-1')}</li>
          <li>{t('form.observation.details.parent-list.item-2')}</li>
          <li>{t('form.observation.details.parent-list.item-3')}</li>
        </ul>
      </>
    );
  }

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <Text variant="size-16" type="body-500" className="text-grey-950">
          {t('form.observation.details.other-list.title')}
        </Text>
        <Text variant="size-14" type="body-400" className="text-grey-950">
          {t('form.observation.details.other-list.label')}
        </Text>
      </div>
      <ul className="flex list-inside list-disc flex-col [&>li]:text-sm [&>li]:font-medium [&>li]:text-grey-600">
        <li>{t('form.observation.details.other-list.item-1')}</li>
        <li>{t('form.observation.details.other-list.item-2')}</li>
        <li>{t('form.observation.details.other-list.item-3')}</li>
        <li>{t('form.observation.details.other-list.item-4')}</li>
      </ul>
    </>
  );
};
