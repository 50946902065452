import { useNavigate, useParams } from 'react-router-dom';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { AccessDenied } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { ActivityDetails } from '~/modules/Community';

export const CommunityActivityDetailsPage: React.FC = () => {
  const { activityId } = useParams();
  const navigate = useNavigate();

  if (!activityId) {
    navigate(AppRoutes.System.NotFound.Root.path, { replace: true });
  }

  return (
    <div className="flex w-full flex-col gap-6">
      <LinkButton to={AppRoutes.App.Community.Root.path} iconName="chevron-left">
        Back to Community Activities
      </LinkButton>
      <Guard
        requiredPermissions={[
          APP_PERMISSIONS.CAN_ACCESS_EDIT_DELETE_ACTIVITY,
          APP_PERMISSIONS.CAN_ACCESS_PRINT_ACTIVITY,
          APP_PERMISSIONS.CAN_ACCESS_ACTIVITY_VISUALS,
          APP_PERMISSIONS.CAN_ACCESS_UPLOAD_FILE_TO_ACTIVITY,
        ]}
        fallback={(
          <AccessDenied
            accessDeniedMessage="You do not have permission to view activity details."
            className="h-full w-full rounded-lg border border-grey-200 bg-white p-6 py-24"
          />
        )}
      >
        <ActivityDetails />
      </Guard>
    </div>
  );
};
