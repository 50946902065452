import { LocalStorageKey } from '@purple/shared-utils';
import { useLogoutMutation } from '~/queries';

export const useLogout = () => {
  const { mutate, isPending } = useLogoutMutation();

  const logout = () => {
    const refresh = localStorage.getItem(LocalStorageKey.Auth.Token.RefreshToken);
    if (!refresh) return;
    mutate({ refresh });
  };

  return { logout, isPending };
};
