import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import type { TActivityListQueryParameters, TActivityListResponse } from '@purple/shared-types';

export const getActivities = async (parameters?: TActivityListQueryParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TActivityListResponse>(ACTIVITY_ENDPOINTS.ROOT, {
    params: parameters,
  });

  return response.data;
};
