import { Skeleton } from '@purple/ui';
import { SafTotalCard } from '../SafTotalCard';

type TSafListTotalCardsProperties = {
  totalNew: string | number;
  totalPrioritized: string | number;
  totalOnHold: string | number;
  totalClosed: string | number;
  isLoading: boolean;
};

const SafListTotalCards = ({
  totalNew,
  totalPrioritized,
  totalOnHold,
  totalClosed,
  isLoading,
}: TSafListTotalCardsProperties) => {
  if (isLoading) {
    return (
      <div className="grid w-full grid-cols-4 gap-4">
        <Skeleton className="h-[94px] w-full" />
        <Skeleton className="h-[94px] w-full" />
        <Skeleton className="h-[94px] w-full" />
        <Skeleton className="h-[94px] w-full" />
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-4 gap-4">
      <SafTotalCard
        title="New"
        value={totalNew}
        icon="new-cirlce"
        iconClassName="bg-brand-blue-100 text-brand-blue-700"
      />
      <SafTotalCard
        title="Prioritized"
        value={totalPrioritized}
        icon="checkmark-circle"
        iconClassName="bg-success-bg text-success-main"
      />
      <SafTotalCard title="On Hold" value={totalOnHold} icon="pause" iconClassName="bg-warning-bg text-warning-main" />
      <SafTotalCard title="Closed" value={totalClosed} icon="X-Circle" iconClassName="bg-grey-100 text-grey-600" />
    </div>
  );
};

export { SafListTotalCards };
