import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAttendanceList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useAttendanceList = ({ studentId, year }: { studentId: string; year?: string }) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_ATTENDANCE_LIST, { studentId, year }],
    queryFn: () => getAttendanceList({ studentId, year }),
    enabled: !!studentId,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to load attendance data');
    }
  }, [isError]);

  return { isError, ...rest };
};
