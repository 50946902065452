import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getNoteList } from '@purple/shared-services';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TNoteQueryParameters } from '@purple/shared-types';

export const useNotesList = (parameters: TNoteQueryParameters) => {
  const studentNotesListQuery = useQuery({
    queryKey: [NOTE_QUERY_KEYS.GET_NOTES_LIST, parameters],
    queryFn: () => getNoteList(parameters),
    enabled: !!parameters.student || !!parameters.action_group || !!parameters.action,
  });

  useEffect(() => {
    if (studentNotesListQuery.isError) {
      showErrorToast('System Error', 'Unable to fetch notes');
    }
  }, [studentNotesListQuery.isError]);

  return studentNotesListQuery;
};
