import type { NumberBadge } from '@purple/ui';

/**
 * Returns the number status variant based on the given percentage.
 * @param percentage - The percentage value.
 * @param reversed - Whether to reverse the variant or not. Default is false.
 * @returns The variant of the NumberBadge component based on the percentage.
 */
export const getNumberStatusBasedOnPercentage = (
  percentage: number | string,
  reversed = false,
): React.ComponentProps<typeof NumberBadge>['variant'] => {
  if (typeof percentage === 'string') {
    return 'default';
  }

  if (reversed) {
    if (percentage >= 0 && percentage < 0.05) {
      return 'success';
    }

    if (percentage >= 0.05 && percentage < 0.1) {
      return 'success-light';
    }

    if (percentage >= 0.1 && percentage < 0.3) {
      return 'warning-light';
    }

    if (percentage >= 0.3 && percentage < 0.5) {
      return 'warning-dark';
    }

    if (percentage >= 0.5) {
      return 'danger';
    }
  }

  if (percentage >= 0 && percentage < 0.5) {
    return 'danger';
  }

  if (percentage >= 0.5 && percentage < 0.7) {
    return 'warning-dark';
  }

  if (percentage >= 0.7 && percentage < 0.9) {
    return 'warning-light';
  }

  if (percentage >= 0.9 && percentage < 0.95) {
    return 'success-light';
  }

  if (percentage >= 0.95) {
    return 'success';
  }

  return 'default';
};
