import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { Button, Form, FormControl, FormField, FormItem, FormMessage, Input } from '@purple/ui';
import { changeStudentIdSchema } from '../../schema';
import type * as z from 'zod';
import type { IEditionSaveStudentByIdFormEntity, IStudentByIdFormEntity } from '../types';

type TStudentIdColumnEditProperties = React.PropsWithChildren<{
  student: IStudentByIdFormEntity;
  onSave: (value: IEditionSaveStudentByIdFormEntity) => void;
}>;

const StudentIdColumnEdit = ({ student, onSave }: TStudentIdColumnEditProperties) => {
  const { id } = student;
  const formId = useId();

  const form = useForm<z.infer<typeof changeStudentIdSchema>>({
    resolver: zodResolver(changeStudentIdSchema),
    mode: 'onChange',
    defaultValues: {
      studentId: id,
    },
  });

  const editStudentIdHandler = (data: z.infer<typeof changeStudentIdSchema>) => {
    onSave({
      previousId: id,
      newId: data.studentId,
    });
  };

  return (
    <Form providerProps={form} id={formId} className="flex w-full" onSubmit={form.handleSubmit(editStudentIdHandler)}>
      <div className="relative">
        <FormField
          control={form.control}
          name="studentId"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.studentId} type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="absolute right-0 mt-1 flex gap-1">
          <Button
            variant="primary"
            size="icon_32"
            iconLeft={<PurpleIcon name="check" />}
            className="shadow-custom-medium"
          />
          <Button
            variant="secondary"
            size="icon_32"
            iconLeft={<PurpleIcon name="X" />}
            className="border-white bg-white shadow-custom-medium"
          />
        </div>
      </div>
    </Form>
  );
};

export { StudentIdColumnEdit };
