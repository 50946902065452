import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useAddStudentsToList } from '~/queries';
import { showSuccessToast } from '~/shared/lib';
import { DefaultStudentsIdForm } from './DefaultStudentsIdForm';
import { ManualStudentsIdForm } from './ManualStudentsIdForm';
import { ADD_STUDENTS_BY_ID_MODE, addStudentsByIdSchema } from './schema';
import type * as z from 'zod';
import type { StudentListByIDsResponseBody } from '@purple/shared-types';
import type { IEditionSaveStudentByIdFormEntity, IStudentByIdFormEntity } from './ManualStudentsIdForm/types';

type TAddStudentsByIdModalProperties = {
  onSuccess: (newStudentsIds: { id: string; name: string }[]) => void;
};

export const AddStudentsByIdModal = ({ onSuccess }: TAddStudentsByIdModalProperties) => {
  const [manualStudents, setManualStudents] = useState<IStudentByIdFormEntity[]>([]);

  const { isOpen, toggleModal, closeModal } = useModal('add-students-by-id');

  const { mutate: addStudentsByIds, isPending } = useAddStudentsToList();

  const form = useForm<z.infer<typeof addStudentsByIdSchema>>({
    resolver: zodResolver(addStudentsByIdSchema),
    mode: 'onChange',
    defaultValues: {
      mode: ADD_STUDENTS_BY_ID_MODE.DEFAULT,
      // NOTE: studentIds - this is the input field where user will enter student ids (textarea)
      studentIds: [],
      // NOTE: manualStudentIds - this is the list of students when some ids were not found in the database
      manualStudentIds: [],
    },
  });

  const formMode = form.watch('mode');
  const formStudentIds = form.watch('studentIds');
  const formManualStudentIds = form.watch('manualStudentIds');

  const onAddSuccess = (response: StudentListByIDsResponseBody) => {
    const { missing_student_ids, students } = response;

    if (missing_student_ids.length === 0) {
      onSuccess(
        students.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.middle_name} ${student.last_name}`,
        })),
      );
      showSuccessToast('System Message', 'Students were successfully selected');
      closeModal();
    } else {
      form.setValue('mode', ADD_STUDENTS_BY_ID_MODE.MANUAL);
      const correctStudents: IStudentByIdFormEntity[] = students.map((student) => ({
        id: student.student_id,
        fullName: `${student.first_name} ${student.middle_name} ${student.last_name}`,
        isCorrect: true,
      }));
      const incorrectStudents: IStudentByIdFormEntity[] = missing_student_ids.map((id) => ({
        id,
        fullName: '-',
        isCorrect: false,
      }));
      setManualStudents([...correctStudents, ...incorrectStudents]);
    }
  };

  const addStudentsByIdHandler = (data: z.infer<typeof addStudentsByIdSchema>) => {
    if (formMode === ADD_STUDENTS_BY_ID_MODE.DEFAULT) {
      const uniqueStudentsIds = [...new Set(data.studentIds)];
      addStudentsByIds(uniqueStudentsIds, {
        onSuccess: onAddSuccess,
      });
    }
    if (formMode === ADD_STUDENTS_BY_ID_MODE.MANUAL) {
      const uniqueStudentsIds = [...new Set(data.manualStudentIds)];

      addStudentsByIds(uniqueStudentsIds, {
        onSuccess: onAddSuccess,
      });
    }
  };

  const manualIdChangeHandler = (value: IEditionSaveStudentByIdFormEntity) => {
    const updatedManualStudents = manualStudents.map((student) => {
      if (student.id === value.previousId) {
        return {
          ...student,
          id: value.newId,
          isCorrect: true,
        };
      }
      return student;
    });

    setManualStudents(updatedManualStudents);

    const manualStudentIds = updatedManualStudents.map((student) => student.id);
    form.setValue('manualStudentIds', manualStudentIds);
  };

  const manualIdRemoveHandler = (id: string) => {
    const updatedManualStudents = manualStudents.filter((student) => student.id !== id);

    if (updatedManualStudents.length === 0) {
      closeModal();
      return;
    }

    setManualStudents(updatedManualStudents);

    const manualStudentIds = updatedManualStudents.map((student) => student.id);
    form.setValue('manualStudentIds', manualStudentIds);
  };

  const canSubmit = useMemo(() => {
    if (formMode === ADD_STUDENTS_BY_ID_MODE.MANUAL) {
      return !isPending && formManualStudentIds.length > 0 && manualStudents.every((student) => student.isCorrect);
    }
    return !isPending && formStudentIds.length > 0;
  }, [formManualStudentIds.length, formMode, formStudentIds.length, isPending, manualStudents]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Add Students by ID</DialogTitle>
            <DialogDescription className="text-grey-600">
              Enter Students IDs (separated by commas or new line)
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="flex max-h-[400px] w-full flex-col gap-4 overflow-y-auto p-6">
          {formMode === ADD_STUDENTS_BY_ID_MODE.DEFAULT && (
            <Form providerProps={form} onSubmit={form.handleSubmit(addStudentsByIdHandler)}>
              <DefaultStudentsIdForm form={form} />
            </Form>
          )}
          {formMode === ADD_STUDENTS_BY_ID_MODE.MANUAL && (
            <ManualStudentsIdForm
              manualStudents={manualStudents}
              onChange={manualIdChangeHandler}
              onRemove={manualIdRemoveHandler}
            />
          )}
        </div>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(addStudentsByIdHandler)} disabled={!canSubmit} isLoading={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
