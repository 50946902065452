import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAction } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';

export const useDeleteAction = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, string>({
    mutationKey: [ACTIONS_QUERY_KEYS.DELETE_ACTION],
    mutationFn: deleteAction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST] });
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
      showSuccessToast('System message', 'The action was deleted successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not delete the action. Check the provided information and try again');
    },
  });
};
