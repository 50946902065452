import { Card, CardContent, CardDescription, CardHeader, Heading } from '@purple/ui';

type TStatisticCardProperties = {
  title?: string;
  value?: number;
};

export const StatisticCard = ({ title, value }: TStatisticCardProperties) => {
  return (
    <Card className="gap-4 p-3">
      <CardHeader>
        <CardDescription className="font-medium">{title ?? 'No Card Title'}</CardDescription>
      </CardHeader>
      <CardContent>
        <Heading variant="size-36" type="heading-700" className="text-brand-blue-950">
          {value ?? 0}
        </Heading>
      </CardContent>
    </Card>
  );
};
