import { useMutation } from '@tanstack/react-query';
import { safPlaceOnHold } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSafPlaceOnHold = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.PLACE_ON_HOLD],
    mutationFn: safPlaceOnHold,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
      showSuccessToast('System message', 'The SAF was placed on hold successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not place on hold. Check the provided information and try again');
    },
  });
};
