import React from 'react';
import { Badge } from '@purple/ui';
import { Tag } from '~/components';
import { StudentStatusBadge } from '../StudentStatusBadge';

const MAX_SHOW_LABELS = 3;

type StudentLabelsProperties = {
  labels: string[];
  isStudentActive: boolean;
};

const StudentLabels = ({ labels, isStudentActive }: StudentLabelsProperties) => {
  const [isAllVisible, setIsAllVisible] = React.useState(false);

  const [visibleLabels, hiddenLabels] = React.useMemo(() => {
    return [labels.slice(0, MAX_SHOW_LABELS), labels.slice(MAX_SHOW_LABELS)];
  }, [labels]);

  const canSeeMore = React.useMemo(() => labels.length > MAX_SHOW_LABELS, [labels]);

  return (
    <div className="flex flex-row flex-wrap gap-2">
      <StudentStatusBadge isActive={isStudentActive} />
      {visibleLabels.map((label) => {
        return <Tag key={label} label={label} />;
      })}
      {isAllVisible
      && hiddenLabels.map((label) => {
        return <Tag key={label} label={label} />;
      })}
      {canSeeMore && (
        <Badge
          label={isAllVisible ? 'Show less' : 'View all'}
          variant="transparent"
          size="small"
          onClick={() => setIsAllVisible((state) => !state)}
        />
      )}
    </div>
  );
};

export { StudentLabels };
