import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import {
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { LIST_TYPE_OPTIONS } from './constants';

const FilterActionsRow = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const [listType, setListType] = useState<string>(searchParameters.get('list_type') || '');
  const { search, onSearchChange, onClearSearch } = useSearch();

  const listTypeChangeHandler = (value: string) => {
    setListType(value);
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('list_type', value);
      newSearchParameters.set('offset', '0');
      return newSearchParameters;
    });
  };

  const listTypeClearHandler = () => {
    setListType('');
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('list_type');
      newSearchParameters.set('offset', '0');
      return newSearchParameters;
    });
  };

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="min-w-[170px]">
          <RadixSelect value={listType} onValueChange={listTypeChangeHandler}>
            <RadixSelectTrigger hasClearButton={listType.length > 0} onClearCallback={listTypeClearHandler}>
              <RadixSelectValue placeholder="List Type" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {LIST_TYPE_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </div>
        <div className="w-[240px]">
          <SearchInput
            placeholder="Search by list name"
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
        </div>
      </div>
    </div>
  );
};

export { FilterActionsRow };
