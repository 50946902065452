import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';

export const getAttendanceExportFile = async (studentId: string, year: string) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.EXPORT_ATTENDANCE_ANALYSIS, studentId);
  const response = await axiosInstance.get<Blob>(URL_PATH, {
    params: {
      year,
    },
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
