import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import {
  AppFilters,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { SAFS_SORT_SELECT_OPTIONS } from '../constants';
import type { TFiltersConfig } from '@purple/ui';

type TSaFsHeaderSectionProperties = {
  filtersConfig: TFiltersConfig;
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

export const SaFsHeaderSection = ({
  filtersConfig,
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}: TSaFsHeaderSectionProperties) => {
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex gap-4">
        <AppFilters config={filtersConfig} />
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[200px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="w-max">
            {SAFS_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <RadixSelect onValueChange={onPeriodChange} defaultValue={PERIOD_SELECT_OPTIONS[0]?.value} value={timePeriod}>
          <RadixSelectTrigger
            hasClearButton={Boolean(timePeriod)}
            onClearCallback={onPeriodClear}
            triggerContainerClassName="w-[180px]"
          >
            <RadixSelectValue placeholder="Time Period" />
          </RadixSelectTrigger>
          <RadixSelectContent>
            {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </div>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        onClear={onClearSearch}
        placeholder="Search by subject or SAF number"
        className="max-w-[300px]"
      />
    </div>
  );
};
