import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { IPaginatedResponse, TSchoolSafItem, TSchoolSafsRequestParameters } from '@purple/shared-types';

export const getSchoolSafsList = async ({
  schoolId,
  queryParameters,
}: {
  schoolId: string;
  queryParameters: TSchoolSafsRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOL_SAFS_LIST, schoolId);
  const response = await axiosInstance.get<IPaginatedResponse<TSchoolSafItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
