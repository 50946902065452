import { useMemo } from 'react';
import { cutGradeName, getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { TSafListManagerStudent } from '@purple/shared-types';

type TStudentNameProperties = {
  student: TSafListManagerStudent | null;
  submitted_student_first_name: string;
  submitted_student_last_name: string;
};

const StudentName = ({
  student,
  submitted_student_first_name,
  submitted_student_last_name,
}: TStudentNameProperties) => {
  const { photo, full_name, school, student_id, grade } = student || {};

  const fullName
    = submitted_student_first_name || submitted_student_last_name
      ? `${submitted_student_first_name} ${submitted_student_last_name}`
      : '-';

  const isStudentLinked = useMemo(() => Boolean(student), [student]);

  if (!isStudentLinked) {
    return (
      <Text variant="size-14" type="body-500" className="line-clamp-1 w-full text-grey-950">
        {fullName}
      </Text>
    );
  }

  return (
    <Tooltip delayDuration={300}>
      <TooltipTrigger>
        <Text variant="size-14" type="body-500" className="max-w-[80px] text-left text-brand-blue-700">
          <span className="block w-full truncate">{full_name}</span>
        </Text>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          align="center"
          className="min-w-[280px] border border-grey-200 bg-white text-center"
          withArrow={false}
        >
          <div className="flex flex-row flex-nowrap items-start justify-start gap-2 py-1">
            <Avatar size={32}>
              <AvatarImage src={photo ?? undefined} />
              <AvatarFallback className="bg-grey-200">{getInitialsFromName(full_name ?? '—')}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-1">
                <Text variant="size-12" type="body-600" className="text-grey-950">
                  Name:
                </Text>
                <Text variant="size-12" type="body-400" className="text-grey-950">
                  {full_name}
                </Text>
              </div>
              <div className="flex flex-row items-center gap-1">
                <Text variant="size-12" type="body-600" className="text-grey-950">
                  ID:
                </Text>
                <Text variant="size-12" type="body-400" className="text-grey-950">
                  {student_id || 'N/A'}
                </Text>
              </div>
              <div className="flex flex-row items-center gap-1">
                <div className="flex flex-row items-center gap-1">
                  <Text variant="size-12" type="body-600" className="text-grey-950">
                    School:
                  </Text>
                  <Text variant="size-12" type="body-400" className="text-grey-950">
                    {school?.name || '-'}
                  </Text>
                </div>
                <div className="min-h-1 min-w-1 rounded-full bg-grey-600" />
                <div className="flex flex-row items-center gap-1">
                  <Text variant="size-12" type="body-600" className="text-grey-950">
                    Grade:
                  </Text>
                  <Text variant="size-12" type="body-400" className="text-grey-950">
                    {cutGradeName(grade || '-')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

export { StudentName };
