export const USER_ENDPOINTS = {
  CURRENT: '/users/me/',
  SEARCH: '/users/search/',
  USER_EVENTS: 'users/me/calendar_events/',
  CURRENT_PROFILE: '/users/me/profile/',
  CURRENT_PROFILE_FILES: '/users/me/files/',
  UPDATE_CURRENT_PROFILE: '/users/me/profile/',
  UPDATE_CURRENT_PROFILE_PASSWORD: '/users/me/change-password/',
  UPDATE_CURRENT_PROFILE_AVATAR: '/users/me/avatar/',
  UPDATE_CURRENT_PROFILE_EMAIL: '/users/email/change/',
} as const;

export const DISTRICTS_ENDPOINTS = {
  CHECK: '/districts/{subdomain}/check/',
} as const;

export const PERMISSIONS_ENDPOINTS = {
  GET_USER_PERMISSIONS: '/users/me/permissions/',
} as const;

export const STUDENTS_ENDPOINTS = {
  GET_STUDENT_DETAILS: '/students/{studentId}/',
  UPDATE_STUDENT_HOME_INFORMATION: '/students/{studentId}/home-information/',
  GET_STUDENTS_LIST: '/students/',
  GET_STUDENTS_DATA_ALL: '/students/students-base-info/',
  GET_STUDENTS_DATA_LIST: '/students/student-data-list/',
  GET_ACTION_LINKED_STUDENTS: '/students/by-action-group/{actionGroupId}/',
  GET_STUDENTS_DATA_FILTERS_LIST: '/students/student-data-filter-options/',
  GET_DASHBOARD: '/students/{id}/dashboard/',
  GET_STUDENT_CALENDAR: '/students/{studentId}/student-calendar/',
  GET_STUDENT_GRADES_FILTERS: '/students/{studentId}/grades/filter-options/',
  GET_STUDENT_GRADES_LIST: '/students/{studentId}/grades/',
  GET_STUDENT_TRENDS_AND_FLAGS: '/students/{studentId}/trends-and-flags/',
  GET_STUDENT_INCIDENTS_LIST: '/students/{studentId}/incidents/',
  GET_STUDENT_INCIDENTS_FILTERS: '/students/incident-types/',
  GET_STUDENT_INCIDENT_DETAIL: '/students/incidents/{incidentId}/',
  GET_STUDENT_ACCOUNT_HISTORY: '/students/{studentId}/history/',
  GET_STUDENT_SUPPORT_BY_PROGRAM: '/students/{studentId}/support-by-program/',
  EXPORT_SUPPORT_BY_PROGRAM: '/students/{studentId}/support-by-program/export-table/',
  // Students List Endpoints
  ADD_STUDENTS_TO_LIST: '/students/add-students-to-list/',
  // Student Safs Endpoints
  GET_STUDENT_SAFS_FILTERS_OPTIONS: '/students/{schoolId}/safs/filter-options/',
  GET_STUDENT_SAFS_LIST: '/students/{schoolId}/safs/',
  // Student Meeting Attendance Endpoints
  GET_STUDENT_MEETING_ATTENDANCE_LIST: '/students/{studentId}/meeting-attendance/',
  // Student Attendance Endpoints
  GET_STUDENT_ATTENDANCE_LIST: '/students/{studentId}/attendance-analysis/',
  EXPORT_ATTENDANCE_ANALYSIS: '/students/{studentId}/attendance-analysis/export-table/',
  // Student Service Providers Endpoints
  GET_STUDENT_SERVICE_PROVIDERS_FILTERS: '/students/{studentId}/service-providers/filter-options/',
} as const;

export const SCHOOLS_ENDPOINTS = {
  SCHOOLS: '/schools/',
  GET_SCHOOLS_LIST: '/schools/list/',
  GET_MY_SCHOOLS_LIST: '/schools/my-schools/',
  GET_SCHOOL_INFO: '/schools/{schoolId}/',
  UPDATE_SCHOOL_INFO: '/schools/{schoolId}/',
  GET_SCHOOLS_SAFS_FILTERS_OPTIONS: '/schools/{schoolId}/safs/filter-options/',
  GET_SCHOOL_SAFS_LIST: '/schools/{schoolId}/safs/',
  GET_SCHOOLS_USERS_WITH_ACCESS_LIST: '/schools/{schoolId}/users/',
  GET_SCHOOLS_USERS_WITH_ACCESS_FILTERS: '/schools/{schoolId}/users/filter-choices/',
  GET_SCHOOL_DATA_STATS: '/schools/school-data-stats/',
  GET_SCHOOL_DATA_CALENDAR: '/schools/school-data-calendar/',
  GET_SCHOOL_FILTER_CHOICES: '/schools/filter-choices/',
  GET_SCHOOL_OVERVIEW: '/schools/{schoolId}/data-overview/',
  GET_SCHOOL_OVERVIEW_LINKED_STUDENTS: '/schools/{schoolId}/data-overview/linked-students-chart/',
} as const;

export const SAF_ENDPOINTS = {
  CREATE_SAF: '/safs/create/',
  NEEDS: '/safs/needs/list/',
  HISTORY: '/safs/{safId}/history/',
  CORRESPONDENTS: '/saf/{safId}/correspondence/',
  CORRESPONDENTS_BY_ID: '/saf/{safId}/correspondence/{correspondentId}/',
  REPLY_CORRESPONDENTS_BY_ID: '/saf/{safId}/correspondence/{correspondentId}/reply/',
  GET_SAF_LIST_MANAGER: '/saf-list-manager/',
  GET_SAF_LIST_MANAGER_FILTERS: '/saf-list-manager/filter-choices/',
  GET_SAF_DETAILS: '/saf-list-manager/{safId}/',
  GET_CLOSE_SAF_CATEGORIES: '/saf-list-manager/close-saf-categories/',
  CLOSE_SAF: '/saf-list-manager/{safId}/close/',
  LINK_STUDENT: '/saf-list-manager/{safId}/link-to-student/',
  ASSIGN_PRIORITY: '/saf-list-manager/{safId}/assign-priority/',
  UPDATE_SAF_DETAILS: '/saf-list-manager/{safId}/',
  PLACE_ON_HOLD: '/saf-list-manager/{saf_id}/place-on-hold/',
  REMOVE_FROM_HOLD: '/saf-list-manager/{saf_id}/remove-from-hold/',
  TRANSFER: '/saf-list-manager/{saf_id}/transfer/',
  NO_NEED: '/saf-list-manager/{saf_id}/no-need/',
  UNIDENTIFIABLE: '/saf-list-manager/{saf_id}/student-unidentifiable/',
} as const;

export const ACTIONS = {
  GET_ACTION_GROUPS: '/action-groups/',
  ACTION_GROUPS_BY_ID: '/action-groups/{actionGroupId}/',
  ACTION_GROUPS_HISTORY: '/action-groups/{actionGroupId}/history/',
  GROUP_ACTION_TAGS: '/action-groups/{actionId}/tags/',
  BATCH_ADD_STUDENTS: '/action-groups/{actionGroupId}/batch-add-students/',
  REMOVE_LINKED_STUDENT: '/action-groups/{actionGroupId}/remove-linked-student/{studentId}/',
  CREATE_ACTION_GROUP_RECURRENCE: '/action-groups/{actionGroupId}/add-recurrence/',
  GET_ACTION_GROUPS_CHOICES: '/action-groups/dropdown-search/',
  GET_ACTION_CHOICES: '/actions/action-choices/',
  GET_ACTIONS_DETAILS: '/actions/{actionId}/',
  DELETE_ACTION: '/actions/{actionId}/',
  UPDATE_ACTION: '/actions/{actionId}/',
  CREATE_ACTION: '/actions/',
  ACTION_DETAILS: '/actions/{actionId}/',
  ACTION_TAGS: '/actions/{actionId}/tags/',
  GET_SCHOOL_ACTIONS: '/actions/schools/{schoolId}/',
  GET_STUDENT_ACTIONS: '/actions/students/{studentId}/',
  GET_SAF_ACTIONS: '/actions/safs/{safId}/',
  EXPORT_ACTIONS: '/actions/export-table/{studentId}',
  ACTION_PURPLE_USER_PARTICIPANTS: '/actions/{actionId}/purple-user-participants/',
  REMOVE_PURPLE_USER_PARTICIPANTS: '/actions/{actionId}/purple-user-participants/batch-remove-participants/',
  ADD_PURPLE_USER_PARTICIPANTS: '/actions/{actionId}/purple-user-participants/batch-add-participants/',
} as const;

export const REMINDERS = {
  CREATE: '/reminders/',
  CREATE_RECURRING: '/recurring-reminders/',
  UPDATE_REMINDER: '/reminders/{reminderId}/',
  GET_REMINDERS_DETAILS: '/reminders/{reminderId}/',
  DELETE_ONE_TIME_REMINDER: '/reminders/{reminderId}/',
  UPDATE_RECURRING_REMINDER: '/recurring-reminders/{reminderId}/',
  DELETE_RECURRING_REMINDER: '/recurring-reminders/{reminderId}/',
} as const;

export const PRIORITY_LIST_ENDPOINTS = {
  CREATE_PRIORITY_LIST: '/priority-lists/',
  GET_PRIORITY_LISTS: '/priority-lists/',
  GET_PRIORITY_LIST_BY_ID: '/priority-lists/{priorityListId}/',
  UPDATE_PRIORITY_LIST_BY_ID: '/priority-lists/{priorityListId}/',
  ADD_TO_PRIORITY_LIST_BULK: '/priority-lists/{priorityListId}/batch-add-students/',
  DELETE_PRIORITY_LIST: '/priority-lists/{priorityListId}/',
  ADD_SHARE_PRIORITY_LIST_BULK: '/priority-lists/{priorityListId}/batch-add-list-sharing/',
  REMOVE_SHARE_PRIORITY_LIST_BULK: '/priority-lists/{priorityListId}/batch-remove-list-sharing/',
  GET_PRIORITY_LIST_STUDENTS: '/priority-lists/{priorityListId}/paginated-students/',
  REMOVE_STUDENTS_FROM_PRIORITY_LIST: '/priority-lists/{priorityListId}/batch-remove-students/',
};

export const DASHBOARD_ENDPOINTS = {
  GET_DASHBOARD: '/dashboard/',
  GET_DASHBOARD_NEW_STUDENTS: '/dashboard/new-students/',
  GET_DASHBOARD_RED_STUDENTS: '/dashboard/red-students/',
} as const;

export const NOTE_ENDPOINTS = {
  ROOT: '/notes/',
  BY_ID: '/notes/{noteId}/',
} as const;

export const RICH_TEXT_IMAGES_ENDPOINTS = {
  ROOT: '/rich-text-images/',
  BY_ID: '/rich-text-images/{imageId}/',
} as const;

export const FILE_ENDPOINTS = {
  ROOT: '/files/',
  BY_ID: '/files/{fileId}/',
} as const;

export const ACTIVITY_ENDPOINTS = {
  ROOT: '/community-activities/',
  BY_ID: '/community-activities/{activityId}/',
  ACTIVITY_TYPES_LIST: '/community-activities/type-choices/',
  CRISIS_ACTIVITY_LIST: '/community-activities/crisis-view/',
  ACTIVITY_FILTER_OPTIONS: 'community-activities/filter-choices/',
  BULK_DELETE: '/community-activities/bulk-delete/',
  HISTORY: '/community-activities/{activityId}/history/',
  TAGS: '/community-activities/{activityId}/tags/',
  CHOICES: '/community-activities/activity-choices/',
  RECURRING_BY_ID: '/recurring-community-activities/{recurringActivityId}/',
  CREATE_RECURRING_ACTIVITY: '/recurring-community-activities/',
} as const;
