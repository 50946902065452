export const LIST_TYPE_OPTIONS = [
  {
    label: 'Owned by Me',
    value: 'Owned by Me',
  },
  {
    label: 'Shared with Me',
    value: 'Shared with Me',
  },
];
