import { useMutation } from '@tanstack/react-query';
import { sendSafEmail } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSendSafEmail = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.SEND_EMAIL],
    mutationFn: sendSafEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.EMAIL_LIST] });
      showSuccessToast('System message', 'The email was sent successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not send email. Check the provided information and try again');
    },
  });
};
