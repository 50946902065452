import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActivityHistoryRequestParameters, TActivityHistoryResponse } from '@purple/shared-types';

export const getActivityHistory = async ({ activityId, ...parameters }: TActivityHistoryRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActivityHistoryResponse>(
    replaceUrlParameters(ACTIVITY_ENDPOINTS.HISTORY, activityId),
    {
      params: parameters,
    },
  );
  return response.data;
};
