import { useMutation } from '@tanstack/react-query';
import { createRecurringReminder } from '@purple/shared-services';
import { REMINDERS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TReminderCreateRecurringBody, TReminderRecurring } from '@purple/shared-types';

export const useCreateRecurringReminder = () => {
  return useMutation<TReminderRecurring, AxiosError<Record<string, string[]>>, TReminderCreateRecurringBody>({
    mutationKey: [REMINDERS_QUERY_KEYS.CREATE_RECURRING],
    mutationFn: createRecurringReminder,
    onSuccess: () => {
      showSuccessToast('System message', 'The recurring reminder has been created successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create the recurring reminder');
    },
  });
};
