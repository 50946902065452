import { useMemo } from 'react';
import { formatDateToLocalTime, formatMinutesToDuration } from '@purple/shared-utils';
import { Button, ScrollArea, Text } from '@purple/ui';
import { GroupActionAddRecurrenceDialog } from '~/components';
import { useAppSelector, useModal } from '~/hooks';
import { useActionGroupDetails } from '~/queries';
import { userSelector } from '~/store/features/user';
import { GroupActionModalSkeleton } from '../../GroupActionModalSkeleton';

type TDetailsProperties = {
  eventId: string | null;
};

export const Details = ({ eventId }: TDetailsProperties) => {
  const { id } = useAppSelector(userSelector);
  const { openModal } = useModal('delete-group-action-event-modal');
  const { openModal: openRecurrenceModal } = useModal('group-action-add-recurrence');

  const { data, isLoading } = useActionGroupDetails(eventId as string);

  const { details, date_and_time_details } = data || {};

  const isEventOwner = useMemo(() => {
    return data?.details.created_by?.id === id;
  }, [data?.details.created_by?.id, id]);

  return (
    <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
      {isLoading && !data && <GroupActionModalSkeleton />}
      {data && !isLoading && (
        <div className="flex flex-col gap-6 p-6">
          <div className="flex items-center justify-between gap-2">
            <Text variant="size-16" type="body-600">
              {details?.title}
            </Text>
            {isEventOwner && (
              <div className="flex items-center gap-4">
                <Button variant="secondary" className="px-4 py-2.5" onClick={openRecurrenceModal}>
                  Create Recurrence
                </Button>
                <Button variant="secondary" className="px-4 py-2.5" onClick={openModal}>
                  Delete Action
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <Text variant="size-16" type="body-600">
              Description
            </Text>
            <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
              <Text variant="size-14" type="body-500">
                Created by:
              </Text>
              <Text variant="size-14">{details?.created_by?.full_name}</Text>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Text variant="size-16" type="body-600">
              Date & Time
            </Text>
            <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
              <Text variant="size-14" type="body-500">
                Start Date:
              </Text>
              <Text variant="size-14">{formatDateToLocalTime(date_and_time_details?.date_and_time)}</Text>

              <Text variant="size-14" type="body-500">
                End Date:
              </Text>
              <Text variant="size-14">{formatDateToLocalTime(date_and_time_details?.end_date_and_time)}</Text>

              <Text variant="size-14" type="body-500">
                Duration:
              </Text>
              <Text variant="size-14">{formatMinutesToDuration(date_and_time_details?.duration)}</Text>
            </div>
          </div>
        </div>
      )}
      {data && <GroupActionAddRecurrenceDialog groupAction={data} />}
    </ScrollArea>
  );
};
