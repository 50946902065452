import { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useDebouncedCallback } from '@purple/hooks';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { showErrorToast } from '~/shared/lib';
import type { TLocation } from '@purple/shared-types';

const DEBOUNCE_DELAY_MS = 1000;

const getComponent = (
  components: google.maps.GeocoderAddressComponent[],
  type: string,
  nameType: 'long_name' | 'short_name' = 'long_name',
): string => {
  const component = components.find((c) => c.types.includes(type));
  return component ? component[nameType] : '';
};

type TAddressComboBoxProperties = {
  location: TLocation | null;
  onChange: (location: TLocation) => void;
};

const AddressComboBox = ({ onChange, location }: TAddressComboBoxProperties) => {
  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (location) {
      const address = `${location.street}, ${location.city}, ${location.state}, ${location.postal_code}`;

      // false indicates that you don't want to trigger suggestions immediately
      setValue(address, false);
    }
  }, [location, setValue]);

  const debouncedSearch = useDebouncedCallback((newAddress: string) => {
    setValue(newAddress);
  }, DEBOUNCE_DELAY_MS);

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });

      const firstResult = results[0];
      if (firstResult) {
        const { lat, lng } = getLatLng(firstResult);

        const addressComponents = firstResult.address_components;
        const addressObject: TLocation = {
          street: getComponent(addressComponents, 'route'),
          city: getComponent(addressComponents, 'locality'),
          state: getComponent(addressComponents, 'administrative_area_level_1', 'short_name'),
          postal_code: getComponent(addressComponents, 'postal_code'),
          latitude: lat,
          longitude: lng,
        };
        onChange(addressObject);
      }
    } catch {
      showErrorToast(
        'System message',
        'Could not fetch geocode information. Check the provided information and try again',
      );
    }
  };

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={false} placeholder="Select address" selectedLabel={value || ''} disabled={!ready} />
      <ComboBoxContent
        loading={!ready}
        shouldFilter={false}
        searchPlaceholder="Search address..."
        emptyContent="Address not found."
        onSearchChange={debouncedSearch}
      >
        {data.map(({ place_id, description }) => (
          <ComboBoxItem
            key={place_id}
            value={place_id}
            selected={value === place_id}
            onSelect={() => handleSelect(description)}
          >
            {description}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};

export { AddressComboBox };
