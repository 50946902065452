import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { Button, Heading } from '@purple/ui';
import { useGetPriorityLists } from '~/queries';
import { FilterActionsRow, ListGrid, ListGridSkeleton } from './components';

const DEFAULT_LIST_LIMIT = 6;

const MyPriorityListsView = () => {
  const [searchParameters] = useSearchParams();

  const list_type = searchParameters.get('list_type');

  const { debounceSearch } = useSearch();

  const { limit, offset, onLimitChange } = usePaginate({
    defaultLimit: DEFAULT_LIST_LIMIT,
  });

  const { data, isLoading: isDataLoading } = useGetPriorityLists({
    limit,
    offset,
    search: debounceSearch,
    list_type,
  });

  const isMoreExist = useMemo(() => {
    if (data?.results && data.count) {
      return data.results.length < data.count;
    }
    return false;
  }, [data]);

  const viewMoreClickHandler = () => {
    const newLimit = limit + DEFAULT_LIST_LIMIT;
    onLimitChange(newLimit);
  };

  const skeletonCount = useMemo(() => {
    const count = data?.results?.length || DEFAULT_LIST_LIMIT;
    if (count > limit) {
      return limit;
    }
    return count;
  }, [data?.results?.length, limit]);

  return (
    <div className="flex flex-col gap-4 px-4 py-6">
      <div className="flex">
        <Heading variant="size-22" type="heading-600" className="text-grey-title">
          My Priority Lists
        </Heading>
      </div>
      <FilterActionsRow />
      <div className="flex flex-col gap-6">
        {isDataLoading || !data ? <ListGridSkeleton count={skeletonCount} /> : <ListGrid list={data?.results || []} />}
        {isMoreExist && (
          <div className="flex w-full justify-center">
            <Button onClick={viewMoreClickHandler} variant="link" size="link">
              View More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MyPriorityListsView);
