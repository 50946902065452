import { useMemo } from 'react';
import { usePermissions } from '@purple/permissions';
import type { TRequiredPermissions } from '@purple/permissions';

export type TAccordionTab = {
  label: string;
  content: React.ReactNode;
  permissions: TRequiredPermissions;
};

type TUseAccordionOptions = {
  /**
   * Array of tabs
   */
  tabs: TAccordionTab[];
};

export const useAccordion = ({ tabs }: TUseAccordionOptions) => {
  const { hasPermissions } = usePermissions();

  const allowedTabs = useMemo(() => tabs.filter((tab) => hasPermissions(tab.permissions)), [hasPermissions, tabs]);

  return { allowedTabs };
};
