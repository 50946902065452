import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteLinkedStudent } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';

export const useDeleteLinkedStudent = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, { groupActionId: string; studentId: string }>({
    mutationKey: [ACTIONS_QUERY_KEYS.DELETE_LINKED_STUDENT],
    mutationFn: deleteLinkedStudent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [STUDENTS_QUERY_KEYS.GET_ACTION_LINKED_STUDENTS] });
      showSuccessToast('System message', 'Student removed successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not remove linked student. Try again or contact support.');
    },
  });
};
