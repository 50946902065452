import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import type React from 'react';

type TEmailDetailsHeaderProperties = {
  subject?: string;
  onNewEmail?: () => void;
};

export const EmailDetailsHeader: React.FC<TEmailDetailsHeaderProperties> = (props) => {
  const { subject, onNewEmail } = props;

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <Heading tag="h2" className="text-lg font-semibold text-grey-title">
        Subject:
        {' '}
        {subject}
      </Heading>
      <Button type="button" variant="primary" iconLeft={<PurpleIcon name="reply" />} onClick={onNewEmail}>
        New Reply
      </Button>
    </div>
  );
};
