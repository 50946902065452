import { ChartCardContainer, NoDataAvailable } from '@purple/ui';

const TotalFlagsEmpty = () => {
  return (
    <ChartCardContainer>
      <NoDataAvailable iconName="presentation-chart-bar" className="h-[368px]" description="There may be no data to display, or you may not have the necessary access to view it." />
    </ChartCardContainer>
  );
};

export { TotalFlagsEmpty };
