import { getAxiosInstance } from '../../api';
import { SCHOOLS_ENDPOINTS } from '../../constants/private-api';
import type { TSchoolListRequestParameters, TSchoolListResponse } from '@purple/shared-types';

export const getSchoolList = async (parameters: TSchoolListRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TSchoolListResponse>(SCHOOLS_ENDPOINTS.GET_SCHOOLS_LIST, {
    params: parameters,
  });
  return response.data;
};
