import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TPriorityListDetailResponse } from '@purple/shared-types';

export const getPriorityListById = async (id: string) => {
  const axiosInstance = getAxiosInstance();

  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.GET_PRIORITY_LIST_BY_ID, id);

  const response = await axiosInstance.get<TPriorityListDetailResponse>(URL);
  return response.data;
};
