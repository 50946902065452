import { type IStudentMonthEvent, STUDENT_ATTENDANCE_TYPE, STUDENT_CALENDAR_EVENT_TYPE } from '@purple/shared-types';
import { cn, Text } from '@purple/ui';

type CustomEventProperties = {
  event: IStudentMonthEvent;
};

const CustomEvent = ({ event }: CustomEventProperties) => {
  return (
    <div
      className={cn('cursor-auto rounded-md border px-[10px] py-1', {
        'border-brand-blue-100 bg-brand-blue-100':
          event.type === STUDENT_CALENDAR_EVENT_TYPE.BEHAVIOR || event.type === STUDENT_CALENDAR_EVENT_TYPE.ACTIONS,
        'border-error-bg bg-error-bg':
          event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.ABSENT,
        'border-success-main bg-white':
          event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.PRESENT,
        'border-brand-yellow bg-white':
          event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE
          && event.title === STUDENT_ATTENDANCE_TYPE.PARTIAL_PRESENT,
        'border-transparent bg-transparent':
          event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.HOLIDAY,
      })}
    >
      <Text
        variant="size-12"
        type="body-600"
        className={cn('line-clamp-1', {
          'text-brand-blue-700':
            event.type === STUDENT_CALENDAR_EVENT_TYPE.BEHAVIOR || event.type === STUDENT_CALENDAR_EVENT_TYPE.ACTIONS,
          'text-error-main':
            event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.ABSENT,
          'text-success-main':
            event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.PRESENT,
          'text-brand-yellow ':
            event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE
            && event.title === STUDENT_ATTENDANCE_TYPE.PARTIAL_PRESENT,
          'text-grey-500':
            event.type === STUDENT_CALENDAR_EVENT_TYPE.ATTENDANCE && event.title === STUDENT_ATTENDANCE_TYPE.HOLIDAY,
        })}
      >
        {event.title}
      </Text>
    </div>
  );
};

export { CustomEvent };
