import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TSchoolUserWithAccessFilterChoices } from '@purple/shared-types';

export const getSchoolUsersAccessFilterOptions = async ({ schoolId }: { schoolId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOLS_USERS_WITH_ACCESS_FILTERS, schoolId);
  const response = await axiosInstance.get<TSchoolUserWithAccessFilterChoices>(URL_PATH);
  return response.data;
};
