import { useMemo } from 'react';
import { getRandomColor, snakeCaseToCapitalized } from '@purple/shared-utils';
import { EMPTY_CHART_DATA, type TChartConfig } from '@purple/ui';
import { CHART_COLORS } from '~/modules/Home/Tabs/Dashboard/constants';
import { PieChartCard } from '../PieChartCard';
import type { FC } from 'react';
import type { TActivityListResponse } from '@purple/shared-types';

type TDefaultViewStatisticSectionProperties = {
  data?: TActivityListResponse;
  isLoading?: boolean;
};
export const DefaultViewStatisticSection: FC<TDefaultViewStatisticSectionProperties> = ({ data, isLoading }) => {
  const { activity_by_type, time_spent_by_type } = data || {};

  const activityByTypeChartConfig: TChartConfig = useMemo(
    () =>
      activity_by_type
        ? Object.keys(activity_by_type).reduce(
          (accumulator, name, index) => ({
            ...accumulator,
            [name]: {
              label: name,
              color: CHART_COLORS[index] ?? getRandomColor(),
            },
          }),
          {
            value: {
              label: 'Activity',
            },
          },
        )
        : {},
    [activity_by_type],
  );

  const activityByTypeChartData = useMemo(
    () =>
      activity_by_type
        ? Object.entries(activity_by_type).map(([name, value]) => ({
          name: snakeCaseToCapitalized(name),
          value: Number(value?.toFixed(0)) || 0,
          fill: activityByTypeChartConfig[name]!.color!,
          color: activityByTypeChartConfig[name]!.color!,
        }))
        : [],
    [activity_by_type, activityByTypeChartConfig],
  );

  const timeSpentByTypeChartConfig: TChartConfig = useMemo(
    () =>
      time_spent_by_type
        ? Object.keys(time_spent_by_type).reduce(
          (accumulator, name, index) => ({
            ...accumulator,
            [name]: {
              label: name,
              color: CHART_COLORS[index] ?? getRandomColor(),
            },
          }),
          {
            value: {
              label: 'Hours',
            },
          },
        )
        : {},
    [time_spent_by_type],
  );

  const timeSpentByTypeChartData = useMemo(
    () =>
      time_spent_by_type
        ? Object.entries(time_spent_by_type).map(([name, value]) => ({
          name: snakeCaseToCapitalized(name),
          value: Number(value?.toFixed(0)) || 0,
          fill: timeSpentByTypeChartConfig[name]!.color!,
          color: timeSpentByTypeChartConfig[name]!.color!,
        }))
        : [],
    [time_spent_by_type, timeSpentByTypeChartConfig],
  );

  const totalActivity = useMemo(
    () => Number(activityByTypeChartData.reduce((accumulator, item) => accumulator + item.value, 0).toFixed(0)),
    [activityByTypeChartData],
  );
  const totalHours = useMemo(
    () => timeSpentByTypeChartData.reduce((accumulator, item) => accumulator + item.value, 0),
    [timeSpentByTypeChartData],
  );
  const hasNoActivityData = useMemo(() => totalActivity === 0, [totalActivity]);
  const hasNoTimeData = useMemo(() => totalHours === 0, [totalHours]);

  return (
    <section className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <PieChartCard
        cardTitle="Activity by Type"
        chartConfig={activityByTypeChartConfig}
        chartData={hasNoActivityData ? EMPTY_CHART_DATA : activityByTypeChartData}
        totalValue={totalActivity}
        isLoading={isLoading}
        onDownload={() => {}}
      />
      <PieChartCard
        cardTitle="Time Spent by Type (in hours)"
        chartConfig={timeSpentByTypeChartConfig}
        chartData={hasNoTimeData ? EMPTY_CHART_DATA : timeSpentByTypeChartData}
        totalValue={totalHours}
        isLoading={isLoading}
        onDownload={() => {}}
      />
    </section>
  );
};
