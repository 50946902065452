import { type FC, useMemo } from 'react';
import { useSearch, useSort, useTimePeriod } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { CRISIS_GRADE_LEVEL_QUERY_NAME, CRISIS_TYPE_QUERY_NAME } from '@purple/shared-types';
import { cutGradeName, snakeCaseToCapitalized } from '@purple/shared-utils';
import {
  AppFilters,
  AppSelectedFiltersList,
  Button,
  Heading,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { PERIOD_SELECT_OPTIONS, TIME_PERIODS } from '~/constants/options';
import { useModal } from '~/hooks';
import { useActivitiesFilters } from '~/queries/activities/useActivitiesFilters';
import { CRISIS_SORT_SELECT_OPTIONS } from '../../constants';
import type { TFiltersConfig } from '@purple/ui';

type TCrisisContentViewHeaderProperties = {
  isActivitiesSelected?: boolean;
};
export const CrisisContentViewHeader: FC<TCrisisContentViewHeaderProperties> = ({ isActivitiesSelected }) => {
  const { openModal: openBulkDeleteActivityModal } = useModal(ModalType.BULK_DELETE_CRISIS);

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();
  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });

  const { data: activityFilters, isFetching: isLoading } = useActivitiesFilters({ view_name: 'crisis-view' });

  const filters: TFiltersConfig = useMemo(
    () => ({
      categories: [
        {
          label: 'Crisis Type',
          value: CRISIS_TYPE_QUERY_NAME,
          filters:
            activityFilters?.crisis_types?.map((crisis) => ({
              label: snakeCaseToCapitalized(crisis),
              value: crisis,
            })) || [],
        },
        {
          label: 'Grade level',
          value: CRISIS_GRADE_LEVEL_QUERY_NAME,
          filters:
            activityFilters?.grades?.map((grade) => ({
              label: cutGradeName(snakeCaseToCapitalized(grade)),
              value: grade,
            })) || [],
        },
        ...(activityFilters && activityFilters.school && activityFilters.school?.length > 1
          ? [
              {
                label: 'School',
                value: 'school',
                filters:
                  activityFilters?.school?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })) || [],
              },
            ]
          : []),
      ],
    }),
    [activityFilters],
  );

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex flex-col gap-4 px-4 pt-6">
      <Heading tag="h2" variant="size-18" type="heading-600">
        Crisis Call Tracker
      </Heading>
      <div className="flex w-full flex-wrap items-center justify-between gap-4">
        <div className="flex gap-4">
          <AppFilters loading={isLoading} config={filters} />
          <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
            <RadixSelectTrigger
              hasClearButton={hasSortValue}
              onClearCallback={onSortClear}
              triggerContainerClassName="min-w-[200px] max-w-[200px]"
            >
              <RadixSelectValue placeholder="Sort" />
            </RadixSelectTrigger>
            <RadixSelectContent className="w-max">
              {CRISIS_SORT_SELECT_OPTIONS.map((option) => (
                <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                  {option.label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(timePeriod)}
              onClearCallback={onPeriodClear}
              triggerContainerClassName="w-[180px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search by name of school"
            className="max-w-[260px]"
          />
        </div>
        <div className="flex gap-4">
          <Button
            type="button"
            variant="destructive_secondary"
            disabled={!isActivitiesSelected}
            onClick={openBulkDeleteActivityModal}
          >
            Delete Activities
          </Button>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                aria-label="Print activities"
                size="icon_40"
                variant="secondary"
                // onClick={printActivitiesHandler}
                iconLeft={<PurpleIcon name="printer" />}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Print activities</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
      </div>
      <AppSelectedFiltersList config={filters} />
    </div>
  );
};
