import { z } from 'zod';
import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType } from '@purple/shared-types';
import { unionOfLiterals } from '@purple/shared-utils';

export const takeActionDistrictInitiativeSchema = z
  .object({
    document: unionOfLiterals(Object.values(TakeActionDocumentType)),
    link: unionOfLiterals(Object.values(TakeActionLinkType)),
    group: unionOfLiterals(Object.values(TakeActionGroupType)),
    groupName: z
      .string({
        invalid_type_error: 'Group name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Group name must be less than 128 characters.',
      }),
    groupId: z
      .string({
        invalid_type_error: 'Student school must be a string.',
      })
      .trim(),
    title: z.string(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Type is required.',
      }),
    date: z.date({
      message: 'Date is invalid.',
      required_error: 'Date is required.',
    }),
    numberOfResources: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Number of resources must be greater than 0.',
      }),
    services: z.array(z.string()).min(1, {
      message: 'At least one service is required.',
    }),
    tags: z.array(z.string()),
    sharedNotes: z.string().trim(),
    myNotes: z.string().trim(),
  })
  .superRefine((data, context) => {
    if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.NEW && data.groupName === '') {
      context.addIssue({
        path: ['groupName'],
        code: z.ZodIssueCode.custom,
        message: 'Group name is required.',
      });
    }

    if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.EXISTING && data.groupId === '') {
      context.addIssue({
        path: ['groupId'],
        code: z.ZodIssueCode.custom,
        message: 'Group is required.',
      });
    }
  });
