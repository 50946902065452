import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
// English
import safEN from './en/saf.json';
// Spanish
import safES from './es/saf.json';
// Chinese
import safZH from './zh/saf.json';
// Vietnamese
import safVI from './vi/saf.json';
// Arabic
import safAR from './ar/saf.json';

export const defaultNS = 'saf';

export const resources = {
  en: {
    saf: safEN,
  },
  es: {
    saf: safES,
  },
  zh: {
    saf: safZH,
  },
  vi: {
    saf: safVI,
  },
  ar: {
    saf: safAR,
  },
} as const;

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: import.meta.env.DEV,
  resources,
  defaultNS,
});
