import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
  Textarea,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useCreatePriorityList } from '~/queries';
import { createPriorityListSchema } from './schema';
import type * as z from 'zod';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

const REASON_OPTIONS = [
  {
    label: 'Attendance',
    value: 'Attendance',
  },
  {
    label: 'Behavior',
    value: 'Behavior',
  },
  {
    label: 'Academic',
    value: 'Academic',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

type TCreatePriorityListModalProperties = {
  students: TSelectedStudent[];
};

export const CreatePriorityListModal = ({ students }: TCreatePriorityListModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('create-priority-list');

  const { mutate: createList, isPending } = useCreatePriorityList();

  const form = useForm<z.infer<typeof createPriorityListSchema>>({
    resolver: zodResolver(createPriorityListSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      reason: '',
      description: '',
    },
  });

  const createPriorityListHandler = (data: z.infer<typeof createPriorityListSchema>) => {
    createList(
      {
        ...data,
        students: students.map((student) => student.id),
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(createPriorityListHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>New Priority List</DialogTitle>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full flex-col gap-4 p-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Priority List Name</FormLabel>
                  <FormControl>
                    <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Type</FormLabel>
                  <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <RadixSelectTrigger
                        className={cn({
                          'border-error-main': !!form.formState.errors.reason,
                        })}
                      >
                        <RadixSelectValue placeholder="Select type" />
                      </RadixSelectTrigger>
                    </FormControl>
                    <RadixSelectContent>
                      {REASON_OPTIONS.map(({ label, value }) => (
                        <RadixSelectItem key={value} value={value}>
                          {label}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      isError={!!form.formState.errors.description}
                      placeholder="Enter description here"
                      className="min-h-[100px] resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Create
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
