import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { PAGE_VIEW_QUERIES, type TPriorityListDetailResponse, type TSelectedPriorityList } from '@purple/shared-types';
import { Button, cn, Form, FormControl, FormField, FormItem, FormMessage, Input, Text, Textarea } from '@purple/ui';
import { DeleteModal, SharePriorityListModal, TakeActionModal } from '~/components';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
import { useDetailsView } from '~/hooks/useDetailsView';
import { useDeletePriorityList, useEditPriorityList, useSharePriorityList } from '~/queries';
import { EditableArea } from '../EditableArea.tsx';
import { ListItemOptions } from '../ListItemOptions';
import { editPriorityListSchema } from './schema';
import type * as z from 'zod';

const ENTITY_QUERY_NAME = 'list_id' as const;

type TDetailViewHeaderProperties = {
  data: TPriorityListDetailResponse;
};

const DetailViewHeader = ({ data }: TDetailViewHeaderProperties) => {
  const { name, reason, description, is_owner, id, students } = data;

  const { goToListView } = useDetailsView({
    defaultView: PAGE_VIEW_QUERIES.DETAIL_VIEW,
    entityQueryKey: ENTITY_QUERY_NAME,
  });

  const [selectedListItem, setSelectedListItem] = useState<TSelectedPriorityList | null>(null);

  const { openModal: openTakeActionModal } = useModal(ModalType.TAKE_ACTION);
  const { setSelectedStudentId, setSelectedPriorityListId } = useTakeAction();

  const areStudentsExist = students.length > 0;
  const canEdit = is_owner;

  const [editComponentType, setEditComponentType] = useState<'name' | 'description' | null>(null);

  const { mutate: updatePriorityList, isError: isUpdateError } = useEditPriorityList();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal('remove-modal');

  const {
    isOpen: isShareListModalOpen,
    openModal: openShareListModal,
    closeModal: closeShareListModal,
  } = useModal('share-priority-list');

  const { share, isSuccess: isShareSuccess, isPending: isSharePending } = useSharePriorityList();

  useEffect(() => {
    if (isShareSuccess) {
      closeShareListModal();
    }
  }, [closeShareListModal, isShareSuccess]);

  const {
    mutate: deletePriorityList,
    isPending: isDeletingPriorityList,
    isSuccess: isSuccessDeletedPriorityList,
  } = useDeletePriorityList();

  useEffect(() => {
    if (isSuccessDeletedPriorityList) {
      closeDeleteModal();
      goToListView();
    }
  }, [closeDeleteModal, goToListView, isSuccessDeletedPriorityList]);

  // REMOVE METHODS
  const removeListClickHandler = useCallback(
    (newSelectedList: TSelectedPriorityList) => {
      setSelectedListItem(newSelectedList);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  const confirmDeleteHandler = () => {
    if (!selectedListItem) return;
    deletePriorityList(selectedListItem.id);
  };

  // SHARE METHODS
  const shareListClickHandler = useCallback(
    (newSelectedList: TSelectedPriorityList) => {
      setSelectedListItem(newSelectedList);
      openShareListModal();
    },
    [openShareListModal],
  );

  const submitShareListHandler = (newUsersIds: string[]) => {
    if (!selectedListItem) return;
    const oldUsersIds = selectedListItem.shared_with.map((user) => user.id) || [];
    const toAdd = newUsersIds.filter((userId) => !oldUsersIds.includes(userId));
    const toRemove = oldUsersIds.filter((userId) => !newUsersIds.includes(userId));

    share({ idsToAdd: toAdd, idsToRemove: toRemove, listId: selectedListItem.id });
  };

  const form = useForm<z.infer<typeof editPriorityListSchema>>({
    resolver: zodResolver(editPriorityListSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
  });

  useEffect(() => {
    form.reset({
      name,
      description,
    });
  }, [data, description, form, name, isUpdateError]);

  const formName = form.watch('name');
  const formDescription = form.watch('description');

  const takeActionClickHandler = () => {
    if (students.length > 0) {
      if (students.length > 1) {
        setSelectedPriorityListId(id);
      } else {
        const studentId = students[0]?.id;
        if (studentId) {
          setSelectedStudentId(studentId);
        }
      }
      openTakeActionModal();
    }
  };

  const editComponentTypeChangeHandler = (type: 'name' | 'description') => {
    if (canEdit) {
      setEditComponentType(type);
    }
  };

  const editPriorityListHandler = (submitData: z.infer<typeof editPriorityListSchema>) => {
    if (canEdit) {
      updatePriorityList({
        id,
        name: submitData.name,
        description: submitData.description,
      });
    }
  };

  const fieldBlurHandler = () => {
    if (canEdit) {
      form.handleSubmit(editPriorityListHandler)();
      setEditComponentType(null);
    }
  };

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col gap-1">
        <Form providerProps={form} onSubmit={form.handleSubmit(editPriorityListHandler)}>
          <div className="flex items-center gap-2">
            <Text variant="size-14" type="body-500" className="w-[88px] text-grey-title">
              Name:
            </Text>
            {editComponentType === 'name'
              ? (
                  <div className="w-[396px]">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              {...field}
                              onBlur={fieldBlurHandler}
                              isError={!!form.formState.errors.name}
                              placeholder="Enter name"
                              sizeVariant="small"
                              autoFocus
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )
              : (
                  <button
                    type="button"
                    onClick={() => editComponentTypeChangeHandler('name')}
                    className={cn('cursor-text', { 'cursor-pointer': canEdit })}
                  >
                    <EditableArea highlight={canEdit} className="w-[396px]">
                      <Text variant="size-14" type="body-400" className="text-grey-950">
                        {formName}
                      </Text>
                    </EditableArea>
                  </button>
                )}
          </div>
          <div className="flex items-center gap-2">
            <Text variant="size-14" type="body-500" className="w-[88px] text-grey-title">
              Type:
            </Text>
            <EditableArea highlight={false} className="w-[396px]">
              <Text variant="size-14" type="body-400" className="text-grey-950">
                {reason}
              </Text>
            </EditableArea>
          </div>
          <div className="flex gap-2">
            <Text variant="size-14" type="body-500" className="w-[88px] pt-[6px] text-grey-title">
              Description:
            </Text>
            {editComponentType === 'description'
              ? (
                  <div className="w-[396px]">
                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              {...field}
                              isError={!!form.formState.errors.description}
                              placeholder="Enter description here"
                              onBlur={fieldBlurHandler}
                              className="min-h-[140px] resize-none"
                              autoFocus
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )
              : (
                  <button
                    type="button"
                    onClick={() => editComponentTypeChangeHandler('description')}
                    className={cn('cursor-text', { 'cursor-pointer': canEdit })}
                  >
                    <EditableArea highlight={canEdit} className="w-[396px]">
                      <Text variant="size-14" type="body-400" className="text-left text-grey-950">
                        {formDescription || '-'}
                      </Text>
                    </EditableArea>
                  </button>
                )}
          </div>
        </Form>
      </div>
      <div className="flex flex-row gap-2">
        {areStudentsExist && (
          <Guard
            requiredPermissions={[
              APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
              APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
            ]}
          >
            <Button onClick={takeActionClickHandler}>Take Action</Button>
          </Guard>
        )}
        <ListItemOptions
          view={PAGE_VIEW_QUERIES.DETAIL_VIEW}
          onRemove={() => removeListClickHandler(data)}
          onShare={() => shareListClickHandler(data)}
          isOwner={is_owner}
        />
      </div>
      {isDeleteModalOpen && Boolean(selectedListItem) && (
        <DeleteModal
          title={`Are you sure you want to delete “${selectedListItem?.name}”?`}
          description="Please keep in mind that there is no way to restore or backup your list once you proceed."
          onConfirm={confirmDeleteHandler}
          isLoading={isDeletingPriorityList}
        />
      )}
      {isShareListModalOpen && Boolean(selectedListItem) && (
        <SharePriorityListModal
          defaultShareWith={selectedListItem?.shared_with || []}
          onSubmit={submitShareListHandler}
          isLoading={isSharePending}
        />
      )}
      <TakeActionModal />
    </div>
  );
};

export { DetailViewHeader };
