import { convertBooleanToText } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { FC } from 'react';
import type { IStudentAcademics } from '@purple/shared-types';

type StudentAcademicsProperties = {
  data: IStudentAcademics;
};

const StudentAcademics: FC<StudentAcademicsProperties> = ({ data }) => {
  const { is_lack_of_progress_foundation_curriculum, retention } = data;

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Lack of Progress Foundation Curriculum">
        <InfoItemValue>{convertBooleanToText(is_lack_of_progress_foundation_curriculum)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Retention">
        <InfoItemValue>{convertBooleanToText(retention)}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { StudentAcademics };
