export const AppReduxReducer = {
  USER: 'USER',
  USER_PERMISSIONS: 'USER_PERMISSIONS',
  MODAL: 'MODAL',
  DISTRICT: 'DISTRICT',
  STUDENT_DASHBOARD: 'STUDENT_DASHBOARD',
  STUDENT_ASSISTANCE: 'STUDENT_ASSISTANCE',
  STUDENT_INCIDENT: 'STUDENT_INCIDENT',
  TAKE_ACTION: 'TAKE_ACTION',
  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  ROUTE_HISTORY: 'ROUTE_HISTORY',
} as const;
