import { useTranslation } from 'react-i18next';
import { Step, Stepper } from '@purple/ui';
// helpers
import { SAF_STEPS } from '~/constants/saf';
// components
import { FormController } from './FormController';

export const FormContainer: React.FC = () => {
  const { t } = useTranslation('saf');

  return (
    <div className="flex w-full flex-col items-center gap-[60px] rounded-2xl border border-grey-200 bg-white px-8 pb-12 pt-8">
      <Stepper
        initialStep={0}
        steps={SAF_STEPS}
        variant="circle-alt"
        responsive
        styles={{ 'main-container': 'max-w-[420px]' }}
      >
        {SAF_STEPS.map(({ id }) => (
          <Step key={id} label={t(`stepper.${id}`)} id={id} />
        ))}
        <FormController />
      </Stepper>
    </div>
  );
};
