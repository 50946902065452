/**
 * This regular expression will match only latin letter.
 * @example
 * ONLY_LATIN_LETTERS_REGEX.test('цей текст не відпрацює'); // false
 * ONLY_LATIN_LETTERS_REGEX.test('good string'); // true
 */
export const ONLY_LATIN_LETTERS_REGEX = /^[A-Z]+$/i;

/**
 * This regular expression will match only letter.
 * @example
 * CONTAIN_LETTER_REGEX.test('bad string 42'); // false
 * CONTAIN_LETTER_REGEX.test('good string'); // true
 */
export const CONTAIN_LETTER_REGEX = /[A-Z]/i;

/**
 * This regular expression will match only digits.
 * @example
 * CONTAIN_DIGIT_REGEX.test('bad string'); // false
 * CONTAIN_DIGIT_REGEX.test('42'); // true
 */
export const CONTAIN_DIGIT_REGEX = /\d/;

/**
 * This regular expression will match only digits globally.
 * @example
 * CONTAIN_DIGIT_GLOBAL_REGEX.test('bad string'); // false
 * CONTAIN_DIGIT_GLOBAL_REGEX.test('42'); // true
 */
export const CONTAIN_DIGIT_GLOBAL_REGEX = /\D/g;

/**
 * This regular expresion will match only US phone number.
 * @example
 * US_PHONE_NUMBER_REGEX.test('+1 (123) 456-7890'); // true
 * US_PHONE_NUMBER_REGEX.test('+1 (123) 456-789'); // false
 * US_PHONE_NUMBER_REGEX.test('+1 (123) 456-78901'); // false
 */
export const US_PHONE_NUMBER_REGEX = /^\+\d \(\d{3}\) \d{3}-\d{4}$/;

/**
 * This regular expression will match only US state.
 * @example
 * US_STATE_REGEX.test('NY'); // true
 * US_STATE_REGEX.test('NYC'); // false
 * US_STATE_REGEX.test('NYNY'); // false
 */
export const US_STATE_REGEX = /^[A-Z]{2}$/;
