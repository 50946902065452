import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { EditableBlockHeader } from '@purple/ui';
import { useUpdateSchool } from '~/queries';
import { updateSchoolDetailsSchema } from '../../schema';
import { DetailsEdit } from './DetailsEdit';
import { DetailsView } from './DetailsView';
import type * as z from 'zod';
import type { TSchoolInfo, TSchoolUpdateDetails } from '@purple/shared-types';

type TDetailsProperties = {
  data: TSchoolInfo;
};

const Details = ({ data }: TDetailsProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending, isSuccess } = useUpdateSchool();

  useEffect(() => {
    if (isSuccess) {
      setMode('view');
    }
  }, [isSuccess]);

  const form = useForm<z.infer<typeof updateSchoolDetailsSchema>>({
    resolver: zodResolver(updateSchoolDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      school_level: data.details.school_level,
      grades: data.details.grades,
      subtype: data.details.subtype,
      school_office: data.details.school_office,
      school_division: data.details.school_division,
      subgroup: data.details.subgroup,
      phone: data.details.phone,
      website: data.details.website,
      super_neighborhood: data.details.super_neighborhood,
    },
  });

  useEffect(() => {
    form.reset({
      school_level: data.details.school_level,
      grades: data.details.grades,
      subtype: data.details.subtype,
      school_office: data.details.school_office,
      school_division: data.details.school_division,
      subgroup: data.details.subgroup,
      phone: data.details.phone,
      website: data.details.website,
      super_neighborhood: data.details.super_neighborhood,
    });
  }, [data, form, mode]);

  const handleSave = (formData: TSchoolUpdateDetails) => {
    const requestValue = {
      schoolId: data.id,
      request: {
        details: { ...formData },
      },
    };
    mutate(requestValue);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <Guard requiredPermissions={[APP_PERMISSIONS.CAN_EDIT_SCHOOL_PROFILE]}>
        <EditableBlockHeader
          mode={mode}
          onEdit={() => setMode('edit')}
          onCancel={() => setMode('view')}
          onSave={form.handleSubmit(handleSave)}
          isSaving={isPending}
        />
      </Guard>
      {mode === 'view' ? <DetailsView data={data.details} /> : <DetailsEdit data={data.details} form={form} />}
    </div>
  );
};

export { Details };
