// PDF, JPG, JPEG, PNG
export const ACCEPTED_STUDENT_FILE_TYPES = new Set(['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']);
export const ACCEPTED_SCHOOL_FILE_TYPES = new Set(['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']);
export const ACCEPTED_ACTIVITY_FILE_TYPES = new Set(['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']);
export const ACCEPTED_EMAIL_FILE_TYPES = new Set([
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'image/webp',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
  'text/csv',
]);
export const ACCEPTED_DISTRICT_LOGO_TYPES = new Set(['image/jpeg', 'image/jpg', 'image/png']);

export const ALLOWED_STUDENT_FILE_EXTENSIONS = '.pdf, .jpg, .jpeg, .png';
export const ALLOWED_SCHOOL_FILE_EXTENSIONS = '.pdf, .jpg, .jpeg, .png';
export const ALLOWED_ACTIVITY_FILE_EXTENSIONS = '.pdf, .jpg, .jpeg, .png';
export const ALLOWED_EMAIL_FILE_EXTENSIONS
  = '.png,.jpg,.jpeg,.gif,.bmp,.tiff,.webp,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.csv,image/*';
// 10MB
export const MAX_STUDENT_FILE_SIZE = 10_485_760;
export const MAX_SCHOOL_FILE_SIZE = 10_485_760;
export const MAX_ACTIVITY_FILE_SIZE = 10_485_760;
export const MAX_EMAIL_FILES_SIZE = 20 * 1024 * 1024;
export const MAX_ACTIVITY_FILES_SIZE = 10 * 1024 * 1024;
export const MAX_DISTRICT_LOGO_SIZE = 10 * 1024 * 1024;
