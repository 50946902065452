import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, AppTabs } from '@purple/ui';
import {
  ActionDetailsSection,
  ActionGoalsSection,
  ActionHeader,
  ActionTagsSection,
  ActionUserParticipants,
  DateAndTimeSection,
  NotesSection,
} from './components';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

type TIndividualActionDetailsProperties = {
  action: TActionDetails;
};

export const IndividualActionDetailsAlternate: React.FC<TIndividualActionDetailsProperties> = (props) => {
  const { action } = props;

  const stakeholderActionTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Notes',
        value: 'notes',
        content: <NotesSection action={action} />,
        permissions: [],
        className: 'shadow-none border-none',
      },
      {
        label: 'Purple Users Participants',
        value: 'purple-users-participants',
        content: <ActionUserParticipants action={action} />,
        permissions: [],
        className: 'shadow-none border-none',
      },
    ],
    [action],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: stakeholderActionTabs,
  });

  if (!activeTab) return null;

  return (
    <div className="grid w-full grid-cols-1 gap-6 xl:grid-cols-3">
      <aside className="flex w-full shrink-0 flex-col gap-6 rounded-lg border border-grey-200 bg-white p-6">
        <ActionHeader actionData={action} hideTags />
        <ActionGoalsSection action={action} dataKey="student_goals" />
        <Accordion type="multiple" defaultValue={['details']}>
          <AccordionItem value="details" className="border-t border-grey-200">
            <AccordionTrigger>Details</AccordionTrigger>
            <AccordionContent>
              <ActionDetailsSection action={action} hideTitle itemClassName="xl:max-w-[256px] xl:flex-1" />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="date-and-time" className="border-grey-200">
            <AccordionTrigger>Date & Time Details</AccordionTrigger>
            <AccordionContent>
              <DateAndTimeSection
                action={action}
                hideTitle
                itemClassName="xl:max-w-[256px] xl:[&:has(button)]:max-w-full"
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="tags" className="border-none">
            <AccordionTrigger>Tags</AccordionTrigger>
            <AccordionContent>
              <ActionTagsSection action={action} hideTitle triggerClassName="xl:max-w-full" />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </aside>
      <AppTabs
        tabs={stakeholderActionTabs}
        className="col-span-2 flex flex-col items-start xl:-mt-[30px]"
        listClassName="pl-2"
        value={activeTab}
        onValueChange={tabChangeHandler}
      />
    </div>
  );
};
