import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TActivityType } from '@purple/shared-types';

export type TCreateActivityState = {
  /**
   * Whether the modal is open or not for action type selection.
   * If null, the modal is closed.
   */
  activityTypeModalOpen: TActivityType | null;
  /**
   * The selected community activity type.
   */
  selectedCommunityActivityType: string | null;
};

const initialState: TCreateActivityState = {
  activityTypeModalOpen: null,
  selectedCommunityActivityType: null,
};

export const createActivitySlice = createSlice({
  name: AppReduxReducer.CREATE_ACTIVITY,
  initialState,
  reducers: {
    toggleActivityModal: (state, action: PayloadAction<TCreateActivityState['activityTypeModalOpen']>) => {
      state.activityTypeModalOpen = action.payload;
    },
    setSelectedCommunityActivityType: (
      state,
      action: PayloadAction<TCreateActivityState['selectedCommunityActivityType']>,
    ) => {
      state.selectedCommunityActivityType = action.payload;
    },
  },
});

export const { toggleActivityModal } = createActivitySlice.actions;
export const createActivityReducer = createActivitySlice.reducer;
