import { getAxiosInstance } from '../../api';
import { NOTE_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TNote, TUpdateNotePayload } from '@purple/shared-types';

export const updateNote = async ({ noteId, noteData }: { noteId: string; noteData: TUpdateNotePayload }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(NOTE_ENDPOINTS.BY_ID, noteId);
  const response = await axiosInstance.put<TNote>(URL_PATH, noteData);

  return response.data;
};
