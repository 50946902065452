import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { currentUser } from '@purple/shared-services';
import { DISTRICTS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { useAppDispatch } from '~/hooks/redux';
import { setAuthenticated, setIsLoading, setUser } from '~/store/features/user';

export const useUser = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [USER_QUERY_KEYS.CURRENT],
    queryFn: currentUser,
    staleTime: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuthenticated(true));
      dispatch(setUser(data));
      dispatch(setIsLoading(false));
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.CHECK] });
    }
    if (isError) {
      dispatch(setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, isError]);

  return { isLoading, isSuccess };
};
