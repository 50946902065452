import { useMutation, useQueryClient } from '@tanstack/react-query';
import { safAssignPriority } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSafAssignPriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.ASSIGN_PRIORITY],
    mutationFn: safAssignPriority,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, id] });
      showSuccessToast('System Message', 'You successfully assigned a priority');
    },
    onError: () => {
      showErrorToast('System Message', 'Unable to assign the priority');
    },
  });
};
