import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAllSchools } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolListRequestParameters } from '@purple/shared-types';

export const useAllSchools = (parameters: TSchoolListRequestParameters) => {
  const { limit = 100, offset = 0, search = '', ...rest } = parameters;

  const { isError, ...props } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_SCHOOLS, limit, offset, search],
    queryFn: () => getAllSchools({ limit, offset, search, ...rest }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch list of schools', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
