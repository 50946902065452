import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReminderType, ReminderWeeklyInterval, TakeActionType } from '@purple/shared-types';
import {
  Checkbox,
  cn,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadioGroup,
  RadioGroupItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Textarea,
} from '@purple/ui';
import { DAYS_OF_WEEK_OPTIONS, ReadableReminderType, ReadableReminderWeeklyInterval } from '~/constants/reminder';
// hooks
import { useTakeAction } from '~/hooks/redux';
import { useCreateRecurringReminder, useCreateReminder } from '~/queries';
// helpers
import { ReadableTakeActionType } from '~/constants/take-action';
import { showErrorToast } from '~/shared/lib';
// components
import { ActionContainerModal } from '../ActionContainerModal';
// schema
import { takeActionReminderSchema } from './schema';
import type { TReminderType } from '@purple/shared-types';
// types
import type React from 'react';
import type { z } from 'zod';

type TReminderModalProperties = {
  onSuccessfulAction?: () => void;
};

export const ReminderModal: React.FC<TReminderModalProperties> = ({ onSuccessfulAction }) => {
  const formId = useId();
  const {
    toggleActionModal,
    selectedStudentId,
    selectedSafId,
    selectedPriorityListId,
    setSelectedStudentId,
    setSelectedPriorityListId,
  } = useTakeAction();
  const { mutate: createReminder, isPending: isCreatingPending } = useCreateReminder();
  const { mutate: createRecurringReminder, isPending: isCreatingRecurringPending } = useCreateRecurringReminder();

  const isPending = useMemo(
    () => isCreatingPending || isCreatingRecurringPending,
    [isCreatingPending, isCreatingRecurringPending],
  );

  const form = useForm<z.infer<typeof takeActionReminderSchema>>({
    resolver: zodResolver(takeActionReminderSchema),
    mode: 'onChange',
    defaultValues: {
      type: ReminderType.ONE_TIME,
      subject: '',
      notifyBeforeStart: undefined,
      duration: undefined,
      isAllDayEvent: false,
      date: new Date(),
      endDate: undefined,
      daysOfWeek: [],
      weeklyInterval: undefined,
      description: '',
    },
  });

  const reminderType = form.watch('type');
  const startDate = form.watch('date');
  const isAllDayEvent = form.watch('isAllDayEvent');

  const reminderTypeChangeHandler = (value: TReminderType) => {
    form.setValue('type', value);
    if (value === ReminderType.RECURRING) {
      form.setValue('isAllDayEvent', false);
    }
  };

  const reminderAllTimeChangeHandler = (value: boolean) => {
    form.setValue('isAllDayEvent', value);
    if (form.watch('type') === ReminderType.RECURRING) {
      form.setValue('type', ReminderType.ONE_TIME);
    }
  };

  const resetFormHandler = () => {
    form.reset({
      ...form.formState.defaultValues,
      notifyBeforeStart: null!,
      duration: null!,
    });
  };

  const submitReminderActionHandler = (data: z.infer<typeof takeActionReminderSchema>) => {
    if (!selectedStudentId) {
      showErrorToast('Student not selected', 'Please select a student to take an action');
      return;
    }
    if (Array.isArray(selectedStudentId) || selectedPriorityListId) {
      showErrorToast('Multiple students selected', 'Please select only one student to take reminder action');
      return;
    }
    const successCallback = () => {
      toggleActionModal(null);
      setSelectedStudentId(null);
      setSelectedPriorityListId(null);
      onSuccessfulAction?.();
      resetFormHandler();
    };

    if (data.type === ReminderType.ONE_TIME) {
      createReminder(
        {
          student: selectedStudentId,
          subject: data.subject,
          notify_before_minutes: data.notifyBeforeStart,
          ...(data.description && {
            description: data.description,
          }),
          date_and_time: data.date.toISOString(),
          is_all_day_event: data.isAllDayEvent,
          ...(!data.isAllDayEvent && {
            duration: data.duration,
          }),
          ...(selectedSafId && { saf: selectedSafId }),
        },
        {
          onSuccess: successCallback,
        },
      );
      return;
    }

    createRecurringReminder(
      {
        student: selectedStudentId,
        subject: data.subject,
        notify_before_minutes: data.notifyBeforeStart,
        ...(data.description && {
          description: data.description,
        }),
        is_all_day_event: data.isAllDayEvent,
        ...(!data.isAllDayEvent && {
          duration: data.duration,
        }),
        start_date_and_time: data.date.toISOString(),
        end_date_and_time: data.endDate!.toISOString(),
        days_of_week: data.daysOfWeek ?? [],
        weekly_interval: data.weeklyInterval!,
        ...(selectedSafId && { saf: selectedSafId }),
      },
      {
        onSuccess: successCallback,
        onError: (error) => {
          if (error.response?.data?.end_date_and_time) {
            const [errorMessage] = error.response.data.end_date_and_time;
            form.setError('endDate', {
              message: errorMessage ?? 'End date is invalid',
            });
          }
        },
      },
    );
  };

  return (
    <ActionContainerModal
      title={ReadableTakeActionType[TakeActionType.REMINDER]}
      submitButtonLabel="Create"
      formId={formId}
      actionType={TakeActionType.REMINDER}
      isLoading={isPending}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitReminderActionHandler)}
      >
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem className="col-span-2 space-y-2">
              <FormLabel required>Reminder Type</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={reminderTypeChangeHandler}
                  value={field.value}
                  className="flex flex-row items-center justify-start gap-6"
                >
                  {Object.values(ReminderType).map((type) => (
                    <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={type} />
                      </FormControl>
                      <FormLabel>{ReadableReminderType[type]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="subject"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Subject</FormLabel>
              <FormControl>
                <Input {...field} isError={!!fieldState.error} placeholder="Enter subject here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notifyBeforeStart"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Notify Before Start (via email)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter time in minutes here"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="date"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>
                {reminderType === ReminderType.ONE_TIME ? 'Date & Time' : 'Start Date & Time'}
              </FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{ before: new Date() }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {reminderType === ReminderType.RECURRING && (
          <FormField
            control={form.control}
            name="endDate"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>End Date & Time</FormLabel>
                <FormControl>
                  <DatePicker
                    mode="single"
                    placeholder="Select date & time"
                    formatterString="PPP p"
                    isError={!!fieldState.error}
                    triggerDisabled={field.disabled}
                    selected={field.value}
                    defaultMonth={field.value}
                    disabled={{
                      before: startDate,
                    }}
                    captionLayout="dropdown"
                    onDayClick={field.onChange}
                    onTimeChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="isAllDayEvent"
          render={({ field }) => (
            <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value.toString()}
                  checked={field.value}
                  onCheckedChange={reminderAllTimeChangeHandler}
                />
              </FormControl>
              <FormLabel>All Day</FormLabel>
            </FormItem>
          )}
        />

        {!isAllDayEvent && (
          <FormField
            control={form.control}
            name="duration"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Duration (In Minutes)</FormLabel>
                <FormControl>
                  <NumberInput
                    {...field}
                    type="number"
                    isError={!!fieldState.error}
                    placeholder="Enter duration"
                    min={0}
                    changeOnWheel
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {reminderType === ReminderType.RECURRING && (
          <>
            <FormField
              control={form.control}
              name="daysOfWeek"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>Days of week</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!fieldState.error}
                      modalPopover
                      showSearch
                      selectedOptions={DAYS_OF_WEEK_OPTIONS.filter((option) => field.value?.includes(option.value))}
                      placeholder="Select days"
                      onOptionChange={field.onChange}
                    >
                      {DAYS_OF_WEEK_OPTIONS.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          isSelected={field.value?.includes(option.value)}
                        />
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="weeklyInterval"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel required>Weekly Interval</FormLabel>
                  <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <RadixSelectTrigger
                        className={cn({
                          'border-error-main': !!fieldState.error,
                        })}
                      >
                        <RadixSelectValue placeholder="Select interval" />
                      </RadixSelectTrigger>
                    </FormControl>
                    <RadixSelectContent>
                      {Object.values(ReminderWeeklyInterval).map((value) => (
                        <RadixSelectItem key={value} value={value}>
                          {ReadableReminderWeeklyInterval[value]}
                        </RadixSelectItem>
                      ))}
                    </RadixSelectContent>
                  </RadixSelect>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        <FormField
          control={form.control}
          name="description"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter description here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActionContainerModal>
  );
};
