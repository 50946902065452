import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDashboard } from '@purple/shared-services';
import { DASHBOARD_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useDashboard = () => {
  const { isError, ...props } = useQuery({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD],
    queryFn: getDashboard,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch dashboard data', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
