import { Guard } from '@purple/permissions';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Separator } from '@purple/ui';
import { useProfileHeaderSideTabs } from './profileHeaderSideTabs';
import { ProfileSidebarHeader } from './ProfileSidebarHeader';
import { ProfileSidebarHeaderSkeleton } from './ProfileSidebarHeaderSkeleton';
import type { TUserProfile } from '@purple/shared-types';

type ProfileSidebarProperties = {
  data: TUserProfile | undefined;
};

const ProfileSidebar = ({ data }: ProfileSidebarProperties) => {
  const accordionItems = useProfileHeaderSideTabs(data);

  return (
    <aside className="flex h-fit w-[444px] shrink-0 flex-col rounded-lg border border-grey-200 bg-white px-4 pt-6 shadow-custom-heavy">
      {data ? <ProfileSidebarHeader data={data.basic_details} /> : <ProfileSidebarHeaderSkeleton />}
      <Separator className="mt-6 bg-grey-200" />
      <Accordion type="multiple">
        {accordionItems.map((tab) => (
          <Guard key={tab.value} requiredPermissions={tab.permissions}>
            <AccordionItem value={tab.value} className="[&:last-child]:border-none">
              <AccordionTrigger>{tab.label}</AccordionTrigger>
              <AccordionContent>{tab.content}</AccordionContent>
            </AccordionItem>
          </Guard>
        ))}
      </Accordion>
    </aside>
  );
};

export { ProfileSidebar };
