import React from 'react';

export type TSelectedStudent = {
  id: string;
  name: string;
};

type TUseSelectedStudentsReturn = {
  selectedStudents: TSelectedStudent[];
  onChangeStudent: (student: TSelectedStudent) => void;
  onAddBulkStudents: (students: TSelectedStudent[]) => void;
  onReplaceAllStudents: (students: TSelectedStudent[]) => void;
  onClearAllStudents: () => void;
  onRemoveBulkStudents: (students: TSelectedStudent[]) => void;
};

export const useSelectedStudents = (): TUseSelectedStudentsReturn => {
  const [selectedStudents, setSelectedStudents] = React.useState<TSelectedStudent[]>([]);

  const handleReplaceAll = (newStudents: TSelectedStudent[]) => {
    setSelectedStudents(newStudents);
  };

  const handleChangeStudent = (student: TSelectedStudent) => {
    if (selectedStudents.some((selectedStudent) => selectedStudent.id === student.id)) {
      setSelectedStudents(selectedStudents.filter((selectedStudent) => selectedStudent.id !== student.id));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const handleAddBulkStudents = (students: TSelectedStudent[]) => {
    const newStudents = students.filter(
      (student) => !selectedStudents.some((selectedStudent) => selectedStudent.id === student.id),
    );
    setSelectedStudents([...selectedStudents, ...newStudents]);
  };

  const handleRemoveBulkStudents = (students: TSelectedStudent[]) => {
    const uniqStudents = selectedStudents.filter(
      (student) => !students.some((selectedStudent) => selectedStudent.id === student.id),
    );

    setSelectedStudents(uniqStudents);
  };

  const handleClearAll = () => {
    setSelectedStudents([]);
  };

  return {
    selectedStudents,
    onChangeStudent: handleChangeStudent,
    onReplaceAllStudents: handleReplaceAll,
    onClearAllStudents: handleClearAll,
    onAddBulkStudents: handleAddBulkStudents,
    onRemoveBulkStudents: handleRemoveBulkStudents,
  };
};
