import { useMemo } from 'react';
import { Badge } from '@purple/ui';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

const MAX_STUDENTS_COUNT = 12;

type TSelectedStudentsRowProperties = React.PropsWithChildren<{
  selectedStudents: TSelectedStudent[];
  onClearAllStudents: () => void;
  onChangeStudent: (student: TSelectedStudent) => void;
}>;

const SelectedStudentsRow = ({
  selectedStudents,
  onClearAllStudents,
  onChangeStudent,
}: TSelectedStudentsRowProperties) => {
  const studentsCount = useMemo(() => selectedStudents.length, [selectedStudents]);
  const students = useMemo(() => {
    return selectedStudents.slice(0, MAX_STUDENTS_COUNT);
  }, [selectedStudents]);

  const moreStudentsCount = useMemo(() => {
    const leftStudents = selectedStudents.length - MAX_STUDENTS_COUNT;
    return leftStudents > 0 ? leftStudents : 0;
  }, [selectedStudents]);

  if (studentsCount === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-center gap-2">
      {students.map((student) => (
        <Badge
          key={student.id}
          label={student.name}
          onClose={() => onChangeStudent(student)}
          className="bg-brand-blue-100 text-brand-blue-700"
        />
      ))}
      {Boolean(moreStudentsCount) && (
        <Badge label={`+${moreStudentsCount} More`} className="bg-brand-blue-100 text-brand-blue-700" />
      )}
      {Boolean(studentsCount) && (
        <Badge label="Clear All" onClick={onClearAllStudents} className="bg-white text-brand-blue-700" />
      )}
    </div>
  );
};

export { SelectedStudentsRow };
