import { useMutation } from '@tanstack/react-query';
import { getStudentsDataAll } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useGetAllStudentsMutation = () => {
  // NOTE: we use useMutation hook to get all students by clicking on the button (or another trigger)
  return useMutation({
    mutationKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_DATA_ALL_MUTATION],
    mutationFn: getStudentsDataAll,

    onError: () => {
      showErrorToast('System message', 'Could not get all students. Check the provided information and try again');
    },
  });
};
