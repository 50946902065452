import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteGroupAction } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';

export const useDeleteGroupAction = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, string>({
    mutationKey: [ACTIONS_QUERY_KEYS.DELETE_GROUP_ACTION],
    mutationFn: deleteGroupAction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.MY_GROUP_ACTIONS] });
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
      showSuccessToast('System message', 'Group action was deleted successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not delete the group action. Check the provided information and try again',
      );
    },
  });
};
