import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TPriorityList, TPriorityListAddBody } from '@purple/shared-types';

export const addToPriorityListBulk = async ({ id, students }: TPriorityListAddBody) => {
  const apiInstance = getAxiosInstance();
  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.ADD_TO_PRIORITY_LIST_BULK, id);
  const response = await apiInstance.post<TPriorityList>(URL, { students });
  return response.data;
};
