import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type {
  IPaginatedResponse,
  TStudentSupportByProgramItem,
  TStudentSupportByProgramQueryParameters,
} from '@purple/shared-types';

export const getSupportByProgramList = async ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentSupportByProgramQueryParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IPaginatedResponse<TStudentSupportByProgramItem[]>>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_SUPPORT_BY_PROGRAM, studentId),
    {
      params: queryParameters,
    },
  );
  return response.data;
};
