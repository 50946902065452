import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  TakeActionDocumentType,
  TakeActionGroupType,
  TakeActionLinkType,
  TakeActionType,
  UserSearchActionChoice,
} from '@purple/shared-types';
import { convertStringToEditorJson, getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadioGroup,
  RadioGroupItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Textarea,
} from '@purple/ui';
// hooks
import { useDebouncedCallback } from '@purple/hooks';
import { useCurrentUser, useTakeAction } from '~/hooks/redux';
import { useActionChoices, useCreateAction, useUsersSearch } from '~/queries';
// helpers
import {
  ReadableTakeActionDocumentType,
  ReadableTakeActionGroupType,
  ReadableTakeActionLinkType,
  ReadableTakeActionType,
} from '~/constants/take-action';
import { showErrorToast } from '~/shared/lib';
import { constructTakeActionTitle } from '~/shared/utils';
// components
import { TipBox } from '~/components/TipBox';
import { ActionContainerModal } from '../ActionContainerModal';
import { GroupComboBox } from '../GroupComboBox';
// schema
import { BOOLEAN_LIKE_OPTIONS, takeActionCrisisResponseSchema, YES_NO_OPTIONS } from './schema';
// types
import type React from 'react';
import type { z } from 'zod';

type TCrisisResponseModalProperties = {
  onSuccessfulAction?: () => void;
};

export const CrisisResponseModal: React.FC<TCrisisResponseModalProperties> = ({ onSuccessfulAction }) => {
  const formId = useId();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const {
    toggleActionModal,
    selectedStudentId,
    selectedSafId,
    selectedPriorityListId,
    setSelectedStudentId,
    setSelectedPriorityListId,
    isCrisisResponseModalOpen,
  } = useTakeAction();
  const { user } = useCurrentUser();
  const { typeOptions, tagsOptions, crisisTypeOptions, treatAssessmentResultOptions } = useActionChoices({
    record_action_type: TakeActionType.CRISIS_RESPONSE_SESSION,
    students: selectedStudentId,
    priority_list: selectedPriorityListId,
    enabled: isCrisisResponseModalOpen,
  });
  const { data: consultants, isFetching } = useUsersSearch({
    search: debouncedSearchValue,
    action_choices: UserSearchActionChoice.YOUR_CONSULTANTS,
    enabled: isCrisisResponseModalOpen,
  });
  const { mutate: createAction, isPending } = useCreateAction();

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const isMultipleStudents = useMemo(
    () => Array.isArray(selectedStudentId) || selectedPriorityListId,
    [selectedPriorityListId, selectedStudentId],
  );

  const consultantsOptions = useMemo(
    () =>
      consultants?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [consultants],
  );

  const form = useForm<z.infer<typeof takeActionCrisisResponseSchema>>({
    resolver: zodResolver(takeActionCrisisResponseSchema),
    mode: 'onChange',
    defaultValues: {
      document: TakeActionDocumentType.TARGETED,
      link: isMultipleStudents ? TakeActionLinkType.GROUP : TakeActionLinkType.INDIVIDUAL,
      group: TakeActionGroupType.NEW,
      groupName: '',
      groupId: '',
      title: '',
      type: '',
      date: new Date(),
      duration: undefined,
      crisisTypes: [],
      lawEnforcementNotified: undefined,
      cpsNotified: undefined,
      parentCaregiverNotified: undefined,
      tags: [],
      parentConversationSummary: '',
      administrationNotified: undefined,
      consultants: [],
      whoWasConsulted: '',
      threatAssessmentAdministrated: undefined,
      threatAssessmentResult: undefined,
      riskLevelResultExplanation: '',
      parentNotifiedAssessment: undefined,
      weaponsAccessible: undefined,
      followUpPlan: '',
      sharedNotes: '',
      myNotes: '',
    },
  });

  const groupType = form.watch('group');
  const linkType = form.watch('link');
  const documentType = form.watch('document');
  const threatAssessmentAdministrated = form.watch('threatAssessmentAdministrated');

  useEffect(() => {
    form.setValue(
      'title',
      constructTakeActionTitle({
        documentType,
        link: linkType,
        actionType: TakeActionType.CRISIS_RESPONSE_SESSION,
        userName: user.full_name ?? 'Unknown User',
      }),
    );
  }, [groupType, linkType, documentType, user.full_name, form]);

  useEffect(() => {
    if (isMultipleStudents) {
      form.setValue('link', TakeActionLinkType.GROUP);
    }
    if (!isMultipleStudents) {
      form.setValue('link', TakeActionLinkType.INDIVIDUAL);
    }
  }, [isMultipleStudents, form]);

  const resetFormHandler = () => {
    form.reset({
      ...form.formState.defaultValues,
      title: constructTakeActionTitle({
        documentType,
        link: linkType,
        actionType: TakeActionType.CRISIS_RESPONSE_SESSION,
        userName: user.full_name ?? 'Unknown User',
      }),
      duration: null!,
    });
  };

  const submitCrisisResponseActionHandler = (data: z.infer<typeof takeActionCrisisResponseSchema>) => {
    if (!selectedStudentId && !selectedPriorityListId) {
      showErrorToast('Student not selected', 'Please select a student to take an action');
      return;
    }

    const basicBody = {
      record_action_type: TakeActionType.CRISIS_RESPONSE_SESSION,
      document_as: data.document,
      date_and_time: data.date.toISOString(),
      duration: data.duration,
      tags: tagsOptions.flatMap((tag) =>
        data.tags.includes(tag.value) ? [{ name: tag.label, color: tag.color }] : [],
      ),
      title: data.title,
      type: data.type,
      crisis_types: data.crisisTypes,
      ...(data.lawEnforcementNotified !== 'N/A' && {
        is_law_enforcement_notified: data.lawEnforcementNotified === 'Yes',
      }),
      ...(data.cpsNotified !== 'N/A' && { is_CPS_notified: data.cpsNotified === 'Yes' }),
      ...(data.parentCaregiverNotified !== 'N/A' && {
        is_parent_caregiver_notified: data.parentCaregiverNotified === 'Yes',
      }),
      parent_caregiver_conversation_summary: data.parentConversationSummary,
      ...(data.administrationNotified !== 'N/A' && {
        is_administration_notified: data.administrationNotified === 'Yes',
      }),
      who_was_consulted: data.whoWasConsulted,
      is_threat_assessment_administered: data.threatAssessmentAdministrated === 'Yes',
      threat_assessment_result: data.threatAssessmentAdministrated === 'Yes' ? data.threatAssessmentResult : null,
      your_consultants: data.consultants,
      risk_level_result_explanation: data.riskLevelResultExplanation,
      ...(data.parentNotifiedAssessment !== 'N/A' && {
        is_parent_caregiver_notified_of_assessment: data.parentNotifiedAssessment === 'Yes',
      }),
      ...(data.weaponsAccessible !== 'N/A' && {
        is_weapons_accessible_to_the_individual: data.weaponsAccessible === 'Yes',
      }),
      follow_up_plan: data.followUpPlan,
      ...(data.sharedNotes && {
        shared_note: {
          text: convertStringToEditorJson(data.sharedNotes),
        },
      }),
      ...(data.myNotes && {
        private_note: {
          text: convertStringToEditorJson(data.myNotes),
        },
      }),
      ...(selectedSafId && { saf: selectedSafId }),
    };

    const studentsKeys = {
      ...(selectedPriorityListId
        ? {
            priority_list: selectedPriorityListId,
            link_as: data.link,
            ...(data.link === TakeActionLinkType.GROUP
              ? {
                  ...(data.group === TakeActionGroupType.NEW
                    ? { action_group_name: data.groupName }
                    : { action_group: data.groupId }),
                }
              : {}),
          }
        : Array.isArray(selectedStudentId)
          ? {
              students: selectedStudentId,
              link_as: data.link,
              ...(data.link === TakeActionLinkType.GROUP
                ? {
                    ...(data.group === TakeActionGroupType.NEW
                      ? { action_group_name: data.groupName }
                      : { action_group: data.groupId }),
                  }
                : {}),
            }
          : { student: selectedStudentId }),
    };

    createAction(
      // @ts-expect-error ...
      { ...basicBody, ...studentsKeys },
      {
        onSuccess: () => {
          toggleActionModal(null);
          setSelectedStudentId(null);
          setSelectedPriorityListId(null);
          onSuccessfulAction?.();
          form.reset({
            ...form.formState.defaultValues,
            title: data.title,
          });
        },
      },
    );
  };

  return (
    <ActionContainerModal
      title={ReadableTakeActionType[TakeActionType.CRISIS_RESPONSE_SESSION]}
      submitButtonLabel="Create"
      formId={formId}
      actionType={TakeActionType.CRISIS_RESPONSE_SESSION}
      isLoading={isPending}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitCrisisResponseActionHandler)}
      >
        <FormField
          control={form.control}
          name="document"
          render={({ field }) => (
            <FormItem className="col-span-2 space-y-2">
              <div className="flex items-center gap-1">
                <FormLabel required>Document as</FormLabel>
                <TipBox
                  text={
                    'General: Actions that you take for a student(s) that were not rooted in addressing a specific need or issue.\n\nTargeted: Actions that you take for a student(s) that are intentional, strategic, and that were intended to address a specific student need or issue.\n\nAttempted: Action was not able to be completed.'
                  }
                  tooltipClassName="w-[400px] whitespace-pre-wrap"
                />
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-row items-center justify-start gap-6"
                >
                  {Object.values(TakeActionDocumentType).map((type) => (
                    <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={type} />
                      </FormControl>
                      <FormLabel>{ReadableTakeActionDocumentType[type]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {isMultipleStudents && (
          <>
            <FormField
              control={form.control}
              name="link"
              render={({ field }) => (
                <FormItem className="col-span-2 space-y-2">
                  <div className="flex items-center gap-1">
                    <FormLabel required>Link as</FormLabel>
                    <TipBox
                      text={
                        'Individual: Action was taken on behalf of one student at a time.\n\nGroup: Action was taken on behalf of multiple students at the same time, in a group setting.'
                      }
                      tooltipClassName="w-[400px] whitespace-pre-wrap"
                    />
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center justify-start gap-6"
                    >
                      {Object.values(TakeActionLinkType).map((type) => (
                        <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={type} />
                          </FormControl>
                          <FormLabel>{ReadableTakeActionLinkType[type]}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {linkType === TakeActionLinkType.GROUP && (
              <FormField
                control={form.control}
                name="group"
                render={({ field }) => (
                  <FormItem className="col-span-2 space-y-2">
                    <FormLabel required>Group</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-row items-center justify-start gap-6"
                      >
                        {Object.values(TakeActionGroupType).map((type) => (
                          <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={type} />
                            </FormControl>
                            <FormLabel>{ReadableTakeActionGroupType[type]}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {linkType === TakeActionLinkType.GROUP && groupType === TakeActionGroupType.NEW && (
              <FormField
                control={form.control}
                name="groupName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Group Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        isError={!!form.formState.errors.groupName}
                        placeholder="Enter group name"
                        type="text"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {linkType === TakeActionLinkType.GROUP && groupType === TakeActionGroupType.EXISTING && (
              <FormField
                control={form.control}
                name="groupId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Group</FormLabel>
                    <FormControl>
                      <GroupComboBox
                        value={field.value}
                        actionType={TakeActionType.CRISIS_RESPONSE_SESSION}
                        onChange={(value) => form.setValue('groupId', value, { shouldValidate: true })}
                        isError={!!form.formState.errors.groupId}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </>
        )}

        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Title</FormLabel>
              <FormControl>
                <Input
                  isError={!!form.formState.errors.title}
                  type="text"
                  disabled
                  value={field.value}
                  className="hover:border-grey-300 focus:border-grey-300 focus-visible:border-grey-300 active:border-grey-300 disabled:text-grey-400"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select type"
                    selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                  {typeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="date"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!form.formState.errors.duration}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="crisisTypes"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Crisis Types</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!form.formState.errors.crisisTypes}
                  selectedOptions={crisisTypeOptions.filter((item) => field.value.includes(item.value))}
                  placeholder="Select types"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {crisisTypeOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lawEnforcementNotified"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Law Enforcement Notified?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="cpsNotified"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>CPS Notified?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="parentCaregiverNotified"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Parent/Caregiver Notified?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {tagsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="parentConversationSummary"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Parent/Caregiver Conversation Summary</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter conversation here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="administrationNotified"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Administration Notified?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="consultants"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Your Consultants</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={consultantsOptions.filter((tag) => field.value.includes(tag.value))}
                  modalPopover
                  placeholder="Select consultants"
                  showSearch
                  shouldFilter={false}
                  loading={isFetching}
                  onSearchChange={debouncedSearch}
                  onOptionChange={field.onChange}
                >
                  {consultantsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                      customContent
                    >
                      <>
                        {field.value.includes(option.value) && (
                          <PurpleIcon
                            name="check"
                            className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 transform text-brand-blue-700"
                          />
                        )}
                        <div className="flex items-center gap-2">
                          <Avatar size={32}>
                            <AvatarImage src={option.avatar ?? undefined} alt={option.full_name ?? option.email} />
                            <AvatarFallback className="bg-grey-200">
                              {getInitialsFromName(option.full_name ?? '- -')}
                            </AvatarFallback>
                          </Avatar>
                          <div className="flex flex-col gap-1">
                            <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                              {option.full_name ?? option.email}
                            </strong>
                          </div>
                        </div>
                      </>
                    </MultiSelectItem>
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="whoWasConsulted"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Who was Consulted</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter your text here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="threatAssessmentAdministrated"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Threat Assessment Administered</FormLabel>
              <RadixSelect onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {YES_NO_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="threatAssessmentResult"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Threat Assessment Result</FormLabel>
              <RadixSelect onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                    disabled={threatAssessmentAdministrated !== 'Yes'}
                  >
                    <RadixSelectValue placeholder="Select result" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {treatAssessmentResultOptions.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="riskLevelResultExplanation"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Risk Level Result Explanation</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter explanation here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="parentNotifiedAssessment"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Parent/Caregiver Notified of Assessment?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="weaponsAccessible"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Weapons Accessible to this Individual?</FormLabel>
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select option" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {BOOLEAN_LIKE_OPTIONS.map((value) => (
                    <RadixSelectItem key={value} value={value}>
                      {value}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="followUpPlan"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Follow Up Plan</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter plan here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sharedNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel>Shared Notes</FormLabel>
                <TipBox
                  text="These notes are visible to all Purple users, which helps ensure continuity of care for students."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.sharedNotes}
                  placeholder="Enter notes for the Ecosystem here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="myNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel>My Notes</FormLabel>
                <TipBox
                  text="These notes are only visible to you in Purple and will not be shared with other users. However, these notes are still subject to subpoena and other disclosure requests."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.myNotes}
                  placeholder="Enter your notes here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActionContainerModal>
  );
};
