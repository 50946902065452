import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TGroupActionHistoryRequestParameters, TGroupActionHistoryResponse } from '@purple/shared-types';

export const getActionGroupHistory = async ({ groupActionId, ...parameters }: TGroupActionHistoryRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TGroupActionHistoryResponse>(
    replaceUrlParameters(ACTIONS.ACTION_GROUPS_HISTORY, groupActionId),
    {
      params: parameters,
    },
  );
  return response.data;
};
