import { useMask } from '@react-input/mask';
import { CONTAIN_DIGIT_REGEX, convertBooleanToText, isFieldExist, mergeReferences } from '@purple/shared-utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  InfoItem,
  Input,
  MultiSelect,
  MultiSelectItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { InfoItemValue } from '~/components';
import { PHONE_MASK } from '~/constants/phone';
import {
  GRADE_OPTIONS,
  SCHOOL_DIVISION_OPTIONS,
  SCHOOL_LEVEL_OPTIONS,
  SCHOOL_OFFICE_OPTIONS,
  SCHOOL_SUBTYPE_OPTIONS,
  SUPER_NEIGHBORHOOD_OPTIONS,
} from './constants';
import type { UseFormReturn } from 'react-hook-form';
import type { TSchoolDetails, TSchoolUpdateDetails } from '@purple/shared-types';

type TDetailsEditProperties = {
  data: TSchoolDetails;
  form: UseFormReturn<TSchoolUpdateDetails>;
};

const DetailsEdit = ({ data, form }: TDetailsEditProperties) => {
  const { name, school_office, school_division, is_active, school_code } = data;

  const phoneInputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      {/* School Name can not be editable */}
      <InfoItem label="School Name">
        <InfoItemValue>{name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="School Level" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="school_level"
          render={({ field }) => (
            <FormControl>
              <MultiSelect
                {...field}
                selectedOptions={SCHOOL_LEVEL_OPTIONS.filter((option) => field?.value?.includes(option?.value))}
                showSearch
                shouldFilter={false}
                searchPlaceholder="Search by School Level"
                placeholder="Select School Level"
                modalPopover
                onOptionChange={field.onChange}
                size="sm"
              >
                {SCHOOL_LEVEL_OPTIONS.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    isSelected={field?.value?.includes(option?.value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
          )}
        />
      </InfoItem>
      <InfoItem label="Active Grades" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="grades"
          render={({ field, fieldState }) => (
            <FormControl>
              <MultiSelect
                {...field}
                isError={!!fieldState.error}
                selectedOptions={GRADE_OPTIONS.filter((option) => field.value?.includes(option.value))}
                showSearch
                shouldFilter={false}
                searchPlaceholder="Search by Grades"
                placeholder="Select Grades"
                modalPopover
                onOptionChange={field.onChange}
                size="sm"
              >
                {GRADE_OPTIONS.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    isSelected={field?.value?.includes(option.value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
          )}
        />
      </InfoItem>
      <InfoItem label="Subtype" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="subtype"
          render={({ field }) => (
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <RadixSelectTrigger size="sm">
                  <RadixSelectValue placeholder="Select Subtype" />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent>
                {SCHOOL_SUBTYPE_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          )}
        />
      </InfoItem>
      {isFieldExist(school_office) && (
        <InfoItem label="School Office" contentClassName="w-[200px]">
          <FormField
            control={form.control}
            name="school_office"
            render={({ field }) => (
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger size="sm">
                    <RadixSelectValue placeholder="Select School Office" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {SCHOOL_OFFICE_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            )}
          />
        </InfoItem>
      )}
      {isFieldExist(school_division) && (
        <InfoItem label="School Division" contentClassName="w-[200px]">
          <FormField
            control={form.control}
            name="school_division"
            render={({ field }) => (
              <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <RadixSelectTrigger size="sm">
                    <RadixSelectValue placeholder="Select School Division" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {SCHOOL_DIVISION_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
            )}
          />
        </InfoItem>
      )}
      <InfoItem label="Subgroup" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="subgroup"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.subgroup}
                  placeholder="Enter Subgroup"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Neighborhood" contentClassName="w-[200px]">
        <FormField
          control={form.control}
          name="super_neighborhood"
          render={({ field }) => (
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <RadixSelectTrigger size="sm">
                  <RadixSelectValue placeholder="Select Neighborhood" />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent>
                {SUPER_NEIGHBORHOOD_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          )}
        />
      </InfoItem>
      {/* Active Site can not be editable */}
      <InfoItem label="Active Site">
        <InfoItemValue>{convertBooleanToText(is_active)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Phone Number">
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, phoneInputReference])}
                  isError={!!form.formState.errors.phone}
                  placeholder={PHONE_MASK}
                  type="tel"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Website">
        <FormField
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!form.formState.errors.subgroup}
                  placeholder="Enter Website"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      {/* School Code can not be editable */}
      <InfoItem label="School Code">
        <InfoItemValue to={school_code}>{school_code}</InfoItemValue>
      </InfoItem>
    </Form>
  );
};

export { DetailsEdit };
