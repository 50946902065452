import { z } from 'zod';
import { SafSubmitterType } from '@purple/shared-types';
import { unionOfLiterals, zodAlwaysRefine, zPhoneNumber } from '@purple/shared-utils';

export const safFormSchema = zodAlwaysRefine(
  z.object({
    // Helper property to determine the submitter type.
    submitter: unionOfLiterals(Object.values(SafSubmitterType)),
    // Step 1
    myself: z.boolean(),
    anonymous: z.boolean(),
    firstName: z
      .string({
        invalid_type_error: 'First name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'First name must be less than 128 characters.',
      }),
    lastName: z
      .string({
        invalid_type_error: 'Last name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Last name must be less than 128 characters.',
      }),
    email: z
      .string({
        invalid_type_error: 'Email must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Email must be less than 128 characters.',
      }),
    phone: z.string(),
    organization: z
      .string({
        invalid_type_error: 'Organization or campus name must be a string.',
      })
      .trim(),
    // Step 2
    studentFirstName: z
      .string({
        invalid_type_error: 'Student first name must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Student first name is required.',
      })
      .max(128, {
        message: 'Student first name must be less than 128 characters.',
      }),
    studentLastName: z
      .string({
        invalid_type_error: 'Student last name must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Student last name is required.',
      })
      .max(128, {
        message: 'Student last name must be less than 128 characters.',
      }),
    studentMiddleName: z
      .string({
        invalid_type_error: 'Student middle name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Student middle name must be less than 128 characters.',
      }),
    studentId: z
      .string({
        invalid_type_error: 'Student ID must be a string.',
      })
      .trim(),
    studentSchool: z
      .string({
        invalid_type_error: 'Student school must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Student school is required.',
      }),
    studentGrade: z
      .string({
        invalid_type_error: 'Student grade must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Student grade is required.',
      }),
    studentParentPhone: z.string(),
    // Step 3
    needs: z
      .string({
        invalid_type_error: 'Need ID must be a string.',
        required_error: 'Need is required.',
      })
      .trim(),
    details: z
      .string({
        invalid_type_error: 'Details must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Details are required.',
      }),
  }),
).superRefine(async (data, context) => {
  // STEP 1
  if (!data.anonymous && data.firstName === '') {
    context.addIssue({
      path: ['firstName'],
      code: z.ZodIssueCode.custom,
      message: 'First name is required.',
    });
  }

  if (!data.anonymous && data.lastName === '') {
    context.addIssue({
      path: ['lastName'],
      code: z.ZodIssueCode.custom,
      message: 'Last name is required.',
    });
  }

  if (
    !data.anonymous
    && (data.submitter === SafSubmitterType.PROVIDER || data.submitter === SafSubmitterType.TEACHER)
    && data.email === ''
  ) {
    context.addIssue({
      path: ['email'],
      code: z.ZodIssueCode.custom,
      message: 'Email is required.',
    });
  }

  if (!data.anonymous && data.email.length > 0) {
    const email = await z
      .string()
      .email()
      .parseAsync(data.email, { path: ['email'] });
    if (email === null) {
      context.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.custom,
        message: 'Invalid email address.',
      });
    }
  }

  if (
    !data.anonymous
    && (data.submitter === SafSubmitterType.PROVIDER || data.submitter === SafSubmitterType.TEACHER)
    && data.phone === ''
  ) {
    context.addIssue({
      path: ['phone'],
      code: z.ZodIssueCode.custom,
      message: 'Phone number is required.',
    });
  }

  if (!data.anonymous && data.phone.length > 0) {
    const phone = await zPhoneNumber.parseAsync(data.phone, { path: ['phone'] });
    if (phone === null) {
      context.addIssue({
        path: ['phone'],
        code: z.ZodIssueCode.custom,
        message: 'Invalid phone number.',
      });
    }
  }

  if (
    !data.anonymous
    && data.organization === ''
    && (data.submitter === SafSubmitterType.TEACHER || data.submitter === SafSubmitterType.PROVIDER)
  ) {
    context.addIssue({
      path: ['organization'],
      code: z.ZodIssueCode.custom,
      message: 'Organization or campus name is required.',
    });
  }

  // STEP 2
  if (data.studentParentPhone.length > 0) {
    const phone = await zPhoneNumber.parseAsync(data.studentParentPhone, { path: ['studentParentPhone'] });
    if (phone === null) {
      context.addIssue({
        path: ['studentParentPhone'],
        code: z.ZodIssueCode.custom,
        message: 'Invalid phone number.',
      });
    }
  }
});
