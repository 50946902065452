export const AUTH_ENDPOINTS = {
  LOGIN: '/users/token/',
  LOGOUT: '/users/token/blacklist/',
  PASSWORD_RESET: '/users/password/reset/',
  PASSWORD_RESET_CONFIRM: '/users/password/reset/confirm/',
  CHANGE_EMAIL_CONFIRM: '/users/email/change/confirm/{user_id}/{user_token}/',
  CHANGE_EMAIL_DECLINE: '/users/email/change/cancel/{user_id}/{user_token}/',
  SSO_LOGIN: '/saml/signin/',
};

export const JWT_ENDPOINTS = {
  REFRESH: '/users/token/refresh/',
  VERIFY: '/users/token/verify/',
};

export const PUBLIC_ENDPOINTS = [...Object.values(AUTH_ENDPOINTS), ...Object.values(JWT_ENDPOINTS)];
