import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafSendEmailPayload, TSafSendEmailResponse } from '@purple/shared-types';

export const sendSafEmail = async (payload: TSafSendEmailPayload) => {
  const { safId, subject, message_html, message_json, attachments } = payload;

  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('message_html', message_html);
  formData.append('message_json', message_json);

  for (const file of attachments) {
    formData.append('attachments', file);
  }

  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.post<TSafSendEmailResponse>(
    replaceUrlParameters(SAF_ENDPOINTS.CORRESPONDENTS, safId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};
