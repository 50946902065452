import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Separator,
} from '@purple/ui';
// helpers
import { ModalType } from '~/constants/modals';
import { TAKE_ACTION_LIST, TAKE_ACTION_SERVICE_LIST } from '~/constants/take-action';
// hooks
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux/useTakeAction';
import { AcademicCareerPlanningModal } from './AcademicCareerPlanningModal';
// components
import { CheckInModal } from './CheckInModal';
import { CounselingSessionModal } from './CounselingSessionModal';
import { CrisisResponseModal } from './CrisisResponseModal';
import { DistrictInitiativeModal } from './DistrictInitiativeModal';
import { GuidanceCounselingLessonModal } from './GuidanceCounselingLessonModal';
import { ObservationModal } from './ObservationModal';
import { ReminderModal } from './ReminderModal';
import { ResourceModal } from './ResourceModal';
import { StakeholderCollaborativeModal } from './StakeholderCollaborativeModal';
import type React from 'react';
// types
import type { TTakeActionType } from '@purple/shared-types';

type TTakeActionModalProperties = {
  onSuccessfulAction?: () => void;
};

export const TakeActionModal: React.FC<TTakeActionModalProperties> = ({ onSuccessfulAction }) => {
  const { isOpen, toggleModal } = useModal(ModalType.TAKE_ACTION);
  const { toggleActionModal, isMultipleStudents } = useTakeAction();
  const { hasPermissions } = usePermissions();

  const serviceListItems = useMemo(
    () => TAKE_ACTION_SERVICE_LIST.filter(({ permissions }) => hasPermissions([...permissions])),
    [hasPermissions],
  );
  const actionItems = useMemo(
    () =>
      TAKE_ACTION_LIST.filter(({ permissions }) => hasPermissions([...permissions])).filter(
        ({ allowMultipleStudents }) => (isMultipleStudents ? allowMultipleStudents : true),
      ),
    [hasPermissions, isMultipleStudents],
  );

  const actionClickHandler = (action: TTakeActionType) => {
    toggleModal(false);
    toggleActionModal(action);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModal}>
        <DialogContent className="w-[564px]">
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle className="leading-7 tracking-normal">Take Action</DialogTitle>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <DialogDescription asChild className="flex p-6 pb-10">
            <Command className="outline-none">
              <CommandList className="max-h-max rounded-lg border border-grey-200 p-4 pb-2">
                {serviceListItems.length > 0 && (
                  <CommandGroup heading="Service Link">
                    {serviceListItems.map(({ iconName, type, label }) => (
                      <CommandItem
                        key={type}
                        value={type}
                        onSelect={() => actionClickHandler(type)}
                        className="cursor-pointer px-3 py-2 text-base text-grey-950"
                      >
                        <PurpleIcon name={iconName} className="mr-2 h-5 w-5 flex-shrink-0 text-grey-600" />
                        <span>{label}</span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
                <CommandSeparator className="mx-auto my-4 w-[calc(100%-32px)]" />
                {actionItems.length > 0 && (
                  <CommandGroup heading="Action">
                    {actionItems.map(({ iconName, type, label }) => (
                      <CommandItem
                        key={type}
                        value={type}
                        onSelect={() => actionClickHandler(type)}
                        className="cursor-pointer px-3 py-2 text-base text-grey-950"
                      >
                        <PurpleIcon name={iconName} className="mr-2 h-5 w-5 flex-shrink-0 text-grey-600" />
                        <span>{label}</span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
              </CommandList>
            </Command>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <CheckInModal onSuccessfulAction={onSuccessfulAction} />
      <CounselingSessionModal onSuccessfulAction={onSuccessfulAction} />
      <ObservationModal onSuccessfulAction={onSuccessfulAction} />
      <AcademicCareerPlanningModal onSuccessfulAction={onSuccessfulAction} />
      <StakeholderCollaborativeModal onSuccessfulAction={onSuccessfulAction} />
      <DistrictInitiativeModal onSuccessfulAction={onSuccessfulAction} />
      <ResourceModal onSuccessfulAction={onSuccessfulAction} />
      <CrisisResponseModal onSuccessfulAction={onSuccessfulAction} />
      <ReminderModal onSuccessfulAction={onSuccessfulAction} />
      <GuidanceCounselingLessonModal onSuccessfulAction={onSuccessfulAction} />
    </>
  );
};
