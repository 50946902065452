import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  LIMIT_QUERY_NAME,
  OFFSET_QUERY_NAME,
  PAGE_VIEW_QUERIES,
  SEARCH_QUERY_NAME,
  SORT_QUERY_NAME,
  VIEW_QUERY,
} from '@purple/shared-types';
import type { TPageViewQuery } from '@purple/shared-types';

type TUseDetailsViewProperties = {
  entityQueryKey: string;
  defaultView?: TPageViewQuery;
};

export type TUseDetailsViewReturn = {
  goToListView: () => void;
  goToDetailView: (entityId: string) => void;
  view: TPageViewQuery;
  entityId: string | null;
};

/**
 * @deprecated - Use `useQueryParameter` hook instead
 */
const useDetailsView = ({
  entityQueryKey,
  defaultView = PAGE_VIEW_QUERIES.LIST_VIEW,
}: TUseDetailsViewProperties): TUseDetailsViewReturn => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const currentView = searchParameters.get(VIEW_QUERY) as TPageViewQuery;
  const currentEntityId = searchParameters.get(entityQueryKey);

  useEffect(() => {
    const view = searchParameters.get(VIEW_QUERY) as TPageViewQuery;

    if (!view) {
      setSearchParameters(
        (previous) => {
          const newSearchParameters = new URLSearchParams(previous);
          newSearchParameters.set(VIEW_QUERY, defaultView);
          return newSearchParameters;
        },
        {
          replace: true,
        },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToListView = useCallback(() => {
    setSearchParameters(
      (previous) => {
        const newSearchParameters = new URLSearchParams(previous);
        newSearchParameters.set(VIEW_QUERY, PAGE_VIEW_QUERIES.LIST_VIEW);
        newSearchParameters.delete(entityQueryKey);

        // Clear search, sort, limit and offset queries
        newSearchParameters.delete(SEARCH_QUERY_NAME);
        newSearchParameters.delete(SORT_QUERY_NAME);
        newSearchParameters.delete(LIMIT_QUERY_NAME);
        newSearchParameters.delete(OFFSET_QUERY_NAME);

        return newSearchParameters;
      },
      { replace: true },
    );
  }, [entityQueryKey, setSearchParameters]);

  const goToDetailView = useCallback(
    (entityId: string) => {
      setSearchParameters(
        (previous) => {
          const newSearchParameters = new URLSearchParams(previous);
          newSearchParameters.set(VIEW_QUERY, PAGE_VIEW_QUERIES.DETAIL_VIEW);
          newSearchParameters.set(entityQueryKey, entityId);

          // Clear search, sort, limit and offset queries
          newSearchParameters.delete(SEARCH_QUERY_NAME);
          newSearchParameters.delete(SORT_QUERY_NAME);
          newSearchParameters.delete(LIMIT_QUERY_NAME);
          newSearchParameters.delete(OFFSET_QUERY_NAME);

          return newSearchParameters;
        },
        { replace: true },
      );
    },
    [entityQueryKey, setSearchParameters],
  );

  return {
    view: currentView,
    entityId: currentEntityId,
    goToListView,
    goToDetailView,
  };
};

export { useDetailsView };
