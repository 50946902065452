import { useMemo } from 'react';
import { useOverridePointerEvents } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Dialog, DialogDescription, DialogTitle, FileUploadContent, Heading } from '@purple/ui';
import { useModal } from '~/hooks';
import { UploadFileHeader } from './UploadFileHeader';

type UploadFilePreviewProperties = {
  uploadedFile: File | null;
  selectedFileId: string | null;
  setSelectedFileId: (fileId: string | null) => void;
  setUploadedFile: (file: File | null) => void;
};

export const UploadFilePreview = ({
  uploadedFile,
  selectedFileId,
  setSelectedFileId,
  setUploadedFile,
}: UploadFilePreviewProperties) => {
  const { isOpen, toggleModal } = useModal('upload-file-preview');
  useOverridePointerEvents(isOpen);

  const isFileImageType = useMemo(() => {
    return uploadedFile?.type.includes('image');
  }, [uploadedFile]);

  const isFilePdfType = useMemo(() => {
    return uploadedFile?.type === 'application/pdf';
  }, [uploadedFile]);

  const fileUrl = useMemo(() => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile);
    }
    return '';
  }, [uploadedFile]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <FileUploadContent
        onInteractOutside={(event) => event.preventDefault()}
        className="max-h-[720px] max-w-[720px] overflow-y-auto"
        uploadheadercomponent={(
          <UploadFileHeader
            uploadedFile={uploadedFile}
            selectedFileId={selectedFileId}
            setSelectedFileId={setSelectedFileId}
            setUploadedFile={setUploadedFile}
          />
        )}
      >
        <DialogTitle className="sr-only">Preview</DialogTitle>
        <DialogDescription className="sr-only">Preview of the uploaded file</DialogDescription>
        {isFileImageType && (
          <img src={fileUrl} className="block aspect-auto h-auto w-full rounded-md object-contain" alt="preview" />
        )}
        {isFilePdfType && (
          <div className="flex items-center gap-4 p-6">
            <PurpleIcon name="pdf-file" className="h-16 w-16" />
            <Heading variant="size-18" type="heading-500">
              {uploadedFile?.name ?? 'Unknown file'}
            </Heading>
          </div>
        )}
      </FileUploadContent>
    </Dialog>
  );
};
