import { Text } from '@purple/ui';
import type { MonthCaptionProps } from 'react-day-picker';

const CustomCaption = ({ displayIndex }: MonthCaptionProps) => {
  const date = new Date(displayIndex);
  const fullMonthName = date.toLocaleString('en-US', { month: 'long' });
  return (
    <Text variant="size-14" type="body-500" className="text-grey-450">
      {fullMonthName}
    </Text>
  );
};

export { CustomCaption };
