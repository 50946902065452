import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';

export type TStudentIncidentsType = {
  name: string;
  is_used_in_flag_calculations: boolean;
};

export const getStudentIncidentsFilterOptions = async () => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TStudentIncidentsType[]>(STUDENTS_ENDPOINTS.GET_STUDENT_INCIDENTS_FILTERS);

  return response.data;
};
