import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import type { TActivitiesFiltersParameters, TActivitiesFiltersResponse } from '@purple/shared-types';

export const getActivityFilters = async (parameters: TActivitiesFiltersParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TActivitiesFiltersResponse>(ACTIVITY_ENDPOINTS.ACTIVITY_FILTER_OPTIONS, {
    params: parameters,
  });
  return response.data;
};
