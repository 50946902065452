import { useCallback, useEffect, useState } from 'react';
import { PAGE_VIEW_QUERIES, type TPriorityListResponseItem, type TSelectedPriorityList } from '@purple/shared-types';
import { DeleteModal, EmptyDataPlaceholder, SharePriorityListModal, TakeActionModal } from '~/components';
import { useModal } from '~/hooks';
import { useDetailsView } from '~/hooks/useDetailsView';
import { useDeletePriorityList, useSharePriorityList } from '~/queries';
import { ListCard } from '../ListCard';

const ENTITY_QUERY_NAME = 'list_id' as const;

type TListGridProperties = {
  list: TPriorityListResponseItem[];
};

const ListGrid = ({ list }: TListGridProperties) => {
  const { goToDetailView } = useDetailsView({
    entityQueryKey: ENTITY_QUERY_NAME,
    defaultView: PAGE_VIEW_QUERIES.LIST_VIEW,
  });

  const [selectedListItem, setSelectedListItem] = useState<TSelectedPriorityList | null>(null);

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal('remove-modal');

  const {
    isOpen: isShareListModalOpen,
    openModal: openShareListModal,
    closeModal: closeShareListModal,
  } = useModal('share-priority-list');

  const { share, isSuccess: isShareSuccess, isPending: isSharePending } = useSharePriorityList();

  useEffect(() => {
    if (isShareSuccess) {
      closeShareListModal();
    }
  }, [closeShareListModal, isShareSuccess]);

  const {
    mutate: deletePriorityList,
    isPending: isDeletingPriorityList,
    isSuccess: isSuccessDeletedPriorityList,
  } = useDeletePriorityList();

  useEffect(() => {
    closeDeleteModal();
  }, [closeDeleteModal, isSuccessDeletedPriorityList]);

  // REMOVE METHODS
  const removeListClickHandler = useCallback(
    (newSelectedList: TSelectedPriorityList) => {
      setSelectedListItem(newSelectedList);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  const confirmDeleteHandler = () => {
    if (!selectedListItem) return;
    deletePriorityList(selectedListItem.id);
  };

  // SHARE METHODS
  const shareListClickHandler = useCallback(
    (newSelectedList: TSelectedPriorityList) => {
      setSelectedListItem(newSelectedList);
      openShareListModal();
    },
    [openShareListModal],
  );

  const submitShareListHandler = (newUsersIds: string[]) => {
    if (!selectedListItem) return;
    const oldUsersIds = selectedListItem.shared_with.map((user) => user.id) || [];
    const toAdd = newUsersIds.filter((id) => !oldUsersIds.includes(id));
    const toRemove = oldUsersIds.filter((id) => !newUsersIds.includes(id));

    share({ idsToAdd: toAdd, idsToRemove: toRemove, listId: selectedListItem.id });
  };

  if (list.length === 0) {
    return (
      <div className="w-full py-6">
        <EmptyDataPlaceholder
          title="No lists found"
          message="There are no lists to display. If it is an error, please contact support."
        />
      </div>
    );
  }

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-6">
      {list.map((listItem) => (
        <ListCard
          key={listItem.id}
          data={listItem}
          onRemove={() => removeListClickHandler(listItem)}
          onShare={() => shareListClickHandler(listItem)}
          onDetailClick={() => goToDetailView(listItem.id)}
        />
      ))}
      {isDeleteModalOpen && Boolean(selectedListItem) && (
        <DeleteModal
          title={`Are you sure you want to delete “${selectedListItem?.name}”?`}
          description="Please keep in mind that there is no way to restore or backup your list once you proceed."
          onConfirm={confirmDeleteHandler}
          isLoading={isDeletingPriorityList}
        />
      )}
      {isShareListModalOpen && Boolean(selectedListItem) && (
        <SharePriorityListModal
          defaultShareWith={selectedListItem?.shared_with || []}
          onSubmit={submitShareListHandler}
          isLoading={isSharePending}
        />
      )}
      <TakeActionModal />
    </div>
  );
};

export { ListGrid };
