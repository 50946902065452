import { convertBooleanToText } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { FC } from 'react';
import type { IStudentHealth } from '@purple/shared-types';

type StudentHealthProperties = {
  data: IStudentHealth;
};

const StudentHealth: FC<StudentHealthProperties> = ({ data }) => {
  const {
    medical_insurance,
    other_medical_insurance,
    physicians_name,
    physicians_phone_number,
    health_conditions,
    other_health_conditions,
    emergency_contact_name,
    emergency_contact_relationship,
    emergency_contact_phone,
    medications_allergies,
    food_allergies,
    medications_taken,
  } = data;

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Medical Insurance">
        <InfoItemValue>{convertBooleanToText(medical_insurance)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Other Medical Insurance">
        <InfoItemValue>{convertBooleanToText(other_medical_insurance)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Physicians Name">
        <InfoItemValue>{physicians_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Physicians Phone Number">
        <InfoItemValue>{physicians_phone_number}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Health Conditions">
        <InfoItemValue>{health_conditions}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Other Health Conditions">
        <InfoItemValue>{other_health_conditions}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Emergency Contact Name">
        <InfoItemValue>{emergency_contact_name}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Emergency Contact Relationship">
        <InfoItemValue>{emergency_contact_relationship}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Emergency Contact Phone">
        <InfoItemValue>{emergency_contact_phone}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Allergies to Medications">
        <InfoItemValue>{convertBooleanToText(medications_allergies)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Food Allergies">
        <InfoItemValue>{convertBooleanToText(food_allergies)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Medications Taken">
        <InfoItemValue>{medications_taken}</InfoItemValue>
      </InfoItem>
    </div>
  );
};

export { StudentHealth };
