import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentIncidentsList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IStudentIncidentsListRequest } from '@purple/shared-types';

export const useGetStudentIncidentsList = (requestParameters: IStudentIncidentsListRequest) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_INCIDENTS, requestParameters],
    queryFn: () => getStudentIncidentsList(requestParameters),
    enabled: !!requestParameters.studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student incidents', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isError, ...rest };
};
