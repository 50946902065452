import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType, TakeActionType } from '@purple/shared-types';
import { convertStringToEditorJson } from '@purple/shared-utils';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadioGroup,
  RadioGroupItem,
  Textarea,
} from '@purple/ui';
import { TipBox } from '~/components/TipBox';
import {
  ReadableTakeActionDocumentType,
  ReadableTakeActionGroupType,
  ReadableTakeActionLinkType,
  ReadableTakeActionType,
} from '~/constants/take-action';
import { useCurrentUser, useTakeAction } from '~/hooks/redux';
import { useActionChoices, useCreateAction } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { constructTakeActionTitle } from '~/shared/utils';
import { ActionContainerModal } from '../ActionContainerModal';
import { GroupComboBox } from '../GroupComboBox';
import { takeActionObservationSchema } from './schema';
import type React from 'react';
import type * as z from 'zod';

type TFormValues = z.infer<typeof takeActionObservationSchema>;

type TObservationModalProperties = {
  onSuccessfulAction?: () => void;
};

export const ObservationModal: React.FC<TObservationModalProperties> = ({ onSuccessfulAction }) => {
  const formId = useId();
  const { user } = useCurrentUser();

  const {
    toggleActionModal,
    selectedStudentId,
    selectedSafId,
    selectedPriorityListId,
    setSelectedStudentId,
    setSelectedPriorityListId,
    isObservationModalOpen,
  } = useTakeAction();

  const { typeOptions, serviceOptions, tagsOptions } = useActionChoices({
    record_action_type: TakeActionType.OBSERVATION,
    students: selectedStudentId,
    priority_list: selectedPriorityListId,
    enabled: isObservationModalOpen,
  });

  const { mutate: createAction, isPending } = useCreateAction();

  const isMultipleStudents = useMemo(
    () => Array.isArray(selectedStudentId) || selectedPriorityListId,
    [selectedPriorityListId, selectedStudentId],
  );

  const form = useForm<TFormValues>({
    resolver: zodResolver(takeActionObservationSchema),
    mode: 'onChange',
    defaultValues: {
      document: TakeActionDocumentType.GENERAL,
      link: isMultipleStudents ? TakeActionLinkType.GROUP : TakeActionLinkType.INDIVIDUAL,
      group: TakeActionGroupType.NEW,
      groupName: '',
      groupId: '',
      title: '',
      type: '',
      date: new Date(),
      duration: undefined,
      services: [],
      tags: [],
      sharedNotes: '',
      myNotes: '',
    },
  });

  const groupType = form.watch('group');
  const linkType = form.watch('link');
  const documentType = form.watch('document');

  useEffect(() => {
    form.setValue(
      'title',
      constructTakeActionTitle({
        documentType,
        link: linkType,
        actionType: TakeActionType.OBSERVATION,
        userName: user.full_name ?? 'Unknown User',
      }),
    );
  }, [groupType, linkType, documentType, user.full_name, form]);

  useEffect(() => {
    if (isMultipleStudents) {
      form.setValue('link', TakeActionLinkType.GROUP);
    }
    if (!isMultipleStudents) {
      form.setValue('link', TakeActionLinkType.INDIVIDUAL);
    }
  }, [isMultipleStudents, form]);

  const resetFormHandler = () => {
    form.reset({
      ...form.formState.defaultValues,
      title: constructTakeActionTitle({
        documentType,
        link: linkType,
        actionType: TakeActionType.OBSERVATION,
        userName: user.full_name ?? 'Unknown User',
      }),
      duration: null!,
    });
  };

  const submitObservationActionHandler = (data: TFormValues) => {
    if (!selectedStudentId && !selectedPriorityListId) {
      showErrorToast('Student not selected', 'Please select a student to take an action');
      return;
    }

    const basicBody = {
      record_action_type: TakeActionType.OBSERVATION,
      document_as: data.document,
      date_and_time: data.date.toISOString(),
      duration: data.duration,
      service_categories: data.services,
      tags: tagsOptions.flatMap((tag) =>
        data.tags.includes(tag.value) ? [{ name: tag.label, color: tag.color }] : [],
      ),
      title: data.title,
      type: data.type,
      shared_note: {
        text: convertStringToEditorJson(data.sharedNotes),
      },
      private_note: {
        text: convertStringToEditorJson(data.myNotes),
      },
      ...(selectedSafId && { saf: selectedSafId }),
    };

    const studentsKeys = {
      ...(selectedPriorityListId
        ? {
            priority_list: selectedPriorityListId,
            link_as: data.link,
            ...(data.link === TakeActionLinkType.GROUP
              ? {
                  ...(data.group === TakeActionGroupType.NEW
                    ? { action_group_name: data.groupName }
                    : { action_group: data.groupId }),
                }
              : {}),
          }
        : Array.isArray(selectedStudentId)
          ? {
              students: selectedStudentId,
              link_as: data.link,
              ...(data.link === TakeActionLinkType.GROUP
                ? {
                    ...(data.group === TakeActionGroupType.NEW
                      ? { action_group_name: data.groupName }
                      : { action_group: data.groupId }),
                  }
                : {}),
            }
          : { student: selectedStudentId }),
    };

    createAction(
      // @ts-expect-error ...
      { ...basicBody, ...studentsKeys },
      {
        onSuccess: () => {
          toggleActionModal(null);
          setSelectedStudentId(null);
          setSelectedPriorityListId(null);
          onSuccessfulAction?.();
          form.reset({
            ...form.formState.defaultValues,
            title: data.title,
          });
        },
      },
    );
  };

  return (
    <ActionContainerModal
      title={ReadableTakeActionType[TakeActionType.OBSERVATION]}
      submitButtonLabel="Create"
      formId={formId}
      actionType={TakeActionType.OBSERVATION}
      isLoading={isPending}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitObservationActionHandler)}
      >
        <FormField
          control={form.control}
          name="document"
          render={({ field }) => (
            <FormItem className="col-span-2 space-y-2">
              <div className="flex items-center gap-1">
                <FormLabel required>Document as</FormLabel>
                <TipBox
                  text={
                    'General: Actions that you take for a student(s) that were not rooted in addressing a specific need or issue.\n\nTargeted: Actions that you take for a student(s) that are intentional, strategic, and that were intended to address a specific student need or issue.\n\nAttempted: Action was not able to be completed.'
                  }
                  tooltipClassName="w-[400px] whitespace-pre-wrap"
                />
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-row items-center justify-start gap-6"
                >
                  {Object.values(TakeActionDocumentType).map((type) => (
                    <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={type} />
                      </FormControl>
                      <FormLabel>{ReadableTakeActionDocumentType[type]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {isMultipleStudents && (
          <>
            <FormField
              control={form.control}
              name="link"
              render={({ field }) => (
                <FormItem className="col-span-2 space-y-2">
                  <div className="flex items-center gap-1">
                    <FormLabel required>Link as</FormLabel>
                    <TipBox
                      text={
                        'Individual: Action was taken on behalf of one student at a time.\n\nGroup: Action was taken on behalf of multiple students at the same time, in a group setting.'
                      }
                      tooltipClassName="w-[400px] whitespace-pre-wrap"
                    />
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center justify-start gap-6"
                    >
                      {Object.values(TakeActionLinkType).map((type) => (
                        <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={type} />
                          </FormControl>
                          <FormLabel>{ReadableTakeActionLinkType[type]}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {linkType === TakeActionLinkType.GROUP && (
              <FormField
                control={form.control}
                name="group"
                render={({ field }) => (
                  <FormItem className="col-span-2 space-y-2">
                    <FormLabel required>Group</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-row items-center justify-start gap-6"
                      >
                        {Object.values(TakeActionGroupType).map((type) => (
                          <FormItem key={type} className="flex items-center gap-x-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={type} />
                            </FormControl>
                            <FormLabel>{ReadableTakeActionGroupType[type]}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {linkType === TakeActionLinkType.GROUP && groupType === TakeActionGroupType.NEW && (
              <FormField
                control={form.control}
                name="groupName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Group Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        isError={!!form.formState.errors.groupName}
                        placeholder="Enter group name"
                        type="text"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {linkType === TakeActionLinkType.GROUP && groupType === TakeActionGroupType.EXISTING && (
              <FormField
                control={form.control}
                name="groupId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Group</FormLabel>
                    <FormControl>
                      <GroupComboBox
                        value={field.value}
                        actionType={TakeActionType.OBSERVATION}
                        onChange={(value) => form.setValue('groupId', value, { shouldValidate: true })}
                        isError={!!form.formState.errors.groupId}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </>
        )}
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Title</FormLabel>
              <FormControl>
                <Input
                  isError={!!form.formState.errors.title}
                  type="text"
                  disabled
                  value={field.value}
                  className="hover:border-grey-300 focus:border-grey-300 focus-visible:border-grey-300 active:border-grey-300 disabled:text-grey-400"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select type"
                    selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                  {typeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!form.formState.errors.duration}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="services"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Service Categories</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!form.formState.errors.services}
                  selectedOptions={serviceOptions.filter((service) => field.value.includes(service.value))}
                  placeholder="Select categories"
                  modalPopover
                  showSearch
                  onOptionChange={field.onChange}
                >
                  {serviceOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {tagsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sharedNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel required>Shared Notes</FormLabel>
                <TipBox
                  text="These notes are visible to all Purple users, which helps ensure continuity of care for students."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.sharedNotes}
                  placeholder="Enter notes for the Ecosystem here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="myNotes"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <div className="flex items-center gap-1">
                <FormLabel>My Notes</FormLabel>
                <TipBox
                  text="These notes are only visible to you in Purple and will not be shared with other users. However, these notes are still subject to subpoena and other disclosure requests."
                  tooltipClassName="w-[400px]"
                />
              </div>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.myNotes}
                  placeholder="Enter your notes here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </ActionContainerModal>
  );
};
