import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { EditableBlockHeader } from '@purple/ui';
import { useUpdateStudentHomeInformation } from '~/queries';
import { StudentHomeInformationEdit } from './StudentHomeInformationEdit';
import { StudentHomeInformationView } from './StudentHomeInformationView';
import type { FC } from 'react';
import type { IStudentHomeInformation } from '@purple/shared-types';
import type { StudentHomeInformationForm } from './types';

type StudentHomeInformationProperties = {
  studentId: string;
  data: IStudentHomeInformation;
};

const StudentHomeInformation: FC<StudentHomeInformationProperties> = ({ studentId, data }) => {
  const [mode, setMode] = React.useState<'view' | 'edit'>('view');

  const { isPending, mutate, isSuccess } = useUpdateStudentHomeInformation();

  useEffect(() => {
    if (isSuccess) {
      setMode('view');
    }
  }, [isSuccess]);

  const form = useForm<StudentHomeInformationForm>({
    mode: 'onChange',
    defaultValues: {
      relationship_to_the_child: data.relationship_to_the_child,
      other_family_configuration: data.other_family_configuration,
      family_size: data.family_size,
    },
  });

  useEffect(() => {
    form.reset({
      relationship_to_the_child: data.relationship_to_the_child,
      other_family_configuration: data.other_family_configuration,
      family_size: data.family_size,
    });
  }, [data.family_size, data.other_family_configuration, data.relationship_to_the_child, form, mode]);

  const handleSave = (formData: StudentHomeInformationForm) => {
    const request = {
      studentId,
      body: formData,
    };
    mutate(request);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <Guard requiredPermissions={APP_PERMISSIONS.CAN_EDIT_STUDENT_HOME_INFORMATION}>
        <EditableBlockHeader
          mode={mode}
          onEdit={() => setMode('edit')}
          onCancel={() => setMode('view')}
          onSave={form.handleSubmit(handleSave)}
          isSaving={isPending}
        />
      </Guard>
      {mode === 'view'
        ? (
            <StudentHomeInformationView data={data} />
          )
        : (
            <StudentHomeInformationEdit data={data} form={form} />
          )}
    </div>
  );
};

export { StudentHomeInformation };
