import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateGroupActionDetails } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TGroupActionDetailsResponse, TGroupActionDetailsUpdateBody } from '@purple/shared-types';

export const useUpdateGroupAction = () => {
  const queryClient = useQueryClient();

  return useMutation<TGroupActionDetailsResponse, AxiosError, TGroupActionDetailsUpdateBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_GROUP_ACTION_DETAILS],
    mutationFn: updateGroupActionDetails,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_GROUP_DETAILS, data.id] });
      showSuccessToast('System message', 'Group action was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not update group action. Check the provided information and try again');
    },
  });
};
