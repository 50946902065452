import type { Editor } from '@tiptap/core';
import type { MinimalTiptapProperties } from './minimal-tiptap';

export function getOutput(editor: Editor, format: MinimalTiptapProperties['outputValue']) {
  if (format === 'json') {
    return JSON.stringify(editor.getJSON());
  }

  if (format === 'html') {
    return editor.getText() ? String(editor.getHTML()) : '';
  }

  return editor.getText();
}
