import { Skeleton } from '@purple/ui';
import { StatisticCardSkeleton } from './StatisticCard';

export const ActionsHeaderSectionSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="grid grid-flow-col gap-x-4">
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StatisticCardSkeleton key={index} />
        ))}
      </div>
      <div className="flex w-full justify-between">
        <div className="flex gap-4">
          <Skeleton className="h-[40px] w-[120px]" />
          <Skeleton className="h-[40px] w-[200px]" />
          <Skeleton className="h-[40px] w-[180px]" />
        </div>
        <Skeleton className="h-[40px] w-[300px]" />
      </div>
    </div>
  );
};
