import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type { TSchoolSafFilters } from '@purple/shared-types';

export const getSchoolSafFilterOptions = async ({ schoolId }: { schoolId: string }) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(SCHOOLS_ENDPOINTS.GET_SCHOOLS_SAFS_FILTERS_OPTIONS, schoolId);
  const response = await axiosInstance.get<TSchoolSafFilters>(URL_PATH);
  return response.data;
};
