import { getAxiosInstance } from '../../api';
import { SCHOOLS_ENDPOINTS } from '../../constants/private-api';
import type { TTSchoolFilterChoicesResponse } from '@purple/shared-types';

export const getSchoolFilterChoices = async () => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TTSchoolFilterChoicesResponse>(SCHOOLS_ENDPOINTS.GET_SCHOOL_FILTER_CHOICES);
  return response.data;
};
