import { convertPlot } from '~/queries/student-trends-and-flags/converter';
import type { IStudentDataConvertedResponse, IStudentDataResponse } from '@purple/shared-types';

export const convertStudentsDataResponseToDataTable = (data: IStudentDataResponse): IStudentDataConvertedResponse => {
  return {
    ...data,
    results: data.results.map((item) => ({
      ...item,
      plot: convertPlot(item.filtered_cycle_progress, data.colors),
      colorsRange: data.colors,
    })),
  };
};
