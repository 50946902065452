import { useMemo } from 'react';
import { cn, Heading, NumberBadge, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@purple/ui';
import { getNumberStatusBasedOnPercentage } from '../helpers';
import { AttendanceOverYearSkeleton } from './AttendanceOverYearSkeleton';
import type React from 'react';
import type { TSchoolMonthMetrics } from '@purple/shared-types';

type TAttendanceRateTableData = Record<
  'attendanceRate' | 'absenteeismRate',
  { label: string; data: { value: number | string; month: string }[] }
>;

type AttendanceOverYearProperties = {
  /**
   * School attendance data over the selected year.
   */
  data?: Record<string, TSchoolMonthMetrics>;
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const AttendanceOverYear: React.FC<AttendanceOverYearProperties> = (props) => {
  const { data, isLoading = false } = props;

  const tableHeader = useMemo(() => ['Criteria', ...Object.keys(data ?? {}).map((month) => month)], [data]);

  const tableData = useMemo(
    () =>
      Object.entries(data ?? {}).reduce<TAttendanceRateTableData>(
        (accumulator, [month, value]) => ({
          ...accumulator,
          attendanceRate: {
            ...accumulator.attendanceRate,
            data: [
              ...accumulator.attendanceRate.data,
              {
                value: value.attendance === null ? '-' : value.attendance / 100,
                month,
              },
            ],
          },
          absenteeismRate: {
            ...accumulator.absenteeismRate,
            data: [
              ...accumulator.absenteeismRate.data,
              {
                value: value.absenteeism === null ? '-' : value.absenteeism / 100,
                month,
              },
            ],
          },
        }),
        {
          attendanceRate: { label: 'Attendance Rate', data: [] },
          absenteeismRate: { label: 'Absenteeism Rate', data: [] },
        },
      ),
    [data],
  );

  if (isLoading) {
    return <AttendanceOverYearSkeleton />;
  }

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200">
      <Heading tag="h2" variant="size-18" type="heading-600" className="mt-6 px-4 text-base text-grey-title">
        Attendance Over Year
      </Heading>
      <Table wrapperClassName="overflow-x-auto">
        <TableHeader>
          <TableRow className="border-grey-200">
            {tableHeader.map((header, index) => (
              <TableHead
                key={header}
                className={cn('px-5 py-4 text-center text-xs font-semibold uppercase text-grey-600', {
                  'min-w-40 px-5 py-4 text-left capitalize': index === 0,
                })}
              >
                {header}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow className="border-grey-200 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.attendanceRate.label}
            </TableCell>
            {tableData.attendanceRate.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={getNumberStatusBasedOnPercentage(value)}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
          <TableRow className="border-0 p-2">
            <TableCell className="min-w-40 px-3 py-[18px] text-left text-sm font-medium text-grey-950">
              {tableData.absenteeismRate.label}
            </TableCell>
            {tableData.absenteeismRate.data.map(({ value, month }) => (
              <TableCell key={month} className="px-1.5 py-[18px] text-center">
                <NumberBadge variant={getNumberStatusBasedOnPercentage(value, true)}>
                  {value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 })}
                </NumberBadge>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
