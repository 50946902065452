import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@purple/ui';

export const FormInfo: React.FC = () => {
  const { t } = useTranslation('saf');

  return (
    <div className="flex w-full flex-col items-center gap-2">
      <Heading tag="h2" type="heading-600" variant="size-28" className="text-grey-title">
        {t('info.title')}
      </Heading>
      <Text type="body-400" variant="size-16" className="max-w-[742px] text-center text-lg leading-7 text-grey-600">
        {t('info.description')}
      </Text>
    </div>
  );
};
