import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import {
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { MY_FILES_SORT_SELECT_OPTIONS } from './constants';

const ActionsHeaderSection = () => {
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onClearSearch, onSearchChange } = useSearch();

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full justify-between gap-2">
      <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
        <RadixSelectTrigger
          hasClearButton={hasSortValue}
          onClearCallback={onSortClear}
          triggerContainerClassName="w-[220px]"
        >
          <RadixSelectValue placeholder="Sort" />
        </RadixSelectTrigger>
        <RadixSelectContent className="w-max">
          {MY_FILES_SORT_SELECT_OPTIONS.map((option) => (
            <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
              {option.label}
            </RadixSelectItem>
          ))}
        </RadixSelectContent>
      </RadixSelect>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        onClear={onClearSearch}
        placeholder="Search by title"
        className="max-w-[340px]"
      />
    </div>
  );
};

export { ActionsHeaderSection };
