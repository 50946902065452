import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { GroupActionManageMembers } from '~/components/GroupActionManageMembers';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupActionAddMemberDialogProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionAddMemberDialog: React.FC<TGroupActionAddMemberDialogProperties> = ({ groupAction }) => {
  const { isOpen, toggleModal, closeModal } = useModal(ModalType.GROUP_ACTION_MANAGE_MEMBERS);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <DialogTitle>Manage Members</DialogTitle>
          <DialogDescription className="sr-only">
            This dialog allows you to manage members for the group action.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="p-6">
            <GroupActionManageMembers groupActionId={groupAction.id} onModalClose={closeModal} />
          </div>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
