import {
  CITY_COUNCIL_DISTRICT_CHOICES,
  GRADE_CHOICES,
  SCHOOL_DIVISION_CHOICES,
  SCHOOL_LEVEL_CHOICES,
  SCHOOL_OFFICE_CHOICES,
  SCHOOL_SUBTYPE_CHOICES,
  SUPER_NEIGHBORHOOD_CHOICES,
  TRUSTEE_DISTRICT_REGION_CHOICES,
} from '@purple/shared-types';
import { cutGradeName } from '@purple/shared-utils';

export const SCHOOL_LEVEL_OPTIONS = Object.values(SCHOOL_LEVEL_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const GRADE_OPTIONS = Object.values(GRADE_CHOICES).map((choice) => ({
  value: choice,
  label: cutGradeName(choice),
}));

export const SCHOOL_SUBTYPE_OPTIONS = Object.values(SCHOOL_SUBTYPE_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const SCHOOL_OFFICE_OPTIONS = Object.values(SCHOOL_OFFICE_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const SCHOOL_DIVISION_OPTIONS = Object.values(SCHOOL_DIVISION_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const SUPER_NEIGHBORHOOD_OPTIONS = Object.values(SUPER_NEIGHBORHOOD_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const TRUSTEE_DISTRICT_REGION_OPTIONS = Object.values(TRUSTEE_DISTRICT_REGION_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));

export const CITY_COUNCIL_DISTRICT_OPTIONS = Object.values(CITY_COUNCIL_DISTRICT_CHOICES).map((choice) => ({
  value: choice,
  label: choice,
}));
