import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useDeleteFile } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import type React from 'react';

type TDeleteActivityFileDialogProperties = {
  selectedFileId: string | null;
  setSelectedFileId: (fileId: string | null) => void;
};

export const DeleteActivityFileDialog: React.FC<TDeleteActivityFileDialogProperties> = (props) => {
  const { selectedFileId, setSelectedFileId } = props;

  const { isOpen, toggleModal } = useModal(ModalType.DELETE_ACTIVITY_FILE);

  const { mutate: deleteFile, isPending } = useDeleteFile();

  const closeModalHandler = () => {
    setSelectedFileId(null);
    toggleModal(!isOpen);
  };

  const deleteActivityFileHandler = () => {
    if (!selectedFileId) {
      showErrorToast('System Message', 'Unexpected error occurred. Please try again.');
      return;
    }

    deleteFile(selectedFileId, {
      onSuccess: closeModalHandler,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="max-w-[520px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Delete File</DialogTitle>
          <DialogDescription className="sr-only">Delete file modal window</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="p-6">
          <Text variant="size-14" type="body-500" className="text-gray-600">
            Are you sure you want to delete this file?
          </Text>
        </div>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            onClick={deleteActivityFileHandler}
            isLoading={isPending}
            disabled={isPending}
          >
            Yes, Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
