import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityType } from '@purple/shared-types';
import {
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Message,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  Textarea,
} from '@purple/ui';
import { MinimalTiptapEditor } from '~/components/RichTextEditor';
import { ALLOWED_TOPIC_TYPES, ReadableActivityType } from '~/constants/create-activity';
import { useAppSelector, useCreateActivity } from '~/hooks/redux';
import { useActivitiesTypes, useActivityChoices, useCreateNotRecurringActivity } from '~/queries';
import { userSelector } from '~/store/features/user';
import { ActivityContainerModal } from '../ActivityContainerModal';
import { UploadFilesField } from '../UploadFilesField';
import { CRISIS_MODE_OF_SUPPORT_OPTIONS } from './constants';
import { crisisResponseBehavioralSupportSchema } from './schema';
import { SessionParticipantsComboBox } from './SessionParticipantsComboBox';
import type { z } from 'zod';

type TCrisisResponseBehavioralSupportActivityModalProperties = {
  onSuccessfulActivity?: () => void;
};

const CrisisResponseBehavioralSupportActivityModal = ({
  onSuccessfulActivity,
}: TCrisisResponseBehavioralSupportActivityModalProperties) => {
  const formId = useId();
  const { toggleActivityModal, isCrisisResponseBehavioralModalOpen } = useCreateActivity();

  const { schools } = useAppSelector(userSelector);

  const { mutate: createActivity, isPending: isCreatingActivity } = useCreateNotRecurringActivity();

  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const [isFileError, setIsFileError] = useState<boolean>(false);

  const schoolsOptions = useMemo(() => {
    return schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));
  }, [schools]);

  const { data: activitiesTypes } = useActivitiesTypes();

  const community_activity_type_id = useMemo(() => {
    return activitiesTypes?.find((item) => item.name === ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT)?.id;
  }, [activitiesTypes]);

  const { tagsOptions, typeOptions, stakeholdersServedOptions, topicsOptions } = useActivityChoices({
    enabled: isCrisisResponseBehavioralModalOpen,
    community_activity_type: ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT,
  });

  const defaultValues = useMemo(() => {
    const [defaultSchool] = schools;

    return {
      school: schools.length === 1 ? defaultSchool?.id : '',
      name: '',
      date_and_time: new Date(),
      duration: 0,
      type: '',
      topic: '',
      stakeholders_served: [],
      participants_count: 0,
      session_participant: '',
      tags: [],
      description: '',
      crisis_mode_of_support: '',
      crisis_internal_comments: '',
    };
  }, [schools]);

  const form = useForm<z.infer<typeof crisisResponseBehavioralSupportSchema>>({
    resolver: zodResolver(crisisResponseBehavioralSupportSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const formType = form.watch('type');

  const isTopicDisabled = useMemo(() => !ALLOWED_TOPIC_TYPES.has(formType), [formType]);

  useEffect(() => {
    if (!ALLOWED_TOPIC_TYPES.has(formType)) {
      form.setValue('topic', '');
    }
  }, [form, formType]);

  const resetFormHandler = () => {
    toggleActivityModal(null);
    onSuccessfulActivity?.();
    form.reset(defaultValues);
  };

  const uploadFilesHandler = (files: File[] | null) => {
    setUploadedFiles(files);
    setIsFileError(false);
  };

  const uploadFilesErrorHandler = () => {
    setIsFileError(true);
  };

  const submitActivityHandler = (data: z.infer<typeof crisisResponseBehavioralSupportSchema>) => {
    if (!community_activity_type_id) return;

    const selectedTags = tagsOptions
      .filter((item) => data.tags.includes(item.value))
      .map((item) => ({ name: item.label, color: item.color }));

    const body = {
      community_activity_type: community_activity_type_id,
      name: data.name,
      school: data.school,
      date_and_time: data.date_and_time.toISOString(),
      duration: data.duration,
      type: data.type,
      ...(data.topic && { topic: data.topic }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
      participants_count: data.participants_count,
      session_participant: data.session_participant,
      stakeholders_served: data.stakeholders_served,
      crisis_mode_of_support: data.crisis_mode_of_support,
      crisis_internal_comments: data.crisis_internal_comments,
      ...(data.description && { description: data.description }),
      ...(uploadedFiles && { attachments: uploadedFiles }),
    };

    createActivity(body, {
      onSuccess: () => {
        resetFormHandler();
      },
    });
  };

  return (
    <ActivityContainerModal
      title={ReadableActivityType[ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT]}
      submitButtonLabel="Create"
      formId={formId}
      activityType={ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT}
      isLoading={isCreatingActivity}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitActivityHandler)}
      >
        {isFileError && (
          <div className="col-span-2 flex">
            <Message variant="error" onClose={() => setIsFileError(false)}>
              Invalid file format or size!
            </Message>
          </div>
        )}
        <FormField
          control={form.control}
          name="school"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>School</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select school"
                    selectedLabel={schoolsOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search school..." emptyContent="School not found.">
                  {schoolsOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Activity Name</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date_and_time"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{ before: new Date() }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select type"
                    selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                  {typeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="topic"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Topic</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select topic"
                    selectedLabel={topicsOptions.find((option) => option.value === field.value)?.label}
                    disabled={isTopicDisabled}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search topic..." emptyContent="Topic not found.">
                  {topicsOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="stakeholders_served"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Stakeholders Served</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={stakeholdersServedOptions.filter((tag) => {
                    return field.value.includes(tag.value);
                  })}
                  placeholder="Select stakeholders"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {stakeholdersServedOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="participants_count"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Number of Participants</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter number of participants"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="session_participant"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Session Participants</FormLabel>
              <FormControl>
                <SessionParticipantsComboBox
                  value={field.value || ''}
                  onChange={(value) => form.setValue('session_participant', value, { shouldValidate: true })}
                  isError={!!fieldState.error}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {tagsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="crisis_mode_of_support"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Mode of Support</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select mode"
                    selectedLabel={CRISIS_MODE_OF_SUPPORT_OPTIONS.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search mode..." emptyContent="Mode not found.">
                  {CRISIS_MODE_OF_SUPPORT_OPTIONS.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.description}
                  placeholder="Enter description text here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="crisis_internal_comments"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel required>Internal Comments</FormLabel>
              <FormControl>
                <MinimalTiptapEditor
                  {...field}
                  value={field.value}
                  onValueChange={field.onChange}
                  outputValue="json"
                  placeholder="Enter a comment here"
                  contentClass="h-[160px]"
                  className={cn('h-[160px] min-h-[160px] w-full overflow-y-auto', {
                    'border-error-main': form.formState.errors.crisis_internal_comments,
                  })}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <UploadFilesField onFileUpload={uploadFilesHandler} onError={uploadFilesErrorHandler} />
      </Form>
    </ActivityContainerModal>
  );
};

export { CrisisResponseBehavioralSupportActivityModal };
