import { forwardRef } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, Text } from '@purple/ui';
import type { PurpleIconType } from '@purple/icons';

const StepIcon = ({
  activeStateIcon,
  activeStateColor = 'bg-[#E1C122]',
  completedStateIcon = 'check',
  completedStateColor = 'bg-success-main',
  isCompleted,
  isCurrent,
}: {
  activeStateIcon: PurpleIconType;
  activeStateColor?: string;
  completedStateIcon?: PurpleIconType | number;
  completedStateColor?: string;
  isCompleted: boolean;
  isCurrent: boolean;
}) => {
  if (isCompleted) {
    return (
      <div className={cn('flex h-[44px] w-[44px] items-center justify-center rounded-full', completedStateColor)}>
        {typeof completedStateIcon === 'number'
          ? (
              <Text variant="size-14" className="text-white" type="body-400">
                {completedStateIcon}
              </Text>
            )
          : (
              <PurpleIcon name={completedStateIcon} className="text-white" />
            )}
      </div>
    );
  }

  if (isCurrent) {
    return (
      <div className={cn('flex h-[44px] w-[44px] items-center justify-center rounded-full', activeStateColor)}>
        <PurpleIcon name={activeStateIcon} className="text-white" />
      </div>
    );
  }

  return (
    <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100">
      <PurpleIcon name="lock-closed" className="text-grey-400" />
    </div>
  );
};

type TSafManagerStepItemProperties = {
  activeStateIcon: PurpleIconType;
  /**
   * Tailwind CSS color class
   * @example 'bg-success-main'
   */
  activeStateColor?: string;
  completedStateIcon?: PurpleIconType | number;
  /**
   * Tailwind CSS color class
   * @example 'bg-success-main'
   */
  completedStateColor?: string;
  stepTitle: string;
  stepDescription: string;
  isCurrent: boolean;
  isBlocked: boolean;
  isCompleted: boolean;
  isTrigger?: boolean;
  buttonText?: string;
  buttonVariant?: 'primary' | 'secondary';
  onActionClick?: () => void;
};

export const SafManagerStepItem = forwardRef<
  HTMLDivElement,
  TSafManagerStepItemProperties & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      activeStateIcon,
      activeStateColor,
      completedStateIcon,
      completedStateColor,
      stepTitle,
      stepDescription,
      isCompleted,
      isBlocked,
      isTrigger = true,
      isCurrent,
      buttonText,
      buttonVariant = 'primary',
      onActionClick,
      className,
      ...restProperties
    },
    reference,
  ) => {
    return (
      <div
        ref={reference}
        className={cn('flex w-full items-center justify-between gap-3', className)}
        {...restProperties}
      >
        <div className="flex items-center gap-3">
          <StepIcon
            isCompleted={isCompleted}
            isCurrent={isCurrent}
            activeStateIcon={activeStateIcon}
            activeStateColor={activeStateColor}
            completedStateIcon={completedStateIcon}
            completedStateColor={completedStateColor}
          />
          <div className="flex flex-col">
            <Text type="body-600" className="text-left text-grey-950">
              {stepTitle}
            </Text>
            <Text variant="size-14" className="text-gray-600">
              {stepDescription}
            </Text>
          </div>
        </div>
        {buttonText && onActionClick && (
          <Button onClick={onActionClick} disabled={isBlocked} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
        {!isBlocked && !isCompleted && isTrigger && (
          <PurpleIcon
            id="accordion-trigger-icon"
            className="h-6 w-6 shrink-0 text-grey-title transition-transform duration-200"
            name="chevron-down"
          />
        )}
      </div>
    );
  },
);

SafManagerStepItem.displayName = 'SafManagerStepItem';
