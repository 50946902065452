import { Controller } from 'react-hook-form';
import { ReminderWeeklyInterval } from '@purple/shared-types';
import {
  Checkbox,
  cn,
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { DAYS_OF_WEEK_OPTIONS, ReadableReminderWeeklyInterval } from '~/constants/reminder';
import type { Control, FieldValues, Path } from 'react-hook-form';
import type { TReminderDaysOfWeek } from '@purple/shared-types';

type EnableRecurringProperties<TFormValues extends FieldValues> = {
  control: Control<TFormValues>;
  formEnableRecurring: boolean;
  enableRecurringName: Path<TFormValues>;
  endDateAndTimeName: Path<TFormValues>;
  weeklyIntervalName: Path<TFormValues>;
  daysOfWeekName: Path<TFormValues>;
};

const EnableRecurring = <TFormValues extends FieldValues>({
  control,
  formEnableRecurring,
  enableRecurringName,
  endDateAndTimeName,
  weeklyIntervalName,
  daysOfWeekName,
}: EnableRecurringProperties<TFormValues>) => {
  return (
    <>
      <Controller
        name={enableRecurringName}
        control={control}
        render={({ field }) => (
          <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
            <FormControl>
              <Checkbox {...field} value={field.value} checked={!!field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormLabel>Enable Recurring Activity</FormLabel>
          </FormItem>
        )}
      />
      {formEnableRecurring && (
        <>
          <FormField
            control={control}
            name={endDateAndTimeName}
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>End Date & Time</FormLabel>
                <FormControl>
                  <DatePicker
                    mode="single"
                    placeholder="Select date & time"
                    formatterString="PPP p"
                    isError={!!fieldState.error}
                    triggerDisabled={field.disabled}
                    defaultMonth={field.value}
                    selected={field.value}
                    disabled={{ before: new Date() }}
                    captionLayout="dropdown"
                    onDayClick={field.onChange}
                    onTimeChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name={weeklyIntervalName}
            control={control}
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Weekly Occurrence</FormLabel>
                <RadixSelect onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <RadixSelectTrigger
                      className={cn({
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select interval" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {Object.values(ReminderWeeklyInterval).map((value) => (
                      <RadixSelectItem key={value} value={value}>
                        {ReadableReminderWeeklyInterval[value]}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name={daysOfWeekName}
            control={control}
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Days of week</FormLabel>
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!fieldState.error}
                    modalPopover
                    showSearch
                    selectedOptions={DAYS_OF_WEEK_OPTIONS.filter((option) =>
                      (field.value as TReminderDaysOfWeek[])?.includes(option.value),
                    )}
                    placeholder="Select days"
                    onOptionChange={field.onChange}
                  >
                    {DAYS_OF_WEEK_OPTIONS.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={(field.value as TReminderDaysOfWeek[])?.includes(option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </>
  );
};

export { EnableRecurring };
