import { format } from 'date-fns';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { IUserEvent } from '@purple/shared-types';

export const listViewColumns: ColumnDef<IUserEvent>[] = [
  {
    accessorKey: 'time',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Time
      </Text>
    ),
    cell: ({ row }) => {
      const startTime = format(row.original.start_time, 'hh:mm');
      const endTime = row.original.end_time ? format(row.original.end_time, 'hh:mm a') : 'N/A';
      const fullDate = format(row.original.start_time, 'MMMM d, yyyy');
      const formattedDate = `${startTime} - ${endTime}, ${fullDate}`;
      return (
        <Text variant="size-14" type="body-500" className="text-nowrap text-grey-950">
          {formattedDate}
        </Text>
      );
    },
  },
  {
    accessorKey: 'action name',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Action Name
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.title}
      </Text>
    ),
  },
  {
    accessorKey: 'action type',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Action Type
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
        {snakeCaseToCapitalized(row.original.type, '—')}
      </Text>
    ),
  },
  {
    accessorKey: 'owner',
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Owner
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
        {row.original.owner}
      </Text>
    ),
  },
];
