import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { showErrorToast } from '~/shared/lib';
import type { TUserFile } from '@purple/shared-types';

type TActionsColumnProperties = {
  data: TUserFile;
};

const ActionsColumn = ({ data }: TActionsColumnProperties) => {
  const { file, title } = data;

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // TODO: create Edit File modal when we decide what to do with it
  const editClickHandler = () => {
    alert('Edit clicked'); // eslint-disable-line no-alert
  };

  const downloadClickHandler = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(file);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.append(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsDropdownOpen(false);
    } catch {
      showErrorToast('System message', 'There was an error downloading the file');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button iconLeft={<PurpleIcon name="dots-vertical" />} variant="tertiary_icon_only" size="icon_32" />
      </DropdownTrigger>
      <DropdownContent className="max-w-[150px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={editClickHandler}
          className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="pencil" />}
        >
          Edit
        </Button>
        <Button
          variant="link"
          onClick={downloadClickHandler}
          className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
          iconLeft={
            isDownloading ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />
          }
        >
          Download
        </Button>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ActionsColumn };
