import { GoogleMap, Marker } from '@react-google-maps/api';
import type React from 'react';
import type { TLocation } from '@purple/shared-types';

const DEFAULT_HEIGHT = '400px';
const DEFAULT_WIDTH = '100%';

type MapProperties = {
  location: TLocation;
  height?: string;
  width?: string;
  streetViewControl?: boolean;
  mapTypeControl?: boolean;
};

const MapComponent: React.FC<MapProperties> = ({
  location,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  streetViewControl = false,
  mapTypeControl = false,
}) => {
  const mapCenter = { lat: location.latitude, lng: location.longitude };

  return (
    <GoogleMap
      zoom={15}
      center={mapCenter}
      mapContainerStyle={{ height, width }}
      options={{
        streetViewControl,
        mapTypeControl,
      }}
    >
      <Marker position={mapCenter} />
    </GoogleMap>
  );
};

export { MapComponent };
