import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSchoolList } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolListRequestParameters } from '@purple/shared-types';

export const useSchoolList = (parameters: TSchoolListRequestParameters & { enabled?: boolean }) => {
  const { limit = 100, offset = 0, search = '', enabled, ...rest } = parameters;

  const { isError, ...props } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_LIST, limit, offset, search],
    queryFn: () => getSchoolList({ limit, offset, search, ...rest }),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch list of schools', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
