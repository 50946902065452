import { InfoItemSkeleton, Skeleton } from '@purple/ui';

const StudentProfileHeaderSkeleton = () => {
  return (
    <div className="flex flex-col gap-[22px]">
      <div className="flex flex-row justify-between gap-3">
        <div className="flex flex-1 flex-row gap-3">
          <Skeleton className="h-[60px] min-w-[60px] rounded-full" />
          <Skeleton className="flex h-[60px] flex-1 " />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <InfoItemSkeleton />
        <InfoItemSkeleton />
        <InfoItemSkeleton />
      </div>
      <div className="flex flex-row items-center gap-4">
        <Skeleton className="h-[40px] w-full " />
      </div>
    </div>
  );
};

export { StudentProfileHeaderSkeleton };
