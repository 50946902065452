import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { Button, Heading, TableFooterSection, Text } from '@purple/ui';
import { AddStudentsByIdModal, DataTable, DataTableSkeleton, StudentsDataChartModal } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useModal } from '~/hooks';
import { useSelectedStudents } from '~/hooks/useSelectedStudents';
import { useStudentsDataChartModal } from '~/hooks/useStudentsDataChartModal';
import { useGetAllStudentsMutation, useGetStudentsDataFilters, useGetStudentsDataList } from '~/queries';
import {
  FilterActionsRow,
  FilterActionsRowSkeleton,
  SelectAllStudentsRow,
  SelectedStudentsRow,
  StudentsActionsRow,
} from './components';
import { useStudentsDataColumns } from './hooks';
import type { TSelectedStudent } from '~/hooks/useSelectedStudents';

const DEFAULT_SORTING = 'flags_cy';

const StudentsData = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const ordering = searchParameters.get(SORT_QUERY_NAME) || DEFAULT_SORTING;

  const { debounceSearch, search } = useSearch();

  const grade = searchParameters.get('grade');
  const gender = searchParameters.get('gender');
  const current_color = searchParameters.get('current_color');
  const actions = searchParameters.get('actions');
  const student_status = searchParameters.get('student_status');
  const ethnicity = searchParameters.get('ethnicity');
  const priority_list = searchParameters.get('priority_list');
  const subpopulations = searchParameters.get('subpopulations');

  const { isOpen: isAddStudentsByIdOpen, openModal: openAddStudentsByIdModal } = useModal('add-students-by-id');

  const { openStudentChartModal, selectedStudentWithChart, setSelectedStudentWithChart } = useStudentsDataChartModal();

  const { data: filtersData, isLoading: isFiltersDataLoading } = useGetStudentsDataFilters();

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });

  // Set default students filters
  useEffect(() => {
    const isInitialReload = typeof student_status !== 'string' && offset === 0;

    if (isInitialReload) {
      setSearchParameters(
        (previous) => {
          const newSearchParameters = new URLSearchParams(previous);
          newSearchParameters.set('student_status', 'Active');
          return newSearchParameters;
        },
        {
          replace: true,
        },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading: isDataLoading, data } = useGetStudentsDataList({
    limit,
    offset,
    search: debounceSearch,
    ordering,
    time_period: timePeriod,
    grade,
    gender,
    current_color,
    actions,
    student_status,
    ethnicity,
    priority_list,
    subpopulations,
  });

  const { mutate: getAllStudents, isPending: isGetAllStudentsPending } = useGetAllStudentsMutation();

  const {
    selectedStudents,
    onChangeStudent,
    onRemoveBulkStudents,
    onAddBulkStudents,
    onClearAllStudents,
    onReplaceAllStudents,
  } = useSelectedStudents();

  const isSelectedAllOnPage = useMemo(() => {
    if (!data?.results) return false;
    return (
      data?.results.length > 0
      && data?.results?.every((student) => selectedStudents.some((selected) => selected.id === student.id) || false)
    );
  }, [data, selectedStudents]);

  const columns = useStudentsDataColumns({
    onCheck: onChangeStudent,
    onCheckAll: () => {
      const convertedStudents
        = data?.results?.map((student) => ({
          id: student.id,
          name: `${student.first_name} ${student.last_name}`,
        })) || [];

      if (isSelectedAllOnPage) {
        onRemoveBulkStudents(convertedStudents);
      } else {
        onAddBulkStudents(convertedStudents);
      }
    },
    selectedStudents,
    isSelectedAllOnPage,
    onShowChart: (selectedStudent) => {
      setSelectedStudentWithChart(selectedStudent);
      openStudentChartModal();
    },
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isDataLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  const studentsCountLabel = useMemo(() => {
    return data?.count ? `(${data?.count})` : '';
  }, [data]);

  const allStudentsOnPage = useMemo(() => {
    return (
      data?.results?.map((student) => ({
        id: student.id,
        name: `${student.first_name} ${student.last_name}`,
      })) || []
    );
  }, [data]);

  const addStudentsByIdHandler = (newSelectedStudents: TSelectedStudent[]) => {
    onReplaceAllStudents(newSelectedStudents);
  };

  const selectAllFoundHandler = () => {
    getAllStudents(
      {
        limit: data?.count || 0,
        offset: 0,
        search: debounceSearch,
        ordering,
        time_period: timePeriod,
        grade,
        gender,
        current_color,
        actions,
        student_status,
        ethnicity,
        priority_list,
        subpopulations,
      },
      {
        onSuccess: (allStudents) => {
          const newStudents
            = allStudents.results.map((student) => {
              return {
                id: student.id,
                name: `${student.first_name} ${student.middle_name} ${student.last_name}`,
              };
            }) || [];

          onReplaceAllStudents(newStudents);
        },
      },
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 px-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="flex items-end gap-1">
            <Heading variant="size-22" type="heading-600" className="text-grey-title">
              Students
            </Heading>
            <Text variant="size-12" type="body-600" className="pb-1 text-grey-600">
              {studentsCountLabel}
            </Text>
          </div>
          <Guard
            requiredPermissions={[
              APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
              APP_PERMISSIONS.CAN_ACCESS_MY_PRIORITY_LISTS,
            ]}
          >
            <Button variant="secondary" onClick={openAddStudentsByIdModal}>
              Add Students by ID
            </Button>
          </Guard>
        </div>
        <div className="flex flex-row items-center justify-between">
          {isFiltersDataLoading || !filtersData
            ? (
                <FilterActionsRowSkeleton />
              )
            : (
                <FilterActionsRow
                  filterConfig={convertToFilterConfig(filtersData, {
                    snakeCaseValue: false,
                  })}
                  timePeriod={timePeriod}
                  onPeriodChange={onPeriodChange}
                  onPeriodClear={onPeriodClear}
                />
              )}
          <StudentsActionsRow selectedStudents={selectedStudents} />
        </div>
        <SelectAllStudentsRow
          allStudentsCount={data?.count || 0}
          selectedStudents={selectedStudents}
          allStudentsOnPage={allStudentsOnPage}
          onSelectAll={selectAllFoundHandler}
          isSelectingAll={isGetAllStudentsPending}
        />
        <SelectedStudentsRow
          selectedStudents={selectedStudents}
          onClearAllStudents={onClearAllStudents}
          onChangeStudent={onChangeStudent}
        />
      </div>
      <div>
        <DataTable
          columns={columns}
          data={data?.results || []}
          isLoading={isDataLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No students found"
          emptyStateMessage="There are no students to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} className="h-[58px]" />}
        />
        {!isDataNotExist && (
          <TableFooterSection
            currentPage={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
      <StudentsDataChartModal student={selectedStudentWithChart} />
      {isAddStudentsByIdOpen && <AddStudentsByIdModal onSuccess={addStudentsByIdHandler} />}
    </div>
  );
};

export { StudentsData };
