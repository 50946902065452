import { Separator, Skeleton } from '@purple/ui';

export const SafDetailsSkeleton = () => {
  return (
    <aside className="flex w-full shrink-0 flex-col rounded-lg border border-grey-200 bg-white px-4 py-6 shadow-custom-heavy">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-5 w-1/2" />
        </div>
        <Skeleton className="h-5 w-10/12" />
        <div className="flex items-center gap-3">
          <Skeleton className="h-10 w-[140px]" />
          <Skeleton className="h-10 w-[120px]" />
        </div>
        <Skeleton className="h-[100px] w-full" />
      </div>
      <Separator className="my-6 bg-grey-200" />
      <div className="flex flex-col gap-4">
        {[1, 2, 3, 4, 5].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} className="h-12 w-full" />
        ))}
      </div>
    </aside>
  );
};
