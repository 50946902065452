import { useQuery } from '@tanstack/react-query';
import { getSchoolUsersAccessFilterOptions } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS, snakeToCamelWithSpaces } from '@purple/shared-utils';
import type { TSchoolUserWithAccessFilterChoices } from '@purple/shared-types';

export const useUsersWithAccessFilterOptions = (schoolId: string) => {
  const { data, isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_USERS_WITH_ACCESS_FILTERS, { schoolId }],
    queryFn: () => getSchoolUsersAccessFilterOptions({ schoolId }),
    enabled: !!schoolId,
  });

  const transformToFilterConfig = (filters: TSchoolUserWithAccessFilterChoices) => {
    return {
      categories: [
        {
          label: 'Title',
          value: 'title',
          filters: filters.title.map((title) => ({
            label: snakeToCamelWithSpaces(title),
            value: title,
          })),
        },
        {
          label: 'Status',
          value: 'is_active',
          filters: [
            {
              label: 'Active',
              value: 'true',
            },
            {
              label: 'Inactive',
              value: 'false',
            },
          ],
        },
      ],
    };
  };

  return {
    data: data && transformToFilterConfig(data),
    isError,
    ...rest,
  };
};
