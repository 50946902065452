import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Skeleton,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useCurrentUser } from '~/hooks/redux';
// helpers
import { AppRoutes } from '~/constants/routes/routes';
import { useModal } from '~/hooks';
import { showErrorToast } from '~/shared/lib';

export const UserDropdown: React.FC = () => {
  const { user, isLoading } = useCurrentUser();
  const navigate = useNavigate();
  const { openModal } = useModal('my-schools-modal');
  const { openModal: openLogoutModal } = useModal(ModalType.LOG_OUT);

  const userName = useMemo(() => user?.full_name ?? 'Unknown User', [user]);

  const mySchoolText = useMemo(() => {
    if (user?.schools.length > 1) {
      return 'My Schools';
    }
    return 'My School';
  }, [user]);

  const schoolSelectHandler = () => {
    if (user.schools.length === 0) {
      return showErrorToast(
        'System message',
        'You are not assigned to any school yet. If you believe this is an error, please contact your district administrator.',
      );
    }

    if (user.schools.length > 1) {
      openModal();
      return;
    }
    navigate(AppRoutes.App.School.Root.makePath(user.schools[0]?.id, 'overview'));
  };

  if (isLoading || !user) {
    return <Skeleton className="h-9 w-9 rounded-lg" />;
  }

  return (
    <DropdownRoot>
      <DropdownTrigger className="rounded-lg border border-transparent transition-colors duration-200 hover:border-brand-blue-700 focus-visible:border-brand-blue-700 focus-visible:outline-none">
        <Avatar size={36} variant="square">
          <AvatarImage src={user.avatar ?? undefined} alt={userName} />
          <AvatarFallback>{getInitialsFromName(userName)}</AvatarFallback>
        </Avatar>
      </DropdownTrigger>
      <DropdownContent align="end" sideOffset={4} className="min-w-[232px] border-brand-blue-700">
        <DropdownItem asChild>
          <Link
            to={AppRoutes.App.Profile.Root.path}
            className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
          >
            <PurpleIcon name="user" className="mr-2 h-5 w-5 shrink-0" />
            My Profile
          </Link>
        </DropdownItem>
        <DropdownItem
          className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
          onSelect={schoolSelectHandler}
        >
          <PurpleIcon name="office-building" className="mr-2 h-5 w-5 shrink-0" />
          {mySchoolText}
        </DropdownItem>
        <DropdownItem asChild>
          <Link
            to={AppRoutes.App.District.Settings.path}
            className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
          >
            <PurpleIcon name="cog" className="mr-2 h-5 w-5 shrink-0" />
            District Settings
          </Link>
        </DropdownItem>
        <DropdownItem
          iconName="logout"
          onSelect={openLogoutModal}
          className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
        >
          Log Out
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};
