import { useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ACTIVITY_TYPE_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
// hooks
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { ModalType } from '~/constants/modals';
import { useCurrentUser, useModal } from '~/hooks';
import { useActivities, useBulkDeleteActivity, useDeleteActivity } from '~/queries';
// constants
import { TIME_PERIODS } from '~/constants/options';
// components
import { CallToActionModal, DataTable, DataTableSkeleton } from '~/components';
import { ActivitiesHeaderSection } from './ActivitiesHeaderSection';
import { useActivityColumns } from './useActivityColumns';
// types
import type { TActivity } from '@purple/shared-types';

export const Activities: React.FC = () => {
  const { schoolId } = useParams();

  const { openModal: openDeleteActivityModal, closeModal: closeDeleteActivityModal } = useModal(
    ModalType.DELETE_ACTIVITY,
  );
  const { closeModal: closeBulkDeleteActivityModal } = useModal(ModalType.BULK_DELETE_ACTIVITY);
  const { user } = useCurrentUser();

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);
  const activityType = searchParameters.get(ACTIVITY_TYPE_QUERY_NAME);
  const { page, limit, offset, onPageChange, onLimitChange } = usePaginate();
  const { timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });

  const [deleteActivityId, setDeleteActivityId] = useState<string | null>(null);
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);

  const { data, isFetching } = useActivities({
    limit,
    offset,
    search,
    ordering: sort,
    community_activity_type: activityType,
    time_period: timePeriod,
    school: schoolId as string,
  });
  const { mutate: deleteActivity, isPending: isDeletePending } = useDeleteActivity();
  const { mutate: bulkDeleteActivity, isPending: isBulkDeletePending } = useBulkDeleteActivity();

  const activities = useMemo(() => data?.results || [], [data?.results]);
  const pageCount = useMemo(() => (data?.count ? Math.ceil(data.count / limit) : 1), [data?.count, limit]);
  const isDataAvailable = useMemo(
    () => activities.length > 0 && !isFetching,
    [activities, isFetching],
  );
  const isAllSelected = useMemo(
    () =>
      selectedActivityIds.length > 0
        ? activities.filter((activity) => activity.created_by?.id === user.id).every((activity) => selectedActivityIds.includes(activity.id))
        : false,
    [activities, selectedActivityIds, user.id],
  );

  const selectActivityHandler = useCallback(
    (activity: TActivity) => {
      const index = selectedActivityIds.indexOf(activity.id);
      setSelectedActivityIds((previous) =>
        index === -1 ? [...previous, activity.id] : previous.filter((id) => id !== activity.id),
      );
    },
    [selectedActivityIds],
  );

  const selectAllActivitiesHandler = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedActivityIds(activities.filter((activity) => activity.created_by?.id === user.id).map((activity) => activity.id));
      } else {
        setSelectedActivityIds([]);
      }
    },
    [activities, user.id],
  );

  const deleteActivityClickHandler = useCallback(
    (activityId: string) => {
      setDeleteActivityId(activityId);
      openDeleteActivityModal();
    },
    [openDeleteActivityModal],
  );

  const deleteActivityHandler = useCallback(() => {
    if (!deleteActivityId) return;

    deleteActivity(deleteActivityId, {
      onSuccess: () => {
        setSelectedActivityIds((previous) => previous.filter((id) => id !== deleteActivityId));
        setDeleteActivityId(null);
        closeDeleteActivityModal();
      },
    });
  }, [deleteActivity, deleteActivityId, closeDeleteActivityModal]);

  const bulkDeleteActivityHandler = useCallback(() => {
    if (selectedActivityIds.length === 0) return;
    bulkDeleteActivity(
      { activities: selectedActivityIds },
      {
        onSuccess: () => {
          const isDeletedWasSelected
            = deleteActivityId === null ? false : selectedActivityIds.includes(deleteActivityId);
          if (isDeletedWasSelected) {
            setDeleteActivityId(null);
          }
          setSelectedActivityIds([]);
          closeBulkDeleteActivityModal();
        },
      },
    );
  }, [bulkDeleteActivity, selectedActivityIds, closeBulkDeleteActivityModal, deleteActivityId]);

  const activityColumns = useActivityColumns({
    isAllSelected,
    selectedActivityIds,
    onActivitySelect: selectActivityHandler,
    onSelectAll: selectAllActivitiesHandler,
    onDelete: deleteActivityClickHandler,
  });

  if (!schoolId) return null;

  return (
    <section className="flex w-full flex-col gap-6">
      <Heading tag="h2" className="px-4 pt-6 text-lg font-semibold text-grey-title">
        Community Activities
      </Heading>
      <div className="flex w-full flex-col gap-5">
        <ActivitiesHeaderSection isActivitiesSelected={selectedActivityIds.length > 0} />
        <div>
          <DataTable
            columns={activityColumns}
            data={activities}
            isLoading={isFetching}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No activities found"
            emptyStateMessage="It looks like we haven’t found any community activities. If something went wrong, please reach out to support."
            classNames={{
              wrapper: 'mb-0',
              cell: 'py-3 h-14',
              emptyCell: 'py-8',
            }}
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {isDataAvailable && (
            <TableFooterSection
              currentPage={page}
              rowsPerPage={limit}
              pageCount={pageCount}
              totalRows={data?.count || 0}
              onPageChange={onPageChange}
              onRowsPerPageChange={onLimitChange}
            />
          )}
        </div>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_ACTIVITY}
        modalTitle="Delete Activity"
        modalDescription="Clicking the delete button will permanently remove this activity from the system."
        modalTextContent="Are you sure you want to delete this activity?"
        onPrimaryButtonClick={deleteActivityHandler}
        isLoading={isDeletePending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_ACTIVITY}
        modalTitle="Delete selected activities"
        modalDescription="Clicking the delete button will permanently remove selected activities from the system."
        modalTextContent="Are you sure you want to delete selected activities?"
        onPrimaryButtonClick={bulkDeleteActivityHandler}
        isLoading={isBulkDeletePending}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Delete"
      />
    </section>
  );
};
