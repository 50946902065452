import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PurpleIcon } from '@purple/icons';
import { Banner, BannerDescription, Button, cn } from '@purple/ui';
import { useArabicLanguage } from '../hooks';
import type React from 'react';

export const SafBanner: React.FC = () => {
  const [hideBanner, setHideBanner] = useState<boolean>(false);

  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const closeBannerClickHandler = () => {
    setHideBanner(true);
  };

  if (hideBanner) {
    return null;
  }

  return (
    <Banner
      className={cn({
        '[&>button]:left-4 [&>button]:right-[unset]': isArabic,
      })}
    >
      <PurpleIcon
        name="information-circle"
        className={cn('h-5 w-5', {
          'absolute right-4 top-3': isArabic,
        })}
      />
      <Button
        type="button"
        aria-label="Close banner"
        variant="primary"
        size="icon_32"
        iconLeft={<PurpleIcon name="X" />}
        className={cn('z-10 bg-brand-blue-500 text-white')}
        onClick={closeBannerClickHandler}
      />
      <BannerDescription>
        <Trans
          i18nKey="banner"
          t={t}
          components={{
            telLink: (
              <a
                href="tel:988"
                className="font-semibold underline underline-offset-2 outline-none transition-colors hover:text-brand-blue-900 focus:text-brand-blue-900 focus-visible:text-brand-blue-900"
              >
                988
              </a>
            ),
          }}
        />
      </BannerDescription>
    </Banner>
  );
};
