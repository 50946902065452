import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IStudentHomeInformation } from '@purple/shared-types';

type StudentHomeInformationRequestBody = Pick<
  IStudentHomeInformation,
  'relationship_to_the_child' | 'other_family_configuration' | 'family_size' | 'with_whom_does_the_child_live'
>;

type StudentHomeInformationRequest = {
  studentId: string;
  body: StudentHomeInformationRequestBody;
};

type StudentHomeInformationResponseBody = {} & IStudentHomeInformation;

export const updateStudentHomeInformtaion = async (request: StudentHomeInformationRequest) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.patch<StudentHomeInformationResponseBody>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.UPDATE_STUDENT_HOME_INFORMATION, request.studentId),
    request.body,
  );
  return response.data;
};
