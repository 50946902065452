import { useEffect, useId } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { SAF_PRIORITY } from '@purple/shared-types';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useUpdateSafDetails } from '~/queries/saf/useUpdateSafDetails';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import { safPriorityFormSchema } from './saf-priority-form-schema';
import type * as z from 'zod';
import type { TSafDetailsDto } from '@purple/shared-types';

const SAF_PRIORITY_SELECT_OPTIONS = [
  { value: SAF_PRIORITY.LOW, label: 'Low' },
  { value: SAF_PRIORITY.MEDIUM, label: 'Medium' },
  { value: SAF_PRIORITY.HIGH, label: 'High' },
];

type TFormValues = z.infer<typeof safPriorityFormSchema>;

type TSafPriorityModalProperties = {
  safId?: string | null;
};

export const SafPriorityModal = ({ safId }: TSafPriorityModalProperties) => {
  const queryClient = useQueryClient();
  const formId = useId();
  const { isOpen, toggleModal, closeModal } = useModal('saf-priority');

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { mutate, isPending } = useUpdateSafDetails();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(safPriorityFormSchema),
    defaultValues: {
      priority: safDetailsData?.priority ?? undefined,
    },
  });

  const updateSafPriority = (values: TFormValues) => {
    if (!safId) {
      return showErrorToast('System Message', 'Unable to update priority. Check if the SAF is valid.');
    }
    mutate(
      {
        safId,
        requestBody: {
          priority: values.priority,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast('System Message', 'Priority updated successfully');
          queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, safId] });
          closeModal();
        },
        onError: () => showErrorToast('System Message', 'Unable to update priority'),
      },
    );
  };

  useEffect(() => {
    form.reset({
      priority: safDetailsData?.priority ?? undefined,
    });
  }, [safDetailsData?.priority, form]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <DialogTitle>Change SAF Priority</DialogTitle>
            <DialogDescription className="sr-only">Change SAF priority modal</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form providerProps={form} onSubmit={form.handleSubmit(updateSafPriority)} className="p-6" id={formId}>
          <FormField
            control={form.control}
            name="priority"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Select New Priority</FormLabel>
                <RadixSelect onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                  <FormControl>
                    <RadixSelectTrigger
                      className={cn({
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select priority" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {SAF_PRIORITY_SELECT_OPTIONS.map((priority) => (
                      <RadixSelectItem key={priority.value} value={priority.value}>
                        {priority.label}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} variant="primary" disabled={isPending} isLoading={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
