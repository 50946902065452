import { getAxiosInstance } from '../../../api';
import { STUDENTS_ENDPOINTS } from '../../../constants/private-api';
import { replaceUrlParameters } from '../../../helpers';
import type {
  IPaginatedResponse,
  TStudentMeetingAttendanceListItem,
  TStudentMeetingAttendanceRequestParameters,
} from '@purple/shared-types';

export const getStudentMeetingAttendance = async ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentMeetingAttendanceRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(STUDENTS_ENDPOINTS.GET_STUDENT_MEETING_ATTENDANCE_LIST, studentId);

  const response = await axiosInstance.get<IPaginatedResponse<TStudentMeetingAttendanceListItem[]>>(URL_PATH, {
    params: queryParameters,
  });
  return response.data;
};
