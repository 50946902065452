import { Skeleton } from '@purple/ui';

export const SafTabsSkeleton = () => {
  return (
    <div className="col-span-2 flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white p-4 shadow-custom-heavy">
      <div className="flex gap-4">
        <Skeleton className="h-10 w-[180px]" />
        <Skeleton className="h-10 w-[150px]" />
        <Skeleton className="h-10 w-[125px]" />
        <Skeleton className="h-10 w-[120px]" />
      </div>
      <div className="flex flex-col gap-1">
        <Skeleton className="h-6 w-1/3" />
        <Skeleton className="h-6 w-1/4" />
      </div>
      <div className="flex flex-col gap-2">
        {[1, 2, 3, 4, 5].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} className="h-20 w-full" />
        ))}
      </div>
    </div>
  );
};
