import { useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { PAGE_VIEW_QUERIES, type TPageViewQuery } from '@purple/shared-types';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';

type TListItemOptionsProperties = {
  view?: TPageViewQuery;
  onRemove: () => void;
  onShare: () => void;
  onDetailClick?: () => void;
  isOwner: boolean;
};

const ListItemOptions = ({
  view = PAGE_VIEW_QUERIES.LIST_VIEW,
  onRemove,
  onShare,
  onDetailClick,
  isOwner,
}: TListItemOptionsProperties) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const shareClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    onShare();
  };

  const deleteClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    onRemove();
  };

  const detailClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    onDetailClick?.();
  };

  const isListView = useMemo(() => view === PAGE_VIEW_QUERIES.LIST_VIEW, [view]);

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          iconLeft={<PurpleIcon name="dots-vertical" />}
          variant={isListView ? 'tertiary_icon_only' : 'secondary'}
          size={isListView ? 'icon_32' : 'icon_40'}
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[196px] gap-1" align="end" sideOffset={-2}>
        {isListView && (
          <Button
            variant="link"
            onClick={detailClickHandler}
            className="w-full justify-start text-grey-950 hover:text-grey-950 active:text-grey-950"
            iconLeft={<PurpleIcon name="pencil" />}
          >
            Edit Priority List
          </Button>
        )}

        <Button
          variant="link"
          onClick={shareClickHandler}
          className="w-full justify-start text-grey-950 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="share" />}
        >
          Share Priority List
        </Button>
        {isOwner && (
          <Button
            variant="link"
            className="w-full justify-start text-error-main hover:text-error-main active:text-error-main"
            onClick={deleteClickHandler}
            iconLeft={<PurpleIcon name="trash" />}
          >
            Delete List
          </Button>
        )}
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ListItemOptions };
