import { ReminderDaysOfWeek, ReminderType, ReminderWeeklyInterval } from '@purple/shared-types';

export const ReadableReminderType = {
  [ReminderType.ONE_TIME]: 'One-time',
  [ReminderType.RECURRING]: 'Recurring',
} as const;

export const ReadableReminderDaysOfWeek = {
  [ReminderDaysOfWeek.MONDAY]: 'Monday',
  [ReminderDaysOfWeek.TUESDAY]: 'Tuesday',
  [ReminderDaysOfWeek.WEDNESDAY]: 'Wednesday',
  [ReminderDaysOfWeek.THURSDAY]: 'Thursday',
  [ReminderDaysOfWeek.FRIDAY]: 'Friday',
  [ReminderDaysOfWeek.SATURDAY]: 'Saturday',
  [ReminderDaysOfWeek.SUNDAY]: 'Sunday',
} as const;

export const ReadableReminderWeeklyInterval = {
  [ReminderWeeklyInterval.ALTERNATE]: 'Alternate',
  [ReminderWeeklyInterval.EVERY]: 'Every',
  [ReminderWeeklyInterval.FIRST]: 'First',
  [ReminderWeeklyInterval.SECOND]: 'Second',
  [ReminderWeeklyInterval.THIRD]: 'Third',
  [ReminderWeeklyInterval.FOURTH]: 'Fourth',
  [ReminderWeeklyInterval.FIFTH]: 'Fifth',
} as const;

export const DAYS_OF_WEEK_OPTIONS = Object.values(ReminderDaysOfWeek).map((value) => ({
  value,
  label: ReadableReminderDaysOfWeek[value],
}));
