import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  Skeleton,
  Textarea,
} from '@purple/ui';
// hooks
import { useSafNeeds } from '~/queries';
import { useArabicLanguage } from '../hooks';
// components
import { DetailsDescription } from './DetailsDescription';
// types
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { TSafTranslations } from '@purple/shared-types';
import type { safFormSchema } from './schema';

type TObservationFormProperties = {
  /**
   * The form object that contains the form state and methods.
   */
  form: UseFormReturn<z.infer<typeof safFormSchema>>;
  /**
   * The transition delta that determines the direction of the form transition.
   */
  transitionDelta: number;
};

export const ObservationForm: React.FC<TObservationFormProperties> = (props) => {
  const { form, transitionDelta } = props;

  const { data: needs = [], isFetching } = useSafNeeds();

  const { t, i18n } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const submitterType = form.watch('submitter');
  const isSelfStudent = form.watch('myself');

  return (
    <motion.div
      initial={{ x: transitionDelta >= 0 ? '50%' : '-50%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="grid w-full grid-cols-2 gap-x-4 gap-y-6"
    >
      {isFetching
        ? (
            <div className="col-span-2 flex w-full flex-col space-y-2">
              <Skeleton className="h-[17px] w-44" />
              <div className="grid gap-2">
                <div className="flex items-center gap-x-2 space-y-0">
                  <Skeleton className="h-5 w-5" />
                  <Skeleton className="h-5 w-60" />
                </div>
                <div className="flex items-center gap-x-2 space-y-0">
                  <Skeleton className="h-5 w-5" />
                  <Skeleton className="h-5 w-52" />
                </div>
                <div className="flex items-center gap-x-2 space-y-0">
                  <Skeleton className="h-5 w-5" />
                  <Skeleton className="h-5 w-56" />
                </div>
                <div className="flex items-center gap-x-2 space-y-0">
                  <Skeleton className="h-5 w-5" />
                  <Skeleton className="h-5 w-64" />
                </div>
              </div>
            </div>
          )
        : (
            <FormField
              control={form.control}
              name="needs"
              render={({ field }) => (
                <FormItem className="col-span-2 space-y-2">
                  <FormLabel required>{t('form.observation.type.label')}</FormLabel>
                  <FormControl>
                    <RadioGroup onValueChange={field.onChange} value={field.value} dir={isArabic ? 'rtl' : 'ltr'}>
                      {needs.map(({ id, translations }) => (
                        <FormItem key={id} className="flex items-center gap-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={id} />
                          </FormControl>
                          <FormLabel>{translations[i18n.language as TSafTranslations]}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

      <div className="col-span-2 flex w-full flex-col gap-2">
        <DetailsDescription submitterType={submitterType} isSelfStudent={isSelfStudent} />
        <FormField
          control={form.control}
          name="details"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.observation.details.label')}</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.details}
                  placeholder={t('form.observation.details.placeholder')}
                  responsiveHeight
                  className="min-h-[120px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </motion.div>
  );
};
