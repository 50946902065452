import { STUDENT_GRADES_FILTERS } from '@purple/shared-types';

export const SORT_OPTIONS = [
  {
    label: 'Current Grade Level (ASC)',
    value: 'current_grade_level',
  },
  {
    label: 'Current Grade Level (DESC)',
    value: '-current_grade_level',
  },
  {
    label: 'Subject (ASC)',
    value: 'subject',
  },
  {
    label: 'Subject (DESC)',
    value: '-subject',
  },
  {
    label: 'Course Name (ASC)',
    value: 'course_name',
  },
  {
    label: 'Course Name (DESC)',
    value: '-course_name',
  },
  {
    label: 'Grade (ASC)',
    value: 'academic_grade_display',
  },
  {
    label: 'Grade (DESC)',
    value: '-academic_grade_display',
  },
  {
    label: 'Cycle (ASC)',
    value: 'cycle',
  },
  {
    label: 'Cycle (DESC)',
    value: '-cycle',
  },
  {
    label: 'Last Sync Date (ASC)',
    value: 'last_modified_date',
  },
  {
    label: 'Last Sync Date (DESC)',
    value: '-last_modified_date',
  },
];

export const FILTER_TABS = [
  {
    label: 'Grade Level',
    value: STUDENT_GRADES_FILTERS.GRADE_LEVEL,
  },
  {
    label: 'Subject',
    value: STUDENT_GRADES_FILTERS.SUBJECT,
  },
  {
    label: 'Course Name',
    value: STUDENT_GRADES_FILTERS.COURSE_NAME,
  },
  {
    label: 'Grade',
    value: STUDENT_GRADES_FILTERS.GRADE,
  },
  {
    label: 'Cycle',
    value: STUDENT_GRADES_FILTERS.CYCLE,
  },
];
