import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionPurpleUserParticipants } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActionPurpleUserParticipantsRequest } from '@purple/shared-types';

export const useActionPurpleUserParticipants = (
  actionId: string,
  parameters?: TActionPurpleUserParticipantsRequest,
  options?: { enabled?: boolean },
) => {
  const { enabled } = options || {};
  const { isError, ...props } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.PURPLE_USER_PARTICIPANTS, actionId, parameters],
    queryFn: () => getActionPurpleUserParticipants(actionId, parameters),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast(
        'Failed to load purple user participants for this action',
        'Please try again later or contact support',
      );
    }
  }, [isError]);

  return { ...props, isError };
};
