import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { AccessDenied, Heading, Text } from '@purple/ui';
import { SafListManager } from '~/modules/SafListManager';

export const SafListManagerPage: React.FC = () => {
  return (
    <Guard
      requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_SAF_LIST_MANAGER]}
      fallback={(
        <AccessDenied
          iconSize={24}
          accessDeniedMessage="You do not have permission to view this page."
          className="h-full w-full rounded-lg border border-grey-200 bg-white p-6 py-[200px]"
        />
      )}
    >
      <div className="flex w-full flex-col gap-8">
        <div className="flex flex-col gap-1">
          <Heading variant="size-28" type="heading-600" tag="h1" className="text-grey-title">
            SAF List Manager
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-950">
            Process your SAFs / keep track of this year&apos;s SAFs through the SAF List Manager
          </Text>
        </div>
        <div className="col-span-2 flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
          <SafListManager />
        </div>
      </div>
    </Guard>
  );
};
