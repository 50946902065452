import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { IStudentDashboardResponse } from '@purple/shared-types';

export const getStudentDashboard = async (studentId: string) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<IStudentDashboardResponse>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_DASHBOARD, studentId),
  );

  return response.data;
};
