import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppSelectedFiltersList, BackButton, Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useSubPageView } from '~/hooks';
import { useStudentActionsList } from '~/queries';
import { DASHBOARD_VIEW, STUDENT_ACTIONS_FILTERS } from '../constants';
import { ActionsHeaderSection } from './ActionsHeaderSection';
import { ActionsHeaderSectionSkeleton } from './ActionsHeaderSectionSkeleton';
import { studentActionsColumns } from './studentActionsColumns';

const TotalActions = () => {
  const { studentId } = useParams();

  const { changeView } = useSubPageView({
    defaultView: DASHBOARD_VIEW.TOTAL_ACTIONS,
  });

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME);
  const sort = searchParameters.get(SORT_QUERY_NAME);

  const { timePeriod, onPeriodChange, onPeriodClear } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data, isLoading } = useStudentActionsList({
    studentId: studentId as string,
    queryParameters: {
      limit,
      offset,
      ordering: sort,
      search,
      document_as: searchParameters.get('document_as'),
      time_period: timePeriod,
    },
  });

  const isDataNotExist = useMemo(
    () => !data?.results || (data.results.length === 0 && !isLoading),
    [data?.results, isLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 py-6">
      <div className="flex flex-col gap-4 px-4">
        <BackButton label="Back to Dashboard" onClick={() => changeView(DASHBOARD_VIEW.DASHBOARD)} />
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Total actions
        </Heading>
        <div className="flex flex-row">
          {isLoading
            ? (
                <ActionsHeaderSectionSkeleton />
              )
            : (
                <ActionsHeaderSection
                  actionsAmount={data?.totals}
                  timePeriod={timePeriod}
                  onPeriodChange={onPeriodChange}
                  onPeriodClear={onPeriodClear}
                />
              )}
        </div>
        <AppSelectedFiltersList config={STUDENT_ACTIONS_FILTERS} maxDisplayedFilters={8} />
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <DataTable
            columns={studentActionsColumns}
            data={data?.results || []}
            isLoading={isLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No Actions found"
            emptyStateMessage="There are no Actions to display. If it an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { TotalActions };
