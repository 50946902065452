import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafLinkedActions, TSafLinkedActionsRequestParameters } from '@purple/shared-types';

export const getSafActions = async ({
  safId,
  requestParameters,
}: {
  safId: string;
  requestParameters: TSafLinkedActionsRequestParameters;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<TSafLinkedActions>(replaceUrlParameters(ACTIONS.GET_SAF_ACTIONS, safId), {
    params: requestParameters,
  });
  return response.data;
};
