import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import type { TActivityChoiceResponse, TActivityChoicesRequestBody } from '@purple/shared-types';

export const postActivityChoices = async (body: TActivityChoicesRequestBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.post<TActivityChoiceResponse>(ACTIVITY_ENDPOINTS.CHOICES, body);
  return response.data;
};
