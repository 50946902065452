import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentGradesFilterOptions } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useGetStudentGradesFilterOptions = (studentId: string | undefined) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_GRADES_FILTERS, studentId],
    queryFn: () => getStudentGradesFilterOptions(studentId as string),
    enabled: !!studentId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student grades filters', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return {
    isError,
    ...rest,
  };
};
