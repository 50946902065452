import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { SAF_STATUS, type TSafListManagerDto } from '@purple/shared-types';
import { Button } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { SafListItemOptions } from '../SafListItemOptions';

type TActionsColumnProperties = {
  data: TSafListManagerDto;
  onSafIdChange: (safId: string) => void;
};

const ActionsColumn = ({ data, onSafIdChange }: TActionsColumnProperties) => {
  const canCloseSAF = useMemo(() => {
    const isStudentLinked = Boolean(data.student);
    const isPriorityExist = Boolean(data.priority);
    const hasDocumentActions = Boolean(data.documented_actions);
    return isStudentLinked && isPriorityExist && hasDocumentActions;
  }, [data]);

  const { openModal: openCloseSafModal } = useModal(ModalType.CLOSE_SAF);

  const isClosed = useMemo(() => data.status === SAF_STATUS.CLOSED, [data]);

  const closeSafClickHandler = () => {
    onSafIdChange(data.id);
    openCloseSafModal();
  };

  return (
    <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_SAF_PROCESSING]}>
      <div className="flex justify-end gap-1">
        {!isClosed && (
          <Button
            disabled={!canCloseSAF}
            variant="tertiary_icon_only"
            size="icon_32"
            iconLeft={<PurpleIcon name="clipboard-check" />}
            onClick={closeSafClickHandler}
          />
        )}
        <SafListItemOptions data={data} onSafIdChange={onSafIdChange} />
      </div>
    </Guard>
  );
};

export { ActionsColumn };
