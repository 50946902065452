import { Text } from '@purple/ui';
import { IncidentNameComponent } from './IncidentNameComponent';
import { IncidentTypeComponent } from './IncidentTypeComponent';
import type { ColumnDef } from '@tanstack/react-table';
import type { IStudentIncident } from '@purple/shared-types';

export const studentIncidentsColumns: ColumnDef<IStudentIncident>[] = [
  {
    accessorKey: 'name',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        INCIDENT NAME
      </Text>
    ),
    cell: ({ row }) => <IncidentNameComponent name={row.original.name} incidentId={row.original.id} />,
  },
  {
    accessorKey: 'incident_type',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-center text-grey-600">
        TYPE
      </Text>
    ),
    cell: ({ row }) => <IncidentTypeComponent type={row.original.incident_type} />,
  },
  {
    accessorKey: 'incident_date_time',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        INCIDENT DATE
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="whitespace-nowrap text-grey-950">
        {row.original.incident_date_time}
      </Text>
    ),
  },
  {
    accessorKey: 'description',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600">
        DESCRIPTION
      </Text>
    ),
    cell: ({ row }) => (
      <div className="max-w-[184px]">
        <Text variant="size-14" type="body-500" className="truncate text-grey-950">
          {row.original.description}
        </Text>
      </div>
    ),
  },
];
