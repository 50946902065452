import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, Heading, Text } from '@purple/ui';

const COMPARE_TYPES = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
  SAME: 'same',
} as const;

type TOverviewStatisticCardProperties = {
  title: string;
  value: number;
  onViewReport: () => void;
  betterIs: (typeof COMPARE_TYPES)[keyof typeof COMPARE_TYPES];
};

const OverviewStatisticCard = ({ title, value, onViewReport, betterIs }: TOverviewStatisticCardProperties) => {
  const type = useMemo(() => {
    if (value === 0) {
      return COMPARE_TYPES.SAME;
    }
    if (value > 0) {
      return COMPARE_TYPES.INCREASE;
    }

    return COMPARE_TYPES.DECREASE;
  }, [value]);

  const description = useMemo(() => {
    if (type === COMPARE_TYPES.SAME) {
      return 'No change compared to last year';
    }
    if (type === COMPARE_TYPES.INCREASE) {
      return 'Increase compared to last year';
    }
    return 'Decrease compared to last year';
  }, [type]);

  const percentage = useMemo(() => {
    if (type === COMPARE_TYPES.SAME) {
      return value;
    }
    return `${Math.abs(value)}%`;
  }, [type, value]);

  const chartIcon = useMemo(() => {
    if (type === COMPARE_TYPES.SAME) {
      return null;
    }
    if (type === COMPARE_TYPES.INCREASE) {
      return <PurpleIcon name="trending-up" height={32} width={32} className="text-grey-400" />;
    }
    return <PurpleIcon name="trending-down" height={32} width={32} className="text-grey-400" />;
  }, [type]);

  return (
    <div className="flex flex-row items-end justify-between rounded-lg border border-grey-200 bg-white px-6 py-4">
      <div className="flex flex-col gap-2">
        <Heading tag="h4" variant="size-18" type="heading-500" className="text-grey-title">
          {title}
        </Heading>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-1">
            <Heading
              tag="h5"
              variant="size-36"
              type="heading-700"
              className={cn({
                'text-error-main':
                  (type === COMPARE_TYPES.DECREASE && betterIs === COMPARE_TYPES.INCREASE)
                  || (type === COMPARE_TYPES.INCREASE && betterIs === COMPARE_TYPES.DECREASE),
                'text-success-main':
                  (type === COMPARE_TYPES.INCREASE && betterIs === COMPARE_TYPES.INCREASE)
                  || (type === COMPARE_TYPES.DECREASE && betterIs === COMPARE_TYPES.DECREASE),
                'text-brand-yellow': type === COMPARE_TYPES.SAME,
              })}
            >
              {percentage}
            </Heading>
            {chartIcon}
          </div>
          <Text variant="size-12" type="body-400" className="text-grey-600">
            {description}
          </Text>
        </div>
      </div>
      <Button variant="link" size="link" onClick={onViewReport} className="text-brand-blue-700">
        View Report
      </Button>
    </div>
  );
};

export { OverviewStatisticCard };
