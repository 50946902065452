import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { SchoolInfoSidebar, SchoolTabs } from '~/modules/Schools';

export const School: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-1 gap-y-6 2xl:grid-cols-3 2xl:gap-x-6 2xl:gap-y-0">
      <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_SCHOOL_PROFILE]}>
        <SchoolInfoSidebar />
      </Guard>

      <SchoolTabs />
    </div>
  );
};
