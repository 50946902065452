import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { formatDateToLocalTime, formatMinutesToDuration, snakeCaseToCapitalized } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NoDataAvailable,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { MinimalTiptapEditor } from '~/components/RichTextEditor';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector, useModal } from '~/hooks';
import { useNotesList } from '~/queries';
import { useActionDetails, useDeleteAction } from '~/queries/actions';
import { showErrorToast } from '~/shared/lib';
import { userSelector } from '~/store/features/user';
import { CallToActionModal } from '../../CallToActionModal';
import { RescheduleActionModal } from '../RescheduleActionModal';
import { ActionEventModalSkeleton } from './ActionEventModalSkeleton';

type TActionEventModalProperties = {
  eventId: string | null;
  onChangeSelectedEventId?: (id: string | null) => void;
};

export const ActionEventModal = ({ eventId, onChangeSelectedEventId }: TActionEventModalProperties) => {
  const { id } = useAppSelector(userSelector);

  const navigate = useNavigate();

  const { isOpen, toggleModal } = useModal('action-event-modal');
  const {
    isOpen: isDeleteActinOpen,
    openModal: openDeleteActionModal,
    closeModal: closeActionDeleteModal,
  } = useModal('delete-action-event-modal');
  const { isOpen: isRescheduleModalOpen, openModal: openRescheduleModal } = useModal('reschedule-action-event-modal');

  const { data, isLoading, isError } = useActionDetails(eventId as string);
  const { data: notesData } = useNotesList({ action: eventId as string });
  const { mutate, isPending } = useDeleteAction();

  const { title, details, date_and_time_details } = data || {};

  const notes = useMemo(() => notesData?.results ?? [], [notesData?.results]);
  const sharedNote = useMemo(() => notes.find((note) => !note.is_private), [notes]);

  const isEventOwner = useMemo(() => {
    return data?.details.created_by?.id === id;
  }, [data?.details.created_by?.id, id]);

  const navigateToActionDetailsHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while navigating to action details');
    }
    navigate(AppRoutes.App.Actions.Details.makePath(eventId));
    toggleModal(!isOpen);
  };

  const toggleModalCloseHandler = () => {
    onChangeSelectedEventId?.(null);
    toggleModal(!isOpen);
  };

  const deleteActionHandler = () => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while deleting action');
    }
    mutate(eventId, {
      onSuccess: () => {
        toggleModalCloseHandler();
        closeActionDeleteModal();
      },
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={toggleModalCloseHandler}>
        <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle>Action</DialogTitle>
            <DialogDescription className="sr-only">Action event modal</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          {(!eventId || isError) && <NoDataAvailable iconName="folder" className="h-[368px] text-grey-600" />}
          {eventId && (
            <ScrollArea
              type="auto"
              className="flex max-h-[640px] w-full flex-col p-0"
              scrollBarClassName="p-2 w-[22px]"
            >
              {isLoading && !data && <ActionEventModalSkeleton />}
              {data && !isLoading && (
                <div className="flex flex-col gap-6 p-6">
                  <div className="flex items-center justify-between gap-2">
                    <Text variant="size-16" type="body-600">
                      {title}
                    </Text>
                    {isEventOwner && (
                      <div className="flex items-center gap-4">
                        <Button variant="secondary" className="px-4 py-2.5" onClick={openRescheduleModal}>
                          Reschedule Action
                        </Button>
                        <Button variant="secondary" className="px-4 py-2.5" onClick={openDeleteActionModal}>
                          Delete Action
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-3">
                    <Text variant="size-16" type="body-600">
                      Description
                    </Text>
                    <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
                      <Text variant="size-14" type="body-500">
                        Student:
                      </Text>
                      <Link
                        to={AppRoutes.App.Students.StudentDetails.Root.makePath(
                          details?.student?.id ?? '',
                          'dashboard',
                        )}
                        onClick={toggleModalCloseHandler}
                        className="w-max font-primary text-sm font-semibold text-brand-blue-800 hover:underline hover:underline-offset-2"
                      >
                        {details?.student?.full_name}
                      </Link>
                      <Text variant="size-14" type="body-500">
                        Type:
                      </Text>
                      <Text variant="size-14">{snakeCaseToCapitalized(details?.type, '—')}</Text>

                      <Text variant="size-14" type="body-500">
                        Subtype:
                      </Text>
                      <Text variant="size-14">{snakeCaseToCapitalized(details?.subtype, '—')}</Text>
                      {sharedNote && (
                        <>
                          <Text variant="size-14" type="body-500">
                            Shared Notes:
                          </Text>
                          <MinimalTiptapEditor
                            value={sharedNote.text}
                            disabled
                            placeholder="No shared notes"
                            onValueChange={() => {}}
                            contentClass="h-full"
                            className={cn('max-h-[300px] min-h-[150px] w-full overflow-y-auto')}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <Text variant="size-16" type="body-600">
                      Date & Time
                    </Text>
                    <div className="grid grid-cols-[max-content,1fr] gap-x-6 gap-y-2">
                      <Text variant="size-14" type="body-500">
                        Start Date:
                      </Text>
                      <Text variant="size-14">{formatDateToLocalTime(date_and_time_details?.date_and_time)}</Text>

                      <Text variant="size-14" type="body-500">
                        End Date:
                      </Text>
                      <Text variant="size-14">{formatDateToLocalTime(date_and_time_details?.end_date_and_time)}</Text>

                      <Text variant="size-14" type="body-500">
                        Duration:
                      </Text>
                      <Text variant="size-14">{formatMinutesToDuration(date_and_time_details?.duration)}</Text>
                    </div>
                  </div>
                </div>
              )}
            </ScrollArea>
          )}
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            {eventId && !isError && (
              <Button variant="primary" onClick={navigateToActionDetailsHandler} disabled={isLoading}>
                Open Action
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {isDeleteActinOpen && (
        <CallToActionModal
          modalName="delete-action-event-modal"
          modalTitle="Delete Action"
          modalDescription="Delete action modal"
          modalTextContent="Are you sure you want to delete that action?"
          primaryButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          primaryButtonVariant="destructive_primary"
          isLoading={isPending}
          onPrimaryButtonClick={deleteActionHandler}
        />
      )}
      {isRescheduleModalOpen && (
        <RescheduleActionModal eventId={eventId} currentEventTime={data?.date_and_time_details.date_and_time ?? ''} />
      )}
    </>
  );
};
