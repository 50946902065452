/**
 * When developing low level UI components, it is common to have to use a local ref but also support an external one using React.forwardRef. Natively, React does not offer a way to set two refs inside the ref property. This is the goal of this small utility.
 *
 * @param references - array of ref objects
 */
export function mergeReferences<T = unknown>(
  references: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    for (const reference of references) {
      if (typeof reference === 'function') {
        reference(value);
      } else if (reference != null) {
        (reference as React.MutableRefObject<T | null>).current = value;
      }
    }
  };
}
