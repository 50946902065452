import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionTagUpdateBody, TActionTagUpdateResponse } from '@purple/shared-types';

export const updateActionTags = async (actionId: string, body: TActionTagUpdateBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.patch<TActionTagUpdateResponse>(
    replaceUrlParameters(ACTIONS.ACTION_TAGS, actionId),
    body,
  );
  return response.data;
};
