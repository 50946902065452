import { getAxiosInstance } from '../../api';
import { SAF_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSafPriority } from '@purple/shared-types';

export const safAssignPriority = async ({ safId, priority }: { safId: string; priority: TSafPriority }) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.patch<{ id: string; priority: TSafPriority }>(
    replaceUrlParameters(SAF_ENDPOINTS.ASSIGN_PRIORITY, safId),
    { priority },
  );
  return response.data;
};
