import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocalStorage, useToggle } from 'usehooks-ts';
import { useStorageCountdown } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { forgotPasswordFormSchema, LocalStorageKey } from '@purple/shared-utils';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Text,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useResetPasswordMutation } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import type * as z from 'zod';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [isRequestSent, setIsRequestSent] = useToggle(false);
  const [userResetPasswordEmail, setUserResetPasswordEmail] = useLocalStorage<string>(
    LocalStorageKey.Auth.ResetPassword.Email,
    '',
  );

  const { isCounting, startCountdown, time } = useStorageCountdown({
    minutes: 1,
    name: LocalStorageKey.Auth.ResetPassword.CountDown,
  });

  const { isPending, mutateAsync } = useResetPasswordMutation();

  const form = useForm<z.infer<typeof forgotPasswordFormSchema>>({
    resolver: zodResolver(forgotPasswordFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const handleFormSubmit = async (data: z.infer<typeof forgotPasswordFormSchema>) => {
    try {
      await mutateAsync(data);
      setUserResetPasswordEmail(data.email);
      setIsRequestSent();
    } catch {
      showErrorToast('Something went wrong please try again later', 'System message');
    }
  };

  const sendEmailAgainClickHandler = async () => {
    try {
      await mutateAsync({ email: userResetPasswordEmail });
      startCountdown();
    } catch {
      showErrorToast('Something went wrong please try again later', 'System message');
    }
  };

  const handleBackToLogin = () => {
    navigate(AppRoutes.Auth.SignIn.Root.path);
  };

  if (isRequestSent) {
    return (
      <div className="flex w-full flex-col items-center gap-8">
        <div className="absolute left-[16px] top-[24px]">
          <Button onClick={handleBackToLogin} variant="link" size="link" iconLeft={<PurpleIcon name="chevron-left" />}>
            Back to Login
          </Button>
        </div>
        <div className="flex flex-col items-center gap-8">
          <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-950">
            Forgot Password
          </Heading>
        </div>
        <Text variant="size-16" type="body-400" className="text-center text-grey-600">
          We sent a link to
          {' '}
          <span className="font-semibold text-brand-blue-700">{userResetPasswordEmail}</span>
          {' '}
          Follow
          that link to reset your password.
        </Text>
        <Button onClick={sendEmailAgainClickHandler} disabled={isCounting || isPending} isLoading={isPending}>
          Send Link Again
        </Button>
        {isCounting && (
          <Text variant="size-14" type="body-600" className="text-grey-title">
            {time}
          </Text>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <div className="absolute left-[16px] top-[24px]">
        <Button onClick={handleBackToLogin} variant="link" size="link" iconLeft={<PurpleIcon name="chevron-left" />}>
          Back to Login
        </Button>
      </div>
      <div className="flex flex-col items-center gap-8">
        <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-950">
          Forgot Password
        </Heading>
      </div>
      <Form providerProps={form} className="flex w-full flex-col gap-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Email</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.email} placeholder="Enter email" type="email" />
              </FormControl>
              <FormMessage>Enter your email to receive a password reset link.</FormMessage>
            </FormItem>
          )}
        />
        <Button
          type="submit"
          onClick={form.handleSubmit(handleFormSubmit)}
          disabled={!form.formState.isValid || isPending}
          isLoading={isPending}
        >
          Send Reset Link
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
