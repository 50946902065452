import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStudentTrendsAndFlags } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { convertStudentTrendsAndFlags } from './converter';

export const useGetStudentTrendsAndFlags = () => {
  const { studentId } = useParams();
  const { isError, data, isLoading, isSuccess } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_TRENDS_AND_FLAGS, studentId],
    queryFn: () => getStudentTrendsAndFlags(studentId as string),
  });

  const trendsAndFlagsData = useMemo(() => {
    if (data && isSuccess) {
      return convertStudentTrendsAndFlags(data);
    }
    return null;
  }, [data, isSuccess]);

  return { isLoading, isError, data: trendsAndFlagsData, studentId };
};
