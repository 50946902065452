import { Skeleton } from '@purple/ui';
import type React from 'react';

export const EmailDetailsSkeleton: React.FC = () => {
  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col items-start gap-4 px-4 pt-6">
        <Skeleton className="h-[22px] w-[110px]" />
        <div className="flex w-full items-center justify-between gap-4">
          <Skeleton className="h-7 w-[232px]" />
          <Skeleton className="h-10 w-[126px]" />
        </div>
      </div>
      <div className="flex w-full flex-col px-4 pb-4">
        <div className="flex w-full justify-between gap-4 border-t border-grey-200 py-4">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-6 w-[244px]" />
            <Skeleton className="h-5 w-[146px]" />
          </div>
          <Skeleton className="h-6 w-6" />
        </div>
        <div className="flex w-full flex-col gap-6 pb-4">
          <Skeleton className="h-5 w-[244px]" />
          <Skeleton className="h-5 w-[450px]" />
          <div className="flex w-full flex-col gap-1">
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-[600px]" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-full" />
            <Skeleton className="h-5 w-[640px]" />
          </div>
          <Skeleton className="h-5 w-[146px]" />
        </div>
        <div className="flex w-full justify-between gap-4 border-t border-grey-200 py-4">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-6 w-[244px]" />
          </div>
          <Skeleton className="h-6 w-6" />
        </div>
        <div className="flex w-full justify-between gap-4 border-t border-grey-200 py-4">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-6 w-[244px]" />
          </div>
          <Skeleton className="h-6 w-6" />
        </div>
      </div>
    </section>
  );
};
