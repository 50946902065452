import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateActionTags } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionTagUpdateBody, TActionTagUpdateResponse } from '@purple/shared-types';

export const useUpdateActionTags = (actionId: string) => {
  const queryClient = useQueryClient();

  return useMutation<TActionTagUpdateResponse, AxiosError, TActionTagUpdateBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_ACTION_TAGS],
    mutationFn: (body) => updateActionTags(actionId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_DETAILS, actionId] });
      showSuccessToast('System message', 'The action tags were updated successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not update the action tags. Check the provided information and try again',
      );
    },
  });
};
