import { useState } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Label,
  Message,
  RadioGroup,
  RadioGroupItem,
  Separator,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';

type TConfirmEditRecurringReminderProperties = {
  isUpdating: boolean;
  onConfirmEdit: (updateEventType: 'single' | 'all') => void;
};

export const ConfirmEditRecurringReminder = ({
  isUpdating,
  onConfirmEdit,
}: TConfirmEditRecurringReminderProperties) => {
  const { isOpen, closeModal, toggleModal } = useModal('confirm-edit-recurring-reminder-modal');
  const [updateEventType, setUpdateEventType] = useState<'single' | 'all'>('single');

  const warningText
    = updateEventType === 'single'
      ? 'Selecting this option will only affect current reminder'
      : 'Selecting this option will affect all reminders in the group';

  const confirmEditHandler = () => {
    onConfirmEdit(updateEventType);
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={toggleModal}>
      <AlertDialogContent className="w-[564px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Edit Reminder</AlertDialogTitle>
          <AlertDialogDescription className="sr-only">Confirm Edit Reminder modal</AlertDialogDescription>
        </AlertDialogHeader>
        <Separator />
        <div className="flex flex-col gap-5 p-6">
          <div className="flex flex-col gap-2">
            <Text variant="size-16" type="body-600">
              Choose the reminder you want to update:
            </Text>
            <RadioGroup
              onValueChange={(value: 'single' | 'all') => setUpdateEventType(value)}
              defaultValue={updateEventType}
              className="flex flex-col gap-3"
            >
              <div className="flex items-center gap-x-2 space-y-0">
                <RadioGroupItem value="single" id="single" className="h-5 w-5" />
                <Label htmlFor="single">This Reminder</Label>
              </div>
              <div className="flex items-center gap-x-2 space-y-0">
                <RadioGroupItem value="all" id="all" className="h-5 w-5" />
                <Label htmlFor="all">All Reminders in the group</Label>
              </div>
            </RadioGroup>
          </div>
          <Message variant="warning">
            <Text variant="size-14" type="body-500">
              {warningText}
            </Text>
          </Message>
        </div>
        <Separator />
        <AlertDialogFooter>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmEditHandler} disabled={isUpdating} isLoading={isUpdating}>
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
