import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Separator,
  StackedBarChart,
} from '@purple/ui';
import { CustomizedGroupTick } from '~/components/CustomizedGroupTick';
import { LegendComponent } from '~/components/LegendComponent';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import type { IStudentDataEntity } from '@purple/shared-types';

const BAR_SIZE = 40;

type TStudentsDataChartModalProperties = {
  student: IStudentDataEntity | null;
};

export const StudentsDataChartModal = ({ student }: TStudentsDataChartModalProperties) => {
  const { isOpen, toggleModal } = useModal(ModalType.STUDENTS_DATA_CHART);

  if (!student) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[840px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle className="leading-7 tracking-normal">Color Trends</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="h-[400px] w-full px-4 py-6">
          <StackedBarChart
            barSize={BAR_SIZE}
            legend={() => <LegendComponent colorRange={student.colorsRange} />}
            customXAxis={<CustomizedGroupTick />}
            xAxisLabel="Month"
            data={student.plot}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
