export const MY_FILES_SORT_SELECT_OPTIONS = [
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
  {
    label: 'Last Modified (ASC)',
    value: 'updated_at',
  },
  {
    label: 'Last Modified (DESC)',
    value: '-updated_at',
  },
  {
    label: 'Size (ASC)',
    value: 'size',
  },
  {
    label: 'Size (DESC)',
    value: '-size',
  },
];
