import type { ValueOf } from '@purple/shared-types';

export const TypographyControls = {
  BOLD: 'bold',
  ITALIC: 'italic',
  STRIKE: 'strike',
  UNDERLINE: 'underline',
} as const;
export type TTypographyControls = ValueOf<typeof TypographyControls>;

export const ListControls = {
  BULLET_LIST: 'bulletList',
  ORDERED_LIST: 'orderedList',
} as const;
export type TListControls = ValueOf<typeof ListControls>;

export const AlignmentControls = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const;
export type TAlignmentControls = ValueOf<typeof AlignmentControls>;

export const FILES_MAX_SIZE_LIMIT = 20 * 1024 * 1024;
