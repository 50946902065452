import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import type { IPaginatedResponse, IPriorityListRequest, TPriorityListResponseItem } from '@purple/shared-types';

export const getPriorityLists = async (requestParameters: IPriorityListRequest) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<IPaginatedResponse<TPriorityListResponseItem[]>>(
    PRIORITY_LIST_ENDPOINTS.GET_PRIORITY_LISTS,
    { params: requestParameters },
  );
  return response.data;
};
