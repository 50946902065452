import { cn, Skeleton } from '@purple/ui';
import type { FC } from 'react';

const DEFAULT_ROWS = 10;

type DataTableSkeletonProperties = {
  rows?: number;
  className?: string;
  rowClassName?: string;
};

const DataTableSkeleton: FC<DataTableSkeletonProperties> = ({ rows = DEFAULT_ROWS, className, rowClassName }) => {
  return (
    <div className="flex w-full flex-col">
      {Array.from({ length: rows }, (_, index) => index).map((number) => {
        return (
          <div
            key={`${number}-skeleton`}
            className={cn('border-b border-grey-300 px-[20px] py-2 last:border-none', rowClassName)}
          >
            <Skeleton className={cn('h-[22px] w-full', className)} />
          </div>
        );
      })}
    </div>
  );
};

export { DataTableSkeleton };
