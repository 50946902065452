import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionGroupDetails } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useActionGroupDetails = (groupId: string) => {
  const { isError, ...props } = useQuery({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_GROUP_DETAILS, groupId],
    queryFn: () => getActionGroupDetails(groupId),
    enabled: !!groupId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Error', 'Failed to fetch action group details!');
    }
  }, [isError]);

  return { ...props, isError };
};
