import { getAxiosInstance } from '../../api';
import { ACTIVITY_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActivityDetailsResponse } from '@purple/shared-types';

export const getActivityById = async (activityId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActivityDetailsResponse>(
    replaceUrlParameters(ACTIVITY_ENDPOINTS.BY_ID, activityId),
  );
  return response.data;
};
