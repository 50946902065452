import { getAxiosInstance } from '../../api';
import { REMINDERS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TReminderUpdateRecurringBody, TReminderUpdateRecurringResponse } from '@purple/shared-types';

export const updateRecurringReminder = async ({
  reminderId,
  body,
}: {
  reminderId: string;
  body: TReminderUpdateRecurringBody;
}) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.put<TReminderUpdateRecurringResponse>(
    replaceUrlParameters(REMINDERS.UPDATE_RECURRING_REMINDER, reminderId),
    body,
  );
  return response.data;
};
