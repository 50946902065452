import { Link } from 'react-router-dom';
import { formatDateWithTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { IStudentIncidentDetail } from '@purple/shared-types';

type TContentProperties = {
  data: IStudentIncidentDetail;
};

const Content = ({ data }: TContentProperties) => {
  const {
    name,
    student: { full_name, id: student_id },
    incident_type,
    incident_date_time,
    observer_type,
    observer,
    class_period,
    description,
    created_by,
  } = data;

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center justify-between">
        <Text variant="size-16" type="body-600">
          {name}
        </Text>
      </div>
      <div className="flex flex-col gap-3">
        <Text variant="size-16" type="body-600">
          Incident Details
        </Text>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Student Name:
            </Text>
            <Link to={AppRoutes.App.Students.StudentDetails.Root.makePath(student_id, 'dashboard')}>
              <Text variant="size-14" type="body-600" className="text-brand-blue-800">
                {full_name}
              </Text>
            </Link>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Type:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {incident_type}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Date & Time:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {formatDateWithTime(incident_date_time)}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Observer Type:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {observer_type || '-'}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Observed by:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {observer ? `${observer.first_name} ${observer.last_name}` : '-'}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Class Period:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {class_period}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Description:
            </Text>
            <Text variant="size-14" type="body-400" className="flex flex-1 text-grey-950">
              {description}
            </Text>
          </div>
          <div className="flex flex-row gap-8">
            <Text variant="size-14" type="body-500" className="w-[144px] text-grey-950">
              Created by:
            </Text>
            <Text variant="size-14" type="body-400" className="text-grey-950">
              {created_by ? created_by.full_name : '-'}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Content };
