import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';

type TCorrectMarkProperties = React.PropsWithChildren<{
  isCorrect: boolean;
}>;

const CorrectMark = ({ isCorrect }: TCorrectMarkProperties) => {
  return (
    <div
      className={cn('flex h-[20px] w-[20px] items-center justify-center rounded-full', {
        'bg-success-main': isCorrect,
        'bg-error-main': !isCorrect,
      })}
    >
      <PurpleIcon name={isCorrect ? 'check' : 'Exclamation-mark'} height={20} width={20} className="text-white" />
    </div>
  );
};

export { CorrectMark };
