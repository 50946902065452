import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createNote } from '@purple/shared-services';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useCreateNote = (options?: { showNotifications?: boolean }) => {
  const { showNotifications = false } = options || {};

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [NOTE_QUERY_KEYS.CREATE_NOTE],
    mutationFn: createNote,
    onSuccess: () => {
      if (showNotifications) {
        showSuccessToast('System Message', 'Note created successfully');
      }
      queryClient.invalidateQueries({ queryKey: [NOTE_QUERY_KEYS.GET_NOTES_LIST] });
    },
    onError: () => {
      if (showNotifications) {
        showErrorToast('System Error', 'Unable to create note');
      }
    },
  });
};
