import { useSearch } from '@purple/hooks';
import { SearchInput } from '@purple/ui';

const FilterActionsRow = () => {
  const { search, onSearchChange, onClearSearch } = useSearch();

  return (
    <div className="flex w-full flex-row items-center justify-between gap-4">
      <div className="w-[300px]">
        <SearchInput
          placeholder="Search by user name"
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
      </div>
    </div>
  );
};

export { FilterActionsRow };
