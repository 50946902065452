import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateActionDetails } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TActionDetailsUpdateBody, TActionDetailsUpdateResponse } from '@purple/shared-types';

export const useUpdateAction = () => {
  const queryClient = useQueryClient();

  return useMutation<TActionDetailsUpdateResponse, AxiosError, TActionDetailsUpdateBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_ACTION],
    mutationFn: updateActionDetails,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_DETAILS, data.id] });
      showSuccessToast('System message', 'The action was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not update the action. Check the provided information and try again');
    },
  });
};
