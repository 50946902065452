import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector } from '~/hooks';
import { MainHeader } from '~/layout/MainHeader';
import { districtSelector } from '~/store/features/district';
import { isAuthenticatedSelector, isLoadingSelector } from '~/store/features/user';
import { MainContainer } from '../MainContainer';

export const SiteLayout: React.FC = () => {
  const isUserAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isAuthLoading = useAppSelector(isLoadingSelector);
  const { logo, name } = useAppSelector(districtSelector) ?? {};
  if (!isAuthLoading && !isUserAuthenticated) return <Navigate to={AppRoutes.Auth.SignIn.Root.path} replace />;

  return (
    <MainContainer>
      <MainHeader districtName={name} logo={logo} />
      <main className="flex w-full max-w-[1440px] justify-center gap-4 px-8 py-[30px]">
        <Outlet />
      </main>
    </MainContainer>
  );
};
