import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SafSubmitterType } from '@purple/shared-types';
import { Form, useStepper } from '@purple/ui';
// hooks
import { useStudentAssistance } from '~/hooks/redux';
import { useCreateSaf } from '~/queries';
// helpers
import { SafReturnType, SafStepType, StudentAssistanceScreen } from '~/constants/saf';
// components
import { FormActions } from './FormActions';
import { FormInfo } from './FormInfo';
import { InfoForm } from './InfoForm';
import { ObservationForm } from './ObservationForm';
import { StudentForm } from './StudentForm';
// schema
import { safFormSchema } from './schema';
// types
import type { z } from 'zod';

export const FormController: React.FC = () => {
  const formId = useId();

  const { nextStep, prevStep, currentStep, activeStep, previousActiveStep, setStep } = useStepper();
  const { submitterType, setSafCurrentScreen, setLastSubmittedForm, lastSubmittedForm, returnType, setSafReturnType }
    = useStudentAssistance();
  const { mutate: createSaf, isPending } = useCreateSaf();

  const transitionDelta = useMemo(() => activeStep - (previousActiveStep ?? 0), [activeStep, previousActiveStep]);

  const form = useForm<z.infer<typeof safFormSchema>>({
    resolver: zodResolver(safFormSchema),
    mode: 'onChange',
    defaultValues: {
      submitter: lastSubmittedForm?.submitter ?? SafSubmitterType.STUDENT,
      myself: lastSubmittedForm?.myself ?? submitterType === SafSubmitterType.STUDENT,
      anonymous: lastSubmittedForm?.anonymous ?? false,
      firstName: lastSubmittedForm?.firstName ?? '',
      lastName: lastSubmittedForm?.lastName ?? '',
      phone: lastSubmittedForm?.phone ?? '',
      email: lastSubmittedForm?.email ?? '',
      organization: lastSubmittedForm?.organization ?? '',
      studentFirstName: '',
      studentLastName: '',
      studentMiddleName: '',
      studentId: '',
      studentSchool: '',
      studentGrade: '',
      studentParentPhone: '',
      needs: undefined,
      details: '',
    },
  });

  useEffect(() => {
    // We need to pass the submitter type to the form so we can refine the schema based on the submitter type.
    form.setValue('submitter', submitterType);
  }, [submitterType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (returnType === SafReturnType.CURRENT_STUDENT) {
      setStep(2);
      form.reset(
        {
          firstName: lastSubmittedForm?.firstName ?? '',
          lastName: lastSubmittedForm?.lastName ?? '',
          phone: lastSubmittedForm?.phone ?? '',
          email: lastSubmittedForm?.email ?? '',
          organization: lastSubmittedForm?.organization ?? '',
          studentFirstName: lastSubmittedForm?.studentFirstName ?? '',
          studentLastName: lastSubmittedForm?.studentLastName ?? '',
          studentMiddleName: lastSubmittedForm?.studentMiddleName ?? '',
          studentId: lastSubmittedForm?.studentId ?? '',
          studentSchool: lastSubmittedForm?.studentSchool ?? '',
          studentGrade: lastSubmittedForm?.studentGrade ?? '',
          studentParentPhone: lastSubmittedForm?.studentParentPhone ?? '',
          myself: lastSubmittedForm?.myself ?? submitterType === SafSubmitterType.STUDENT,
          submitter: lastSubmittedForm?.submitter ?? SafSubmitterType.STUDENT,
          anonymous: lastSubmittedForm?.anonymous ?? false,
        },
        { keepDefaultValues: true },
      );
    }

    if (returnType === SafReturnType.NEW_STUDENT) {
      setStep(1);
      form.reset(
        {
          firstName: lastSubmittedForm?.firstName ?? '',
          lastName: lastSubmittedForm?.lastName ?? '',
          phone: lastSubmittedForm?.phone ?? '',
          email: lastSubmittedForm?.email ?? '',
          organization: lastSubmittedForm?.organization ?? '',
          myself: lastSubmittedForm?.myself ?? submitterType === SafSubmitterType.STUDENT,
          submitter: lastSubmittedForm?.submitter ?? SafSubmitterType.STUDENT,
          anonymous: lastSubmittedForm?.anonymous ?? false,
        },
        { keepDefaultValues: true },
      );
    }
  }, [returnType]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitStudentAssistanceHandler = (values: z.infer<typeof safFormSchema>) => {
    const phone = values.phone.replaceAll('+1 (', '').replaceAll(') ', '-');
    const studentParentPhone = values.studentParentPhone.replaceAll('+1 (', '').replaceAll(') ', '-');

    const body = {
      description: values.details,
      is_student_filling_form_for_himself: values.myself,
      is_submitted_anonymously: values.anonymous,
      needs: [values.needs],
      submitted_school: values.studentSchool,
      submitted_student_first_name: values.studentFirstName,
      submitted_student_grade: values.studentGrade,
      submitted_student_id: values.studentId,
      submitted_student_last_name: values.studentLastName,
      submitter_org_or_campus: values.organization,
      submitter_type: values.submitter,
      submitted_student_parent_phone: studentParentPhone,
      submitted_student_middle_name: values.studentMiddleName,
      ...(!values.anonymous && {
        submitter: {
          first_name: values.firstName,
          last_name: values.lastName,
          ...(values.email && { email: values.email }),
          ...(values.phone && { phone }),
        },
      }),
    };
    createSaf(body, {
      onSuccess: () => {
        setLastSubmittedForm(values);
        setSafReturnType(null);
        nextStep();
        setSafCurrentScreen(StudentAssistanceScreen.SUCCESS);
      },
    });
  };

  const previousStepClickHandler = () => {
    if (currentStep?.id === SafStepType.INFO) {
      setSafReturnType(null);
      setSafCurrentScreen(StudentAssistanceScreen.SUBMITTER);
      setLastSubmittedForm(null);
      form.reset();
      return;
    }
    prevStep();
  };

  const nextStepClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (currentStep?.id === SafStepType.INFO) {
      const output = await form.trigger(['firstName', 'lastName', 'email', 'phone', 'organization'], {
        shouldFocus: true,
      });
      if (!output) return;
      const isForMySelf = form.getValues('myself');
      if (isForMySelf) {
        form.setValue('studentFirstName', form.getValues('firstName'));
        form.setValue('studentLastName', form.getValues('lastName'));
        form.setValue('studentParentPhone', form.getValues('phone'));
      }
    }

    if (currentStep?.id === SafStepType.STUDENT) {
      const output = await form.trigger(
        ['studentFirstName', 'studentLastName', 'studentSchool', 'studentGrade', 'studentParentPhone'],
        {
          shouldFocus: true,
        },
      );
      if (!output) return;
    }

    nextStep();
  };

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <FormInfo />
      <Form
        providerProps={form}
        id={formId}
        className="w-full max-w-[792px]"
        onSubmit={form.handleSubmit(submitStudentAssistanceHandler)}
      >
        {currentStep?.id === SafStepType.INFO && <InfoForm form={form} transitionDelta={transitionDelta} />}
        {currentStep?.id === SafStepType.STUDENT && <StudentForm form={form} transitionDelta={transitionDelta} />}
        {currentStep?.id === SafStepType.OBSERVATION && (
          <ObservationForm form={form} transitionDelta={transitionDelta} />
        )}
      </Form>
      <FormActions
        formId={formId}
        isSubmitting={isPending}
        onPreviousClick={previousStepClickHandler}
        onNextClick={nextStepClickHandler}
      />
    </div>
  );
};
