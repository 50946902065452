import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TGroupActionDetailsResponse, TGroupActionDetailsUpdateBody } from '@purple/shared-types';

export const updateGroupActionDetails = async (body: TGroupActionDetailsUpdateBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.patch<TGroupActionDetailsResponse>(
    replaceUrlParameters(ACTIONS.ACTION_GROUPS_BY_ID, body.id),
    body,
  );
  return response.data;
};
