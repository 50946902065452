import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLoadScript } from '@react-google-maps/api';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { EditableBlockHeader, Skeleton } from '@purple/ui';
import { useUpdateSchool } from '~/queries';
import { AddressEdit } from './AddressEdit';
import { AddressView } from './AddressView';
import type { TSchoolAddress, TSchoolInfo } from '@purple/shared-types';

const MAX_DECIMAL_PLACES = 6;

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API;
const libraries: 'places'[] = ['places'];

type TAddressProperties = {
  data: TSchoolInfo;
};

const Address = ({ data }: TAddressProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  const { mutate, isPending, isSuccess } = useUpdateSchool();

  useEffect(() => {
    if (isSuccess) {
      setMode('view');
    }
  }, [isSuccess]);

  const form = useForm<Partial<TSchoolAddress>>({
    mode: 'onChange',
    defaultValues: {
      street: data.address?.address?.street,
      city: data.address?.address?.city,
      state: data.address?.address?.state,
      postal_code: data.address?.address?.postal_code,
      latitude: data.address?.address?.latitude,
      longitude: data.address?.address?.longitude,
    },
  });

  useEffect(() => {
    form.reset({
      street: data.address?.address?.street,
      city: data.address?.address?.city,
      state: data.address?.address?.state,
      postal_code: data.address?.address?.postal_code,
      latitude: data.address?.address?.latitude,
      longitude: data.address?.address?.longitude,
    });
  }, [data, form, mode]);

  const handleSave = (formData: Partial<TSchoolAddress>) => {
    // Round latitude and longitude to 6 decimal places because the API accepts only 6 decimal places
    const roundedLatitude = Number.parseFloat(formData.latitude?.toFixed(MAX_DECIMAL_PLACES) || '');
    const roundedLongitude = Number.parseFloat(formData.longitude?.toFixed(MAX_DECIMAL_PLACES) || '');

    if (!roundedLatitude || !roundedLongitude) {
      return;
    }

    const requestValue = {
      schoolId: data.id,
      request: {
        address: {
          address: {
            ...formData,
            latitude: roundedLatitude,
            longitude: roundedLongitude,
          },
        },
      },
    };
    mutate(requestValue);
  };

  if (!isLoaded) {
    return <Skeleton className="h-[120px] w-full" />;
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <Guard requiredPermissions={[APP_PERMISSIONS.CAN_EDIT_SCHOOL_PROFILE]}>
        <EditableBlockHeader
          mode={mode}
          onEdit={() => setMode('edit')}
          onCancel={() => setMode('view')}
          onSave={form.handleSubmit(handleSave)}
          isSaving={isPending}
        />
      </Guard>
      {mode === 'view' ? <AddressView data={data} /> : <AddressEdit data={data} form={form} />}
    </div>
  );
};

export { Address };
