import { CalendarDayDots } from './CalendarDayDots';
import { EventsLegend } from './EventsLegend';
import type { IStudentMonthEvent, TStudentCalendarItemType } from '@purple/shared-types';

type YearStudentCalendarProperties = {
  calendarSwitcher: React.ReactNode;
  events: IStudentMonthEvent[];
  // TODO: uncomment when will continue working on this component
  // currentDate: Date;
  // onNavigate: (date: Date) => void;
  eventType: TStudentCalendarItemType;
};

// TODO: not finished, need to finish after BA will ask the question
const YearStudentCalendar = ({
  calendarSwitcher,
  events,
  // TODO: uncomment when will continue working on this component
  // currentDate,
  // onNavigate,
  eventType,
}: YearStudentCalendarProperties) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-row items-center justify-between">
        {calendarSwitcher}
        {/* TODO: create year next and prev switcher */}
        <div>left and right</div>
      </div>
      <EventsLegend eventType={eventType} />
      <CalendarDayDots eventType={eventType} events={events} />
    </div>
  );
};

export { YearStudentCalendar };
