import { useMutation } from '@tanstack/react-query';
import { safTransfer } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSafTransfer = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.TRANSFER],
    mutationFn: safTransfer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS] });
      showSuccessToast('System message', 'The SAF was transferred successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not transfer the SAF. Check the provided information and try again');
    },
  });
};
