import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentSafsList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentSafsRequestParameters } from '@purple/shared-types';

export const useStudentSafList = ({
  studentId,
  queryParams,
}: {
  studentId: string;
  queryParams: TStudentSafsRequestParameters;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_SAFS_LIST, { studentId, queryParams }],
    queryFn: () => getStudentSafsList({ studentId, queryParameters: queryParams }),
    enabled: !!studentId,
  });
  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch student SAFs');
    }
  }, [isError]);
  return { data, isLoading };
};
