import { getAxiosInstance } from '../../api';
import { FILE_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TFile } from '@purple/shared-types';

export const getFileById = async (fileId: string) => {
  const axiosInstance = getAxiosInstance();
  const URL_PATH = replaceUrlParameters(FILE_ENDPOINTS.BY_ID, fileId);
  const response = await axiosInstance.get<TFile>(URL_PATH);

  return response.data;
};
