import type { ValueOf } from '@purple/shared-types';

export const SchoolFlagType = {
  ATTENDANCE: 'attendance',
  BEHAVIOR: 'behavior',
  GRADES: 'grades',
  HEALTH_NURSE_VISITS: 'health_nurse_visits',
  NEED: 'need',
} as const;
export type TSchoolFlagType = ValueOf<typeof SchoolFlagType>;

export const ReadableSchoolFlagType = {
  [SchoolFlagType.ATTENDANCE]: 'Attendance',
  [SchoolFlagType.BEHAVIOR]: 'Behavior',
  [SchoolFlagType.GRADES]: 'Grades',
  [SchoolFlagType.HEALTH_NURSE_VISITS]: 'Health/Nurse Visits',
  [SchoolFlagType.NEED]: 'Need',
} as const;

export const SCHOOL_FLAG_TYPE_OPTIONS = Object.entries(ReadableSchoolFlagType).map(([value, label]) => ({
  value,
  label,
}));

export const SCHOOL_YEARS = Array.from({ length: 60 }, (_, index) => ({
  value: `${new Date().getFullYear() - index}`,
  label: `${new Date().getFullYear() - index}`,
}));
