import { useMemo } from 'react';
import ISO6391 from 'iso-639-1';
import { getGMTOffset } from '@purple/shared-utils';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  InfoItem,
} from '@purple/ui';
import { AddressComboBox, MapComponent } from '~/components';
import type { UseFormReturn } from 'react-hook-form';
import type { TLocation, TLocationDetails, TSchoolAddress, TUpdateUserLocationDetails } from '@purple/shared-types';

type TDetailsEditProperties = {
  data: TLocationDetails;
  form: UseFormReturn<TUpdateUserLocationDetails>;
};

const DetailsEdit = ({ data, form }: TDetailsEditProperties) => {
  const { address } = data;

  const isAddressExist = useMemo(() => Boolean(address), [address]);

  const languageOptions = useMemo(() => {
    return ISO6391.getAllNames().map((name) => ({
      label: name,
      value: name,
    }));
  }, []);

  const timezoneOptions = useMemo(() => {
    const timezones = Intl.supportedValuesOf('timeZone');

    const options = timezones
      .map((tz) => {
        const gmtOffset = getGMTOffset(tz);
        const timezoneCountry = tz.replace('_', ' ');
        return {
          label: `(${gmtOffset}) ${timezoneCountry}`,
          value: tz,
          offset: gmtOffset,
        };
      })
      .sort((a, b) => a.offset.localeCompare(b.offset));

    return options;
  }, []);

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <InfoItem label="Language" className="flex-col gap-2" contentClassName="w-full max-w-full">
        <FormField
          control={form.control}
          name="language"
          render={({ field, fieldState }) => (
            <FormItem>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select language"
                    selectedLabel={languageOptions.find((option) => option.value === field.value)?.label}
                    className="max-h-8"
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search language..." emptyContent="Language not found.">
                  {languageOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Timezone" className="flex-col gap-2" contentClassName="w-full max-w-full">
        <FormField
          control={form.control}
          name="timezone"
          render={({ field, fieldState }) => (
            <FormItem>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select timezone"
                    selectedLabel={timezoneOptions.find((option) => option.value === field.value)?.label}
                    className="max-h-8"
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search timezone..." emptyContent="Timezone not found.">
                  {timezoneOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Address" className="flex-col gap-2" contentClassName="w-full max-w-full">
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <AddressComboBox
              location={field.value || null}
              onChange={(newAddress) => {
                form.setValue('address', newAddress as TSchoolAddress);
              }}
            />
          )}
        />
      </InfoItem>
      {isAddressExist && <MapComponent location={address as TLocation} />}
    </Form>
  );
};

export { DetailsEdit };
