import { useMemo } from 'react';
import { Skeleton } from '@purple/ui';

type TStudentsNoteFormSkeletonProperties = {
  formMode: 'create' | 'edit' | 'preview';
};

export const StudentsNoteFormSkeleton = ({ formMode }: TStudentsNoteFormSkeletonProperties) => {
  const isPreview = useMemo(() => formMode === 'preview', [formMode]);
  return (
    <div className="space-y-4">
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-[290px] w-full" />
      {!isPreview && <Skeleton className="h-[60px] w-full" />}
    </div>
  );
};
