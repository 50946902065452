import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { APP_PERMISSIONS } from '@purple/permissions';
import { AccessDenied, AppTabs } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { MyPriorityListsContainer } from './MyPriorityLists';
import { SchoolData } from './SchoolData';
import { StudentsData } from './StudentsData';
import type { TAppTab } from '@purple/ui';

export const VisualizerTabs: React.FC = () => {
  const { schools } = useAppSelector(userSelector);

  const schoolDataTabLabel = useMemo(() => (schools.length > 1 ? 'District Data' : 'School Data'), [schools]);

  const visualizerTabs: TAppTab[] = [
    {
      label: 'Student Data',
      value: 'student-data',
      content: <StudentsData />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS],
    },
    {
      label: schoolDataTabLabel,
      value: 'school-data',
      content: <SchoolData />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_SCHOOLS],
    },
    {
      label: 'My Priority Lists',
      value: 'my-priority-lists',
      content: <MyPriorityListsContainer />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_MY_PRIORITY_LISTS, APP_PERMISSIONS.CAN_ACCESS_PRIORITY_LIST_CREATION],
    },
  ];

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: visualizerTabs,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }

  return <AppTabs className="col-span-2" tabs={visualizerTabs} value={activeTab} onValueChange={tabChangeHandler} />;
};
