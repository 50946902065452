import { useCallback, useMemo } from 'react';
import { TakeActionType } from '@purple/shared-types';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import {
  takeActionModalSelector,
  takeActionPriorityListIdSelector,
  takeActionSafIdSelector,
  takeActionSlice,
  takeActionStudentIdSelector,
} from '~/store/features/take-action';
import type { TTakeActionState } from '~/store/features/take-action';

type TUseTakeActionReturn = TTakeActionState & {
  /**
   * Whether the check-in modal is open or not.
   */
  isCheckInModalOpen: boolean;
  /**
   * Whether the observation modal is open or not.
   */
  isObservationModalOpen: boolean;
  /**
   * Whether the counseling modal is open or not.
   */
  isCounselingModalOpen: boolean;
  /**
   * Whether the academic career planning modal is open or not.
   */
  isAcademicCareerPlanningModalOpen: boolean;
  /**
   * Whether the stakeholder collaborative meeting modal is open or not.
   */
  isStakeholderMeetingModalOpen: boolean;
  /**
   * Whether the resource modal is open or not.
   */
  isResourceModalOpen: boolean;
  /**
   * Whether the district initiative modal is open or not.
   */
  isDistrictInitiativeModalOpen: boolean;
  /**
   * Whether the crisis response modal is open or not.
   */
  isCrisisResponseModalOpen: boolean;
  /**
   * Whether the reminder modal is open or not.
   */
  isReminderModalOpen: boolean;
  /**
   * Whether the guidance counseling lesson modal is open or not.
   */
  isGuidanceCounselingLessonModalOpen: boolean;
  /**
   * Toggle the action type modal.
   * @param actionType - type of the action to open the modal for
   */
  toggleActionModal: (actionType: TTakeActionState['actionTypeModalOpen']) => void;
  /**
   * Set the selected student id.
   * @param studentId - id of the student to take action for
   */
  setSelectedStudentId: (studentId: TTakeActionState['selectedStudentId']) => void;
  /**
   * Set the selected saf id.
   * @param safId - id of the saf to take action for
   */
  setSelectedSafId: (safId: TTakeActionState['selectedSafId']) => void;
  /**
   * Set the selected priority list id.
   * @param priorityListId - id of the priority list to take action for
   */
  setSelectedPriorityListId: (priorityListId: TTakeActionState['selectedPriorityListId']) => void;
  /**
   * Whether there are multiple students selected or not.
   */
  isMultipleStudents: boolean;
};

export const useTakeAction = (): TUseTakeActionReturn => {
  const dispatch = useAppDispatch();
  const selectedSafId = useAppSelector(takeActionSafIdSelector);
  const actionTypeModalOpen = useAppSelector(takeActionModalSelector);
  const selectedStudentId = useAppSelector(takeActionStudentIdSelector);
  const selectedPriorityListId = useAppSelector(takeActionPriorityListIdSelector);

  const { toggleActionModal, setSelectedStudentId, setSelectedPriorityListId, setSelectedSafId }
    = takeActionSlice.actions;

  const isMultipleStudents = useMemo(
    () => Array.isArray(selectedStudentId) || Boolean(selectedPriorityListId),
    [selectedStudentId, selectedPriorityListId],
  );

  const isCheckInModalOpen = useMemo(() => actionTypeModalOpen === TakeActionType.CHECK_IN, [actionTypeModalOpen]);
  const isObservationModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.OBSERVATION,
    [actionTypeModalOpen],
  );
  const isCounselingModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.COUNSELING_SESSION,
    [actionTypeModalOpen],
  );
  const isStakeholderMeetingModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
    [actionTypeModalOpen],
  );
  const isGuidanceCounselingLessonModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.GUIDANCE_COUNSELING_LESSON,
    [actionTypeModalOpen],
  );
  const isAcademicCareerPlanningModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION,
    [actionTypeModalOpen],
  );
  const isCrisisResponseModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.CRISIS_RESPONSE_SESSION,
    [actionTypeModalOpen],
  );
  const isDistrictInitiativeModalOpen = useMemo(
    () => actionTypeModalOpen === TakeActionType.DISTRICT_INITIATIVE,
    [actionTypeModalOpen],
  );
  const isReminderModalOpen = useMemo(() => actionTypeModalOpen === TakeActionType.REMINDER, [actionTypeModalOpen]);
  const isResourceModalOpen = useMemo(() => actionTypeModalOpen === TakeActionType.RESOURCE, [actionTypeModalOpen]);

  return {
    actionTypeModalOpen,
    isCheckInModalOpen,
    isObservationModalOpen,
    isCounselingModalOpen,
    isAcademicCareerPlanningModalOpen,
    isStakeholderMeetingModalOpen,
    isDistrictInitiativeModalOpen,
    isResourceModalOpen,
    isCrisisResponseModalOpen,
    isReminderModalOpen,
    isGuidanceCounselingLessonModalOpen,
    selectedStudentId,
    selectedSafId,
    selectedPriorityListId,
    isMultipleStudents,
    toggleActionModal: useCallback(
      (actionType: TTakeActionState['actionTypeModalOpen']) => dispatch(toggleActionModal(actionType)),
      [dispatch, toggleActionModal],
    ),
    setSelectedSafId: useCallback(
      (safId: TTakeActionState['selectedSafId']) => dispatch(setSelectedSafId(safId)),
      [dispatch, setSelectedSafId],
    ),
    setSelectedStudentId: useCallback(
      (studentId: TTakeActionState['selectedStudentId']) => dispatch(setSelectedStudentId(studentId)),
      [dispatch, setSelectedStudentId],
    ),
    setSelectedPriorityListId: useCallback(
      (priorityListId: TTakeActionState['selectedPriorityListId']) =>
        dispatch(setSelectedPriorityListId(priorityListId)),
      [dispatch, setSelectedPriorityListId],
    ),
  };
};
