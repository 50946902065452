import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentsDataFilters } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useGetStudentsDataFilters = () => {
  const { isLoading, isError, isSuccess, data } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_DATA_FILTERS],
    queryFn: () => getStudentsDataFilters(),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch students filters', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, isSuccess, data };
};
