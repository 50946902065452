import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  DatePicker,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useUpdateAction } from '~/queries/actions/useUpdateAction';
import { showErrorToast } from '~/shared/lib';

const rescheduleActionModalSchema = z.object({
  current_date_time: z.date({
    message: 'Current Date & Time is invalid.',
    required_error: 'Current Date & Time is required.',
  }),
  new_date_time: z.date({
    message: 'New Date & Time is invalid.',
    required_error: 'New Date & Time is required.',
  }),
});

type TFormValues = z.infer<typeof rescheduleActionModalSchema>;

type TRescheduleActionModalProperties = {
  eventId: string | null;
  currentEventTime: string;
};

export const RescheduleActionModal = ({ currentEventTime, eventId }: TRescheduleActionModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('reschedule-action-event-modal');
  const formId = useId();
  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(rescheduleActionModalSchema),
    defaultValues: {
      current_date_time: new Date(currentEventTime),
    },
  });

  const { mutate, isPending } = useUpdateAction();

  const saveRescheduleActionHandler = (data: TFormValues) => {
    if (!eventId) {
      return showErrorToast('System Message', 'An error occurred while rescheduling action');
    }

    mutate(
      {
        id: eventId,
        date_and_time: data.new_date_time.toISOString(),
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Reschedule Action</DialogTitle>
            <DialogDescription className="sr-only">Reschedule Action Modal window</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="flex flex-col gap-2 p-6"
          onSubmit={form.handleSubmit(saveRescheduleActionHandler)}
          id={formId}
        >
          <FormField
            control={form.control}
            name="current_date_time"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Current Date & Time</FormLabel>
                <FormControl>
                  <DatePicker
                    mode="single"
                    placeholder="Select date & time"
                    formatterString="PPP p"
                    isError={!!fieldState.error}
                    triggerDisabled
                    selected={field.value}
                    defaultMonth={field.value}
                    captionLayout="dropdown"
                    onDayClick={field.onChange}
                    onTimeChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="new_date_time"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>New Date & Time</FormLabel>
                <FormControl>
                  <DatePicker
                    mode="single"
                    placeholder="Select new date & time"
                    formatterString="PPP p"
                    disabled={(date) => date < new Date() || date < form.getValues('current_date_time')}
                    isError={!!fieldState.error}
                    triggerDisabled={field.disabled}
                    selected={field.value}
                    defaultMonth={field.value}
                    captionLayout="dropdown"
                    onDayClick={field.onChange}
                    onTimeChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={!form.formState.isValid || isPending}
            isLoading={isPending}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
