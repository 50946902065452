import { useQuery } from '@tanstack/react-query';
import { getSchoolOverviewLinkedStudents } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { convertSchoolOverviewLinkedStudents } from './converter';
import type { TSchoolOverviewLinkedStudentsRequest } from '@purple/shared-types';

export const useSchoolOverviewLinkedStudents = (request: TSchoolOverviewLinkedStudentsRequest) => {
  const { schoolId, chart_type } = request;

  const { data, isError, isLoading } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_OVERVIEW_LINKED_STUDENTS, schoolId, chart_type],
    queryFn: () => getSchoolOverviewLinkedStudents(request),
    enabled: !!schoolId,
  });

  return {
    data: data ? convertSchoolOverviewLinkedStudents(data) : data,
    isError,
    isLoading,
  };
};
