import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import type { TActionGroupsRequestParameters, TActionGroupsResponse } from '@purple/shared-types';

export const getActionGroups = async (parameters: TActionGroupsRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActionGroupsResponse>(ACTIONS.GET_ACTION_GROUPS_CHOICES, {
    params: parameters,
  });
  return response.data;
};
