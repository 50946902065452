import { SAF_AREA_SUBCATEGORIES_LABELS, type TActionServiceCategory } from '@purple/shared-types';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';

const SHOW_CATEGORIES_LIMIT = 2;

type TCellServiceCategoryProperties = {
  categories: TActionServiceCategory[];
};

export const CellServiceCategory = ({ categories }: TCellServiceCategoryProperties) => {
  if (!categories || categories.length === 0) {
    return '—';
  }

  if (categories.length > SHOW_CATEGORIES_LIMIT) {
    const visibleCategories = categories.slice(0, SHOW_CATEGORIES_LIMIT);
    const remainingCategories = categories.slice(SHOW_CATEGORIES_LIMIT);

    return (
      <div className="flex items-center gap-1">
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <Text variant="size-14" type="body-500" className="text-grey-950">
              {visibleCategories.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')}
              , ...
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>
              {remainingCategories.map((category) => (
                <Text key={category.id} variant="size-14" type="body-500" className="text-white">
                  {SAF_AREA_SUBCATEGORIES_LABELS[category.name]}
                </Text>
              ))}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    );
  }
  return (
    <Text variant="size-14" type="body-500" className="text-grey-950">
      {categories.map((category) => SAF_AREA_SUBCATEGORIES_LABELS[category.name]).join(', ')}
    </Text>
  );
};
