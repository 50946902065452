import { useCallback, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { SAF_AREA_SUBCATEGORIES_LABELS } from '@purple/shared-types';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Message,
  Separator,
  Text,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useCloseSafOptions } from '~/queries';
import { useCloseSaf } from '~/queries/saf/useCloseSaf';
import { showErrorToast } from '~/shared/lib';
import { CategoryCheckbox } from './CategoryCheckbox';
import { CloseSafFormSkeleton } from './CloseSafFormSkeleton';
import type React from 'react';
import type { TSafAreaCategoriesValues } from '@purple/shared-types';

const closeSafFormSchema = z.object({
  basic_needs: z.boolean(),
  education_and_vocational: z.boolean(),
  emotional_and_mental_health: z.boolean(),
  extra_curricular_enrichment: z.boolean(),
  health: z.boolean(),
  legal_support: z.boolean(),
  life_events_stressors: z.boolean(),
  no_need: z.boolean(),
  service_area_subcategories: z
    .array(z.string())
    .min(1, { message: 'Please select at least one service area subcategory' }),
});

export type TCloseSafFormValues = z.infer<typeof closeSafFormSchema>;

type TCloseSafModalProperties = {
  safId?: string;
  onSuccess?: () => void;
};

export const CloseSafModal: React.FC<TCloseSafModalProperties> = ({ safId, onSuccess }) => {
  const { isOpen, toggleModal } = useModal('close-saf');
  const formId = useId();

  const { data, isLoading } = useCloseSafOptions(isOpen);
  const { mutate, isPending } = useCloseSaf();

  const form = useForm<TCloseSafFormValues>({
    mode: 'onChange',
    resolver: zodResolver(closeSafFormSchema),
    defaultValues: useMemo(
      () => ({
        basic_needs: false,
        education_and_vocational: false,
        emotional_and_mental_health: false,
        extra_curricular_enrichment: false,
        health: false,
        legal_support: false,
        life_events_stressors: false,
        no_need: false,
        service_area_subcategories: [],
      }),
      [],
    ),
  });

  const serviceAreaSubcategories = form.watch('service_area_subcategories');

  const closeSafHandler = useCallback(
    (formValues: TCloseSafFormValues) => {
      if (!safId) {
        return showErrorToast('System message', 'Unable to close SAF, please check if the SAF is valid');
      }
      mutate(
        { safId, service_area_subcategories: formValues.service_area_subcategories },
        {
          onSuccess: () => {
            onSuccess?.();
            toggleModal(!isOpen);
          },
        },
      );
    },
    [mutate, safId, toggleModal, isOpen, onSuccess],
  );

  const clearAllHandler = useCallback(() => {
    form.reset();
  }, [form]);

  const closeModalHandler = useCallback(() => {
    toggleModal(!isOpen);
    form.reset();
  }, [toggleModal, form, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-7 tracking-normal">Close SAF</DialogTitle>
            <DialogDescription>Before closing the SAF, indicate the services that were provided.</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <AnimatePresence mode="wait">
          {isLoading && !data && <CloseSafFormSkeleton />}
          {data && !isLoading && (
            <motion.form
              id={formId}
              key="form"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onSubmit={form.handleSubmit(closeSafHandler)}
              className="grid grid-cols-2 gap-4 overflow-y-auto p-6"
            >
              {form.formState.errors.service_area_subcategories && (
                <Message variant="error" className="col-span-2">
                  <Text variant="size-14" type="body-500">
                    You must select at least one service area subcategory.
                  </Text>
                </Message>
              )}
              {Object.entries(data).map(([key, value]) => {
                const categoryKey = key as TSafAreaCategoriesValues;
                return (
                  <div key={categoryKey} className="rounded-lg border border-grey-200 p-4">
                    <div className="flex flex-col gap-2.5">
                      <CategoryCheckbox
                        form={form}
                        categoryKey={categoryKey}
                        categoryLabel={SAF_AREA_SUBCATEGORIES_LABELS[categoryKey]}
                        subcategories={value}
                        serviceAreaSubcategories={serviceAreaSubcategories}
                      />
                    </div>
                  </div>
                );
              })}
            </motion.form>
          )}
        </AnimatePresence>
        <Separator />
        <DialogFooter className="flex items-center justify-between gap-2">
          <Button variant="secondary" disabled={isLoading || isPending} onClick={clearAllHandler}>
            Clear All
          </Button>
          <div>
            <Button variant="tertiary" onClick={closeModalHandler}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading || isPending} form={formId} isLoading={isPending}>
              Submit
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
