import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { SAF_STATUS, type TSafListManagerDto } from '@purple/shared-types';
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { AppRoutes } from '~/constants/routes/routes';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';

type TMainActionColumnProperties = {
  data: TSafListManagerDto;
};

const MainActionColumn = ({ data }: TMainActionColumnProperties) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions();

  const { openModal: openTakeActionModal } = useModal(ModalType.TAKE_ACTION);
  const { setSelectedStudentId } = useTakeAction();

  const canTakeAction = useMemo(() => {
    const isStudentLinked = Boolean(data.student);
    const isPriorityAssigned = data.priority !== null;

    return isStudentLinked && isPriorityAssigned && data.status === SAF_STATUS.CLOSED;
  }, [data]);

  const hideAction = useMemo(() => data.status === SAF_STATUS.CLOSED && !canTakeAction, [data, canTakeAction]);

  const handleTakeAction = () => {
    const studentPk = data.student?.id;
    if (studentPk) {
      setSelectedStudentId(studentPk);
      openTakeActionModal();
    }
  };

  const redirectToSafDetails = () => {
    navigate(AppRoutes.App.Safs.SAFDetails.makePath(data.id, 'saf-manager'));
  };

  if (canTakeAction) {
    const hasTakeActionPermissions = hasPermissions([
      APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
      APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
    ]);

    if (hasTakeActionPermissions) {
      return (
        <Button variant="secondary" size="small" onClick={handleTakeAction}>
          Take Action
        </Button>
      );
    }

    return (
      <Tooltip delayDuration={300}>
        <TooltipTrigger asChild>
          <span>
            <Button variant="secondary" size="small" onClick={handleTakeAction} disabled>
              Take Action
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            align="center"
            className="w-[220px] border border-grey-200 bg-white text-center text-grey-950"
            withArrow={false}
          >
            You do not have permissions to do this action.
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    );
  }

  if (hideAction) {
    return null;
  }

  return (
    <Button variant="secondary" size="small" onClick={redirectToSafDetails}>
      Process SAF
    </Button>
  );
};

export { MainActionColumn };
