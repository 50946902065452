import { useMutation } from '@tanstack/react-query';
import { safUnidentifiable } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSafUnidentifiable = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.UNIDENTIFIABLE],
    mutationFn: safUnidentifiable,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
      showSuccessToast('System message', 'The SAF was made as unidentifiable successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not make as unidentifiable. Check the provided information and try again',
      );
    },
  });
};
