import { createSelector } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { RootState } from '~/store';

const getState = (state: RootState) => state[AppReduxReducer.TAKE_ACTION];

export const takeActionModalSelector = createSelector(getState, (state) => state.actionTypeModalOpen);
export const takeActionStudentIdSelector = createSelector(getState, (state) => state.selectedStudentId);
export const takeActionPriorityListIdSelector = createSelector(getState, (state) => state.selectedPriorityListId);
export const takeActionSafIdSelector = createSelector(getState, (state) => state.selectedSafId);
