import { formatDuration, intervalToDuration } from 'date-fns';

export function formatMinutesToDuration(minutes?: number | null) {
  if (minutes === 0 || !minutes) {
    return '—';
  }
  const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });

  return formatDuration(duration, { format: ['hours', 'minutes'] });
}
