import { cn } from '@purple/ui';

type ToggleButtonProperties = {
  isActive: boolean;
  onClick: () => void;
  tooltip: string;
  ariaLabel: string;
  children: React.ReactNode;
};

export const ToggleButton = ({ isActive, onClick, tooltip, ariaLabel, children }: ToggleButtonProperties) => {
  return (
    <button
      className={cn(
        'flex h-8 w-8 items-center justify-center rounded-md text-grey-600 hover:bg-grey-100',
        isActive && 'bg-grey-200',
      )}
      onClick={onClick}
      type="button"
      title={tooltip}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};
