export const PURPLE_USER_PARTICIPANT_SORT = [
  {
    label: 'Name (ASC)',
    value: 'full_name',
  },
  {
    label: 'Name (DESC)',
    value: '-full_name',
  },
  {
    label: 'Title (ASC)',
    value: 'title',
  },
  {
    label: 'Title (DESC)',
    value: '-title',
  },
] as const;
