import { Badge, cn } from '@purple/ui';
import type { IStudentIncidentType } from '@purple/shared-types';

type TIncidentTypeComponentProperties = React.PropsWithChildren<{
  type: IStudentIncidentType;
}>;

const IncidentTypeComponent = ({ type }: TIncidentTypeComponentProperties) => {
  const { name, is_used_in_flag_calculations } = type;

  return (
    <div className="flex justify-center">
      <Badge
        label={name}
        className={cn({
          'bg-error-bg text-error-main': is_used_in_flag_calculations,
          'border border-error-main bg-white text-error-main': !is_used_in_flag_calculations,
        })}
      />
    </div>
  );
};

export { IncidentTypeComponent };
