import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { DISTRICTS_QUERY_KEYS, signInFormSchema } from '@purple/shared-utils';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Message,
  PasswordInput,
  Separator,
  Text,
} from '@purple/ui';
import AppLogo from '~/assets/images/default-district-logo.png';
import { AppRoutes } from '~/constants/routes/routes';
import { useLoginMutation } from '~/queries';
import type * as z from 'zod';
import type { TDistrictInfo } from '@purple/shared-types';

const APP_NAME = 'PurpleSENSE';
const API_BASE_URL = import.meta.env.VITE_API_URL;
const SSO_AUTH_URL = '/saml/signin/';

const SignIn = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParameters] = useSearchParams();

  const queryParameter = searchParameters.get('state') || '';
  const ssoError = searchParameters.get('error');

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { isPending, mutate: signIn } = useLoginMutation();
  const districtSettings = queryClient.getQueryData<TDistrictInfo>([DISTRICTS_QUERY_KEYS.CHECK]);

  const hasSsoProvider = useMemo(() => districtSettings?.provider_type !== null, [districtSettings?.provider_type]);
  const districtLogo = useMemo(() => (districtSettings && districtSettings.logo ? districtSettings.logo : AppLogo), [districtSettings]);
  const isGoogleSsoProvider = useMemo(() => districtSettings?.provider_type === 'google', [districtSettings?.provider_type]);
  const isMicrosoftSsoProvider = useMemo(() => districtSettings?.provider_type === 'microsoft', [districtSettings?.provider_type]);
  const isClassLinkSsoProvider = useMemo(() => districtSettings?.provider_type === 'classlink', [districtSettings?.provider_type]);

  const form = useForm<z.infer<typeof signInFormSchema>>({
    resolver: zodResolver(signInFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const formChangeHandler = () => {
    if (errorMessage !== null && form.formState.isValid) {
      setErrorMessage(null);
    }
  };

  const handleForgotPassword = () => {
    navigate(AppRoutes.Auth.ResetPassword.Root.path);
  };

  const handleLogin = (data: z.infer<typeof signInFormSchema>) => {
    signIn(data, {
      onError: (error) => {
        setErrorMessage(
          error.response?.data.detail
          ?? 'We could not log you in with the provided credentials. Please check your email and password, and try again. If the issue persists, contact support.',
        );
      },
    });
  };

  const ssoLoginHandler = useCallback(() => {
    window.location.replace(`${API_BASE_URL}${SSO_AUTH_URL}?district_pk=${districtSettings?.id}`);
  }, [districtSettings?.id]);

  return (
    <div className="flex w-full flex-col items-center gap-8 max-h-720:gap-2">
      <div className="flex flex-col items-center gap-8 max-h-720:gap-2">
        <Heading tag="h2" type="heading-600" variant="size-22" className="text-grey-950">
          Login to
          {' '}
          {APP_NAME}
        </Heading>
        <img src={districtLogo} alt={APP_NAME} className="aspect-square w-[108px] h-[108px]" />
      </div>
      {queryParameter === 'email_confirmed' && (
        <Message variant="success">
          <Text variant="size-14" type="body-500">
            Your email has been successfully changed. Please log in to verify your identity!
          </Text>
        </Message>
      )}
      <Form
        providerProps={form}
        className="flex w-full flex-col gap-4"
        onSubmit={form.handleSubmit(handleLogin)}
        onChange={formChangeHandler}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Email</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.email} placeholder="Enter your email" type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-1">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    {...field}
                    isError={!!form.formState.errors.password}
                    placeholder="Enter your password"
                    type="password"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <Button type="button" variant="link" size="link" onClick={handleForgotPassword}>
              Forgot password?
            </Button>
          </div>
        </div>
        {errorMessage !== null && (
          <Message variant="error">
            <Text variant="size-14" type="body-500">
              {errorMessage}
            </Text>
          </Message>
        )}
        {ssoError && (
          <Message variant="error">
            <Text variant="size-14" type="body-500">
              {ssoError}
            </Text>
          </Message>
        )}
        <Button type="submit" disabled={!form.formState.isValid} isLoading={isPending}>
          Login
        </Button>
      </Form>
      {hasSsoProvider && (
        <div className="flex w-full flex-row items-center gap-4">
          <Separator className="shrink" />
          <Heading tag="h5" type="heading-500" variant="size-18">
            OR
          </Heading>
          <Separator className="shrink" />
        </div>
      )}
      {hasSsoProvider && (
        <div className="flex w-full flex-col gap-4">
          {isGoogleSsoProvider && (
            <Button variant="secondary" iconLeft={<PurpleIcon name="Google" />} onClick={ssoLoginHandler}>
              Continue with Google
            </Button>
          )}
          {isMicrosoftSsoProvider && (
            <Button variant="secondary" iconLeft={<PurpleIcon name="Microsoft" />} onClick={ssoLoginHandler}>
              Continue with Microsoft
            </Button>
          )}
          {isClassLinkSsoProvider && (
            <Button variant="secondary" iconLeft={<PurpleIcon name="ClassLink" />} onClick={ssoLoginHandler}>
              Continue with ClassLink
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SignIn;
