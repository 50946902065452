import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionLinkedStudentsRequestParameters, TActionLinkedStudentsResponse } from '@purple/shared-types';

export const getActionLinkedStudents = async ({
  groupActionId,
  ...parameters
}: TActionLinkedStudentsRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TActionLinkedStudentsResponse>(
    replaceUrlParameters(STUDENTS_ENDPOINTS.GET_ACTION_LINKED_STUDENTS, groupActionId),
    {
      params: parameters,
    },
  );
  return response.data;
};
