export const SORT_OPTIONS = [
  {
    label: 'Incident Name (ASC)',
    value: 'name',
  },
  {
    label: 'Incident Name (DESC)',
    value: '-name',
  },
  {
    label: 'Type (ASC)',
    value: 'incident_type',
  },
  {
    label: 'Type (DESC)',
    value: '-incident_type',
  },
  {
    label: 'Incident Date (ASC)',
    value: 'incident_date_time',
  },
  {
    label: 'Incident Date (DESC)',
    value: '-incident_date_time',
  },
  {
    label: 'Description (ASC)',
    value: 'description',
  },
  {
    label: 'Description (DESC)',
    value: '-description',
  },
];
