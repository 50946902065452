import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStudentDashboard } from '@purple/shared-services';
import { getTransformObjectArrayToLabelValuePairs, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { IStudentDashboardResponse } from '@purple/shared-types';
import type { IStudentDashboardData } from './types';

function convertDashboardData(input: IStudentDashboardResponse): IStudentDashboardData {
  return {
    studentIs: {
      currentRate: input.insight.student_trending.current,
      prevRate: input.insight.student_trending.prev,
    },
    avgAttendancePerCycle: {
      currentRate: input.insight.avg_attendance_per_cycle.current,
      prevRate: input.insight.avg_attendance_per_cycle.prev,
    },
    totalSAFs: input.insight.total_safs,
    totalActions: input.insight.total_actions,
    meetingAttendance: input.insight.meeting_attendance,
    actions: getTransformObjectArrayToLabelValuePairs(input.attendance.actions),
    attendance: getTransformObjectArrayToLabelValuePairs(input.attendance.attendance),
    supportByType: getTransformObjectArrayToLabelValuePairs(input.circle_of_support.support_by_type),
    supportByRole: getTransformObjectArrayToLabelValuePairs(input.circle_of_support.support_by_role),
    supportByProgram: getTransformObjectArrayToLabelValuePairs(input.circle_of_support.support_by_program),
    supportByNeed: getTransformObjectArrayToLabelValuePairs(input.circle_of_support.support_by_need),
  };
}

export const useStudentDashboard = () => {
  const { studentId } = useParams();
  const { isError, data, isLoading } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_DASHBOARD, studentId],
    queryFn: () => getStudentDashboard(studentId as string),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Error while fetching student dashboard data. Please try again later.');
    }
  }, [isError]);

  return { isLoading, isError, data: data ? convertDashboardData(data) : data };
};
