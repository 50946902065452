import * as z from 'zod';

export const updateLocationDetailsSchema = z.object({
  language: z.string().optional(),
  timezone: z.string().optional(),
  address: z
    .union([
      z.object({
        street: z.string(),
        city: z.string(),
        state: z.string(),
        postal_code: z.string(),
        latitude: z.number(),
        longitude: z.number(),
      }),
      z.null(),
    ])
    .optional(),
});
