import { useQuery } from '@tanstack/react-query';
import { getStudentSafFilterOptions } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';

export const useStudentSafsFilterOptions = (studentId: string) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_SAF_FILTERS, { studentId }],
    queryFn: () => getStudentSafFilterOptions({ studentId }),
    enabled: !!studentId,
  });

  return {
    data,
    isError,
    isLoading,
  };
};
