import { Heading } from '@purple/ui';
import { useDashboard } from '~/queries';
import { AttendanceChart } from './AttendanceChart';
import { MyPriorityList } from './MyPriorityList';
import { NewProviders } from './NewProviders';
import { NewStudents } from './NewStudents';
import { OverdueAttendance } from './OverdueAttendance';
import { SafsNeedingPrioritization } from './SafsNeedingPrioritization';
import { SafSubmitters } from './SafSubmitters';
import { StudentsInRed } from './StudentsInRed';

export const DashboardTab: React.FC = () => {
  const { data: dashboardData, isFetching } = useDashboard();

  return (
    <section className="grid w-full gap-6">
      <div className="flex w-full flex-col gap-4">
        <Heading tag="h2" variant="size-22" type="heading-600" className="text-grey-title">
          Recent Updates
        </Heading>
        <div className="grid grid-cols-3 gap-6">
          <SafsNeedingPrioritization data={dashboardData?.safs_needed_prioritization} />
          <SafSubmitters data={dashboardData?.saf_submitters} isLoading={isFetching} />
          <NewStudents />
          <StudentsInRed />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <OverdueAttendance data={dashboardData?.overdue_attendance} isLoading={isFetching} />
        <AttendanceChart data={dashboardData?.students_absenteeism_percentages} isLoading={isFetching} />
      </div>
      <div className="flex w-full flex-col gap-4">
        <Heading tag="h2" variant="size-22" type="heading-600" className="text-grey-title">
          Quick Access
        </Heading>
        <div className="grid w-full grid-cols-2 gap-6">
          <NewProviders data={dashboardData?.new_providers} isLoading={isFetching} />
          <MyPriorityList data={dashboardData?.my_priority_lists} isLoading={isFetching} />
        </div>
      </div>
    </section>
  );
};
