import { useMutation } from '@tanstack/react-query';
import { createReminder } from '@purple/shared-services';
import { REMINDERS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useCreateReminder = () => {
  return useMutation({
    mutationKey: [REMINDERS_QUERY_KEYS.CREATE],
    mutationFn: createReminder,
    onSuccess: () => {
      showSuccessToast('System message', 'The reminder has been created successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create the reminder');
    },
  });
};
