import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFile } from '@purple/shared-services';
import { FILE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TFile, TFileUpdatePayload } from '@purple/shared-types';

export const useUpdateFile = () => {
  const queryClient = useQueryClient();
  return useMutation<TFile, AxiosError<Partial<Record<string, string[]>>>, TFileUpdatePayload>({
    mutationKey: [FILE_QUERY_KEYS.UPDATE_FILE],
    mutationFn: updateFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILES_LIST] });
      queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEYS.GET_FILE_BY_ID, data.id] });
      showSuccessToast('System message', 'File updated successfully');
    },
    onError: (error) => {
      if (error?.response?.data.file) {
        showErrorToast('System Message', error.response.data.file.join(', '));
        return;
      }
      showErrorToast('System Message', 'Failed to update the file');
    },
  });
};
