import { getAxiosInstance } from '../../api';
import { SCHOOLS_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TSchoolInfo, TSchoolUpdateRequest } from '@purple/shared-types';

export const updateSchoolInformation = async ({
  schoolId,
  request,
}: {
  schoolId: string;
  request: TSchoolUpdateRequest;
}) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.patch<TSchoolInfo>(
    replaceUrlParameters(SCHOOLS_ENDPOINTS.UPDATE_SCHOOL_INFO, schoolId),
    request,
  );
  return response.data;
};
