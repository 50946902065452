import { Skeleton } from '@purple/ui';

export const PieChartCardSkeleton = () => {
  return (
    <div className="flex flex-col gap-6 rounded-lg px-4 py-6 shadow-custom-medium">
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-[24px] w-1/2" />
        <Skeleton className="h-[32px] w-[32px]" />
      </div>
      <div className="flex items-center gap-4">
        <Skeleton className="h-[170px] w-[170px] shrink-0 rounded-full" />
        <div className="flex w-full flex-col gap-3">
          <Skeleton className="h-[20px] w-full" />
          <Skeleton className="h-[20px] w-full" />
          <Skeleton className="h-[20px] w-full" />
          <Skeleton className="h-[20px] w-full" />
          <Skeleton className="h-[20px] w-full" />
        </div>
      </div>
    </div>
  );
};
