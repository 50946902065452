import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
  Text,
} from '@purple/ui';
import { useAppSelector, useModal } from '~/hooks';
import { ECO_SYSTEM_DATA_SELECT_VIEW } from '~/modules/Home/Tabs/EcosystemHub/constants';
import { useCurrentUserPartialUpdate } from '~/queries';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import { userSelector } from '~/store/features/user';
import type { TEcoSystemDataViewValues } from '~/modules/Home/Tabs/EcosystemHub/constants';

export const DefaultEcoSystemDataView = () => {
  const queryClient = useQueryClient();
  const { ecosystem_default_view } = useAppSelector(userSelector);
  const { isOpen, closeModal, toggleModal } = useModal('default-eco-system-data-view');
  const [defaultDataView, setDefaultDataView] = useState<TEcoSystemDataViewValues>(ecosystem_default_view);

  const { mutate, isPending } = useCurrentUserPartialUpdate();

  const onValueChange = (newValue: TEcoSystemDataViewValues) => {
    setDefaultDataView(newValue);
  };

  const applyChangesHandler = () => {
    mutate(
      {
        ecosystem_default_view: defaultDataView,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT] });
          showSuccessToast('System Message', 'Default Ecosystem Hub View has been set successfully');
          closeModal();
        },
        onError: () => {
          showErrorToast('System Message', 'Failed to set the default Ecosystem Hub View');
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Set Default Ecosystem Hub View</DialogTitle>
          <DialogDescription className="sr-only">Set The Default Eco System Hub View</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="p-6">
          <div className="flex flex-col gap-1">
            <Text variant="size-14" type="body-500">
              Ecosystem Hub View
              <span className="text-error-main">*</span>
            </Text>
            <RadixSelect value={defaultDataView} onValueChange={onValueChange}>
              <RadixSelectTrigger>
                <RadixSelectValue />
              </RadixSelectTrigger>
              <RadixSelectContent>
                {ECO_SYSTEM_DATA_SELECT_VIEW.map((view) => (
                  <RadixSelectItem key={view.value} value={view.value}>
                    {view.label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
          </div>
        </div>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={applyChangesHandler} disabled={isPending} isLoading={isPending}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
