import { useMutation, useQueryClient } from '@tanstack/react-query';
import { safLinkStudent } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useSafLinkStudent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.LINK_STUDENT],
    mutationFn: safLinkStudent,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
      queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_DETAILS, id] });
      showSuccessToast('System Message', 'You successfully linked a student');
    },
    onError: () => {
      showErrorToast('System Message', 'Unable to link the student');
    },
  });
};
