import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentIncidentsFilterOptions } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useGetStudentIncidentsFilterOptions = () => {
  const { isLoading, isError, isSuccess, data, ...props } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_INCIDENTS_FILTERS],
    queryFn: () => getStudentIncidentsFilterOptions(),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student incidents filters', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { ...props, isError, isLoading, isSuccess, data };
};
