import { useMutation } from '@tanstack/react-query';
import { createSaf } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useCreateSaf = () => {
  return useMutation({
    mutationKey: [SAF_QUERY_KEYS.CREATE_SAF],
    mutationFn: createSaf,
    onError: () => {
      showErrorToast(
        'System message',
        'Could not create the student assistance form. Check the provided information and try again',
      );
    },
  });
};
