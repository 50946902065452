import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';
import type { TActionDetailsUpdateBody, TActionDetailsUpdateResponse } from '@purple/shared-types';

export const updateActionDetails = async (body: TActionDetailsUpdateBody) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.patch<TActionDetailsUpdateResponse>(
    replaceUrlParameters(ACTIONS.ACTION_DETAILS, body.id),
    body,
  );
  return response.data;
};
