import { getAxiosInstance } from '../../api';
import { PERMISSIONS_ENDPOINTS } from '../../constants/private-api';
import type { TAppPermissions } from '@purple/permissions';

type UserPermissionsResponseBody = {
  permissions: TAppPermissions[];
};

export const getUserPermissions = async () => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<UserPermissionsResponseBody>(PERMISSIONS_ENDPOINTS.GET_USER_PERMISSIONS);

  return response.data;
};
