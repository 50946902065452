import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import type { TSchoolDataCalendarRequestParameters, TSchoolDataCalendarResponse } from '@purple/shared-types';

export const getSchoolDataCalendar = async (parameters: TSchoolDataCalendarRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TSchoolDataCalendarResponse>(SCHOOLS_ENDPOINTS.GET_SCHOOL_DATA_CALENDAR, {
    params: parameters,
  });
  return response.data;
};
