import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useGoBack, useModal } from '~/hooks';
import { useDeleteAction } from '~/queries';
import type React from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TDeleteActionDialogProperties = {
  action: TActionDetails;
};

export const DeleteActionDialog: React.FC<TDeleteActionDialogProperties> = (props) => {
  const { action } = props;

  const { isOpen, toggleModal, closeModal } = useModal('delete-action');
  const { mutate: deleteAction, isPending } = useDeleteAction();
  const { onGoBack } = useGoBack({
    fallbackPath: action?.details?.school?.id
      ? AppRoutes.App.School.Root.makePath(action.details.school.id, 'actions')
      : null,
  });

  const deleteActionHandler = () => {
    deleteAction(action.id, {
      onSuccess: () => {
        closeModal();
        onGoBack();
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-full max-w-[480px]">
        <DialogHeader className="flex-row items-center justify-between p-6 pb-0">
          <DialogTitle className='className="leading-7 tracking-normal'>
            Are you sure you want to delete this action?
          </DialogTitle>
          <DialogDescription className="sr-only">
            Confirm if you want to delete the action with title:
            {' '}
            {action.title}
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Text variant="size-14" type="body-400" className="px-6 pb-1 pt-3 text-grey-950">
          Please keep in mind that there is no way to restore or backup this action once you proceed.
        </Text>
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            disabled={isPending}
            isLoading={isPending}
            onClick={deleteActionHandler}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
