import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSupportByProgramList } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentSupportByProgramQueryParameters } from '@purple/shared-types';

export const useSupportByProgramList = ({
  studentId,
  queryParameters,
}: {
  studentId: string;
  queryParameters: TStudentSupportByProgramQueryParameters;
}) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_SUPPORT_BY_PROGRAM, { studentId, queryParameters }],
    queryFn: () => getSupportByProgramList({ studentId, queryParameters }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'An error occurred while fetching support by program data');
    }
  });

  return { isError, ...rest };
};
