import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCurrentProfile } from '@purple/shared-services';
import { USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TUserProfileDTO, TUserProfileMutationError, TUserProfileUpdateDTO } from '@purple/shared-types';

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<TUserProfileDTO, AxiosError<TUserProfileMutationError>, { requestPayload: TUserProfileUpdateDTO }>(
    {
      mutationKey: [USER_QUERY_KEYS.CURRENT_PROFILE_UPDATE],
      mutationFn: updateCurrentProfile,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT_PROFILE] });
        showSuccessToast('System message', 'The profile was updated successfully');
      },
      onError: (errorResponse) => {
        if (errorResponse?.response?.data.avatar) {
          showErrorToast('System Message', errorResponse.response.data.avatar.join(', '));
          return;
        }
        showErrorToast('System Message', 'Failed to update the profile');
      },
    },
  );
};
