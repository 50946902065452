import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useProfileFiles } from '~/queries';
import { ActionsHeaderSection } from './ActionsHeaderSection';
import { useProfileFilesColumns } from './profileFilesColumns';

const MyFiles = () => {
  const [searchParameters] = useSearchParams();

  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { isLoading: isDataLoading, data } = useProfileFiles({
    search,
    limit,
    offset,
    ordering: sort,
  });

  const filesColumns = useProfileFilesColumns();

  const isDataNotExist = useMemo(
    () => (!data?.results || data.results.length === 0) && !isDataLoading,
    [data?.results, isDataLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  return (
    <section className="flex h-fit w-full flex-col rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
      <div className="flex px-4 py-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          My Files
        </Heading>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-5 px-4">
          <ActionsHeaderSection />
        </div>
        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={filesColumns}
              data={data?.results || []}
              isLoading={isDataLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No Files found"
              emptyStateMessage="There are no Files to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {!isDataNotExist && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={data?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export { MyFiles };
