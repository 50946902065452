import { useTabs } from '@purple/hooks';
import { AccessDenied, AppTabs } from '@purple/ui';
import { schoolTabs } from './school-tabs';

export const SchoolTabs: React.FC = () => {
  const { activeTab, tabChangeHandler } = useTabs({
    tabs: schoolTabs,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied />
      </div>
    );
  }

  return <AppTabs className="col-span-2" tabs={schoolTabs} value={activeTab} onValueChange={tabChangeHandler} />;
};
