import { Skeleton } from '@purple/ui';

export const SafsHeaderSectionSkeleton = () => {
  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-4">
        <Skeleton className="h-[40px] w-[120px]" />
        <Skeleton className="h-[40px] w-[200px]" />
        <Skeleton className="h-[40px] w-[180px]" />
      </div>
      <Skeleton className="h-[40px] w-[300px]" />
    </div>
  );
};
