import { formatDate } from '@purple/shared-utils';
import { InfoItem } from '@purple/ui';
import { InfoItemValue } from '~/components';
import type { FC } from 'react';
import type { IStudentDemographic } from '@purple/shared-types';

type StudentDemographicProperties = {
  data: IStudentDemographic;
};

const StudentDemographic: FC<StudentDemographicProperties> = ({ data }) => {
  const { updated_at, gender, age, birthday, ethnicity, overage_years, subpop } = data;

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Last Update">
        <InfoItemValue>{formatDate(updated_at)}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Gender">
        <InfoItemValue>{gender}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Age">
        <InfoItemValue>{age}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Birthdate">
        <InfoItemValue>{birthday ? formatDate(birthday) : '-'}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Ethnicity">
        <InfoItemValue>{ethnicity}</InfoItemValue>
      </InfoItem>
      <InfoItem label="Overage Years">
        <InfoItemValue>{overage_years}</InfoItemValue>
      </InfoItem>
      {subpop.map((subpopItem) => (
        <InfoItem key={subpopItem.id} label={subpopItem.name}>
          <InfoItemValue>Yes</InfoItemValue>
        </InfoItem>
      ))}
    </div>
  );
};

export { StudentDemographic };
