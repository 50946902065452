import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteRecurringReminder } from '@purple/shared-services';
import { REMINDERS_QUERY_KEYS, USER_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useDeleteRecurringReminder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [REMINDERS_QUERY_KEYS.DELETE_RECURRING_REMINDER],
    mutationFn: deleteRecurringReminder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.EVENTS] });
      showSuccessToast('System Message', 'All reminders in the group have been deleted');
    },
    onError: () => {
      showErrorToast('System Message', 'An error occurred while deleting reminders');
    },
  });
};
