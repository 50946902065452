import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getActionLinkedStudents } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TActionLinkedStudentsRequestParameters } from '@purple/shared-types';

export const useActionLinkedStudents = (parameters: TActionLinkedStudentsRequestParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_ACTION_LINKED_STUDENTS, parameters],
    queryFn: () => getActionLinkedStudents(parameters),
    enabled: !!parameters.groupActionId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch action linked students');
    }
  }, [isError]);

  return { isError, ...rest };
};
