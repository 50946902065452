import { Link } from 'react-router-dom';
import { formateDateShortMonth } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { FileAction } from './FileAction';
import type { ColumnDef } from '@tanstack/react-table';
import type { TFileListItem } from '@purple/shared-types';

type TFilesOptions = {
  onSelectFileId: (fileId: string) => void;
  onSelectFile: (file: File | null) => void;
};

type TFilesColumns = (options: TFilesOptions) => ColumnDef<TFileListItem>[];

export const useFilesColumns: TFilesColumns = (options) => {
  const { onSelectFileId, onSelectFile } = options;

  return [
    {
      accessorKey: 'title',
      meta: { className: 'min-w-[240px] max-w-[240px] w-[240px]' },
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Title
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Link
          to={original.file}
          target="_blank"
          rel="noreferrer"
          className="inline-block max-w-[200px] cursor-pointer truncate align-middle font-primary font-medium text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
        >
          {original.title}
        </Link>
      ),
    },
    {
      accessorKey: 'owner',
      meta: { className: 'min-w-[220px] max-w-[220px] w-[220px]' },
      header: () => (
        <Text variant="size-12" type="body-600" className="min-w-[180px] max-w-[180px] uppercase text-grey-600">
          Owner
        </Text>
      ),
      cell: ({
        row: {
          original: { created_by },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="max-w-[180px] truncate text-grey-950">
          {created_by?.full_name || '–'}
        </Text>
      ),
    },
    {
      accessorKey: 'last_modified',
      meta: { className: 'min-w-[220px] w-full' },
      header: () => (
        <Text variant="size-12" type="body-600" className="min-w-[180px] uppercase text-grey-600">
          Last Modified
        </Text>
      ),
      cell: ({
        row: {
          original: { updated_at },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {formateDateShortMonth(updated_at)}
        </Text>
      ),
    },
    {
      accessorKey: 'size',
      meta: { className: 'min-w-[140px] max-w-[140px] w-[140px]' },
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Size
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {original.size.toFixed(2)}
          {' '}
          MB
        </Text>
      ),
    },
    {
      accessorKey: 'actions',
      meta: { className: 'max-w-[72px] min-w-[72px] w-[72px]' },
      header: () => null,
      cell: ({ row: { original } }) => (
        <FileAction note={original} onSelect={onSelectFileId} onReplace={onSelectFile} />
      ),
    },
  ] satisfies ColumnDef<TFileListItem>[];
};
