import React from 'react';
import { DataNotFoundLayout, EntityDetailsLayout } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { useAccordion } from '~/hooks';
import { useGetStudentDetails } from '~/queries';
import { StudentNewlyCreatedStatus, StudentProfileHeader, StudentProfileHeaderSkeleton } from './components';
import { useStudentSidebarAccordionItems } from './useStudentSidebarAccordionItems';

type StudentProfileSidebarProperties = {
  studentId: string;
};

const StudentProfileSidebar = ({ studentId }: StudentProfileSidebarProperties) => {
  const { data, isLoading, isError, refetch } = useGetStudentDetails(studentId);

  const isNewlyCreated = React.useMemo(() => data?.details?.is_newly_created, [data]);

  const accordionItems = useStudentSidebarAccordionItems(data, studentId);

  const { allowedTabs } = useAccordion({
    tabs: accordionItems,
  });

  return (
    <EntityDetailsLayout
      back={(
        <LinkButton to={AppRoutes.App.Visualizer.Root.path} iconName="chevron-left">
          Back to Student Data
        </LinkButton>
      )}
      header={
        isLoading || !data
          ? (
              <StudentProfileHeaderSkeleton />
            )
          : (
              <StudentProfileHeader studentPk={studentId} data={data.details} />
            )
      }
      content={allowedTabs}
      errorContent={isError ? <DataNotFoundLayout onRetry={() => refetch()} className="h-[608px]" /> : null}
      statusContent={isNewlyCreated && <StudentNewlyCreatedStatus />}
    />
  );
};

export { StudentProfileSidebar };
