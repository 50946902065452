import { useMemo } from 'react';
import { InfoItem } from '@purple/ui';
import { InfoItemValue, MapComponent } from '~/components';
import type { TSchoolAddress, TSchoolInfo } from '@purple/shared-types';

type TAddressViewProperties = {
  data: TSchoolInfo;
};

const AddressView = ({ data }: TAddressViewProperties) => {
  const { address } = data;
  const { address: schoolAddress } = address;

  const formatAddress = useMemo(() => {
    if (!schoolAddress) return '-';
    return `${schoolAddress.street}, ${schoolAddress.city}, ${schoolAddress.state} ${schoolAddress.postal_code}`;
  }, [schoolAddress]);

  const isAddressExist = useMemo(() => Boolean(schoolAddress), [schoolAddress]);

  return (
    <div className="flex flex-col gap-4">
      <InfoItem label="Address">
        <InfoItemValue>{formatAddress}</InfoItemValue>
      </InfoItem>
      {isAddressExist && <MapComponent location={schoolAddress as TSchoolAddress} />}
    </div>
  );
};

export { AddressView };
