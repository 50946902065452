import { TakeActionType } from '@purple/shared-types';
import { ReadableTakeActionType } from '~/constants/take-action';

export const ACTION_TYPES_OPTIONS = [
  {
    label: ReadableTakeActionType[TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION],
    value: TakeActionType.ACADEMIC_CAREER_PLANNING_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.CHECK_IN],
    value: TakeActionType.CHECK_IN,
  },
  {
    label: ReadableTakeActionType[TakeActionType.COUNSELING_SESSION],
    value: TakeActionType.COUNSELING_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.GUIDANCE_COUNSELING_LESSON],
    value: TakeActionType.GUIDANCE_COUNSELING_LESSON,
  },
  {
    label: ReadableTakeActionType[TakeActionType.CRISIS_RESPONSE_SESSION],
    value: TakeActionType.CRISIS_RESPONSE_SESSION,
  },
  {
    label: ReadableTakeActionType[TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING],
    value: TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING,
  },
] as const;

export const GROUP_ACTIONS_SORT_OPTIONS = [
  {
    label: 'Duration (ASC)',
    value: 'duration',
  },
  {
    label: 'Duration (DESC)',
    value: '-duration',
  },
  {
    label: 'Created By (ASC)',
    value: 'created_by',
  },
  {
    label: 'Created By (DESC)',
    value: '-created_by',
  },
  {
    label: 'Date Created (ASC)',
    value: 'created_at',
  },
  {
    label: 'Date Created (DESC)',
    value: '-created_at',
  },
] as const;
