import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { SAF_STATUS, TakeActionType, type TSafListManagerDto } from '@purple/shared-types';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { AppRoutes } from '~/constants/routes/routes';
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';

type TSafListItemOptionsProperties = {
  data: TSafListManagerDto;
  onSafIdChange: (safId: string) => void;
};

const SafListItemOptions = ({ data, onSafIdChange }: TSafListItemOptionsProperties) => {
  const navigate = useNavigate();

  const { setSelectedStudentId, toggleActionModal } = useTakeAction();

  const { openModal: openPlaceOnHoldModal } = useModal(ModalType.SAF_PLACE_ON_HOLD);
  const { openModal: openRemoveFromHoldModal } = useModal(ModalType.SAF_REMOVE_FROM_HOLD);
  const { openModal: openTransferModal } = useModal(ModalType.SAF_TRANSFER);
  const { openModal: openNoNeedModal } = useModal(ModalType.SAF_NO_NEED);
  const { openModal: openUnidentifiableModal } = useModal(ModalType.SAF_UNIDENTIFIABLE);

  const isStudentLinked = Boolean(data.student);
  const isSafClosed = useMemo(() => data.status === SAF_STATUS.CLOSED, [data]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const setSelectedSafId = () => {
    onSafIdChange(data.id);
  };

  const editClickHandler = () => {
    setIsDropdownOpen(false);
    navigate(AppRoutes.App.Safs.SAFDetails.makePath(data.id, 'saf-manager'));
  };

  const placeOnHoldClickHandler = () => {
    setIsDropdownOpen(false);
    setSelectedSafId();
    openPlaceOnHoldModal();
  };

  const removeFromHoldClickHandler = () => {
    setIsDropdownOpen(false);
    setSelectedSafId();
    openRemoveFromHoldModal();
  };

  const transferSafClickHandler = () => {
    setIsDropdownOpen(false);
    setSelectedSafId();
    openTransferModal();
  };

  const sendEmailToSubmitterClickHandler = () => {
    setIsDropdownOpen(false);
  };

  const studentUnidentifiableClickHandler = () => {
    setIsDropdownOpen(false);
    setSelectedSafId();
    openUnidentifiableModal();
  };

  const noNeedSafClickHandler = () => {
    setIsDropdownOpen(false);
    setSelectedSafId();
    openNoNeedModal();
  };

  const setReminderClickHandler = () => {
    setIsDropdownOpen(false);

    const studentPk = data.student?.id;
    if (studentPk) {
      setSelectedStudentId(studentPk);
      toggleActionModal(TakeActionType.REMINDER);
    }
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button iconLeft={<PurpleIcon name="dots-vertical" />} variant="tertiary_icon_only" size="icon_32" />
      </DropdownTrigger>
      <DropdownContent className="max-w-[248px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={editClickHandler}
          className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="pencil" />}
        >
          Edit
        </Button>
        {isStudentLinked && data.status !== SAF_STATUS.ON_HOLD && !isSafClosed && (
          <Button
            variant="link"
            onClick={placeOnHoldClickHandler}
            className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
            iconLeft={<PurpleIcon name="pause" />}
          >
            Place on Hold
          </Button>
        )}

        {isStudentLinked && data.status === SAF_STATUS.ON_HOLD && !isSafClosed && (
          <Button
            variant="link"
            onClick={removeFromHoldClickHandler}
            className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
            iconLeft={<PurpleIcon name="play" />}
          >
            Remove from Hold
          </Button>
        )}

        <Button
          variant="link"
          onClick={transferSafClickHandler}
          className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="share" />}
        >
          Transfer SAF
        </Button>
        <Button
          variant="link"
          onClick={sendEmailToSubmitterClickHandler}
          className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="mail" />}
        >
          Send Email to Submitter
        </Button>
        {!isSafClosed && !isStudentLinked && (
          <Button
            variant="link"
            onClick={studentUnidentifiableClickHandler}
            className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
            iconLeft={<PurpleIcon name="question-mark-circle" />}
          >
            Student Unidentifiable
          </Button>
        )}

        {isStudentLinked && !isSafClosed && (
          <Button
            variant="link"
            onClick={noNeedSafClickHandler}
            className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
            iconLeft={<PurpleIcon name="X-Circle" />}
          >
            No Need SAF
          </Button>
        )}

        {isStudentLinked && (
          <Guard
            requiredPermissions={[
              APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
              APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
            ]}
          >
            <Button
              variant="link"
              onClick={setReminderClickHandler}
              className="w-full justify-start text-grey-600 hover:text-grey-950 active:text-grey-950"
              iconLeft={<PurpleIcon name="bell" />}
            >
              Set Reminder
            </Button>
          </Guard>
        )}
      </DropdownContent>
    </DropdownRoot>
  );
};

export { SafListItemOptions };
