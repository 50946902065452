import { useState } from 'react';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import { useAddSharePriorityListBulk } from './add-bulk-share';
import { useRemoveSharePriorityListBulk } from './remove-bulk-share';

type TSharePriorityListProperties = {
  idsToAdd: string[];
  idsToRemove: string[];
  listId: string;
};

export const useSharePriorityList = () => {
  const {
    mutate: addToShareList,
    isPending: isAddingToShareListUsers,
    isError: isAddToShareListError,
  } = useAddSharePriorityListBulk();
  const {
    mutate: removeFromShareList,
    isPending: isRemovingFromShareListUsers,
    isError: isRemoveFromShareListError,
  } = useRemoveSharePriorityListBulk();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const shareHandler = ({ idsToAdd, idsToRemove, listId }: TSharePriorityListProperties) => {
    setIsSuccess(false);
    const promises = [];

    if (idsToAdd.length > 0) {
      promises.push(
        new Promise<void>((resolve, reject) => {
          addToShareList(
            { id: listId, users: idsToAdd },
            {
              onSuccess: () => resolve(),
              onError: (error) => reject(new Error(error?.message)),
            },
          );
        }),
      );
    }

    if (idsToRemove.length > 0) {
      promises.push(
        new Promise<void>((resolve, reject) => {
          removeFromShareList(
            { id: listId, users: idsToRemove },
            {
              onSuccess: () => resolve(),
              onError: (error) => reject(new Error(error?.message)),
            },
          );
        }),
      );
    }

    if (promises.length > 0) {
      Promise.allSettled(promises)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LISTS] });
          queryClient.invalidateQueries({ queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_BY_ID] });
          showSuccessToast('System message', 'The share list was successfully updated');
          setIsSuccess(true);
        })
        .catch(() => {
          showErrorToast('System message', 'Failed to update the share list');
        });
    }
  };

  return {
    share: shareHandler,
    isPending: isAddingToShareListUsers || isRemovingFromShareListUsers,
    isSuccess,
    isError: isAddToShareListError || isRemoveFromShareListError,
  };
};
