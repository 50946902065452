import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useDeleteNote } from '~/queries';
import type React from 'react';

type TDeleteActionNoteDialogProperties = {
  noteId?: string | null;
};

export const DeleteActionNoteDialog: React.FC<TDeleteActionNoteDialogProperties> = (props) => {
  const { noteId = null } = props;

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.DELETE_ACTION_NOTE);
  const { mutate: deleteNote, isPending } = useDeleteNote();

  const deleteActionHandler = () => {
    if (!noteId) return;
    deleteNote(
      { noteId },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-full max-w-[480px]">
        <DialogHeader className="flex-row items-center justify-between p-6 pb-0">
          <DialogTitle className='className="leading-7 tracking-normal'>
            Are you sure you want to delete this note?
          </DialogTitle>
          <DialogDescription className="sr-only">
            Confirm if you want to delete note that is related to the action.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Text variant="size-14" type="body-400" className="px-6 pb-1 pt-3 text-grey-950">
          By deleting this note, you will permanently remove it from the action.
        </Text>
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive_primary"
            disabled={isPending}
            isLoading={isPending}
            onClick={deleteActionHandler}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
