import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const deleteGroupAction = async (groupActionId: string) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.delete<void>(replaceUrlParameters(ACTIONS.ACTION_GROUPS_BY_ID, groupActionId));

  return response.data;
};
