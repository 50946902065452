import { SAF_PRIORITY, type TSchoolSafItem } from '@purple/shared-types';
import { cn, Text } from '@purple/ui';

type TPriorityTableCellProperties = {
  rowData: TSchoolSafItem;
};

export const PriorityTableCell = ({ rowData }: TPriorityTableCellProperties) => {
  const isLow = rowData.priority?.toLowerCase() === SAF_PRIORITY.LOW;
  const isMedium = rowData.priority?.toLowerCase() === SAF_PRIORITY.MEDIUM;
  const isHigh = rowData.priority?.toLowerCase() === SAF_PRIORITY.HIGH;

  if (!rowData.priority) {
    return '—';
  }

  return (
    <div
      className={cn('w-max rounded-full px-2.5 py-1', {
        'bg-brand-blue-100': isLow,
        'bg-warning-bg': isMedium,
        'bg-error-bg': isHigh,
      })}
    >
      <Text
        variant="size-14"
        type="body-500"
        className={cn('text-center capitalize', {
          'text-brand-blue-700': isLow,
          'text-warning-main': isMedium,
          'text-error-main': isHigh,
        })}
      >
        {rowData.priority}
      </Text>
    </div>
  );
};
