import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMySchoolList } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TSchoolListRequestParameters } from '@purple/shared-types';

export const useMySchools = (parameters: TSchoolListRequestParameters) => {
  const { limit = 100, offset = 0, search = '', ...rest } = parameters;

  const { isError, ...props } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_MY_SCHOOLS, limit, offset, search],
    queryFn: () => getMySchoolList({ limit, offset, search, ...rest }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch list of my schools', 'Please try again later');
    }
  }, [isError]);

  return { ...props, isError };
};
