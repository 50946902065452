import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentCalendarEvents } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertToMonthEventCalendar } from '../converter';
import type { IStudentCalendarRequest } from '@purple/shared-types';

export const useGetStudentCalendarEvents = (requestOptions: IStudentCalendarRequest) => {
  const { studentId, month, year, itemType, period } = requestOptions;

  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENT_CALENDAR, studentId, month, year, itemType, period],
    queryFn: () => getStudentCalendarEvents(requestOptions),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student calendar data', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isSuccess, isError, data: data ? convertToMonthEventCalendar(data) : data };
};
