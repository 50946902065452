import { SAF_PRIORITY, SAF_STATUS } from '@purple/shared-types';
import { convertToLabel } from '@purple/shared-utils';

export const SAF_SORT_SELECT_OPTIONS = [
  {
    label: 'Student Name (ASC)',
    value: 'student_last_name',
  },
  {
    label: 'Student Name (DESC)',
    value: '-student_last_name',
  },
  {
    label: 'Submitter (ASC)',
    value: 'submitter__last_name',
  },
  {
    label: 'Submitter (DESC)',
    value: '-submitter__last_name',
  },
  {
    label: 'Submitter Type (ASC)',
    value: 'submitter_type',
  },
  {
    label: 'Submitter Type (DESC)',
    value: '-submitter_type',
  },
  {
    label: 'Status (ASC)',
    value: 'status',
  },
  {
    label: 'Status (DESC)',
    value: '-status',
  },
  {
    label: 'Priority (ASC)',
    value: 'priority',
  },
  {
    label: 'Priority (DESC)',
    value: '-priority',
  },
  {
    label: 'Submitted Date (ASC)',
    value: 'created_at',
  },
  {
    label: 'Submitted Date (DESC)',
    value: '-created_at',
  },
  {
    label: 'Prioritized On (ASC)',
    value: 'priority_assigned_at',
  },
  {
    label: 'Prioritized On (DESC)',
    value: '-priority_assigned_at',
  },
  {
    label: 'SAF Owner (ASC)',
    value: 'current_owner__last_name',
  },
  {
    label: 'SAF Owner (DESC)',
    value: '-current_owner__last_name',
  },
] as const;

export const STATIC_SAF_FILTERS = {
  categories: [
    {
      label: 'Status',
      value: 'status',
      filters: Object.values(SAF_STATUS).map((status) => ({
        label: convertToLabel(status),
        value: status,
      })),
    },
    {
      label: 'Priority',
      value: 'priority',
      filters: Object.values(SAF_PRIORITY).map((priority) => ({
        label: convertToLabel(priority),
        value: priority,
      })),
    },
  ],
};

export const SELECTED_SAF_ID_QUERY = 'selected_saf';
