import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import type { TSchoolDataStatsRequestParameters, TSchoolDataStatsResponse } from '@purple/shared-types';

export const getSchoolDataStats = async (parameters: TSchoolDataStatsRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TSchoolDataStatsResponse>(SCHOOLS_ENDPOINTS.GET_SCHOOL_DATA_STATS, {
    params: parameters,
  });
  return response.data;
};
