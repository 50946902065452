import { useMutation } from '@tanstack/react-query';
import { removeStudentsFromPriorityList } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useRemoveStudentsFromPriorityList = () => {
  return useMutation({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.REMOVE_STUDENT_FROM_PRIORITY_LIST],
    mutationFn: removeStudentsFromPriorityList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_STUDENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_BY_ID],
      });
      showSuccessToast('System message', 'The students were removed successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not remove the students. Check the provided information and try again');
    },
  });
};
