import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { isFieldExist } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, Text, Textarea } from '@purple/ui';
import { useUpdateGroupAction } from '~/queries';
import { GroupActionSectionHeader } from './GroupActionSectionHeader';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

const goalsSchema = z.object({
  goal: z.string().trim(),
});

type TGroupActionGoalsSectionProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionGoalsSection: React.FC<TGroupActionGoalsSectionProperties> = (props) => {
  const { groupAction } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateGroupAction, isPending } = useUpdateGroupAction();

  const defaultValues = useMemo(
    () => ({
      goal: groupAction.goal_or_progress_towards_goal ?? '',
    }),
    [groupAction],
  );

  const form = useForm<z.infer<typeof goalsSchema>>({
    resolver: zodResolver(goalsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveGoalsClickHandler = (formData: z.infer<typeof goalsSchema>) => {
    updateGroupAction(
      {
        id: groupAction.id,
        goal_or_progress_towards_goal: formData.goal,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          form.reset(formData);
        },
      },
    );
  };

  if (!isFieldExist(groupAction.goal_or_progress_towards_goal)) return null;

  return (
    <div className="flex w-full flex-col gap-2">
      <GroupActionSectionHeader
        title="Goals / Progress Towards Goals"
        editing={isEditing}
        loading={isPending}
        disableEditing
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveGoalsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="goal"
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter goals here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <Text className="text-balance font-primary text-base font-normal text-grey-600">
              {groupAction.goal_or_progress_towards_goal || 'No goals set'}
            </Text>
          )}
    </div>
  );
};
