import { useQuery } from '@tanstack/react-query';
import { getSchoolSafFilterOptions } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';

export const useSafsFilterOptions = (schoolId: string) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOLS_SAF_FILTERS, { schoolId }],
    queryFn: () => getSchoolSafFilterOptions({ schoolId }),
    enabled: !!schoolId,
  });

  return {
    data,
    isError,
    isLoading,
  };
};
