import { PurpleIcon } from '@purple/icons';
import { Button, Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, Separator } from '@purple/ui';
import { useAppSelector, useModal } from '~/hooks';
import { useGetStudentIncidentDetail } from '~/queries';
import { studentIncidentIdSelector } from '~/store/features/studentIncident';
import { Content } from './Content';
import { ContentSkeleton } from './ContentSkeleton';

export const StudentIncidentDetailModal = () => {
  const { isOpen, toggleModal } = useModal('detail-student-incident');

  const studentIncidentId = useAppSelector(studentIncidentIdSelector);

  const { data, isLoading } = useGetStudentIncidentDetail(studentIncidentId);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[840px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Incident Details</DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        {!data || isLoading ? <ContentSkeleton /> : <Content data={data} />}
      </DialogContent>
    </Dialog>
  );
};
