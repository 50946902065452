import { useMutation } from '@tanstack/react-query';
import { deletePriorityList } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useDeletePriorityList = () => {
  return useMutation({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.DELETE_PRIORITY_LIST],
    mutationFn: deletePriorityList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LISTS] });
      showSuccessToast('System message', 'The priority list was removed successfully');
    },
    onError: () => {
      showErrorToast(
        'System message',
        'Could not remove a priority list. Check the provided information and try again',
      );
    },
  });
};
