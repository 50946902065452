import { useSubPageView } from '~/hooks';
import { DASHBOARD_VIEW } from './constants';
import { Dashboard } from './Dashboard';
import { MeetingAttendance } from './MeetingAttendance';
import { TotalActions } from './TotalActions';
import { TotalSafs } from './TotalSafs';

const DashboardContainer = () => {
  const { view } = useSubPageView({ defaultView: DASHBOARD_VIEW.DASHBOARD });

  switch (view) {
    case DASHBOARD_VIEW.TOTAL_ACTIONS: {
      return <TotalActions />;
    }
    case DASHBOARD_VIEW.TOTAL_SAFS: {
      return <TotalSafs />;
    }
    case DASHBOARD_VIEW.MEETING_ATTENDANCE: {
      return <MeetingAttendance />;
    }
    case DASHBOARD_VIEW.DASHBOARD: {
      return <Dashboard />;
    }
    default: {
      return null;
    }
  }
};

export { DashboardContainer };
