import { Skeleton } from '@purple/ui';

export const AttendanceOverYearSkeleton: React.FC = () => {
  return (
    <div className="flex min-h-[244px] w-full flex-col gap-4 rounded-lg border border-grey-200">
      <Skeleton className="mx-2 mt-6 h-[24px] w-[170px]" />
      <div className="flex w-full flex-col items-center px-2">
        <Skeleton className="my-4 h-[28px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-4 h-[28px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-4 h-[28px] w-full" />
      </div>
    </div>
  );
};
