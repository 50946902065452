import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton, DeleteStudentNoteDialog, StudentNoteDialog } from '~/components';
import { useNotesList } from '~/queries';
import { NotesHeaderSection } from './NotesHeaderSection';
import { useNotesColumns } from './useNotesColumns';

export const NotesTab = () => {
  const { studentId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [noteModalType, setNoteModalType] = useState<'create' | 'edit' | 'preview'>('create');

  const notesColumns = useNotesColumns({ onSelectOption: setSelectedNoteId, onModeChange: setNoteModalType });

  const { data, isLoading } = useNotesList({
    limit,
    offset,
    search: debounceSearch,
    ordering: sort,
    student: studentId,
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <>
      <div className="flex pb-4 pl-4 pr-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Notes
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row px-4">
          <NotesHeaderSection setNoteId={setSelectedNoteId} />
        </div>
        <div>
          <DataTable
            columns={notesColumns}
            data={data?.results || []}
            isLoading={isLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No notes found"
            emptyStateMessage="There are no notes to display. If it is an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
      <StudentNoteDialog
        noteId={selectedNoteId}
        setSelectedNoteId={setSelectedNoteId}
        modalType={noteModalType}
        onModeChange={setNoteModalType}
      />
      <DeleteStudentNoteDialog noteId={selectedNoteId} setSelectedNoteId={setSelectedNoteId} />
    </>
  );
};
