import { z } from 'zod';
import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType } from '@purple/shared-types';
import { unionOfLiterals, zodAlwaysRefine } from '@purple/shared-utils';

export const BOOLEAN_LIKE_OPTIONS = ['Yes', 'No', 'N/A'] as const;
export const YES_NO_OPTIONS = ['Yes', 'No'] as const;

export const takeActionCrisisResponseSchema = zodAlwaysRefine(
  z.object({
    document: unionOfLiterals(Object.values(TakeActionDocumentType)),
    link: unionOfLiterals(Object.values(TakeActionLinkType)),
    group: unionOfLiterals(Object.values(TakeActionGroupType)),
    groupName: z
      .string({
        invalid_type_error: 'Group name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Group name must be less than 128 characters.',
      }),
    groupId: z
      .string({
        invalid_type_error: 'Student school must be a string.',
      })
      .trim(),
    title: z.string(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .min(1, {
        message: 'Type is required.',
      }),
    date: z.date({
      message: 'Date is invalid.',
      required_error: 'Date is required.',
    }),
    duration: z
      .number({
        invalid_type_error: 'Duration must be a number.',
        message: 'Duration must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Duration must be greater than 0.',
      }),
    crisisTypes: z.array(z.string()).min(1, {
      message: 'At least one crisis type is required.',
    }),
    lawEnforcementNotified: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'Law enforcement notified is required.',
    }),
    cpsNotified: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'CPS notified is required.',
    }),
    parentCaregiverNotified: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'Parent/caregiver notified is required.',
    }),
    tags: z.array(z.string()),
    parentConversationSummary: z.string().trim().min(1, {
      message: 'Parent/Caregiver conversation summary is required.',
    }),
    administrationNotified: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'Administration notified is required.',
    }),
    consultants: z.array(z.string()).min(1, {
      message: 'At least one consultant is required.',
    }),
    whoWasConsulted: z.string().trim().min(1, {
      message: 'Who was consulted is required.',
    }),
    threatAssessmentAdministrated: unionOfLiterals(YES_NO_OPTIONS, {
      message: 'Threat assessment administrated is required.',
    }),
    threatAssessmentResult: z.string().trim().optional(),
    riskLevelResultExplanation: z.string().trim().min(1, {
      message: 'Risk level result explanation is required.',
    }),
    parentNotifiedAssessment: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'Parent notified of assessment is required.',
    }),
    weaponsAccessible: unionOfLiterals(BOOLEAN_LIKE_OPTIONS, {
      message: 'Weapons accessible to this individual is required.',
    }),
    followUpPlan: z.string().trim().min(1, {
      message: 'Follow-up plan is required.',
    }),
    sharedNotes: z.string().trim(),
    myNotes: z.string().trim(),
  }),
).superRefine((data, context) => {
  if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.NEW && data.groupName === '') {
    context.addIssue({
      path: ['groupName'],
      code: z.ZodIssueCode.custom,
      message: 'Group name is required.',
    });
  }

  if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.EXISTING && data.groupId === '') {
    context.addIssue({
      path: ['groupId'],
      code: z.ZodIssueCode.custom,
      message: 'Group is required.',
    });
  }

  if (
    data.threatAssessmentAdministrated === 'Yes'
    && (data.threatAssessmentResult === '' || data.threatAssessmentResult === undefined)
  ) {
    context.addIssue({
      path: ['threatAssessmentResult'],
      code: z.ZodIssueCode.custom,
      message: 'Threat assessment result is required.',
    });
  }
});
