import { getAxiosInstance } from '../../api';
import { AUTH_ENDPOINTS } from '../../constants/public-api';
import type { IUser } from '@purple/shared-types';

export type LoginRequestBody = {
  email: string;
  password: string;
};

export type LoginResponseBody = {
  access: string;
  refresh: string;
  user: IUser;
};

export const login = async (body: LoginRequestBody) => {
  const apiInstance = getAxiosInstance();
  const response = await apiInstance.post<LoginResponseBody>(AUTH_ENDPOINTS.LOGIN, body);
  return response.data;
};
