import { z } from 'zod';
import { TakeActionDocumentType, TakeActionGroupType, TakeActionLinkType } from '@purple/shared-types';

export const takeActionCounselingSchema = z
  .object({
    document: z.nativeEnum(TakeActionDocumentType),
    link: z.nativeEnum(TakeActionLinkType),
    group: z.nativeEnum(TakeActionGroupType),
    groupName: z
      .string({
        invalid_type_error: 'Group name must be a string.',
      })
      .trim()
      .max(128, {
        message: 'Group name must be less than 128 characters.',
      }),
    groupId: z
      .string({
        invalid_type_error: 'Student school must be a string.',
      })
      .trim(),
    title: z.string(),
    focusAreas: z.array(z.string()).min(1, {
      message: 'At least one focus area is required.',
    }),
    date: z.date({
      message: 'Date is invalid.',
      required_error: 'Date is required.',
    }),
    duration: z
      .number({
        invalid_type_error: 'Duration must be a number.',
        message: 'Duration must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Duration must be greater than 0.',
      }),
    levelOfDistressStart: z
      .string()
      .trim()
      .refine(
        (value) => {
          const numberValue = Number(value);

          if (Number.isNaN(numberValue)) {
            return false;
          }

          return numberValue >= 0;
        },
        {
          message: 'Level of distress must be a number.',
        },
      )
      .optional(),
    levelOfDistressEnd: z
      .string()
      .trim()
      .refine(
        (value) => {
          const numberValue = Number(value);

          if (Number.isNaN(numberValue)) {
            return false;
          }

          return numberValue >= 0;
        },
        {
          message: 'Level of distress must be a number.',
        },
      )
      .optional(),
    suicideScreeningAdministrated: z.union([z.literal('Yes'), z.literal('No')]),
    suicideScreeningResult: z.string().trim(),
    tags: z.array(z.string()),
    parentAcknowledged: z.boolean(),
    sharedNotes: z.string().trim(),
    myNotes: z.string().trim(),
  })
  .superRefine((data, context) => {
    if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.NEW && data.groupName === '') {
      context.addIssue({
        path: ['groupName'],
        code: z.ZodIssueCode.custom,
        message: 'Group name is required.',
      });
    }

    if (data.link === TakeActionLinkType.GROUP && data.group === TakeActionGroupType.EXISTING && data.groupId === '') {
      context.addIssue({
        path: ['groupId'],
        code: z.ZodIssueCode.custom,
        message: 'Group is required.',
      });
    }

    if (data.suicideScreeningAdministrated === 'Yes' && data.suicideScreeningResult === '') {
      context.addIssue({
        path: ['suicideScreeningResult'],
        code: z.ZodIssueCode.custom,
        message: 'Suicide screening assessment result is required.',
      });
    }
  });
