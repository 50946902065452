import { formatDateToLocalTime } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { userSelector } from '~/store/features/user';
import { NotesTableOptions } from './NotesTableOptions';
import { NotesTableTitleCell } from './NotesTableTitleCell';
import type { ColumnDef } from '@tanstack/react-table';
import type { TNote } from '@purple/shared-types';

type TNotesOptions = {
  onSelectOption: (noteId: string) => void;
  onModeChange: (mode: 'create' | 'edit' | 'preview') => void;
};

type TNotesColumns = (options: TNotesOptions) => ColumnDef<TNote>[];

export const useNotesColumns: TNotesColumns = (options) => {
  const { id } = useAppSelector(userSelector);
  const { onSelectOption, onModeChange } = options || {};

  const notesColumns: ColumnDef<TNote>[] = [
    {
      accessorKey: 'title',
      minSize: 220,
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Title
        </Text>
      ),
      cell: ({ row: { original } }) => (
        <NotesTableTitleCell rowData={original} onSelectOption={onSelectOption} onModeChange={onModeChange} />
      ),
    },
    {
      accessorKey: 'created_by',
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Created By
        </Text>
      ),
      cell: ({
        row: {
          original: { created_by },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
          {created_by?.full_name || '–'}
        </Text>
      ),
    },
    {
      accessorKey: 'last_modified',
      minSize: 200,
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Last Modified
        </Text>
      ),
      cell: ({
        row: {
          original: { updated_at },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {formatDateToLocalTime(updated_at)}
        </Text>
      ),
    },
    {
      accessorKey: 'last_modified_by',
      minSize: 200,
      header: () => (
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
          Last Modified By
        </Text>
      ),
      cell: ({
        row: {
          original: { last_modified_by },
        },
      }) => (
        <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
          {last_modified_by?.full_name || '–'}
        </Text>
      ),
    },
    {
      id: 'actions',
      maxSize: 40,
      cell: ({ row: { original } }) => {
        if (original.created_by?.id === id) {
          return <NotesTableOptions rowData={original} onSelectOption={onSelectOption} onModeChange={onModeChange} />;
        }
        return null;
      },
    },
  ];
  return notesColumns;
};
