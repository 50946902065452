import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAttendanceExportFile } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useExportAttendanceFile = ({
  studentId,
  selectedYear,
  enabled,
  onSuccess,
}: {
  studentId: string;
  selectedYear: string;
  enabled: boolean;
  onSuccess?: () => void;
}) => {
  const { isError, data, isSuccess, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.EXPORT_ATTENDANCE_ANALYSIS, { studentId, selectedYear }],
    queryFn: () => getAttendanceExportFile(studentId, selectedYear),
    enabled: !!studentId && enabled,
  });

  useEffect(() => {
    if (isSuccess && enabled && data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `attendance_for_${selectedYear}-${studentId}.xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      // Clean up the URL to prevent memory leaks
      window.URL.revokeObjectURL(url);

      onSuccess?.();
    }
  }, [isSuccess, enabled, data, selectedYear, studentId, onSuccess]);

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to export the table');
    }
  }, [isError]);

  return { isError, ...rest };
};
