import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useTimePeriod } from '@purple/hooks';
import { SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppSelectedFiltersList, BackButton, Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useSubPageView } from '~/hooks';
import { useStudentMeetingAttendanceFilterOptions, useStudentMeetingAttendanceList } from '~/queries';
import { DASHBOARD_VIEW } from '../constants';
import { HeaderSection, HeaderSectionSkeleton } from './HeaderSection';
import { meetingColumns } from './meetingColumns';

const MeetingAttendance = () => {
  const { studentId } = useParams();

  const { changeView } = useSubPageView({
    defaultView: DASHBOARD_VIEW.MEETING_ATTENDANCE,
  });

  const [searchParameters] = useSearchParams();
  const search = searchParameters.get(SEARCH_QUERY_NAME) || '';
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data: filterOptions, isLoading: isFiltersLoading } = useStudentMeetingAttendanceFilterOptions(
    studentId as string,
  );

  const { isLoading: isDataLoading, data } = useStudentMeetingAttendanceList({
    studentId: studentId as string,
    queryParameters: {
      limit,
      offset,
      ordering: sort,
      search,
      service_providers: searchParameters.get('service_providers'),
      time_period: timePeriod,
    },
  });

  const isDataNotExist = useMemo(
    () => (!data?.results || data.results.length === 0) && (!isDataLoading || !isFiltersLoading),
    [data?.results, isDataLoading, isFiltersLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3 py-6">
      <div className="flex flex-col gap-4 px-4">
        <BackButton label="Back to Dashboard" onClick={() => changeView(DASHBOARD_VIEW.DASHBOARD)} />
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Meeting Attendance
        </Heading>
        <div className="flex flex-row">
          {isFiltersLoading || !filterOptions
            ? (
                <HeaderSectionSkeleton />
              )
            : (
                <div className="flex w-full flex-col gap-4">
                  <HeaderSection
                    filterConfig={convertToFilterConfig(filterOptions)}
                    timePeriod={timePeriod}
                    onPeriodChange={onPeriodChange}
                    onPeriodClear={onPeriodClear}
                  />
                  <AppSelectedFiltersList config={convertToFilterConfig(filterOptions)} maxDisplayedFilters={8} />
                </div>
              )}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          <DataTable
            columns={meetingColumns}
            data={data?.results || []}
            isLoading={isDataLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No Meeting attendances found"
            emptyStateMessage="There are no Meeting attendances to display. If it an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { MeetingAttendance };
