import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import type { TMyGroupActionsRequestParameters, TMyGroupActionsResponse } from '@purple/shared-types';

export const getMyGroupActions = async (parameters?: TMyGroupActionsRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<TMyGroupActionsResponse>(ACTIONS.GET_ACTION_GROUPS, { params: parameters });
  return response.data;
};
