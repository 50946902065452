import { PurpleIcon, type PurpleIconType } from '@purple/icons';
import { cn, Heading, Text } from '@purple/ui';

const ICON_SIZE = 32;

type TSafTotalCardProperties = {
  title: string;
  value: string | number;
  icon: PurpleIconType;
  iconClassName?: string;
};

const SafTotalCard = ({ title, value, icon, iconClassName }: TSafTotalCardProperties) => {
  return (
    <div className="flex w-full gap-3 rounded-lg border border-grey-200 p-4">
      <div className={cn('flex h-14 w-14 items-center justify-center rounded-xl', iconClassName)}>
        <PurpleIcon name={icon} height={ICON_SIZE} width={ICON_SIZE} />
      </div>
      <div className="flex flex-col">
        <Text variant="size-14" type="body-500" className="text-grey-600">
          {title}
        </Text>
        <Heading variant="size-28" type="heading-700" tag="h5" className="text-grey-title">
          {value}
        </Heading>
      </div>
    </div>
  );
};

export { SafTotalCard };
