import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppSelector } from '~/hooks';
import { isAuthenticatedSelector, isLoadingSelector } from '~/store/features/user';

export const AuthLayout: React.FC = () => {
  const isUserAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isAuthLoading = useAppSelector(isLoadingSelector);

  if (!isAuthLoading && isUserAuthenticated) return <Navigate to={AppRoutes.App.Home.Root.path} replace />;

  return (
    <div className="flex h-screen w-full items-center justify-center bg-grey-50">
      <div className="relative flex w-[564px] items-center justify-center rounded-3xl border border-grey-200 bg-white px-[64px] py-[72px] shadow-custom-standard max-h-720:py-[48px]">
        <Outlet />
      </div>
    </div>
  );
};
