import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import type React from 'react';
import type { ToolbarProps } from 'react-big-calendar';
import type { IStudentMonthEvent } from '@purple/shared-types';

const CustomToolbar: React.FC<
  ToolbarProps<IStudentMonthEvent> & {
    leftContent: React.ReactNode;
    maxDate: Date;
  }
> = ({ label, onNavigate, date, maxDate, leftContent }) => {
  const isNextDisabled = date && date.getMonth() >= maxDate.getMonth() && date.getFullYear() >= maxDate.getFullYear();

  return (
    <div className="flex flex-row items-center justify-between rounded-t-lg border border-b-0 border-grey-200 p-4">
      <div>{leftContent}</div>
      <div className="flex flex-row items-center gap-2">
        <Button
          variant="tertiary"
          size="icon_32"
          iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700" />}
          onClick={() => onNavigate('PREV')}
          pw-id="calendar-button-prev"
        />
        <Text variant="size-14" type="body-600" className="text-brand-blue-700" pw-id="calendar-month">
          {label}
        </Text>
        <Button
          variant="tertiary"
          size="icon_32"
          iconLeft={<PurpleIcon name="chevron-right" className="text-brand-blue-700" />}
          onClick={() => onNavigate('NEXT')}
          disabled={isNextDisabled}
          pw-id="calendar-button-next"
        />
      </div>
    </div>
  );
};

export { CustomToolbar };
