import { useState } from 'react';
import { cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
// types
import { PurpleIcon } from '@purple/icons';
import type React from 'react';
import type { PurpleIconType } from '@purple/icons';

type TTipBoxProperties = {
  /**
   * The text to display in the tip box.
   */
  text: string;
  /**
   * The icon name to display in the tip box.
   * @default 'information'
   */
  iconName?: PurpleIconType;
  /**
   * The alignment of the tooltip.
   */
  align?: React.ComponentProps<typeof TooltipContent>['align'];
  /**
   * Override or extend the styles applied to the tooltip content component.
   */
  tooltipClassName?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const TipBox: React.FC<TTipBoxProperties> = (props) => {
  const { text, iconName = 'information-circle', align, tooltipClassName, className } = props;

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const tooltipChangeHandler = (open: boolean) => {
    setIsTooltipOpen(open);
  };

  const toggleTooltipHandler = () => {
    setIsTooltipOpen((previous) => !previous);
  };

  return (
    <Tooltip open={isTooltipOpen} onOpenChange={tooltipChangeHandler}>
      <TooltipTrigger asChild>
        <button
          type="button"
          aria-label="Show tip"
          onClick={toggleTooltipHandler}
          className={cn(
            'flex h-5 w-5 items-center justify-center border-0 text-grey-600 outline-none transition-colors duration-300 hover:text-grey-950 focus:text-grey-950 focus-visible:text-grey-950',
            className,
          )}
        >
          <PurpleIcon name={iconName} className="h-4 w-4" />
        </button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent align={align} className={tooltipClassName}>
          {text}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
