import { getAxiosInstance } from '../../api';
import { PRIORITY_LIST_ENDPOINTS } from '../../constants/private-api';
import { replaceUrlParameters } from '../../helpers';

export const deletePriorityList = async (listId: string) => {
  const apiInstance = getAxiosInstance();

  const URL = replaceUrlParameters(PRIORITY_LIST_ENDPOINTS.DELETE_PRIORITY_LIST, listId);

  const response = await apiInstance.delete<void>(URL);
  return response.data;
};
