import React from 'react';
import { PAGE_VIEW_QUERIES } from '@purple/shared-types';
import { BackButton } from '@purple/ui';
import { useDetailsView } from '~/hooks/useDetailsView';
import { useGetPriorityListById } from '~/queries';
import { DetailViewHeader, DetailViewHeaderSkeleton, StudentsTable } from './components';

const ENTITY_QUERY_NAME = 'list_id' as const;

const MyPriorityListDetailView = () => {
  const { goToListView, entityId } = useDetailsView({
    defaultView: PAGE_VIEW_QUERIES.DETAIL_VIEW,
    entityQueryKey: ENTITY_QUERY_NAME,
  });

  const { data } = useGetPriorityListById(entityId);

  return (
    <div className="flex flex-col gap-2 py-6">
      <div className="flex flex-col gap-[18px] px-4">
        <BackButton label="Back to My Priority Lists" onClick={goToListView} />
        {data ? <DetailViewHeader data={data} /> : <DetailViewHeaderSkeleton />}
      </div>
      <StudentsTable listData={data} canEdit={Boolean(data?.is_owner)} />
    </div>
  );
};

export default React.memo(MyPriorityListDetailView);
