import { TakeActionType } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import type { TFiltersConfig } from '@purple/ui';

type TUseSupportByRoleFilterOptions = {
  roles?: string[];
  owners?: string[];
};

export const useSupportByRoleFilterOptions = (_params?: TUseSupportByRoleFilterOptions) => {
  const filterOptions = {
    categories: [
      {
        label: 'Action Type',
        value: 'record_action_type',
        filters: Object.values(TakeActionType).map((type) => ({
          label: snakeCaseToCapitalized(type),
          value: type,
        })),
      },
      {
        label: 'Creator role',
        value: 'role',
        filters: [],
      },
      {
        label: 'Created By',
        value: 'created_by',
        filters: [],
      },
    ],
  } satisfies TFiltersConfig;

  return { filterOptions };
};
