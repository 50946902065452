import { useMutation } from '@tanstack/react-query';
import { addSharePriorityListBulk } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useAddSharePriorityListBulk = () => {
  return useMutation({
    mutationKey: [PRIORITY_LISTS_QUERY_KEYS.ADD_SHARE_PRIORITY_LIST_BULK],
    mutationFn: addSharePriorityListBulk,
    onError: () => {
      showErrorToast(
        'System message',
        'Could not add users to the share list. Check the provided information and try again',
      );
    },
  });
};
