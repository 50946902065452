import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import type { View } from 'react-big-calendar';
import type { TEcoSystemActionsTypes } from '@purple/shared-types';
import type { TEcoSystemDataViewValues } from './constants';

export const getStartDateByListView = (dateView: TEcoSystemDataViewValues, date: Date) => {
  switch (dateView) {
    case 'list': {
      return startOfDay(date).toISOString();
    }
    case 'day': {
      return startOfDay(date).toISOString();
    }
    case 'week': {
      return startOfWeek(date).toISOString();
    }
    case 'month': {
      return startOfMonth(date).toISOString();
    }
    default: {
      return startOfDay(date).toISOString();
    }
  }
};

export const getEndDateByListView = (dateView: TEcoSystemDataViewValues, date: Date) => {
  switch (dateView) {
    case 'list': {
      return endOfDay(date).toISOString();
    }
    case 'day': {
      return endOfDay(date).toISOString();
    }
    case 'week': {
      return endOfWeek(date).toISOString();
    }
    case 'month': {
      return endOfMonth(date).toISOString();
    }
    default: {
      return endOfDay(date).toISOString();
    }
  }
};

export const getDataViewByCalendarView = (calendarView: View) => {
  switch (calendarView) {
    case 'day': {
      return 'day';
    }
    case 'week': {
      return 'week';
    }
    case 'month': {
      return 'month';
    }
    default: {
      return 'day';
    }
  }
};

export const getCalendarViewByDataView = (dateView: TEcoSystemDataViewValues) => {
  switch (dateView) {
    case 'list': {
      return 'day';
    }
    case 'day': {
      return 'day';
    }
    case 'week': {
      return 'week';
    }
    case 'month': {
      return 'month';
    }
    default: {
      return 'day';
    }
  }
};

export const getEventColorByType = (type: TEcoSystemActionsTypes) => {
  switch (type) {
    case 'action': {
      return 'bg-[#5369BD] hover:bg-[#3A4C8C] active:bg-[#2A3A6C]';
    }
    case 'group_action': {
      return 'bg-[#F38B23] hover:bg-[#D16C00] active:bg-[#A84D00]';
    }
    case 'community_activity': {
      return 'bg-[#AE5983] hover:bg-[#8C3F64] active:bg-[#6A2B4A]';
    }
    // TODO: Uncomment when the following event types will be added
    // case 'recurring_sync': {
    //   return 'bg-[#2AA5AD] hover:bg-[#1A7A7F] active:bg-[#0A4F54]';
    // }
    // case 'one_time_sync': {
    //   return 'bg-[#38B723] hover:bg-[#2C8C1A] active:bg-[#1F5D11]';
    // }
    case 'reminder': {
      return 'bg-[#374071] hover:bg-[#2A2A57] active:bg-[#1D1D3C]';
    }
    default: {
      return 'bg-[#CEDAEF] hover:bg-[#A9B7D4] active:bg-[#8494BF]';
    }
  }
};
