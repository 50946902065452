import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TAppPermissions } from '@purple/permissions';

type IUserPermissionsState = {
  permissions: TAppPermissions[];
};

const initialState: IUserPermissionsState = {
  permissions: [],
};

export const userPermissionsSlice = createSlice({
  name: AppReduxReducer.USER_PERMISSIONS,
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<TAppPermissions[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const { setPermissions } = userPermissionsSlice.actions;
export const userPermissionsReducer = userPermissionsSlice.reducer;
