import { Skeleton } from '@purple/ui';
import type React from 'react';

export const SchoolStatsSkeleton: React.FC = () => {
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="flex w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
        <Skeleton className="h-[28px] w-[186px]" />
        <div className="flex w-full items-baseline gap-1">
          <Skeleton className="h-[48px] w-[112px]" />
          <Skeleton className="h-[28px] w-[34px]" />
        </div>
        <Skeleton className="h-[16px] w-[166px]" />
      </div>
      <div className="flex w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
        <Skeleton className="h-[28px] w-[186px]" />
        <div className="flex w-full items-baseline gap-1">
          <Skeleton className="h-[48px] w-[112px]" />
          <Skeleton className="h-[28px] w-[34px]" />
        </div>
        <Skeleton className="h-[16px] w-[166px]" />
      </div>
      <div className="flex w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4">
        <Skeleton className="h-[28px] w-[186px]" />
        <div className="flex w-full items-baseline gap-1">
          <Skeleton className="h-[48px] w-[112px]" />
          <Skeleton className="h-[28px] w-[34px]" />
        </div>
        <Skeleton className="h-[16px] w-[166px]" />
      </div>
    </div>
  );
};
