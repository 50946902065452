import { getAxiosInstance } from '../../api';
import { AUTH_ENDPOINTS } from '../../constants/public-api';
import { replaceUrlParameters } from '../../helpers';

type TSendEmailConfirmationRequest = {
  uid: string;
  token: string;
};

export const sendEmailConfirmation = async ({ uid, token }: TSendEmailConfirmationRequest): Promise<void> => {
  const axiosInstance = getAxiosInstance();

  const URL = replaceUrlParameters(AUTH_ENDPOINTS.CHANGE_EMAIL_CONFIRM, uid, token);
  const response = await axiosInstance.post<void>(URL);
  return response.data;
};
