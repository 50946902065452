import type { TActivityBodyRequest } from '@purple/shared-types';

export const convertActivityToFormData = (parameters: TActivityBodyRequest) => {
  const formData = new FormData();

  if (parameters.name) formData.append('name', parameters.name);
  if (parameters.community_activity_type)
    formData.append('community_activity_type', parameters.community_activity_type);
  if (parameters.type) formData.append('type', parameters.type);
  if (parameters.end_date_and_time) formData.append('end_date_and_time', parameters.end_date_and_time);
  if (parameters.school) formData.append('school', parameters.school);
  if (parameters.date_and_time) formData.append('date_and_time', parameters.date_and_time);
  if (parameters.start_date_and_time) formData.append('start_date_and_time', parameters.start_date_and_time);

  if (parameters.tags) {
    formData.append('tags', JSON.stringify(parameters.tags));
  }

  if (parameters.duration !== undefined) formData.append('duration', parameters.duration.toString());
  if (parameters.description) formData.append('description', parameters.description);

  if (parameters.attachments) {
    for (const file of parameters.attachments) {
      formData.append('attachments', file);
    }
  }

  if (parameters.stakeholders_served) {
    for (const stakeholder of parameters.stakeholders_served) {
      formData.append('stakeholders_served', stakeholder);
    }
  }

  if (parameters.service_category) {
    for (const category of parameters.service_category) {
      formData.append('service_categories', category);
    }
  }

  if (parameters.number_of_people_served) {
    formData.append('number_of_people_served', JSON.stringify(parameters.number_of_people_served));
  }

  if (parameters.quantity_of_resources_provided) {
    formData.append('quantity_of_resources_provided', JSON.stringify(parameters.quantity_of_resources_provided));
  }

  if (parameters.days_of_week) {
    for (const day of parameters.days_of_week) {
      formData.append('days_of_week', day);
    }
  }

  if (parameters.weekly_interval !== undefined) {
    formData.append('weekly_interval', parameters.weekly_interval.toString());
  }

  if (parameters.enable_recurring !== undefined) {
    formData.append('enable_recurring', parameters.enable_recurring.toString());
  }

  if (parameters.bags_provided_count !== undefined) {
    formData.append('bags_provided_count', parameters.bags_provided_count.toString());
  }

  if (parameters.bags_provided_to_other_school !== undefined) {
    formData.append('bags_provided_to_other_school', parameters.bags_provided_to_other_school.toString());
  }

  if (parameters.supported_school) {
    formData.append('supported_school', parameters.supported_school);
  }

  if (parameters.participants_count !== undefined) {
    formData.append('participants_count', parameters.participants_count.toString());
  }

  if (parameters.grade_level) {
    for (const grade of parameters.grade_level) {
      formData.append('grade_level', grade);
    }
  }

  if (parameters.campus_contact_title) {
    formData.append('campus_contact_title', parameters.campus_contact_title);
  }

  if (parameters.campus_contact_name) {
    formData.append('campus_contact_name', parameters.campus_contact_name);
  }

  if (parameters.contact_phone_number) {
    formData.append('contact_phone_number', parameters.contact_phone_number);
  }

  if (parameters.contact_email) {
    formData.append('contact_email', parameters.contact_email);
  }

  if (parameters.crisis_type) {
    for (const crisis of parameters.crisis_type) {
      formData.append('crisis_type', crisis);
    }
  }

  if (parameters.crisis_grade_level) {
    formData.append('crisis_grade_level', parameters.crisis_grade_level);
  }

  if (parameters.description_of_crisis) {
    formData.append('description_of_crisis', parameters.description_of_crisis);
  }

  if (parameters.assigned_to) {
    formData.append('assigned_to', parameters.assigned_to);
  }

  if (parameters.topic) {
    formData.append('topic', parameters.topic);
  }

  if (parameters.session_participant) {
    formData.append('session_participant', parameters.session_participant);
  }

  if (parameters.crisis_mode_of_support) {
    formData.append('mode_of_support', parameters.crisis_mode_of_support);
  }

  if (parameters.crisis_internal_comments) {
    formData.append('crisis_internal_comments', parameters.crisis_internal_comments);
  }

  return formData;
};
