import { APP_PERMISSIONS } from '@purple/permissions';
import {
  StudentAcademics,
  StudentCustomFields,
  StudentDemographic,
  StudentHealth,
  StudentHomeInformation,
  StudentSchoolHistory,
} from './components';
import type { IStudentDetailsResponse } from '@purple/shared-services';

const ACCORDION_LABELS = {
  DEMOGRAPHIC: 'Demographic',
  HOME_INFO: 'Home Information',
  ACADEMICS: 'Academics',
  HEALTH_INFO: 'Health Info and Emergency Contact',
  SCHOOL_HISTORY: 'School History',
  ADDITIONAL_INFORMATION: 'Additional Information',
};

export const useStudentSidebarAccordionItems = (data: IStudentDetailsResponse | undefined, studentId: string) => {
  if (!data) {
    return [];
  }

  const items = [
    {
      label: ACCORDION_LABELS.DEMOGRAPHIC,
      content: <StudentDemographic data={data?.demographic} />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_DEMOGRAPHIC_INFORMATION],
    },
    {
      label: ACCORDION_LABELS.HOME_INFO,
      content: <StudentHomeInformation data={data?.home_information} studentId={studentId} />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_HOME_INFORMATION],
    },
    {
      label: ACCORDION_LABELS.ACADEMICS,
      content: <StudentAcademics data={data?.academics} />,
      permissions: [],
    },
    {
      label: ACCORDION_LABELS.HEALTH_INFO,
      content: <StudentHealth data={data?.health} />,
      permissions: [APP_PERMISSIONS.CAN_ACCESS_STUDENT_HEALTH_INFORMATION],
    },
    {
      label: ACCORDION_LABELS.SCHOOL_HISTORY,
      content: <StudentSchoolHistory data={data?.school_history} />,
      permissions: [],
    },
  ];

  const isCustomFieldsNotEmpty = Object.keys(data.custom_fields).length > 0;
  if (isCustomFieldsNotEmpty) {
    items.push({
      label: ACCORDION_LABELS.ADDITIONAL_INFORMATION,
      content: <StudentCustomFields data={data.custom_fields} />,
      permissions: [],
    });
  }

  return items;
};
