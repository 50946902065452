import { getAxiosInstance } from '../../api';
import { ACTIONS } from '../../constants/private-api';
import type { TActionChoicesRequestParameters, TActionChoicesResponse } from '@purple/shared-types';

export const postActionChoices = async (parameters: TActionChoicesRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const body = {
    ...parameters,
  };
  if (parameters.priority_list) {
    delete body.students;
  } else if (parameters.students) {
    delete body.priority_list;
  }

  const response = await axiosInstance.post<TActionChoicesResponse>(ACTIONS.GET_ACTION_CHOICES, body);
  return response.data;
};
