import { Skeleton } from '@purple/ui';

export const NewStudentsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white px-4 py-6 pb-4 shadow-custom-medium">
      <div className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
        <Skeleton className="h-[24px] w-[100px]" />
        <Skeleton className="h-[20px] w-[84px]" />
      </div>
      <div className="flex w-full items-center justify-center gap-4">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-[48px] w-[62px]" />
            <Skeleton className="h-[24px] w-[101px]" />
          </div>
          <Skeleton className="h-[132px] w-full" />
        </div>
      </div>
      <div className="mt-auto flex w-full items-center justify-center">
        <Skeleton className="h-[36px] w-[108px]" />
      </div>
    </div>
  );
};
