import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { ISubPopulation } from '@purple/shared-types';

type TSubpopInfoProperties = React.PropsWithChildren<{
  subpop: ISubPopulation[];
}>;

const SubpopInfo = ({ subpop }: TSubpopInfoProperties) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const tooltipChangeHandler = (open: boolean) => {
    setIsTooltipOpen(open);
  };

  const toggleTooltipHandler = () => {
    setIsTooltipOpen((previous) => !previous);
  };

  return (
    <Tooltip open={isTooltipOpen} onOpenChange={tooltipChangeHandler}>
      <TooltipTrigger asChild>
        <button type="button" aria-label="Show tip" onClick={toggleTooltipHandler}>
          <PurpleIcon name="information-circle" height={16} width={16} className="text-grey-600" />
        </button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent className="bg-white" side="bottom">
          <div className="flex flex-col gap-2">
            {subpop.map((subpopItem) => (
              <Text key={subpopItem.id} variant="size-12" type="body-500" className="px-4 text-grey-950">
                {subpopItem.name}
              </Text>
            ))}
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

export { SubpopInfo };
