import { Skeleton } from '@purple/ui';

export const OverdueAttendanceSkeleton: React.FC = () => {
  return (
    <div className="col-span-2 flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white pt-6 shadow-custom-medium">
      <Skeleton className="mx-4 h-[24px] w-[160px]" />
      <div className="flex w-full flex-col items-center px-2">
        <Skeleton className="my-3 h-[16px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
        <div className="w-full border-b border-gray-200" />
        <Skeleton className="my-3 h-[32px] w-full" />
      </div>
    </div>
  );
};
