import * as z from 'zod';
import { FormErrorMessages } from '../../constants/error-messages';

export const signInFormSchema = z.object({
  email: z
    .string({
      invalid_type_error: FormErrorMessages.EMAIL.INVALID_TYPE,
    })
    .trim()
    .min(1, {
      message: FormErrorMessages.EMAIL.REQUIRED,
    })
    .email({
      message: FormErrorMessages.EMAIL.INVALID_EMAIL,
    }),
  password: z
    .string({
      invalid_type_error: FormErrorMessages.PASSWORD.INVALID_TYPE,
    })
    .trim()
    .min(1, {
      message: FormErrorMessages.PASSWORD.REQUIRED,
    }),
});
