import { formatDateToLocalTime } from '@purple/shared-utils';
import { convertPlot } from '../student-trends-and-flags/converter';
import type { IStudentCalendarResponse } from '@purple/shared-services';
import type {
  IPaginatedResponse,
  IStudentAccountHistoryItem,
  IStudentDataConvertedResponse,
  IStudentDataResponse,
  IStudentIncidentDetail,
  IStudentMonthEvents,
  TStudentCalendarEventType,
} from '@purple/shared-types';

export const convertToMonthEventCalendar = (data: IStudentCalendarResponse): IStudentMonthEvents | null => {
  const { calendar } = data;

  if (!calendar) return null;

  const studentEvents: IStudentMonthEvents = [];

  for (const [date, events] of Object.entries(calendar)) {
    for (const event of events) {
      studentEvents.push({
        title: event.value,
        start: new Date(date),
        end: new Date(date),
        type: event.type as TStudentCalendarEventType,
      });
    }
  }

  return studentEvents;
};

export const convertAccountHistoryResponseToDataTable = (
  response: IPaginatedResponse<IStudentAccountHistoryItem[]>,
) => {
  return {
    ...response,
    results: response.results.map((item) => ({
      ...item,
      datetime: formatDateToLocalTime(item.datetime),
    })),
  };
};

export const convertIncidentDetailResponseToDataTable = (data: IStudentIncidentDetail) => {
  return {
    ...data,
    incident_date_time: formatDateToLocalTime(data.incident_date_time),
    recorded_on: formatDateToLocalTime(data.recorded_on),
  };
};

export const convertStudentDataResponseToDataTable = (data: IStudentDataResponse): IStudentDataConvertedResponse => {
  return {
    ...data,
    results: data.results.map((item) => ({
      ...item,
      plot: convertPlot(item.filtered_cycle_progress, data.colors),
      colorsRange: data.colors,
    })),
  };
};
