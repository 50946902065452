import { useTranslation } from 'react-i18next';
import { useMask } from '@react-input/mask';
import { motion } from 'framer-motion';
import { CONTAIN_DIGIT_REGEX, mergeReferences } from '@purple/shared-utils';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { SAF_PHONE_MASK } from '~/constants/saf';
import { useStudentAssistance } from '~/hooks/redux';
import { SchoolComboBox } from '../components';
import { useArabicLanguage } from '../hooks';
// types
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { safFormSchema } from './schema';

type TStudentFormProperties = {
  /**
   * The form object that contains the form state and methods.
   */
  form: UseFormReturn<z.infer<typeof safFormSchema>>;
  /**
   * The transition delta that determines the direction of the form transition.
   */
  transitionDelta: number;
};

export const StudentForm: React.FC<TStudentFormProperties> = (props) => {
  const { form, transitionDelta } = props;

  const inputReference = useMask({
    mask: SAF_PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const { schoolGrades } = useStudentAssistance();
  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  return (
    <motion.div
      initial={{ x: transitionDelta >= 0 ? '50%' : '-50%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="grid w-full grid-cols-2 gap-x-4 gap-y-6"
    >
      <FormField
        control={form.control}
        name="studentFirstName"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t('form.student.first-name.label')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                isError={!!form.formState.errors.studentFirstName}
                placeholder={t('form.student.first-name.placeholder')}
                type="text"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentLastName"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t('form.student.last-name.label')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                isError={!!form.formState.errors.studentLastName}
                placeholder={t('form.student.last-name.placeholder')}
                type="text"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentMiddleName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.student.middle-name.label')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                isError={!!form.formState.errors.studentMiddleName}
                placeholder={t('form.student.middle-name.placeholder')}
                type="text"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.student.id.label')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                isError={!!form.formState.errors.studentId}
                placeholder={t('form.student.id.placeholder')}
                type="text"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentSchool"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t('form.student.school.label')}</FormLabel>
            <FormControl>
              <SchoolComboBox
                value={field.value}
                onChange={(value) => form.setValue('studentSchool', value, { shouldValidate: true })}
                isError={!!form.formState.errors.studentSchool}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentGrade"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t('form.student.grade.label')}</FormLabel>
            <RadixSelect onValueChange={field.onChange} defaultValue={field.value} disabled={schoolGrades.length === 0}>
              <FormControl>
                <RadixSelectTrigger dir={isArabic ? 'rtl' : 'ltr'}>
                  <RadixSelectValue placeholder={t('form.student.grade.placeholder')} />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent>
                {[...schoolGrades]
                  .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
                  .map((grade) => (
                    <RadixSelectItem key={grade} value={grade}>
                      {grade}
                    </RadixSelectItem>
                  ))}
              </RadixSelectContent>
            </RadixSelect>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="studentParentPhone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.student.parent-phone.label')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                ref={mergeReferences([field.ref, inputReference])}
                isError={!!form.formState.errors.studentParentPhone}
                placeholder={SAF_PHONE_MASK}
                type="tel"
                dir={isArabic ? 'rtl' : 'ltr'}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </motion.div>
  );
};
