import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { Avatar, AvatarFallback, AvatarImage, Button, Heading, InfoItem, PrintButton } from '@purple/ui';
// helpers
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
import { useTakeAction } from '~/hooks/redux';
// components
import { InfoItemValue } from '~/components';
import { TakeActionModal } from '~/components/Modals/TakeActionModal';
import { AppRoutes } from '~/constants/routes/routes';
import { StudentLabels } from '../StudentLabels';
// types
import type { IStudentDetails } from '@purple/shared-types';

type StudentProfileHeaderProperties = {
  studentPk: string;
  data: IStudentDetails;
};

const StudentProfileHeader = ({ studentPk, data }: StudentProfileHeaderProperties) => {
  const { full_name, photo, tags, student_id, grade, school, is_active } = data;

  const { openModal: openTakeActionModal } = useModal(ModalType.TAKE_ACTION);
  const { setSelectedStudentId } = useTakeAction();

  const handleTakeAction = () => {
    setSelectedStudentId(studentPk);
    openTakeActionModal();
  };

  // TODO: use SubmitSAF hook in the future when it will be ready
  const handleSubmitSAF = () => {
    alert('Submit SAF'); // eslint-disable-line no-alert
  };

  // TODO: call Print API hook when it will be ready
  const handlePrint = () => {
    alert('Print'); // eslint-disable-line no-alert
  };

  const schoolUrl = AppRoutes.App.School.Root.makePath(school?.id as string, 'overview');

  return (
    <div className="flex flex-col gap-[22px]">
      <div className="flex flex-row justify-between gap-3">
        <div className="flex flex-row gap-3">
          <Avatar size={60}>
            <AvatarImage src={photo} alt={full_name} className="object-cover" />
            <AvatarFallback>
              <PurpleIcon name="user" className="h-6 w-6 text-brand-blue-700" />
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-1">
            <Heading tag="h2" variant="size-18" type="heading-600">
              {full_name}
            </Heading>
            <StudentLabels labels={tags} isStudentActive={is_active} />
          </div>
        </div>
        <PrintButton onClick={handlePrint} />
      </div>
      <div className="flex flex-col gap-4">
        <InfoItem label="Student ID">
          <InfoItemValue>{student_id}</InfoItemValue>
        </InfoItem>
        <InfoItem label="Student Grade">
          <InfoItemValue>{grade}</InfoItemValue>
        </InfoItem>
        <InfoItem label="Current School">
          <InfoItemValue to={schoolUrl}>{school?.name}</InfoItemValue>
        </InfoItem>
      </div>
      <div className="flex flex-row items-center gap-4">
        <Guard
          requiredPermissions={[
            APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
            APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
          ]}
        >
          <Button onClick={handleTakeAction} variant="primary">
            Take Action
          </Button>
        </Guard>
        <Button onClick={handleSubmitSAF} variant="secondary">
          Submit SAF
        </Button>
      </div>
      <TakeActionModal />
    </div>
  );
};

export { StudentProfileHeader };
