import { Skeleton } from '@purple/ui';

export const StatisticCardSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-grey-200 bg-white p-3 shadow-sm">
      <Skeleton className="h-[25px] w-[160px]" />
      <Skeleton className="h-[50px] w-[60px]" />
    </div>
  );
};
