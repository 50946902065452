import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getPriorityListStudents } from '@purple/shared-services';
import { PRIORITY_LISTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import { convertStudentsDataResponseToDataTable } from './converter';
import type { IPriorityListStudentsRequest } from '@purple/shared-types';

export const useGetPriorityListStudents = (request: IPriorityListStudentsRequest) => {
  const { pagination, search, id } = request;
  const { limit, offset } = pagination;

  const { isError, error, data, isLoading, isSuccess } = useQuery({
    queryKey: [PRIORITY_LISTS_QUERY_KEYS.GET_PRIORITY_LIST_STUDENTS, limit, offset, search, id],
    queryFn: () => getPriorityListStudents(request),
    enabled: !!id,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch students', 'Something went wrong, try again later.');
    }
  }, [isError]);

  return { isLoading, isError, error, isSuccess, data: data ? convertStudentsDataResponseToDataTable(data) : data };
};
