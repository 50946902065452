import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSafEmailSubject } from '@purple/shared-services';
import { SAF_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';

export const useSafEmailSubject = ({ safId, correspondenceId }: { safId: string; correspondenceId: string }) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SAF_QUERY_KEYS.EMAIL_SUBJECT, safId, correspondenceId],
    queryFn: () => getSafEmailSubject({ safId, correspondenceId }),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch SAF email subject');
    }
  }, [isError]);

  return { isError, ...rest };
};
