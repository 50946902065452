import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '~/store/white-list';
import type { PayloadAction } from '@reduxjs/toolkit';

type IStudentIncidentState = {
  incidentId: string;
};

const initialState: IStudentIncidentState = {
  incidentId: '',
};

export const studentIncidentSlice = createSlice({
  name: AppReduxReducer.STUDENT_INCIDENT,
  initialState,
  reducers: {
    setStudentIncidentId: (state, action: PayloadAction<string>) => {
      state.incidentId = action.payload;
    },
    resetStudentIncidentId: (state) => {
      state.incidentId = initialState.incidentId;
    },
  },
});

export const { setStudentIncidentId, resetStudentIncidentId } = studentIncidentSlice.actions;

export const studentIncidentReducer = studentIncidentSlice.reducer;
