import { Badge } from '@purple/ui';

type TagProperties = {
  label: string;
};

const Tag = ({ label }: TagProperties) => {
  return <Badge size="small" label={label} className="bg-brand-blue-100 text-brand-blue-700" />;
};

export { Tag };
